import { AssistantRole } from '@endpoint/endpoint-bff-graphql-schema';
import { FormikValue } from 'routes/OpenEscrow';

import { formatTeammateRole } from './formatTeammateRole';

type TeammateRoleSelection = { value: AssistantRole; label: string } | undefined;

export const getTeammateRoleSelection = (prefix: string, values: FormikValue): TeammateRoleSelection => {
  const teammateIndexString = prefix.substring(prefix.indexOf('[') + 1, prefix.indexOf(']'));
  const teammateIndex = Number(teammateIndexString);

  if (!teammateIndexString || Number.isNaN(teammateIndex)) {
    return undefined;
  }

  const selectedRole = values.teammates?.[teammateIndex]?.role;

  if (!selectedRole) {
    return undefined;
  }

  return {
    value: selectedRole,
    label: formatTeammateRole(selectedRole),
  };
};
