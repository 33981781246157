import { useEffect, ReactElement } from 'react';
import { isAgent, useAppContext } from 'utils/context';
import { useNavigate } from 'react-router';

export const useAgentOnlyRoute = (component: ReactElement, fallback: ReactElement, isLoading: boolean | undefined) => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAgent(user)) {
      navigate('/transactions/in-escrow');
    }
  }, [user, navigate, isLoading]);

  return isLoading ? fallback : component;
};
