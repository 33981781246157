import React, { FC } from 'react';
import { Text, Box } from '@endpoint/blockparty';
import { formatCurrency } from 'utils/formatCurrency';

interface AmountWidgetProps {
  value: string | undefined;
}

export const AmountWidget: FC<AmountWidgetProps> = ({ value }) => {
  const amount = parseFloat(value ?? '0');

  return (
    <Box bg="carbon0" borderRadius="radiusDefault" mb="space70" p="space50">
      <Text as="p" color="carbon500" size="fontSize10">
        Amount
      </Text>
      <Text as="p" size="fontSize30">
        {formatCurrency(amount)}
      </Text>
    </Box>
  );
};
