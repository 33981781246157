import React, { FC, useContext } from 'react';
import { Box, DrawerSection, Heading, Stack, Text } from '@endpoint/blockparty';
import { TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { CompanyContext, useAppContext } from 'utils/context';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';
import { getUserRoles } from 'utils/transactions/getUserRoles';
import { formatDate, WEEKDAY_MONTH_DAY } from 'utils/formatDate';

import { AgentView } from './AgentView';
import { BuyerView } from './BuyerView';
import { SigningAppointmentBlock } from './SigningAppointment';

/* ======================================= */

export const M6SigningVariant: FC = () => {
  const { user } = useAppContext();
  const { data: transactionData } = useTransactionDetailsContext();
  const { participants, todos } = transactionData?.transaction ?? {};
  // @ts-ignore
  const userRoles = getUserRoles(participants, user.id);

  /**
   * TC
   */

  const isTransactionCoordinator =
    userRoles.includes(TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR) ||
    userRoles.includes(TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR);

  if (isTransactionCoordinator) {
    return <AgentView userRoles={userRoles} />;
  }

  /**
   * Buyer
   */
  const isBuyer = userRoles.includes(TransactionRole.BUYER);

  if (isBuyer) {
    return <BuyerView todos={todos ?? []} />;
  }

  /**
   * Listing and/or Selling Agent
   */

  const isListingOrSellingAgent =
    userRoles.includes(TransactionRole.LISTING_AGENT) || userRoles.includes(TransactionRole.SELLING_AGENT);

  if (isListingOrSellingAgent) {
    return <AgentView userRoles={userRoles} />;
  }

  /**
   * Fallback
   */
  return <PinnedItemM6 />;
};

export const PinnedItemM6: FC = () => {
  const { data: transactionData } = useTransactionDetailsContext();
  const { companyName } = useContext(CompanyContext);
  const { signing } = transactionData?.transaction ?? {};
  const note: string | undefined = transactionData?.transaction?.milestones?.[4]?.data?.notes;

  const signingInfo = {
    signingDate: signing?.date,
    signingLocation: signing?.location,
    notaryName: signing?.notaryName,
    notaryPhone: signing?.notaryPhone,
    notaryProvider: signing?.notaryProvider,
    isRemote: signing?.isRemote,
    documentExpirationDate: signing?.documentExpirationDate,
  };

  let heading = 'Your signing appointment';
  let body = `We’re in the home stretch! If you need to make changes to your signing appointment, please message your ${companyName} team as soon as possible.`;
  let reminder = 'Remember to bring:';
  let reminderItemFirst = 'A valid government-issued ID';
  let reminderItemSecond = `Any additional documents requested from your ${companyName} team or lender`;

  if (signingInfo.isRemote) {
    const documentExpirationDate = signingInfo.documentExpirationDate
      ? formatDate(signingInfo.documentExpirationDate, WEEKDAY_MONTH_DAY)
      : '';

    heading = 'Schedule your remote signing appointment';
    body = `We’re in the home stretch! You will receive an email from ${
      signingInfo.notaryProvider
    } with a link to complete your signing appointment. Please complete your signing ${
      documentExpirationDate ? `before ${documentExpirationDate} to close ` : ''
    }on time.`;

    reminder = "What you'll need for signing:";
    reminderItemFirst = 'A secure internet connection and webcam';
    reminderItemSecond = 'A valid government-issued ID';
  }

  return (
    <DrawerSection>
      <Stack spacing="space70">
        <Box>
          <Heading data-test-id="pinned-item-heading" mb="space50" size="fontSize50">
            {heading}
          </Heading>
          <Text as="p" data-test-id="pinned-item-body">
            {body}
          </Text>
        </Box>
        {!signingInfo.isRemote && <SigningAppointmentBlock signingInfo={signingInfo} />}
        <Box>
          <Text as="p" data-test-id="pinned-item-reminder" fontWeight="semi" mb="space30">
            {reminder}
          </Text>
          <Box>
            <Text as="li" data-test-id="pinned-item-reminder-first">
              {reminderItemFirst}
            </Text>
            <Text as="li" data-test-id="pinned-item-reminder-second">
              {reminderItemSecond}
            </Text>
          </Box>
        </Box>
        {note && (
          <Box>
            <Text as="p" fontWeight="semi" mb="space30">
              Note from {companyName}
            </Text>
            <Text as="p" data-test-id="pinned-item-note">
              {note}
            </Text>
          </Box>
        )}
      </Stack>
    </DrawerSection>
  );
};

M6SigningVariant.displayName = 'M6SigningVariant';
PinnedItemM6.displayName = 'PinnedItemM6';
