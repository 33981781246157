import React, { FC, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Box,
  Text,
  ProgressBar,
} from '@endpoint/blockparty';
import { TodoSectionStatus } from '@endpoint/endpoint-bff-graphql-schema';

import { SectionList } from '../SectionList';
import { ProgressTrackerProps } from '../types';

export const MobileProgressTracker: FC<ProgressTrackerProps> = ({ progressTracker }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!progressTracker) return null;

  const { name, dueDate, estimatedTimeInMinutes, sectionData = [] } = progressTracker;
  const numCompletedSections = sectionData.filter((section) => section.status === TodoSectionStatus.COMPLETED).length;

  return (
    <Box
      bg="background"
      boxShadow={isExpanded ? '0px 3px 10px rgba(0, 0, 0, 0.15)' : ''}
      display={{ base: 'block', md: 'none' }}
    >
      <Accordion collapsible>
        <AccordionItem value="accordion-item-1" variant="ghost">
          <AccordionHeader
            iconRight
            iconRightName="ArrowChevronDown"
            pb="space0"
            px="space50"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Box textAlign="left">
              <Text as="p" color="passive" mb="space30" size="fontSize10">
                {`Due ${dueDate} | Est. time ${estimatedTimeInMinutes} mins`}
              </Text>
              <Text as="h1" id="to-do-name" size="fontSize30">
                To-Do: {name}
              </Text>
            </Box>
          </AccordionHeader>
          <Box pb="space50" pt="space40" px="space50">
            <ProgressBar aria-labelledby="to-do-name" completed={numCompletedSections} steps={sectionData.length} />
          </Box>
          <AccordionPanel hideDivider p="space0">
            <SectionList sections={sectionData} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
