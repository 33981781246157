import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Formik, Form } from 'formik';
import { FormikPasswordInputGroup, FormikInputGroup } from 'components/form/FormikBlockparty';
import * as Yup from 'yup';
import { Box, Heading, InputGroup, Stack, Text, Checkbox, Flex, Button, Link, Image } from '@endpoint/blockparty';
import { AppContext, AppContextType, useAppConfigsContext } from 'utils/context';
import { getSsoEmailMatchingConfigurations, isNonSsoEmail, isEnabledSsoEmail } from 'utils/singleSignOn';
import { CUSTOM_STORAGE_VAULT } from 'utils/auth/storage';
import { localStorageGetItem, localStorageSetItem } from 'utils/localStorageAccessor';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSignIn as useSignInV2 } from 'hooks/auth/useSignIn';
import { getOrganizationId } from 'utils/getOrganizationData';
import { useInitiateSSOMutation } from 'hooks/auth/SSO/useInitiateSSOMutation';

import Alerts from '../Alerts';
import { trackAction } from '../../../utils/analytics';
import {
  CheckboxCheckedTrackingPayload,
  LinkClickedTrackingPayload,
  SignInTrackingEvents,
  TrackingEventType,
} from '../../../consts/analytics';
import { RouteOptions } from '../../Utilities/Constant';
import { SignUpLink } from './SignUpLink';
import { useInputError } from '../../../hooks/useInputError';
import { signInEmailErrors } from '../../Utilities/helper';

interface ISignInFormValues {
  email: string;
  password: string;
}

const SignIn: FC = () => {
  const navigate = useNavigate();
  const { signIn: signInV2 } = useSignInV2();
  const { authFields, setAuthFields }: AppContextType = useContext(AppContext);
  const { ENABLE_AVENUE8_SSO } = useFeatureFlags();
  const { AWS_COGNITO_SSO_IDENTITY_PROVIDERS, NON_SSO_EMAILS, SSO_CALLBACK_URL } = useAppConfigsContext();
  const ssoEmailMatchers = getSsoEmailMatchingConfigurations(AWS_COGNITO_SSO_IDENTITY_PROVIDERS);
  const emailError = useInputError(signInEmailErrors);
  const [emailHasSsoDomain, setEmailHasSsoDomain] = useState<boolean>(false);
  const [isSsoFlowDisabled, setIsSsoFlowDisabled] = useState<boolean>(false);
  const { initiateSSO } = useInitiateSSOMutation();
  const [isKeepSignIn, setIsKeepSignIn] = useState<boolean>(true);
  const lockUrl = `${process.env.PUBLIC_URL}/assets/images/lock.svg`;
  const SIGN_UP_TEXT = 'Don’t have an account? ';
  const FORGOT_LOGIN_LINK_TEXT = 'Forgot email or password?';
  const KEEP_USER_SIGNED_IN_TEXT = 'Keep me signed in on this device';

  async function handleSSO(email: string) {
    const cognitoIdp = getCognitoIdentityProviderForEmail(email);

    return handleSSOInitiationFromUserService(cognitoIdp);
  }

  async function handleSSOInitiationFromUserService(cognitoIdp: string) {
    const [organizationId, callbackUrl] = await Promise.all([getOrganizationId(), SSO_CALLBACK_URL]);
    const idpUrl = await getIdpUrl(organizationId, cognitoIdp, callbackUrl);

    if (!idpUrl) {
      console.error(
        `External IdP with cognitoIdp ${cognitoIdp}, organization id ${organizationId} and callback url ${callbackUrl} doesn't exist.`,
      ); // TODO: coordinate with product what should happen when an idp url is not found
    }

    window.location.href = idpUrl!;
  }

  async function getIdpUrl(organizationId: number, identityProvider: string, callbackUrl: string) {
    const response = await initiateSSO({
      organizationId,
      identityProvider,
      callbackUrl,
    });

    return response.data?.initiateSSO.idpUrl;
  }

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('Please enter an email address in valid format, e.g. name@website.com')
      .required('Please enter your email address'),
    password: Yup.string()
      .trim()
      .when('email', {
        is: (email: string) =>
          isSsoFlowDisabled ||
          !isEnabledSsoEmail(AWS_COGNITO_SSO_IDENTITY_PROVIDERS, NON_SSO_EMAILS, email, ENABLE_AVENUE8_SSO),
        then: Yup.string().required('Please enter your password'),
      }),
  });

  const onSubmit = (values: ISignInFormValues) => {
    if (!isSsoFlowDisabled) {
      updateSsoState(values.email);
    }

    if (
      !isEnabledSsoEmail(AWS_COGNITO_SSO_IDENTITY_PROVIDERS, NON_SSO_EMAILS, values.email, ENABLE_AVENUE8_SSO) ||
      isSsoFlowDisabled
    ) {
      signInV2(values.email.trim().toLowerCase(), values.password.trim());
    }
  };

  const forgotLogin = () => {
    const trackForgotLoginProperties: LinkClickedTrackingPayload = {
      name: TrackingEventType.LINK_CLICKED,
      linkId: FORGOT_LOGIN_LINK_TEXT,
    };

    trackAction(SignInTrackingEvents.CLICKED_FORGOT_EMAIL_OR_PASSWORD, trackForgotLoginProperties);
    setAuthFields({ ...authFields, errorCode: null });
    navigate(RouteOptions.FORGOT_CREDENTIALS);
  };

  const keepSignedIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const trackingName = checked ? TrackingEventType.CHECKBOX_CHECKED : TrackingEventType.CHECKBOX_UNCHECKED;
    const trackKeepSignedInProperties: CheckboxCheckedTrackingPayload = {
      name: trackingName,
      checkboxId: KEEP_USER_SIGNED_IN_TEXT,
    };

    trackAction(SignInTrackingEvents.KEEP_USER_SIGNED_IN, trackKeepSignedInProperties);

    localStorageSetItem(CUSTOM_STORAGE_VAULT, checked.toString());
  };

  const getCognitoIdentityProviderForEmail = (email: string): string => {
    if (isNonSsoEmail(NON_SSO_EMAILS, email)) {
      return '';
    }

    return ssoEmailMatchers?.find((idp) => !!email.match(idp.emailMatchingRegex))?.cognitoIdp || '';
  };

  const updateSsoState = (email: string) => {
    setEmailHasSsoDomain(
      isEnabledSsoEmail(AWS_COGNITO_SSO_IDENTITY_PROVIDERS, NON_SSO_EMAILS, email, ENABLE_AVENUE8_SSO),
    );
  };

  const disableSsoFlow = () => {
    setIsSsoFlowDisabled(true);
  };

  const isSsoFlowActive = () => {
    return (emailHasSsoDomain && !isSsoFlowDisabled) ?? false;
  };

  useEffect(() => {
    const storedValue = localStorageGetItem(CUSTOM_STORAGE_VAULT);

    if (!storedValue) {
      return localStorageSetItem(CUSTOM_STORAGE_VAULT, 'true');
    }

    return setIsKeepSignIn(storedValue === 'true');
  }, []);

  return (
    <Formik
      initialValues={{ email: authFields.email, password: authFields.password }}
      validateOnBlur
      validationSchema={SignInSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, values }) => (
        <Form>
          <Box mb="space70" textAlign="center">
            <Heading as="h2" mb="space40" size="fontSize60">
              Sign in to your account
            </Heading>
            {!isSsoFlowActive() && (
              <Text>
                {SIGN_UP_TEXT}
                <SignUpLink color="blue500" />
              </Text>
            )}
          </Box>
          <Alerts />
          <Stack mb="space60" spacing="space60">
            <FormikInputGroup
              autoFocus
              error={emailError}
              label="Email Address"
              name="email"
              placeholder="e.g. you@website.com"
              type="email"
              onKeyUp={() => updateSsoState(values.email)}
            />

            {!isSsoFlowActive() && (
              <>
                <FormikPasswordInputGroup label="Password" name="password" placeholder="Your Password" />
                <Text>
                  <Link
                    color="blue500"
                    cursor="pointer"
                    dataTestId="forgot-creds-link"
                    textDecoration="underline"
                    onClick={forgotLogin}
                  >
                    {FORGOT_LOGIN_LINK_TEXT}
                  </Link>
                </Text>
              </>
            )}
          </Stack>
          {!isSsoFlowActive() && (
            <>
              <InputGroup display="flex" groupId="remember" mb="space80">
                <Checkbox isChecked={isKeepSignIn} name="keepSigned" onChange={keepSignedIn}>
                  {`${KEEP_USER_SIGNED_IN_TEXT} (not suggested for public or shared devices)`}
                </Checkbox>
              </InputGroup>

              <Flex justifyContent="center">
                <Button isDisabled={!(isValid && dirty)} type="submit" width="100%">
                  Sign In
                </Button>
              </Flex>
            </>
          )}
          {isSsoFlowActive() && (
            <>
              <Flex backgroundColor="carbon0" justifyContent="center" mb="space80" pb="space50" pt="space50">
                <Text>
                  <Image alt="lock" height={21} mr="space20" src={lockUrl} verticalAlign="middle" width={21} /> Single
                  Sign-On enabled for this email domain
                </Text>
              </Flex>
              <Flex justifyContent="center" mb="space60">
                <Button
                  dataTestId="sso-sign-in-button"
                  isDisabled={!(isValid && dirty)}
                  width="100%"
                  onClick={() => handleSSO(values.email.trim())}
                >
                  Sign In
                </Button>
              </Flex>
              <Flex justifyContent="center" mb="space60">
                <Text>
                  <Button
                    dataTestId="override-sso-link"
                    padding="space0"
                    style={{ fontWeight: 'normal' }}
                    variant="link"
                    variantColor="blue"
                    onClick={disableSsoFlow}
                  >
                    Sign in with password instead
                  </Button>
                  &nbsp;(for shared accounts)
                </Text>
              </Flex>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;
