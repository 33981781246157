export const HEADING_TEXT = 'Please verify your identity.';

export const SOCIAL_SECURITY_NUMBER_INPUT_TEST_ID = 'ssn-input';
export const SOCIAL_SECURITY_NUMBER_INPUT_LABEL = 'Last 4 Digits of Social Security Number';
export const SOCIAL_SECURITY_NUMBER_INPUT_NAME = 'lastFourSocial';
export const SOCIAL_SECURITY_NUMBER_INPUT_PLACEHOLDER = '####';

export const DATE_OF_BIRTH_INPUT_TEST_ID = 'dob-input';
export const DATE_OF_BIRTH_INPUT_LABEL = 'Date of Birth';
export const DATE_OF_BIRTH_INPUT_NAME = 'dateOfBirth';
export const DATE_OF_BIRTH_INPUT_PLACEHOLDER = 'MM/DD/YYYY';

export const STREET1_INPUT_TEST_ID = 'street1-input';
export const STREET1_INPUT_LABEL = 'Home Address';
export const STREET1_INPUT_NAME = 'street1';
export const STREET1_INPUT_PLACEHOLDER = 'e.g. 125 Main St';

export const STREET2_INPUT_TEST_ID = 'street2-input';
export const STREET2_INPUT_LABEL = 'Apt., suite, etc. (optional)';
export const STREET2_INPUT_NAME = 'street2';
export const STREET2_INPUT_PLACEHOLDER = 'e.g. Unit 500';

export const CITY_INPUT_TEST_ID = 'city-input';
export const CITY_INPUT_LABEL = 'City';
export const CITY_INPUT_NAME = 'city';
export const CITY_INPUT_PLACEHOLDER = 'e.g. Seattle';

export const STATE_INPUT_TEST_ID = 'state-input';
export const STATE_INPUT_LABEL = 'State';
export const STATE_INPUT_NAME = 'state';
export const STATE_INPUT_PLACEHOLDER = 'e.g. WA';

export const ZIP_INPUT_TEST_ID = 'zip-input';
export const ZIP_INPUT_LABEL = 'Zip';
export const ZIP_INPUT_NAME = 'zip';
export const ZIP_INPUT_PLACEHOLDER = 'e.g. 98010';
