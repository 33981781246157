import React, { FC, useContext } from 'react';
import { Stack, Flex } from '@endpoint/blockparty';
import { FormikInputGroup, FormikGroup, FormikSelectGroup, FormikTextArea } from 'components/form/FormikBlockparty';

import { SUPPORTED_STATE } from './constants';
import { OrderForm } from './types.d';
import { CompanyContext } from '../../../utils/context';

export const ParcelNumberForm: FC<OrderForm> = ({ counties, formikValue, onSelectChange }) => {
  const { companyName } = useContext(CompanyContext);
  const isAHC = companyName === 'AHC';
  const { state: selectedState } = formikValue;

  return (
    <Stack spacing="space60">
      <FormikInputGroup isRequired label="Parcel Number" name="parcelNumber" placeholder="e.g. 123456789" />
      <Flex>
        <FormikSelectGroup
          defaultValue={isAHC ? { label: 'WA', value: 'WA' } : { label: formikValue.state, value: formikValue.state }}
          disabled={isAHC}
          flex={1}
          isRequired
          isSearchable
          label="State"
          mr="space40"
          name="state"
          options={SUPPORTED_STATE}
          placeholder="Select"
          onSelectChange={onSelectChange}
        />
        <FormikSelectGroup
          defaultValue={{ label: formikValue.county, value: formikValue.county }}
          disabled={!isAHC && !selectedState}
          flex={1}
          isRequired
          isSearchable
          label="County"
          name="county"
          options={counties}
          placeholder="Select"
        />
      </Flex>
      <FormikGroup
        component={FormikTextArea}
        label="Anything else you’d like us to know? (optional)"
        name="additionalInformation"
        placeholder="e.g. I’m ordering this on behalf of my agent, Ella Fitzgerald, efitz@gmail.com"
      />
    </Stack>
  );
};
