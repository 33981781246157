import { StateOfOperation } from '@endpoint/endpoint-bff-graphql-schema';

export enum USState {
  AZ = 'AZ',
  CA = 'CA',
  TX = 'TX',
  WA = 'WA',
}

export const getSupportedUSState = (currentState?: StateOfOperation | USState) => {
  return currentState ? StateOfOperation[currentState] : undefined;
};
