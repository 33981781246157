import React, { FC } from 'react';
import { Box, SkeletonBlock } from '@endpoint/blockparty';

export const PinnedItemSkeleton: FC = () => {
  return (
    <>
      <Box bg="white" data-test-id="pinned-item-skeleton" mb="space20" p="space60">
        <SkeletonBlock height={16} maxWidth={212} mb="space60" />
        <SkeletonBlock height={16} maxWidth={427} mb="space40" />
        <SkeletonBlock height={12} maxWidth={416} mb="space40" />
        <SkeletonBlock height={12} maxWidth={244} mb="space40" />
      </Box>
      <Box bg="white" p="space60">
        <SkeletonBlock height={16} maxWidth={268} mb="space60" />
        <SkeletonBlock height={120} maxWidth={456} />
      </Box>
      <Box bg="white" p="space60">
        <SkeletonBlock height={16} maxWidth={268} mb="space60" />
        <SkeletonBlock height={120} maxWidth={456} />
      </Box>
      <Box bg="white" p="space60">
        <SkeletonBlock height={16} maxWidth={268} mb="space60" />
        <SkeletonBlock height={120} maxWidth={456} />
      </Box>
    </>
  );
};
