import React, { FC, useState } from 'react';
import { SubHeaderContainer } from 'components/SubHeader/SubHeaderContainer';
import { SubHeaderTodo } from 'components/SubHeader/SubHeaderTodo';
import { TwoColumnWithProgressContainer, Step } from 'components/form/TwoColumnWithProgressContainer';
import { useNavigate } from 'react-router-dom';
import { TRANSACTIONS } from 'consts/routes';
import { useCancelModal } from 'hooks/useCancelModal';
import { useAppContext } from 'utils/context';
import { OpenEscrowSkeleton } from 'components/Skeleton';
import { useAgentOnlyRoute } from 'hooks/useAgentOnlyRoute/useAgentOnlyRoute';

import { parcelFormValidationSchema, addressFormValidationSchema, orderMethodSchema } from './ValidationSchemas';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { OrderReportContextValue, OrderReportContext } from './Context';

const TOTAL_STEPS = 3;

const steps: Step[] = [
  {
    component: Step1,
    title: 'Select order method',
  },
  {
    component: Step2,
    title: 'Review',
  },
  {
    component: Step3,
    title: 'Order submitted',
  },
];

export interface FormikValue {
  orderMethod: string;
  propertyAddress: string;
  propertyAddress2?: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  parcelNumber: string;
  additionalInformation?: string;
}

const validationSchemas: { [key: string]: unknown } = {
  'By Parcel Number': parcelFormValidationSchema,
  'By Property Address': addressFormValidationSchema,
};

export const OrderReports: FC = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [reswareFileNumber, setReswareFileNumber] = useState('');
  const [orderMethod, setOrderMethod] = useState<string>('');
  const { onOpen, Modal } = useCancelModal({
    onConfirm: () => navigate(`/${TRANSACTIONS}`),
    cancelButtonText: 'Continue',
    confirmButtonText: 'Yes, Cancel',
  });

  const orderReportContextValue: OrderReportContextValue = {
    currentStep,
    setCurrentStep,
    reswareFileNumber,
    setReswareFileNumber,
    setOrderMethod,
  };

  const validationSchema = validationSchemas[orderMethod] || orderMethodSchema;
  const shouldSubHeaderButtonDisabled = currentStep === TOTAL_STEPS;

  const OrderReportsContent = useAgentOnlyRoute(
    <TwoColumnWithProgressContainer
      steps={steps}
      title="Order Pre-Listing Report"
      validationSchema={validationSchema}
    />,
    <OpenEscrowSkeleton />,
    user.loading,
  );

  return (
    <OrderReportContext.Provider value={orderReportContextValue}>
      {Modal}
      <SubHeaderContainer>
        <SubHeaderTodo buttonClick={onOpen} buttonLabel="Cancel" isButtonDisabled={shouldSubHeaderButtonDisabled} />
      </SubHeaderContainer>
      {OrderReportsContent}
    </OrderReportContext.Provider>
  );
};
