import { EmdWireTransferType, ToDoType } from '@endpoint/platform-api-connector/dist/graphql-types';
import { TransactionDetailsPayload } from 'routes/TransactionDetail/Todos/queries';
import { getContactBankingInfoByMarketId } from 'routes/TransactionDetail/Contacts/helpers';
import { formatCurrency } from 'utils/formatCurrency';
import * as Sentry from '@sentry/react';
import { formatPaymentAddress } from 'utils/formatPaymentAddress';
import { ProcessVariation } from 'consts/enums';

const US_TODO_TYPE_VALUES = [ToDoType.B_CONFIRM_FUNDS_WIRE_US.valueOf(), ToDoType.B_CONFIRM_EMD_WIRE_US.valueOf()];
const INT_TODO_TYPE_VALUES = [ToDoType.B_CONFIRM_FUNDS_WIRE_INT.valueOf(), ToDoType.B_CONFIRM_EMD_WIRE_INT.valueOf()];

const getIsUSBank = ({
  emdWireTransferType,
  todoSchemaKey,
}: {
  emdWireTransferType?: EmdWireTransferType;
  todoSchemaKey?: ToDoType;
}) => {
  const todoSchemaKeyUpperCase = todoSchemaKey?.toUpperCase() ?? '';

  if (US_TODO_TYPE_VALUES.includes(todoSchemaKeyUpperCase)) {
    return true;
  }

  if (INT_TODO_TYPE_VALUES.includes(todoSchemaKeyUpperCase)) {
    return false;
  }

  if (emdWireTransferType === EmdWireTransferType.US) {
    return true;
  }

  return false;
};

export const formatWireTransferData = (transactionData: TransactionDetailsPayload, todoSchemaKey?: ToDoType) => {
  const { emd, fileNum, participants, property, marketId, processVariation } = transactionData!.transaction;
  const isUnityTransaction = processVariation === ProcessVariation.UNITY;
  const todoSchemaKeyUpperCase = todoSchemaKey?.toUpperCase() ?? '';
  const isWireTransferTodoSchemaType = [...US_TODO_TYPE_VALUES, ...INT_TODO_TYPE_VALUES].includes(
    todoSchemaKeyUpperCase,
  );

  if (!emd?.wireTransferType && !isWireTransferTodoSchemaType) {
    Sentry.captureEvent({
      level: Sentry.Severity.Error,
      message: `formatWireTransferData error -- WireTransferType is Null and todoSchemaKey is Not for Wire Transfer. Transaction Info: ${JSON.stringify(
        transactionData,
      )}`,
    });
  }

  const isUsBank = getIsUSBank({ emdWireTransferType: emd?.wireTransferType!, todoSchemaKey });
  // @ts-ignore
  const paymentAmount = formatCurrency(emd?.amount);
  const { address } = property;

  const contactInfo = getContactBankingInfoByMarketId(marketId?.valueOf(), isUnityTransaction);
  const { paymentStreet, paymentSuite, paymentCityStateZip } = formatPaymentAddress(contactInfo);

  return {
    address,
    fileNum,
    isUsBank,
    participants,
    paymentAmount,
    paymentStreet,
    paymentSuite,
    paymentCityStateZip,
  };
};
