import React, { FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Text } from '@endpoint/blockparty';
import { AlertMessage } from 'components/AlertMessage';
import { TransactionSkeleton } from 'components/Skeleton';
import { isAgent, useAppContext } from 'utils/context';
import { useIsSupportedOrderReportState } from 'hooks/useIsSupportedOrderReportState';
import { useGetPreListingTransactions } from 'hooks/useGetPreListingTransactions';

import { NoTransactions } from '../NoTransactions';
import { TransactionCard } from '../TransactionCard';
import { TransactionGrid } from '../TransactionGrid';

export const Listing: FC = () => {
  const { data, loading, error } = useGetPreListingTransactions();
  const { user } = useAppContext();
  const navigate = useNavigate();
  const hasNoTransactions = data?.myTransactions.length === 0 || data?.myTransactions === null;
  const isOrderReportSupported = useIsSupportedOrderReportState(user);

  useEffect(() => {
    if (!isAgent(user)) {
      navigate('/transactions/in-escrow');
    }
  }, [navigate, user, user.group]);

  if (loading) {
    return (
      <TransactionGrid>
        <TransactionSkeleton />
      </TransactionGrid>
    );
  }

  if (error) {
    return (
      <Box m="space50">
        <AlertMessage>Cannot load Listing transactions</AlertMessage>
      </Box>
    );
  }

  if (hasNoTransactions) {
    return (
      <NoTransactions
        description="Once an order has been submitted, it will appear here."
        title="There are no orders yet"
      >
        {!isAgent(user) && isOrderReportSupported && (
          <Text as="p" color="carbon500" data-test-id="order-report-quick-link">
            If you’d like to order a pre-listing report, click{' '}
            <Link color="blue500" to="/order-reports">
              <Text color="blue500">Order Reports</Text>
            </Link>{' '}
            to get started
          </Text>
        )}
      </NoTransactions>
    );
  }

  return (
    <TransactionGrid>
      {data?.myTransactions.map((transaction) => {
        return (
          <Box key={transaction.id} bg="background" textDecoration="none" width="100%">
            <TransactionCard {...transaction} />
          </Box>
        );
      })}
    </TransactionGrid>
  );
};
