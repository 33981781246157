import React, { FC, Fragment, useEffect, useState } from 'react';
import Cleave from 'cleave.js/react';
import { useParams } from 'react-router';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useTransactionParticipants } from 'hooks/useTransactionParticipants';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { ErrorLoadingStatesWidget } from 'routes/TransactionDetail/Todo/TodoStep/TodoStepComponent/ErrorLoadingStatesWidget';
import { Box, Divider, Flex, Input, Stack, Text } from '@endpoint/blockparty';
import { getTotalPercent } from 'utils/getTotalPercent';

interface TodoFixedSumProps {
  field: FieldOptions;
}

export const TodoFixedSum: FC<TodoFixedSumProps> = ({ field }) => {
  const { transactionId = '' } = useParams();
  const { data: buyers, error, loading } = useTransactionParticipants(transactionId, [TransactionRole.BUYER]);
  const [total, setTotal] = useState<number>(0);
  const { id, answer, placeHolder } = field;
  const { setFieldTouched, setFieldValue, touched, values } = useFormikContext<GenericObject>();
  const isTotalValid = total === 100;
  const maskOptions = {
    numeral: true,
    numeralIntegerScale: 3,
    numeralThousandsGroupStyle: 'thousand',
  };

  useEffect(() => {
    if (answer) {
      const totalPercent = getTotalPercent(answer);

      for (const [buyer, value] of Object.entries(answer)) {
        setFieldValue(`${id}.${buyer}`, value);
      }

      setTotal(totalPercent);
    }
  }, [id, answer, setFieldValue]);

  useEffect(() => {
    if (!values[id] && !answer) {
      buyers.forEach((buyer) => {
        setFieldValue(`${id}.${buyer}`, '0');
      });
    }
  }, [id, answer, buyers, setFieldValue, values]);

  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>, buyer: string) => {
    const totalPercent = getTotalPercent({ ...values[field.id], [buyer]: value });

    setFieldValue(`${id}.${buyer}`, value);
    setTotal(totalPercent);
  };

  if (error || loading) {
    return <ErrorLoadingStatesWidget error={error} loading={loading} />;
  }

  return (
    <>
      <Stack mb="space50" spacing="space50">
        {buyers.map((buyer) => {
          const buyerField = `${id}.${buyer}`;
          const isFieldTouched = touched[id] as GenericObject;
          const hasError = isFieldTouched?.[buyer] && !isTotalValid;

          return (
            <Fragment key={buyer}>
              <Flex alignItems="center">
                <Text data-test-id={buyer} flex={1}>
                  {buyer}
                </Text>
                <Box width="100px">
                  <Field name={buyerField}>
                    {({ field: formikField }: FieldProps) => {
                      return (
                        <Input
                          {...formikField}
                          as={Cleave}
                          data-test-id={`${buyer}-shares`}
                          isInvalid={hasError}
                          name={buyerField}
                          options={maskOptions}
                          placeholder={placeHolder}
                          textAlign="right"
                          onBlur={() => setFieldTouched(buyerField, true)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChange(event, buyer)}
                        />
                      );
                    }}
                  </Field>
                </Box>
                <Text ml="space30">%</Text>
              </Flex>
              <Divider />
            </Fragment>
          );
        })}
      </Stack>
      <Flex alignItems="flex-end" flexDirection="column">
        <Text as="p" color={!isTotalValid ? 'watermelon500' : null} data-test-id="fixed-sum-total" size="fontSize40">
          {total}%
        </Text>
        <Text as="p" color="carbon500" size="fontSize20">
          of 100%
        </Text>
      </Flex>
    </>
  );
};
