import React, { FC } from 'react';
import { Box, EPContainer, EPContent, EPSidebar } from '@endpoint/blockparty';

import { PropertyDetailsSkeleton } from './PropertyDetailsSkeleton';
import { ProgressTrackerSkeleton } from './ProgressTrackerSkeleton';
import { TodoListSkeleton } from './TodoListSkeleton';

export const TransactionDetailSkeleton: FC = () => (
  <EPContainer>
    <EPSidebar>
      <Box mb="space60">
        <PropertyDetailsSkeleton />
      </Box>
      <ProgressTrackerSkeleton />
    </EPSidebar>
    <EPContent>
      <TodoListSkeleton />
    </EPContent>
  </EPContainer>
);
