import { TransactionType, Transaction } from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatCurrency } from 'utils/formatCurrency';
import { displayDateStatus } from 'utils/transactions';
import { User } from 'utils/context';
import { getTransactionRole } from 'utils/transactions/getTransactionRole';

export const getPropertyDetails = ({ transaction, userId }: { transaction: Transaction; userId: User['id'] }) => {
  const { fileNum, price, loanAmount, type, dates } = transaction;
  const { estimatedClosing, cancellationDate } = dates;
  const respectiveParticipant =
    transaction.type === TransactionType.EQUITY
      ? {
          label: '',
          value: '',
        }
      : getTransactionRole({ transaction, userId });

  return [
    respectiveParticipant,
    {
      label: 'File #',
      value: fileNum,
    },
    { ...displayLoanOrPriceAmount(type, price, loanAmount) },
    // estimatedClosing should always be displayed, but is conditionally
    // rendered if bad data is given in non-prod environments
    { ...((estimatedClosing || cancellationDate) && displayDateStatus(transaction)) },
  ];
};

export const displayLoanOrPriceAmount = (
  type: Transaction['type'],
  price: Transaction['price'],
  loanAmount: Transaction['loanAmount'],
) => {
  const isTransactionTypeRefiOrEquity = type === TransactionType.REFINANCE || type === TransactionType.EQUITY;
  const loanOrPriceLabel = isTransactionTypeRefiOrEquity ? 'Loan' : 'Price';
  const fallbackToPrice = isTransactionTypeRefiOrEquity && !loanAmount;

  const loanOrPriceValue = !isTransactionTypeRefiOrEquity || fallbackToPrice ? price : loanAmount;
  const value = loanOrPriceValue ? formatCurrency(loanOrPriceValue) : 'N/A';

  return {
    label: `${loanOrPriceLabel}:`,
    value,
  };
};
