import React, { FC } from 'react';
import { Box, Flex, SkeletonBlock } from '@endpoint/blockparty';

interface TransactionSkeletonProps {
  count?: number;
}

export const TransactionSkeleton: FC<TransactionSkeletonProps> = ({ count = 5 }) => {
  return (
    <>
      {[...Array(count)].map((_item, i) => (
        // eslint-disable-next-line
        <Box bg="white" key={i} data-test-id="transaction-skeleton-item">
          <Box>
            <SkeletonBlock borderRadius="none" height={112} maxWidth="none" />
          </Box>

          <Flex flexDirection="column" pb="20px" pt="space30" px="space50">
            <SkeletonBlock height={16} maxWidth={128} mb="space30" />
            <SkeletonBlock height={12} maxWidth={231} mb="30px" />
            <SkeletonBlock height={12} maxWidth={201} mb="space30" />
            <SkeletonBlock height={12} maxWidth={187} mb="space30" />
            <SkeletonBlock height={12} maxWidth={187} mb="space30" />
            <SkeletonBlock height={12} maxWidth={187} mb="26px" />
            <SkeletonBlock height={4} maxWidth="none" mb="space40" />
            <SkeletonBlock height={12} maxWidth={231} />
          </Flex>
        </Box>
      ))}
    </>
  );
};
