import { partition } from 'lodash';
import { TransactionsPayload } from 'routes/Transactions/queries';
import { isClosingDateAvailable, sortByEstimatedClosing } from 'utils/transactions/sortingTransactionHelpers';

export function closedTransactionSorter(transactionPayload: TransactionsPayload): TransactionsPayload {
  const [transactionsWithEstimatedClosing, transactionsWithNoEstimatedClosing] = partition(
    transactionPayload.myTransactions,
    isClosingDateAvailable,
  );

  return {
    ...transactionPayload,
    myTransactions: [
      ...transactionsWithNoEstimatedClosing,
      ...sortByEstimatedClosing(transactionsWithEstimatedClosing),
    ],
  };
}
