import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { TransactionParticipant, TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { getAllParticipantsNameByRoles } from 'utils/transactions/getAllParticipantsNameByRoles';

const GET_PARTICIPANTS = gql`
  query getTransactionParticipants($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      participants {
        roles
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const useTransactionParticipants = (transactionId: string, roles: TransactionRole[] = []) => {
  const { loading, error, data } = useQuery<ParticipantsPayload>(GET_PARTICIPANTS, {
    variables: { where: { id: transactionId } },
  });

  const participants = getAllParticipantsNameByRoles(data?.transaction?.participants, roles);

  return {
    data: participants,
    loading,
    error,
  };
};

export interface ParticipantsPayload {
  transaction: {
    participants: TransactionParticipant[];
  };
}
