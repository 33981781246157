export const PLACEHOLDER_MLS =
  'https://apex-static-assets.s3-us-west-2.amazonaws.com/EP-App/Illustration_NoMLS_placeholder.svg';

export const AHC_DESCRIPTION = 'Access Home Closing';
export const AHC_MANIFEST = 'assets/manifests/ahc/manifest.json';
export const ENDPOINT_DESCRIPTION = 'Endpoint Closing';
export const ENDPOINT_MANIFEST = 'assets/manifests/endpoint/manifest.json';
export const OPS_TEST_DESCRIPTION = 'Ops Test';
export const OPS_TEST_MANIFEST = 'assets/manifests/opstest/manifest.json';
export const QE_DESCRIPTION = 'QE Test Organization';
export const QE_MANIFEST = 'assets/manifest/qetestorg/manifest.json';
export const QE_DESCRIPTION2 = 'QE Test Organization2';
export const QE_MANIFEST2 = 'assets/manifest/qetestorg2/manifest.json';
export const FAVICON_16_ID = 'favicon16';
export const FAVICON_32_ID = 'favicon32';
export const FAVICON_48_ID = 'favicon48';
export const FAVICON_180_ID = 'favicon180';
export const FAVICON_192_ID = 'favicon192';
