import { theme } from './endpoint';

export const opsTestTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    navbarLink: '#fff',
    navbarUnderline: '#fff',
    progressBarSignUp: '#fff',
    shellBgHeader: '#D600BA', // brandPrimary500
    brandPrimary0: '#FCE3F9',
    brandPrimary100: '#FAD1F5',
    brandPrimary200: '#F6B1ED',
    brandPrimary300: '#F183E3',
    brandPrimary400: '#EC51D7',
    brandPrimary500: '#D600BA',
    brandPrimary600: '#990085',
    brandPrimary700: '#6B005D',
    brandPrimary800: '#4D0042',
    brandPrimary900: '#33002C',
  },
  config: {
    ...theme.config,
    organization: 'Ops',
  },
};
