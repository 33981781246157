import * as Yup from 'yup';

export const profileEditValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Please enter an email address in valid format, e.g. name@website.com')
    .required('Email is required'),
  phone: Yup.string()
    .transform((value: string) => {
      // Remove non-numerics and validates against new value
      const newValue = value.replace(/\D/g, '');

      return newValue;
    })
    .min(10, 'Please enter your 10-digit phone number')
    .required('Phone number is required'),
});
