import React, { useContext } from 'react';
import { Box } from '@endpoint/blockparty';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';

import { AppContext, AppContextType } from '../../../utils/context';
import { OfflineErrorOverlay } from '../../../components/Error/Offline';

const SignUpRight = ({ children, ...props }: BoxProps) => {
  const { isOnline }: AppContextType = useContext(AppContext);

  return (
    <Box
      maxWidth={{ base: 'initial', md: '583px' }}
      mb={{ base: 'space90', md: 'space80' }}
      mt={{ base: 'space70', md: 'space90' }}
      mx={{ base: 'space60', sm: 'space70', md: 'space80' }}
      overflowY={{ base: 'auto', md: 'initial' }}
      position="relative"
      {...props}
    >
      {!isOnline && <OfflineErrorOverlay styles={{ backgroundColor: '#FFFFFF' }} />}
      {children}
    </Box>
  );
};

export default SignUpRight;
