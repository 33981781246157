import { Market } from '@endpoint/endpoint-bff-graphql-schema';

export function getDistinctMarket(markedIds: (number | undefined)[], markets: Market[]): string | undefined {
  if (markedIds) {
    const distinctMarkets: string[] = [];

    markedIds.forEach((marketId) => {
      const market = getZendeskTagByMarketId(markets, marketId?.valueOf());

      if (market && distinctMarkets.indexOf(market) < 0) {
        distinctMarkets.push(market);
      }
    });

    if (distinctMarkets.length === 1) {
      return distinctMarkets[0];
    }
  }

  return undefined;
}

/**
 * Find the market with the given id and return its zendeskTag property.
 * @param {Market[]} markets - Market[] - this is the array of markets that you get from the API.
 * @param {number | undefined} marketId - The market ID of the market you want to get the Zendesk tag
 * for.
 * @returns A string that represents zendesk tag
 */
export function getZendeskTagByMarketId(markets: Market[], marketId: number | undefined) {
  return markets.find((m) => m.id === marketId)?.zendeskTag;
}
