import React, { FC, useContext } from 'react';
import { Stack, Flex } from '@endpoint/blockparty';
import { FormikInputGroup, FormikGroup, FormikSelectGroup, FormikTextArea } from 'components/form/FormikBlockparty';

import { OrderForm } from './types.d';
import { SUPPORTED_STATE } from './constants';
import { CompanyContext } from '../../../utils/context';

export const PropertyAddressForm: FC<OrderForm> = ({ counties, formikValue, onSelectChange }) => {
  const { companyName } = useContext(CompanyContext);
  const isAHC = companyName === 'AHC';
  const { state: selectedState, county } = formikValue;

  return (
    <Stack spacing="space60">
      <FormikInputGroup
        autoComplete="off"
        isRequired
        label="Property Address"
        name="propertyAddress"
        placeholder="e.g. 123 Main St"
      />
      <FormikInputGroup
        autoComplete="off"
        label="Apt., suite, etc. (optional)"
        name="propertyAddress2"
        placeholder="e.g. Apt 456"
      />
      <FormikInputGroup autoComplete="off" isRequired label="City" name="city" placeholder="e.g. Seattle" />
      <Flex>
        <FormikSelectGroup
          autoComplete="off"
          defaultValue={isAHC ? { label: 'WA', value: 'WA' } : { label: selectedState, value: selectedState }}
          disabled={isAHC}
          flex={1}
          isRequired
          isSearchable
          label="State"
          mr="space40"
          name="state"
          options={SUPPORTED_STATE}
          placeholder="Select"
          onSelectChange={onSelectChange}
        />
        <FormikInputGroup
          autoComplete="off"
          flex={1}
          isRequired
          label="Zip"
          mask={{ numericOnly: true, blocks: [5, 4], delimiters: ['-'], delimiterLazyShow: true }}
          name="zip"
          placeholder="e.g. 12345"
        />
      </Flex>
      <FormikSelectGroup
        defaultValue={{ label: county, value: county }}
        disabled={!isAHC && !selectedState}
        isRequired
        isSearchable
        label="County"
        name="county"
        options={counties}
        placeholder="Select"
      />
      <FormikGroup
        component={FormikTextArea}
        label="Anything else you’d like us to know? (optional)"
        name="additionalInformation"
        placeholder="e.g. I’m ordering this on behalf of my agent, Ella Fitzgerald, efitz@gmail.com"
      />
    </Stack>
  );
};
