import React, { FC } from 'react';
import { Box } from '@endpoint/blockparty';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';

export const AnchoredButton: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      bg="background"
      bottom="0"
      boxShadow={{ base: 'medium', md: 'none' }}
      display={{ base: 'block', md: 'flex' }}
      flex={1}
      justifyContent="flex-end"
      left="0"
      mt={{ base: 'space0', md: 'space80' }}
      position={{ base: 'fixed', md: 'initial' }}
      px={{ base: 'space50', md: 'space0' }}
      py={{ base: 'space30', md: 'space0' }}
      right="0"
      width="100%"
      zIndex="sticky"
      {...props}
    >
      {children}
    </Box>
  );
};
