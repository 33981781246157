import React, { useMemo } from 'react';
import {
  Modal,
  ModalContent,
  ModalSection,
  Stack,
  ModalFooter,
  Button,
  Text,
  useDisclosure,
} from '@endpoint/blockparty';

interface UseModalProps {
  textPrompt: string;
  secondaryTextPrompt: string;
  onConfirm: () => void;
  cancelButtonText: string;
  confirmButtonText: string;
}

export const useModal = ({
  textPrompt,
  secondaryTextPrompt,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
}: UseModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const wrapperView = useMemo(() => {
    return (
      <Modal id="todo-forms-modal" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalSection>
            <Stack spacing="space40">
              <Text fontWeight="semi" size="fontSize30">
                {textPrompt}
              </Text>
              <Text>{secondaryTextPrompt}</Text>
            </Stack>
          </ModalSection>
          <ModalFooter>
            <Button mr="space50" variant="outline" onClick={onClose}>
              {cancelButtonText}
            </Button>
            <Button
              onClick={() => {
                onClose();
                onConfirm();
              }}
            >
              {confirmButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }, [onClose, isOpen, onConfirm, cancelButtonText, confirmButtonText, textPrompt, secondaryTextPrompt]);

  return { openModal: onOpen, Modal: wrapperView };
};
