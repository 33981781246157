import {
  TransactionSummaryParticipant,
  TransactionSummaryParticipantRole,
} from '@endpoint/endpoint-bff-graphql-schema';

import { TransactionSummaryCardDetails } from '..';

export const getTransactionRole = ({ transaction, userId }: TransactionSummaryCardDetails) => {
  const { participants } = transaction;

  const matchedUser = participants.find((participant) => participant.contactId === userId);

  const matchedUserRole = matchedUser?.roles[0];

  let transactionUserRole = 'Buyer:';
  let transactionUserName = 'Not available yet';

  if (matchedUserRole) {
    transactionUserRole = getUserRole(matchedUserRole);
    transactionUserName = getUserName(participants, matchedUserRole);
  }

  return {
    label: transactionUserRole,
    value: transactionUserName,
  };
};

const roleMapping: { [key in TransactionSummaryParticipantRole]: string } = {
  [TransactionSummaryParticipantRole.BUYER]: 'Agent:',
  [TransactionSummaryParticipantRole.SELLER]: 'Agent:',
  [TransactionSummaryParticipantRole.LISTING_AGENT_TRANSACTION_COORDINATOR]: 'Agent:',
  [TransactionSummaryParticipantRole.SELLING_AGENT_TRANSACTION_COORDINATOR]: 'Agent:',
  [TransactionSummaryParticipantRole.SELLING_AGENT]: 'Buyer:',
  [TransactionSummaryParticipantRole.LISTING_AGENT]: 'Seller:',
  [TransactionSummaryParticipantRole.BORROWER]: 'M. Broker:',
  [TransactionSummaryParticipantRole.LOAN_OFFICER]: 'Borrower:',
  [TransactionSummaryParticipantRole.MORTGAGE_BROKER]: 'Borrower:',
  [TransactionSummaryParticipantRole.LOAN_PROCESSOR]: '',
  [TransactionSummaryParticipantRole.ESCROW_AGENT]: '',
  [TransactionSummaryParticipantRole.PROPTECH_PARTNER]: '',
  [TransactionSummaryParticipantRole.PROPTECH_BUYER]: '',
  [TransactionSummaryParticipantRole.PROPTECH_SELLER]: '',
  [TransactionSummaryParticipantRole.BUYER_API]: '',
  [TransactionSummaryParticipantRole.SELLER_API]: '',
  [TransactionSummaryParticipantRole.SETTLEMENT_COMPANY]: '',
  [TransactionSummaryParticipantRole.CLOSING_SPECIALIST]: '',
};

export const getUserRole = (role: TransactionSummaryParticipantRole) => {
  return roleMapping[role] || 'Buyer:';
};

const getUserName = (participants: TransactionSummaryParticipant[], role: TransactionSummaryParticipantRole) => {
  switch (role) {
    case TransactionSummaryParticipantRole.BUYER:
    case TransactionSummaryParticipantRole.SELLING_AGENT_TRANSACTION_COORDINATOR:
      return findRespectiveParticipants(participants, TransactionSummaryParticipantRole.SELLING_AGENT);
    case TransactionSummaryParticipantRole.SELLER:
    case TransactionSummaryParticipantRole.LISTING_AGENT_TRANSACTION_COORDINATOR:
      return findRespectiveParticipants(participants, TransactionSummaryParticipantRole.LISTING_AGENT);
    case TransactionSummaryParticipantRole.SELLING_AGENT:
      return findRespectiveParticipants(participants, TransactionSummaryParticipantRole.BUYER);
    case TransactionSummaryParticipantRole.LISTING_AGENT:
      return findRespectiveParticipants(participants, TransactionSummaryParticipantRole.SELLER);
    case TransactionSummaryParticipantRole.BORROWER:
      return findRespectiveParticipants(participants, TransactionSummaryParticipantRole.MORTGAGE_BROKER);
    case TransactionSummaryParticipantRole.LOAN_OFFICER:
    case TransactionSummaryParticipantRole.MORTGAGE_BROKER:
      return findRespectiveParticipants(participants, TransactionSummaryParticipantRole.BORROWER);
    default:
      return 'Not available yet';
  }
};

export const findRespectiveParticipants = (
  participants: TransactionSummaryParticipant[],
  expectedRole: TransactionSummaryParticipantRole,
) => {
  const foundParticipants = participants.filter((participant) => participant?.roles?.includes(expectedRole));

  const participantNames = foundParticipants.length
    ? foundParticipants
        .map((participant) => {
          const { firstName, lastName } = participant || {};

          return firstName && lastName ? `${firstName} ${lastName}` : 'Name not available';
        })
        .join(', ')
    : 'Not available yet';

  return participantNames;
};
