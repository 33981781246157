import { MutationHookOptions, OperationVariables, useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { CreateTransactionDocumentInput, Document } from '@endpoint/endpoint-bff-graphql-schema';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';

import { CREATE_TRANSACTION_DOCUMENT } from './mutations';
import { useSentryTransaction } from '../useSentryTransaction';

export const useCreateTransactionDocument = (config: MutationHookOptions) => {
  const sentryTransaction = useSentryTransaction('useCreateDocument', 'GraphQL client mutation');

  const [
    createTransactionDocumentMutation,
    {
      data: createTransactionDocumentResult,
      loading: createTransactionDocumentLoading,
      error: createTransactionDocumentError,
    },
  ] = useMutation<{ createTransactionDocument: Document; input: CreateTransactionDocumentInput }, OperationVariables>(
    CREATE_TRANSACTION_DOCUMENT,
    config,
  );

  const createTransactionDocument = async (input: CreateTransactionDocumentInput): Promise<Document | undefined> => {
    const result = await createTransactionDocumentMutation({
      variables: { input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
      },
    });

    return result?.data?.createTransactionDocument;
  };

  if ((createTransactionDocumentResult || createTransactionDocumentError) && !createTransactionDocumentLoading) {
    sentryTransaction.finish();
  }

  return {
    createTransactionDocument,
    createTransactionDocumentResult,
    createTransactionDocumentLoading,
    createTransactionDocumentError,
  };
};
