import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { gql, useMutation } from '@apollo/client';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { ConfirmSignInInput, AuthResponse } from '@endpoint/endpoint-bff-graphql-schema';

export const CONFIRM_SIGN_IN_MUTATION = gql`
  mutation ConfirmSignIn($input: ConfirmSignInInput!) {
    confirmSignIn(input: $input) {
      session
      authentication {
        accessToken
        idToken
      }
    }
  }
`;

export const useConfirmSignInMutation = () => {
  const sentryTransaction = useSentryTransaction('confirmSignIn', 'GraphQL client mutation');

  const [
    confirmSignInMutation,
    { data: confirmSignInResult, loading: confirmSignInLoading, error: confirmSignInError },
  ] = useMutation<{ confirmSignIn: AuthResponse }, { input: ConfirmSignInInput }>(CONFIRM_SIGN_IN_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  const confirmSignIn = (input: ConfirmSignInInput) => {
    return confirmSignInMutation({
      variables: { input },
    });
  };

  if ((confirmSignInResult || confirmSignInError) && !confirmSignInLoading) {
    sentryTransaction.finish();
  }

  return {
    confirmSignIn,
    confirmSignInResult,
    confirmSignInLoading,
    confirmSignInError,
  };
};
