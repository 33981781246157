import React, { FC, ReactElement, useState, FormEventHandler } from 'react';
import {
  DrawerActionBar,
  DrawerContent,
  Drawer,
  DrawerSection,
  Stack,
  Text,
  Flex,
  Button,
  DrawerFooter,
} from '@endpoint/blockparty';
import { Formik } from 'formik';
import { string, object, array } from 'yup';
import { UploadField } from 'components/form/UploadField';
import { FormikSelectGroup } from 'components/form/FormikBlockparty';
import { Multiple } from 'components/form/Multiple';
import { documentTypeOptions, initialValues, DocumentUploadValuesUnclean } from 'consts/uploadDocument';

import { checkFileFieldIsEmpty, hasAllFilesFinishedUploading } from '../../uploadDocuments';

interface DrawerProps {
  handleReset: Function;
  handleSubmit: FormEventHandler<HTMLFormElement> | undefined;
  isSubmitting: boolean;
  values: DocumentUploadValuesUnclean;
  isValid: boolean;
}

interface DocumentUploadDrawerProps {
  handleUploadSubmit(transactionId: string, rawData: DocumentUploadValuesUnclean): Promise<any>;
  isOpen: boolean;
  onClose(): void;
  transactionId: string;
}

export const DocumentUploadDrawer: FC<DocumentUploadDrawerProps> = ({
  handleUploadSubmit,
  isOpen,
  onClose,
  transactionId,
}) => {
  const [uploadError, setUploadError] = useState(false);

  return (
    <Drawer data-test-id="icon-help-drawer" id="icon-help-drawer" isOpen={isOpen} onClose={onClose}>
      <DrawerContent>
        <DrawerActionBar />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleUploadSubmit(transactionId, values as DocumentUploadValuesUnclean)
              .then(() => {
                onClose();
              })
              .catch(() => {
                setUploadError(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ handleReset, handleSubmit, isSubmitting, values, isValid }: DrawerProps): ReactElement => {
            // formik's dirty check doesn't work well with objects that contain nested objects
            // it immediately senses that the objects aren't equal so this valuesChanged check takes its place
            const valuesChanged: boolean =
              Object.keys(values.files[0].file).length !== 0 || values.files[0].documentType !== '';
            // manual validation is required because file upload response has a fileId as a key
            const isFileMissing = checkFileFieldIsEmpty(values);
            const allFilesUploaded = hasAllFilesFinishedUploading(values);

            return (
              <Flex
                as="form"
                data-test-id="doc-upload-drawer"
                flex={1}
                flexDirection="column"
                overflow="auto"
                onSubmit={handleSubmit}
              >
                <Flex flex={1} flexDirection="column">
                  <DrawerSection>
                    <Stack spacing="space40">
                      <Text as="h3" fontWeight="semi" size="fontSize60">
                        Upload a Document
                      </Text>
                      <Text>
                        You can select one or more documents to upload by clicking or dragging your file into the area
                        below (dragging is not availabe for mobile devices).
                      </Text>
                      <Text>Maximum upload file size 50MB. PDF or JPEG only</Text>

                      {uploadError && (
                        <Text color="watermelon500" fontWeight="semi">
                          Something went wrong, please try again later.
                        </Text>
                      )}
                    </Stack>
                  </DrawerSection>
                  <DrawerSection>
                    <Multiple fieldSpacing="space30" hideDivider hideTitle name="files" title="Another Document">
                      {(prefix: string) => (
                        <Stack spacing="space20">
                          <UploadField
                            allowMultiple={false}
                            name={`${prefix}.file`}
                            transactionId={transactionId}
                            useGrayUploadSuccess
                          />
                          <Text color="carbon500" size="fontSize10">
                            Document Type (required)
                          </Text>
                          <FormikSelectGroup
                            isClearable={false}
                            isRequired
                            name={`${prefix}.documentType`}
                            options={documentTypeOptions}
                            placeholder="Select document type"
                          />
                        </Stack>
                      )}
                    </Multiple>
                  </DrawerSection>
                </Flex>
                <DrawerFooter>
                  <Button
                    data-test-id="upload-cancel"
                    isDisabled={isSubmitting}
                    mr="space30"
                    type="reset"
                    variant="outline"
                    onClick={(): void => {
                      handleReset();
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-test-id="upload-submit"
                    isDisabled={isSubmitting || !valuesChanged || !isValid || !allFilesUploaded || isFileMissing}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Upload
                  </Button>
                </DrawerFooter>
              </Flex>
            );
          }}
        </Formik>
      </DrawerContent>
    </Drawer>
  );
};

export const validationSchema = object().shape({
  files: array().of(
    object().shape({
      documentType: string().required('Please select one document type'),
      // eslint-disable-next-line react/forbid-prop-types
      file: object(),
    }),
  ),
});

DocumentUploadDrawer.displayName = 'DocumentUploadDrawer';
UploadField.displayName = 'UploadField';
