import React, { FC } from 'react';
import { Flex, Text } from '@endpoint/blockparty';

interface ContactProps {
  title: string;
  name: string;
}

export const Contact: FC<ContactProps> = ({ title, name }) => {
  return (
    <Flex key={name} justifyContent="space-between">
      <Text color="passive" data-test-id="contact-title" flex={1}>
        {title}
      </Text>
      <Text data-test-id="contact-name" flex={{ base: 1, md: 2 }} textTransform="capitalize">
        {name}
      </Text>
    </Flex>
  );
};
