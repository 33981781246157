import React, { FC } from 'react';
import { useField } from 'formik';
import { isString } from 'lodash';
import { InputGroupProps } from '@endpoint/blockparty/dist/components/InputGroup';
import {
  Text,
  Input,
  InputGroup,
  InputLabel,
  InputErrorMessage,
  InputElemGroup,
  InputLeftElement,
} from '@endpoint/blockparty';
import Cleave from 'cleave.js/react';

export interface FormikInputGroupProps extends InputGroupProps {
  isRequired?: boolean;
  isOptional?: boolean;
  label?: string;
  placeholder: string;
  mask?: any;
  error?: React.ReactNode;
  autoFocus?: boolean;
  autoComplete?: string;
  description?: React.ReactNode | string;
  helperText?: React.ReactNode | string;
  name: string;
  isSensitiveInformation?: boolean;
  leftIcon?: React.ReactNode;
}

const getDescription = (description: React.ReactNode | string): React.ReactNode =>
  isString(description) ? <Text size="fontSize10">{description}</Text> : description;

export const FormikInputGroup: FC<FormikInputGroupProps> = ({
  isRequired,
  isOptional,
  name,
  label,
  isSensitiveInformation,
  placeholder,
  mask,
  error,
  autoFocus,
  autoComplete,
  description,
  helperText,
  leftIcon,
  ...props
}) => {
  const [field, meta] = useField(name);

  const isInvalid = Boolean((meta.touched && meta.error) || !!error);

  const options = {
    as: Cleave,
    options: mask,
  };

  return (
    <InputGroup groupId={name} isInvalid={isInvalid} isRequired={isRequired} {...props}>
      <InputLabel>
        {label || name}
        {isOptional ? ' (optional)' : ''}
      </InputLabel>
      <InputElemGroup>
        {leftIcon && <InputLeftElement width="48px">{leftIcon}</InputLeftElement>}
        <Input
          {...field}
          className={isSensitiveInformation && 'fs-exclude dd-privacy-hidden'}
          placeholder={placeholder}
          {...(mask && options)}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          pl={leftIcon ? '48px' : '16px'}
        />
      </InputElemGroup>
      {isInvalid ? <InputErrorMessage>{error || meta.error}</InputErrorMessage> : getDescription(description)}
      {helperText && (!error || !meta.error) && !meta.touched && getDescription(helperText)}
    </InputGroup>
  );
};
