import React, { FC } from 'react';
import { InputGroup, InputLabel, InputErrorMessage, Select } from '@endpoint/blockparty';
import { useField } from 'formik';

import { FormikInputGroupProps } from './FormikGroup';

export interface Options {
  label: string | JSX.Element;
  value: string;
}

// @ts-ignore
export interface FormikSelectGroupProps extends FormikInputGroupProps {
  defaultValue?: Options;
  name: string;
  options: Options[];
  isClearable?: boolean;
  isSearchable?: boolean;
  onSelectChange?: Function;
  autoComplete?: string;
}

export const FormikSelectGroup: FC<FormikSelectGroupProps> = ({
  component,
  defaultValue,
  isRequired,
  name,
  label,
  options,
  disabled,
  onSelectChange,
  placeholder,
  isClearable,
  isSearchable,
  autoComplete,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const isInvalid = Boolean(meta.touched && meta.error);
  const currentValue = defaultValue ? { label: defaultValue.label, value: defaultValue.value } : null;

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Backspace') {
      helpers.setValue(undefined, true);
    }
  };

  const selectedOption = React.useMemo(() => {
    return options.find((opt) => opt.value === field.value) || null;
  }, [field.value, options]);

  return (
    <InputGroup groupId={name} isInvalid={isInvalid} isRequired={isRequired} {...props}>
      {label && <InputLabel id={`input-label-${name}-select`}>{label}</InputLabel>}
      <Select
        {...props}
        aria-labelledby={`input-label-${name}-select`}
        // @ts-ignore
        autoComplete={autoComplete}
        classNamePrefix="select"
        defaultValue={currentValue}
        isClearable={isClearable}
        isDisabled={disabled}
        isSearchable={isSearchable}
        name={name}
        options={options}
        placeholder={placeholder}
        value={selectedOption}
        onBlur={() => {
          helpers.setTouched(true, true);
        }}
        onChange={(e: any) => {
          if (e) {
            onSelectChange && onSelectChange.apply({ field, e });
            helpers.setValue(e.value, true);
          }
        }}
        onKeyDown={handleKeyPress}
      />

      {isInvalid && <InputErrorMessage>{meta.error}</InputErrorMessage>}
    </InputGroup>
  );
};
