import React, { FC, useContext, useState, useEffect } from 'react';
import { Layout, Loading } from '@endpoint/blockparty';
import { Routes, Navigate, useLocation, useNavigate, Route } from 'react-router-dom';
import { SETTINGS, TRANSACTIONS, TRANSACTION_DETAILS, TODO_ASSIGNMENT, TRANSACTION } from 'consts/routes';
import { AppContext, AppContextType, isContactTypeAgentOrTC, useAppConfigsContext } from 'utils/context';
import { PageTracker } from 'utils/analytics/pageTracker';
import { formatAnalyticsIdentity, trackIdentity } from 'utils/analytics';
// Transactions
import { OpenEscrow } from 'routes/OpenEscrow';
import { OrderReports } from 'routes/OrderReports';
import { Header } from 'components/Header';
import { ZendeskChat } from 'components/ZendeskChat';
import { pushDataToWebView } from 'utils/webview';
import SignUpContainer from 'Auth/Containers/SignUp';
import SignUpRole from 'Auth/Components/SignUpRole';
import NotificationPreference from 'Auth/Components/NotificationPreference';
import NotificationPreferenceUnity from 'Auth/Components/NotificationPreferenceUnity';
import SetUpProfile from 'Auth/Components/SetUpProfile';
import SetUpUnityProfile from 'Auth/Components/SetUpUnityProfile';
import SetUpUnityEntityProfile from 'Auth/Components/SetUpUnityEntityProfile';
import OutOfCoverage from 'Auth/Components/OutOfCoverage';
import { useGetUser } from 'hooks/useGetUser';
import { useIsSupportedOrderReportState } from 'hooks/useIsSupportedOrderReportState';
import { ParticipantCheck } from 'components/ParticipantCheck';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSetAppContextUser } from 'hooks/useSetAppContextUser';
import { SignUpRoleUnity } from 'Auth/Components/SignUpRoleUnity';
import { UnityRole } from 'Auth/Utilities/Constant';
import useOrganizationId from 'hooks/useOrganizationId';
import InEscrowPaginated from 'routes/Transactions/InEscrowPaginated';

import { Transactions } from '../routes/Transactions';
import { Closed } from '../routes/Transactions/Closed';
import { InEscrow } from '../routes/Transactions/InEscrow';
import { Listing } from '../routes/Transactions/Listing';
// Transaction Details
import { TransactionDetail } from '../routes/TransactionDetail';
import Todo from '../routes/TransactionDetail/Todo';
import { Todos } from '../routes/TransactionDetail/Todos';
import { Documents } from '../routes/TransactionDetail/Documents';
import { Contacts } from '../routes/TransactionDetail/Contacts';
// Settings
import { Settings } from '../routes/Settings';
import { Profile } from '../routes/Settings/Profile';
import { UnityProfile } from '../routes/Settings/UnityProfile';
import { Security } from '../routes/Settings/Security';
import { Notifications } from '../routes/Settings/Notifications';
import { ProfileCancellation } from '../routes/Settings/Cancellation';
import { NoMatch } from '../routes/NoMatch';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { RouteOptions, SET_UP_PROFILE_URL, SELECT_ROLE_URL } from '../Auth/Utilities/Constant';
import { OfflineErrorOverlay } from '../components/Error/Offline';

const AuthorizedApp: FC = () => {
  const organizationId = useOrganizationId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isEscrowPaginationEnabled, unityTransactionCreationEnabled } = useFeatureFlags();
  const { setAuthFields, authFields, user, setUser, isOnline }: AppContextType = useContext(AppContext);
  const [heightStyle, setHeightStyle] = useState<string>('100%');
  const [shouldNavigateToTransactions, setShouldNavigateToTransactions] = useState<boolean>(false);

  const { getUser, refetch, user: userMeResponse, userLoading, userError } = useGetUser();
  const { setAppContextUser } = useSetAppContextUser();
  const isSupportedOrderReportState = useIsSupportedOrderReportState(user);
  const [hasSegmentUserIdentity, setHasSegmentUserIdentity] = useState(false);

  const { ZENDESK_APP_ID } = useAppConfigsContext();

  const unitySetUpProfilePage = user.entityName ? <SetUpUnityEntityProfile /> : <SetUpUnityProfile />;

  useEffect(() => {
    // Load initial user data
    if (!user.id && !userError) {
      void getUser();
    }

    // Refresh user data, only happens after onboarding is completed
    if (user.refetch === true) {
      void refetch();
      setShouldNavigateToTransactions(true);
    }
  }, [getUser, user.refetch, refetch, userError, user.id]);

  useEffect(() => {
    // Assign user data to context
    if (userMeResponse) {
      setAppContextUser(userMeResponse);
      pushDataToWebView(userMeResponse.id, userMeResponse.email);
    }
  }, [userMeResponse, setAppContextUser]);

  useEffect(() => {
    if (user.id && !hasSegmentUserIdentity) {
      trackIdentity(user.id, organizationId, formatAnalyticsIdentity({ ...user }));
      setHasSegmentUserIdentity(true);
    }
  }, [user, organizationId, hasSegmentUserIdentity]);

  useEffect(() => {
    if (userLoading || (Object.keys(user).length <= 1 && !Object.keys(user).includes('isOnboarded'))) {
      return;
    }

    const publicRoutes = [RouteOptions.SIGN_IN, RouteOptions.SIGN_UP];
    const isPublicRoute = publicRoutes.some((item: string): boolean => pathname === item);
    const hasUserType = !!(unityTransactionCreationEnabled ? user?.unityRole : user?.contactType);

    if (isPublicRoute) {
      navigate('/');
    } else if (user.isOnboarded) {
      if (user.transactions?.length === 1 && shouldNavigateToTransactions && !isContactTypeAgentOrTC(user)) {
        navigate(`/${TRANSACTION}/${user.transactions[0]}/todos`);
        setShouldNavigateToTransactions(false);
      } else if (shouldNavigateToTransactions) {
        navigate(`/${TRANSACTIONS}`);
        setShouldNavigateToTransactions(false);
      } else if (authFields.navigateToAfterLogin !== '' && authFields.navigateToAfterLogin !== pathname && user?.id) {
        const navigateTo =
          user.transactions?.length === 1 && !isContactTypeAgentOrTC(user)
            ? `/${TRANSACTION}/${user.transactions[0]}/todos`
            : authFields.navigateToAfterLogin;

        setAuthFields({ ...authFields, navigateToAfterLogin: '' });
        navigate(navigateTo);
      }
    } else if (user.onboardingUrl) {
      navigate(user.onboardingUrl);
    } else if (!hasUserType && unityTransactionCreationEnabled && user.entityName) {
      setUser({ ...user, unityRole: UnityRole.BUYER });
    } else if (!hasUserType && unityTransactionCreationEnabled && user.id) {
      setUser({ ...user, unityRole: user.isAgent ? UnityRole.AGENT : UnityRole.BUYER });
    } else if (!hasUserType) {
      navigate(SELECT_ROLE_URL);
    } else if (hasUserType) {
      navigate(SET_UP_PROFILE_URL);
    }
  }, [
    authFields,
    getUser,
    navigate,
    pathname,
    setAuthFields,
    shouldNavigateToTransactions,
    userLoading,
    unityTransactionCreationEnabled,
    user,
    setUser,
  ]);

  if (userLoading) {
    return <Loading left="49%" position="absolute" size="medium" top="49%" zIndex={1} />;
  }

  return user?.isOnboarded === true ? (
    <>
      <Layout data-test-id="ep-layout" height={heightStyle}>
        <PageTracker />
        <Header isOnline={isOnline} />
        {!isOnline && <OfflineErrorOverlay />}
        <Routes>
          <Route element={<ErrorBoundary element={<Navigate to={`/${TRANSACTIONS}/in-escrow`} />} />} path="/" />
          <Route element={<ErrorBoundary element={<Transactions />} />} path={TRANSACTIONS}>
            <Route element={<ErrorBoundary element={<Navigate to="in-escrow" />} />} index />
            <Route
              element={<ErrorBoundary element={isEscrowPaginationEnabled ? <InEscrowPaginated /> : <InEscrow />} />}
              path="in-escrow"
            />
            <Route element={<ErrorBoundary element={<Closed />} />} path="closed" />
            <Route element={<ErrorBoundary element={<Listing />} />} path="listings" />
          </Route>
          <Route element={<ErrorBoundary element={<TransactionDetail />} />} path={TRANSACTION_DETAILS}>
            <Route element={<ErrorBoundary element={<Navigate to="todos" />} />} index />
            <Route element={<ErrorBoundary element={<Todos setHeightStyle={setHeightStyle} />} />} path="todos" />
            <Route element={<ErrorBoundary element={<Documents />} />} path="documents/*" />
            <Route element={<ErrorBoundary element={<Contacts />} />} path="contacts" />
          </Route>

          <Route
            element={<ParticipantCheck element={<Navigate replace to={`${window.location.pathname}/step/about`} />} />}
            path={TODO_ASSIGNMENT}
          />
          <Route element={<ParticipantCheck element={<Todo />} />} path={`${TODO_ASSIGNMENT}/step/:stepId`} />

          {/* Top Header Routes */}
          <Route element={<ErrorBoundary element={<OpenEscrow />} />} path="open-escrow">
            <Route element={<ErrorBoundary element={<OpenEscrow />} />} path=":transactionId" />
          </Route>
          <Route
            element={<ErrorBoundary element={isSupportedOrderReportState ? <OrderReports /> : <NoMatch />} />}
            path="order-reports"
          />
          <Route element={<ErrorBoundary element={<Settings />} />} path={SETTINGS}>
            <Route
              element={<ErrorBoundary element={unityTransactionCreationEnabled ? <UnityProfile /> : <Profile />} />}
              path="profile"
            />
            <Route element={<ErrorBoundary element={<Security />} />} path="security" />
            <Route element={<ErrorBoundary element={<Notifications />} />} path="notifications" />
            <Route element={<ErrorBoundary element={<ProfileCancellation />} />} path="cancellation" />
          </Route>
          {/* Catch-all route */}
          <Route element={<NoMatch />} path="*" />
        </Routes>
      </Layout>
      {ZENDESK_APP_ID && <ZendeskChat appId={ZENDESK_APP_ID} />}
    </>
  ) : (
    <>
      <PageTracker />
      {!!user.loading && <Loading left="49%" position="absolute" size="medium" top="40%" zIndex={1} />}
      <Routes>
        <Route element={<SignUpContainer />} path={RouteOptions.SIGN_UP}>
          <Route
            element={unityTransactionCreationEnabled ? <SignUpRoleUnity /> : <SignUpRole />}
            path={RouteOptions.SELECT_ROLE}
          />
          <Route
            element={unityTransactionCreationEnabled ? unitySetUpProfilePage : <SetUpProfile />}
            path={RouteOptions.SET_UP_PROFILE}
          />
          <Route element={<OutOfCoverage />} path={RouteOptions.OUT_OF_COVERAGE} />
          <Route
            element={unityTransactionCreationEnabled ? <NotificationPreferenceUnity /> : <NotificationPreference />}
            path={RouteOptions.NOTIFICATION_PREFERENCE}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AuthorizedApp;
