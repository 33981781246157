import { useQuery } from '@apollo/client';
import { TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { TransactionsPayload, GET_TRANSACTIONS } from 'routes/Transactions/queries';

import { prelistingSorter } from './prelistingSorter';

export const useGetPreListingTransactions = () => {
  const { data, loading, error } = useQuery<TransactionsPayload>(GET_TRANSACTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { where: { transactionStatus: TransactionStatus.PRELISTING } },
  });

  return { data: data && prelistingSorter(data), loading, error };
};
