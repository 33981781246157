import React, { FC, useContext, useEffect } from 'react';
import { Heading, Flex, Image, Button, Box, Text, Link } from '@endpoint/blockparty';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TRANSACTIONS, TRANSACTION_IN_ESCROW } from 'consts/routes';

import { FormikValue } from '../..';
import { OpenEscrowContextValue, OpenEscrowContext } from '../../Context';

export const ThankYouStep: FC<CurrentStepComponentProps<FormikValue>> = () => {
  const navigate = useNavigate();

  const openEscrowContextValue: OpenEscrowContextValue = useContext(OpenEscrowContext);

  useEffect(() => {
    openEscrowContextValue.setCurrentStep(8);
  }, [openEscrowContextValue]);

  return (
    <>
      <Flex justifyContent="center" mb="space70">
        <Image
          alt="Thank you"
          height="128px"
          src="https://images.ctfassets.net/py22ts3k5d4r/6ja6hUnRpWcOvKevwTrCg9/d02c9cb5c58e4354207560d7f9560357/Illustration_HighFive.svg?h=250"
          width="128px"
        />
      </Flex>
      <Heading as="h1" mb="space70" size="fontSize50">
        Woohoo!
      </Heading>
      <Box pb="space70">
        <Text>Thanks for sending us your order!</Text>
      </Box>
      <Box>
        <Heading as="h2" pb="space50" size="fontSize30">
          What’s Next?
        </Heading>
        <Text as="p" pb="space50">
          Escrow will officially open once our team has processed the Purchase Agreement. We will be sending out email
          invitations to members involved in the transaction once processing is complete.
        </Text>
        <Text as="p" pb="space50">
          You will be able to view the transaction once escrow opens.
        </Text>
        {!openEscrowContextValue.isFromPrelim ? (
          <Text as="p">
            {`Please note, it will take a few minutes for your transaction's file number to be created. Once ready, it can
            be found in the `}
            <Link
              as={RouterLink}
              color="blue500"
              fontWeight="normal"
              size="fontSize20"
              textDecoration="underline"
              to={TRANSACTION_IN_ESCROW}
            >
              In Escrow
            </Link>{' '}
            section on your Transactions page.
          </Text>
        ) : (
          <Text as="p">
            Your transaction <Text fontWeight="semi">#{openEscrowContextValue.reswareFileNumber}</Text> can be found in
            the{' '}
            <Link
              as={RouterLink}
              color="blue500"
              fontWeight="normal"
              size="fontSize20"
              textDecoration="underline"
              to={TRANSACTION_IN_ESCROW}
            >
              In Escrow
            </Link>{' '}
            section on your Transactions page.
          </Text>
        )}
      </Box>

      <Flex justifyContent="flex-end" mt="space80">
        <Button onClick={() => navigate(`/${TRANSACTIONS}`)}>Got It</Button>
      </Flex>
    </>
  );
};
