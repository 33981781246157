import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { AppContextType, AppContext } from 'utils/context';
import { AuthResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { useAuth } from 'hooks/auth/useAuth';
import { SignInTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import { RouteOptions } from 'Auth/Utilities/Constant';

export const useHandleAuthResponse = () => {
  const { setAuthFields, authFields, setUser }: AppContextType = useContext(AppContext);
  const { setTokens, setData } = useAuth();
  const navigate = useNavigate();

  return useCallback(
    (authResponse: AuthResponse, mfaURL?: string, afterLoginURL?: string) => {
      // if the user has no MFA or this response is after MFA
      if (authResponse.authentication) {
        setTokens(authResponse.authentication);
        navigate(afterLoginURL ?? '/');

        const trackLoginSuccessfulProperties = {
          name: SignInTrackingEvents.ACCOUNT_SIGNED_IN,
          mfaEnabled: Boolean(authFields.isMfaEnabled),
        };

        trackAction(SignInTrackingEvents.ACCOUNT_SIGNED_IN, trackLoginSuccessfulProperties);

        // TODO: migrate all global auth context handling to useAuth after removing Amplify login completely
        setAuthFields({
          ...authFields,
          navigateToAfterLogin: '',
        });
      } else {
        setData('session', authResponse.session);

        if (authResponse?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setAuthFields({
            ...authFields,
            errorCode: null,
            resetPassword: true,
            passwordExpired: true,
          });

          navigate(RouteOptions.RESET_PASSWORD);
        } else if (mfaURL) {
          setAuthFields({
            ...authFields,
            isMfaEnabled: true,
          });

          navigate(mfaURL);
        }
      }

      setUser((user: any) => ({ ...user, loading: false }));
    },
    [authFields, navigate, setAuthFields, setData, setTokens, setUser],
  );
};
