import React, { FC, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Box, Heading, Text, Flex, Button, Stack, useToast } from '@endpoint/blockparty';
import { Formik, Form } from 'formik';
import { identity, pickBy } from 'lodash';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { Label } from 'components/DataBlock';
import { UpdateContactInput, ContactWhereUniqueInput } from '@endpoint/endpoint-bff-graphql-schema';
import { AppContext, AppContextType } from 'utils/context';
import Toast from 'Auth/Components/Toast';
import { updateCognitoPoolUserAttribute } from 'utils/auth/storage';
import { isNewLoginSystemEnabled } from 'Auth/Utilities/helper';
import { useContact } from 'hooks/useContact';
import { trackAction, formatAnalyticsUnityProfileIdentity, trackIdentityPreCreation } from 'utils/analytics';
import { SignUpTrackingEvents, TRACK_SET_PROFILE_PAYLOAD } from 'consts/analytics';

import { NOTIFICATION_PREFERENCE_URL } from '../../Utilities/Constant';
import UnityProfileValidationSchema from './SetUpUnityEntityProfileValidationSchema';
import { ITransacteeIndividual } from '../../types.d';
import { SET_PROFILE_CONTINUE_BUTTON_TEXT } from '../../../consts/descriptions';

const SetUpUnityEntityProfile: FC = () => {
  const toast = useToast();
  const { user, setUser }: AppContextType = useContext(AppContext);
  const { updateContact, updateContactLoading, updateContactResult, updateContactError } = useContact();

  const handleSubmitEntityProfile = async (values: Partial<ITransacteeIndividual>) => {
    trackAction(SignUpTrackingEvents.SET_PROFILE_BUTTON_SUBMITTED, TRACK_SET_PROFILE_PAYLOAD);
    const { firstName, lastName, middleName, suffix } = values;

    if (user.id) {
      const updateContactInput: UpdateContactInput = {
        firstName,
        lastName,
        middleName: middleName?.trim(),
        suffix: suffix?.trim(),
      };

      const contactWhereUniqueInput: ContactWhereUniqueInput = {
        id: user.id,
      };

      const cleanUpdateContactInput: UpdateContactInput = pickBy(updateContactInput, identity);

      if (!isNewLoginSystemEnabled()) {
        await updateCognitoPoolUserAttribute(user.id);
      }

      await updateContact(contactWhereUniqueInput, cleanUpdateContactInput).then(() => {
        trackIdentityPreCreation(formatAnalyticsUnityProfileIdentity(cleanUpdateContactInput));
      });
    }
  };

  useEffect(() => {
    if (!updateContactLoading && updateContactResult) {
      const onboardingUrl = NOTIFICATION_PREFERENCE_URL;

      setUser({ ...user, ...updateContactResult.updateContact, onboardingUrl, roles: [user.unityRole] });
    }

    if (!updateContactLoading && updateContactError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={updateContactError.message} onClose={onClose} />,
      });

      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        message: `updateContact mutation error -- Set up profile screen / Onboarding. Contact id: ${
          user.id
        }. Error: ${JSON.stringify(updateContactError)}}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContactLoading, updateContactResult, updateContactError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
      }}
      validateOnBlur
      validationSchema={UnityProfileValidationSchema}
      onSubmit={handleSubmitEntityProfile}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Box mb="space70" textAlign="left">
            <Heading as="h2" mb="space50" size={{ base: 'fontSize70', md: 'fontSize60' }}>
              Set up your profile
            </Heading>
            <Text>The following information will be used for your official documents.</Text>
          </Box>
          <Stack mb="space80" spacing="space60">
            {user.entityName && (
              <>
                <Box bg="carbon0" borderRadius="radiusDefault" mt="space0" p="space50">
                  <Label>Company Name</Label>
                  <Text>{user.entityName}</Text>
                </Box>
              </>
            )}
            <FormikInputGroup
              autoFocus
              data-test-id="firstName"
              display="block"
              label="First Name"
              name="firstName"
              placeholder="e.g. John"
            />
            <FormikInputGroup
              data-test-id="middleName"
              display="block"
              isOptional
              label="Middle Name"
              name="middleName"
              placeholder="e.g. William"
            />
            <FormikInputGroup
              data-test-id="lastName"
              display="block"
              label="Last Name"
              name="lastName"
              placeholder="e.g. Doe"
            />
            <FormikInputGroup
              data-test-id="suffix"
              display="block"
              isOptional
              label="Suffix"
              name="suffix"
              placeholder="e.g. Jr."
            />
          </Stack>
          <Flex
            backgroundColor="white"
            bottom={{ base: 0, md: 'initial' }}
            boxShadow={{ base: 'medium', md: 'none' }}
            justifyContent="flex-end"
            left={{ base: 0, md: 'initial' }}
            position={{ base: 'fixed', md: 'initial' }}
            px={{ base: 'space50', md: 'space0' }}
            py={{ base: 'space30', md: 'space0' }}
            right={{ base: 0, md: 'initial' }}
            zIndex="overlay"
          >
            <Button isDisabled={!(isValid && dirty)} isLoading={updateContactLoading} type="submit">
              {SET_PROFILE_CONTINUE_BUTTON_TEXT}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SetUpUnityEntityProfile;
