import React, { FC, useContext } from 'react';
import {
  Alert,
  AlertIcon,
  AlertContent,
  AlertDescription,
  AlertTitle,
  Box,
  Text,
  AlertCloseButton,
} from '@endpoint/blockparty';
import { AlertProps } from '@endpoint/blockparty/dist/components/Alert/index';
import { CompanyContext } from 'utils/context';

import { getCompanyPhoneNumber } from '../../utils/getCompanyPhoneNumber';
import { formatPhone } from '../../utils/formatPhone';

interface AlertMessageProps {
  description?: string;
  status?: AlertProps['status'];
  title?: string;
  errorMessagePrefix?: string;
  phoneLinkColor?: string;
  isCloseable?: boolean;
  onClose?: () => void;
}

export const AlertMessage: FC<AlertMessageProps> = ({
  description = '',
  title = 'Oops! An error has occurred.',
  errorMessagePrefix = 'Please call ',
  status = 'error',
  isCloseable = false,
  onClose,
  children,
  phoneLinkColor = 'carbon900',
}) => {
  const { companyName } = useContext(CompanyContext);

  return (
    <Alert status={status}>
      <AlertIcon />
      <AlertContent>
        <AlertTitle as="p" data-test-id="alert-title">
          {title}
        </AlertTitle>
        <AlertDescription>
          {children}
          {description ? (
            <Text>{description}</Text>
          ) : (
            <Box data-test-id="default-error-text">
              <Text>
                {errorMessagePrefix}
                {companyName} for assistance at
              </Text>{' '}
              <Text as="a" color={phoneLinkColor} href={`tel:+1${getCompanyPhoneNumber(companyName).technicalSupport}`}>
                {formatPhone(getCompanyPhoneNumber(companyName).technicalSupport)}
              </Text>{' '}
              <Text>or use the chat icon to message us directly.</Text>
            </Box>
          )}
        </AlertDescription>
      </AlertContent>
      {isCloseable && <AlertCloseButton onClick={onClose} />}
    </Alert>
  );
};

AlertMessage.displayName = 'AlertMessage';
