import React, { useState, useEffect } from 'react';
import { Box, Text, EPContent, Heading, Divider, Switch, Flex, Button } from '@endpoint/blockparty';
import { AlertMessage } from 'components/AlertMessage';
import { AnchoredButton } from 'components/AnchoredButton';
import { ANCHORED_BUTTON_OFFSET, CENTERED_CONTENT_WIDTH } from 'consts/responsive';
import { useAppContext } from 'utils/context';
import { trackAction } from 'utils/analytics';
import { NotificationTrackingEvents } from 'consts/analytics';
import { useUpdateContact } from 'hooks/useUpdateContact';
import { NotificationPreferencesInput } from '@endpoint/endpoint-bff-graphql-schema';

export const Notifications = () => {
  const { user, setUser } = useAppContext();
  const hasEmailNotifications = user?.notificationPreferences?.email;
  const hasSmsNotifications = user?.notificationPreferences?.sms;
  const [email, setEmail] = useState(hasEmailNotifications);
  const [sms, setSms] = useState(hasSmsNotifications);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { EMAIL_DISABLED, EMAIL_ENABLED } = NotificationTrackingEvents;

  const { updatedContact, updateContact, updateContactLoading } = useUpdateContact();

  useEffect(() => {
    if (user) {
      setEmail(hasEmailNotifications);
      setSms(hasSmsNotifications);
    }
  }, [hasEmailNotifications, hasSmsNotifications, user]);

  const handleEmailClick = () => {
    trackAction(!email ? EMAIL_ENABLED : EMAIL_DISABLED);
    setEmail(!email);
    setDisabled(false);
  };

  const handleCancel = () => {
    setEmail(hasEmailNotifications);
    setSms(hasSmsNotifications);
    setDisabled(true);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setDisabled(true);

      const isUserPreferenceLoaded = typeof email === 'boolean' && typeof sms === 'boolean';

      if (user.id && isUserPreferenceLoaded) {
        const notificationPreferences: NotificationPreferencesInput = {
          email: !!email,
          sms: !!sms,
        };

        await updateContact({
          variables: { where: { id: user.id }, input: { notificationPreferences } },
        });
      }

      setHasSubmitted(true);
    } catch (error) {
      setHasError(true);
      setDisabled(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!updateContactLoading && updatedContact) {
      setUser({ ...user, ...updatedContact });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedContact, updateContactLoading]);

  return (
    <>
      <EPContent mb={{ base: ANCHORED_BUTTON_OFFSET, md: 'space0' }}>
        <Heading mb="space70" size="fontSize40">
          Notification Settings
        </Heading>

        {hasSubmitted && (
          <Box mb="space70">
            <AlertMessage
              description="Your notification settings have been updated."
              status="success"
              title="Success!"
            />
          </Box>
        )}
        {hasError && (
          <Box mb="space70">
            <AlertMessage
              description="Please try again."
              title="Oops! We’ve encountered a slight hiccup in completing your request."
            />
          </Box>
        )}
        <Divider />
        <Box py="space50">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            margin="0 auto"
            maxWidth={CENTERED_CONTENT_WIDTH}
            width="100%"
          >
            <Text as="p">Enable email notifications</Text>
            <Switch
              data-test-id="email-notifications"
              id="email-notifications"
              isChecked={email}
              onChange={handleEmailClick}
            />
          </Flex>
        </Box>
        <Divider />
        <AnchoredButton>
          <Flex justifyContent="flex-end">
            <Button data-test-id="cancel" isDisabled={disabled} mr="space50" variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button data-test-id="submit" isDisabled={disabled} isLoading={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </AnchoredButton>
      </EPContent>
    </>
  );
};
