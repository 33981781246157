import React, { FC } from 'react';
import { Flex, Text, Box, Checkbox } from '@endpoint/blockparty';

interface DocumentsListHeaderProps {
  areAllDocumentsChecked: boolean;
  areSomeChecked: boolean;
  onCheckAll(checked: boolean): void;
}

export const DocumentsListHeader: FC<DocumentsListHeaderProps> = ({
  areAllDocumentsChecked,
  areSomeChecked,
  onCheckAll,
}) => {
  return (
    <Flex justifyContent="space-between">
      <Checkbox
        alignItems="flex-start"
        isChecked={areSomeChecked}
        isIndeterminate={areSomeChecked && !areAllDocumentsChecked}
        p="space40"
        onChange={(e: any) => {
          onCheckAll(e.target.checked as boolean);
        }}
      />
      <Box flex={1}>
        <Flex
          alignContent="space-between"
          alignItems="flex-start"
          flexDirection={{ base: 'column', md: 'row' }}
          p="space40"
        >
          <Box data-test-id="name" flex={1}>
            {areSomeChecked ? <Text color="carbon500">Deselect</Text> : <Text color="carbon500">Name</Text>}
          </Box>
          <Box display={{ base: 'none', md: 'block' }} flex={1}>
            <Text color="carbon500" data-test-id="type">
              Type
            </Text>
          </Box>
          <Box display={{ base: 'none', md: 'block' }} flex={1}>
            <Text color="carbon500" data-test-id="date-added">
              Date Added
            </Text>
          </Box>
        </Flex>
      </Box>
      <Text as="p" color="carbon500" data-test-id="note" m="space40">
        Note
      </Text>
    </Flex>
  );
};
