import React from 'react';
import { TodoAssignmentImage } from '@endpoint/endpoint-bff-graphql-schema';
import { Flex, Image } from '@endpoint/blockparty';

interface TodoImageProps {
  image: TodoAssignmentImage;
}

export const TodoImage = ({ image: { alt, src } }: TodoImageProps) => {
  return (
    <Flex justifyContent="center" mb="space70">
      <Image alt={alt ?? ''} height="128px" src={src} width="128px" />
    </Flex>
  );
};
