import { format, isWithinInterval, subDays, isValid } from 'date-fns';
import { getDate } from 'utils/getDate';

/**
 * ex: "8:00pm"
 */
export const TIME = "h:mmaaaaa'm'";
/**
 * ex: "Nov 18"
 */
export const MONTH_AND_DAY = 'MMM dd';
/**
 * ex: "Nov 18, 12:00am"
 */
export const MONTH_DAY_TIME = "MMM dd, h:mmaaaaa'm'";
/**
 * ex: "Nov 18, 2020"
 */
export const MONTH_DAY_YEAR = 'MMM dd, yyyy';
/**
 * ex: "Nov 18, 2020, 12:00am"
 */
export const MONTH_DAY_YEAR_TIME = "MMM dd, yyyy, hh:mmaaaaa'm'";
/**
 * ex: "Wednesday, Nov 18"
 */
export const DAY_OF_WEEK_MONTH_DAY = 'iiii, MMM d';
/**
 * ex: "Wed Nov 18"
 */
export const WEEKDAY_MONTH_DAY = 'iii MMM d';
/**
 * ex: "Wed Nov 18, 12:00am"
 */
export const DAY_OF_WEEK_MONTH_DAY_TIME = `iii MMM d, ${TIME}`;
/**
 * ex: "Wednesday, Nov 18, 2020"
 */
export const DAY_OF_WEEK_MONTH_DAY_YEAR = 'iiii, MMM d, yyyy';
/**
 * ex: "Nov 18 at 12:00am"
 */
export const MONTH_DAY_YEAR_AT_TIME = "MMM dd 'at' h:mmaaaaa'm'";
/**
 * ex: "2020-11-18"
 */
export const YEAR_MONTH_DAY = 'yyyy-MM-dd';
/**
 * ex: "11/18/2020"
 */
export const MONTH_DAY_YEAR_WITH_SLASHES = 'MM/dd/yyyy';

/**
 * Workday time boundaries in 24hr format
 */
export const WORKDAY_BEGIN_HOUR = 0;

export const WORKDAY_END_HOUR = 23;

/**
 * Checks if current date is within 24 hours of end date.
 * The argument takes an ISO format date.
 *
 * @param date - i.e. 2020-06-12T12:00:00.000Z
 * @returns boolean
 */
export const isWithin24Hours = (date: string): boolean => {
  const dayBeforeEndDate = subDays(new Date(date), 1);
  const endDate = new Date(date);

  return isWithinInterval(new Date(), { start: dayBeforeEndDate, end: endDate });
};

export const formatDate = (date: Date | string, formatType: string, shouldTrimZ: boolean = false): string => {
  let preparedDate: Date | undefined;

  if (typeof date === 'string') {
    preparedDate = getDate(date, shouldTrimZ);
  } else {
    preparedDate = date;
  }

  if (preparedDate && isValid(preparedDate)) {
    return format(preparedDate, formatType);
  }

  return '';
};
