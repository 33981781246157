import React, { FC, useEffect } from 'react';
import { Heading, Box, Text, Stack } from '@endpoint/blockparty';
import { AssistantRole } from '@endpoint/endpoint-bff-graphql-schema';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { Multiple } from 'components/form/Multiple';
import { FormikInputGroup, FormikSelectGroup } from 'components/form/FormikBlockparty';

import { FormikValue, TeamMate } from '../..';
import { formatTeammateRole } from './formatTeammateRole';
import { getTeammateRoleSelection } from './getTeammateRoleSelection';

const SELECT_ROLE_OPTIONS = [
  { value: AssistantRole.SELLING_AGENT, label: formatTeammateRole(AssistantRole.SELLING_AGENT) },
  {
    value: AssistantRole.SELLING_TRANSACTION_COORDINATOR,
    label: formatTeammateRole(AssistantRole.SELLING_TRANSACTION_COORDINATOR),
  },
  { value: AssistantRole.LISTING_AGENT, label: formatTeammateRole(AssistantRole.LISTING_AGENT) },
  {
    value: AssistantRole.LISTING_TRANSACTION_COORDINATOR,
    label: formatTeammateRole(AssistantRole.LISTING_TRANSACTION_COORDINATOR),
  },
  { value: AssistantRole.LOAN_OFFICER, label: formatTeammateRole(AssistantRole.LOAN_OFFICER) },
  { value: AssistantRole.LOAN_PROCESSOR, label: formatTeammateRole(AssistantRole.LOAN_PROCESSOR) },
  { value: AssistantRole.MORTGAGE_BROKER, label: formatTeammateRole(AssistantRole.MORTGAGE_BROKER) },
  { value: AssistantRole.ESCROW_AGENT, label: formatTeammateRole(AssistantRole.ESCROW_AGENT) },
];

export const Step5: FC<CurrentStepComponentProps<FormikValue>> = ({
  currentStepPosition = 5,
  setCurrentStepPosition,
}) => {
  const { isValid, validateForm, values } = useFormikContext<FormikValue>();

  useEffect(() => {
    const resolveValidateForm: () => void = async () => {
      await validateForm();
    };

    resolveValidateForm();
  }, [validateForm]);

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Add teammates to this transaction (optional)
      </Heading>
      <Box mb="space60">
        <Text size="fontSize20">
          Please add team members who will be helping you process this transaction (ie. assistants or transaction
          coordinators).
        </Text>
      </Box>
      <Box mb="space60">
        <Multiple name="teammates" title="Teammate">
          {(prefix: string) => (
            <>
              <FormikInputGroup label="Teammate First Name" name={`${prefix}.firstName`} placeholder="e.g. John" />
              <FormikInputGroup
                label="Teammate Middle Name (optional)"
                name={`${prefix}.middleName`}
                placeholder="e.g. William"
              />
              <FormikInputGroup label="Teammate Last Name" name={`${prefix}.lastName`} placeholder="e.g. Doe" />
              <FormikInputGroup label="Teammate Email" name={`${prefix}.email`} placeholder="e.g. name@gmail.com" />
              <FormikInputGroup
                label="U.S. Mobile Phone Number"
                mask={{ numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ['(', ')', ' ', '-'] }}
                name={`${prefix}.phone`}
                placeholder="(555) 555-5555"
              />
              <FormikSelectGroup
                data-test-id="role-select"
                defaultValue={getTeammateRoleSelection(prefix, values)}
                isClearable={false}
                label="Role"
                name={`${prefix}.role`}
                options={SELECT_ROLE_OPTIONS}
                placeholder="Select"
              />
            </>
          )}
        </Multiple>
      </Box>
      <NavigationButton
        goNext={() => setCurrentStepPosition(currentStepPosition + 1)}
        goPrevious={() => setCurrentStepPosition(currentStepPosition - 1)}
        iconRight="ArrowChevronRight"
        isDisabled={!isValid}
      >
        Next
      </NavigationButton>
    </>
  );
};

export const Summary = (formikValues: FormikValue) => {
  const { teammates } = formikValues;

  const noFieldFilledOut = teammates.every((teammate) => Object.values(teammate).every((val) => !val));

  if (noFieldFilledOut) {
    return <Text as="p">N/A</Text>;
  }

  const getTeamMateName = (teammate: TeamMate) => {
    return teammate.middleName
      ? `${teammate.firstName} ${teammate.middleName} ${teammate.lastName}`
      : `${teammate.firstName} ${teammate.lastName}`;
  };

  return (
    <Stack spacing="space60">
      {teammates
        .filter((teammate) => Object.keys(teammate).length && Object.values(teammate).every((value) => !!value))
        .map((teammate) => (
          <Stack key={teammate.email} spacing="space10">
            <Text as="p">{getTeamMateName(teammate)}</Text>
            <Text as="p">{teammate.email}</Text>
            <Text as="p">{teammate.phone}</Text>
            <Text as="p">{formatTeammateRole(teammate.role)}</Text>
          </Stack>
        ))}
    </Stack>
  );
};

const formHasAnyInput = (...fields: string[]) => fields.some((field) => field && field.length);

export const step5ValidationSchema = yup.object().shape({
  teammates: yup.array().of(
    yup.object().shape(
      {
        firstName: yup.string().when(['lastName', 'email', 'phone', 'middleName', 'role'], {
          is: formHasAnyInput,
          then: yup.string().required('Teammate First Name is required'),
        }),
        lastName: yup.string().when(['firstName', 'email', 'phone', 'middleName', 'role'], {
          is: formHasAnyInput,
          then: yup.string().required('Teammate Last Name is required'),
        }),
        email: yup.string().when(['firstName', 'lastName', 'phone', 'middleName', 'role'], {
          is: formHasAnyInput,
          then: yup.string().required('Teammate Email is required').email('Invalid email'),
        }),
        phone: yup.string().when(['firstName', 'lastName', 'email', 'middleName', 'role'], {
          is: formHasAnyInput,
          then: yup
            .string()
            .transform((value) => {
              // replace non numeric character before validation
              const newValue = value.replace(/\D/g, '');

              return newValue;
            })
            .min(10, 'Please enter your 10-digit phone number')
            .required('U.S. Mobile Phone Number is required'),
        }),
        middleName: yup.string(),
        role: yup.string().when(['firstName', 'lastName', 'phone', 'email', 'middleName'], {
          is: formHasAnyInput,
          then: yup.string().required('Role is required'),
        }),
      },
      [
        ['firstName', 'lastName'],
        ['firstName', 'email'],
        ['firstName', 'phone'],
        ['firstName', 'role'],
        ['lastName', 'email'],
        ['lastName', 'phone'],
        ['lastName', 'role'],
        ['email', 'phone'],
        ['email', 'role'],
        ['phone', 'role'],
      ],
    ),
  ),
});
