import { useQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { GET_ZENDESK_TOKEN, ZendeskTokenPayload } from './queries';

export const useGetZendeskToken = (email: string) => {
  const sentryTransaction = useSentryTransaction('getZendeskToken', 'GraphQL client query');
  const { data, error, loading } = useQuery<ZendeskTokenPayload>(GET_ZENDESK_TOKEN, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
    variables: { where: { email } },
  });

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    data: data?.getZendeskToken?.zendeskJWT,
    loading,
    error,
  };
};
