import React, { FC, useEffect } from 'react';
import { Heading, Box, Text, Stack } from '@endpoint/blockparty';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { Multiple } from 'components/form/Multiple';

import { FormikValue, Client } from '../..';

export const Step4: FC<CurrentStepComponentProps<FormikValue>> = ({
  currentStepPosition = 4,
  setCurrentStepPosition,
}) => {
  const { isValid, validateForm, values } = useFormikContext<FormikValue>();

  const fieldPrefix = values.representing === 'buyer' ? 'Buyer' : 'Seller';

  useEffect(() => {
    const resolveValidateForm: () => void = async () => {
      await validateForm();
    };

    resolveValidateForm();
  }, [validateForm]);

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Add your client(s) to this transaction
      </Heading>
      <Box mb="space60">
        <Text size="fontSize20">
          {`For sign up and security purposes, please provide a unique email address for your client. If there is more than one ${values.representing}, each must have their own unique email address. Once escrow is opened, we'll send them an
          email invite to join this transaction.`}
        </Text>
      </Box>
      <Box mb="space60">
        <Multiple name="clients" title={`${fieldPrefix}`}>
          {(prefix: string) => (
            <>
              <FormikInputGroup
                label={`${fieldPrefix} First Name`}
                name={`${prefix}.firstName`}
                placeholder="e.g. John"
              />
              <FormikInputGroup
                label={`${fieldPrefix} Middle Name (optional)`}
                name={`${prefix}.middleName`}
                placeholder="e.g. William"
              />
              <FormikInputGroup label={`${fieldPrefix} Last Name`} name={`${prefix}.lastName`} placeholder="e.g. Doe" />
              <FormikInputGroup
                label={`${fieldPrefix} Email`}
                name={`${prefix}.email`}
                placeholder="e.g. name@gmail.com"
              />
              <FormikInputGroup
                label="U.S. Mobile Phone Number"
                mask={{ numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ['(', ')', ' ', '-'] }}
                name={`${prefix}.phone`}
                placeholder="(555) 555-5555"
              />
            </>
          )}
        </Multiple>
      </Box>
      <NavigationButton
        goNext={() => setCurrentStepPosition(currentStepPosition + 1)}
        goPrevious={() => setCurrentStepPosition(currentStepPosition - 1)}
        iconRight="ArrowChevronRight"
        isDisabled={!isValid}
      >
        Next
      </NavigationButton>
    </>
  );
};

export const Summary = (formikValues: FormikValue) => {
  const { clients } = formikValues;

  const getName = (client: Client) => {
    return client.middleName
      ? `${client.firstName} ${client.middleName} ${client.lastName}`
      : `${client.firstName} ${client.lastName}`;
  };

  return (
    <Stack spacing="space60">
      {clients.map((client) => (
        <Stack key={client.email} spacing="space10">
          <Text as="p">{getName(client)}</Text>
          <Text as="p">{client.email}</Text>
          <Text as="p">{client.phone}</Text>
        </Stack>
      ))}
    </Stack>
  );
};

export const step4ValidationSchema = yup.object().shape({
  clients: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required('Client first name is required'),
      lastName: yup.string().required('Client last name is required'),
      email: yup.string().required('Client email is required').email('Invalid email'),
      phone: yup
        .string()
        .transform((value) => {
          // replace non numeric character before validation
          const newValue = value.replace(/\D/g, '');

          return newValue;
        })
        .min(10, 'Please enter your 10-digit phone number')
        .required('Phone number is required'),
    }),
  ),
});
