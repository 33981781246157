import React, { FC } from 'react';
import {
  Drawer,
  DrawerActionBar,
  DrawerHeader,
  DrawerContent,
  DrawerSection,
  Flex,
  Stack,
  Text,
} from '@endpoint/blockparty';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';

interface DefinitionsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DefinitionsDrawer: FC<DefinitionsDrawerProps> = ({ isOpen, onClose }) => {
  const {
    todoAssignment: { definitions },
  } = useTodoStepContext();

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerContent>
        <DrawerActionBar />
        <Flex flex={1} flexDirection="column" overflow="auto">
          <DrawerHeader title="Helpful Definitions" />
          <Stack m="space60" spacing="space60">
            {definitions?.length ? (
              definitions.map(({ term, definition }) => (
                <DrawerSection key={term} contentSpacing="space30" p="space0">
                  <Text as="p" fontWeight="semi" textTransform="capitalize">
                    {term}
                  </Text>
                  <Text as="p">{definition}</Text>
                </DrawerSection>
              ))
            ) : (
              <Text as="p">No definitions available</Text>
            )}
          </Stack>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
