import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/platform-api-connector/dist/graphql-types';

export const GET_TRANSACTIONS = gql`
  query getTransactions($where: MyTransactionsWhereUniqueInput!) {
    myTransactions(where: $where) {
      id
      fileNum
      marketId
      signingDate
      dates {
        estimatedClosing
        prelistingOpened
        escrowOpen
        emdContractDate
        cancellationDate
        titleProductDelivered
        buyerSigningDate
        sellerSigningDate
      }
      status
      type
      price
      loanAmount
      prelimPending
      participants {
        roles
        user {
          id
          firstName
          lastName
        }
      }
      property {
        propertyImage
        address {
          street1
          street2
          city
          state
          zip
        }
      }
      todos {
        id
        status
      }
      milestones {
        id
        status
        position
      }
      office {
        name
      }
    }
  }
`;

export const GET_TRANSACTIONS_IDS = gql`
  query getTransactions($where: MyTransactionsWhereUniqueInput!) {
    myTransactions(where: $where) {
      id
      fileId
      fileNum
      createdAt
    }
  }
`;

export const GET_DOCUMENT = gql`
  query getDocument($input: GetDocumentInput!) {
    getDocument(input: $input) {
      id
      name
      createdAt
      url
      mimeType
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query getDocuments($input: GetDocumentsInput!) {
    getDocuments(input: $input) {
      id
      name
      createdAt
      url
      mimeType
      type
      escrowNote
      uploadedBy
    }
  }
`;

export const GET_SIGNATURE_URL = gql`
  query getSignatureUrl($input: GetSignUrlInput!) {
    getSignUrl(input: $input) {
      signUrl
      expiresAt
    }
  }
`;

export interface TransactionsPayload {
  myTransactions: Transaction[];
}
