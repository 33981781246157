import React, { FC } from 'react';
import { Box, Stack, Text } from '@endpoint/blockparty';
import { TransactionSigning } from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatDate, DAY_OF_WEEK_MONTH_DAY_TIME, WEEKDAY_MONTH_DAY } from 'utils/formatDate';
import { formatPhone } from 'utils/formatPhone';

import { Label } from '../../Label';

interface SigningBlockProps {
  signingInfo: {
    signerName?: string;
    signingDate?: TransactionSigning['date'];
    signingLocation?: TransactionSigning['location'];
    notaryName?: TransactionSigning['notaryName'];
    notaryPhone?: TransactionSigning['notaryPhone'];
    notaryProvider?: TransactionSigning['notaryProvider'];
    isRemote?: TransactionSigning['isRemote'];
    documentExpirationDate?: TransactionSigning['documentExpirationDate'];
  };
}

export const SigningAppointmentBlock: FC<SigningBlockProps> = ({ signingInfo }) => {
  const {
    signerName,
    signingDate,
    signingLocation = 'N/A',
    notaryName = 'N/A',
    notaryPhone,
    notaryProvider = 'N/A',
    isRemote = false,
    documentExpirationDate,
  } = signingInfo;

  let timeLabel = 'Time';
  let locationLabel = 'Location';
  let notaryLabel = 'Notary';

  if (isRemote) {
    timeLabel = 'Must sign by';
    locationLabel = 'Appointment Type';
    notaryLabel = 'Notary Partner';
  }

  const signingDateText = signingDate ? formatDate(signingDate, DAY_OF_WEEK_MONTH_DAY_TIME) : 'N/A';
  const documentExpirationDateText = documentExpirationDate
    ? formatDate(documentExpirationDate, WEEKDAY_MONTH_DAY)
    : 'N/A';
  const notaryPhoneText = notaryPhone ? formatPhone(notaryPhone) : 'N/A';

  return (
    <Box>
      <Text as="p" fontWeight="semi" mb="space50">
        Signing Appointment Details
      </Text>
      <Stack bg="carbon0" borderRadius="radiusDefault" p="space50" spacing="space50">
        {signerName && (
          <Box>
            <Label>Signer</Label>
            <Text as="p" data-test-id="signer">
              {signerName}
            </Text>
          </Box>
        )}
        <Box>
          <Label>{timeLabel}</Label>
          <Text as="p" data-test-id="signing-time">
            {isRemote ? documentExpirationDateText : signingDateText}
          </Text>
        </Box>
        <Box>
          <Label>{locationLabel}</Label>
          <Text as="p" data-test-id="signing-location">
            {isRemote ? 'Remote online notary' : signingLocation}
          </Text>
        </Box>
        <Box>
          <Label>{notaryLabel}</Label>
          <Text as="p" data-test-id="notary-name">
            {isRemote ? notaryProvider : notaryName}
          </Text>
          {!isRemote && (
            <Text as="p" data-test-id="notary-phone">
              {notaryPhoneText}
            </Text>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export const SigningAppointmentBlockOpposite: FC<SigningBlockProps> = ({ signingInfo }) => {
  const { signerName, signingDate, isRemote = false, documentExpirationDate } = signingInfo;
  const signingDateText = signingDate ? formatDate(signingDate, DAY_OF_WEEK_MONTH_DAY_TIME) : 'N/A';
  const documentExpirationDateText = documentExpirationDate
    ? formatDate(documentExpirationDate, WEEKDAY_MONTH_DAY)
    : 'N/A';

  return (
    <Box>
      <Text as="p" fontWeight="semi" mb="space50">
        Signing Appointment Details
      </Text>
      <Stack bg="carbon0" borderRadius="radiusDefault" p="space50" spacing="space50">
        {signerName && (
          <Box>
            <Label>Signer</Label>
            <Text as="p" data-test-id="signer">
              {signerName}
            </Text>
          </Box>
        )}
        <Box>
          <Label>{isRemote ? 'Must sign by' : 'Time'}</Label>
          <Text as="p" data-test-id="signing-time">
            {isRemote ? documentExpirationDateText : signingDateText}
          </Text>
        </Box>
        {isRemote && (
          <Box>
            <Label>Appointment Type</Label>
            <Text as="p" data-test-id="signing-location">
              Remote online notary
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

SigningAppointmentBlock.displayName = 'SigningAppointmentBlock';
SigningAppointmentBlockOpposite.displayName = 'SigningAppointmentBlockOpposite';
