import React, { FC } from 'react';
import { Box, Text } from '@endpoint/blockparty';
import { DataBlock, Label } from 'components/DataBlock';

import { WireTransferProps } from '..';

export const WireInstructions: FC<WireTransferProps> = ({
  accountNumber,
  bankAddress,
  isUsBank,
  paymentAmount,
  payableName,
  routingNumber,
  swiftCode,
  streetAddress,
  suite,
  cityStateZip,
}) => {
  const dataTestIdPrefix = isUsBank ? 'wire-us' : 'wire-international';

  return (
    <DataBlock layoutType="stack">
      <Box>
        <Label>Amount</Label>
        <Text as="p" data-test-id={`${dataTestIdPrefix}-amount`}>
          {paymentAmount}
        </Text>
      </Box>
      <Box>
        <Label>Payable to</Label>
        <Text as="p" data-test-id={`${dataTestIdPrefix}-payable-name`}>
          {payableName}
        </Text>
      </Box>
      <Box>
        <Label>Receiving Bank</Label>
        <Text as="p" data-test-id={`${dataTestIdPrefix}-receiving-bank-name`}>
          {bankAddress.name}
        </Text>
        <Text as="p" data-test-id={`${dataTestIdPrefix}-receiving-bank-address-line1`}>
          {bankAddress.addressLine1}
        </Text>
        <Text as="p" data-test-id={`${dataTestIdPrefix}-receiving-bank-address-line2`}>
          {bankAddress.addressLine2}
        </Text>
      </Box>
      <Box>
        <Label>Account #</Label>
        <Text as="p" data-test-id={`${dataTestIdPrefix}-account-number`}>
          {accountNumber}
        </Text>
      </Box>
      <Box>
        <Label>Routing #</Label>
        <Text as="p" data-test-id={`${dataTestIdPrefix}-routing-number`}>
          {routingNumber}
        </Text>
      </Box>
      {!isUsBank && (
        <Box>
          <Label>SWIFT Code</Label>
          <Text as="p" data-test-id="wire-international-swift">
            {swiftCode}
          </Text>
        </Box>
      )}
      <Box>
        <Label>Credit Beneficiary</Label>
        <Text as="p" data-test-id="wire-ep-payable-name">
          {payableName}
        </Text>
        <Text as="p" data-test-id="wire-ep-street-address">
          {streetAddress}
        </Text>
        <Text as="p" data-test-id="wire-ep-suite">
          {suite}
        </Text>
        <Text as="p" data-test-id="wire-ep-city-state-zip">
          {cityStateZip}
        </Text>
      </Box>
    </DataBlock>
  );
};

WireInstructions.displayName = 'WireInstructions';
