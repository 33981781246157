import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';

export const getFileExtension = (mimeType: string = ''): string => {
  return mimeType?.replace('.', '').replace('/', '').replace('application', '').replace('image', '');
};

export const getFileNameWithoutExtension = (fileName: string): string => {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
};

export const renderMimetype = (mimeType: string = ''): IconNames => {
  const extension = getFileExtension(mimeType);

  return mimeTypeIconMap[extension] ? mimeTypeIconMap[extension] : 'Paper';
};

export const mimeTypeIconMap: { [index: string]: IconNames } = {
  jpeg: 'Image',
  jpg: 'Image',
  png: 'Image',
  pdf: 'DocumentPDF',
};
