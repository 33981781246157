import React, { useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Heading, Text, Stack, Button, Flex, Link } from '@endpoint/blockparty';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { RouteOptions, SELECT_ROLE_URL } from 'Auth/Utilities/Constant';
import { useNavigate } from 'react-router';
import { useCodeVerify as useCodeVerifyV2 } from 'hooks/auth/useCodeVerify';

import { AppContextType, AppContext } from '../../../utils/context';
import Alerts from '../Alerts';
import { useInputError } from '../../../hooks/useInputError';
import { useAuth } from '../../../hooks/auth/useAuth';
import { MFA_RESEND_CODE_LINK_TEXT, MFA_SUBMIT_BUTTON_TEXT } from '../../../consts/descriptions';

const CONFIRMATION_CODE_WARNING = 'Please enter a 6-digit code';

const MfaSchema = Yup.object().shape({
  code: Yup.string()
    .required(CONFIRMATION_CODE_WARNING)
    .min(6, CONFIRMATION_CODE_WARNING)
    .max(6, CONFIRMATION_CODE_WARNING),
});

interface IMfaFormValues {
  code: string;
}

function Mfa() {
  const navigate = useNavigate();
  const { data } = useAuth();
  const { setAuthFields, authFields }: AppContextType = useContext(AppContext);
  const { email } = authFields;
  const codeError = useInputError(new Map());

  const { codeVerify: codeVerifyV2, codeResend: codeResendV2 } = useCodeVerifyV2();

  useEffect(() => {
    if (!data.username) {
      navigate(RouteOptions.SIGN_IN);
    }
  }, [navigate, email, data.username]);

  const codeSubmit = (values: IMfaFormValues) => {
    setAuthFields({
      ...authFields,
      navigateToAfterLogin: SELECT_ROLE_URL,
    });

    codeVerifyV2(values.code);
  };

  return (
    <Formik initialValues={{ code: '' }} validateOnBlur validationSchema={MfaSchema} onSubmit={codeSubmit}>
      {({ isValid, dirty }) => (
        <Form>
          <Box mb="space70" textAlign="center">
            <Heading as="h2" mb="space50" size="fontSize60">
              Enter your 6-digit code
            </Heading>
            <Text>
              A code has been sent via text message to the phone number on file. It may take a few minutes to arrive.
            </Text>
          </Box>
          <Alerts />
          <Stack mb="space80" spacing="space60">
            <FormikInputGroup
              autoFocus
              error={codeError}
              label="6-digit code"
              name="code"
              placeholder="Enter your code"
              type="number"
            />
            <Text>
              <Link color="blue500" cursor="pointer" textDecoration="underline" onClick={codeResendV2}>
                {MFA_RESEND_CODE_LINK_TEXT}
              </Link>
            </Text>
          </Stack>
          <Flex justifyContent="flex-end">
            <Button isDisabled={!(isValid && dirty)} type="submit">
              {MFA_SUBMIT_BUTTON_TEXT}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default Mfa;
