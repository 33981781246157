import { MutationHookOptions, OperationVariables, useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { CreateUnassignedDocumentInput, Document } from '@endpoint/endpoint-bff-graphql-schema';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { CREATE_UNASSIGNED_DOCUMENT } from './mutations';

export const useCreateDocument = (config: MutationHookOptions) => {
  const sentryTransactionCreateDocument = useSentryTransaction('createDocument', 'GraphQL client mutation');

  const [
    createUnassignedDocumentMutation,
    {
      data: createUnassignedDocumentResult,
      loading: createUnassignedDocumentLoading,
      error: createUnassignedDocumentError,
    },
  ] = useMutation<{ createUnassignedDocument: Document }, OperationVariables>(CREATE_UNASSIGNED_DOCUMENT, config);

  const createUnassignedDocument = async (input: CreateUnassignedDocumentInput) => {
    await createUnassignedDocumentMutation({
      variables: { input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionCreateDocument.toTraceparent(),
        },
      },
    });

    sentryTransactionCreateDocument.finish();
  };

  return {
    createUnassignedDocument,
    createUnassignedDocumentResult,
    createUnassignedDocumentLoading,
    createUnassignedDocumentError,
  };
};
