import React, { FC } from 'react';
import { Box, Flex, SkeletonBlock } from '@endpoint/blockparty';

export const PropertyHeaderSkeleton: FC = () => {
  return (
    <Box bg="white" data-test-id="property-header-skeleton" gridColumn="1 / 3" mb={{ base: 'space50', md: 'space0' }}>
      <Flex alignItems="center" px="space50" py="18px">
        <SkeletonBlock height={40} maxWidth={40} mr="space50" />
        <Box flex={1}>
          <SkeletonBlock height={12} maxWidth={280} mb="space40" />
          <SkeletonBlock height={12} maxWidth={90} />
        </Box>
      </Flex>
    </Box>
  );
};
