import { downloadMultipleFiles, downloadSingleFile } from 'routes/TransactionDetail/Documents/downloadDocuments';
import { DocumentTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import { TransactionDocument } from 'utils/documents';

// Below functions are abstracted from Documents page, but is needed for both:
// 1. multiple zipped file downloads
// 2. individual downloads on mapped documents
export const downloadDocuments = (documents: TransactionDocument[]): Promise<Blob[] | Blob> => {
  trackAction(DocumentTrackingEvents.DOWNLOAD_DOC);

  if (documents.length === 1) {
    return downloadSingleFile(documents[0]);
  }

  return downloadMultipleFiles(documents);
};
