import React, { FC, useContext } from 'react';
import { Link } from '@endpoint/blockparty';
import { Color } from '@endpoint/blockparty/dist/components/Box/types/color';
import { LinkClickedTrackingPayload, SignInTrackingEvents, TrackingEventType } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { AppContext, AppContextType } from '../../../utils/context';
import { RouteOptions } from '../../Utilities/Constant';

interface ISignUpLinkProps {
  color?: Color;
}

const SIGN_UP_LINK_TEXT = 'Sign up';

export const SignUpLink: FC<ISignUpLinkProps> = ({ color }: ISignUpLinkProps) => {
  const { authFields, setAuthFields }: AppContextType = useContext(AppContext);

  const signUp = () => {
    setAuthFields({ ...authFields, errorCode: null });
    const trackSignUpProperties: LinkClickedTrackingPayload = {
      name: TrackingEventType.LINK_CLICKED,
      linkId: SIGN_UP_LINK_TEXT,
    };

    trackAction(SignInTrackingEvents.CLICKED_SIGN_UP_LINK, trackSignUpProperties);
  };

  return (
    <Link
      color={color}
      cursor="pointer"
      dataTestId="sign-up-link"
      href={RouteOptions.SIGN_UP}
      textDecoration="underline"
      {...{ onClick: signUp }}
    >
      {SIGN_UP_LINK_TEXT}
    </Link>
  );
};
