import React, { FC, useEffect } from 'react';
import { Button, Flex, Heading } from '@endpoint/blockparty';
import { useNavigate, useLocation } from 'react-router-dom';
import { TRANSACTIONS } from 'consts/routes';

export const NoMatch: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // handles cases where previous root was bookmarked or saved by broswer
  useEffect(() => {
    if (pathname.startsWith('/beta') || pathname.startsWith('beta')) {
      navigate(`/${TRANSACTIONS}`);
    }
  }, [navigate, pathname]);

  return (
    <>
      <Flex alignItems="center" flexDirection="column" height="100%" justifyContent="center">
        <Heading>Sorry, we couldn’t find that page</Heading>
        <Button mt="space60" onClick={() => navigate(`/${TRANSACTIONS}`)}>
          Back to Transactions
        </Button>
      </Flex>
    </>
  );
};
