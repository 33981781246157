import React, { FC, useCallback, useState } from 'react';
import { Box, ProgressTracker, ProgressTrackerItem } from '@endpoint/blockparty';
import { MilestoneStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';

import { ProgressCarousel } from '../ProgressCarousel';

export const ProgressCardView: FC = () => {
  const { milestones } = useTransactionDetailsContext();
  const stepsCompleted = milestones.filter((milestone) => milestone.status === MilestoneStatus.COMPLETE).length;
  const stepUpcomingIndex = milestones.findIndex((milestone) => milestone.status !== MilestoneStatus.COMPLETE);

  const getActiveStep = () => {
    // active state on first Upcoming step
    if (stepUpcomingIndex > -1) {
      return stepUpcomingIndex + 1;
    }

    // active state on last step
    return stepsCompleted;
  };

  const getMilestoneStatus = (status: MilestoneStatus) => {
    switch (status) {
      case 'COMPLETE':
        return 'completed';

      case 'INTERMEDIATE':
        return 'current';

      default:
        return 'upcoming';
    }
  };

  const [activeStep, setActiveCurrentProgressStep] = useState<number>(getActiveStep);

  const handleActiveStep = useCallback((step: number) => {
    setActiveCurrentProgressStep(step);
  }, []);

  return (
    <Box data-test-id="progress-card-view">
      <ProgressTracker activeStep={activeStep} isInteractive>
        {milestones.map((milestone, i) => {
          return (
            <ProgressTrackerItem
              key={milestone.id}
              status={stepUpcomingIndex === i ? 'current' : getMilestoneStatus(milestone.status)}
            />
          );
        })}
      </ProgressTracker>
      <ProgressCarousel activeStep={activeStep} handleActiveStep={handleActiveStep} />
    </Box>
  );
};
