import React, { FC } from 'react';
import {
  Box,
  Flex,
  Text,
  useSelectStateAlpha,
  SelectAlpha,
  SelectDropdownAlpha,
  SelectItemAlpha,
  Icon,
} from '@endpoint/blockparty';

import { ProgressCardView } from './ProgressCardView';
import { ProgressListView } from './ProgressListView';

const progressViewOptions: {
  label: string;
  value: string;
  iconName: 'GridView' | 'ListView';
}[] = [
  { value: 'detail', label: 'Detail View', iconName: 'GridView' },
  { value: 'list', label: 'List View', iconName: 'ListView' },
];

export const ProgressTracker: FC = () => {
  const select = useSelectStateAlpha({ defaultValue: 'detail', sameWidth: false });
  const isDetailView = select?.value === 'detail';
  const iconName = isDetailView ? 'GridView' : 'ListView';

  return (
    <Box bg="background" mt={{ base: 'space40', md: 'space60' }} p="space50">
      <Flex alignItems="center" justifyContent="space-between" mb="space60">
        <Text as="h5" size="fontSize40">
          Progress
        </Text>
        <Box>
          <SelectAlpha pl="space30" state={select}>
            <Icon color="carbon300" name={iconName} size="large" />
          </SelectAlpha>
          <SelectDropdownAlpha state={select}>
            {progressViewOptions.map((option) => (
              <SelectItemAlpha key={option.value} value={option.value}>
                <Icon color="carbon300" mr="space30" name={option.iconName} size="large" />
                <Text>{option.label}</Text>
              </SelectItemAlpha>
            ))}
          </SelectDropdownAlpha>
        </Box>
      </Flex>
      {isDetailView ? <ProgressCardView /> : <ProgressListView />}
    </Box>
  );
};
