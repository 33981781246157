import React, { useContext } from 'react';
import { Alert, AlertIcon, AlertContent, AlertTitle, AlertDescription } from '@endpoint/blockparty';

import { AppContext, AppContextType } from '../../../utils/context';
import { getErrorDetails } from '../../Utilities/helper';

function Alerts() {
  const { authFields }: AppContextType = useContext(AppContext);
  const { level, alert } = getErrorDetails(authFields.errorCode);

  return (
    <>
      {!!authFields.errorCode && !!alert && (
        <Alert data-test-id="alert-component" mb="space70" status={level}>
          <AlertIcon />
          <AlertContent>
            <AlertTitle as="p">{alert.title}</AlertTitle>
            <AlertDescription as="p">{alert.description}</AlertDescription>
          </AlertContent>
        </Alert>
      )}
    </>
  );
}

export default Alerts;
