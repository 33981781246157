import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';

export const getEmptyFieldValues = (multipleInputField: FieldOptions): GenericObject => {
  let emptyValues: GenericObject = {};

  multipleInputField.children?.forEach((child) => {
    const fieldId = child.field?.id;

    if (fieldId) emptyValues = { ...emptyValues, [fieldId]: '' };
  });

  return emptyValues;
};
