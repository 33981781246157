import { useContext } from 'react';
import { AppContextType, AppContext } from 'utils/context';
import { getLegacyErrorCode } from 'Auth/Utilities/helper';
import { gql, useMutation } from '@apollo/client';
import { clearTokensV2, getAccessTokenV2 } from 'utils/auth/storage';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { SignOutInput, SignOutResponse } from '@endpoint/endpoint-bff-graphql-schema';

export const SIGN_OUT_MUTATION = gql`
  mutation SignOut($input: SignOutInput!) {
    signOut(input: $input) {
      success
      errorMessage
    }
  }
`;

export const useSignOut = () => {
  const sentryTransaction = useSentryTransaction('signOut', 'GraphQL client mutation');
  const { setError }: AppContextType = useContext(AppContext);
  const [signOutMutation] = useMutation<{ signOut: SignOutResponse }, { input: SignOutInput }>(SIGN_OUT_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  return async () => {
    try {
      const token = await getAccessTokenV2();

      setError(null);

      const { data } = await signOutMutation({
        variables: {
          input: {
            authorization: `Bearer ${token}`,
          },
        },
      });

      if (!data?.signOut?.success) {
        throw new Error(data?.signOut?.errorMessage ?? 'An unknown error occurred');
      }
    } catch (error: any) {
      const errorCode = getLegacyErrorCode(error?.message ?? error);

      setError(errorCode);
    } finally {
      await clearTokensV2();
    }
  };
};
