// import { importedService } from '';
// @TODO replace with logging service
const importedService = console.log;

function logger<P>(loggingService: VoidFunc<P>) {
  return (params: P) => {
    loggingService(params);
  };
}

//----------------------------------------------------------------------------

export const logTest = logger;
export const devLogger = logger(console.log);
export const Log = logger(importedService);
