import {
  Transaction,
  TransactionStatus as LegacyTransactionStatus,
} from '@endpoint/platform-api-connector/dist/graphql-types';
import { Market, TransactionStatus, TransactionSummary } from '@endpoint/endpoint-bff-graphql-schema';

import { getDistinctMarket } from './getDistinctMarket';
import { User } from '../context';

export const setMarket = (transactions: Transaction[], markets: Market[]) => {
  const openTransactions = transactions.filter(
    (trans) => trans.status === LegacyTransactionStatus.IN_ESCROW && trans.property.address,
  );
  const markedIds = openTransactions.map((transaction) => transaction.marketId as number);

  if (markedIds && !!window.$zopim && typeof window.$zopim === 'function') {
    window.$zopim(() => {
      setMarketTag(getDistinctMarket(markedIds, markets));
    });
  }
};

export const setMarketForTransactionSummary = (transactions: TransactionSummary[], markets: Market[]) => {
  const openTransactions = transactions.filter(
    (transaction) => transaction.status === TransactionStatus.IN_ESCROW && transaction.property.address,
  );
  const markedIds = openTransactions.map((transaction) => transaction.marketId);

  if (markedIds && !!window.$zopim && typeof window.$zopim === 'function') {
    window.$zopim(() => {
      setMarketTag(getDistinctMarket(markedIds, markets));
    });
  }
};

export const openChatWindow = (userInfo: User, zendeskTag: string | undefined) => {
  const { firstName, lastName, email } = userInfo;

  const capitalFullName = `${firstName} ${lastName}`;

  if (!!window.$zopim && typeof window.$zopim === 'function') {
    window.$zopim(() => {
      window.$zopim.livechat.setEmail(email);
      window.$zopim.livechat.setName(capitalFullName);
      window.$zopim.livechat.button.show();
      window.$zopim.livechat.window.show();

      if (!window.$zopim.livechat.isChatting()) {
        setTransactionFileNumberTag(userInfo.lastTransactionViewed?.fileNum);
        setMarketTag(zendeskTag); // set market based on last viewed transaction
      }
    });
  }
};

const setTransactionFileNumberTag = (transactionNumber: string | undefined) => {
  const NO_RESWARE_FILE = 'No Resware File Available';
  const transactionNumberTag = transactionNumber ? `FileNumber: ${transactionNumber}` : NO_RESWARE_FILE;

  if (transactionNumber) {
    window.$zopim.livechat.removeTags(NO_RESWARE_FILE);
  }

  window.$zopim.livechat.addTags(transactionNumberTag);
};

/**
 * Assigns a market tag to the chat, which is used to direct chats according to ZD configuration.
 * If market cannot be determiined then no tag will be added, and chat will be directed to general CX group configured in ZD.
 * @param market if present, add as market tag
 */
const setMarketTag = (market: string | undefined) => {
  if (market) {
    window.$zopim.livechat.addTags(market);
  }
};
