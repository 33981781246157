import * as Sentry from '@sentry/react';

export function localStorageSetItem(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value);
  } catch (err) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error while setting local storage item: ${err}`,
    });
  }
}

export function localStorageGetItem(key: string): string | null {
  try {
    return window.localStorage.getItem(key);
  } catch (err) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error while getting local storage item: ${err}`,
    });
  }

  return null;
}

export function localStorageRemoveItem(key: string) {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error while removing local storage item: ${err}`,
    });
  }
}
