import React from 'react';
import { Box, CloseButton, Flex, Icon, Text } from '@endpoint/blockparty';

import { UploadedFileProps } from '..';

export const UploadFailed = ({ uploadedFile }: { uploadedFile: UploadedFileProps }) => {
  return (
    <Flex
      alignItems="center"
      backgroundColor="watermelon0"
      border="1px solid"
      borderColor="watermelon500"
      borderRadius="radiusDefault"
      flexDirection="row"
      justifyContent="space-between"
      px="space50"
      py="20px"
    >
      <Box>
        <Icon color="watermelon500" dataTestId="icon-error-circle" mr="space50" name="ErrorCircle" />
        <Text>{uploadedFile.name}</Text>
      </Box>
      <CloseButton
        onClick={() => {
          uploadedFile.close && uploadedFile.close();
        }}
      />
    </Flex>
  );
};
