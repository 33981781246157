import {
  Condition,
  ConditionalGroup,
  ConditionOperator,
  FieldValueComparator,
} from '@endpoint/endpoint-bff-graphql-schema';

const isConditionMet = (condition: Condition, fieldValues: GenericObject): boolean => {
  if (condition.fieldValueComparator === FieldValueComparator.EQUAL) {
    return fieldValues[condition.fieldId] === condition.valueToCompare;
  }

  return false;
};

export const isConditionalGroupSatisfied = (
  conditionalGroup: ConditionalGroup,
  fieldValues: GenericObject,
): boolean => {
  const { conditionOperator, conditions = [] } = conditionalGroup;

  if (conditionOperator === ConditionOperator.OR) {
    return conditions.some((condition) => isConditionMet(condition, fieldValues));
  }

  if (conditionOperator === ConditionOperator.AND) {
    return conditions.every((condition) => isConditionMet(condition, fieldValues));
  }

  return false;
};
