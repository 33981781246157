import React, { FC } from 'react';
import { Box, SkeletonBlock } from '@endpoint/blockparty';

export const TodoProgressSkeleton: FC = () => {
  return (
    <>
      <Box bg="white" p="space60">
        <SkeletonBlock height={12} maxWidth={183} mb="space50" />
        <SkeletonBlock height={16} maxWidth={232} mb="space60" />
        <SkeletonBlock height={4} maxWidth="none" mb="space70" />
        <SkeletonBlock height={12} maxWidth={232} mb="space60" />
        <SkeletonBlock height={12} maxWidth={232} mb="space60" />
        <SkeletonBlock height={12} maxWidth={232} mb="space60" />
        <SkeletonBlock height={12} maxWidth={232} mb="space60" />
        <SkeletonBlock height={12} maxWidth={232} mb="space60" />
      </Box>
    </>
  );
};
