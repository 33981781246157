import React, { FC, useCallback } from 'react';
import { Icon, Stack } from '@endpoint/blockparty';
import { openChatWindow } from 'utils/zendesk';
import { useAppContext } from 'utils/context';
import { trackAction } from 'utils/analytics';
import { ChatEvents } from 'consts/analytics';
import { useGetZendeskTag } from 'hooks/useGetZendeskTag';
import { getConfig } from 'utils/config';

import { HeaderAvatar } from '../HeaderAvatar';

export const HeaderRight: FC = () => {
  const { user } = useAppContext();
  const { ZENDESK_APP_ID } = getConfig();

  const zendeskTag = useGetZendeskTag(user.lastTransactionViewed?.marketId?.valueOf());
  const openChat = useCallback(() => {
    trackAction(ChatEvents.OPEN_CHAT_NAV_BAR);
    openChatWindow(user, zendeskTag);
  }, [user, zendeskTag]);

  return (
    <Stack alignItems="center" direction="row" height="100%" spacing="space60">
      {ZENDESK_APP_ID && (
        <Icon
          color="background"
          cursor="pointer"
          data-test-id="zendesk-icon"
          name="ChatHelp"
          size="large"
          onClick={openChat}
        />
      )}
      <HeaderAvatar />
    </Stack>
  );
};
