import { Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { isNewLoginSystemEnabled } from 'Auth/Utilities/helper';
import refreshTokens from 'utils/auth/refreshTokens';

import * as storage from './storage';

export const renewToken = () => {
  const newLoginSystemEnabled = isNewLoginSystemEnabled();

  if (newLoginSystemEnabled) {
    return refreshTokens();
  }

  return storage.renewToken();
};

export const authLink = setContext(async (_, { headers }) => {
  let token;
  const isMTEPSignInSignUpEnabled = isNewLoginSystemEnabled();

  if (isMTEPSignInSignUpEnabled) {
    token = await storage.getAccessTokenV2();
  } else {
    token = await storage.getAccessToken();
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const refreshTokenLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const errorCode = graphQLErrors?.[0]?.extensions?.code || graphQLErrors?.[0]?.message;
  const networkMessage = (networkError && JSON.stringify(networkError)) || '';

  if (errorCode === 'UNAUTHENTICATED' || networkMessage.indexOf('BAD_TOKEN') > -1) {
    const oldHeaders = operation.getContext().headers;

    return new Observable((observer) => {
      renewToken()
        .then((renewedToken) => {
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${renewedToken}`,
            },
          });
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          Sentry.captureEvent({
            level: Sentry.Severity.Info,
            message: `refreshTokenLink error: ${JSON.stringify(error)}`,
          });
        });
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  return undefined;
});
