import React, { FC } from 'react';
import { Flex, Text, Box } from '@endpoint/blockparty';

export const WebViewDocumentsListHeader: FC = () => {
  return (
    <Flex justifyContent="space-between">
      <Box flex={1}>
        <Flex
          alignContent="space-between"
          alignItems="flex-start"
          flexDirection={{ base: 'column', md: 'row' }}
          p="space40"
        >
          <Box data-test-id="document" flex={1}>
            <Text color="carbon500">Document</Text>
          </Box>
        </Flex>
      </Box>
      <Text as="p" color="carbon500" data-test-id="note" m="space40">
        Note
      </Text>
    </Flex>
  );
};
