import React, { FC } from 'react';

import { SignInLink } from './SignInLink';

export const UserAlreadyExistError: FC = () => (
  <>
    {'Sorry, this email is already in use. Is this you? '}
    <SignInLink color="watermelon500" />
  </>
);
