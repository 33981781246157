import React, { FC, useContext, useEffect } from 'react';
import { Heading, Flex, Image, Button, Box, Text, Link } from '@endpoint/blockparty';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TRANSACTION_LISTINGS } from 'consts/routes';

import { FormikValue } from '.';
import { OrderReportContextValue, OrderReportContext } from './Context';

export const Step3: FC<CurrentStepComponentProps<FormikValue>> = () => {
  const navigate = useNavigate();

  const orderReportContextValue: OrderReportContextValue = useContext(OrderReportContext);

  useEffect(() => {
    orderReportContextValue.setCurrentStep(3);
  }, [orderReportContextValue]);

  return (
    <>
      <Flex justifyContent="center" mb="space70">
        <Image
          alt="Thank you"
          height="128px"
          src="https://images.ctfassets.net/py22ts3k5d4r/6ja6hUnRpWcOvKevwTrCg9/d02c9cb5c58e4354207560d7f9560357/Illustration_HighFive.svg?h=250"
          width="128px"
        />
      </Flex>
      <Heading as="h1" mb="space70" size="fontSize50">
        Woohoo!
      </Heading>
      <Box pb="space70">
        <Text>Thanks for sending us your order!</Text>
      </Box>
      <Box>
        <Heading as="h2" pb="space50" size="fontSize30">
          What’s Next?
        </Heading>
        <Text as="p" pb="space50">
          Our team is processing your order and will notify you once it’s ready. Please note it may take up to 48 hours.
        </Text>
        <Text as="p" pb="space50">
          You will be able to view order content after the order has been delivered.
        </Text>
        <Text as="p">
          Please note, it will take a few minutes for your transaction&apos;s file number to be created. Once ready, it
          can be found in the{' '}
          <Link
            as={RouterLink}
            color="blue500"
            fontWeight="normal"
            size="fontSize20"
            textDecoration="underline"
            to={TRANSACTION_LISTINGS}
          >
            Listings
          </Link>{' '}
          section on your Transactions page.
        </Text>
      </Box>

      <Flex justifyContent="flex-end" mt="space80">
        <Button onClick={() => navigate(TRANSACTION_LISTINGS)}>Got It</Button>
      </Flex>
    </>
  );
};
