import React, { FC, useContext, useEffect } from 'react';
import { Box, Flex, Image, Heading, Text, Link, Button } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';
import { CompanyContext, AppContext, AppContextType, useAppConfigsContext } from 'utils/context';
import { RouteOptions, NOTIFICATION_PREFERENCE_URL } from 'Auth/Utilities/Constant';

import astronaut from './Illustration_Space.png';

const OutOfCoverage: FC = () => {
  const { CONTACT_US_URL } = useAppConfigsContext();
  const navigate = useNavigate();
  const { companyName } = useContext(CompanyContext);
  const { user, setUser }: AppContextType = useContext(AppContext);

  const handleSubmit = () => {
    setUser({ ...user, onboardingUrl: NOTIFICATION_PREFERENCE_URL });
  };

  useEffect(() => {
    if (!user) {
      navigate(RouteOptions.SIGN_IN);
    }
  }, [navigate, user]);

  return (
    <>
      <Flex justifyContent="center" mb="space80" mt={{ base: '96px', md: 'space0' }}>
        <Image alt="Out of Coverage" height="176px" src={astronaut} width="176px" />
      </Flex>
      <Flex justifyContent="center" mb="space50">
        <Heading as="h1" size="fontSize50">
          {companyName} isn&apos;t in your area yet
        </Heading>
      </Flex>
      <Flex justifyContent="center" mb="space50">
        <Box lineHeight="lineHeight20">
          <Text>
            {companyName} is currently only available in{' '}
            <Link color="blue500" href={CONTACT_US_URL} isExternal textDecoration="underline">
              select locations.
            </Link>
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="center" mb="space80">
        <Box lineHeight="lineHeight20">
          <Text as="p" textAlign="center">
            If you&apos;d like to continue creating an account, our team will let you know once we&apos;re available in
            your location. Please note we will not be able to process your orders until we are in your area. Sit tight –
            we’re on our way!
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="center">
        <Button data-test-id="submit" type="submit" onClick={handleSubmit}>
          Continue Creating Account
        </Button>
      </Flex>
    </>
  );
};

export default OutOfCoverage;
