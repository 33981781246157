import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { ProfileUpdateInput } from '@endpoint/endpoint-bff-graphql-schema';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { ProfileUpdateData, ProfileUpdateVariables, REQUEST_PROFILE_UPDATE } from './mutations';

export const useRequestProfileUpdate = () => {
  const sentryTransactionRequestProfileUpdate = useSentryTransaction('requestProfileUpdate', 'GraphQL client mutation');

  const [
    requestProfileUpdateMutation,
    { data: requestProfileUpdateResult, loading: requestProfileUpdateLoading, error: requestProfileUpdateError },
  ] = useMutation<ProfileUpdateData, ProfileUpdateVariables>(REQUEST_PROFILE_UPDATE, { errorPolicy: 'all' });

  const requestProfileUpdate = useCallback(
    async (input: ProfileUpdateInput) => {
      await requestProfileUpdateMutation({
        variables: { data: input },
        context: {
          clientName: BFF_CLIENT_NAME,
          headers: {
            [SENTRY_TRACE_HEADER_NAME]: sentryTransactionRequestProfileUpdate.toTraceparent(),
          },
        },
      });

      sentryTransactionRequestProfileUpdate.finish();
    },
    [requestProfileUpdateMutation, sentryTransactionRequestProfileUpdate],
  );

  return {
    requestProfileUpdate,
    requestProfileUpdateResult,
    requestProfileUpdateLoading,
    requestProfileUpdateError,
  };
};
