import { gql } from '@apollo/client';
import { ProfileUpdateInput, ProfileUpdateResponse } from '@endpoint/endpoint-bff-graphql-schema';

export interface ProfileUpdateData {
  RequestProfileUpdate: ProfileUpdateResponse;
}

export interface ProfileUpdateVariables {
  data: ProfileUpdateInput;
}
export const REQUEST_PROFILE_UPDATE = gql`
  mutation RequestProfileUpdate($data: ProfileUpdateInput!) {
    requestProfileUpdate(input: $data) {
      fileNumber
      email
      firstName
      lastName
      phone
      middleName
      licenseNumber
    }
  }
`;
