import React, { FC } from 'react';
import { NavBar as SubNavBar, EPSubHeader } from '@endpoint/blockparty';
import { CENTERED_CONTENT_WIDTH } from 'consts/responsive';

import { SettingsRoutes } from '../routes';
import { NavItemSettings } from './NavItemSettings';

export const SubHeaderSettings: FC = (props) => {
  return (
    <EPSubHeader {...props}>
      <SubNavBar
        margin="0 auto"
        maxWidth={CENTERED_CONTENT_WIDTH}
        underlineColor="brandPrimary500"
        underlineHeight={4}
        width="100%"
      >
        {SettingsRoutes.map(({ route, label }) => {
          return (
            <NavItemSettings key={`sub-nav-bar-item-${label}`} data-test-id={`sub-nav-bar-item-${label}`} route={route}>
              {label}
            </NavItemSettings>
          );
        })}
      </SubNavBar>
    </EPSubHeader>
  );
};
