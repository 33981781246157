import React, { FC, useEffect, useContext } from 'react';
import { Box, Heading, Text, Image, Flex, Loading } from '@endpoint/blockparty';
import { AppContextType, AppContext } from 'utils/context';
import { useHandleAuthResponse } from 'hooks/auth/useHandleAuthResponse';

const ResetSuccessMfa: FC = () => {
  const { authFields, setUser, user }: AppContextType = useContext(AppContext);
  const handleAuthResponse = useHandleAuthResponse();

  const TIME_TO_REDIRECT = 3000;

  useEffect(() => {
    const timerId = setTimeout(() => {
      setUser({ ...user, refetch: true });

      if (authFields?.accessToken && authFields?.idToken) {
        handleAuthResponse(
          { authentication: { accessToken: authFields?.accessToken, idToken: authFields?.idToken } },
          undefined,
          authFields.navigateToAfterLogin,
        );
      }
    }, TIME_TO_REDIRECT);

    return () => clearTimeout(timerId);
  }, [user, setUser, authFields, handleAuthResponse]);

  return (
    <>
      <Flex justifyContent="center" mb="space70">
        <Image
          alt="Thank you"
          height="128px"
          src="https://images.ctfassets.net/py22ts3k5d4r/6ja6hUnRpWcOvKevwTrCg9/d02c9cb5c58e4354207560d7f9560357/Illustration_HighFive.svg?h=250"
          width="128px"
        />
      </Flex>
      <Box mb="space70" textAlign="center">
        <Heading as="h2" mb="space40" size="fontSize60">
          Awesome!
          <br />
          Your new password is good to go.
        </Heading>
        <Text>One moment while we log you into your account...</Text>
        <br />
        <br />
        <Loading mt="space40" size="medium" />
      </Box>
    </>
  );
};

export default ResetSuccessMfa;
