import { UserNotFoundError } from 'Auth/Components/Signin/UserNotFoundError';
import { UserAlreadyExistError } from 'Auth/Components/Signup/UserAlreadyExistError';
import { CodeMismatchExceptionError } from 'Auth/Components/MfaSignUp/CodeMismatchExceptionError';
import { ContactType, ParticipantRole } from '@endpoint/endpoint-bff-graphql-schema/dist/types';

import {
  EMPTY_STRING,
  ErrorCodes,
  NOTIFICATION_PREFERENCE_URL,
  SELECT_ROLE_URL,
  SET_UP_PROFILE_URL,
  StrongPasswordType,
  PASSWORD_LENGTH,
} from './Constant';

enum ErrorLevel {
  INFO = 'info',
  ERROR = 'error',
}

interface ErrorDetails {
  level: ErrorLevel;
  alert?: {
    title?: string;
    description?: string;
  };
  inputError?: string;
}

const extractPhoneNumber = (user: any, resetPassword: boolean): string => {
  const phoneNumber =
    (resetPassword ? user?.CodeDeliveryDetails?.Destination : user?.challengeParam?.CODE_DELIVERY_DESTINATION) || '';
  const phoneEndingIn = phoneNumber.substring(phoneNumber.length - 4);

  return phoneEndingIn;
};

const PHONE_NUMBER_PREFIX = '+1';

const getPhoneNumber = (phone: string): string | null =>
  phone ? `${PHONE_NUMBER_PREFIX}${phone.replace(/\D/g, '')}` : null;

/**
 * Format Phone number for the MFA sign up flow
 * @param phoneNumber +15555555555
 * @returns phoneNumber as +1 (555) 555-5555
 */
const getMfaDisplayPhoneNumber = (phoneNumber: string): string => {
  const numbers = phoneNumber?.replace(/\D/g, '');

  return `+1 (${numbers?.substring(1, 4)}) ${numbers?.substring(4, 7)}-${numbers?.substring(7)}`;
};

const errorDetails = new Map()
  .set(ErrorCodes.UserNotFoundException, {
    level: 'error',
    inputError: 'Sorry, we could not find an account associated with this email.',
  })
  .set(ErrorCodes.NotAuthorizedException, {
    level: 'error',
    alert: {
      title: 'Hmm.. looks like something isn’t matching up.',
      description: 'Please re-enter your email and password and try again.',
    },
  })
  .set(ErrorCodes.CodeMismatchException, {
    level: 'error',
    alert: {
      title: 'The code you entered is incorrect',
      description: EMPTY_STRING,
    },
    inputError: 'Please re-enter your 6-digit code',
  })
  .set(ErrorCodes.CustomCodeResend, {
    level: 'info',
    alert: {
      title: 'A code has been resent to your mobile phone',
      description: EMPTY_STRING,
    },
  })
  .set(ErrorCodes.UserNotConfirmedException, {
    level: 'error',
    alert: {
      title: 'The code you entered is incorrect',
      description: 'Please re-enter your 6-digit code',
    },
    inputError: 'Please re-enter your 6-digit code',
  })
  .set(ErrorCodes.UsernameExistsException, {
    level: 'error',
    inputError: 'Sorry, this email is already in use',
  })
  .set(ErrorCodes.SomethingWentWrong, {
    level: 'error',
    alert: {
      title: 'Oops, something went wrong, try again',
    },
  })
  .set(ErrorCodes.SsoUserPasswordResetException, {
    level: 'error',
    alert: {
      title: 'Single Sign-On is enabled for this email address.',
      description: 'Please contact your system administrator for the password.',
    },
  })
  .set(ErrorCodes.InvalidPasswordException, {
    level: 'error',
    alert: {
      title: 'Unexpected error',
      description:
        'Provided password cannot be used for security reasons. Please select a different password and try again.',
    },
  })
  .set(ErrorCodes.InvalidSignInPasswordException, {
    level: 'error',
    alert: {
      title: 'Unexpected error',
      description: 'Provided password cannot be used for security reasons. Please reset your password to sign in.',
    },
  })
  .set(ErrorCodes.ForbiddenException, {
    level: 'error',
    alert: {
      title: 'Unexpected error',
      description:
        'This action cannot be completed as the Endpoint application cannot be accessed in your current country. Please contact us at (866) 230-7571 for assistance.',
    },
  });

const getErrorDetails = (errorCode: ErrorCodes | null): ErrorDetails =>
  errorDetails.get(errorCode) || {
    level: 'error',
    alert: {
      title: 'Unexpected error',
      description: 'Please, try again later',
    },
  };

const signInEmailErrors = new Map<ErrorCodes, React.ReactNode>().set(
  ErrorCodes.UserNotFoundException,
  UserNotFoundError,
);

const signUpEmailErrors = new Map<ErrorCodes, React.ReactNode>().set(
  ErrorCodes.UsernameExistsException,
  UserAlreadyExistError,
);

const signUpMfaErrors = new Map<ErrorCodes, React.ReactNode>().set(
  ErrorCodes.CodeMismatchException,
  CodeMismatchExceptionError,
);

const isMeetingStrongPasswordCriteria = (type: StrongPasswordType, value: string) => {
  switch (type) {
    case StrongPasswordType.Miminum:
      return value.length >= PASSWORD_LENGTH;
    case StrongPasswordType.UpperCase:
      return value.match(/[A-Z]/);
    case StrongPasswordType.LowerCase:
      return value.match(/[a-z]/);
    case StrongPasswordType.Number:
      return value.match(/[0-9]/);
    case StrongPasswordType.SpecialCharacter:
      return value.match(/\W|_/g);
    case StrongPasswordType.All:
      return (
        value.length >= PASSWORD_LENGTH &&
        value.match(/[A-Z]/) &&
        value.match(/[a-z]/) &&
        value.match(/[0-9]/) &&
        value.match(/\W|_/g)
      );
    default:
      return false;
  }
};

export const INCORRECT_PASSWORD_ATTEMPT = 'Password attempts exceeded';
const INCORRECT_MFA_ATTEMPT =
  'Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.';
const INCORRECT_RESEND_CODE_ATTEMPT = 'Attempt limit exceeded, please try after some time.';
const INCORRECT_ATTEMPT = [INCORRECT_PASSWORD_ATTEMPT, INCORRECT_MFA_ATTEMPT, INCORRECT_RESEND_CODE_ATTEMPT];

const isIncorrectAttempt = (error: Error): boolean => INCORRECT_ATTEMPT.includes(error.message);
const isNotConfirmedUser = (error: Error): boolean => error.name === ErrorCodes.UserNotConfirmedException;

// TODO: Needs verification that these error messages are the same from BFF
const isIncorrectAttemptV2 = (error: string): boolean => INCORRECT_ATTEMPT.includes(error);

const getCurrentStep = (pathname: string): number => {
  let currentStep = 0;

  if (pathname === SELECT_ROLE_URL) {
    currentStep = 1;
  } else if (pathname === SET_UP_PROFILE_URL) {
    currentStep = 2;
  } else if (pathname === NOTIFICATION_PREFERENCE_URL) {
    currentStep = 3;
  }

  return currentStep;
};

const getContactType = (contactType: string): ContactType => {
  switch (contactType) {
    case 'realEstateAgent':
      return ContactType.AGENT;
    case 'assistantOrTransactionCoordinator':
      return ContactType.TRANSACTION_COORDINATOR;
    case 'mortgageBroker':
      return ContactType.MORTGAGE_BROKER;
    case 'lender':
      return ContactType.LOAN_PROCESSOR;
    case 'loanOfficer':
      return ContactType.LOAN_OFFICER;
    default:
      return ContactType.TRANSACTEE_INDIVIDUAL;
  }
};

const getUserRolesForSignupSegmentTracking = (
  contactTypeRoles?: Array<string> | null,
  transactionRoles?: Array<string> | null,
): Array<string> => {
  let output: Array<string> = [];

  if (Array.isArray(transactionRoles) && transactionRoles.length > 0) {
    output = transactionRoles;
  } else if (Array.isArray(contactTypeRoles)) {
    output = contactTypeRoles;
  }

  return [...output].sort();
};

const isUserBuyerOrSeller = (transactionRoles: ParticipantRole[] | undefined): boolean => {
  if (transactionRoles?.length) {
    return transactionRoles.includes(ParticipantRole.BUYER) || transactionRoles.includes(ParticipantRole.SELLER);
  }

  return false;
};

/**
 * BFF only return a error message and does not return a error exception
 * We would need to map the error message to the legacy error exception
 */
const getLegacyErrorCode = (errorMessage: string) => {
  switch (errorMessage) {
    case 'Cannot reset password for the user as there is no registered/verified email or phone_number':
    case 'Username/client id combination not found.':
    case 'User does not exist.':
      return ErrorCodes.UserNotFoundException;
    case 'Incorrect username or password.':
      return ErrorCodes.NotAuthorizedException;
    case 'An account with the given email already exists.':
    case 'User account already exists':
    case 'User already exists':
      return ErrorCodes.UsernameExistsException;
    case 'Invalid verification code provided, please try again.':
    case 'Invalid code or auth state for the user.':
      return ErrorCodes.CodeMismatchException;
    case 'Provided password cannot be used for security reasons.':
      return ErrorCodes.InvalidPasswordException;
    case 'Unable to login because of security reasons.':
      return ErrorCodes.InvalidSignInPasswordException;
    case 'Request not allowed due to WAF block.':
      return ErrorCodes.ForbiddenException;
    default:
      return errorMessage;
  }
};

const isNewLoginSystemEnabled = () => {
  return true;
};

export {
  extractPhoneNumber,
  getCurrentStep,
  getPhoneNumber,
  getMfaDisplayPhoneNumber,
  getErrorDetails,
  getContactType,
  getUserRolesForSignupSegmentTracking,
  isIncorrectAttempt,
  isNotConfirmedUser,
  isMeetingStrongPasswordCriteria,
  isUserBuyerOrSeller,
  signInEmailErrors,
  signUpEmailErrors,
  signUpMfaErrors,
  isIncorrectAttemptV2,
  getLegacyErrorCode,
  isNewLoginSystemEnabled,
};
