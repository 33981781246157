import { TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import isEqual from 'lodash/isEqual';

import { getAnswersFromStepContent } from './getAnswersFromStepContent';

interface IsAnswerEqualProps {
  formattedAnswer: GenericObject;
  stepContent: TodoAssignmentContent[];
}

export const isAnswerEqual = ({ formattedAnswer, stepContent }: IsAnswerEqualProps) => {
  const answerKeys = Object.keys(formattedAnswer || {});

  const existingAnswers = getAnswersFromStepContent(answerKeys, stepContent);

  return !!Object.keys(existingAnswers).length && isEqual(formattedAnswer, existingAnswers);
};
