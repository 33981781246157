import { TransactionParticipant, TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';

export const getAllParticipantsNameByRoles = (
  participants: TransactionParticipant[] = [],
  roles: TransactionRole[] = [],
) => {
  const nameOfParticipants =
    participants
      ?.filter((p) => roles.some((role) => p.roles.includes(role)))
      .map(({ user }) => `${user?.firstName || ''} ${user?.lastName || ''}`) || [];

  return nameOfParticipants;
};
