import { EnhancedOption } from '@endpoint/endpoint-bff-graphql-schema';
import { EmdPaymentOption, TodoStepId } from 'consts/enums';

export const markDisabledOptions = (stepId: string, options: EnhancedOption[] = []): EnhancedOption[] => {
  return options.map((option) => {
    if (stepId === TodoStepId.PAYMENT_METHOD_OPTIONS && option.value === EmdPaymentOption.PAY_ONLINE) {
      return { ...option, isDisabled: true };
    }

    return option;
  });
};
