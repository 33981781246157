import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

export const usePageLeavePrompt = (confirmationMessage: string) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = confirmationMessage; // eslint-disable-line no-param-reassign

      return confirmationMessage;
    };

    const handleBeforeHistoryChange = () => {
      const result = window.confirm(confirmationMessage); // eslint-disable-line no-alert

      if (result) return true;

      navigate(pathname);

      return false;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBeforeHistoryChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBeforeHistoryChange);
    };
  }, [navigate, pathname, confirmationMessage]);
};
