import React, { FC, useState, useContext } from 'react';
import { Heading } from '@endpoint/blockparty';
import {
  OrderPrelimWithAddressInput,
  OrderPrelimWithParcelInput,
  UserInput,
} from '@endpoint/endpoint-bff-graphql-schema';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { AlertMessage } from 'components/AlertMessage';
import { FormikSummaryDisplay } from 'components/form/FormikBlockparty';
import { getReportSubmissionAction } from 'consts/analytics';
import { useOrderPrelim } from 'hooks/useOrderPrelim';
import { trackAction } from 'utils/analytics';
import { AppContext, CompanyContext } from 'utils/context';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { FormikValue } from '.';

export const Step2: FC<CurrentStepComponentProps<FormikValue>> = ({ setCurrentStepPosition, formikValue }) => {
  const { unityTransactionCreationEnabled } = useFeatureFlags();
  const [isSubmitting] = useState(false);
  const [hasError] = useState(false);
  const { companyName } = useContext(CompanyContext);
  const { user: userContext } = useContext(AppContext);
  const TRACK_REPORT_SUBMISSION = getReportSubmissionAction(companyName);

  const { orderPrelimWithAddress, orderPrelimWithParcel, orderPrelimLoading, orderPrelimError } = useOrderPrelim();

  const userInput: UserInput = {
    contactType: userContext.contactType,
    isAgent: userContext.isAgent,
    isTransactionCoordinator: userContext.isTransactionCoordinator,
  };

  const orderWithParcel = async () => {
    const payload: OrderPrelimWithParcelInput = {
      state: formikValue.state,
      county: formikValue.county,
      parcelNumber: formikValue.parcelNumber,
      note: formikValue.additionalInformation,
      processVariation: unityTransactionCreationEnabled ? 2 : 1,
      user: userInput,
    };

    await orderPrelimWithParcel({ variables: { input: payload } });
  };

  const orderWithAddress = async () => {
    const payload: OrderPrelimWithAddressInput = {
      propertyAddress: {
        streetAddress1: formikValue.propertyAddress,
        streetAddress2: formikValue.propertyAddress2,
        city: formikValue.city,
        state: formikValue.state,
        county: formikValue.county,
        zipCode: formikValue.zip,
      },
      note: formikValue.additionalInformation,
      processVariation: unityTransactionCreationEnabled ? 2 : 1,
      user: userInput,
    };

    await orderPrelimWithAddress({ variables: { input: payload } });
  };

  const handleCreatePrelimOrder = async () => {
    trackAction(TRACK_REPORT_SUBMISSION);

    if (formikValue.parcelNumber) {
      await orderWithParcel();
    } else {
      await orderWithAddress();
    }

    setCurrentStepPosition(3);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const FormatSummary = () => {
    const formatData: Array<{ label: string; value: string }> = [];

    formatData.push({ label: 'Order Method', value: formikValue.orderMethod });

    if (formikValue.orderMethod === 'By Property Address') {
      const address2 = formikValue.propertyAddress2 ? `${formikValue.propertyAddress2} <br/>` : '';

      formatData.push({
        label: 'Property Address',
        value: `${formikValue.propertyAddress}<br/>${address2}${formikValue.city}, ${formikValue.state} ${formikValue.zip}<br/>${formikValue.county}`,
      });
    } else {
      formatData.push({ label: 'Parcel Number', value: formikValue.parcelNumber });
      formatData.push({ label: 'State', value: formikValue.state });
      formatData.push({ label: 'County', value: formikValue.county });
    }

    formatData.push({ label: 'Additional Information', value: formikValue.additionalInformation || 'N/A' });

    return (
      <>
        {formatData.map(({ label, value }) => (
          <FormikSummaryDisplay
            key={label}
            label={label}
            shouldRenderHTML={label === 'Property Address'}
            value={value || ''}
            onClick={() => setCurrentStepPosition(1)}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Review and submit your order.
      </Heading>
      {(hasError || orderPrelimError) && <AlertMessage />}
      <FormatSummary />
      <NavigationButton goNext={handleCreatePrelimOrder} isLoading={isSubmitting || orderPrelimLoading}>
        Submit
      </NavigationButton>
    </>
  );
};
