import { theme } from './endpoint';

export const ahcTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    navbarLink: '#fff',
    navbarUnderline: '#fff',
    progressBarSignUp: '#fff',
    shellBgHeader: '#00A0C8', // brandPrimary500
    brandPrimary0: '#BFE7F1',
    brandPrimary100: '#99D9E9',
    brandPrimary200: '#73CBE1',
    brandPrimary300: '#4DBCD8',
    brandPrimary400: '#26AED0',
    brandPrimary500: '#00A0C8',
    brandPrimary600: '#0088AA',
    brandPrimary700: '#00708C',
    brandPrimary800: '#00586E',
    brandPrimary900: '#004050',
  },
  config: {
    ...theme.config,
    FAVICON_ICO: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/favicon.ico',
    FAVICON_16: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/favicon-16x16.png',
    FAVICON_32: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/favicon-32x32.png',
    FAVICON_48: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/favicon-48x48.png',
    FAVICON_180: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/favicon-180x180.png',
    FAVICON_192: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/favicon-192x192.png',
    LOGO_DARK: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/AHC-logo-dark.png',
    LOGO_DARK_FULL: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/AHC-logo-dark-full.png',
    LOGO_LIGHT: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/AHC-logo-light.png',
    LOGO_LIGHT_FULL: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/AHCTheme/AHC-logo-light-full.png',
    HEADSET_IMAGE: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Headset.png',
    OUT_OF_AREA_IMAGE: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Illustration_Space.png',
    organization: 'AHC',
  },
};
