import React, { FC, useContext } from 'react';
import { Box, Flex, Image, useTheme } from '@endpoint/blockparty';
import { Outlet, useNavigate } from 'react-router-dom';
import { RouteOptions } from 'Auth/Utilities/Constant';
import { CompanyContext, CompanyContextType, DEFAULT_AUTH_STATE, AppContext, AppContextType } from 'utils/context';

import SignInFooter from '../../Components/SignInFooter';
import { OfflineErrorOverlay } from '../../../components/Error/Offline';

const SignInContainer: FC = () => {
  const { setAuthFields, isOnline }: AppContextType = useContext(AppContext);
  const { companyName }: CompanyContextType = useContext(CompanyContext);
  const navigate = useNavigate();
  const { config, colors } = useTheme();
  const { LOGO_DARK_FULL } = config;
  const gradientBarColor =
    companyName === 'Endpoint' ? 'linear-gradient(90deg, #087EA8 0%, #009FAB 100%);' : colors.brandPrimary500;

  const navigateToHome = () => {
    setAuthFields(DEFAULT_AUTH_STATE);
    navigate(RouteOptions.SIGN_IN);
  };

  return (
    <Flex backgroundColor="slate0" flexDirection="column" height="100%" overflow="auto">
      <Flex margin="0 auto" maxWidth="1440px" p="space50" width="100%">
        <Image alt={companyName} cursor="pointer" src={LOGO_DARK_FULL} width={135} onClick={navigateToHome} />
      </Flex>
      <Flex
        alignItems="flex-start"
        flex={1}
        margin="0 auto"
        maxWidth="544px"
        my="space80"
        position="relative"
        px="space50"
        width="100%"
      >
        {!isOnline && <OfflineErrorOverlay />}
        <Box backgroundColor="white" width="100%">
          {/* Gradient Bar */}
          <Box background={gradientBarColor} height="8px" width="100%" />
          {/* Card */}
          <Box pb="space70" pt="space80" px={{ base: 'space50', md: 'space70' }}>
            <Outlet />
          </Box>
        </Box>
      </Flex>
      {/* Footer */}
      <SignInFooter />
    </Flex>
  );
};

export default SignInContainer;
