import { useState, useEffect } from 'react';
import { getOrganizationDetails } from 'utils/getOrganizationData';
import { getCompanyDefaults, getConfig, DEFAULT_ORGANIZATION_ID, ENDPOINT_DISPLAY_NAME } from 'utils/config';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PublicOrganization } from '@endpoint/endpoint-bff-graphql-schema';

const useOrganization = () => {
  const { ORGANIZATION_ID } = getConfig();
  const { isAppConfigsFromOrgService } = useFeatureFlags();
  const { COMPANY_SHORTNAME, COMPANY_BRANDNAME } = getCompanyDefaults();
  const [organization, setOrganization] = useState<PublicOrganization>({
    id: isAppConfigsFromOrgService ? DEFAULT_ORGANIZATION_ID : ORGANIZATION_ID,
    shortName: isAppConfigsFromOrgService ? ENDPOINT_DISPLAY_NAME : COMPANY_SHORTNAME,
    brandName: isAppConfigsFromOrgService ? ENDPOINT_DISPLAY_NAME : COMPANY_BRANDNAME,
    epAppConfigs: {
      BFF_URL: '',
      HELP_URL: '',
      ENVIRONMENT: '',
      GRAPHQL_URL: '',
      PRIVACY_URL: '',
      COMPANY_EMAIL: '',
      CONTACT_US_URL: '',
      NON_SSO_EMAILS: [],
      ZENDESK_APP_ID: '',
      SSO_CALLBACK_URL: '',
      SEGMENT_WRITE_KEY: '',
      DATA_PRIVACY_EMAIL: '',
      GOOGLE_TAG_MANAGER: {
        auth: '',
        gtmId: '',
        preview: '',
      },
      THEME_URL: '',
      COMPANY_PHONE_NUMBER: {
        generalInquiries: '',
        technicalSupport: '',
      },
      MANIFEST_URL: '',
      LAUNCH_DARKLY_CLIENT_ID: '',
      AWS_COGNITO_SSO_IDENTITY_PROVIDERS: [],
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const org = await getOrganizationDetails();

      if (Boolean(org?.shortName?.trim()) && Boolean(org?.brandName?.trim())) {
        setOrganization({
          id: org.id,
          shortName: org.shortName,
          brandName: org.brandName,
          epAppConfigs: org.epAppConfigs,
        });
      }
    };

    void fetchData();
  }, []);

  return organization;
};

export default useOrganization;
