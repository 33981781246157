import { gql, useMutation } from '@apollo/client';
import { SSOInput, SsoAuthResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';

export const SSO_CODE = gql`
  mutation SSO($input: SSOInput!) {
    sso(input: $input) {
      authentication {
        accessToken
        idToken
        refreshToken
      }
      user {
        firstName
        lastName
        email
        phoneNumber
        isSSO
      }
    }
  }
`;

export const useSSOMutation = () => {
  const sentryTransaction = useSentryTransaction('SSO', 'GraphQL client mutation');

  const [SSOMutation, { data: SSOResult, loading: SSOLoading, error: SSOError }] = useMutation<
    { sso: SsoAuthResponse },
    { input: SSOInput }
  >(SSO_CODE, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  const SSO = (input: SSOInput) => {
    return SSOMutation({
      variables: { input },
    });
  };

  if ((SSOResult || SSOError) && !SSOLoading) {
    sentryTransaction.finish();
  }

  return {
    SSO,
  };
};
