import { type CompanyName } from 'utils/whiteLabel/types';

import { ahcTheme } from './ahc';
import { theme } from './endpoint';
import { qeTestOrgTheme } from './qe-test-org';
import { qeTestOrg2Theme } from './qe-test-org-2';
import { opsTestTheme } from './opstest';

export const getTheme = (companyName: CompanyName) => {
  switch (companyName) {
    case 'AHC':
      return ahcTheme;
    case 'Ops':
      return opsTestTheme;
    case 'QETestOrg':
      return qeTestOrgTheme;
    case 'QETestOrg2':
      return qeTestOrg2Theme;
    case 'Endpoint':
    default:
      return theme;
  }
};
