import { localStorageGetItem } from '../../localStorageAccessor';

export const CUSTOM_STORAGE_VAULT = 'storage_vault';

export const getStorageVault = () => {
  const vault = localStorageGetItem(CUSTOM_STORAGE_VAULT);

  return vault === 'true' ? localStorage : sessionStorage;
};

export class CustomStorage {
  static setItem(key: string, value: string): string {
    const storage = getStorageVault();

    storage.setItem(key, value);

    return value;
  }

  static getItem(key: string): string {
    const storage = getStorageVault();

    return storage.getItem(key) || '';
  }

  static removeItem(key: string): void {
    const storage = getStorageVault();

    storage.removeItem(key);
  }

  static clear(): void {
    const storage = getStorageVault();

    storage.clear();
  }
}
