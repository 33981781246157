import React, { FC } from 'react';
import { useField } from 'formik';
import { InputGroupProps } from '@endpoint/blockparty/dist/components/InputGroup';
import { InputGroup, InputLabel, InputErrorMessage } from '@endpoint/blockparty';

export interface FormikInputGroupProps extends InputGroupProps {
  isRequired?: boolean;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  component?: React.ElementType;
  name: string;
}

export const FormikGroup: FC<FormikInputGroupProps> = ({
  component,
  isRequired,
  name,
  label,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);
  const isInvalid = Boolean(meta.touched && meta.error);

  const Component = component;

  if (!Component) {
    return null;
  }

  return (
    <InputGroup groupId={name} isInvalid={isInvalid} isRequired={isRequired} {...props}>
      <InputLabel>{label || name}</InputLabel>
      <Component {...field} placeholder={placeholder} />
      {isInvalid && <InputErrorMessage>{meta.error}</InputErrorMessage>}
    </InputGroup>
  );
};
