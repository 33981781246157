import React from 'react';
import { MilestoneStatus, TransactionStatus, TransactionSummary } from '@endpoint/endpoint-bff-graphql-schema';
import { DisplayDate } from 'routes/Transactions/TransactionSummaryCard/DisplayDate';
import { MONTH_DAY_YEAR, formatDate } from 'utils/formatDate';

export const displayDateStatus = (transaction: TransactionSummary) => {
  const { status: transactionStatus, milestones, dates } = transaction;
  const orderPending = milestones[0]?.status === MilestoneStatus.UPCOMING;
  const { estimatedClosing, cancellationDate } = dates || {};

  switch (transactionStatus) {
    case TransactionStatus.CLOSED:
      return {
        label: 'Closed:',
        value: estimatedClosing ? <DisplayDate estimatedClosing={estimatedClosing} /> : '',
      };
    case TransactionStatus.CANCELLED:
      return {
        label: 'Cancelled:',
        value: cancellationDate ? formatDate(cancellationDate, MONTH_DAY_YEAR) : '',
      };
    case TransactionStatus.IN_ESCROW:
      return {
        label: 'Closing:',
        value:
          orderPending || !estimatedClosing ? 'Not available yet' : <DisplayDate estimatedClosing={estimatedClosing} />,
      };
    default:
      return { label: '', value: '' };
  }
};
