import React, { FC, Fragment } from 'react';
import { Box, SkeletonBlock } from '@endpoint/blockparty';

interface TodoListSkeletonProps {
  count?: number;
}

export const TodoListSkeleton: FC<TodoListSkeletonProps> = ({ count = 7 }) => {
  return (
    <>
      <Box bg="white">
        <SkeletonBlock height={16} maxWidth={138} mb="space40" />
        <SkeletonBlock height={1} maxWidth="none" mb="space30" />

        {[...Array(count)].map((_item, i) => {
          return (
            // eslint-disable-next-line
            <Fragment key={i}>
              <Box my="space60">
                <SkeletonBlock height={16} maxWidth={302} mb="space50" />
                <SkeletonBlock height={12} maxWidth={95} mb="space30" />
              </Box>
              <SkeletonBlock height={1} maxWidth="none" mb="space30" />
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};
