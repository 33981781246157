import React, { FC, Fragment } from 'react';
import { Box, Grid, Image, Text } from '@endpoint/blockparty';
import { getPropertyDetails } from 'routes/TransactionDetail/Todos/PropertyDetails/helpers';
import { PLACEHOLDER_MLS } from 'consts/assets';
import { getFormattedAddressParts } from 'utils/formatAddress';
import { useAppContext } from 'utils/context';

import { TransactionDetailsPayload } from '../queries';

/*= ====================================== */

export const PropertyDetails: FC<TransactionDetailsPayload> = ({ transaction, ...props }) => {
  const { user } = useAppContext();

  const { property } = transaction;
  const { propertyImage, address } = property;
  const { streetAddress, cityStateZip } = getFormattedAddressParts(address);
  const propertyDetails = getPropertyDetails({ transaction, userId: user?.id });

  return (
    <Box bg="background" {...props}>
      <Image
        alt="Future home"
        maxHeight={{ base: '128px', sm: '184px' }}
        objectFit="cover"
        src={propertyImage || PLACEHOLDER_MLS}
      />
      <Box p="space50">
        <Box mb="space50">
          <Text as="h5" data-test-id="property-details-street-address" size="fontSize40">
            {streetAddress}
          </Text>
          <Text as="p" data-test-id="property-details-city-state-zip" size="fontSize20">
            {cityStateZip}
          </Text>
        </Box>
        <Grid data-test-id="property-details-container" templateColumns="72px 1fr">
          {propertyDetails.map(({ label, value }, i) => {
            const key = `${label}${i}`;

            return (
              <Fragment key={key}>
                <Text fontWeight="semi" size="fontSize10">
                  {label}
                </Text>
                <Text size="fontSize10">{value}</Text>
              </Fragment>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
