import { StateOfOperation } from '@endpoint/endpoint-bff-graphql-schema';

import { User } from '../context';

export const isUnsupportedOrderReportStates = (user: User): boolean => {
  return (
    user?.stateSignedUp === StateOfOperation.TX ||
    user?.stateSignedUp === StateOfOperation.CO ||
    user?.stateSignedUp === StateOfOperation.FL ||
    user?.stateSignedUp === StateOfOperation.TN ||
    user?.stateSignedUp === StateOfOperation.MO ||
    user?.stateSignedUp === StateOfOperation.OH ||
    user?.stateSignedUp === StateOfOperation.IN
  );
};
