import { StateOfOperation } from '@endpoint/endpoint-bff-graphql-schema';
import { USState } from 'utils/getSupportedUSState';

export const getDocumentRegion = (stateSignedUp?: StateOfOperation | USState): StateOfOperation | USState => {
  const DEFAULT_STATE_OF_OPERATION = StateOfOperation.WA;

  if (stateSignedUp) return stateSignedUp;

  return DEFAULT_STATE_OF_OPERATION;
};
