interface FieldSetAnswer {
  key: string;
  answer: string;
}

export const getFieldSetAnswers = (fieldSet: GenericObject): FieldSetAnswer[] => {
  let summary: FieldSetAnswer[] = [];

  Object.keys(fieldSet).forEach((key) => {
    if (key === '__ui_uuid') {
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    const uniqueKey = fieldSet.__ui_uuid ? `${fieldSet.__ui_uuid}-${key}` : key;

    summary = [...summary, { key: uniqueKey, answer: fieldSet[key] }];
  });

  return summary;
};
