import React from 'react';
import { Box, Flex, Icon, Stack, Text } from '@endpoint/blockparty';
import { SectionMetaData, TodoSectionStatus } from '@endpoint/endpoint-bff-graphql-schema';

interface SectionListProps {
  sections: SectionMetaData[];
}

export const SectionList = ({ sections }: SectionListProps) => {
  return (
    <Box pb="space60" px="space60">
      <Stack spacing="space50">
        {sections.map((section, i) => {
          const isCurrentSection = section.status === TodoSectionStatus.INPROGRESS;
          const isComplete = section.status === TodoSectionStatus.COMPLETED;
          const isLast = sections.length - 1 === i;

          const showNextBadge = isCurrentSection && !isLast;
          const showNumberBadge = !isCurrentSection && !isComplete;
          const showCompleteBadge = (isComplete && !isCurrentSection) || (isLast && isCurrentSection);

          const displayName = isLast ? `${section.name} 🎉` : section.name;

          return (
            <Flex key={section.name} alignItems="baseline" data-test-id="item-content">
              <Box mr="space30">
                {showNextBadge && <Icon name="ArrowForward" />}
                {showNumberBadge && (
                  <Flex
                    alignItems="center"
                    border="1px solid"
                    borderColor="carbon400"
                    borderRadius="radiusFull"
                    color="carbon400"
                    height="16px"
                    justifyContent="center"
                    textAlign="center"
                    width="16px"
                  >
                    <Text color="carbon400" size="fontSize10">
                      {i + 1}
                    </Text>
                  </Flex>
                )}
                {showCompleteBadge && <Icon color="turquoise500" name="CheckCircle" />}
              </Box>
              <Text
                color={(showNextBadge && 'text') || (showNumberBadge && 'carbon400') || 'carbon900'}
                fontWeight={isCurrentSection ? 'semi' : null}
              >
                {displayName}
              </Text>
            </Flex>
          );
        })}
      </Stack>
    </Box>
  );
};
