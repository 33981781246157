import React from 'react';
import { Flex, LayoutHeader } from '@endpoint/blockparty';
import _ from 'lodash';

import { HeaderLeft } from './HeaderLeft';
import { HeaderRight } from './HeaderRight';
import { Logo } from './Logo';

interface HeaderProps {
  isOnline: Boolean;
}

export const Header = (props: HeaderProps) => {
  const { isOnline } = props;

  return (
    <LayoutHeader
      boxShadow="large"
      display="block" // used to fix Safari header
      minHeight="56px" // needed since this is shared with Opsware
      position="sticky"
      top="0px"
      zIndex="banner"
      {..._.omit(props, ['isOnline'])}
    >
      <Flex flex="1" height="100%" margin="0 auto" maxWidth={1440} px={{ base: 'space50', md: 'space60' }}>
        <Logo />
        {isOnline && <HeaderLeft />}
        {isOnline && <HeaderRight />}
      </Flex>
    </LayoutHeader>
  );
};
