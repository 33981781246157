import { CreditBeneficiary } from 'hooks/useWireInstructions/types';
import { getContactBankingInfoByMarketId } from 'routes/TransactionDetail/Contacts/helpers';
import { formatPaymentAddress } from 'utils/formatPaymentAddress';

export const getCreditBeneficiary = (
  payableName: string,
  marketId?: number,
  isUnityTransaction?: boolean,
): CreditBeneficiary => {
  const contactInfo = getContactBankingInfoByMarketId(marketId, isUnityTransaction);
  const { paymentStreet, paymentSuite, paymentCityStateZip } = formatPaymentAddress(contactInfo);

  return {
    payableName,
    streetAddress: paymentStreet,
    suite: paymentSuite,
    cityStateZip: paymentCityStateZip,
  };
};
