import React, { FC } from 'react';
import { Button, Flex, Tag, Text } from '@endpoint/blockparty';
import { Transaction, TransactionType, TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { useNavigate } from 'react-router-dom';
import { TransactionTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { MilestoneTracker } from '../MilestoneTracker';

interface StatusVariantProps {
  transaction: Transaction;
}

export const StatusVariant: FC<StatusVariantProps> = ({ transaction }) => {
  const navigate = useNavigate();
  const { status, type, id } = transaction;
  const isRefiOrEquity = type === TransactionType.REFINANCE || type === TransactionType.EQUITY;
  const pendingTransaction = !!transaction.prelimPending;

  switch (status) {
    case TransactionStatus.CLOSED:
      return (
        <Flex data-test-id="status-tag-closed">
          <Tag alignItems="center" display="flex" mr="space30" variantColor="aloe500">
            {isRefiOrEquity ? 'CLOSED' : 'SOLD'} – Congrats!
          </Tag>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <Text aria-label="celebrate" as="span" role="img">
            🎉
          </Text>
        </Flex>
      );
    case TransactionStatus.CANCELLED:
      return (
        <Flex>
          <Tag alignItems="center" data-test-id="status-tag-cancelled" display="flex" variantColor="cancelled">
            Cancelled
          </Tag>
        </Flex>
      );
    case TransactionStatus.IN_ESCROW:
      return <MilestoneTracker transaction={transaction} />;
    case TransactionStatus.PRELISTING:
      return (
        <>
          <Button
            data-test-id="status-open-escrow"
            isDisabled={pendingTransaction}
            mb="space50"
            variant="outline"
            width="100%"
            onClick={() => {
              navigate(`/open-escrow/${id}`, {
                // Pre-populates Open Escrow - Select option
                state: { currentPrelimTransaction: transaction, currentPrelimId: id },
              });

              trackAction(TransactionTrackingEvents.OPEN_ESCROW_FROM_TRANSACTION_CARD);
            }}
          >
            Open Escrow
          </Button>
          <Button
            data-test-id="view-reports"
            isDisabled={pendingTransaction}
            width="100%"
            onClick={() => {
              navigate(`/transaction/${transaction.id}/documents`);

              trackAction(TransactionTrackingEvents.PRELIM_VIEW_REPORTS);
            }}
          >
            View Reports
          </Button>
        </>
      );
    default:
      return <></>;
  }
};
