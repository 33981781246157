import React, { FC } from 'react';
import { Box, Text, ProgressBar } from '@endpoint/blockparty';
import { TodoSectionStatus } from '@endpoint/endpoint-bff-graphql-schema';
import { TodoProgressSkeleton } from 'components/Skeleton';

import { SectionList } from '../SectionList';
import { ProgressTrackerProps } from '../types';

export const DesktopProgressTracker: FC<ProgressTrackerProps> = ({ progressTracker }) => {
  if (!progressTracker) return <TodoProgressSkeleton />;

  const { name, dueDate, estimatedTimeInMinutes, sectionData = [] } = progressTracker;
  const numCompletedSections = sectionData.filter((section) => section.status === TodoSectionStatus.COMPLETED).length;

  return (
    <Box bg="background" display={{ base: 'none', md: 'block' }}>
      <Box p="space60">
        <Text as="p" color="passive" mb="space30" size="fontSize10">
          {`Due ${dueDate} | Est. time ${estimatedTimeInMinutes} mins`}
        </Text>
        <Text as="h1" id="to-do-name" mb="space40" size="fontSize30">
          To-Do: {name}
        </Text>
        <ProgressBar aria-labelledby="to-do-name" completed={numCompletedSections} steps={sectionData.length} />
      </Box>
      <SectionList sections={sectionData} />
    </Box>
  );
};
