import { gql, useMutation } from '@apollo/client';
import { InitiateSSOInput, InitiateSSOResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';

export const INITIATE_SSO = gql`
  mutation InitiateSSO($input: InitiateSSOInput!) {
    initiateSSO(input: $input) {
      idpUrl
    }
  }
`;

export const useInitiateSSOMutation = () => {
  const sentryTransaction = useSentryTransaction('initiateSSO', 'GraphQL client mutation');

  const [initiateSSOMutation, { data: initiateSSOResult, loading: initiateSSOLoading, error: initiateSSOError }] =
    useMutation<{ initiateSSO: InitiateSSOResponse }, { input: InitiateSSOInput }>(INITIATE_SSO, {
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
      },
    });

  const initiateSSO = (input: InitiateSSOInput) => {
    return initiateSSOMutation({
      variables: { input },
    });
  };

  if ((initiateSSOResult || initiateSSOError) && !initiateSSOLoading) {
    sentryTransaction.finish();
  }

  return {
    initiateSSO,
  };
};
