import React, { FC } from 'react';
import { Transaction, TransactionStatus, MilestoneStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { Text } from '@endpoint/blockparty';
import { differenceInCalendarDays } from 'date-fns';
import { formatDate, MONTH_DAY_YEAR } from 'utils/formatDate';
import { User } from 'utils/context';

/* ======================================= */
export interface TransactionCardDetails {
  transaction: Transaction;
  userId: User['id'];
}

interface DisplayDateProps {
  estimatedClosing: Date;
}

export type DetailList = {
  label: string;
  value: any;
};

// General transactions helpers
/*= ====================================== */

export const displayDateStatus = (transaction: Transaction) => {
  const { status, milestones, dates } = transaction;
  const orderPending = milestones[0]?.status === MilestoneStatus.UPCOMING;
  const estimatedClosing = dates.estimatedClosing;

  switch (status) {
    case TransactionStatus.CLOSED:
      return {
        label: 'Closed:',
        value: <DisplayDate estimatedClosing={estimatedClosing} />,
      };
    case TransactionStatus.CANCELLED:
      return {
        label: 'Cancelled:',
        value: formatDate(transaction.dates.cancellationDate, MONTH_DAY_YEAR),
      };
    case TransactionStatus.IN_ESCROW:
      return {
        label: 'Closing:',
        value:
          orderPending || !estimatedClosing ? 'Not available yet' : <DisplayDate estimatedClosing={estimatedClosing} />,
      };
    default:
      return { label: '', value: '' };
  }
};

export const DisplayDate: FC<DisplayDateProps> = ({ estimatedClosing }) => {
  const currentDate = new Date().toISOString();
  const daysLeft = differenceInCalendarDays(new Date(estimatedClosing), new Date(currentDate));

  const displayDaysLeft = daysLeft <= 0 ? '' : `(in ${daysLeft} days)`;

  return (
    <>
      <Text size="fontSize10">{formatDate(estimatedClosing, MONTH_DAY_YEAR)}</Text>{' '}
      <Text data-test-id="days-left-text" fontWeight="semi" size="fontSize10">
        {displayDaysLeft}
      </Text>
    </>
  );
};
