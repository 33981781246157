import { BasicAddress, WireTransferBankAddress } from '@endpoint/endpoint-bff-graphql-schema';
import { getBankDetailsByMarketId } from 'utils/getBankDetailsByMarketId';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { InstructionType, useGetPaymentInstructions } from 'hooks/useGetPaymentInstructions';
import useOrganizationId from 'hooks/useOrganizationId';
import { getPayableToNameByMarketId } from 'utils/getPayableToName';
import { getCreditBeneficiary } from 'hooks/useWireInstructions/helpers/getCreditBeneficiary';
import { CreditBeneficiary } from 'hooks/useWireInstructions/types';

type WirePayableDetailsProps = {
  isInternationalWire: boolean;
  isUnityTransaction: boolean;
  marketId: number;
  transactionId: string;
};

const formatAddress = (wireTransferBankAddress?: WireTransferBankAddress) => {
  if (!wireTransferBankAddress) {
    return { name: '', addressLine1: '', addressLine2: '' };
  }

  const { name, street1, street2, city, state, zip } = wireTransferBankAddress;
  const addressLine1 = `${street1}${street2 ? `, ${street2}` : ''}`;
  const addressLine2 = `${city}, ${state} ${zip}`;

  return { name, addressLine1, addressLine2 };
};

const formatCreditBeneficiary = (payableName: string, creditBeneficiaryAddress?: BasicAddress): CreditBeneficiary => {
  if (!creditBeneficiaryAddress) {
    return { payableName, streetAddress: '', suite: '', cityStateZip: '' };
  }

  const { street1, street2, city, state, zip } = creditBeneficiaryAddress;

  return {
    payableName,
    streetAddress: street1,
    suite: street2 ?? '',
    cityStateZip: `${city}, ${state} ${zip}`,
  };
};

export const useWirePayableDetails = ({
  isInternationalWire,
  isUnityTransaction,
  marketId,
  transactionId,
}: WirePayableDetailsProps) => {
  const { isMarketBankDataFromOrgService } = useFeatureFlags();
  const organizationId = useOrganizationId();
  const {
    payableToName,
    wireTransferBankAddress,
    paymentInstructionsLoading,
    paymentInstructionsError,
    creditBeneficiaryAddress,
  } = useGetPaymentInstructions({
    transactionId,
    instructionType: InstructionType.WIRE,
    isInternationalWire,
    skipQuery: !isMarketBankDataFromOrgService,
  });
  const defaultPaymentInstructionsResponse = { paymentInstructionsLoading, paymentInstructionsError };
  const creditBeneficiary = formatCreditBeneficiary(payableToName, creditBeneficiaryAddress);

  if (isMarketBankDataFromOrgService) {
    return {
      ...defaultPaymentInstructionsResponse,
      bankAddress: formatAddress(wireTransferBankAddress),
      payableName: payableToName,
      creditBeneficiary,
    };
  }

  const hardCodedPayableName = getPayableToNameByMarketId(marketId, isUnityTransaction);

  return {
    ...defaultPaymentInstructionsResponse,
    bankAddress: getBankDetailsByMarketId(organizationId, marketId, isUnityTransaction, isInternationalWire),
    payableName: hardCodedPayableName,
    creditBeneficiary: getCreditBeneficiary(hardCodedPayableName, marketId, isUnityTransaction),
  };
};
