import React, { FC, useContext } from 'react';
import { compareAsc } from 'date-fns';
import { Box } from '@endpoint/blockparty';
import { NoTransactions as EmptyState } from 'routes/Transactions/NoTransactions';
import { isWebView } from 'utils/webview';
import { CompanyContext } from 'utils/context';
import { CheckedTransactionDocument } from 'utils/documents';

import { DocumentRow } from '../DocumentRow';

interface DocumentsListProps {
  docs: CheckedTransactionDocument[];
  onNoteClick: (note: string) => void;
  onCheckboxClick: (id: string) => void;
}

export const DocumentsList: FC<DocumentsListProps> = ({ docs, onNoteClick, onCheckboxClick }) => {
  const { companyName } = useContext(CompanyContext);

  const selectedDocuments = docs.filter((doc) => doc.checked);

  const sortedByDateDocs = docs.sort((prev, curr) => compareAsc(new Date(curr.createdAt), new Date(prev.createdAt)));

  const documentRows = sortedByDateDocs.map((doc) => {
    return (
      <DocumentRow
        key={doc.id}
        doc={doc}
        reduceOpacity={isWebView() && selectedDocuments.length > 0 && !doc.checked}
        onCheckboxClick={onCheckboxClick}
        onNoteClick={onNoteClick}
      />
    );
  });

  return (
    <>
      {documentRows?.length === 0 ? (
        <Box mb="space90" mt={{ base: 'space70', md: 'space90' }}>
          <EmptyState
            data-test-id="no-documents"
            description={`All documents you and/or ${companyName} upload will appear here. To get started, click on the 'Upload Document' button.`}
            title="There are no documents yet"
          />
        </Box>
      ) : (
        documentRows
      )}
    </>
  );
};
