import React, { FC, useEffect, useState } from 'react';
import { Box, DatePicker, InputElemGroup, InputRightElement, InputLabel, Icon } from '@endpoint/blockparty';
import { isBefore, isSameDay, parse } from 'date-fns';
import { getDate } from 'utils/getDate';
import { formatDate, TIME, DAY_OF_WEEK_MONTH_DAY_YEAR } from 'utils/formatDate';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { useFormikContext } from 'formik';

import { extractParam, DateTimePickerRange } from './helpers/extractParam';
import { getNow } from './helpers/util';
import { getMaxTimeBoundary, getMinTimeBoundary } from './helpers/timeBoundary';
import { validateSelectedTime } from './helpers/validateSelectedTime';
import { getMinStartDate } from './helpers/dateBoundary';

interface TodoDateTimePickerProps {
  field: FieldOptions;
  param?: string;
}

export const TodoDateTimePicker: FC<TodoDateTimePickerProps> = ({ field: { id, answer }, param }) => {
  const formikContext = useFormikContext<GenericObject>();

  const todoStartDate = getDate(extractParam(DateTimePickerRange.START, param), true);

  const endDate = getDate(extractParam(DateTimePickerRange.END, param), true);
  const startDate = getMinStartDate(todoStartDate, endDate);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedTime, setSelectedTime] = useState<Date | undefined>();

  const [minTime, setMinTime] = useState<Date | undefined>(getMinTimeBoundary(selectedDate, todoStartDate, endDate));
  const [maxTime, setMaxTime] = useState<Date | undefined>(getMaxTimeBoundary(selectedDate, endDate, minTime));

  useEffect(() => {
    if (answer) {
      const { date, time } = answer;

      formikContext.setFieldValue(id, answer);

      setSelectedDate(getDate(date));
      setSelectedTime(parse(time, TIME, getNow()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dateTime =
      selectedDate && selectedTime
        ? {
            date: formatDate(selectedDate, DAY_OF_WEEK_MONTH_DAY_YEAR),
            time: formatDate(selectedTime, TIME),
          }
        : null;

    dateTime && formikContext.setFieldValue(id, dateTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    const newMinTime = getMinTimeBoundary(selectedDate, todoStartDate, endDate);
    const newMaxTime = getMaxTimeBoundary(selectedDate, endDate, newMinTime);

    setMinTime(newMinTime);
    setMaxTime(newMaxTime);

    selectedTime && setSelectedTime(validateSelectedTime(selectedDate, selectedTime, startDate, endDate));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  const hasSameStartEndDate = startDate && endDate && (isSameDay(startDate, endDate) || isBefore(endDate, startDate));

  useEffect(() => {
    if (hasSameStartEndDate && startDate) {
      setSelectedDate(startDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSameStartEndDate]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.blur();

  return (
    <>
      {/* DatePicker */}
      <Box mb="space60">
        <InputLabel>Day:</InputLabel>
        <InputElemGroup iconRightSpacing>
          <DatePicker
            dataTestId="dateTimePicker"
            dateFormat={DAY_OF_WEEK_MONTH_DAY_YEAR}
            disabled={hasSameStartEndDate}
            id="date-picker"
            maxDate={endDate}
            minDate={startDate}
            placeholderText="Select date"
            selected={selectedDate}
            strictParsing
            onChange={(e: Date) => setSelectedDate(e)}
            onChangeRaw={(e) => e.preventDefault()}
            onFocus={handleFocus}
          />
          <InputRightElement>
            <Icon color={hasSameStartEndDate ? 'carbon200' : 'carbon400'} name="CalendarDateRange" size="20px" />
          </InputRightElement>
        </InputElemGroup>
      </Box>
      {/* TimePicker */}
      <Box>
        <InputLabel>Time:</InputLabel>
        <InputElemGroup iconRightSpacing>
          <DatePicker
            dataTestId="dateTimePicker"
            dateFormat="h:mmaaaaa'm'"
            disabled={!selectedDate}
            id="time-picker"
            maxTime={maxTime}
            minTime={minTime}
            placeholderText="Select time"
            selected={selectedTime}
            showTimeSelect
            showTimeSelectOnly
            onChange={(e: Date) => setSelectedTime(e)}
            onChangeRaw={(e) => e.preventDefault()}
            onFocus={handleFocus}
          />
          <InputRightElement>
            <Icon color={selectedDate ? 'carbon400' : 'carbon200'} name="Clock" size="20px" />
          </InputRightElement>
        </InputElemGroup>
      </Box>
    </>
  );
};
