import React, { FC, useContext } from 'react';
import { Modal, ModalContent, ModalSection, Stack, Heading, Text, ModalFooter, Button } from '@endpoint/blockparty';
import { CompanyContext } from 'utils/context';

interface IDocumentNoteModal {
  isOpen: boolean;
  onClose: () => void;
  note: string;
}

export const DocumentNoteModal: FC<IDocumentNoteModal> = ({ isOpen, onClose, note }) => {
  const { companyName } = useContext(CompanyContext);

  return (
    <Modal id="note-modal" isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalSection>
          <Stack spacing="space40">
            <Heading as="h1" size="fontSize20">
              Note from {companyName}
            </Heading>
            <Text>{note}</Text>
          </Stack>
        </ModalSection>
        <ModalFooter>
          <Button mr="space10" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
