import React, { FC } from 'react';
import { Button } from '@endpoint/blockparty';

interface AddFieldSetButtonProps {
  fieldSetName: string;
  onClick: () => void;
}

export const AddFieldSetButton: FC<AddFieldSetButtonProps> = ({ fieldSetName, onClick }) => {
  return (
    <Button iconLeft="Add" my="space0" size="none" variant="link" onClick={onClick}>
      Add Another {fieldSetName}
    </Button>
  );
};
