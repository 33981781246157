import React, { FC, ReactNode, useState } from 'react';
import { useField } from 'formik';
import { InputGroupProps } from '@endpoint/blockparty/dist/components/InputGroup';
import {
  Input,
  InputGroup,
  InputElemGroup,
  InputLabel,
  InputErrorMessage,
  InputRightElement,
  Icon,
} from '@endpoint/blockparty';

export interface FormikPasswordInputGroupProps extends InputGroupProps {
  autoFocus?: boolean;
  component?: ReactNode;
  isRequired?: boolean;
  label?: string;
  name?: string;
  placeholder: string;
  error?: React.ReactNode;
}

export const FormikPasswordInputGroup: FC<FormikPasswordInputGroupProps> = ({
  component,
  isRequired,
  name,
  label,
  placeholder,
  error,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [field, meta] = useField(name as string);

  const isInvalid = Boolean(((meta.value.length > 0 || meta.touched) && meta.error) || !!error);

  return (
    <InputGroup groupId={name} isInvalid={isInvalid} isRequired={isRequired} {...props}>
      {label && <InputLabel>{label}</InputLabel>}
      <InputElemGroup iconRightSpacing>
        <Input
          {...field}
          className="fs-exclude dd-privacy-hidden"
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
        />
        <InputRightElement color="carbon300" onClick={() => setShowPassword(!showPassword)}>
          <Icon name={showPassword ? 'VisibilityOn' : 'VisibilityOff'} size="large" />
        </InputRightElement>
      </InputElemGroup>
      {isInvalid && <InputErrorMessage>{error || meta.error}</InputErrorMessage>}
    </InputGroup>
  );
};
