import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Link, Text, useDisclosure } from '@endpoint/blockparty';
import { ContentType, MarkupType, TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import { TextProps } from '@endpoint/blockparty/dist/components/Text';
import { TodoStepId } from 'consts/enums';

import { DefinitionsDrawer } from '../DefinitionsDrawer';
import { TodoHyperlink } from '../TodoHyperlink';
import { TodoDrawerOpener } from '../TodoDrawerOpener';

interface TodoParagraphProps extends TextProps {
  paragraph: Pick<TodoAssignmentContent, 'children' | 'textMarkup' | 'value'>;
}

function getStylesByStep(stepId: string): Partial<TextProps> {
  const defaultStyles: Partial<TextProps> = { as: 'p', mb: 'space50', size: 'fontSize20' };

  switch (stepId) {
    case TodoStepId.ABOUT:
      return { ...defaultStyles, mb: 'space70' };
    default:
      return defaultStyles;
  }
}

function getStylingProps(textMarkup: MarkupType[] | undefined): Partial<TextProps> {
  let styles: Partial<TextProps> = {};

  if (textMarkup) {
    for (const item of textMarkup) {
      switch (item) {
        case MarkupType.bold:
          styles = { ...styles, fontWeight: 'semi' };
          break;
        case MarkupType.italic:
          styles = { ...styles, fontStyle: 'italic' };
          break;
        case MarkupType.underline:
          styles = { ...styles, textDecoration: 'underline' };
          break;
        default:
      }
    }
  }

  return styles;
}

export const TodoParagraph: FC<TodoParagraphProps> = ({ paragraph: { children, textMarkup, value }, ...textProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { stepId = '' } = useParams();

  if (!children.length) {
    return (
      <Text {...getStylesByStep(stepId)} {...getStylingProps(textMarkup)} {...textProps}>
        {value}
      </Text>
    );
  }

  return (
    <>
      <DefinitionsDrawer isOpen={isOpen} onClose={onClose} />
      <Text as="p" mb="space50" size="fontSize20" {...textProps}>
        {children.map((element) => {
          switch (element.nodeType) {
            case ContentType.TEXT:
              return (
                <Text key={element.value} as="span" {...getStylingProps(element.textMarkup)}>
                  {element.value}
                </Text>
              );
            case ContentType.HYPERLINK:
              return <TodoHyperlink content={element} />;
            case ContentType.TERM:
              return (
                <Link
                  key={element.value}
                  as="button"
                  border="none"
                  color="brandPrimary500"
                  textDecoration="underline"
                  type="button"
                  onClick={onOpen}
                >
                  {element.value}
                </Link>
              );
            case ContentType.DRAWER_OPENER:
              return <TodoDrawerOpener key={element.value} content={element} />;
            default:
              return <></>;
          }
        })}
      </Text>
    </>
  );
};
