import { gql } from '@apollo/client';

export const GET_MARKETS = gql`
  query getMarkets($input: GetMarketsInput) {
    getMarkets(input: $input) {
      id
      name
      organizationId
      organizationLegalName
      active
      fundingType
      state
      zendeskTag
      acceptsPrelistings
      createdAt
      updatedAt
    }
  }
`;
