import React, { FC } from 'react';
import { Box, EPContainer, EPSidebar, EPContent } from '@endpoint/blockparty';

import { ContactDetailsSkeleton } from './ContactDetailsSkeleton';
import { ContactListSkeleton } from './ContactListSkeleton';
import { PropertyHeaderSkeleton } from '../PropertyHeaderSkeleton';

export const ContactsSkeleton: FC = () => (
  <EPContainer data-test-id="contacts-skeleton" gridTemplateRows="max-content">
    <PropertyHeaderSkeleton />

    <EPSidebar>
      <Box>
        <ContactDetailsSkeleton />
      </Box>
    </EPSidebar>

    <EPContent>
      <ContactListSkeleton />
    </EPContent>
  </EPContainer>
);
