import { TransactionStatus, TransactionSummary } from '@endpoint/endpoint-bff-graphql-schema';
import { TransactionType } from '@endpoint/common-model';

import {
  DetailList,
  displayDateStatus,
  displayLoanOrPriceAmount,
  getSigningDetails,
  getTransactionRole,
  TransactionSummaryCardDetails,
} from '..';

export const getRefinanceAndEquityTransactionDetails = ({
  transaction,
  userId,
}: TransactionSummaryCardDetails): DetailList[] => {
  const { status } = transaction;

  switch (status) {
    case TransactionStatus.IN_ESCROW:
    case TransactionStatus.CLOSED:
      if (transaction.type === TransactionType.EQUITY) {
        return [...getBaseRefinanceAndEquityTransactionDetails(transaction)];
      }

      return [getTransactionRole({ transaction, userId }), ...getBaseRefinanceAndEquityTransactionDetails(transaction)];
    default:
      return [];
  }
};

const getBaseRefinanceAndEquityTransactionDetails = (transaction: TransactionSummary): DetailList[] => {
  const { fileNum, loanAmount, price, type } = transaction;
  const noLoanOrPrice = {
    label: 'Loan:',
    value: 'Not available yet',
  };
  const loanAmountDetails = !loanAmount && !price ? noLoanOrPrice : displayLoanOrPriceAmount(type, price, loanAmount);

  const fileNumDetails = {
    label: 'File #',
    value: fileNum || 'Not available yet',
  };

  return [
    {
      ...fileNumDetails,
    },
    {
      ...loanAmountDetails,
    },
    { ...getSigningDetails(transaction) },
    { ...displayDateStatus(transaction) },
  ];
};
