import { useLazyQuery } from '@apollo/client';
import { GetUnityContactResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { GET_UNITY_CONTACT } from './queries';

export const useGetUnityContact = () => {
  const sentryTransaction = useSentryTransaction('getUnityContact', 'GraphQL client query');

  const [getUnityContact, { data, error, loading }] = useLazyQuery<{
    getUnityContact: GetUnityContactResponse;
  }>(GET_UNITY_CONTACT, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
    fetchPolicy: 'network-only',
  });

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    getUnityContact,
    unityContact: data?.getUnityContact,
    unityContactLoading: loading,
    unityContactError: error,
  };
};
