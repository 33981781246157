const mailingInstructionAnswerFormatter = (fieldValues: GenericObject) =>
  fieldValues.mailed_check
    ? {
        mailed_check: true,
        acknowledge: true,
      }
    : undefined;

const wireTransferAnswerFormatter = (fieldValues: GenericObject) =>
  fieldValues.wire_transfer_complete
    ? {
        wire_transfer_complete: 'I have read the ALTA wire fraud warning and completed my wire transfer',
        acknowledge: true,
      }
    : undefined;

const formatters: { [key: string]: Function } = {
  mailing_instructions: mailingInstructionAnswerFormatter,
  confirm_funds_wire_us: wireTransferAnswerFormatter,
  review_wire_funds_int: wireTransferAnswerFormatter,
};

export function formatAnswer(stepId: string, fieldValues: GenericObject): GenericObject {
  if (stepId in formatters) {
    return formatters[stepId](fieldValues);
  }

  return fieldValues;
}
