import React, { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { Box, Text } from '@endpoint/blockparty';
import { DataBlock, Label } from 'components/DataBlock';
import { WireTransferTransactionInfo } from 'components/WireTransferInstructions';
import { WireInstructions } from 'hooks/useWireInstructions/types';

import { ErrorLoadingStatesWidget } from '../../ErrorLoadingStatesWidget';

interface TodoWireInstructionsProps {
  wireInstructions: WireInstructions;
  isLoading: boolean;
  error: ApolloError | undefined;
  isInternational?: boolean;
}

export const TodoWireInstructions: FC<TodoWireInstructionsProps> = ({
  wireInstructions,
  isInternational,
  isLoading,
  error,
}) => {
  if (isLoading || error) return <ErrorLoadingStatesWidget error={error} loading={isLoading} />;

  const dataTestIdPrefix = isInternational ? 'wire-international' : 'wire-us';

  return (
    <Box my="space70">
      <Text as="h2" fontWeight="semi" mb="space50" size="fontSize30">
        Wire Transfer Instructions
      </Text>

      <DataBlock layoutType="grid">
        <Box>
          <Label>Amount</Label>
          <Text as="p" data-test-id={`${dataTestIdPrefix}-amount`}>
            {wireInstructions.payment.paymentAmount}
          </Text>
        </Box>
        <Box>
          <Label>Payable to</Label>
          <Text as="p" data-test-id={`${dataTestIdPrefix}-payable-name`}>
            {wireInstructions.creditBeneficiary.payableName}
          </Text>
        </Box>
        <Box>
          <Label>Receiving Bank</Label>
          <Text as="p" data-test-id={`${dataTestIdPrefix}-receiving-bank-name`}>
            {wireInstructions.bank.name}
          </Text>
          <Text as="p" data-test-id={`${dataTestIdPrefix}-receiving-bank-address-line1`}>
            {wireInstructions.bank.addressLine1}
          </Text>
          <Text as="p" data-test-id={`${dataTestIdPrefix}-receiving-bank-address-line2`}>
            {wireInstructions.bank.addressLine2}
          </Text>
        </Box>
        <Box>
          <Label>Account #</Label>
          <Text as="p" data-test-id={`${dataTestIdPrefix}-account-number`}>
            {wireInstructions.payment.accountNumber}
          </Text>
        </Box>
        <Box>
          <Label>Routing #</Label>
          <Text as="p" data-test-id={`${dataTestIdPrefix}-routing-number`}>
            {wireInstructions.payment.routingNumber}
          </Text>
        </Box>
        {isInternational && (
          <Box>
            <Label>SWIFT Code</Label>
            <Text as="p" data-test-id="wire-international-swift">
              {wireInstructions.payment.swiftCode}
            </Text>
          </Box>
        )}
        <Box>
          <Label>Credit Beneficiary</Label>
          <Text as="p" data-test-id="wire-ep-payable-name">
            {wireInstructions.creditBeneficiary.payableName}
          </Text>
          <Text as="p" data-test-id="wire-ep-street-address">
            {wireInstructions.creditBeneficiary.streetAddress}
          </Text>
          <Text as="p" data-test-id="wire-ep-suite">
            {wireInstructions.creditBeneficiary.suite}
          </Text>
          <Text as="p" data-test-id="wire-ep-city-state-zip">
            {wireInstructions.creditBeneficiary.cityStateZip}
          </Text>
        </Box>
      </DataBlock>

      <Text as="h2" fontWeight="semi" mb="space50" mt="space70" size="fontSize30">
        Please Reference the Following
      </Text>
      <WireTransferTransactionInfo
        address={wireInstructions.transactionDetails.property?.address ?? {}}
        fileNumber={wireInstructions.transactionDetails.fileNum}
        layoutType="grid"
        participants={wireInstructions.transactionDetails.participants}
      />
    </Box>
  );
};
