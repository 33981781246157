import { partition } from 'lodash';
import { TransactionsPayload } from 'routes/Transactions/queries';
import {
  isClosingDateAvailable,
  isPendingTransaction,
  isPrelistingOpenedAvailable,
  sortByEstimatedClosing,
  sortByPreListingOpened,
} from 'utils/transactions/sortingTransactionHelpers';

export function transactionInEscrowSorter(transactionPayload: TransactionsPayload): TransactionsPayload {
  const [pendingTransactions, nonPendingTransactions] = partition(
    transactionPayload.myTransactions,
    isPendingTransaction,
  );

  const [pendingTransactionWithPODate, pendingTransactionWOPODate] = partition(
    pendingTransactions,
    isPrelistingOpenedAvailable,
  );

  const [nonPendingTransactionWithClosingDate, nonPendingTransactionWOClosingDate] = partition(
    nonPendingTransactions,
    isClosingDateAvailable,
  );

  return {
    ...transactionPayload,
    myTransactions: [
      ...pendingTransactionWOPODate,
      ...sortByPreListingOpened(pendingTransactionWithPODate),
      ...nonPendingTransactionWOClosingDate,
      ...sortByEstimatedClosing(nonPendingTransactionWithClosingDate),
    ],
  };
}
