import React, { useState, useEffect, useRef, FC } from 'react';
import { Formik, Form, Field, FieldProps, FormikProps } from 'formik';
import {
  Box,
  Button,
  EPContent,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLabel,
  InputErrorMessage,
  Stack,
  Text,
  Link,
} from '@endpoint/blockparty';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import { AlertMessage } from 'components/AlertMessage';
import { AnchoredButton } from 'components/AnchoredButton';
import { useAppContext } from 'utils/context';
import { ANCHORED_BUTTON_OFFSET } from 'consts/responsive';
import { trackAction } from 'utils/analytics';
import { formatPhoneNational, getNationalNumber } from 'utils/formatPhone';
import { ProfileTrackingEvents } from 'consts/analytics';
import { useCompanySupportPhoneNumber } from 'hooks/useCompanySupportPhoneNumber';
import { useRequestProfileUpdate } from 'hooks/useRequestProfileUpdate';
import { useGetActiveFileNumbers } from 'hooks/useGetActiveFileNumbers';
import { ContactType, ProfileUpdateInput } from '@endpoint/endpoint-bff-graphql-schema';
import { PROFILE_CANCELLATION } from 'consts/routes';
import { useNavigate } from 'react-router';
/*= ====================================== */

interface MappedInputFieldsTypes {
  fields: {
    label: string;
    name: string;
    autoFormat?: boolean;
    options?: any;
    validation?: any;
  }[];
}

/*= ====================================== */

export const Profile: FC = () => {
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { user } = useAppContext();
  const formikRef = useRef<FormikProps<ProfileUpdateInput>>(null);
  const supportPhoneNumber = useCompanySupportPhoneNumber();

  const { requestProfileUpdate, requestProfileUpdateResult, requestProfileUpdateError } = useRequestProfileUpdate();
  const { getActiveFileNumbers, activeFileNumbers, activeFileNumbersLoading, activeFileNumberError } =
    useGetActiveFileNumbers();

  const role = user.contactType || ContactType.TRANSACTEE_INDIVIDUAL;
  const isAgent = role === ContactType.AGENT;

  let formInitialValues: ProfileUpdateInput = {
    firstName: user?.firstName || '',
    middleName: user?.middleName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
    fileNumber: '',
  };

  const profileFields = [
    {
      label: 'First Name',
      name: 'firstName',
    },
    {
      label: 'Middle Name (optional)',
      name: 'middleName',
    },
    {
      label: 'Last Name',
      name: 'lastName',
    },
    {
      label: 'Email',
      name: 'email',
    },
    {
      label: 'U.S. Mobile Phone Number',
      name: 'phone',
      autoFormat: true,
      options: {
        numericOnly: true,
        blocks: [0, 3, 0, 3, 4],
        delimiters: ['(', ')', ' ', '-'],
      },
    },
    // To be revisited
    // {
    //   label: 'Brokerage Name',
    //   name: 'brokerage',
    // },
  ];

  if (isAgent) {
    formInitialValues = {
      ...formInitialValues,
      licenseNumber: user?.realEstateAgentLicenseNumber || '',
    };

    profileFields.push({
      label: 'License Number',
      name: 'licenseNumber',
    });
  }

  const deleteNullFields = (input: ProfileUpdateInput) => {
    const tmpObject = input;

    Object.keys(input).forEach((key) => {
      if (!tmpObject[key as keyof ProfileUpdateInput]) delete tmpObject[key as keyof ProfileUpdateInput];
    });

    return tmpObject;
  };

  useEffect(() => {
    if (requestProfileUpdateResult) {
      setHasSubmitted(true);
      formikRef?.current?.setSubmitting(false);
    }

    if (requestProfileUpdateError) {
      formikRef?.current?.setStatus({ error: 'Failed to request profile edit' });
      formikRef?.current?.setSubmitting(false);
    }
  }, [requestProfileUpdateResult, requestProfileUpdateError]);

  useEffect(() => {
    formikRef?.current?.setSubmitting(true);
    const values = formikRef.current?.values;

    if (activeFileNumberError) {
      Sentry.captureEvent({
        level: Sentry.Severity.Warning,
        message: `Error trying to retrieve active transactions fileNumbers by status. Error: ${JSON.stringify(
          activeFileNumberError,
        )}}`,
      });
    }

    const fileNumbers = activeFileNumbers?.getActiveFileNumbers;

    if ((fileNumbers || activeFileNumberError) && values) {
      const input = deleteNullFields({
        fileNumber: fileNumbers ? fileNumbers[0] : undefined,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: getNationalNumber(values.phone),
        middleName: user?.middleName !== values.middleName ? values.middleName : undefined,
        licenseNumber: user?.realEstateAgentLicenseNumber !== values.licenseNumber ? values.licenseNumber : undefined,
      });

      requestProfileUpdate(input).catch((err) => {
        Sentry.captureEvent({
          level: Sentry.Severity.Warning,
          message: `Error trying to requestProfileUpdate. Error: ${JSON.stringify(err)}}`,
        });
      });
    } else {
      formikRef?.current?.setSubmitting(false);
    }
  }, [user, requestProfileUpdate, activeFileNumbers, activeFileNumbersLoading, activeFileNumberError]);

  const handleEditProfileRequest = async () => {
    trackAction(ProfileTrackingEvents.PROFILE_EDIT);
    getActiveFileNumbers();
  };

  return (
    <>
      <EPContent mb={{ base: ANCHORED_BUTTON_OFFSET, md: 'space0' }}>
        <Box>
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            innerRef={formikRef}
            validationSchema={profileEditValidationSchema}
            onSubmit={handleEditProfileRequest}
          >
            {({ dirty, isSubmitting, isValid, resetForm, status: formStatus }) => {
              return (
                <Form>
                  <Heading mb="space50" size="fontSize40">
                    Request Profile Edit
                  </Heading>
                  {hasSubmitted && (
                    <Box mb="space70">
                      <AlertMessage
                        description={`We will update your profile as soon as we review your changes. If your issue is urgent, call us at ${supportPhoneNumber}.`}
                        status="info"
                        title="Your request to update information has been received."
                      />
                    </Box>
                  )}
                  {formStatus?.error && (
                    <Box mb="space70">
                      <AlertMessage description={formStatus?.error} status="error" />
                    </Box>
                  )}
                  <Text as="p" mb="space70">
                    Fill out the fields you would like to request edit. You are not required to fill out the entire
                    form.
                  </Text>
                  <Stack spacing="space60">
                    <MappedInputFields fields={profileFields} />
                  </Stack>

                  <Box mb="space90" mt="space80">
                    <Text data-test-id="text-cancel-link">
                      If you would like to deactivate your account,{' '}
                      <Link
                        cursor="pointer"
                        data-test-id="profile-cancel-link"
                        textDecoration="underline"
                        onClick={() => navigate(PROFILE_CANCELLATION)}
                      >
                        <Text as="p" color="blue500" display="inline">
                          click here
                        </Text>
                      </Link>
                      .
                    </Text>
                  </Box>

                  <AnchoredButton>
                    <Flex justifyContent="flex-end">
                      <Button
                        data-test-id="cancel-profile-edit"
                        isDisabled={!dirty || !isValid}
                        isLoading={isSubmitting}
                        mr="space50"
                        type="reset"
                        variant="outline"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        isDisabled={!dirty || !isValid}
                        isLoading={isSubmitting}
                        loadingText="Sending request..."
                        type="submit"
                      >
                        Request Edit
                      </Button>
                    </Flex>
                  </AnchoredButton>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </EPContent>
    </>
  );
};

export const MappedInputFields: FC<MappedInputFieldsTypes> = ({ fields }) => {
  return (
    <>
      {fields.map((profileField) => {
        const { label, name, autoFormat, options } = profileField;

        return (
          <Field key={label} name={name}>
            {({ field, meta }: FieldProps) => (
              <InputGroup isInvalid={Boolean(meta?.error) && meta?.touched}>
                <InputLabel>{label}</InputLabel>
                {autoFormat ? (
                  <>
                    <Input
                      {...field}
                      as={Cleave}
                      data-test-id={name}
                      options={options}
                      value={formatPhoneNational(field.value)}
                    />
                  </>
                ) : (
                  <Input {...field} data-test-id={name} />
                )}

                {meta.error && <InputErrorMessage>{meta.error}</InputErrorMessage>}
              </InputGroup>
            )}
          </Field>
        );
      })}
    </>
  );
};

export const profileEditValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Please enter an email address in valid format, e.g. name@website.com')
    .required('Email is required'),
  phone: Yup.string()
    .transform((value: string) => {
      // Remove non-numerics and validates against new value
      const newValue = value.replace(/\D/g, '');

      return newValue;
    })
    .min(10, 'Please enter your 10-digit phone number')
    .required('Phone number is required'),
  licenseNumber: Yup.string(),
});
