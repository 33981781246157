import React, { FC, useContext } from 'react';
import { formatDate, MONTH_DAY_YEAR_TIME } from 'utils/formatDate';
import { Text } from '@endpoint/blockparty';
import { CompanyContext } from 'utils/context';
import { CheckedTransactionDocument } from 'utils/documents';

interface DocumentAddedProps {
  doc: CheckedTransactionDocument;
}

export const DocumentAdded: FC<DocumentAddedProps> = ({ doc }) => {
  const { companyName } = useContext(CompanyContext);

  return (
    <Text color="carbon500" data-test-id="document-added">
      {`${formatDate(doc.createdAt, MONTH_DAY_YEAR_TIME)}, by ${
        doc.uploadedBy && doc.uploadedBy.toLowerCase() === 'customer' ? 'you' : companyName
      }`}
    </Text>
  );
};
