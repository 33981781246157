import { useContext } from 'react';
import { SIGN_UP_MFA_URL } from 'Auth/Utilities/Constant';
import { AppContextType, AppContext } from 'utils/context';
import { getLegacyErrorCode, getPhoneNumber } from 'Auth/Utilities/helper';
import useOrganizationId from 'hooks/useOrganizationId';
import { GenericTrackingEvents, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { useSignUpMutation } from '../useSignUpMutation';
import { useHandleAuthResponse } from '../useHandleAuthResponse';
import { useAuth } from '../useAuth';

export const useSignUp = () => {
  const organizationId = useOrganizationId();
  const { setData } = useAuth();
  const { authFields, setAuthFields, setUser }: AppContextType = useContext(AppContext);
  const { signUpMutation } = useSignUpMutation();
  const handleAuthResponse = useHandleAuthResponse();

  const signUp = async (email: string, phone: string, password: string) => {
    try {
      const formattedPhoneNumber = getPhoneNumber(phone)!;

      setUser((user: any) => ({ ...user, loading: true }));

      // Persist for codeResend
      setData('username', email);
      setData('password', password);
      setData('phone', formattedPhoneNumber);

      const response = await signUpMutation({
        username: email,
        password,
        phoneNumber: formattedPhoneNumber,
        organizationId,
      });
      const signInData = response?.data?.signUp;

      if (signInData) {
        handleAuthResponse(signInData, SIGN_UP_MFA_URL, authFields.navigateToAfterLogin);
      }
    } catch (error: any) {
      const errorCode = getLegacyErrorCode(error?.message ?? error);

      trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
      setAuthFields({ ...authFields, errorCode });
    } finally {
      setUser((user: any) => ({ ...user, loading: false }));
    }
  };

  return { signUp };
};
