import { Step } from 'components/form/TwoColumnWithProgressContainer';

import { Step1, Summary, validationSchema } from './Step1';
import { Step2, Summary as Step2Summary, step2ValidationSchema } from './Step2';
import { Step3, step3ValidationSchema } from './Step3';
import { Step4, Summary as Step4Summary, step4ValidationSchema } from './Step4';
import { Step5, Summary as Step5Summary, step5ValidationSchema } from './Step5';
import { Step6, Summary as Step6Summary } from './Step6';
import { ReviewStep } from './ReviewStep';
import { ThankYouStep } from './ThankYouStep';
import {
  STEP1_TITLE,
  STEP2_TITLE,
  STEP3_TITLE,
  STEP4_TITLE,
  STEP5_TITLE,
  STEP6_TITLE,
  REVIEW_STEP_TITLE,
  THANK_YOU_STEP_TITLE,
} from './constants';

export const Steps: Step[] = [
  {
    component: Step1,
    title: STEP1_TITLE,
    summaryTitle: 'Existing Order',
    summary: Summary,
    validationSchema,
  },
  {
    component: Step2,
    title: STEP2_TITLE,
    summaryTitle: 'Purchase Agreement',
    summary: Step2Summary,
    validationSchema: step2ValidationSchema,
  },
  {
    component: Step3,
    title: STEP3_TITLE,
    validationSchema: step3ValidationSchema,
  },
  {
    component: Step4,
    title: STEP4_TITLE,
    summaryTitle: 'Client(s)',
    summary: Step4Summary,
    validationSchema: step4ValidationSchema,
  },
  {
    component: Step5,
    title: STEP5_TITLE,
    summaryTitle: 'Teammate(s)',
    summary: Step5Summary,
    validationSchema: step5ValidationSchema,
  },
  {
    component: Step6,
    title: STEP6_TITLE,
    summaryTitle: 'Notes',
    summary: Step6Summary,
  },
  {
    component: ReviewStep,
    title: REVIEW_STEP_TITLE,
  },
  {
    component: ThankYouStep,
    title: THANK_YOU_STEP_TITLE,
  },
];
