import React, { Fragment, FC, useCallback, useState, useEffect } from 'react';
import { Box, Flex, Stack, Text, Icon } from '@endpoint/blockparty';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { omit } from 'lodash';
import { Document } from '@endpoint/endpoint-bff-graphql-schema';

import { getUniqueId } from '../../utils/getUniqueId';
import { S3MetaData } from '../../routes/TransactionDetail/Documents/interfaces';
import { Uploading } from '../Upload/Uploading';
import { UploadFailed } from '../Upload/UploadFailed';
import { UploadSuccess } from '../Upload/UploadSuccess';

/* ====================================== */

const MAX_UPLOAD_SIZE = 50000000;
const MIME_TYPES = ['application/pdf', 'image/jpeg'];

export enum UploadStatus {
  LOADING = 'loading',
  FAILED = 'failed',
  SUCCESS = 'success',
  DEFAULT = 'default',
}

export enum FileRejections {
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
}

interface UploadProps extends DropzoneOptions {
  multiple?: boolean;
  maxSize?: number;
  accept?: Array<string>;
  callback: Function;
  files?: UploadedFiles;
  useGrayUploadSuccess?: boolean;
}
export interface UploadedFileProps {
  id: string;
  name: string;
  file?: Partial<File>;
  percentage?: number;
  status?: UploadStatus;
  cancel?: XMLHttpRequest['abort'];
  close?: () => void;
  response?: UploadResponse[];
  uploadUrl?: string;
  fileType: string;
}

export interface UploadResponse {
  filename: string;
  mime_type: string;
  s3?: S3MetaData;
}

export interface UploadedFiles {
  [key: string]: UploadedFileProps;
}

export interface CreateTransactionDocumentResponse {
  createTransactionDocument: Document;
}

/*= ====================================== */

export const UploadTransactionDocument: FC<UploadProps> = ({
  maxSize = MAX_UPLOAD_SIZE,
  accept = MIME_TYPES,
  multiple = true,
  callback,
  files,
  useGrayUploadSuccess = false,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>(files || {});

  useEffect(() => {
    callback && callback(uploadedFiles);
    Object.entries(uploadedFiles).forEach(([key, value]) => {
      uploadedFiles[key] = { ...value, close: setDefaultStatus(value.name) };
    });
  }, [callback, uploadedFiles]);

  const setDefaultStatus = (id: string) => () => {
    setUploadedFiles((prev) => omit(prev, id));
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const fileWithIds = acceptedFiles.map((x) => ({ id: getUniqueId(), file: x, name: x.name }));

      const fileState = fileWithIds.reduce((acc: UploadedFiles, file) => {
        acc[file.name] = {
          ...file,
          name: file.name,
          percentage: 100,
          fileType: file.file.type,
          status: UploadStatus.SUCCESS,
          close: setDefaultStatus(file.name),
        };

        return acc;
      }, {} as UploadedFiles);

      setUploadedFiles((prev) => ({
        ...prev,
        ...fileState,
      }));
    },
    [setUploadedFiles],
  );

  // Dropzone hook
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept,
    maxSize,
    multiple,
    onDrop,
  });

  const toggleUploadWidget = !multiple && Object.values(uploadedFiles).length && Object.values(uploadedFiles)[0].status;

  return (
    <>
      {toggleUploadWidget ? null : (
        <Box {...getRootProps()} cursor="pointer" mb="space40">
          <Box as="input" {...getInputProps()} />
          <Flex
            alignItems="center"
            bg={isDragActive && !isDragReject ? 'blue0' : 'white'}
            border="1px dashed"
            borderColor="carbon300"
            justifyContent="center"
            py="20px"
          >
            <Icon color="blue500" mr="space30" name="Upload" size="large" />
            <Text size="fontSize20">Choose a file or drag it here</Text>
          </Flex>
        </Box>
      )}

      {/* Default error message */}
      {fileRejections.length > 0 ? (
        <Text as="p" color="watermelon500" data-test-id="file-rejection" mb="space40">
          Error: {getErrorMessage(fileRejections[0]?.errors[0]?.code)}
        </Text>
      ) : null}

      <Stack>
        {Object.values(uploadedFiles).map((uploadedFile: UploadedFileProps, i) => {
          const fileId = Object.keys(uploadedFiles)[i];

          return (
            <Fragment key={fileId}>
              {uploadedFile.status === UploadStatus.LOADING && <Uploading uploadedFile={uploadedFile} />}
              {uploadedFile.status === UploadStatus.FAILED && <UploadFailed uploadedFile={uploadedFile} />}
              {uploadedFile.status === UploadStatus.SUCCESS && (
                <UploadSuccess uploadedFile={uploadedFile} useGrayUploadSuccess={useGrayUploadSuccess} />
              )}
            </Fragment>
          );
        })}
      </Stack>
    </>
  );
};

export const getErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case FileRejections.FILE_INVALID_TYPE:
      return 'File type must be PDF or JPG';
    case FileRejections.FILE_TOO_LARGE:
      return 'File is larger than 50 MB';
    default:
      return 'File is invalid';
  }
};
