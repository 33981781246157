import React, { FC, Fragment } from 'react';
import { Box, Card, CardSection, Divider, Image, Flex, Text, Grid } from '@endpoint/blockparty';
import { Transaction, TransactionType, TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { getSaleDetails } from 'utils/transactions/getSaleDetails';
import { PLACEHOLDER_MLS } from 'consts/assets';
import { useAppContext } from 'utils/context';
import { getRefiAndEquityDetails } from 'utils/transactions/getRefiAndEquityDetails';
import { DetailList } from 'utils/transactions';

import { Address } from './Address';
import { StatusVariant } from './StatusVariant';

export const TransactionCard: FC<Transaction> = (transaction) => {
  const { user } = useAppContext();
  const { property, status } = transaction;
  const { propertyImage, address } = property;
  const isCancelled = status === TransactionStatus.CANCELLED;
  const transactionIsRefiOrEquity =
    transaction.type === TransactionType.REFINANCE || transaction.type === TransactionType.EQUITY;

  const transactionCardDetails = transactionIsRefiOrEquity
    ? getRefiAndEquityDetails({ transaction, userId: user?.id })
    : getSaleDetails({ transaction, userId: user?.id });

  return (
    <Card
      as="article"
      borderRadius="radiusNone"
      data-test-id={`transaction-card-${transaction.fileNum}`}
      display="flex"
      flexDirection="column"
      height="100%"
      id={transaction.id}
      overflow="hidden"
      variant="ghost"
    >
      <Box>
        <Image
          alt="Future home"
          display="block"
          height="112px"
          objectFit="cover"
          src={propertyImage || PLACEHOLDER_MLS}
          style={{ filter: isCancelled ? 'grayscale(100%)' : undefined }}
        />
      </Box>
      <CardSection flexDirection="column" height="100%" p="space50">
        <Address address={address} />
        <Divider mb="space50" mt="space30" />
        <Flex flexDirection="column" height="100%" justifyContent="space-between">
          <Grid data-test-id="transaction-card-details" templateColumns="72px 1fr">
            {transactionCardDetails.map(({ label, value }: DetailList, i: number) => {
              const key = `${label}${i}`;

              return (
                <Fragment key={key}>
                  <Text data-test-id={`transaction-detail-${label}`} fontWeight="semi" size="fontSize10">
                    {label}
                  </Text>
                  <Text
                    data-test-id="transaction-detail-value"
                    size="fontSize10"
                    style={
                      i === 0
                        ? {
                            // truncates string after second line
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                          }
                        : undefined
                    }
                  >
                    {value}
                  </Text>
                </Fragment>
              );
            })}
          </Grid>
          <Box data-test-id="transaction-card-status" mt="space60">
            <StatusVariant transaction={transaction} />
          </Box>
        </Flex>
      </CardSection>
    </Card>
  );
};
