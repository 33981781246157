import React, { FC } from 'react';
import { SummaryField } from '@endpoint/endpoint-bff-graphql-schema';
import { Text } from '@endpoint/blockparty';

interface GeneralPurposeSummaryProps {
  field: SummaryField;
}

export const GeneralPurposeSummary: FC<GeneralPurposeSummaryProps> = ({ field }: GeneralPurposeSummaryProps) => {
  const { isSensitiveInformation, answer } = field;

  return (
    <Text className={isSensitiveInformation ? 'fs-exclude dd-privacy-hidden' : ''} data-test-id="summary-field-answer">
      {answer || 'N/A'}
    </Text>
  );
};
