import React, { useState, FC, useEffect } from 'react';
import { Box, Flex, Heading, Button, Divider, Stack, useDisclosure, useToast } from '@endpoint/blockparty';
import { CENTERED_CONTENT_WIDTH } from 'consts/responsive';
import { AlertMessage } from 'components/AlertMessage';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AnchoredButton } from 'components/AnchoredButton';
import { DocumentsSkeleton } from 'components/Skeleton';
import { clearTokens } from 'utils/auth/storage';
import { DOWNLOAD_DOCUMENT, isWebView } from 'utils/webview';
import { renewToken } from 'utils/auth';
import { useDocuments } from 'hooks/useDocuments';
import { usePollingReducer } from 'hooks/usePollingReducer';
import { CheckedTransactionDocument } from 'utils/documents';
import { DocumentUploadValuesUnclean } from 'consts/uploadDocument';
import { Document } from '@endpoint/endpoint-bff-graphql-schema';
import { useTransaction } from 'hooks/useTransaction';
import { PropertyHeader } from 'components/PropertyHeader';

import { DocumentInstructions } from './Components/DocumentInstructions';
import { WebViewDocumentInstructions } from './Components/WebViewDocumentInstructions';
import { DocumentsList } from './Components/DocumentsList';
import { DocumentsListHeader } from './Components/DocumentsListHeader';
import { WebViewDocumentsListHeader } from './Components/WebViewDocumentsListHeader';
import { DocumentNoteModal } from './Components/DocumentNoteModal';
import { DocumentUploadDrawer } from './Components/DocumentUploadDrawer';
import { downloadDocumentsById } from './downloadDocuments';
import { uploadDocumentsBff } from './uploadDocuments';
import { LegacyDocumentId } from './interfaces';
import { useCreateTransactionDocument } from '../../../hooks/useCreateTransactionDocument';

export interface CreateTransactionDocumentResponse {
  createTransactionDocument: Document;
}

interface DocumentPollingState {
  totalDocsAfterUpload: number | null;
}

export const Documents: FC = () => {
  const { transactionId = '' } = useParams();
  const navigate = useNavigate();
  const {
    pollingState: { isPolling: isPollingDocuments, totalDocsAfterUpload },
    dispatchPollingAction,
  } = usePollingReducer<DocumentPollingState>({ totalDocsAfterUpload: null });
  const { documents, documentsLoading, documentsError, startPollingDocuments, stopPollingDocuments } =
    useDocuments(transactionId);

  const { data: transactionData } = useTransaction(transactionId);

  const isDataLoading = (!documents && !documentsError) || !transactionData;

  const { createTransactionDocument } = useCreateTransactionDocument({
    errorPolicy: 'all',
  });

  const [noteModal, openNoteModal] = useState(false);
  const [note, setNote] = useState('');

  const [documentData, setDocumentData] = useState<CheckedTransactionDocument[]>([]);

  const areSomeDocumentsChecked: boolean = documentData.some((doc) => doc.checked);
  const areAllDocumentsChecked: boolean = documentData.every((doc) => doc.checked);

  const toast = useToast();

  useEffect(() => {
    if (isPollingDocuments && documents?.length === totalDocsAfterUpload) {
      dispatchPollingAction({
        type: 'stop-polling',
        stopPolling: stopPollingDocuments,
        customState: { totalDocsAfterUpload: null },
      });
    }

    return () => {
      // Always stop polling when the component unmounts
      if (isPollingDocuments) {
        dispatchPollingAction({
          type: 'stop-polling',
          stopPolling: stopPollingDocuments,
          customState: { totalDocsAfterUpload: null },
        });
      }
    };
  }, [dispatchPollingAction, documents?.length, isPollingDocuments, totalDocsAfterUpload, stopPollingDocuments]);

  useEffect(() => {
    if (documents && !documentsLoading) {
      const updatedData = documents.map((datum) => ({ ...datum, checked: false }));

      setDocumentData(updatedData);
    }
  }, [documents, documentsLoading]);

  const handleNoteClick = (clickedNote: string) => {
    setNote(clickedNote);
    openNoteModal(!noteModal);
  };

  const handleCheckboxClick = (id: string) => {
    const multipleDocumentDownloadRule = (datum: CheckedTransactionDocument) => ({
      ...datum,
      checked: datum.id === id ? !datum.checked : datum.checked,
    });

    const singleDocumentDownloadRule = (datum: CheckedTransactionDocument) => {
      return {
        ...datum,
        checked: datum.id === id ? !datum.checked : false,
      };
    };

    const updatedData = documentData.map(isWebView() ? singleDocumentDownloadRule : multipleDocumentDownloadRule);

    setDocumentData(updatedData);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleToggleAllDocuments = (toggle: boolean) => {
    const updatedData = documentData.map((datum) => ({ ...datum, checked: toggle }));

    setDocumentData(updatedData);
  };

  const documentsSelected = documentData.filter((doc) => doc.checked);

  const sendWebViewMessage = async (downloadDocumentIds: LegacyDocumentId[]) => {
    const accessToken = await renewToken();

    if (!accessToken) {
      await clearTokens();
      navigate('/signin');
    }

    const downloadDocumentPayload = {
      event: DOWNLOAD_DOCUMENT,
      payload: {
        accessToken,
        downloadDocumentIds,
        transactionId,
      },
    };

    window?.ReactNativeWebView?.postMessage(JSON.stringify(downloadDocumentPayload));
  };

  const handleUploadDocuments = async (uploadTransactionId: string, files: DocumentUploadValuesUnclean) => {
    const uploadedDocs = await uploadDocumentsBff(uploadTransactionId, files, createTransactionDocument);

    if (uploadedDocs.length) {
      toast({
        duration: 4000,
        description: `${uploadedDocs.length} document${uploadedDocs.length === 1 ? '' : 's'} uploaded`,
        icon: 'CheckCircle',
        isClosable: true,
      });
    }

    // We need to poll for documents because there is a delay between upload and when the document is available
    dispatchPollingAction({
      type: 'start-polling',
      startPolling: () => {
        startPollingDocuments(3000);
      },
      customState: { totalDocsAfterUpload: (documents?.length ?? 0) + files.files.length },
    });
  };

  const handleDownloadDocuments = (downloadedDocuments: CheckedTransactionDocument[]) => {
    const downloadDocumentIds = downloadedDocuments.map((doc) => doc.id);

    downloadDocumentsById(transactionId, downloadDocumentIds)
      .then((downloadedDocs) => {
        handleToggleAllDocuments(false);

        if (Array.isArray(downloadedDocs) && downloadedDocs.length) {
          toast({
            duration: 4000,
            description: `${downloadedDocs.length} documents downloaded`,
            icon: 'CheckCircle',
            isClosable: true,
          });
        }

        if (!Array.isArray(downloadedDocs) && downloadedDocs.size) {
          toast({
            duration: 4000,
            description: '1 document downloaded',
            icon: 'CheckCircle',
            isClosable: true,
          });
        }
      })
      .catch(() => {
        Sentry.captureEvent({
          level: Sentry.Severity.Info,
          message: `Error downloading documents: ${JSON.stringify(
            downloadDocumentIds,
          )}  on transaction: ${transactionId}`,
        });

        toast({
          duration: 5000,
          position: 'top-right',
          render: () => <AlertMessage />,
        });
      });
  };

  return (
    <>
      <Box
        height="100%"
        margin={{ md: '0 auto' }}
        maxWidth={CENTERED_CONTENT_WIDTH}
        overflow={{ base: 'auto', md: 'initial' }}
        width="100%"
      >
        <Box mx={{ base: 'space50', md: 'space60', lg: 'space0' }} py={{ base: 'space50', md: 'space60' }}>
          {isDataLoading && <DocumentsSkeleton />}
          {documentsError && (
            <Box m="space50">
              <AlertMessage />
            </Box>
          )}
          {documents && !documentsError && transactionData && (
            <>
              <PropertyHeader
                fileNum={transactionData?.transaction.fileNum}
                property={transactionData?.transaction.property}
              />

              <Box
                backgroundColor="white"
                height="100%"
                justifyContent="center"
                mt={{ base: 'space0', md: 'space60' }}
                overflow="auto"
              >
                <Box
                  justifyContent="center"
                  m={{ base: 'space50', md: 'space70' }}
                  mt={{ base: 'space70', lg: 'space60' }}
                >
                  <Heading data-test-id="header" size="fontSize50">
                    Your Documents
                  </Heading>
                  <Flex alignItems="flex-end" justifyContent="space-between" mb="space50">
                    <Flex flex={1} maxWidth="500px" mr="space60">
                      {isWebView() ? <WebViewDocumentInstructions /> : <DocumentInstructions />}
                    </Flex>
                    <Box display={{ base: 'none', md: 'flex' }}>
                      <Button
                        data-test-id="doc-download-button"
                        iconLeft="Download"
                        isDisabled={documentsSelected.length === 0}
                        mr="space50"
                        variant="outline"
                        onClick={() => handleDownloadDocuments(documentsSelected)}
                      >
                        Download
                      </Button>
                      <Button data-test-id="doc-upload-button" onClick={onOpen}>
                        Upload
                      </Button>
                    </Box>
                  </Flex>
                  <Divider />
                  <Box>
                    {isWebView() ? (
                      <WebViewDocumentsListHeader />
                    ) : (
                      <DocumentsListHeader
                        areAllDocumentsChecked={areAllDocumentsChecked}
                        areSomeChecked={areSomeDocumentsChecked}
                        onCheckAll={handleToggleAllDocuments}
                      />
                    )}
                    <Divider />
                    <Box backgroundColor="white" mb="space90">
                      <DocumentsList
                        docs={documentData || []}
                        onCheckboxClick={handleCheckboxClick}
                        onNoteClick={handleNoteClick}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <AnchoredButton display={{ base: 'block', md: 'none' }}>
                <Stack>
                  <Button
                    data-test-id="download-button"
                    iconLeft="Download"
                    isDisabled={documentsSelected.length === 0}
                    variant="outline"
                    onClick={async () => {
                      const documentsToDownloadMetadata = documentsSelected.map((doc) => ({
                        id: doc.id,
                        mime_type: doc.mimeType,
                        name: doc.name,
                        type: doc.type,
                      }));

                      if (isWebView()) {
                        await sendWebViewMessage(documentsToDownloadMetadata);
                      } else {
                        handleDownloadDocuments(documentsSelected);
                      }
                    }}
                  >
                    Download
                  </Button>
                  <Button data-test-id="upload-button" loadingText="Sending request..." onClick={onOpen}>
                    Upload
                  </Button>
                </Stack>
              </AnchoredButton>
            </>
          )}
        </Box>
      </Box>
      <DocumentUploadDrawer
        handleUploadSubmit={handleUploadDocuments}
        isOpen={isOpen}
        transactionId={transactionId}
        onClose={onClose}
      />
      <DocumentNoteModal isOpen={noteModal} note={note} onClose={() => openNoteModal(!noteModal)} />
    </>
  );
};
