import React, { FC } from 'react';
import { MilestoneStatus, TransactionSummary } from '@endpoint/endpoint-bff-graphql-schema';
import { Box, Flex, ProgressBar, Tag, Text } from '@endpoint/blockparty';

interface MilestoneTrackerProps {
  transaction: TransactionSummary;
}

export const MilestoneTracker: FC<MilestoneTrackerProps> = ({ transaction }) => {
  const { milestones, todoAssignmentCount } = transaction;
  const orderPending = milestones[0]?.status === MilestoneStatus.UPCOMING;
  const completedMilestones = milestones.filter((m) => m.status === MilestoneStatus.COMPLETE).length;
  const totalMilestones = milestones.length;
  const todoCount = todoAssignmentCount || 0;
  const pluralTodoCount = todoCount > 1 ? "'s" : '';

  return (
    <>
      <ProgressBar completed={completedMilestones} steps={totalMilestones} />
      <Box mt="space30">
        {orderPending ? (
          <Flex data-test-id="pending-transaction-tag">
            <Tag alignItems="center" display="flex" variantColor="orderProcessing">
              Order Pending
            </Tag>
          </Flex>
        ) : (
          <>
            <Text data-test-id="transaction-milestone-count" fontWeight="semi" size="fontSize10">
              Milestone {completedMilestones}/{totalMilestones}
              {todoCount > 0 && ': '}
            </Text>
            {todoCount > 0 && (
              <Text data-test-id="transaction-todo-count" size="fontSize10">
                {todoCount} to-do{pluralTodoCount} due
              </Text>
            )}
          </>
        )}
      </Box>
    </>
  );
};
