import { gql } from '@apollo/client';

export const GET_LINK_TOKEN = gql`
  query GetPlaidLinkToken {
    getPlaidLinkToken {
      linkToken
      expiresAt
      errorMessage
    }
  }
`;
