import { gql } from '@apollo/client';

export const GET_UNITY_CONTACT = gql`
  query getUnityContact {
    getUnityContact {
      id
      firstName
      middleName
      lastName
      email
      contactClass
      licenses {
        id
        licenseNumber
        stateOfOperation
      }
    }
  }
`;
