import { useQuery } from '@apollo/client';
import { TransactionContacts } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';

import { GET_TRANSACTION_CONTACTS } from './queries';

export const useTransactionContacts = (transactionId: string) => {
  const sentryTransaction = useSentryTransaction('queryTransactionContacts', 'GraphQL client query');

  const { data, error, loading } = useQuery<{ getTransactionContacts: TransactionContacts }>(GET_TRANSACTION_CONTACTS, {
    variables: { transactionId },
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  if (!loading) {
    sentryTransaction.finish();
  }

  return {
    contacts: data?.getTransactionContacts,
    contactsLoading: loading,
    contactsError: error,
  };
};
