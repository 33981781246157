import { Field, FieldProps } from 'formik';
import React, { FC } from 'react';
import { Input, InputGroup, InputLabel, InputErrorMessage } from '@endpoint/blockparty';
import Cleave from 'cleave.js/react';
import { formatPhoneNational } from 'utils/formatPhone';

export interface MappedInputFieldsTypes {
  fields: {
    label: string;
    name: string;
    autoFormat?: boolean;
    options?: any;
    validation?: any;
  }[];
}

export const MappedInputFields: FC<MappedInputFieldsTypes> = ({ fields }) => {
  return (
    <>
      {fields.map((profileField) => {
        const { label, name, autoFormat, options } = profileField;

        return (
          <Field key={label} name={name}>
            {({ field, meta }: FieldProps) => (
              <InputGroup isInvalid={Boolean(meta?.error) && meta?.touched}>
                <InputLabel>{label}</InputLabel>
                {autoFormat ? (
                  <Input
                    {...field}
                    as={Cleave}
                    data-test-id={name}
                    options={options}
                    value={formatPhoneNational(field.value)}
                  />
                ) : (
                  <Input {...field} data-test-id={name} />
                )}

                {meta.error && <InputErrorMessage>{meta.error}</InputErrorMessage>}
              </InputGroup>
            )}
          </Field>
        );
      })}
    </>
  );
};
