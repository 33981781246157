import { TodoAssignmentMetadata } from '@endpoint/todo-service-client';

export enum DateTimePickerRange {
  START = 'start',
  END = 'end',
}

export function extractParam(type: DateTimePickerRange, param?: string): string | undefined {
  if (!param) {
    return undefined;
  }

  const parsedParam = JSON.parse(param) as Array<TodoAssignmentMetadata>;

  return parsedParam.find((item) => item.type === type)?.name;
}
