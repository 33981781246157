import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Todo } from '@endpoint/platform-api-connector/dist/graphql-types';
import replace from 'lodash/replace';
import { clearTokens } from 'utils/auth/storage';
import { ErrorLoadingStatesWidget } from 'routes/TransactionDetail/Todo/TodoStep/TodoStepComponent/ErrorLoadingStatesWidget';
import { formatDate, MONTH_AND_DAY } from 'utils/formatDate';
import { getTransactionRole } from 'utils/transactions/getTransactionRole';
import { renewToken } from 'utils/auth';
import { useAppContext } from 'utils/context';
import { useTransaction } from 'hooks/useTransaction';
import { TodoSkeleton } from 'components/Skeleton';
import { useGetPayableToName } from 'hooks/useGetPayableName';

interface MobileCheckDepositProps {
  value: string | undefined;
}

export const MobileCheckDeposit: FC<MobileCheckDepositProps> = ({ value }) => {
  const [accessToken, setAccessToken] = useState<string>('');
  const navigate = useNavigate();
  const { transactionId = '', todoId = '' } = useParams();
  const { user } = useAppContext();
  const { data: transactionData, error: transactionError, loading: transactionLoading } = useTransaction(transactionId);
  const { transaction } = { ...transactionData };
  const returnRoute = `/transaction/${transactionId}/todos`;
  const mobileCheckTodo =
    transaction?.todos.filter((todo: Todo) => todo?.assignments && todo.assignments[0]?.id === todoId) || [];
  const dueDate: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.due) || null;
  const fileNumber: string | null = (transaction && transaction.fileNum) || null;
  const firstName: string | null = (user && user.firstName) || null;
  const lastName: string | null = (user && user.lastName) || null;
  const groupId: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.id) || null;
  const note: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.escrowNote) || null;
  const todoName: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.name) || null;
  const todoStatus: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.status) || null;
  const userId: string | null = (user && user.id) || null;
  const expectedUserRole: string = transaction
    ? getTransactionRole({ transaction, userId: user.id }).label
    : 'Not Available';
  const formattedPaymentAmount: number = value && value.length ? Number(replace(value, ',', '')) : 0;
  const { payableTo, payableToNameLoading, payableToNameError } = useGetPayableToName({
    transactionId,
    marketId: transaction?.marketId || undefined,
    isUnityTransaction: Boolean(user?.unityTransactionCreationEnabled),
  });

  const mobileCheckPayload = useMemo(
    () => ({
      event: 'MOBILE_CHECK_DEPOSIT',
      payload: {
        accessToken,
        returnRoute,
        transactionId,
        taskId: todoId,
        fileNumber,
        groupId,
        todoName,
        todoStatus,
        userId,
        userRole: expectedUserRole,
        firstName,
        lastName,
        dueDate: dueDate ? formatDate(dueDate, MONTH_AND_DAY, true) : null,
        transactionAddress: {
          street1: transaction?.property.address.street1,
          street2: transaction?.property.address.street2,
          city: transaction?.property.address.city,
          state: transaction?.property.address.state,
          zip: transaction?.property.address.zip,
          county: transaction?.property.address.county,
        },
        note,
        checkInstructions: {
          amount: formattedPaymentAmount,
          payableTo,
        },
      },
    }),
    [
      accessToken,
      returnRoute,
      transactionId,
      todoId,
      fileNumber,
      groupId,
      todoName,
      todoStatus,
      userId,
      expectedUserRole,
      firstName,
      lastName,
      dueDate,
      formattedPaymentAmount,
      payableTo,
      transaction,
      note,
    ],
  );

  useEffect(() => {
    const clearSession = async () => {
      await clearTokens();
      navigate('/signin');
    };

    renewToken()
      .then(async (token: string) => {
        token ? setAccessToken(token) : await clearSession();
      })
      .catch(async () => clearSession());
  }, [navigate]);

  useEffect(() => {
    if (accessToken) {
      if (!transactionError && !transactionLoading) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(mobileCheckPayload));
      }
    }
  }, [accessToken, transactionError, transactionLoading, mobileCheckPayload]);

  if (transactionError || transactionLoading || payableToNameError || payableToNameLoading) {
    return (
      <ErrorLoadingStatesWidget
        error={transactionError || payableToNameError}
        loading={transactionLoading || payableToNameLoading || !accessToken}
      />
    );
  }

  return <TodoSkeleton data-test-id="skeleton" />;
};
