import { object as yupObject, string as yupString, ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { UnityRole } from 'Auth/Utilities/Constant';

export const REQUIRED_OPERATING_STATE_ERROR = 'Please select your operating state';

const UnityIndividualProfileFieldsSchema = {
  firstName: yupString().trim().required('Please enter your first name'),
  middleName: yupString(),
  lastName: yupString().trim().required('Please enter your last name'),
  suffix: yupString(),
};

const UnityAgentTcProfileFieldsSchema = {
  ...UnityIndividualProfileFieldsSchema,
  stateOfOperation: yupString().required(REQUIRED_OPERATING_STATE_ERROR),
  licenseNumber: yupString().required('Please enter your license number'),
};

const UnityProfileValidationSchema: Partial<Record<UnityRole, ObjectSchema<ObjectShape>>> = {
  [UnityRole.BUYER]: yupObject().shape(UnityIndividualProfileFieldsSchema),
  [UnityRole.SELLER]: yupObject().shape(UnityIndividualProfileFieldsSchema),
  [UnityRole.BORROWER]: yupObject().shape(UnityIndividualProfileFieldsSchema),
  [UnityRole.AGENT]: yupObject().shape(UnityAgentTcProfileFieldsSchema),
  [UnityRole.TRANSACTION_COORDINATOR]: yupObject().shape(UnityIndividualProfileFieldsSchema),
};

export default UnityProfileValidationSchema;
