type Validation = {
  type: string;
  message: string;
  options?: {
    [key: string]: string | number | boolean;
  };
};

type ValueType = any;
type ValidationFunction = (value: ValueType, options?: Validation['options']) => boolean;

export const checkEmail: ValidationFunction = (value: string) => {
  const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  const isValidEmail = validateEmail.test(value);

  return isValidEmail;
};

export const checkPhone: ValidationFunction = (value: string) => {
  // Regex removes non-numeric characters from field value
  const isValid = value?.replace(/\D/g, '').length === 10;

  return isValid;
};
