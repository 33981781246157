import { useContext } from 'react';
import { AppContextType, AppContext } from 'utils/context';
import { getLegacyErrorCode } from 'Auth/Utilities/helper';
import { gql, useMutation } from '@apollo/client';
import { clearTokensV2 } from 'utils/auth/storage';
import { BFF_CLIENT_NAME } from 'Apollo';
import { RevokeSSOInput, RevokeSSOResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { getOrganizationId } from 'utils/getOrganizationData';

export const REVOKE_SSO_MUTATION = gql`
  mutation RevokeSSO($input: RevokeSSOInput!) {
    revokeSSO(input: $input) {
      success
      errorMessage
    }
  }
`;

export const useRevokeSSO = () => {
  const { setError }: AppContextType = useContext(AppContext);
  const [revokeSSOMutation] = useMutation<{ revokeSSO: RevokeSSOResponse }, { input: RevokeSSOInput }>(
    REVOKE_SSO_MUTATION,
    {
      context: {
        clientName: BFF_CLIENT_NAME,
      },
    },
  );

  return async () => {
    try {
      const organizationId = await getOrganizationId();

      setError(null);

      const { data } = await revokeSSOMutation({
        variables: {
          input: {
            organizationId,
          },
        },
      });

      if (!data?.revokeSSO?.success) {
        throw new Error(data?.revokeSSO?.errorMessage ?? 'An unknown error occurred');
      }
    } catch (error: any) {
      const errorCode = getLegacyErrorCode(error?.message ?? error);

      setError(errorCode);
    } finally {
      await clearTokensV2();
    }
  };
};
