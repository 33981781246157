import { ApolloError, useQuery } from '@apollo/client';
import { County, Market } from '@endpoint/endpoint-bff-graphql-schema';
import React from 'react';

import { BFF_CLIENT_NAME } from '../../Apollo';
import { getMarketsWithCounties } from './queries';

export interface CountiesByState {
  [state: string]: County[];
}

export interface UseCountiesResponse {
  countiesByState: CountiesByState;
  error: ApolloError | undefined;
  loading: boolean;
}

export interface GetMarketsWithCountiesResponse {
  getMarkets: Market[];
}

export function useCountiesByState(): UseCountiesResponse {
  const { error, data, loading } = useQuery<GetMarketsWithCountiesResponse>(getMarketsWithCounties, {
    variables: { input: { isActive: true } },
    context: {
      clientName: BFF_CLIENT_NAME,
    },
  });

  const countiesByState = React.useMemo((): CountiesByState => {
    if (!data) {
      return {};
    }

    return data.getMarkets.reduce((prev: CountiesByState, current) => {
      const enableMerge = current.state === 'CA';
      const stateAlreadySet = current.state in prev;

      return {
        ...prev,
        [current.state]:
          enableMerge && stateAlreadySet
            ? [...prev[current.state], ...(current.counties as County[])]
            : current.counties,
      } as CountiesByState;
    }, {});
  }, [data]);

  return {
    countiesByState,
    error,
    loading,
  };
}
