import React, { FC, useState, useEffect, useContext } from 'react';
import { Heading, Box, RadioGroup, Link, Stack, Divider, Text } from '@endpoint/blockparty';
import * as yup from 'yup';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { Field, useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { FormikRadio, FormikSelectGroup } from 'components/form/FormikBlockparty';
import { formatAddress, getFormattedAddressParts } from 'utils/formatAddress';
import { GET_TRANSACTIONS, TransactionsPayload } from 'routes/Transactions/queries';
import { TransactionStatus, Transaction } from '@endpoint/platform-api-connector/dist/graphql-types';
import { Options } from 'components/form/FormikBlockparty/FormikSelectGroup';
import { trackAction } from 'utils/analytics';
import { TransactionTrackingEvents } from 'consts/analytics';
import { CompanyContext, useAppConfigsContext } from 'utils/context';

import { FormikValue } from '../..';
import { OpenEscrowContextValue, OpenEscrowContext } from '../../Context';

export const openOptions = {
  newOrder: 'Open a new escrow order',
  existingOrder: 'Convert an existing pre-listing order',
};

export const Step1: FC<CurrentStepComponentProps<FormikValue>> = ({ setCurrentStepPosition, formikValue }) => {
  const { CONTACT_US_URL } = useAppConfigsContext();
  const { values, isValid, validateForm, setFieldValue } = useFormikContext<FormikValue>();
  const { setReswareFileNumber, setIsFromPrelim }: OpenEscrowContextValue = useContext(OpenEscrowContext);
  const isPrelistingSelected = values.openOptions === openOptions.existingOrder;

  const [prelistings, setPrelistings] = useState<any>([]);
  const [prelimTransactions, setPrelimTransactions] = useState<Transaction[]>([]);

  const { companyName } = useContext(CompanyContext);

  const hasNoPrelistings = !prelistings.length;
  const placeholderText = hasNoPrelistings
    ? 'No existing orders available. Please open a new order.'
    : 'Select from existing orders...';

  const { data } = useQuery<TransactionsPayload>(GET_TRANSACTIONS, {
    variables: { where: { transactionStatus: TransactionStatus.PRELISTING } },
  });

  useEffect(() => {
    const resolveValidateForm: () => void = async () => {
      await validateForm();
    };

    resolveValidateForm();
  }, [validateForm, values]);

  useEffect(() => {
    if (data) {
      const { myTransactions } = data;
      const nonPendingTransactions = myTransactions.filter((transaction) => !transaction.prelimPending);

      if (nonPendingTransactions.length) {
        const formattedOptions = nonPendingTransactions.map((transaction) => formatTransactionOptions(transaction));

        setPrelimTransactions(nonPendingTransactions);
        setPrelistings(formattedOptions);
      }
    }
  }, [data]);

  function onSelectChange() {
    const { e } = this;
    const selected = prelimTransactions.find((x) => x.id === e.value);

    setFieldValue('prelim', selected);

    if (selected) {
      setReswareFileNumber(selected.fileNum);
      setFieldValue('prelimDefaultValues', formatTransactionOptions(selected));
    }
  }

  const cleanReswareFileNumber = (selectionValue: string) => {
    switch (selectionValue) {
      case openOptions.newOrder: {
        setReswareFileNumber('');
        setIsFromPrelim(false);
        break;
      }

      case openOptions.existingOrder: {
        setIsFromPrelim(true);
        break;
      }

      default: {
        break;
      }
    }
  };

  function handleRadioChange() {
    const { e } = this;

    if (e) {
      const selectedValue = (e.target as HTMLTextAreaElement).value;

      trackEscrowOpenOptionSelection(selectedValue);
      cleanReswareFileNumber(selectedValue);
    }
  }

  const trackEscrowOpenOptionSelection = (selectionValue: string) => {
    switch (selectionValue) {
      case openOptions.newOrder: {
        trackAction(TransactionTrackingEvents.OPEN_NEW_ESCROW);
        break;
      }

      case openOptions.existingOrder: {
        trackAction(TransactionTrackingEvents.OPEN_ESCROW_BY_CONVERTING_PRELIM);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Please select how you would like to open escrow.
      </Heading>
      <Box fontSize="fontSize20" mb="space60">
        {companyName !== 'AHC' && (
          <>
            Note: {companyName} is currently operating in{' '}
            <Link
              color="blue500"
              fontWeight="normal"
              href={CONTACT_US_URL}
              isExternal
              size="fontSize20"
              textDecoration="underline"
            >
              select locations
            </Link>
            .
          </>
        )}
      </Box>
      <Box mb="space60">
        <Stack spacing="space60">
          <RadioGroup
            aria-label="radio-group-order-method"
            defaultValue={values.openOptions}
            name="openOptions"
            role="group"
            spacing="space50"
          >
            <Field
              component={FormikRadio}
              id="Convert an existing pre-listing order"
              name="openOptions"
              value={openOptions.existingOrder}
              onRadioChange={handleRadioChange}
            >
              {openOptions.existingOrder}
            </Field>
            <Field
              component={FormikRadio}
              id="Open a new escrow order"
              name="openOptions"
              value={openOptions.newOrder}
              onRadioChange={handleRadioChange}
            >
              {openOptions.newOrder}
            </Field>
          </RadioGroup>
          {isPrelistingSelected && (
            <>
              <Divider />
              <Text fontWeight="semi" size="fontSize30">
                Select from existing pre-listing orders
              </Text>
              <FormikSelectGroup
                data-test-id="prelim-transaction-dropdown"
                defaultValue={formikValue.prelimDefaultValues}
                disabled={hasNoPrelistings}
                isRequired
                name="prelimTransactionId"
                options={prelistings}
                placeholder={placeholderText}
                onSelectChange={onSelectChange}
              />
            </>
          )}
        </Stack>
      </Box>
      <NavigationButton
        goNext={() => setCurrentStepPosition(2)}
        iconRight="ArrowChevronRight"
        isDisabled={!values.openOptions || !isValid}
      >
        Next
      </NavigationButton>
    </>
  );
};

export const Summary = (formikValues: FormikValue) => {
  const { prelim, openOptions: openOption } = formikValues;

  if (!prelim || openOption === openOptions.newOrder) return null;

  const {
    // @ts-ignore
    property: { address },
    fileNum,
  } = prelim;
  const { streetAddress, cityStateZip } = getFormattedAddressParts(address);

  return (
    <Box>
      <Text as="p" data-test-id="street-address">
        {streetAddress}
      </Text>
      <Text as="p" data-test-id="city-state-zip">
        {cityStateZip}
      </Text>
      <Text data-test-id="file-number">File# {fileNum}</Text>
    </Box>
  );
};

export const validationSchema = yup.object().shape({
  openOptions: yup.string().required('Please choose one'),
  prelimTransactionId: yup.string().when('openOptions', {
    is: openOptions.existingOrder,
    then: yup.string().required('Please select an existing pre-listing order'),
  }),
});

export const formatTransactionOptions = (transaction: Transaction): Options => {
  return {
    label: (
      <>
        <Text data-test-id="prelim-address">{formatAddress(transaction?.property.address)}</Text>
        <Text color="carbon500" pl="space40">
          File# {transaction.fileNum}
        </Text>
      </>
    ),
    value: transaction.id,
  };
};
