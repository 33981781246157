import React, { FC, useState } from 'react';
import { Box, Button, Flex, Text, EPSubHeader } from '@endpoint/blockparty';
import { useNavigate } from 'react-router-dom';
import { CENTERED_CONTENT_WIDTH } from 'consts/responsive';

interface SubHeaderTodoProps {
  buttonLabel?: string;
  buttonLinks?: string;
  isButtonDisabled?: boolean;
  location?: string;
  buttonClick?: Function;
  shouldShowButton?: boolean;
}

export const SubHeaderTodo: FC<SubHeaderTodoProps> = (props: SubHeaderTodoProps) => {
  const navigate = useNavigate();
  const {
    buttonLabel = 'Save and Exit',
    buttonLinks,
    isButtonDisabled = false,
    location,
    buttonClick,
    shouldShowButton = true,
  } = props;

  const [isSaving, setIsSaving] = useState(false);

  const handleOnClick = async () => {
    setIsSaving(true);

    try {
      if (buttonClick) await buttonClick();
    } finally {
      setIsSaving(false);
    }

    if (buttonLinks) navigate(buttonLinks);
  };

  return (
    <EPSubHeader {...props}>
      <Flex
        alignItems="center"
        height={56}
        justifyContent="space-between"
        margin="0 auto"
        maxWidth={CENTERED_CONTENT_WIDTH}
        py="space30"
        width="100%"
        {...props}
      >
        <Box maxWidth={{ base: 191, md: '100%' }}>
          <Text>{location}</Text>
        </Box>
        <Flex alignItems="center">
          {shouldShowButton && (
            <Button
              data-test-id="sub-header-todo-button"
              isDisabled={isButtonDisabled}
              isLoading={isSaving}
              variant="outline"
              variantColor="slate"
              onClick={handleOnClick}
            >
              {buttonLabel}
            </Button>
          )}
        </Flex>
      </Flex>
    </EPSubHeader>
  );
};
