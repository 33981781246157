import { UploadResponse, UploadResponseBff } from 'routes/OpenEscrow';
import { UploadedFileProps } from 'components/Upload';

interface DocumentTypeOption {
  value: string;
  label: string;
}

export interface DocumentUploadFile {
  file: UploadResponse;
  documentType: string;
}

export interface DocumentUploadFileBff {
  file: UploadResponseBff;
  documentType: string;
}

export interface DocumentUploadFileUnclean {
  file: UploadedFileProps;
  documentType: string;
}

export interface DocumentUploadValuesUnclean {
  files: DocumentUploadFileUnclean[];
}

export interface DocumentUploadValues {
  files: DocumentUploadFile[];
}

export interface DocumentUploadValuesBff {
  files: DocumentUploadFileBff[];
}

export const initialValues = {
  files: [
    {
      file: {},
      documentType: '',
    },
  ],
};

export const documentTypeOptions: DocumentTypeOption[] = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Addendum', label: 'Addendum' },
  { value: 'Loan Document', label: 'Loan Document' },
  { value: 'Inspection', label: 'Inspection' },
  { value: 'Other', label: 'Other' },
];
