import React, { useContext } from 'react';
import { Text } from '@endpoint/blockparty';
import { CompanyContext } from 'utils/context';

export const DocumentInstructions = () => {
  const { companyName } = useContext(CompanyContext);

  return (
    <Text as="p" mt="space50">
      Preview or download documents uploaded by you or {companyName}. To upload a file, the size must be less than 50MB,
      PDF or JPG only.
    </Text>
  );
};
