import React, { FC } from 'react';
import { Box, Divider, DrawerSection, Heading, Text } from '@endpoint/blockparty';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';
import { TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { parseISO } from 'date-fns';
import { findParticipantNameByRole } from 'utils/findParticipantNameByRole/findParticipantNameByRole';

import { SigningAppointmentBlock, SigningAppointmentBlockOpposite } from '../SigningAppointment';

interface AgentViewProps {
  userRoles: TransactionRole[];
}

export const AgentView: FC<AgentViewProps> = ({ userRoles }) => {
  const { data: transactionData } = useTransactionDetailsContext();

  /**
   * This is the only instance where we'll be referring to the
   * sellerSigning and buyerSigning data for displaying info for both parties
   */

  const { participants, sellerSigning, buyerSigning } = transactionData!.transaction;

  const isDualAgent =
    userRoles.includes(TransactionRole.SELLING_AGENT) && userRoles.includes(TransactionRole.LISTING_AGENT);

  const isListingAgent =
    userRoles.includes(TransactionRole.LISTING_AGENT) ||
    userRoles.includes(TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR);

  const sellerName = findParticipantNameByRole(participants, TransactionRole.SELLER);
  const buyerName = findParticipantNameByRole(participants, TransactionRole.BUYER);

  const buyerSigningInfo = {
    signerName: buyerName,
    signingDate: buyerSigning?.date && parseISO(buyerSigning.date),
    signingLocation: buyerSigning?.location,
    notaryName: buyerSigning?.notaryName,
    notaryPhone: buyerSigning?.notaryPhone,
  };

  const sellerSigningInfo = {
    signerName: sellerName,
    signingDate: sellerSigning?.date && parseISO(sellerSigning.date),
    signingLocation: sellerSigning?.location,
    notaryName: sellerSigning?.notaryName,
    notaryPhone: sellerSigning?.notaryPhone,
    notaryProvider: sellerSigning?.notaryProvider,
    isRemote: sellerSigning?.isRemote,
    documentExpirationDate: sellerSigning?.documentExpirationDate,
  };

  return (
    <Box>
      <DrawerSection>
        <Box mb="space70">
          <Heading data-test-id="top-heading" mb="space50" size="fontSize50">
            {isDualAgent || isListingAgent ? 'Seller' : 'Buyer'}&apos;s signing appointment
          </Heading>
          <Text as="p" data-test-id="top-description">
            Your {isDualAgent ? 'seller' : 'client'}’s signing appointment has been scheduled
          </Text>
        </Box>
        <SigningAppointmentBlock signingInfo={isDualAgent || isListingAgent ? sellerSigningInfo : buyerSigningInfo} />
      </DrawerSection>
      <Divider />
      {isDualAgent ? (
        <DrawerSection data-test-id="dual-agent-section">
          <Box mb="space70">
            <Heading data-test-id="bottom-heading" mb="space50" size="fontSize50">
              Buyer&apos;s signing appointment
            </Heading>
            <Text as="p" data-test-id="bottom-description">
              Your buyer’s signing appointment has been scheduled
            </Text>
          </Box>
          <SigningAppointmentBlock signingInfo={buyerSigningInfo} />
        </DrawerSection>
      ) : (
        // This block displays other side's info
        <DrawerSection>
          <Box mb="space70">
            <Heading data-test-id="bottom-heading" mb="space50" size="fontSize50">
              {isListingAgent ? 'Buyer' : 'Seller'}&apos;s signing appointment
            </Heading>
            <Text as="p" data-test-id="bottom-description">
              The other party’s signing appointment has been scheduled
            </Text>
          </Box>
          <SigningAppointmentBlockOpposite signingInfo={isListingAgent ? buyerSigningInfo : sellerSigningInfo} />
        </DrawerSection>
      )}
    </Box>
  );
};
