import React from 'react';

import { ErrorPage } from './500';

interface OfflineErrorOverlayProps {
  styles?: object;
}

export const OfflineErrorOverlay = ({ styles }: OfflineErrorOverlayProps) => {
  return (
    <ErrorPage
      body="Please check your connection before refreshing your page."
      heading="Oops! No internet connection found."
      styles={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 21,
        backgroundColor: '#F5F5F7',
        ...styles,
      }}
      testId="offline-overlay"
    />
  );
};
