import { MARKET_ID } from 'consts/markets';
import { BANK_DETAILS, BANK_DETAILS_AHC_WA, BANK_DETAILS_TX, EXPANSION_BANK_DETAILS } from 'consts/moneyTransfer';
import { Bank } from 'hooks/useWireInstructions/types';
import { isMmunikyMarket } from 'utils/getMarket';

export const getBanksByMarketIdMap = (isUnityTransaction: boolean, isInternationalWire?: boolean) => {
  return {
    [MARKET_ID.WA]: BANK_DETAILS,
    [MARKET_ID.CA]: BANK_DETAILS,
    [MARKET_ID.AZ]: isUnityTransaction ? EXPANSION_BANK_DETAILS : BANK_DETAILS,
    [MARKET_ID.TX]: BANK_DETAILS_TX,
    [MARKET_ID.NorCA]: BANK_DETAILS,
    [MARKET_ID.AHC]: BANK_DETAILS_AHC_WA,
    [MARKET_ID.SoCA]: BANK_DETAILS,
    [MARKET_ID.FL]: EXPANSION_BANK_DETAILS,
    [MARKET_ID.CO]: EXPANSION_BANK_DETAILS,
    [MARKET_ID.TN]: EXPANSION_BANK_DETAILS,
    [MARKET_ID.MO]: EXPANSION_BANK_DETAILS,
    [MARKET_ID.OH]: BANK_DETAILS,
    [MARKET_ID.IN]: EXPANSION_BANK_DETAILS,
    [MARKET_ID.KS]: isInternationalWire && !isUnityTransaction ? BANK_DETAILS : EXPANSION_BANK_DETAILS,
    [MARKET_ID.KY]: isInternationalWire && !isUnityTransaction ? BANK_DETAILS : EXPANSION_BANK_DETAILS,
  };
};

export const getBankDetailsByMarketId = (
  organizationId: number,
  marketId?: number | null,
  isUnityTransaction?: boolean,
  isInternationalWire?: boolean,
): Bank => {
  const banksByMarketIdMap = getBanksByMarketIdMap(Boolean(isUnityTransaction), Boolean(isInternationalWire));

  if (marketId) {
    if (marketId in banksByMarketIdMap) {
      return banksByMarketIdMap[marketId];
    }

    if (isMmunikyMarket(marketId)) {
      return isInternationalWire ? BANK_DETAILS : EXPANSION_BANK_DETAILS;
    }

    return {};
  }

  switch (organizationId) {
    case 1:
      return BANK_DETAILS;
    case 2:
      return BANK_DETAILS_AHC_WA;
    default:
      return {};
  }
};
