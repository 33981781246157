import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { DatePicker } from '@endpoint/blockparty';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { formatDate, MONTH_DAY_YEAR_WITH_SLASHES } from 'utils/formatDate';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

interface TodoDatePickerProps {
  field: FieldOptions;
}

export const TodoDatePicker: FC<TodoDatePickerProps> = ({ field: { id, placeHolder, answer } }) => {
  const formikContext = useFormikContext<GenericObject>();
  const [dateSelection, setDateSelection] = useState<Date | null>(answer ? new Date(answer) : null);

  useTodoFieldInitializer({ id, answer });

  const handleDateChange = (date: Date) => {
    setDateSelection(date);
    formikContext.setFieldValue(id, formatDate(date, MONTH_DAY_YEAR_WITH_SLASHES));
  };

  return (
    <DatePicker
      autoComplete="off"
      dateFormat={MONTH_DAY_YEAR_WITH_SLASHES}
      id={id}
      placeholderText={placeHolder ?? 'Select date'}
      selected={dateSelection}
      onChange={handleDateChange}
      // prevents typing and pasting
      onChangeRaw={(e) => e.preventDefault()}
    />
  );
};
