import React, { FC } from 'react';
import { Box, Divider, Text } from '@endpoint/blockparty';

export const TeamContactsHeader: FC<{ title: string }> = ({ title }) => (
  <Box>
    <Text as="h5" data-test-id="team-contact-header" mb="10px" size="fontSize40">
      {title}
    </Text>
    <Divider />
  </Box>
);
