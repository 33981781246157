import React, { FC } from 'react';
import { FieldOptions, FieldValidations } from '@endpoint/endpoint-bff-graphql-schema';
import { InputGroup, InputLabel, InputErrorMessage, Select } from '@endpoint/blockparty';
import { Options } from 'components/form/FormikBlockparty/FormikSelectGroup';
import { useField } from 'formik';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

interface TodoDropdownProps {
  field: FieldOptions;
}

export const TodoDropdown: FC<TodoDropdownProps> = ({ field }) => {
  const { id, label, options, validations, placeHolder } = field;
  const [formikField, meta, formikFieldHelpers] = useField(id);
  const isRequired = validations.some((validation) => validation.type === FieldValidations.Required);
  const isInvalid = Boolean(meta.touched && meta.error);

  useTodoFieldInitializer(field);

  const handleDropDownSelectionChange = (e: Options) => {
    formikFieldHelpers.setValue(e.value);
  };

  const mappedOptions: Options[] = options?.map((option) => ({ label: option, value: option })) ?? [];

  if (!mappedOptions.length) {
    return <></>;
  }

  return (
    <InputGroup data-test-id={id} groupId={id} isInvalid={isInvalid} isRequired={isRequired}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        {...formikField}
        blurInputOnSelect={false}
        inputId={id}
        name={id}
        options={mappedOptions}
        placeholder={placeHolder ?? 'Select...'}
        value={formikField.value ? { label: formikField.value, value: formikField.value } : undefined}
        onChange={(e: Options) => {
          handleDropDownSelectionChange(e);
        }}
      />

      {isInvalid && <InputErrorMessage>{meta.error}</InputErrorMessage>}
    </InputGroup>
  );
};
