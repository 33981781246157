import { MARKET_ID } from 'consts/markets';
import {
  PAYABLE_TO_CA_SOUTH_LEGACY,
  PAYABLE_TO_TX,
  PAYABLE_TO_AHC_WA,
  PAYABLE_TO_CA,
  PAYABLE_TO_DEFAULT,
  PAYABLE_TO_EMD_CHECK,
  PAYABLE_TO_ENDPOINT_INC,
} from 'consts/moneyTransfer';
import { isMmunikyMarket } from 'utils/getMarket';

export const getMarketPayableForCheckInstructionsMap = (isUnityTransaction: boolean): { [key: number]: string } => {
  const defaultPayable = isUnityTransaction ? PAYABLE_TO_DEFAULT : PAYABLE_TO_EMD_CHECK;

  return {
    [MARKET_ID.WA]: defaultPayable,
    [MARKET_ID.CA]: isUnityTransaction ? PAYABLE_TO_CA : PAYABLE_TO_EMD_CHECK,
    [MARKET_ID.AZ]: defaultPayable,
    [MARKET_ID.TX]: isUnityTransaction ? PAYABLE_TO_TX : PAYABLE_TO_EMD_CHECK,
    [MARKET_ID.NorCA]: isUnityTransaction ? PAYABLE_TO_CA : PAYABLE_TO_EMD_CHECK,
    [MARKET_ID.SoCA]: isUnityTransaction ? PAYABLE_TO_CA : PAYABLE_TO_EMD_CHECK,
    [MARKET_ID.FL]: defaultPayable,
    [MARKET_ID.CO]: defaultPayable,
    [MARKET_ID.TN]: defaultPayable,
    [MARKET_ID.MO]: defaultPayable,
    [MARKET_ID.OH]: defaultPayable,
    [MARKET_ID.IN]: defaultPayable,
    [MARKET_ID.KS]: defaultPayable,
    [MARKET_ID.KY]: defaultPayable,
  };
};

export const getMarketPayableMap = (isUnityTransaction: boolean): { [key: number]: string } => ({
  [MARKET_ID.WA]: isUnityTransaction ? PAYABLE_TO_DEFAULT : PAYABLE_TO_ENDPOINT_INC,
  [MARKET_ID.CA]: PAYABLE_TO_CA,
  [MARKET_ID.AZ]: isUnityTransaction ? PAYABLE_TO_DEFAULT : PAYABLE_TO_ENDPOINT_INC,
  [MARKET_ID.TX]: PAYABLE_TO_TX,
  [MARKET_ID.NorCA]: PAYABLE_TO_CA,
  [MARKET_ID.AHC]: PAYABLE_TO_AHC_WA,
  [MARKET_ID.SoCA]: isUnityTransaction ? PAYABLE_TO_CA : PAYABLE_TO_CA_SOUTH_LEGACY,
  [MARKET_ID.FL]: PAYABLE_TO_DEFAULT,
  [MARKET_ID.CO]: PAYABLE_TO_DEFAULT,
  [MARKET_ID.TN]: PAYABLE_TO_DEFAULT,
  [MARKET_ID.MO]: PAYABLE_TO_DEFAULT,
  [MARKET_ID.OH]: PAYABLE_TO_DEFAULT,
  [MARKET_ID.IN]: PAYABLE_TO_DEFAULT,
  [MARKET_ID.KS]: PAYABLE_TO_DEFAULT,
  [MARKET_ID.KY]: PAYABLE_TO_DEFAULT,
});

export const getPayableToNameByMarketId = (marketId?: number, isUnityTransaction?: boolean): string => {
  const marketPayableMap = getMarketPayableMap(Boolean(isUnityTransaction));

  if (marketId && isMmunikyMarket(marketId)) {
    return PAYABLE_TO_DEFAULT;
  }

  if (marketId && marketId in marketPayableMap) {
    return marketPayableMap[marketId];
  }

  return PAYABLE_TO_ENDPOINT_INC;
};

export const getPayableToNameByMarketIdCheckInstructions = (marketId?: number, isUnityTransaction?: boolean) => {
  const marketPayableForCheckInstructionsMap = getMarketPayableForCheckInstructionsMap(Boolean(isUnityTransaction));

  if (marketId && marketId in marketPayableForCheckInstructionsMap) {
    return marketPayableForCheckInstructionsMap[marketId];
  }

  return PAYABLE_TO_EMD_CHECK;
};
