import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  setHeightStyle: (arg0: string) => void;
}

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
// Browsers are starting to handle scroll restoration on their own
// This sets each route to be viewed at the top of the page
export const ScrollToTop: FC<ScrollToTopProps> = ({ setHeightStyle }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setHeightStyle('auto');
    window.scrollTo(0, 0);

    setTimeout(() => {
      setHeightStyle('100%');
    }, 0);
  }, [pathname, setHeightStyle]);

  return null;
};
