enum HelloSignStaticStatuses {
  CANNOT_BE_SIGNED_YET = "The document cannot be signed yet as we are waiting for the other party to complete signing first. To save your progress and return at a later time, please click the 'Save and Exit' button above.",
  DECLINED = 'The document has been declined. For assistance, please feel free to contact us through the in-app chat.',
  ERROR = "Oops! There seems to be an error with the HelloSign app. To save your progress, click 'Save and Exit' above, then refresh the page. If the problem persists, please feel free to contact us through the in-app chat.",
  FAILED = 'There is an error loading this document. Please refresh this page.',
  NOT_LOADED = 'Not Loaded',
}

export const getHelloSignStatuses = (callToActionText: string) =>
  ({
    ALREADY_SIGNED: `The document has already been signed. Please complete this to-do by clicking on the '${callToActionText}' button below.`,
    SIGNED: `Nice! The document has been signed. Please complete this to-do by clicking on the '${callToActionText}' button below.`,
    CANNOT_BE_SIGNED_YET: HelloSignStaticStatuses.CANNOT_BE_SIGNED_YET,
    DECLINED: HelloSignStaticStatuses.DECLINED,
    ERROR: HelloSignStaticStatuses.ERROR,
    FAILED: HelloSignStaticStatuses.FAILED,
    NOT_LOADED: HelloSignStaticStatuses.NOT_LOADED,
  } as const);

export type HelloSignStatuses = ReturnType<typeof getHelloSignStatuses>;
export type HelloSignStatus = HelloSignStatuses[keyof HelloSignStatuses];

export enum SignatureUrlRequestErrorMessage {
  ALREADY_SIGNED = 'HelloSign error: This request has already been signed',
}

export enum HelloSignEvents {
  CANCEL = 'cancel',
  CLOSE = 'close',
  DECLINE = 'decline',
  ERROR = 'error',
  OPEN = 'open',
  SIGN = 'sign',
}

export enum HelloSignValue {
  SIGN = 'Signed document',
  DECLINE = 'Declined document',
}

export enum HelloSignFieldValue {
  SIGNED = 'signed',
  DECLINED = 'declined',
}

export const HELLO_SIGN_CONTAINER_ID = 'hellosign-container';
