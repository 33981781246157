import { useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import {
  OpenEscrowInput,
  OpenEscrowFromPrelimInput,
  TransactionCreationResult,
} from '@endpoint/endpoint-bff-graphql-schema';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { OPEN_ESCROW, OPEN_ESCROW_FROM_PRELIM } from './mutations';

export const useOpenEscrow = () => {
  const sentryTransactionOpenEscrow = useSentryTransaction('openEscrow', 'GraphQL client mutation');
  const sentryTransactionOpenEscrowFromPrelim = useSentryTransaction('openEscrowFromPrelim', 'GraphQL client mutation');

  const [openEscrowMutation, { data: openEscrowResult, loading: openEscrowLoading, error: openEscrowError }] =
    useMutation<{ openEscrow: TransactionCreationResult }, { input: OpenEscrowInput }>(OPEN_ESCROW, {
      errorPolicy: 'all',
    });

  const openEscrow = async (input: OpenEscrowInput) => {
    await openEscrowMutation({
      variables: { input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionOpenEscrow.toTraceparent(),
        },
      },
    });

    sentryTransactionOpenEscrow.finish();
  };

  const [
    openEscrowFromPrelimMutation,
    { data: openEscrowFromPrelimResult, loading: openEscrowFromPrelimLoading, error: openEscrowFromPrelimError },
  ] = useMutation<{ openEscrowFromPrelim: TransactionCreationResult }, { input: OpenEscrowFromPrelimInput }>(
    OPEN_ESCROW_FROM_PRELIM,
    {
      errorPolicy: 'all',
    },
  );

  const openEscrowFromPrelim = async (input: OpenEscrowFromPrelimInput) => {
    await openEscrowFromPrelimMutation({
      variables: { input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionOpenEscrowFromPrelim.toTraceparent(),
        },
      },
    });

    sentryTransactionOpenEscrowFromPrelim.finish();
  };

  return {
    openEscrow,
    openEscrowResult,
    openEscrowLoading,
    openEscrowError,
    openEscrowFromPrelim,
    openEscrowFromPrelimResult,
    openEscrowFromPrelimLoading,
    openEscrowFromPrelimError,
  };
};
