import React, { FC } from 'react';
import { Box, Divider, Flex, Text, Button, Stack } from '@endpoint/blockparty';
import { FieldArray, useFormikContext } from 'formik';
import { trackAction } from 'utils/analytics';
import { StackProps } from '@endpoint/blockparty/dist/components/Stack';
import { getAddedToEscrowOrderAction } from 'consts/analytics';

interface MultipleProps {
  name: string;
  title: string;
  children: (prefix: string) => JSX.Element;
  fieldSpacing?: StackProps['spacing'];
  hideDivider?: boolean;
  hideTitle?: boolean;
}

export const Multiple: FC<MultipleProps> = ({
  hideDivider,
  hideTitle,
  name,
  title,
  children,
  fieldSpacing = 'space60',
}) => {
  const { values } = useFormikContext<any>();

  return (
    <FieldArray name={name}>
      {({ push, remove }) => {
        const handleAddClicked = () => {
          push({});
          trackAction(getAddedToEscrowOrderAction(title));
        };

        return (
          <Stack spacing="space70">
            {values[name].map((field: any, index: number) => (
              // eslint-disable-next-line
              <Box key={`${name}-${field.__ui_uuid}`}>
                <Stack spacing={fieldSpacing}>
                  {index > 0 && (
                    <>
                      {!hideDivider && <Divider />}
                      <Flex
                        flexDirection="row"
                        justifyContent="space-between"
                        pb={hideTitle && hideDivider ? 'space30' : 'space0'}
                      >
                        {!hideTitle ? (
                          <Text as="h1" fontWeight="semi" size="fontSize30">
                            {title} {index + 1}
                          </Text>
                        ) : (
                          <Box />
                        )}
                        <Button
                          dataTestId="remove-group"
                          iconLeft="Close"
                          size="small"
                          variant="outline"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Flex>
                    </>
                  )}
                  {children(`${name}[${index}]`)}
                </Stack>
              </Box>
            ))}
            <Box>
              <Button dataTestId="add-new-group" iconLeft="Add" variant="link" onClick={handleAddClicked}>
                Add {title}
              </Button>
            </Box>
          </Stack>
        );
      }}
    </FieldArray>
  );
};
