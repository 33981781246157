import React, { FC } from 'react';
import { Property } from '@endpoint/platform-api-connector/dist/graphql-types';
import { Text, Center, Avatar, Box, Flex } from '@endpoint/blockparty';
import { formatDisplayAddress } from 'utils/transactions/displayAddressHelpers';
import { PLACEHOLDER_MLS } from 'consts/assets';

interface PropertyHeaderProps {
  fileNum: string;
  property: Property;
}

export const PropertyHeader: FC<PropertyHeaderProps> = ({ property, fileNum }) => {
  const fileNumberText = fileNum ? `File #${fileNum}` : '';

  return (
    <Flex flexDirection="row" gridColumn="1 / 3" mb={{ base: 'space50', md: 'space0' }}>
      <Center bg="white" flex={1} mx="auto" size="76px">
        <Box height="40px" m="space50">
          <Avatar name="property-image" src={property?.propertyImage || PLACEHOLDER_MLS} width="40px" />
        </Box>
        <Text color="carbon900" data-test-id="property-header" flex={1} my="space50" pr="space50">
          {formatDisplayAddress(property?.address)} <br />
          {fileNumberText}
        </Text>
      </Center>
    </Flex>
  );
};
