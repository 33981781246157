import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalContent,
  ModalSection,
  ModalFooter,
  Text,
  useDisclosure,
} from '@endpoint/blockparty';

interface UseCancelModalProps {
  onConfirm: () => void;
  cancelButtonText: string;
  confirmButtonText: string;
}

const useCancelModal = ({ onConfirm, cancelButtonText, confirmButtonText }: UseCancelModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const wrapperView = useMemo(() => {
    return (
      <Modal id="open-escrow-modal" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalSection>
            <Box>
              <Heading as="h6" mb="space50" size="fontSize30">
                Are you sure you want to cancel?
              </Heading>
              <Text>Your order will not be saved.</Text>
            </Box>
          </ModalSection>
          <ModalFooter>
            <Button mr="space50" variant="outline" onClick={onClose}>
              {cancelButtonText}
            </Button>
            <Button onClick={onConfirm}>{confirmButtonText}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }, [onClose, isOpen, onConfirm, cancelButtonText, confirmButtonText]);

  return { onOpen, Modal: wrapperView };
};

export { useCancelModal };
