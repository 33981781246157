import * as Sentry from '@sentry/react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { TrackingPages, TrackingEvents } from 'consts/analytics';

interface SegmentAnalyticsTracker {
  trackAction(...args: [name: TrackingEvents, properties?: GenericObject]): void;
  trackPage(...args: [pageName: TrackingPages]): void;
  trackIdentity(...args: [userId: string, organizationId: number, properties?: GenericObject]): void;
  trackIdentityPreCreation(...args: [properties?: GenericObject]): void;
}

interface ConsoleAnalyticsTracker {
  trackAction(...args: Parameters<typeof console.log>): void;
  trackPage(...args: Parameters<typeof console.log>): void;
  trackIdentity(...args: Parameters<typeof console.log>): void;
  trackIdentityPreCreation(...args: Parameters<typeof console.log>): void;
}

type AnalyticsTracker = SegmentAnalyticsTracker | ConsoleAnalyticsTracker;
type LogToSegmentFunction = AnalyticsTracker[
  | 'trackAction'
  | 'trackPage'
  | 'trackIdentity'
  | 'trackIdentityPreCreation'];

const logToSegment = (fn: LogToSegmentFunction, ...args: Parameters<LogToSegmentFunction>) => {
  try {
    fn(...args);
  } catch (e: any) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: e.message,
    });
  }
};

const segmentTracker = (segmentWriteKey: AppConfig['SEGMENT_WRITE_KEY']): SegmentAnalyticsTracker => {
  const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey || '' });

  return {
    trackAction(name, properties) {
      logToSegment(analytics.track, name, properties);
    },
    trackPage(pageName) {
      logToSegment(analytics.page, pageName);
    },
    trackIdentity(userId, organizationId, properties) {
      logToSegment(analytics.identify, userId, { organizationId, ...properties });
    },
    trackIdentityPreCreation(properties) {
      logToSegment(analytics.identify, properties);
    },
  };
};

const consoleTracker = (): ConsoleAnalyticsTracker => ({
  trackAction(...args: Parameters<typeof console.log>) {
    console.log(...args);
  },
  trackPage(...args: Parameters<typeof console.log>) {
    console.log(...args);
  },
  trackIdentity(...args: Parameters<typeof console.log>) {
    console.log(...args);
  },
  trackIdentityPreCreation(...args: Parameters<typeof console.log>) {
    console.log(...args);
  },
});

export const createAnalyticsTracker = (segmentWriteKey: AppConfig['SEGMENT_WRITE_KEY']): AnalyticsTracker => {
  // Segment Key will only be available in production (and maybe staging)
  if (segmentWriteKey && process.env.NODE_ENV !== 'development') {
    return segmentTracker(segmentWriteKey);
  }

  return consoleTracker();
};
