import React, { FC, useEffect, useState } from 'react';
import { FieldOptions, FieldValidations } from '@endpoint/endpoint-bff-graphql-schema';
import { Checkbox } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';

interface TodoCheckboxProps {
  field: FieldOptions;
}

export const TodoCheckbox: FC<TodoCheckboxProps> = ({ field }) => {
  const formikContext = useFormikContext<GenericObject>();
  const [isChecked, setIsChecked] = useState(!!field.answer ?? false);

  useEffect(() => {
    if (field.answer) {
      formikContext.setFieldValue(field.id, field.answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isRequired = field.validations.some((validation) => validation.type === FieldValidations.Required);

    if (isRequired) {
      formikContext.setFieldValue(field.id, event.target.checked || undefined);
      setIsChecked(event.target.checked);

      return;
    }

    // TODO: confirm what we should be saving for checkbox field answers
    formikContext.setFieldValue(field.id, event.target.checked);
    setIsChecked(event.target.checked);
  };

  return (
    <Checkbox isChecked={isChecked} mt="space70" onChange={handleCheckboxChange}>
      {field.label}
    </Checkbox>
  );
};
