import { DwollaAddressInput, DwollaCustomerInput } from '@endpoint/endpoint-bff-graphql-schema';

export class DwollaCustomerBuilder {
  private dwollaCustomer: DwollaCustomerInput;

  constructor(transactionId: string, todoAssignmentId: string) {
    this.dwollaCustomer = {
      transactionId,
      todoAssignmentId,
    };
  }

  withSSN(SSN: string) {
    this.dwollaCustomer.ssn = SSN;

    return this;
  }

  withDateOfBirth(dateOfBirth: string) {
    this.dwollaCustomer.dateOfBirth = dateOfBirth;

    return this;
  }

  withAddress(address: DwollaAddressInput) {
    this.dwollaCustomer.address = address;

    return this;
  }

  build() {
    return this.dwollaCustomer;
  }
}
