import React, { FC } from 'react';
import { Box, Heading, Icon, List, ListItem, Text } from '@endpoint/blockparty';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { ContentType, TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import { getUniqueStepComponentKey } from 'routes/TransactionDetail/Todo/TodoStep/helpers/getUniqueStepComponentKey';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';
import { ListItemProps, ListProps } from '@endpoint/blockparty/dist/components/List';

import { doDirectChildItemsHaveIcons } from './helpers/doDirectChildItemsHaveIcons';
import { TodoHyperlink } from '../TodoHyperlink';
import { TodoParagraph } from '../TodoParagraph';

interface TodoUnorderedListProps {
  list: Pick<TodoAssignmentContent, 'children'>;
  listProps?: ListProps;
  listItemProps?: ListItemProps;
}

const TWO_COLUMN_GRID_STYLES: BoxProps = {
  display: 'grid',
  gridColumnGap: 'space30',
  gridTemplateColumns: '0.25fr 5fr',
};

function renderListItem(element: TodoAssignmentContent): JSX.Element | JSX.Element[] {
  switch (element.nodeType) {
    case ContentType.HEADING_2:
      return (
        <Heading as="h2" gridColumn={2} mb="space30" size="fontSize20">
          {element.value}
        </Heading>
      );
    case ContentType.HEADING_3:
      return (
        <Heading as="h3" gridColumn={2} mb="space30" size="fontSize30">
          {element.value}
        </Heading>
      );
    case ContentType.PARAGRAPH:
      return <TodoParagraph gridColumn={2} mb="space0" paragraph={element} />;
    case ContentType.HYPERLINK:
      return <TodoHyperlink content={element} gridColumn={2} />;
    case ContentType.UNORDERED_LIST:
      return (
        <TodoUnorderedList
          list={{ children: element.children }}
          listItemProps={{ mb: element.children.length > 1 ? 'space20' : 'space0' }}
          listProps={{ pl: 'space60', ml: 'space70', mb: 'space0' }}
        />
      );
    default:
      return <></>;
  }
}

interface CompositeListItemProps {
  item: TodoAssignmentContent;
  listItemProps?: ListItemProps;
}

const shouldRenderInGridLayout = (listItem: TodoAssignmentContent, listItemChild: TodoAssignmentContent) => {
  if (listItemChild.icon) {
    return true;
  }

  if (listItem.children.length <= 1) {
    return false;
  }

  return [ContentType.PARAGRAPH, ContentType.HYPERLINK].includes(listItemChild.nodeType);
};

const CompositeListItem: FC<CompositeListItemProps> = ({ item, listItemProps = {} }) => {
  return (
    <ListItem>
      {item.children.map((element, index) => {
        const listItemStyles = shouldRenderInGridLayout(item, element) ? TWO_COLUMN_GRID_STYLES : {};

        return (
          <Box key={getUniqueStepComponentKey(element, index)} {...listItemProps} {...listItemStyles}>
            {element.icon && <Icon color="brandPrimary500" name={element.icon as IconNames} size="large" />}
            {renderListItem(element)}
          </Box>
        );
      })}
    </ListItem>
  );
};

export const TodoUnorderedList: FC<TodoUnorderedListProps> = ({
  list: { children },
  listProps = {},
  listItemProps = {},
}) => {
  const listStyles: ListProps = doDirectChildItemsHaveIcons(children)
    ? { listStyleType: 'none' }
    : { listStylePosition: 'outside', marginLeft: 'space50' };

  return (
    <List mb="space70" {...listStyles} {...listProps}>
      {children.map((item, index) => {
        const text = item.value;

        if (item.children.length) {
          return (
            <CompositeListItem key={getUniqueStepComponentKey(item, index)} item={item} listItemProps={listItemProps} />
          );
        }

        const listItemStyles: ListItemProps = item.icon ? TWO_COLUMN_GRID_STYLES : {};

        return (
          <ListItem key={text} alignItems="center" {...listItemStyles} {...listItemProps}>
            {item.icon && <Icon color="brandPrimary500" name={item.icon as IconNames} size="large" />}
            <Text as="span" gridColumn={2}>
              {text}
            </Text>
          </ListItem>
        );
      })}
    </List>
  );
};
