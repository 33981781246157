import parsePhoneNumber from 'libphonenumber-js';

export const formatPhone = (phoneNumber: string, options?: { withCountryCode?: boolean }) => {
  if (!phoneNumber) return '';
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? `${options?.withCountryCode ? '+1 ' : ''}(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
};

export const formatPhoneNational = (phone: string = '') => {
  const phoneNumber = parsePhoneNumber(phone, 'US');

  return phoneNumber?.formatNational({ v2: true }) ?? '';
};

export const getNationalNumber = (phone: string = '') => {
  const phoneNumber = parsePhoneNumber(phone, 'US');

  return phoneNumber?.nationalNumber?.toString() ?? '';
};

export const formatPhoneNumberWithOptionalIntlCode = (phoneNumber: string) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return '';
};
