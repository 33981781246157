import { getFormattedAddressParts } from 'utils/formatAddress';
import { TransactionDetailsPayload } from 'routes/TransactionDetail/Todos/queries';
import { formatCurrency } from 'utils/formatCurrency';

export const formatCheckData = (transactionData: TransactionDetailsPayload) => {
  const { emd, property } = transactionData!.transaction;

  const { address } = property;
  const { streetAddress, cityStateZip } = getFormattedAddressParts(address);
  // @ts-ignore
  const paymentAmount = formatCurrency(emd?.amount);

  return {
    paymentAmount,
    transactionStreetAddress: streetAddress,
    transactionCityStateZip: cityStateZip,
  };
};
