import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { getLegacyErrorCode, isIncorrectAttemptV2 } from 'Auth/Utilities/helper';
import { useSignInMutation } from 'hooks/auth/useSignInMutation';
import { ACCOUNT_LOCKED_URL, ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_IN, REQUEST_MFA_URL } from 'Auth/Utilities/Constant';
import {
  ButtonClickedTrackingPayload,
  GenericTrackingEvents,
  SignInTrackingEvents,
  TrackingEventType,
  TRACK_GENERIC_ERROR_VIEWED_PAYLOAD,
} from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import { AppContextType, AppContext } from 'utils/context';
import { useHandleAuthResponse } from 'hooks/auth/useHandleAuthResponse';
import { useAuth } from 'hooks/auth/useAuth';
import useOrganizationId from 'hooks/useOrganizationId';

const trackLoginSubmitProperties: ButtonClickedTrackingPayload = {
  name: TrackingEventType.BUTTON_CLICKED,
  buttonId: 'Sign In',
};

export const useSignIn = () => {
  const { setData } = useAuth();
  const { authFields, setAuthFields, setUser }: AppContextType = useContext(AppContext);
  const navigate = useNavigate();
  const handleAuthResponse = useHandleAuthResponse();
  const organizationId = useOrganizationId();
  const { signInMutation } = useSignInMutation();

  const signIn = async (email: string, password: string) => {
    setUser((user: any) => ({ ...user, loading: true }));

    trackAction(SignInTrackingEvents.EMAIL_AND_PASSWORD_SUBMITTED, trackLoginSubmitProperties);

    setAuthFields({
      ...authFields,
      errorCode: null,
    });

    try {
      setData('username', email);
      setData('password', password);

      const response = await signInMutation({
        username: email,
        password,
        organizationId,
      });

      if (response?.data?.signIn) {
        handleAuthResponse(response.data.signIn, REQUEST_MFA_URL, authFields.navigateToAfterLogin);
      }
    } catch (error: any) {
      if (isIncorrectAttemptV2(error.message)) {
        trackAction(SignInTrackingEvents.MAX_LOGIN_ATTEMPTS_REACHED);
        navigate(ACCOUNT_LOCKED_URL, {
          state: {
            accountLockedInstructions: ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_IN,
          },
        });
      } else {
        const errorCode = getLegacyErrorCode(error?.message ?? error);

        trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
        setAuthFields({ ...authFields, errorCode });
      }
    } finally {
      setUser((user: any) => ({ ...user, loading: false }));
    }
  };

  return { signIn };
};
