import { gql } from '@apollo/client';

export const CREATE_TODO_DOCUMENT = gql`
  mutation CreateTodoDocument($input: CreateTodoDocumentInput!) {
    createTodoDocument(input: $input) {
      id
      transactionId
      name
      type
      uploadUrl
    }
  }
`;
