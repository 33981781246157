import React, { useState } from 'react';
import { RadioGroup, Radio, Text } from '@endpoint/blockparty';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

interface TodoRadioGroupProps {
  field: FieldOptions;
}

export const TodoRadioGroup = ({ field }: TodoRadioGroupProps) => {
  const [selectedValue, setSelectedValue] = useState(field.answer ?? '');
  const formikContext = useFormikContext<GenericObject>();

  useTodoFieldInitializer(field);

  const handleRadioSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikContext.setFieldValue(field.id, event.target.value);
    setSelectedValue(event.target.value);
  };

  return (
    <Field name={field.id}>
      {({ field: formikField }: FieldProps) => {
        if (!field.enhancedOptions?.length) {
          return <></>;
        }

        return (
          <RadioGroup
            {...formikField}
            aria-label={field.label}
            defaultValue={selectedValue}
            id={field.id}
            mt="space70"
            name={field.id}
          >
            {field.enhancedOptions?.map(({ value, description, isDisabled }) => (
              <Radio
                key={value}
                color={isDisabled ? 'carbon200' : 'carbon900'}
                id={value}
                isDisabled={isDisabled}
                label={value}
                value={value}
                onChange={handleRadioSelectionChange}
                {...(description ? { mb: 'space40' } : {})}
              >
                {isDisabled ? `${value} - Option Not Available` : value}
                {description && (
                  <Text as="p" color={isDisabled ? 'carbon200' : 'carbon500'}>
                    {description}
                  </Text>
                )}
              </Radio>
            ))}
          </RadioGroup>
        );
      }}
    </Field>
  );
};
