import { gql } from '@apollo/client';

export const GET_TODO_ASSIGNMENT_DOCUMENTS = gql`
  query getTodoAssignmentDocuments($transactionId: String!, $todoAssignmentId: String!) {
    getTodoAssignmentDocuments(transactionId: $transactionId, todoAssignmentId: $todoAssignmentId) {
      id
      mimeType
      url
    }
  }
`;
