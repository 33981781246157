import { EndpointTeamContact } from 'routes/TransactionDetail/Contacts/data/EndpointContactData';

interface PaymentAddress {
  paymentStreet: string;
  paymentSuite: string;
  paymentCityStateZip: string;
}

export const formatPaymentAddress = (
  contactInfo?: Pick<EndpointTeamContact, 'street' | 'cityStateZip'>,
): PaymentAddress => {
  let paymentStreet: string = '';
  let paymentSuite: string = '';
  let paymentCityStateZip: string = '';

  if (!contactInfo) {
    return { paymentStreet, paymentSuite, paymentCityStateZip };
  }

  const streetParts = contactInfo.street.split(',');

  paymentStreet = streetParts[0];
  paymentSuite = (streetParts[1] || '').trim();
  paymentCityStateZip = contactInfo.cityStateZip;

  return { paymentStreet, paymentSuite, paymentCityStateZip };
};
