import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { useFormikContext } from 'formik';
import React, { FC, useEffect } from 'react';

import { useTodoStepContext } from '../..';

type AcknowledgementProps = {
  field: FieldOptions;
};

export const Acknowledgement: FC<AcknowledgementProps> = ({ field }: AcknowledgementProps) => {
  const formikContext = useFormikContext<GenericObject>();
  const { documentPreviewLoaded } = useTodoStepContext();

  useEffect(() => {
    formikContext.setFieldValue(field.id, documentPreviewLoaded ? { acknowledge: true } : undefined);
    /**
     Setting field id acknowledge with answer of true for backward compatibility with previous flow
     Can be removed with ENABLE_TODO_SERVICE feature flag cleanup if no other clients are consuming this value
     */
    formikContext.setFieldValue('acknowledge', documentPreviewLoaded ? true : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.id, documentPreviewLoaded]);

  return <></>;
};
