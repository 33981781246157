import React, { FC, Dispatch, SetStateAction } from 'react';
import { Box, EPContainer, EPContent, EPSidebar } from '@endpoint/blockparty';
import { AlertMessage } from 'components/AlertMessage';
import { TransactionDetailSkeleton } from 'components/Skeleton';
import { TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { useNavigate } from 'react-router';
import { ScrollToTop } from 'utils/ScrollToTop';
import { getUserRoles } from 'utils/transactions/getUserRoles';
import { useAppContext } from 'utils/context';

import { PropertyDetails } from './PropertyDetails';
import { ProgressTracker } from './ProgressTracker';
import { TodoList } from './TodoList';
import { useTransactionDetailsContext } from '..';

export const Todos: FC<{ setHeightStyle: Dispatch<SetStateAction<string>> }> = ({ setHeightStyle }) => {
  const { loading, error, data } = useTransactionDetailsContext();

  const navigate = useNavigate();
  const { user } = useAppContext();

  const pendingTransaction =
    data?.transaction?.status === TransactionStatus.PRELISTING && !data?.transaction?.prelimPending;

  if (pendingTransaction) {
    navigate(`/transaction/${data?.transaction.id}/documents`);
  }

  const userTransactionRoles = getUserRoles(data?.transaction?.participants, user.id);

  if (loading) {
    return (
      <>
        <ScrollToTop setHeightStyle={setHeightStyle} />
        <TransactionDetailSkeleton />
      </>
    );
  }

  if (error) {
    return (
      <Box m="space50">
        <AlertMessage />
      </Box>
    );
  }

  return (
    <>
      <EPContainer>
        {data?.transaction && (
          <>
            <EPSidebar>
              <PropertyDetails transaction={data.transaction} />
              <ProgressTracker />
            </EPSidebar>
            <EPContent>
              <TodoList todos={data.transaction.todos} userTransactionRoles={userTransactionRoles} />
            </EPContent>
          </>
        )}
      </EPContainer>
    </>
  );
};
