import React, { FC } from 'react';
import { EmdPaymentType } from '@endpoint/platform-api-connector/dist/graphql-types';
import { PinnedItemSkeleton } from 'components/Skeleton/PinnedItemSkeleton';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';
import { useCheckPayableDetails } from 'hooks/useCheckPayableDetails';
import { useTransactionOffice } from 'hooks/useTransactionOffice';
import { useCompanyContext } from 'utils/context';
import { ProcessVariation } from 'consts/enums';

import { PinnedItemErrorMessage } from '../PinnedItemErrorMessage';
import { PinnedItemM2Check } from './PinnedItemM2Check';
import { PinnedItemM2WireTransfer } from './PinnedItemM2WireTransfer';

export const M2PaymentVariant: FC = () => {
  const { data: transactionData } = useTransactionDetailsContext();
  const { companyName } = useCompanyContext();
  const { emd, id, marketId, processVariation } = transactionData!.transaction;
  const isUnityTransaction = processVariation === ProcessVariation.UNITY;

  const isPaymentCheck = emd?.paymentType === EmdPaymentType.CHECK;
  const { error: errorFetchingOffice, loading: loadingOffice, data: officeData } = useTransactionOffice(id);
  const office = officeData?.transaction?.office || {};
  const isAHC = companyName === 'AHC';
  const {
    checkInstructionsAddress: paymentAddress,
    payableName,
    paymentInstructionsLoading,
    paymentInstructionsError,
  } = useCheckPayableDetails({
    isAHC,
    isUnityTransaction,
    marketId: marketId as number,
    transactionId: id,
  });

  if (loadingOffice || paymentInstructionsLoading) {
    return <PinnedItemSkeleton />;
  }

  if (errorFetchingOffice || paymentInstructionsError) {
    return <PinnedItemErrorMessage />;
  }

  return isPaymentCheck ? (
    <PinnedItemM2Check payableName={payableName} paymentAddress={paymentAddress} />
  ) : (
    <PinnedItemM2WireTransfer office={office} />
  );
};

M2PaymentVariant.displayName = 'M2PaymentVariant';
