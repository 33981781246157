// * Prelisting/Listing details is only for Agents/TC/FSBO in Sale transactions

import { Transaction, TransactionDates } from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatDate, MONTH_DAY_YEAR } from 'utils/formatDate';

import { DetailList } from '..';

export const getListingDetails = (t: Transaction): DetailList[] => [
  {
    ...displayListingDate(t.dates),
  },
  {
    label: 'File #',
    value: t.fileNum ? t.fileNum : 'Not available yet',
  },
  {
    label: 'Status:',
    value: t.dates.titleProductDelivered ? 'Escrow is ready to be opened' : 'Order Processing',
  },
];

// Generally, prelisting date will always appear before delivered date
export const displayListingDate = (dates: TransactionDates) => {
  return dates.titleProductDelivered
    ? {
        label: 'Delivered:',
        value: formatDate(dates.titleProductDelivered, MONTH_DAY_YEAR),
      }
    : {
        label: 'Ordered:',
        value: formatDate(dates.prelistingOpened, MONTH_DAY_YEAR),
      };
};
