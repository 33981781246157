import React, { FC, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Box, Heading, Text, Flex, Button, Stack, useToast } from '@endpoint/blockparty';
import { Formik, Form } from 'formik';
import { identity, pickBy } from 'lodash';
import { FormikInputGroup, FormikSelectGroup } from 'components/form/FormikBlockparty';
import {
  ContactType,
  UpdateContactInput,
  ContactWhereUniqueInput,
  StateOfOperation,
} from '@endpoint/endpoint-bff-graphql-schema';
import { AppContext, AppContextType, CompanyContextType, useCompanyContext } from 'utils/context';
import Toast from 'Auth/Components/Toast';
import { useContact } from 'hooks/useContact';
import { formatPhoneNumberWithOptionalIntlCode } from 'utils/formatPhone';
import { useGetMarkets } from 'hooks/useGetMarkets';
import { isSupportedSignUpState } from 'utils/isSupportedSignUpState';
import { useAuth } from 'hooks/auth/useAuth';
import { trackAction, formatAnalyticsProfileIdentity, trackIdentityPreCreation } from 'utils/analytics';
import { SignUpTrackingEvents, TRACK_SET_PROFILE_PAYLOAD } from 'consts/analytics';

import ProfileValidationSchema from './SetUpProfileValidationSchema';
import { US_STATES, NOTIFICATION_PREFERENCE_URL, OUT_OF_COVERAGE_URL } from '../../Utilities/Constant';
import {
  ITransacteeIndividual,
  IAgentProfileFormValues,
  ITCProfileFormValues,
  IMortgageBrokerProfileFormValues,
  ISsoProfileFormValues,
} from '../../types.d';
import { SET_PROFILE_CONTINUE_BUTTON_TEXT } from '../../../consts/descriptions';

const SetUpProfile: FC = () => {
  const { data } = useAuth();
  const { companyName }: CompanyContextType = useCompanyContext();
  const isAHC = companyName === 'AHC';
  const toast = useToast();
  const { user, setUser }: AppContextType = useContext(AppContext);
  const { updateContact, updateContactLoading, updateContactResult, updateContactError } = useContact();

  const role = user.contactType || ContactType.TRANSACTEE_INDIVIDUAL;

  const isTransactionCoordinator = role === ContactType.TRANSACTION_COORDINATOR;
  const isAgent = role === ContactType.AGENT;
  const isLender =
    role === ContactType.MORTGAGE_BROKER || role === ContactType.LOAN_OFFICER || role === ContactType.LOAN_PROCESSOR;
  const isTransacteeIndividual = role === ContactType.TRANSACTEE_INDIVIDUAL;
  const isTransacteeCorporation = role === ContactType.TRANSACTEE_CORPORATION;
  const isTransacteePartnership = role === ContactType.TRANSACTEE_PARTNERSHIP;
  const isTransacteeTrust = role === ContactType.TRANSACTEE_TRUST;
  const isRoleWithOutStateRequirement =
    isTransacteeIndividual || isTransacteeCorporation || isTransacteePartnership || isTransacteeTrust;

  const { markets, loading: marketsLoading, error: marketsError } = useGetMarkets({ isActive: true });

  useEffect(() => {
    if (!marketsLoading && !!marketsError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={marketsError.message} onClose={onClose} />,
      });
    }
  }, [marketsLoading, marketsError, toast]);

  const handleSubmitProfile = async (
    values: Partial<
      | ITransacteeIndividual
      | IAgentProfileFormValues
      | ITCProfileFormValues
      | IMortgageBrokerProfileFormValues
      | ISsoProfileFormValues
    >,
  ) => {
    trackAction(SignUpTrackingEvents.SET_PROFILE_BUTTON_SUBMITTED, TRACK_SET_PROFILE_PAYLOAD);
    const { firstName, lastName, middleName, suffix } = values;
    const entityName = (values as IAgentProfileFormValues | ITCProfileFormValues | IMortgageBrokerProfileFormValues)
      ?.entityName;
    const licenseNumber = (values as IAgentProfileFormValues)?.licenseNumber?.trim();

    const valueStateOfOperation = getSupportedSignupState(
      (values as IAgentProfileFormValues | ITCProfileFormValues | IMortgageBrokerProfileFormValues)?.stateOfOperation,
    );

    if (user.id) {
      let phone = '';

      phone = data?.phone;

      let updateContactInput: UpdateContactInput = {
        firstName,
        lastName,
        middleName: middleName?.trim(),
        suffix: suffix?.trim(),
        phone: formatPhoneNumberWithOptionalIntlCode(phone),
      };

      if (isTransactionCoordinator || isLender) {
        updateContactInput = {
          ...updateContactInput,
          entityName,
          stateOfOperation: valueStateOfOperation,
        };
      } else if (isAgent) {
        updateContactInput = {
          ...updateContactInput,
          entityName,
          stateOfOperation: valueStateOfOperation,
          licenseNumber,
        };
      }

      const contactWhereUniqueInput: ContactWhereUniqueInput = {
        id: user.id,
      };

      const cleanUpdateContactInput: UpdateContactInput = pickBy(updateContactInput, identity);

      await updateContact(contactWhereUniqueInput, cleanUpdateContactInput).then(() => {
        trackIdentityPreCreation(formatAnalyticsProfileIdentity(cleanUpdateContactInput));
      });
    }
  };

  const getSupportedSignupState = (chosenStateOfOperation: StateOfOperation): StateOfOperation | undefined => {
    if (!!markets?.length && isSupportedSignUpState(markets, chosenStateOfOperation)) return chosenStateOfOperation;

    return undefined;
  };

  useEffect(() => {
    if (!updateContactLoading && updateContactResult) {
      const onboardingUrl =
        updateContactResult.updateContact.stateOfOperation || isRoleWithOutStateRequirement
          ? NOTIFICATION_PREFERENCE_URL
          : OUT_OF_COVERAGE_URL;
      const currentRoles = user.roles;

      setUser({ ...user, ...updateContactResult.updateContact, onboardingUrl, roles: currentRoles });
    }

    if (!updateContactLoading && updateContactError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={updateContactError.message} onClose={onClose} />,
      });

      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        message: `updateContact mutation error -- Set up profile screen / Onboarding. Contact id: ${
          user.id
        }. Error: ${JSON.stringify(updateContactError)}}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContactLoading, updateContactResult, updateContactError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        stateOfOperation: isAHC ? 'WA' : undefined,
      }}
      validateOnBlur
      validationSchema={ProfileValidationSchema[role]}
      onSubmit={handleSubmitProfile}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Box mb="space70" textAlign="left">
            <Heading as="h2" mb="space50" size={{ base: 'fontSize70', md: 'fontSize60' }}>
              Set up your profile
            </Heading>
            <Text>The following information will be used for your official documents.</Text>
          </Box>
          <Stack mb="space80" spacing="space60">
            <FormikInputGroup
              autoFocus
              data-test-id="firstName"
              display="block"
              isOptional={false}
              label="First Name"
              name="firstName"
              placeholder="e.g. John"
            />
            <FormikInputGroup
              data-test-id="middleName"
              display="block"
              isOptional
              label="Middle Name"
              name="middleName"
              placeholder="e.g. William"
            />
            <FormikInputGroup
              data-test-id="lastName"
              display="block"
              label="Last Name"
              name="lastName"
              placeholder="e.g. Doe"
            />
            <FormikInputGroup
              data-test-id="suffix"
              display="block"
              isOptional
              label="Suffix"
              name="suffix"
              placeholder="e.g. Jr."
            />

            {(isAgent || isTransactionCoordinator) && (
              <FormikInputGroup
                data-test-id="entityName"
                label="Brokerage/Company Name"
                name="entityName"
                placeholder="e.g. Example Brokerage"
              />
            )}
            {isAgent && (
              <FormikInputGroup
                data-test-id="licenseNumber"
                isOptional
                label="Real Estate License Number"
                name="licenseNumber"
                placeholder="e.g. 87654321"
              />
            )}
            {isLender && (
              <FormikInputGroup
                data-test-id="entityName2"
                label="Mortgage Company Name"
                name="entityName"
                placeholder="e.g. Example Company"
              />
            )}
            {(isAgent || isTransactionCoordinator || isLender) && (
              <FormikSelectGroup
                data-test-id="stateOfOperation"
                defaultValue={isAHC ? { value: 'WA', label: 'WA' } : undefined}
                disabled={isAHC}
                isClearable={false}
                isSearchable
                label="Operating State"
                name="stateOfOperation"
                options={US_STATES}
                placeholder="Select"
              />
            )}
          </Stack>
          <Flex
            backgroundColor="white"
            bottom={{ base: 0, md: 'initial' }}
            boxShadow={{ base: 'medium', md: 'none' }}
            justifyContent="flex-end"
            left={{ base: 0, md: 'initial' }}
            position={{ base: 'fixed', md: 'initial' }}
            px={{ base: 'space50', md: 'space0' }}
            py={{ base: 'space30', md: 'space0' }}
            right={{ base: 0, md: 'initial' }}
            zIndex="overlay"
          >
            <Button isDisabled={!(isValid && dirty)} isLoading={updateContactLoading} type="submit">
              {SET_PROFILE_CONTINUE_BUTTON_TEXT}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SetUpProfile;
