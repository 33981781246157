import { Transaction } from '@endpoint/endpoint-bff-graphql-schema';
import { TransactionDetailsPayload } from 'routes/TransactionDetail/Todos/queries';
import _ from 'lodash';

export function mergeTransactions(
  apiTransaction: TransactionDetailsPayload,
  bffTransaction: Transaction,
): TransactionDetailsPayload | undefined {
  const bffProps = new Set(Object.keys(bffTransaction));

  const apiTempOverrideProps = new Set(['participants', 'milestones']);

  const apiProps = Object.keys(apiTransaction.transaction).filter(
    (key) => !bffProps.has(key) || apiTempOverrideProps.has(key),
  );

  const res = {
    transaction: {
      ...bffTransaction,
      ..._.pick(apiTransaction.transaction, apiProps),
    },
  };

  // @ts-ignore
  return res;
}

export function buildTransaction(
  transactionDetails?: TransactionDetailsPayload,
  bffTransaction?: Transaction,
): TransactionDetailsPayload | undefined {
  if (!transactionDetails) {
    return undefined;
  }

  if (!bffTransaction) {
    return transactionDetails;
  }

  return mergeTransactions(transactionDetails, bffTransaction);
}
