import { useLazyQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { GET_ACTIVE_FILE_NUMBERS } from './queries';

export const useGetActiveFileNumbers = (maxResults?: number) => {
  const sentryTransaction = useSentryTransaction('getActiveFileNumbers', 'GraphQL client query');

  const [getActiveFileNumbers, { data, error, loading }] = useLazyQuery<{
    getActiveFileNumbers: string[];
  }>(GET_ACTIVE_FILE_NUMBERS, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
    variables: { maxResults },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
  });

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    getActiveFileNumbers,
    activeFileNumbers: data,
    activeFileNumbersLoading: loading,
    activeFileNumberError: error,
  };
};
