import React, { FC } from 'react';
import { Avatar, Box, Flex, Grid, Text } from '@endpoint/blockparty';
import { EscrowTeamMember } from '@endpoint/endpoint-bff-graphql-schema';

export const ContactList: FC<{ team: EscrowTeamMember[] }> = ({ team }) => {
  const displayTenMembers = team.slice(0, 10);

  return (
    <Grid
      gap="space60"
      pb="space80"
      pt={{ base: 'space60', md: 'space70' }}
      templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
    >
      {displayTenMembers.map((c) => (
        <Contact key={c.lastName} {...c} />
      ))}
    </Grid>
  );
};

export const Contact: FC<EscrowTeamMember> = ({ firstName, lastName, photoUrl, title }) => {
  return (
    <Flex>
      <Box height="40px" mr="space50" my="auto">
        <Avatar name={lastName} src={photoUrl} width="40px" />
      </Box>
      <Flex flexDirection="column">
        <Text fontWeight="semi" m="space0" p="space0">
          {`${firstName} ${lastName}`}
        </Text>
        <Text m="space0" p="space0">
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};
