import { gql } from '@apollo/client';

export const GET_ME = gql`
  query getMe {
    getMe {
      id
      email
      phone
      contactType
      notificationPreferences {
        email
        sms
      }
      createdAt
      updatedAt
      group
      firstName
      middleName
      lastName
      suffix
      licenseNumber
      stateOfOperation
      entityName
      isOnboarded
      transactions
      isAgent
      isTransactionCoordinator
    }
  }
`;
