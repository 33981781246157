import React, { FC } from 'react';
import { SummaryField } from '@endpoint/endpoint-bff-graphql-schema';
import { Flex, Text, Icon, Stack } from '@endpoint/blockparty';
import { UploadedFileProps } from 'components/Upload';
import { getFileNameFromUploadedFile, setIconByMimeType } from 'utils/fileUpload';

interface SummaryFileUploaderProps {
  field: SummaryField;
}

export const SummaryFileUploader: FC<SummaryFileUploaderProps> = ({ field }: SummaryFileUploaderProps) => {
  return (
    <Stack mt="space60">
      {Object.entries(field.answer).map(([key, file]) => {
        const fileName = getFileNameFromUploadedFile(file as UploadedFileProps);
        const mimeType = fileName.split('.')[1];

        return (
          <Flex key={key} flexDirection="row" justifyContent="space-between" mb="space40">
            <Flex alignItems="center">
              <Icon
                color={mimeType?.includes('pdf') ? '' : 'blue500'}
                mr="space30"
                name={setIconByMimeType(mimeType)}
                size="large"
              />
              <Text>{fileName}</Text>
            </Flex>
          </Flex>
        );
      })}
    </Stack>
  );
};
