import { BFF_CLIENT_NAME, client } from 'Apollo';
import * as Sentry from '@sentry/react';
import { Document } from '@endpoint/endpoint-bff-graphql-schema';
import { GET_DOCUMENT } from 'routes/Transactions/queries';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';

export type TransactionDocument = Pick<
  Document,
  'id' | 'name' | 'url' | 'mimeType' | 'type' | 'createdAt' | 'escrowNote' | 'uploadedBy'
>;

export interface DocumentQueryResponse {
  getDocument: TransactionDocument;
}

export interface DocumentsQueryResponse {
  getDocuments: TransactionDocument[];
}

export interface CheckedTransactionDocument extends TransactionDocument {
  checked: boolean;
}

const fetchDocument = async (documentId: string, transactionId: string, traceHeader: string) => {
  const {
    data: { getDocument },
  } = await client.query<DocumentQueryResponse>({
    query: GET_DOCUMENT,
    variables: { input: { id: documentId, transactionId } },
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: traceHeader },
    },
  });

  return getDocument;
};

export const fetchDocumentsById = async (documentIds: string[], transactionId: string) => {
  if (!documentIds.length) {
    return [];
  }

  const sentryTransaction = Sentry.startTransaction({ name: 'queryDocumentsById', op: 'GraphQL client query' });

  const documents = await Promise.all(
    documentIds.map((id) => fetchDocument(id, transactionId, sentryTransaction.toTraceparent())),
  );

  sentryTransaction.finish();

  return documents;
};
