import React, { useContext, useEffect } from 'react';
import { Box, Heading, Text, Flex, Button } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';
import { CompanyContext, DEFAULT_AUTH_STATE, AppContext, AppContextType } from 'utils/context';
import { useLocation } from 'react-router-dom';

import { RouteOptions } from '../../Utilities/Constant';
import { getCompanyPhoneNumber } from '../../../utils/getCompanyPhoneNumber';
import { formatPhone } from '../../../utils/formatPhone';

interface AccountLockedInstructions {
  accountLockedInstructions: string;
}

function AccountLocked() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuthFields }: AppContextType = useContext(AppContext);
  const { companyName } = useContext(CompanyContext);
  const accountLockedInstructions = (location.state as AccountLockedInstructions)?.accountLockedInstructions;

  useEffect(() => {
    if (!accountLockedInstructions) {
      navigate(RouteOptions.SIGN_IN);
    }
  }, [accountLockedInstructions, navigate]);

  return (
    <>
      <Box mb="space80" textAlign="center">
        <Heading as="h3" mb="space40" size="fontSize50">
          Your account’s security is important to us
        </Heading>
        <Text as="p" mb="space60">
          To ensure your account remains secure, we’ve <b>temporarily locked your account for 15 minutes</b> due to too
          many incorrect attempts. We apologize for the inconvenience.
        </Text>
        <Text>
          {accountLockedInstructions || ''}
          <Text as="a" color="blue500" href={`tel:+1${getCompanyPhoneNumber(companyName).generalInquiries}`}>
            {formatPhone(getCompanyPhoneNumber(companyName).generalInquiries)}
          </Text>{' '}
          and we’ll help get you logged in.
        </Text>
      </Box>
      <Flex flex={1} justifyContent="center" width="100%">
        <Button
          mb="space70"
          onClick={() => {
            setAuthFields(DEFAULT_AUTH_STATE);
            navigate(RouteOptions.SIGN_IN);
          }}
        >
          Back to Sign In
        </Button>
      </Flex>
    </>
  );
}

export default AccountLocked;
