import { gql } from '@apollo/client';

export const OPEN_ESCROW = gql`
  mutation OpenEscrow($input: OpenEscrowInput!) {
    openEscrow(input: $input) {
      transactionId
    }
  }
`;

export const OPEN_ESCROW_FROM_PRELIM = gql`
  mutation OpenEscrowFromPrelim($input: OpenEscrowFromPrelimInput!) {
    openEscrowFromPrelim(input: $input) {
      transactionId
    }
  }
`;
