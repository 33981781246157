import { useMutation } from '@apollo/client';
import {
  DwollaCustomer,
  DwollaCustomerInput,
  DwollaKbaVerification,
  DwollaKbaVerificationResponse,
  DwollaTransfer,
  DwollaTransferInput,
} from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { SEND_TRANSFER, UPSERT_DWOLLA_CUSTOMER, VERIFY_KBA } from './mutations';

export const useDwolla = () => {
  const [
    upsertDwollaCustomerMutation,
    { data: dwollaCustomerCreationResult, loading: dwollaCustomerLoading, error: dwollaCustomerUpsertError },
  ] = useMutation<{ upsertDwollaCustomer: DwollaCustomer }, { data: DwollaCustomerInput }>(UPSERT_DWOLLA_CUSTOMER, {
    errorPolicy: 'all',
  });

  const sentryTransactionUpsertDwollaCustomer = useSentryTransaction('upsertDwollaCustomer', 'GraphQL client mutation');
  const sentryTransactionVerifyKba = useSentryTransaction('verifyKba', 'GraphQL client mutation');

  const upsertDwollaCustomer = async (input: DwollaCustomerInput) => {
    await upsertDwollaCustomerMutation({
      variables: { data: input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionUpsertDwollaCustomer.toTraceparent(),
        },
      },
    });

    sentryTransactionUpsertDwollaCustomer.finish();
  };

  const [
    verifyKBAMutation,
    { data: kbaVerificationResults, loading: kbaVerificationLoading, error: kbaVerificationError },
  ] = useMutation<{ verifyKba: DwollaKbaVerificationResponse }, { data: DwollaKbaVerification }>(VERIFY_KBA, {
    errorPolicy: 'all',
  });

  const verifyKBA = async (input: DwollaKbaVerification) => {
    await verifyKBAMutation({
      variables: { data: input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionVerifyKba.toTraceparent(),
        },
      },
    });

    sentryTransactionVerifyKba.finish();
  };

  const [sendTransfer, { data: transferResults, loading: transferLoading, error: transferError }] = useMutation<
    { sendTransfer: DwollaTransfer },
    { data: DwollaTransferInput }
  >(SEND_TRANSFER, { errorPolicy: 'all' });

  return {
    upsertDwollaCustomer,
    dwollaCustomerCreationResult,
    dwollaCustomerLoading,
    dwollaCustomerUpsertError,
    verifyKBA,
    kbaVerificationResults,
    kbaVerificationLoading,
    kbaVerificationError,
    sendTransfer,
    transferResults,
    transferLoading,
    transferError,
  };
};
