import React, { FC } from 'react';
import { EPContainer, EPSidebar, EPContent } from '@endpoint/blockparty';

import { TodoProgressSkeleton } from '../TodoSkeletons/TodoProgressSkeleton';
import { TodoSkeleton } from '../TodoSkeletons/TodoSkeleton';

export const OpenEscrowSkeleton: FC = () => (
  <EPContainer>
    <EPSidebar>
      <TodoProgressSkeleton />
    </EPSidebar>
    <EPContent>
      <TodoSkeleton />
    </EPContent>
  </EPContainer>
);
