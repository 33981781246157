import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Link } from '@endpoint/blockparty';
import { RouteOptions } from 'Auth/Utilities/Constant';
import { LinkClickedTrackingPayload, SignUpTrackingEvents, TrackingEventType } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { AppContext, AppContextType } from '../../../utils/context';

interface ISignInLinkProps {
  color?: string;
}

// TODO: make this a button
export const SignInLink: FC<ISignInLinkProps> = ({ color }: ISignInLinkProps) => {
  const navigate = useNavigate();
  const { authFields, setAuthFields }: AppContextType = useContext(AppContext);
  const SIGN_IN_TEXT = 'Sign in';

  const handleClick = () => {
    const trackSignInProperties: LinkClickedTrackingPayload = {
      name: TrackingEventType.LINK_CLICKED,
      linkId: SIGN_IN_TEXT,
    };

    trackAction(SignUpTrackingEvents.SIGN_IN_LINK, trackSignInProperties);
    setAuthFields({ ...authFields, errorCode: null });
    navigate(RouteOptions.SIGN_IN);
  };

  return (
    <Link color={color} cursor="pointer" textDecoration="underline" onClick={handleClick}>
      {SIGN_IN_TEXT}
    </Link>
  );
};
