import { createContext, useContext } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import {
  ContactType,
  NotificationPreferences,
  StateOfOperation,
  OrganizationEpAppConfigValues,
} from '@endpoint/endpoint-bff-graphql-schema';
import { ErrorCodes, UnityRole } from 'Auth/Utilities/Constant';
import { CompanyName } from 'utils/whiteLabel/types';
import { USState } from 'utils/getSupportedUSState';
import { DEFAULT_ORGANIZATION_ID, getConfig } from 'utils/config';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export interface User {
  loading?: boolean;
  id?: string;
  displayName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  email?: string;
  entityName?: string;
  phone?: string;
  photo?: string;
  realEstateAgentLicenseNumber?: string;
  notificationPreferences?: NotificationPreferences;
  betaGroup?: boolean;
  isExclusivelyAgent?: boolean;
  isOnboarded?: boolean;
  lastTransactionViewed?: LastTransactionViewedType;
  updateLastTransactionViewed?: Function;
  stateSignedUp?: USState | StateOfOperation;
  showNotificationsHeaderIcon?: boolean;
  setShowNotificationsHeaderIcon?: Function;
  contactType?: ContactType;
  onboardingUrl?: string;
  roles?: Array<string>;
  group?: string;
  refetch?: boolean;
  transactions?: string[];
  isNonAgent?: boolean;
  isAgent?: boolean;
  isTransactionCoordinator?: boolean;
  unityTransactionCreationEnabled?: boolean;
  unityRole?: UnityRole;
}

export interface AuthUser {
  username?: string;
  password?: string;
  session?: string;
  organizationId?: number;
  identity?: Record<string, string>;
}

export interface AuthFields {
  email: string;
  navigateToAfterLogin: string;
  referrer: string;
  mfa: string;
  password: string;
  phone: string;
  user: CognitoUser | AuthUser | null;
  errorCode: ErrorCodes | null;
  resetPassword: boolean;
  passwordExpired?: boolean;
  resendCodeAction: () => Promise<any>;
  isMfaEnabled?: boolean;
  accessToken?: string;
  idToken?: string;
}

export interface SignUpOnboarding {
  completedStep: number | null;
}
export interface LastTransactionViewedType {
  fileNum?: string | undefined;
  marketId?: number | undefined;
  officeName?: string | undefined;
}

export interface TodoData {
  id: string;
}

export type IsOnline = Boolean;

export interface AppContextType {
  user: User;
  setUser: Function;
  authFields: AuthFields;
  setAuthFields: Function;
  error: any;
  setError: Function;
  todoData: TodoData;
  setTodoData: Function;
  isOnline: Boolean;
}

export interface AppConfigsContextType extends OrganizationEpAppConfigValues {
  ORGANIZATION_ID: number;
}

export const defaultAppConfigsContext = {
  ORGANIZATION_ID: DEFAULT_ORGANIZATION_ID,
  BFF_URL: '',
  HELP_URL: '',
  ENVIRONMENT: '',
  GRAPHQL_URL: '',
  PRIVACY_URL: '',
  COMPANY_EMAIL: '',
  CONTACT_US_URL: '',
  NON_SSO_EMAILS: [],
  ZENDESK_APP_ID: '',
  SSO_CALLBACK_URL: '',
  DATA_PRIVACY_EMAIL: '',
  GOOGLE_TAG_MANAGER: { auth: '', gtmId: '', preview: '' },
  THEME_URL: '',
  COMPANY_PHONE_NUMBER: {},
  MANIFEST_URL: '',
  AWS_COGNITO_SSO_IDENTITY_PROVIDERS: [],
};

export const DEFAULT_AUTH_STATE = {
  email: '',
  navigateToAfterLogin: '',
  referrer: '',
  mfa: '',
  password: '',
  phone: '',
  user: null,
  errorCode: null,
  resetPassword: false,
  resendCodeAction: () => new Promise({} as any),
};

export const DEFAULT_TODO_STATE = {
  id: '',
};

export const DEFAULT_IS_ONLINE_STATE = true;

export const DEFAULT_USER_STATE = {};

export const defaultAppContext = {
  user: DEFAULT_USER_STATE,
  setUser: () => null,
  authFields: DEFAULT_AUTH_STATE,
  setAuthFields: () => null,
  error: {},
  setError: () => null,
  todoData: DEFAULT_TODO_STATE,
  setTodoData: () => null,
  isOnline: DEFAULT_IS_ONLINE_STATE,
};

export const AppContext = createContext<AppContextType>(defaultAppContext);

export const useAppContext = () => useContext(AppContext);

export interface CompanyContextType {
  companyName: CompanyName;
}

export const CompanyContext = createContext<CompanyContextType>({ companyName: 'Endpoint' });

export const useCompanyContext = () => useContext(CompanyContext);

export function isAgent(user: User): boolean {
  return Boolean(user.isAgent || user.isTransactionCoordinator) || user?.group === 'AGENTS';
}

export function isContactTypeAgentOrTC(user: User): boolean {
  if (user.unityTransactionCreationEnabled) {
    return Boolean(user.isAgent || user.isTransactionCoordinator);
  }

  return user.contactType === ContactType.AGENT || user.contactType === ContactType.TRANSACTION_COORDINATOR;
}

export const AppConfigsContext = createContext<AppConfigsContextType>(defaultAppConfigsContext);

export const useAppConfigsContext = () => {
  const { isAppConfigsFromOrgService } = useFeatureFlags();
  const appConfigurationContext = useContext(AppConfigsContext);

  if (isAppConfigsFromOrgService) {
    return appConfigurationContext;
  }

  const config = getConfig();

  return {
    ...config,
    COMPANY_EMAIL: undefined,
    AWS_COGNITO_SSO_IDENTITY_PROVIDERS: config.AWS_COGNITO_SSO_IDENITY_PROVIDERS,
  };
};
