import { useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import {
  OperationVariables,
  SubmitTodoAssignmentStepResponse,
  SUBMIT_TODO_ASSIGNMENT_STEP_MUTATION,
} from './mutations';

export const useSubmitTodoAssignmentStep = () => {
  const sentryTransaction = useSentryTransaction('submitTodoAssignmentStep', 'GraphQL client mutation');

  const [submitTodoAssignmentStep, { data, error, loading }] = useMutation<
    SubmitTodoAssignmentStepResponse,
    OperationVariables
  >(SUBMIT_TODO_ASSIGNMENT_STEP_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    submitTodoAssignmentStep,
    submitTodoAssignmentStepLoading: loading,
    submitTodoAssignmentStepError: error,
  };
};
