import React, { FC } from 'react';
import { Text } from '@endpoint/blockparty';
import { differenceInCalendarDays } from 'date-fns';
import { MONTH_DAY_YEAR, formatDate } from 'utils/formatDate';

interface DisplayDateProps {
  estimatedClosing: Date;
}

export const DisplayDate: FC<DisplayDateProps> = ({ estimatedClosing }) => {
  const currentDate = new Date().toISOString();
  const daysLeft = differenceInCalendarDays(new Date(estimatedClosing), new Date(currentDate));

  const displayDaysLeft = daysLeft <= 0 ? '' : `(in ${daysLeft} days)`;

  return (
    <>
      <Text size="fontSize10">{formatDate(estimatedClosing, MONTH_DAY_YEAR)}</Text>{' '}
      <Text data-test-id="days-left-text" fontWeight="semi" size="fontSize10">
        {displayDaysLeft}
      </Text>
    </>
  );
};
