import { gql } from '@apollo/client';
import { SignUpInput, AuthResponse } from '@endpoint/endpoint-bff-graphql-schema';

export interface SignUpData {
  signUp: AuthResponse;
}

export interface SignUpVariables {
  input: SignUpInput;
}

export const SIGN_UP_MUTATION = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      session
      authentication {
        accessToken
        idToken
      }
    }
  }
`;
