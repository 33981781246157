import React, { FC, useState } from 'react';
import { EPContainer, EPSidebar, EPContent } from '@endpoint/blockparty';
import { ProgressPanel } from 'components/form/ProgressPanel';
import { Formik, Form } from 'formik';
import { FormikValue } from 'routes/OpenEscrow';
import { ANCHORED_BUTTON_OFFSET } from 'consts/responsive';

export interface Step {
  component: FC<CurrentStepComponentProps<any>>;
  title: string;
  validationSchema?: any;
  summaryTitle?: string;
  summary?: (formikValues: FormikValue) => JSX.Element | null;
}

export interface CurrentStepComponentProps<T> {
  currentStepPosition?: number;
  formikValue: T;
  setCurrentStepPosition: Function;
}

interface TwoColumnWithProgressContainerProps {
  initialValues?: object;
  steps: Step[];
  title: string;
  validationSchema?: any;
}

/**
 * TwoColumn contains sidebar and main content.
 * See Figma below for example of the layout.
 * Figma ref: https://www.figma.com/file/d9zcbACqotpxTcwPdrYSfA/Prototypes%3A-Order-Prelim?node-id=1815%3A166
 */
export const TwoColumnWithProgressContainer: FC<TwoColumnWithProgressContainerProps> = (
  props: TwoColumnWithProgressContainerProps,
) => {
  const [currentStepPosition, setCurrentStepPosition] = useState(1);
  const { initialValues = {}, steps, title, validationSchema } = props;
  const currentStep = steps[currentStepPosition - 1];
  const { validationSchema: stepValidationSchema, component: CurrentStepComponent } = currentStep;

  return (
    <EPContainer>
      <EPSidebar>
        <ProgressPanel currentStepPosition={currentStepPosition} steps={steps} title={title} />
      </EPSidebar>
      <EPContent mb={{ base: ANCHORED_BUTTON_OFFSET, md: 'space0' }} role="main">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema || stepValidationSchema}
          onSubmit={() => {}}
        >
          {({ values }) => (
            <Form>
              <CurrentStepComponent
                currentStepPosition={currentStepPosition}
                formikValue={values}
                setCurrentStepPosition={setCurrentStepPosition}
              />
            </Form>
          )}
        </Formik>
      </EPContent>
    </EPContainer>
  );
};
