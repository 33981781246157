import { StateOfOperation } from '@endpoint/endpoint-bff-graphql-schema';

export interface MmunikyOptionsProps {
  [name: string]: {
    id: number;
    name: string;
  };
}

export const mmunikyStateOptions: MmunikyOptionsProps = {
  [StateOfOperation.KS]: {
    id: 15,
    name: 'Kansas',
  },
  [StateOfOperation.IL]: {
    id: 16,
    name: 'Illinois',
  },
  [StateOfOperation.KY]: {
    id: 17,
    name: 'Kentucky',
  },
  [StateOfOperation.MI]: {
    id: 18,
    name: 'Michigan',
  },
  [StateOfOperation.MN]: {
    id: 19,
    name: 'Minnesota',
  },
  [StateOfOperation.UT]: {
    id: 20,
    name: 'Utah',
  },
  [StateOfOperation.NV]: {
    id: 21,
    name: 'Nevada',
  },
};

export const mmunikyMarketIds = (): number[] => Object.values(mmunikyStateOptions).map((state) => state.id);

export const MARKET_ID = {
  WA: 1,
  CA: 2,
  AZ: 3,
  TX: 4,
  NorCA: 5,
  AHC: 6,
  SoCA: 7,
  FL: 8,
  CO: 9,
  TN: 10,
  MO: 11,
  OH: 12,
  IN: 13,
  KS: 15,
  KY: 17,
};
