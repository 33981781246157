import React, { FC, FocusEvent } from 'react';
import { useFormikContext } from 'formik';
import Cleave from 'cleave.js/react';
import { Input, InputElemGroup, InputRightElement, Text, Stack } from '@endpoint/blockparty';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { CleaveOptions } from 'cleave.js/options';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

export interface TodoPercentInputProps {
  field: FieldOptions;
}

export const TodoPercentInput: FC<TodoPercentInputProps> = ({ field: { id: fieldId, label, answer, placeHolder } }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<GenericObject>();

  useTodoFieldInitializer({ id: fieldId, answer });

  const value = values[fieldId];

  const options: CleaveOptions = {
    blocks: [2, 2],
    delimiter: '.',
    numeral: true,
    numericOnly: true,
  };

  return (
    <Stack mt="space60" spacing="space0">
      {label && (
        <Text color="carbon900" mb="space20" size="fontSize20">
          {label}
        </Text>
      )}
      <InputElemGroup>
        <Input
          as={Cleave}
          options={options}
          placeholder={placeHolder}
          value={value}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setFieldValue(fieldId, e.target.value);
            setFieldTouched(fieldId);
          }}
        />
        <InputRightElement>
          <Text>%</Text>
        </InputRightElement>
      </InputElemGroup>
    </Stack>
  );
};
