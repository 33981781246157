import { useMutation } from '@apollo/client';
import { CreateContactInput, UpdateContactInput, ContactWhereUniqueInput } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import {
  CreateContactData,
  CreateContactVariables,
  CREATE_CONTACT,
  UpdateContactData,
  UpdateContactVariables,
  UPDATE_CONTACT,
} from './mutations';

export const useContact = () => {
  const [
    createContactMutation,
    { data: createContactResult, loading: createContactLoading, error: createContactError },
  ] = useMutation<CreateContactData, CreateContactVariables>(CREATE_CONTACT, {
    errorPolicy: 'all',
  });

  const sentryTransactionCreateContact = useSentryTransaction('createContact', 'GraphQL client mutation');
  const sentryTransactionUpdateContact = useSentryTransaction('updateContact', 'GraphQL client mutation');

  const createContact = async (input: CreateContactInput) => {
    await createContactMutation({
      variables: { data: input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionCreateContact.toTraceparent(),
        },
      },
    });

    sentryTransactionCreateContact.finish();
  };

  const [
    updateContactMutation,
    { data: updateContactResult, loading: updateContactLoading, error: updateContactError },
  ] = useMutation<UpdateContactData, UpdateContactVariables>(UPDATE_CONTACT, {
    errorPolicy: 'all',
  });

  const updateContact = async (where: ContactWhereUniqueInput, input: UpdateContactInput) => {
    await updateContactMutation({
      variables: { where, input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionUpdateContact.toTraceparent(),
        },
      },
    });

    sentryTransactionUpdateContact.finish();
  };

  return {
    createContact,
    createContactResult,
    createContactLoading,
    createContactError,
    updateContact,
    updateContactResult,
    updateContactLoading,
    updateContactError,
  };
};
