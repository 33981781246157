import { useParams } from 'react-router';
import { ApolloError } from '@apollo/client';
import { useCheckPayableDetails } from 'hooks/useCheckPayableDetails';
import { useTransaction } from 'hooks/useTransaction';
import { getFormattedAddressParts } from 'utils/formatAddress';
import { formatPaymentAddress } from 'utils/formatPaymentAddress';
import { CheckInstructions } from 'routes/TransactionDetail/Todo/TodoStep/TodoStepComponent/CheckInstructions/types';
import { useCompanyContext } from 'utils/context';
import { ProcessVariation } from 'consts/enums';

export const useCheckInstructions = (): Omit<CheckInstructions, 'formattedAmount'> & {
  isLoading: boolean;
  error: ApolloError | undefined;
  transactionEmdAmount?: number | null;
} => {
  const { transactionId = '' } = useParams();
  const { companyName } = useCompanyContext();
  const isAHC = companyName === 'AHC';

  const {
    data: transactionPayload,
    loading: transactionLoading,
    error: transactionError,
  } = useTransaction(transactionId);

  const { emd, property, marketId, processVariation } = transactionPayload?.transaction ?? {};
  const isUnityTransaction = processVariation === ProcessVariation.UNITY;
  const transactionEmdAmount = emd?.amount;
  const transactionAddress = getFormattedAddressParts(property?.address ?? {});
  const { checkInstructionsAddress, payableName, paymentInstructionsLoading, paymentInstructionsError } =
    useCheckPayableDetails({
      isAHC,
      isUnityTransaction,
      marketId: marketId as number,
      transactionId,
    });

  const paymentAddress = formatPaymentAddress(checkInstructionsAddress);
  const isLoading = transactionLoading || paymentInstructionsLoading;
  const error = transactionError || paymentInstructionsError;

  return {
    transactionEmdAmount,
    payableName,
    paymentAddress,
    transactionAddress,
    isLoading,
    error,
  };
};
