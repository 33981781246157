import {
  TransactionSummaryParticipantRole,
  TransactionSummaryParticipant,
} from '@endpoint/endpoint-bff-graphql-schema';

export const getUserRoles = (participants: TransactionSummaryParticipant[] = [], userId: string = '') => {
  if (!userId) return [];

  return participants.reduce((acc, participant) => {
    if (participant.contactId === userId) {
      acc.push(participant.roles[0]);
    }

    return acc;
  }, [] as TransactionSummaryParticipantRole[]);
};
