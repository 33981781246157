import React from 'react';

import { DesktopProgressTracker } from './DesktopProgressTracker';
import { MobileProgressTracker } from './MobileProgressTracker';
import { ProgressTrackerProps } from './types';

export const TodoProgressTracker = ({ progressTracker }: ProgressTrackerProps) => {
  return (
    <>
      <DesktopProgressTracker progressTracker={progressTracker} />
      <MobileProgressTracker progressTracker={progressTracker} />
    </>
  );
};
