import React, { FC } from 'react';
import { Box, Stack, Text } from '@endpoint/blockparty';
import {
  CheckInstructionsMailTo,
  CheckInstructionsMemo,
  CheckInstructionsPaymentDetails,
} from 'components/CheckInstructions';
import { ApolloError } from '@apollo/client';

import { CheckInstructions } from '../types';
import { ErrorLoadingStatesWidget } from '../../ErrorLoadingStatesWidget';

interface TodoCheckInstructionsProps {
  checkInstructions: CheckInstructions;
  isLoading: boolean;
  error: ApolloError | undefined;
}

export const TodoCheckInstructions: FC<TodoCheckInstructionsProps> = ({
  checkInstructions: { formattedAmount, payableName, paymentAddress, transactionAddress },
  isLoading,
  error,
}) => {
  if (error || isLoading) return <ErrorLoadingStatesWidget error={error} loading={isLoading} />;

  return (
    <Stack my="space70" spacing="space70">
      <Box>
        <Text as="h2" fontWeight="semi" mb="space50" size="fontSize30">
          Payment Details (Cashier&apos;s Check Only)
        </Text>
        <CheckInstructionsMailTo layoutType="grid" payableName={payableName} paymentAmount={formattedAmount} />
      </Box>
      <Box>
        <Text as="h2" fontWeight="semi" mb="space50" size="fontSize30">
          Note in Memo of Check
        </Text>
        <CheckInstructionsMemo
          cityStateZip={transactionAddress.cityStateZip}
          streetAddress={transactionAddress.streetAddress}
        />
      </Box>
      <Box>
        <Text as="h2" fontWeight="semi" mb="space50" size="fontSize30">
          Mail To
        </Text>
        <CheckInstructionsPaymentDetails
          cityStateZip={paymentAddress.paymentCityStateZip}
          layoutType="grid"
          payableName={payableName}
          streetAddress={paymentAddress.paymentStreet}
          suite={paymentAddress.paymentSuite}
        />
      </Box>
    </Stack>
  );
};
