import React, { FC } from 'react';
import { Grid, Stack, Text } from '@endpoint/blockparty';

export interface DataBlockProps {
  layoutType?: 'grid' | 'stack';
}

export const DataBlock: FC<DataBlockProps> = ({ layoutType, children }) => {
  const Comp =
    layoutType === 'stack' ? (
      <Stack bg="carbon0" borderRadius="radiusDefault" p="space50" spacing="space50">
        {children}
      </Stack>
    ) : (
      <Grid
        bg="carbon0"
        borderRadius="radiusDefault"
        gap="space50"
        p="space50"
        templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
      >
        {children}
      </Grid>
    );

  return Comp;
};

export const Label: FC = ({ children }) => (
  <Text as="p" color="carbon500" size="fontSize10">
    {children}
  </Text>
);

Label.displayName = 'Label';
DataBlock.displayName = 'DataBlock';
