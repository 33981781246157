import { useQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { GET_TEAM_CONTACT_INFO, TeamContactInfoPayload } from './queries';

export const useGetTeamContactInfo = (marketId: number | null) => {
  const { isTransactionContactFromOrgService } = useFeatureFlags();

  const { data, loading, error } = useQuery<TeamContactInfoPayload>(GET_TEAM_CONTACT_INFO, {
    variables: { input: { marketId } },
    context: { clientName: BFF_CLIENT_NAME },
    skip: !isTransactionContactFromOrgService || !marketId,
  });

  return {
    teamContactInfo: data?.getTeamContactInfo,
    teamContactInfoLoading: loading,
    teamContactInfoError: error,
  };
};
