import { OrganizationEpAppConfigValues } from '@endpoint/endpoint-bff-graphql-schema';
import { AppConfigsContextType } from 'utils/context';

export const buildAppConfigsContextValue = (
  id: number,
  epAppConfigs: OrganizationEpAppConfigValues | undefined,
): AppConfigsContextType => {
  return {
    ORGANIZATION_ID: id,
    BFF_URL: epAppConfigs?.BFF_URL || '',
    HELP_URL: epAppConfigs?.HELP_URL || '',
    ENVIRONMENT: epAppConfigs?.ENVIRONMENT || '',
    GRAPHQL_URL: epAppConfigs?.GRAPHQL_URL || '',
    PRIVACY_URL: epAppConfigs?.PRIVACY_URL || '',
    COMPANY_EMAIL: epAppConfigs?.COMPANY_EMAIL || '',
    CONTACT_US_URL: epAppConfigs?.CONTACT_US_URL || '',
    NON_SSO_EMAILS: epAppConfigs?.NON_SSO_EMAILS || [],
    ZENDESK_APP_ID: epAppConfigs?.ZENDESK_APP_ID || '',
    SSO_CALLBACK_URL: epAppConfigs?.SSO_CALLBACK_URL || '',
    SEGMENT_WRITE_KEY: epAppConfigs?.SEGMENT_WRITE_KEY || '',
    DATA_PRIVACY_EMAIL: epAppConfigs?.DATA_PRIVACY_EMAIL || '',
    GOOGLE_TAG_MANAGER: epAppConfigs?.GOOGLE_TAG_MANAGER || { auth: '', gtmId: '', preview: '' },
    THEME_URL: epAppConfigs?.THEME_URL || '',
    COMPANY_PHONE_NUMBER: epAppConfigs?.COMPANY_PHONE_NUMBER || {
      generalInquiries: '',
      technicalSupport: '',
    },
    MANIFEST_URL: epAppConfigs?.MANIFEST_URL || '',
    LAUNCH_DARKLY_CLIENT_ID: epAppConfigs?.LAUNCH_DARKLY_CLIENT_ID || '',
    AWS_COGNITO_SSO_IDENTITY_PROVIDERS: epAppConfigs?.AWS_COGNITO_SSO_IDENTITY_PROVIDERS || [],
  };
};
