import React, { FC } from 'react';
import { Flex, IconButton } from '@endpoint/blockparty';
import { MilestoneStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';
import { useAppContext } from 'utils/context';
import { getUserRoles } from 'utils/transactions/getUserRoles';
import { trackAction } from 'utils/analytics';
import { TodoTrackingEvents } from 'consts/analytics';

import { getMilestoneDescription } from './descriptionHelper';
import { ProgressCarouselItem } from './ProgressCarouselItem';

interface ProgressCarouselProps {
  activeStep: number;
  handleActiveStep: (step: number) => void;
}

type StatusText = 'Completed' | 'In Progress' | 'Not Started';
type ColorVariant = 'complete' | 'inProgress' | 'upcoming';

export type StatusValues = {
  text: StatusText;
  colorVariant: ColorVariant;
};

/*= ====================================== */

export const ProgressCarousel: FC<ProgressCarouselProps> = ({ activeStep, handleActiveStep, ...props }) => {
  const { user } = useAppContext();
  const { milestones, signing, emd, data } = useTransactionDetailsContext();
  const participants = data?.transaction.participants;
  // @ts-ignore
  const userRoles = getUserRoles(participants, user.id);

  const role = userRoles ? userRoles[0] : 'not Available';

  return (
    <>
      <Flex
        bg="carbon0"
        borderRadius="radiusDefault"
        mb="space30"
        overflowX="hidden"
        position="relative"
        scrollBehavior="smooth"
        width="100%"
        {...props}
      >
        {milestones.map((milestone, i) => {
          const milestoneStep = i + 1;
          const currentMilestone = activeStep - 1;
          const last = milestones.length === milestoneStep;
          const description = getMilestoneDescription(milestone, signing, emd, role);
          const statusValues = getStatusValues(milestone.status, milestones[i - 1]?.status);

          return (
            <ProgressCarouselItem
              key={milestoneStep}
              currentMilestone={currentMilestone}
              description={description}
              last={last}
              milestone={milestone}
              milestoneStep={milestoneStep}
              statusValues={statusValues}
              transaction={data!.transaction}
              userRoles={userRoles}
            />
          );
        })}
      </Flex>
      <Flex justifyContent="center">
        <IconButton
          dataTestId="milestone-previous-button"
          isDisabled={activeStep === 1}
          label="View previous milestone"
          mr="40px"
          name="ArrowChevronLeft"
          variant="unstyled"
          variantColor="carbon"
          onClick={() => {
            activeStep > 0 && handleActiveStep(activeStep - 1);
            trackAction(TodoTrackingEvents.TRACK_PREVIOUS_MILESTONE);
          }}
        />
        <IconButton
          dataTestId="milestone-next-button"
          isDisabled={activeStep === milestones.length}
          label="View next milestone"
          name="ArrowChevronRight"
          variant="unstyled"
          variantColor="carbon"
          onClick={() => {
            activeStep < milestones.length && handleActiveStep(activeStep + 1);
            trackAction(TodoTrackingEvents.TRACK_NEXT_MILESTONE);
          }}
        />
      </Flex>
    </>
  );
};

const getStatusValues = (
  currentMilestoneStatus: MilestoneStatus,
  previousMilestoneStatus: MilestoneStatus | undefined,
): StatusValues => {
  if (currentMilestoneStatus === MilestoneStatus.COMPLETE) {
    return { text: 'Completed', colorVariant: 'complete' };
  }

  if (previousMilestoneStatus === MilestoneStatus.COMPLETE) {
    return { text: 'In Progress', colorVariant: 'inProgress' };
  }

  return { text: 'Not Started', colorVariant: 'upcoming' };
};
