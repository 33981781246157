import React, { FC } from 'react';
import { Box, Flex, NavBar, NavBarItem, Loading } from '@endpoint/blockparty';
import { matchPath, useLocation } from 'react-router-dom';
import { isAgent, useAppContext } from 'utils/context';
import { useIsSupportedOrderReportState } from 'hooks/useIsSupportedOrderReportState';
import { TODO_ASSIGNMENT_WITH_STEP } from 'consts/routes';

import { HeaderLeftMobile } from '../HeaderLeftMobile';
import { HeaderLeftRoutes, NonAgentRoutes, NonOrderReportsRoutes, OtherStateRoutes, RouteLabels } from '../routes';

export const AGENTS = 'AGENTS';

export const HeaderLeft: FC = (props) => {
  const { pathname } = useLocation();
  const { user } = useAppContext();
  const isSupportedOrderReportState = useIsSupportedOrderReportState(user);
  const headerRoutes = getHeaderRoutes(isSupportedOrderReportState);

  return (
    <Flex flex="1" {...props}>
      <NavBar>
        {/* NavBar - Mobile */}
        <Box display={{ base: 'block', md: 'none' }}>
          {user.loading && <Loading my="auto" />}
          {headerRoutes.length > 0 && !user.loading && <HeaderLeftMobile headerRoutes={headerRoutes} />}
        </Box>
        {/* NavBar - Desktop */}
        <Box display={{ base: 'none', md: 'flex' }} gap="space70">
          {user.loading && <Loading alignSelf="center" />}
          {!user.loading &&
            HeaderLeftRoutes.reduce((result: Array<JSX.Element>, { route, match, label }) => {
              const matchedPath = pathname.includes(match);
              const isLabelSupportedInStateSignedUp = headerRoutes.includes(label);

              if (isLabelSupportedInStateSignedUp === true) {
                result.push(
                  <NavBarItem
                    key={`nav-bar-item-${label}`}
                    data-test-id={`nav-bar-item-${label}`}
                    href={route}
                    selected={matchedPath}
                  >
                    {label}
                  </NavBarItem>,
                );
              }

              return result;
            }, [])}
        </Box>
      </NavBar>
    </Flex>
  );

  function getHeaderRoutes(isOrderReportSupported: boolean): Array<RouteLabels> {
    let routes: RouteLabels[] = [];
    const isViewingTodo = matchPath(TODO_ASSIGNMENT_WITH_STEP, pathname);

    if (isViewingTodo) {
      routes = [];
    } else if (isAgent(user) && !isOrderReportSupported) {
      routes = NonOrderReportsRoutes;
    } else if (isAgent(user)) {
      routes = OtherStateRoutes;
    } else {
      routes = NonAgentRoutes;
    }

    return routes;
  }
};
