import React, { FC, useEffect } from 'react';
import { Divider } from '@endpoint/blockparty';
import { ConditionalGroup, TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import { useFormikContext } from 'formik';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { getUniqueStepComponentKey } from 'routes/TransactionDetail/Todo/TodoStep/helpers/getUniqueStepComponentKey';

import { TodoStepComponent } from '..';
import { isConditionalGroupSatisfied } from './helpers/isConditionalGroupSatisfied';
import { getAnswerWithoutConditionalFields } from './helpers/getAnswerWithoutConditionalFields';

interface TodoConditionalContentProps {
  conditionalGroup: ConditionalGroup;
  content: TodoAssignmentContent[];
}

export const TodoConditionalContent: FC<TodoConditionalContentProps> = ({ conditionalGroup, content }) => {
  const { values, setValues, setFieldValue } = useFormikContext<GenericObject>();
  const { handlePreviousSubmission } = useTodoStepContext();

  const shouldRenderContent = isConditionalGroupSatisfied(conditionalGroup, values);

  useEffect(() => {
    content.forEach(({ field }) => {
      if (field?.answer) {
        setFieldValue(field.id, field.answer);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldRenderContent || !Object.keys(values).length) {
      return;
    }

    const newValues = getAnswerWithoutConditionalFields(content, values);

    setValues(newValues);
  }, [content, setValues, shouldRenderContent, values]);

  if (!shouldRenderContent) {
    return <></>;
  }

  return (
    <>
      <Divider my="space70" />
      {content.map((element, index) => {
        return (
          <TodoStepComponent
            key={getUniqueStepComponentKey(element, index)}
            content={element}
            onPrevious={handlePreviousSubmission}
          />
        );
      })}
    </>
  );
};
