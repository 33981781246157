import { Box, EPSubHeader, Flex, Text, Button, useToast } from '@endpoint/blockparty';
import React from 'react';
import { CENTERED_CONTENT_WIDTH } from 'consts/responsive';
import { SubHeaderContainer } from 'components/SubHeader/SubHeaderContainer';
import { TodoAssignmentHeader, TodoButtonNavigation, TodoAssignment } from '@endpoint/endpoint-bff-graphql-schema';
import { useParams, useNavigate } from 'react-router';
import { useSaveTodoAssignmentStep } from 'hooks/useSaveTodoAssignmentStep';
import { AlertMessage } from 'components/AlertMessage';
import { FormikProps } from 'formik';
import { TODOS, TRANSACTION } from 'consts/routes';
import { TodoTrackingEvents } from 'consts/analytics';
import { TodoTrackingPayload } from 'hooks/useTodoAnalytics';
import { trackAction } from 'utils/analytics';
import { TodoStepId } from 'consts/enums';
import isEmpty from 'lodash/isEmpty';
import { useAppContext } from 'utils/context';

import { shouldSaveAnswer } from '../helpers/shouldSaveAnswer';
import { formatAnswer } from '../TodoStep/helpers/formatAnswer';

interface TodoHeaderProps {
  header?: TodoAssignmentHeader;
  formRef?: React.RefObject<FormikProps<GenericObject>>;
  todoAssignment?: TodoAssignment;
  userTransactionRoles?: Array<string>;
}

function TodoHeader({ header, formRef, todoAssignment, userTransactionRoles }: TodoHeaderProps) {
  const buttonLabel = 'Save and Exit';
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAppContext();
  const { todoId = '', transactionId = '', stepId = '' } = useParams();
  const { saveTodoAssignmentStep, saveTodoAssignmentStepLoading } = useSaveTodoAssignmentStep();

  const formatAnswerForSaveAndExit = () => {
    const { stepContent } = todoAssignment ?? {};
    const stepAnswer = formRef?.current?.values ?? {};

    if (stepId === TodoStepId.PAY_ONLINE && isEmpty(stepAnswer)) {
      return {};
    }

    const formattedAnswer = formatAnswer(stepId, stepAnswer);

    if (
      shouldSaveAnswer({
        direction: TodoButtonNavigation.NEXT,
        stepId,
        formattedAnswer,
        stepContent,
      })
    )
      return { answer: formattedAnswer };

    return {};
  };

  const handleSaveandExit = async () => {
    const todoTrackingPayload: TodoTrackingPayload = {
      transactionId,
      todoName: todoAssignment?.progressTracker?.name ?? 'Not Available',
      userId: user.id,
      userTransactionRoles,
    };

    trackAction(TodoTrackingEvents.TODO_SAVE_AND_EXIT, todoTrackingPayload);

    try {
      const response = await saveTodoAssignmentStep({
        variables: {
          input: {
            todoAssignmentId: todoId,
            currentStepId: stepId,
            ...formatAnswerForSaveAndExit(),
          },
        },
      });

      if (!response.errors) {
        const TODOS_URL = `/${TRANSACTION}/${transactionId}/${TODOS}`;

        navigate(TODOS_URL);
      }
    } catch (e) {
      toast({
        duration: 5000,
        position: 'top-right',
        render: ({ onClose }) => (
          <Box mr="space50" mt="space50">
            <AlertMessage isCloseable onClose={onClose}>
              <p>There was an error saving your to-do.</p>
            </AlertMessage>
          </Box>
        ),
      });
    }
  };

  return (
    <SubHeaderContainer>
      <EPSubHeader>
        <Flex
          alignItems="center"
          height={56}
          justifyContent="space-between"
          margin="0 auto"
          maxWidth={CENTERED_CONTENT_WIDTH}
          py="space30"
          width="100%"
        >
          <Box maxWidth={{ base: 191, md: '100%' }}>
            <Text>{header?.address}</Text>
          </Box>
          {header?.shouldRenderSaveButton && (
            <Button
              data-test-id="sub-header-todo-button"
              isDisabled={false}
              isLoading={saveTodoAssignmentStepLoading}
              loadingText="Saving..."
              variant="outline"
              variantColor="slate"
              onClick={handleSaveandExit}
            >
              {buttonLabel}
            </Button>
          )}
        </Flex>
      </EPSubHeader>
    </SubHeaderContainer>
  );
}

export default TodoHeader;
