import { useQuery } from '@apollo/client';
import { GetMarketsInput, Market } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { GET_MARKETS } from './queries';

export const useGetMarkets = (params?: GetMarketsInput) => {
  const sentryTransaction = useSentryTransaction('getMarkets', 'GraphQL client query');

  const { data, error, loading } = useQuery<{ getMarkets: Market[] }>(GET_MARKETS, {
    variables: { input: params },
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
    fetchPolicy: 'cache-and-network',
  });

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    markets: data?.getMarkets,
    error,
    loading,
  };
};
