import React from 'react';
import { Box, IconButton, Icon, Text } from '@endpoint/blockparty';

interface Props {
  onClose?: () => void;
  id: string;
  message: string;
}

const Toast = ({ onClose, id, message = '' }: Props) => {
  return (
    <Box
      alignItems="start"
      bg="watermelon0"
      borderRadius="radiusDefault"
      boxShadow="medium"
      display="flex"
      id={id}
      m="space50"
      maxWidth="80vw"
      p="space50"
      width="414px"
    >
      <Icon color="watermelon500" mr="space50" name="ErrorCircle" size="large" />
      <Box>
        <Text as="h2" color="carbon900" fontWeight="semi">
          Oops! Something went wrong.
        </Text>
        <Text as="p" color="carbon900">
          {message}
        </Text>
      </Box>
      <IconButton
        bg="transparent"
        border="none"
        color="carbon900"
        label="close"
        ml="auto"
        name="Close"
        variant="none"
        onClick={onClose}
      />
    </Box>
  );
};

export default Toast;
