import React, { FC } from 'react';
import { Box, Flex, Drawer, DrawerContent, DrawerActionBar, DrawerHeader, DrawerSection } from '@endpoint/blockparty';

interface DefinitionProps {
  header: string;
  description: string;
  isOpen: boolean;
  onClose: any;
}

export const Definition: FC<DefinitionProps> = ({ header, description, isOpen, onClose }) => {
  return (
    <Box>
      <Drawer id="transaction1234" isOpen={isOpen} onClose={onClose}>
        <DrawerContent>
          <DrawerActionBar />
          <Flex flex={1} flexDirection="column" overflow="auto">
            <DrawerHeader title={header} />
            <DrawerSection>{description}</DrawerSection>
          </Flex>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
