import { MilestoneStatus } from '@endpoint/endpoint-bff-graphql-schema';
import { Transaction } from '@endpoint/platform-api-connector/dist/graphql-types';

export function isPendingTransaction(transaction: Transaction): boolean {
  const milestoneStatus = transaction.milestones[0]?.status;

  return (
    milestoneStatus === MilestoneStatus.UPCOMING ||
    (!transaction.dates.estimatedClosing &&
      milestoneStatus !== MilestoneStatus.COMPLETE &&
      milestoneStatus !== MilestoneStatus.INTERMEDIATE)
  );
}

export function isTransactionPrelimPending(transaction: Transaction): boolean {
  return !!transaction.prelimPending;
}

export function isPrelistingOpenedAvailable(transaction: Transaction): boolean {
  return !!transaction.dates.prelistingOpened;
}

export function isTransactionContainAtLeastDateField(transaction: Transaction): boolean {
  const { dates } = transaction;

  return dates.titleProductDelivered || dates.prelistingOpened;
}

export function isTitleProductAvailable(transaction: Transaction): boolean {
  return !!transaction.dates.titleProductDelivered;
}

export function isClosingDateAvailable(transaction: Transaction): boolean {
  return !!transaction.dates.estimatedClosing;
}

export function sortByTitleProductDelivereDate(transactions: Transaction[]): Transaction[] {
  return transactions.sort(
    (a, b) => Date.parse(b.dates.titleProductDelivered) - Date.parse(a.dates.titleProductDelivered),
  );
}

export function sortByEstimatedClosing(transaction: Transaction[]): Transaction[] {
  return transaction.sort((a, b) => Date.parse(b.dates.estimatedClosing) - Date.parse(a.dates.estimatedClosing));
}

export function sortByPrelistingOpenedDate(transactions: Transaction[]): Transaction[] {
  return transactions.sort((a, b) => Date.parse(b.dates.prelistingOpened) - Date.parse(a.dates.prelistingOpened));
}

export function sortByPreListingOpened(transaction: Transaction[]): Transaction[] {
  return transaction.sort((a, b) => Date.parse(b.dates.prelistingOpened) - Date.parse(a.dates.prelistingOpened));
}
