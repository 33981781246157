import React, { FC } from 'react';
import { Box, Text } from '@endpoint/blockparty';
import { DataBlock, DataBlockProps, Label } from 'components/DataBlock';

interface CheckInstructionsPaymentDetailsProps {
  payableName?: string;
  streetAddress: string;
  suite: string;
  cityStateZip: string;
  layoutType?: DataBlockProps['layoutType'];
}

export const CheckInstructionsPaymentDetails: FC<CheckInstructionsPaymentDetailsProps> = ({
  streetAddress,
  suite,
  payableName,
  cityStateZip,
  layoutType = 'stack',
}) => {
  return (
    <DataBlock layoutType={layoutType}>
      <Box>
        <Label>How</Label>
        <Text as="p">Overnight Mail</Text>
      </Box>
      <Box>
        <Label>Where</Label>
        <Text as="p" data-test-id="check-payable-name">
          {payableName}
        </Text>
        <Text as="p" data-test-id="check-ep-street-address">
          {streetAddress}
        </Text>
        <Text as="p" data-test-id="check-ep-suite">
          {suite}
        </Text>
        <Text as="p" data-test-id="check-ep-city-state-zip">
          {cityStateZip}
        </Text>
      </Box>
    </DataBlock>
  );
};

CheckInstructionsPaymentDetails.displayName = 'CheckInstructionsPaymentDetails';
