import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { TodoButtonNavigation } from '@endpoint/endpoint-bff-graphql-schema';
import { Heading, Text, Box, RadioGroup, Radio, Flex, Button, useToast } from '@endpoint/blockparty';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { AlertMessage } from 'components/AlertMessage';
import { useSubmitTodoAssignmentStep } from 'hooks/useSubmitTodoAssignmentStep';
import { getTodoStepUrl } from 'routes/TransactionDetail/Todo/helpers/navigation';
import { trackEmdAnalytics } from 'routes/TransactionDetail/Todo/TodoStep/analytics/index';

export interface EMDErrorProps {
  validationError: boolean;
}

export const NON_ONLINE_PAYMENT_METHOD = 'non_online_payment_method';
export const PAYMENT_METHOD_OPTIONS = 'payment-method-options';
export const USE_OTHER_PAYMENT_METHOD = 'Use other payment method';

export const EMDError: FC<EMDErrorProps> = ({ validationError }) => {
  enum PaymentOptions {
    check = 'Pay with a check',
    wireTransfer = 'Pay using wire transfer',
  }

  const navigate = useNavigate();
  const toast = useToast();
  const { todoId = '', transactionId = '' } = useParams();
  const [paymentMethod, setPaymentMethod] = useState<PaymentOptions>();

  const { submitTodoAssignmentStep } = useSubmitTodoAssignmentStep();
  const { todoAssignment, setShowTodoStepNavigationPanel, getTodoAssignment } = useTodoStepContext();

  const handleRadioSelection = (value: PaymentOptions) => {
    setPaymentMethod(value);
  };

  const handleNextClick = async () => {
    try {
      const response = await submitTodoAssignmentStep({
        variables: {
          input: {
            todoAssignmentId: todoId,
            currentStepId: NON_ONLINE_PAYMENT_METHOD,
            direction: TodoButtonNavigation.NEXT,
            answer: { non_online_payment_method_choices: paymentMethod },
            hasSuccessPage: todoAssignment.hasSuccessPage,
            hasSummaryPage: todoAssignment.hasSummaryPage,
          },
        },
      });

      trackEmdAnalytics({
        todoName: todoAssignment.progressTracker.name || 'Not Available',
        answer: { non_online_payment_method_choices: paymentMethod },
      });

      const nextStepId = response.data?.submitTodoAssignmentStep?.todoAssignmentStepId;

      if (nextStepId) {
        const stepUrl = getTodoStepUrl({ transactionId, todoId, stepId: nextStepId });

        navigate(stepUrl);
        getTodoAssignment({ variables: { todoAssignmentId: todoId, stepId: nextStepId } });
      }

      setShowTodoStepNavigationPanel(true);
    } catch (e) {
      toast({
        duration: 5000,
        position: 'top-right',
        render: ({ onClose }) => (
          <Box mr="space50" mt="space50">
            <AlertMessage isCloseable onClose={onClose}>
              <p>There was an error updating your to-do.</p>
            </AlertMessage>
          </Box>
        ),
      });
    }
  };

  useEffect(() => {
    const submitPaymentMethodOptionsStepAnswer = async () => {
      try {
        await submitTodoAssignmentStep({
          variables: {
            input: {
              todoAssignmentId: todoId,
              currentStepId: PAYMENT_METHOD_OPTIONS,
              direction: TodoButtonNavigation.NEXT,
              answer: { payment_method_choice: USE_OTHER_PAYMENT_METHOD },
              hasSuccessPage: todoAssignment.hasSuccessPage,
              hasSummaryPage: todoAssignment.hasSummaryPage,
            },
          },
        });
      } catch (e) {
        toast({
          duration: 5000,
          position: 'top-right',
          render: ({ onClose }) => (
            <Box mr="space50" mt="space50">
              <AlertMessage isCloseable onClose={onClose}>
                <p>There was an error updating your to-do.</p>
              </AlertMessage>
            </Box>
          ),
        });
      }
    };

    void submitPaymentMethodOptionsStepAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todoAssignment, todoId, submitTodoAssignmentStep]);

  const errorMessage = validationError
    ? 'Unfortunately, our payment processor was unable to successfully verify your identity. Please select a different payment method.'
    : 'Unfortunately, we were unable to successfully initiate this transfer. Please select a different payment method.';

  return (
    <>
      <Heading as="h1" data-test-id="error-header" fontWeight="semi" mb="space70" size="fontSize50">
        {errorMessage}
      </Heading>
      <Text>We offer the following payment options: </Text>
      <Box mb="space70">
        <RadioGroup
          aria-label="select-new-payment-method"
          defaultValue={paymentMethod}
          mt="space60"
          name="select-new-payment-method"
          spacing="space50"
        >
          <Radio value={PaymentOptions.check} onChange={() => handleRadioSelection(PaymentOptions.check)}>
            Pay with a check
          </Radio>
          <Radio value={PaymentOptions.wireTransfer} onChange={() => handleRadioSelection(PaymentOptions.wireTransfer)}>
            Pay using wire transfer
          </Radio>
        </RadioGroup>
      </Box>
      <Box
        bg="background"
        bottom="0"
        boxShadow={{ base: 'medium', md: 'none' }}
        display="flex"
        flex={1}
        justifyContent="flex-end"
        left="0"
        mt={{ base: 'space0', md: 'space80' }}
        position={{ base: 'fixed', md: 'initial' }}
        px={{ base: 'space50', md: 'space0' }}
        py={{ base: 'space30', md: 'space0' }}
        right="0"
        width="100%"
      >
        <Flex justifyContent="flex-end">
          <Button isDisabled={!paymentMethod} onClick={handleNextClick}>
            Next
          </Button>
        </Flex>
      </Box>
    </>
  );
};
