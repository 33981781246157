import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { SubHeaderContainer } from 'components/SubHeader/SubHeaderContainer';
import { SubHeaderTransactions } from 'components/SubHeader/SubHeaderTransactions';
import { SubHeaderLoading } from 'components/Skeleton/SubheaderLoading';
import { isAgent, useAppContext } from 'utils/context';
import { USState } from 'utils/getSupportedUSState';

export const Transactions: FC = () => {
  const { user } = useAppContext();
  const isTexasUser = user.stateSignedUp === USState.TX;

  return (
    <>
      <SubHeaderContainer>
        {user.loading && <SubHeaderLoading />}
        {!user.loading && (
          <SubHeaderTransactions isNonAgent={!isAgent(user)} isTexasUser={isTexasUser} userFirstName={user.firstName} />
        )}
      </SubHeaderContainer>
      <Outlet />
    </>
  );
};
