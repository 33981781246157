import React, { FC } from 'react';
import { Box, Text } from '@endpoint/blockparty';
import { Label } from 'components/DataBlock';

interface CheckInstructionsMemoProps {
  streetAddress: string;
  cityStateZip: string;
}

export const CheckInstructionsMemo: FC<CheckInstructionsMemoProps> = ({ streetAddress, cityStateZip }) => {
  return (
    <Box bg="carbon0" borderRadius="radiusDefault" p="space50">
      <Box>
        <Label>Property</Label>
        <Text as="p" data-test-id="check-street-address">
          {streetAddress}
        </Text>
        <Text as="p" data-test-id="check-city-state-zip">
          {cityStateZip}
        </Text>
      </Box>
    </Box>
  );
};

CheckInstructionsMemo.displayName = 'CheckInstructionsMemo';
