export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const IDENTIFY_USER = 'IDENTIFY_USER';
export const SHOW_OAUTH_ALERT = 'SHOW_OAUTH_ALERT';

export const isWebView = () => 'ReactNativeWebView' in window;

export const pushDataToWebView = (userId: string, email: string) => {
  if (isWebView()) {
    const identifyUserData = {
      event: IDENTIFY_USER,
      payload: {
        userId,
        email,
      },
    };

    window?.ReactNativeWebView?.postMessage(JSON.stringify(identifyUserData));
  }
};
