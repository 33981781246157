import React from 'react';
import { Box, Text, EPContent } from '@endpoint/blockparty';

export const Security = () => {
  return (
    <>
      <EPContent>
        <Box>
          <Text as="h5" mb="space30" size="fontSize40">
            {/* Placeholder until designs are ready */}
            Security Content
          </Text>
        </Box>
      </EPContent>
    </>
  );
};
