enum ErrorCodes {
  CodeMismatchException = 'CodeMismatchException',
  CustomCodeResend = 'CustomCodeResend',
  LimitExceededException = 'LimitExceededException',
  NoError = 'NoError',
  NotAuthorizedException = 'NotAuthorizedException',
  SomethingWentWrong = 'SomethingWentWrong',
  SubmitError = 'SubmitError',
  UserNotFoundException = 'UserNotFoundException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UsernameExistsException = 'UsernameExistsException',
  SsoUserPasswordResetException = 'SsoUserPasswordResetException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidPasswordException = 'InvalidPasswordException',
  InvalidSignInPasswordException = 'InvalidSignInPasswordException',
  ForbiddenException = 'ForbiddenException',
}

enum MfaType {
  No_Mfa = 'NOMFA',
  Sms_Mfa = 'SMS_MFA',
}

enum ForgotType {
  password = 'password',
  email = 'email',
}

const RouteOptions = {
  NEW_TRANSACTIONS_IN_ESCROW: 'transactions/in-escrow',
  ACCOUNT_LOCKED: 'accountlocked',
  FORGOT_CREDENTIALS: 'forgotcredentials',
  FORGOT_EMAIL: 'forgotemail',
  FORGOT_PASSWORD: 'forgotpassword',
  UNVERIFIED_USER: 'unverifieduser',
  RESET_PASSWORD: 'resetpassword',
  RESET_SUCCESS_MFA: 'resetsuccessmfa',
  REQUEST_MFA: 'requestmfa',
  ROOT: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  EDIT_PHONE_NUMBER: 'editphonenumber',
  SELECT_ROLE: 'selectrole',
  NOTIFICATION_PREFERENCE: 'notificationpreference',
  SET_UP_PROFILE: 'setprofile',
  OUT_OF_COVERAGE: 'outofcoverage',
  NEW_ORDER_REPORTS: 'order-reports',
  NEW_SETTINGS_PROFILE: 'settings/profile',
  TODOS: 'todos',
};

export enum StrongPasswordType {
  All,
  Miminum,
  UpperCase,
  LowerCase,
  Number,
  SpecialCharacter,
}

const EMPTY_STRING = '';

const ACCOUNT_LOCKED_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.ACCOUNT_LOCKED}`;
const ACCOUNT_LOCKED_URL_SIGN_UP = `${RouteOptions.SIGN_UP}/${RouteOptions.ACCOUNT_LOCKED}`;
const FORGOT_CREDENTIALS_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.FORGOT_CREDENTIALS}`;
const FORGOT_EMAIL_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.FORGOT_EMAIL}`;
const FORGOT_PASSWORD_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.FORGOT_PASSWORD}`;
const REQUEST_MFA_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.REQUEST_MFA}`;
const RESET_SUCCESS_MFA_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.RESET_SUCCESS_MFA}`;
const UNVERIFIED_USER_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.UNVERIFIED_USER}`;
const RESET_PASSWORD_URL = `${RouteOptions.SIGN_IN}/${RouteOptions.RESET_PASSWORD}`;
const SIGN_UP_MFA_URL = `${RouteOptions.SIGN_UP}/${RouteOptions.REQUEST_MFA}`;
const SELECT_ROLE_URL = `${RouteOptions.SIGN_UP}/${RouteOptions.SELECT_ROLE}`;
const SET_UP_PROFILE_URL = `${RouteOptions.SIGN_UP}/${RouteOptions.SET_UP_PROFILE}`;
const NOTIFICATION_PREFERENCE_URL = `${RouteOptions.SIGN_UP}/${RouteOptions.NOTIFICATION_PREFERENCE}`;
const OUT_OF_COVERAGE_URL = `${RouteOptions.SIGN_UP}/${RouteOptions.OUT_OF_COVERAGE}`;

const CONFIRMATION_CODE_RE_ENTER = 'Please re-enter your 6-digit code';
const MIN_RESEND_CODE_INTERVAL_MS = 30000;

const TOTAL_ONBOARDING_STEPS = 3;

const NO_LEADING_AND_TRAILING_SPACES_REGEX = /^[^\s]+(\s+[^\s]+)*$/;

const PASSWORD_COMPLEXITY_ERROR = 'Your password has not met one or more of the criteria shown below.';

const PASSWORD_LENGTH = 12;

const PASSWORD_REGEX = /^(?!\s).*(?=.{12,}$)(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*[^\s]$/;

const ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_UP =
  'After 15 minutes has passed, please sign in with your email and password and try again. If you’d like to speak to our team, please contact us at ';

const ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_IN =
  'After 15 minutes has passed, please try again by clicking “Back to Sign In” below. If you’d like to speak to our team, please contact us at ';

const OPERATING_STATES = [
  { value: 'AZ', label: 'AZ' },
  { value: 'CA', label: 'CA' },
  { value: 'WA', label: 'WA' },
  { value: 'TX', label: 'TX' },
];

const US_STATES = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NB', label: 'NB' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];

export enum UnityRole {
  AGENT = 'agent',
  TRANSACTION_COORDINATOR = 'transactionCoordinator',
  BUYER = 'buyer',
  SELLER = 'seller',
  BORROWER = 'borrower',
}

export {
  ACCOUNT_LOCKED_URL,
  ACCOUNT_LOCKED_URL_SIGN_UP,
  ErrorCodes,
  EMPTY_STRING,
  MfaType,
  RouteOptions,
  ForgotType,
  FORGOT_CREDENTIALS_URL,
  FORGOT_EMAIL_URL,
  FORGOT_PASSWORD_URL,
  REQUEST_MFA_URL,
  RESET_SUCCESS_MFA_URL,
  RESET_PASSWORD_URL,
  SELECT_ROLE_URL,
  SET_UP_PROFILE_URL,
  UNVERIFIED_USER_URL,
  NOTIFICATION_PREFERENCE_URL,
  OUT_OF_COVERAGE_URL,
  MIN_RESEND_CODE_INTERVAL_MS,
  PASSWORD_COMPLEXITY_ERROR,
  PASSWORD_LENGTH,
  PASSWORD_REGEX,
  SIGN_UP_MFA_URL,
  ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_IN,
  ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_UP,
  OPERATING_STATES,
  US_STATES,
  TOTAL_ONBOARDING_STEPS,
  CONFIRMATION_CODE_RE_ENTER,
  NO_LEADING_AND_TRAILING_SPACES_REGEX,
};
