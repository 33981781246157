import { List, ListItem } from '@endpoint/blockparty';
import { ContentType, TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import React, { FC } from 'react';

import { TodoParagraph } from '../TodoParagraph';

export interface TodoOrderedListProps {
  list: Pick<TodoAssignmentContent, 'children'>;
}

export function renderListItem(element: TodoAssignmentContent) {
  if (element.nodeType === ContentType.PARAGRAPH) {
    return <TodoParagraph as="span" mb="space0" paragraph={element} />;
  }

  return <></>;
}

export const CompositeListItem = ({ item }: { item: TodoAssignmentContent }) => {
  return (
    <ListItem>
      {item.children.map((element) => {
        return <React.Fragment key={element.value}>{renderListItem(element)}</React.Fragment>;
      })}
    </ListItem>
  );
};

export const TodoOrderedList: FC<TodoOrderedListProps> = ({ list: { children } }) => {
  return (
    <List as="ol" listStyleType="decimal" mb="space60" spacing="space20">
      {children.map((item) => {
        const text = item.value;

        return <CompositeListItem key={text} item={item} />;
      })}
    </List>
  );
};
