import React, { FC } from 'react';
import { Box, Text } from '@endpoint/blockparty';
import { DataBlock, DataBlockProps, Label } from 'components/DataBlock';

interface CheckInstructionsMailToProps {
  paymentAmount: string;
  payableName: string;
  layoutType?: DataBlockProps['layoutType'];
}

export const CheckInstructionsMailTo: FC<CheckInstructionsMailToProps> = ({
  paymentAmount,
  payableName,
  layoutType = 'stack',
}) => {
  return (
    <DataBlock layoutType={layoutType}>
      <Box>
        <Label>Amount</Label>
        <Text as="p" data-test-id="check-amount">
          {paymentAmount}
        </Text>
      </Box>
      <Box>
        <Label>Payable to</Label>
        <Text as="p" data-test-id="check-payable-name">
          {payableName}
        </Text>
      </Box>
    </DataBlock>
  );
};

CheckInstructionsMailTo.displayName = 'CheckInstructionsMailTo';
