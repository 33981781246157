import React from 'react';
import { Box, Icon, Text } from '@endpoint/blockparty';

interface Props {
  id: string;
  header: string;
  message: string;
}

const GenericToast = ({ id, header = '', message = '' }: Props) => {
  return (
    <Box
      alignItems="start"
      bg="turquoise0"
      borderRadius="radiusDefault"
      boxShadow="medium"
      display="flex"
      id={id}
      m={{ base: 'space0', md: 'space50' }}
      maxWidth={{ base: '100vw', md: '80vw' }}
      px="space60"
      py="space50"
      width="414px"
    >
      <Icon color="turquoise500" mr="space50" name="Information" size="large" />
      <Box>
        <Text as="h2" color="carbon900" fontWeight="semi">
          {header}
        </Text>
        <Text as="p" color="carbon900" textAlign="left">
          {message}
        </Text>
      </Box>
    </Box>
  );
};

export default GenericToast;
