import React, { FC, ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorPage } from 'components/Error/500';

type ErrorBoundaryProps = {
  element: ReactElement;
};

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ element }) => {
  return <Sentry.ErrorBoundary fallback={<ErrorPage />}>{element}</Sentry.ErrorBoundary>;
};
