import React, { createContext, useContext, useState, Dispatch, SetStateAction, FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryLazyOptions } from '@apollo/client';
import { Box, Flex, useToast } from '@endpoint/blockparty';
import { TodoAssignment, TodoButtonNavigation } from '@endpoint/endpoint-bff-graphql-schema';
import { Form, Formik, FormikProps } from 'formik';
import { AlertMessage } from 'components/AlertMessage';
import { TodoSkeleton } from 'components/Skeleton';
import { useShowNativeOauthAlert } from 'hooks/useShowNativeOauthAlert';
import { useSubmitTodoAssignmentStep } from 'hooks/useSubmitTodoAssignmentStep';
import { TodoAssignmentQueryVariables } from 'hooks/useTodoAssignment/queries';
import { TODOS, TRANSACTION } from 'consts/routes';
import { QueryParam, TodoStepId } from 'consts/enums';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { getUniqueStepComponentKey } from 'routes/TransactionDetail/Todo/TodoStep/helpers/getUniqueStepComponentKey';

import { TodoStepComponent } from './TodoStepComponent';
import { TodoStepNavigationPanel } from './TodoStepNavigationPanel';
import { getTodoStepUrl } from '../helpers/navigation';
import { getValidationSchema } from '../helpers/validation';
import { formatAnswer } from './helpers/formatAnswer';
import { shouldSaveAnswer } from '../helpers/shouldSaveAnswer';
import { TodoSummary } from './TodoSummary';
import { trackEmdAnalytics } from './analytics/index';

export interface TodoStepContextType {
  todoAssignment: TodoAssignment;
  documentPreviewLoaded: boolean;
  isEsignDocumentSigned: boolean;
  setShowTodoStepNavigationPanel: Dispatch<SetStateAction<boolean>>;
  getTodoAssignment: (options?: QueryLazyOptions<TodoAssignmentQueryVariables>) => void;
  setDocumentPreviewLoaded: Dispatch<SetStateAction<boolean>>;
  setIsEsignDocumentSigned: Dispatch<SetStateAction<boolean>>;
  handlePreviousSubmission: (values?: GenericObject) => void;
  userTransactionRoles?: Array<string>;
}

export const NavigationPanel: FC = ({ children }) => (
  <Flex
    bg="background"
    bottom="0"
    boxShadow={{ base: 'medium', md: 'none' }}
    flex={1}
    justifyContent="flex-end"
    left="0"
    mt={{ base: 'space0', md: 'space80' }}
    position={{ base: 'fixed', md: 'initial' }}
    px={{ base: 'space50', md: 'space0' }}
    py={{ base: 'space30', md: 'space0' }}
    right="0"
    width="100%"
  >
    {children}
  </Flex>
);

export const TodoStepContext = createContext<TodoStepContextType>({
  todoAssignment: {
    hasSuccessPage: false,
    hasSummaryPage: false,
    progressTracker: { name: '', dueDate: '', estimatedTimeInMinutes: 0 },
    currentStepId: '',
    stepContent: [],
    navigationPanel: [],
    header: { address: '', shouldRenderSaveButton: false },
  },
  documentPreviewLoaded: false,
  isEsignDocumentSigned: false,
  setShowTodoStepNavigationPanel: () => {},
  getTodoAssignment: () => {},
  setDocumentPreviewLoaded: () => null,
  setIsEsignDocumentSigned: () => null,
  handlePreviousSubmission: () => {},
  userTransactionRoles: [],
});
export const useTodoStepContext = () => useContext(TodoStepContext);

interface TodoStepProps {
  todoAssignment: TodoAssignment;
  getTodoAssignment: (options?: QueryLazyOptions<TodoAssignmentQueryVariables>) => void;
  userTransactionRoles: Array<string>;
  formRef?: React.RefObject<FormikProps<GenericObject>>;
}

export const TodoStep = ({ todoAssignment, getTodoAssignment, userTransactionRoles, formRef }: TodoStepProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { todoId = '', transactionId = '', stepId = '' } = useParams();
  const [documentPreviewLoaded, setDocumentPreviewLoaded] = useState<boolean>(false);
  const [isEsignDocumentSigned, setIsEsignDocumentSigned] = useState<boolean>(false);
  const [showTodoStepNavigationPanel, setShowTodoStepNavigationPanel] = useState(true);
  const { submitTodoAssignmentStep, submitTodoAssignmentStepLoading } = useSubmitTodoAssignmentStep();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Show OAuth Alert on Mobile devices. Remove when work to support Plaid OAuth flow is complete.
  useShowNativeOauthAlert(stepId);

  const validationSchema = getValidationSchema(todoAssignment.stepContent);

  const { stepContent, navigationPanel, hasSuccessPage, hasSummaryPage, currentStepId, summary } = todoAssignment;

  if (submitTodoAssignmentStepLoading || isSubmitting) {
    return <TodoSkeleton />;
  }

  const handleFormSubmission = async (values: GenericObject, direction: TodoButtonNavigation) => {
    setIsSubmitting(true);
    const nextButton = navigationPanel[1];

    if (nextButton.isExitButton) {
      const TODOS_URL = `/${TRANSACTION}/${transactionId}/${TODOS}`;

      navigate(TODOS_URL);

      return;
    }

    try {
      const formattedAnswer = formatAnswer(currentStepId, values);

      const response = await submitTodoAssignmentStep({
        variables: {
          input: {
            todoAssignmentId: todoId,
            currentStepId: stepId,
            direction,
            ...(shouldSaveAnswer({ direction, stepId, formattedAnswer, stepContent })
              ? { answer: formattedAnswer }
              : {}),
            hasSuccessPage,
            hasSummaryPage,
          },
        },
      });

      if (!isEmpty(values)) {
        trackEmdAnalytics(
          omit(
            {
              todoName: todoAssignment.progressTracker.name || 'Not Available',
              answer: formattedAnswer,
            },
            ['social-security-number', 'account_number'],
          ),
        );
      }

      const nextStepId = response.data?.submitTodoAssignmentStep?.todoAssignmentStepId;

      if (nextStepId) {
        redirectToStep(nextStepId);
      }
    } catch (e) {
      toast({
        duration: 5000,
        position: 'top-right',
        render: () => (
          <Box mr="space50" mt="space50">
            <AlertMessage isCloseable>
              <p>There was an error updating your to-do.</p>
            </AlertMessage>
          </Box>
        ),
      });
    }

    setIsSubmitting(false);
  };

  const handleNextSubmission = (values?: GenericObject) => {
    void handleFormSubmission(values ?? {}, TodoButtonNavigation.NEXT);
  };

  const handlePreviousSubmission = (values?: GenericObject) => {
    void handleFormSubmission(values ?? {}, TodoButtonNavigation.PREVIOUS);
  };

  const handleCancelEditStep = () => {
    void redirectToStep(TodoStepId.SUMMARY);
  };

  const redirectToStep = (nextStepId: string, isFromSummary = false) => {
    const stepUrl = isFromSummary
      ? `${getTodoStepUrl({ transactionId, todoId, stepId: nextStepId })}?${QueryParam.EDIT_STEP}=true`
      : getTodoStepUrl({ transactionId, todoId, stepId: nextStepId });

    navigate(stepUrl);
    getTodoAssignment({ variables: { todoAssignmentId: todoId, stepId: nextStepId } });
  };

  const TodoStepContextValue: TodoStepContextType = {
    todoAssignment,
    documentPreviewLoaded,
    isEsignDocumentSigned,
    setShowTodoStepNavigationPanel,
    getTodoAssignment,
    setDocumentPreviewLoaded,
    setIsEsignDocumentSigned,
    handlePreviousSubmission,
    userTransactionRoles,
  };

  return (
    <section>
      <TodoStepContext.Provider value={TodoStepContextValue}>
        {!stepContent.length && summary ? (
          <TodoSummary summary={summary} onEdit={redirectToStep} onSubmit={handleNextSubmission} />
        ) : (
          <Formik
            initialValues={{}}
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={handleNextSubmission}
          >
            <Form>
              {stepContent.map((content, index) => {
                return (
                  <TodoStepComponent
                    key={getUniqueStepComponentKey(content, index)}
                    content={content}
                    onPrevious={handlePreviousSubmission}
                  />
                );
              })}
              {showTodoStepNavigationPanel && (
                <TodoStepNavigationPanel
                  currentStepId={currentStepId}
                  navigationPanel={navigationPanel}
                  validationSchema={validationSchema}
                  onCancelEdit={handleCancelEditStep}
                  onPrevious={handlePreviousSubmission}
                />
              )}
            </Form>
          </Formik>
        )}
      </TodoStepContext.Provider>
    </section>
  );
};
