import React, { FC } from 'react';
import { Box, Heading, Flex, Tag, Text, useDisclosure } from '@endpoint/blockparty';
import {
  MilestoneStatus,
  Milestone,
  TransactionRole,
  Transaction,
  TransactionType,
} from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatDate, MONTH_DAY_TIME } from 'utils/formatDate';
import { checkForPinnedItem } from 'utils/transactions/checkForPinnedItem';
import { trackAction } from 'utils/analytics';
import { TodoTrackingEvents } from 'consts/analytics';

import { PinnedItem } from '../../PinnedItem';
import { StatusValues } from '../index';

interface ProgressCarouselItemProps {
  currentMilestone: number;
  milestoneStep: number;
  description: string;
  statusValues: StatusValues;
  last?: boolean;
  milestone: Milestone;
  userRoles: TransactionRole[];
  transaction: Transaction;
}

/* ======================================= */

export const ProgressCarouselItem: FC<ProgressCarouselItemProps> = ({
  currentMilestone,
  milestoneStep,
  description,
  last,
  statusValues,
  milestone,
  userRoles,
  transaction,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { completedAt, id, name, status } = milestone;
  const statusCompleted = status === MilestoneStatus.COMPLETE;
  const statusInProgress = statusValues.colorVariant === 'inProgress';
  const isLastCompletedMilestone = statusCompleted && last;
  const completionDate = statusCompleted ? `${formatDate(completedAt, MONTH_DAY_TIME)}` : null;
  const hasPinnedItem = checkForPinnedItem({ userRoles, milestone, transaction });
  const itemName = React.useMemo(() => {
    if ((transaction.type?.toUpperCase() as TransactionType) === TransactionType.EQUITY) {
      return name.replaceAll('Seller', 'Borrower');
    }

    if ((transaction.type?.toUpperCase() as TransactionType) === TransactionType.REFINANCE) {
      return name.replaceAll('Buyer', 'Borrower');
    }

    return name;
  }, [name, transaction]);

  return (
    <>
      <Flex
        data-test-id={`milestone-step-${milestoneStep}`}
        flex="0 0 auto"
        flexDirection="column"
        justifyContent="space-between"
        p="space50"
        transform={`translateX(-${currentMilestone * 100}%)`}
        transition="normal"
        transitionProperty="transform"
        width="100%"
      >
        <Box>
          <Heading
            fontWeight={statusInProgress || isLastCompletedMilestone ? 'semi' : 'normal'}
            mb="space20"
            size="fontSize20"
          >
            {milestoneStep}. {itemName} {last && '🎉'}
          </Heading>
          <Text size="fontSize10">
            {description}
            {hasPinnedItem && (
              <>
                {' '}
                <PinnedItemButton onOpen={onOpen} />
              </>
            )}
          </Text>
        </Box>
        <Flex mt="space50">
          <Tag mr="space30" variant={statusCompleted ? 'solid' : 'outline'} variantColor={statusValues.colorVariant}>
            {statusValues.text}
          </Tag>

          <Text color="carbon500" data-test-id="milestone-completed-date" size="fontSize10">
            {completionDate}
          </Text>
        </Flex>
      </Flex>
      {hasPinnedItem && <PinnedItem id={id} isOpen={isOpen} milestoneStep={milestoneStep} onClose={onClose} />}
    </>
  );
};

export const PinnedItemButton: FC<{ onOpen: () => void }> = ({ onOpen }) => {
  return (
    <Box
      as="button"
      bg="transparent"
      border="none"
      color="blue500"
      cursor="pointer"
      data-test-id="pinned-item-button"
      textDecoration="underline"
      onClick={() => {
        onOpen && onOpen();
        trackAction(TodoTrackingEvents.CLICKED_PINNED_ITEM);
      }}
    >
      View
    </Box>
  );
};
