import React, { FC } from 'react';
import { Box, Text } from '@endpoint/blockparty';
import { SummaryField } from '@endpoint/endpoint-bff-graphql-schema';

import { getFieldSetAnswers } from './helpers/getFieldSetAnswers';

interface MultipleInputSummaryProps {
  field: SummaryField;
}

export const MultipleInputSummary: FC<MultipleInputSummaryProps> = ({ field }) => {
  return (
    <Box
      className={field.isSensitiveInformation ? 'fs-exclude dd-privacy-hidden' : ''}
      data-test-id="multiple-input-summary"
    >
      {(field.answer ?? []).map((fieldSet: GenericObject, index: number) => (
        // eslint-disable-next-line no-underscore-dangle
        <Box key={`field-set-${fieldSet.__ui_uuid}`} mt="space40">
          {field.answer.length > 1 && <Text as="p" mb="space30">{`${field.label} ${index + 1}`}</Text>}

          {getFieldSetAnswers(fieldSet).map(({ key, answer }) => (
            <Text key={key} as="p">
              {answer}
            </Text>
          ))}
        </Box>
      ))}
    </Box>
  );
};
