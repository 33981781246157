import React from 'react';
import { Box, CloseButton, Flex, Loading, ProgressBar, Text } from '@endpoint/blockparty';

import { UploadedFileProps } from '..';

export const Uploading = ({ uploadedFile }: { uploadedFile: UploadedFileProps }) => {
  return (
    <Box>
      <Flex
        alignItems="center"
        backgroundColor="white"
        border="1px solid"
        borderBottom="none"
        borderColor="carbon300"
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
        flexDirection="row"
        justifyContent="space-between"
        px="space50"
        py="20px"
      >
        <Box>
          <Loading data-test-id="loading-icon" mr="space50" />
          <Text>{uploadedFile.name}</Text>
        </Box>
        <CloseButton
          onClick={() => {
            uploadedFile.cancel && uploadedFile.cancel();
            uploadedFile.close && uploadedFile.close();
          }}
        />
      </Flex>
      <ProgressBar
        aria-label="document upload"
        borderBottomLeftRadius="4px"
        borderBottomRightRadius="4px"
        borderTopLeftRadius="radiusNone"
        borderTopRightRadius="radiusNone"
        completed={uploadedFile?.percentage || 0}
        steps={100}
      />
    </Box>
  );
};
