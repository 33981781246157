import React, { FC, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Stack } from '@endpoint/blockparty';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { getUniqueId } from 'utils/getUniqueId';

import { TodoStepComponent } from '..';
import { AddFieldSetButton } from './AddFieldSetButton';
import { FieldSetHeader } from './FieldSetHeader';
import { getEmptyFieldValues } from './helpers/getEmptyFieldValues';
import { getFieldSetWithNestedIds } from './helpers/getFieldSetWithNestedIds';

interface TodoMultipleInputProps {
  field: FieldOptions;
}

export const TodoMultipleInput: FC<TodoMultipleInputProps> = ({ field }) => {
  const { handlePreviousSubmission } = useTodoStepContext();
  const { values, setValues } = useFormikContext<GenericObject>();
  const groupValues = values[field.id];
  const name = field.label ?? '';

  useEffect(() => {
    if (field.answer) {
      setValues({ [field.id]: field.answer });

      return;
    }

    if (!groupValues) {
      // Seed the first field set if no values exist
      setValues({ [field.id]: [getEmptyFieldValues(field)] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!field.children?.length) {
    return <></>;
  }

  return (
    <FieldArray name={field.id}>
      {({ push, remove }) => (
        <>
          <Stack mt="space70" spacing="space60">
            {(groupValues ?? []).map((el: any, index: number) => {
              const fieldSet = getFieldSetWithNestedIds(field, index);

              return (
                // eslint-disable-next-line no-underscore-dangle
                <Stack key={el.__ui_uuid ?? ''} aria-label={name} as="fieldset" spacing="space0">
                  {index > 0 && (
                    <FieldSetHeader title={`${name} ${index + 1}`} onRemoveFieldSetClick={() => remove(index)} />
                  )}

                  {fieldSet.map((element) => {
                    return (
                      <TodoStepComponent
                        key={element.field?.id}
                        content={element}
                        onPrevious={handlePreviousSubmission}
                      />
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
          <AddFieldSetButton
            fieldSetName={name}
            onClick={() => {
              push({ __ui_uuid: getUniqueId(), ...getEmptyFieldValues(field) });
            }}
          />
        </>
      )}
    </FieldArray>
  );
};
