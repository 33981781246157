import { User } from '../context';

export function getUserFullName(user: Partial<User>): string {
  let result = '';

  if (user.entityName) return user.entityName.trim();
  if (user.firstName) result = user.firstName;
  if (user.middleName) result += ` ${user.middleName}`;
  if (user.lastName) result += ` ${user.lastName}`;
  if (user.suffix) result += ` ${user.suffix}`;

  return result.trim();
}
