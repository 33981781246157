import React, { FC } from 'react';
import { useCheckInstructions } from 'hooks/useCheckInstructions';
import { formatCurrency } from 'utils/formatCurrency';

import { TodoCheckInstructions } from '../TodoCheckInstructions';

interface EmdCheckInstructionsWidgetProps {
  amount?: string;
}

export const EmdCheckInstructionsWidget: FC<EmdCheckInstructionsWidgetProps> = ({ amount }) => {
  const { transactionEmdAmount, payableName, paymentAddress, transactionAddress, error, isLoading } =
    useCheckInstructions();

  let formattedAmount: string = '';

  if (amount) {
    const numericAmount = parseFloat(amount);

    formattedAmount = formatCurrency(numericAmount);
  } else if (transactionEmdAmount) {
    formattedAmount = formatCurrency(transactionEmdAmount);
  }

  const checkInstructions = { formattedAmount, payableName, paymentAddress, transactionAddress };

  return <TodoCheckInstructions checkInstructions={checkInstructions} error={error} isLoading={isLoading} />;
};
