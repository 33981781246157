import { TRANSACTION } from 'consts/routes';

interface GetTodoStepUrlProps {
  transactionId: string;
  todoId: string;
  stepId: string;
}

export const getTodoStepUrl = ({ transactionId, todoId, stepId }: GetTodoStepUrlProps) => {
  return `/${TRANSACTION}/${transactionId}/todo/${todoId}/step/${stepId}`;
};
