import { TodoButtonNavigation, TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import { TodoStepId } from 'consts/enums';

import { isAnswerEqual } from './isAnswerEqual';

interface ShouldSaveAnswerProps {
  direction: TodoButtonNavigation;
  stepId: string;
  formattedAnswer: GenericObject;
  stepContent?: TodoAssignmentContent[];
}

export const shouldSaveAnswer = ({ direction, stepId, formattedAnswer, stepContent }: ShouldSaveAnswerProps) => {
  const isStepWithNoAnswer = [TodoStepId.ABOUT, TodoStepId.SUMMARY].includes(stepId as TodoStepId);
  const isNavigatingBackward = direction === TodoButtonNavigation.PREVIOUS;
  const isEqualToPreviousAnswer = stepContent ? isAnswerEqual({ formattedAnswer, stepContent }) : false;

  return !isStepWithNoAnswer && !isNavigatingBackward && !isEqualToPreviousAnswer;
};
