import { gql } from '@apollo/client';

export const GET_TRANSACTION_CONTACTS = gql`
  query getTransactionContacts($transactionId: String!) {
    getTransactionContacts(transactionId: $transactionId) {
      buyers {
        id
        contactId
        firstName
        middleName
        lastName
        contactType
        roles
        entityName
      }
      sellers {
        id
        contactId
        firstName
        middleName
        lastName
        contactType
        roles
        entityName
      }
      buyerAgents {
        id
        contactId
        firstName
        middleName
        lastName
        contactType
        roles
        entityName
      }
      sellerAgents {
        id
        contactId
        firstName
        middleName
        lastName
        contactType
        roles
        entityName
      }
      closingTeam {
        firstName
        lastName
        title
        photoUrl
      }
    }
  }
`;
