import { useQuery } from '@apollo/client';
import { TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { TransactionsPayload, GET_TRANSACTIONS } from 'routes/Transactions/queries';

import { closedTransactionSorter } from './closedTransactionSorter';

export const useGetClosedTransactions = () => {
  const { data, loading, error } = useQuery<TransactionsPayload>(GET_TRANSACTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { where: { transactionStatus: TransactionStatus.CLOSED } },
  });

  return { data: data && closedTransactionSorter(data), loading, error };
};
