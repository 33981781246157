import React, { FC, useState } from 'react';
import { Text, Heading } from '@endpoint/blockparty';
import {
  DwollaKbaAnswerSelection,
  DwollaKbaSession,
  DwollaKbaVerification,
} from '@endpoint/endpoint-bff-graphql-schema';
import { NavigationButton } from 'components/form/NavigationButton';

import { KBAQuestion } from './kbaQuestion';
import { HEADING_TEXT, TEXT_CONTENT } from './const';

export interface KBAFormInput {
  kbaSession: DwollaKbaSession;
  verifyKBA: Function;
}

export const KBAForm: FC<KBAFormInput> = ({ kbaSession, verifyKBA }) => {
  const [loading, setIsLoading] = useState(false);
  const [answerSelections, setAnswerSelections] = useState<Array<DwollaKbaAnswerSelection>>([]);

  const handleAnswerSelection = (questionId: string, answerId: string) => {
    const answerSelection: DwollaKbaAnswerSelection = { questionId, answerId };

    const index = answerSelections.findIndex((x) => x.questionId === questionId);

    if (index >= 0) {
      answerSelections.splice(index, 1);
    }

    setAnswerSelections([...answerSelections, answerSelection]);
  };

  const submitKbaAnswers = () => {
    const kbaVerificationInput: DwollaKbaVerification = {
      sessionId: kbaSession.id,
      answerSelections,
    };

    setIsLoading(true);
    verifyKBA(kbaVerificationInput);
  };

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        {HEADING_TEXT}
      </Heading>
      <Text mb="space50">{TEXT_CONTENT}</Text>
      {kbaSession.questions.map((question) => (
        <KBAQuestion key={question.id} handleAnswerSelection={handleAnswerSelection} question={question} />
      ))}
      <NavigationButton
        data-test-id="navigation-button"
        goNext={() => submitKbaAnswers()}
        iconRight="ArrowChevronRight"
        isDisabled={answerSelections.length < kbaSession.questions.length}
        isLoading={loading}
      >
        Next
      </NavigationButton>
    </>
  );
};
