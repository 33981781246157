import { Address } from '@endpoint/platform-api-connector/dist/graphql-types';

export const formatAddress = (address: Address) => {
  const { streetAddress, cityStateZip } = getFormattedAddressParts(address);

  return `${streetAddress}, ${cityStateZip}`;
};

export const getFormattedAddressParts = (address: Address) => {
  const { street1, street2: unit, city, state, zip } = address;
  const street2 = unit ? ` ${unit}` : '';
  const streetAddress = `${street1}${street2}`;
  const cityStateZip = `${city}, ${state} ${zip}`;

  return { streetAddress, cityStateZip };
};
