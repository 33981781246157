import React, { FC } from 'react';
import { Box } from '@endpoint/blockparty';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';

interface PdfViewerProps extends BoxProps {
  source: string;
}

export const PdfViewer: FC<PdfViewerProps> = ({ source, ...rest }) => (
  <Box {...rest} data-test-id="pdf-viewer">
    <iframe
      height="100%"
      src={`${process.env.PUBLIC_URL}/pdfjs-2.7.570-es5-dist/web/viewer.html?file=${encodeURIComponent(source)}`}
      title="Preview file"
      width="100%"
    />
  </Box>
);

PdfViewer.displayName = 'PdfViewer';
