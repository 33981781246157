import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { REQUEST_MFA_URL, ErrorCodes, UNVERIFIED_USER_URL } from 'Auth/Utilities/Constant';
import { AppContext, AppContextType } from 'utils/context';
import { gql, useMutation } from '@apollo/client';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { getLegacyErrorCode } from 'Auth/Utilities/helper';
import useOrganizationId from 'hooks/useOrganizationId';
import { ForgotPasswordInput, ForgotPasswordResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { GenericTrackingEvents, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { useAuth } from '../useAuth';

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
      errorMessage
    }
  }
`;

export const useForgotPassword = () => {
  const { setData } = useAuth();
  const sentryTransaction = useSentryTransaction('forgotPassword', 'GraphQL client mutation');
  const { authFields, setAuthFields, setUser }: AppContextType = useContext(AppContext);
  const navigate = useNavigate();
  const [forgotPasswordMutation] = useMutation<
    { forgotPassword: ForgotPasswordResponse },
    { input: ForgotPasswordInput }
  >(FORGOT_PASSWORD_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  const organizationId = useOrganizationId();

  return async (email: string) => {
    try {
      setUser((user: any) => ({ ...user, loading: true }));
      setData('username', email);

      setAuthFields({
        ...authFields,
        errorCode: null,
        resetPassword: true,
        email,
      });

      const { data } = await forgotPasswordMutation({
        variables: {
          input: {
            username: email,
            organizationId,
          },
        },
      });

      if (!data?.forgotPassword?.success) {
        throw new Error(data?.forgotPassword?.errorMessage ?? 'An unknown error occurred');
      }

      navigate(REQUEST_MFA_URL);
    } catch (error: any) {
      const errorCode = getLegacyErrorCode(error?.message ?? error);

      if (errorCode === ErrorCodes.UserNotFoundException) {
        navigate(UNVERIFIED_USER_URL);
      } else {
        trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
        setAuthFields({ ...authFields, errorCode });
      }
    } finally {
      setUser((user: any) => ({ ...user, loading: false }));
    }
  };
};
