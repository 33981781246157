import { Button, Divider, Flex, Text } from '@endpoint/blockparty';
import React, { FC } from 'react';

interface FieldSetHeaderProps {
  title: string;
  onRemoveFieldSetClick: () => void;
}

export const FieldSetHeader: FC<FieldSetHeaderProps> = ({ title, onRemoveFieldSetClick }) => (
  <>
    <Divider mb="space40" />
    <Flex flexDirection="row" justifyContent="space-between" mb="space40">
      <Text fontWeight="semi" size="fontSize30">
        {title}
      </Text>
      <Button iconLeft="Close" size="small" variant="outline" onClick={onRemoveFieldSetClick}>
        Remove
      </Button>
    </Flex>
  </>
);
