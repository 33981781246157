import React, { FC, useEffect, useMemo, useState } from 'react';
import { BFF_CLIENT_NAME } from 'Apollo';
import { GetPlaidAccountResponse, DwollaCustomerInput, PlaidLinkToken } from '@endpoint/endpoint-bff-graphql-schema';
import { useParams } from 'react-router';
import { isContainsDwollaValidationError } from 'utils/dwollaErrorStatus';
import { ApolloError, OperationVariables, useQuery } from '@apollo/client';
import { Log } from 'utils/logger';
import { Flex, Loading } from '@endpoint/blockparty';
import { EMDTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import * as Sentry from '@sentry/react';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { useDwolla } from 'hooks/useDwolla';
import { GET_LINK_TOKEN } from 'hooks/useDwolla/queries';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';

import { GetNextStepInput, StepManager } from './helpers/StepManager';
import { Plaid } from './Steps/Plaid';
import { DwollaCustomerBuilder } from './helpers/DwollaCustomerBuilder';
import { ErrorLoadingStatesWidget } from '../ErrorLoadingStatesWidget';
import { EMDError } from './Steps/Error';

export const UNABLE_TO_VERIFY = 'user_validation_error';
export const ONLINE_PAYMENT_ERROR = 'online_payment_error';

export interface OnlinePaymentWidgetProps {
  value: string | undefined;
}

export const OnlinePaymentWidget: FC<OnlinePaymentWidgetProps> = (props) => {
  const { transactionId = '', todoId = '' } = useParams();
  const [emdAmount, setEMDAmount] = useState<number>(0);
  const { value } = props;
  const { setShowTodoStepNavigationPanel } = useTodoStepContext();

  useEffect(() => {
    setShowTodoStepNavigationPanel(false);
  }, [setShowTodoStepNavigationPanel]);

  const [nextStep, setNextStep] = useState<JSX.Element>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [dwollaCustomerUpsertData, setDwollaCustomerUpsertData] = useState<DwollaCustomerInput>();
  const [dwollaCustomerId, setDwollaCustomerId] = useState<string>('');
  const dwollaCustomerbuilder = useMemo(
    () => new DwollaCustomerBuilder(transactionId, todoId),
    [transactionId, todoId],
  );

  const stepManager = useMemo(() => new StepManager(transactionId, todoId, props), [props, todoId, transactionId]);
  const MAX_AMOUNT_WITH_NO_VERIFICATION_REQUIRED = 5000;

  const sentryTransaction = useSentryTransaction('getPlaidLinkToken', 'GraphQL client query');

  const {
    data: linkTokenData,
    loading: isPlaidLinkLoading,
    error: getLinkTokenError,
  } = useQuery<{ getPlaidLinkToken: PlaidLinkToken }, OperationVariables>(GET_LINK_TOKEN, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  sentryTransaction.finish();

  const {
    upsertDwollaCustomer,
    dwollaCustomerCreationResult,
    dwollaCustomerLoading,
    dwollaCustomerUpsertError,
    verifyKBA,
    kbaVerificationResults,
    kbaVerificationLoading,
    kbaVerificationError,
  } = useDwolla();

  // get EMD amount
  useEffect(() => {
    setEMDAmount(Number(value));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleUpsertDwollaCustomer = async (input: DwollaCustomerInput) => {
    setDwollaCustomerUpsertData(input);
    await upsertDwollaCustomer(input);
  };

  const handleErrors = (apolloError?: ApolloError | undefined, errorMessage?: string) => {
    if (apolloError || errorMessage) {
      const errorMessageToLog = apolloError ? `EMD error occured: ${JSON.stringify(apolloError)}` : errorMessage;

      Sentry.captureEvent({
        level: Sentry.Severity.Warning,
        message: errorMessageToLog,
      });

      Log(errorMessageToLog);

      setHasError(true);
    }
  };

  useEffect(() => {
    linkTokenData && stepManager.setLinkToken(linkTokenData?.getPlaidLinkToken?.linkToken);
  }, [linkTokenData, stepManager]);

  const setNextStepInput: GetNextStepInput = {
    emdAmount,
    upsertDwollaCustomer: handleUpsertDwollaCustomer,
    dwollaCustomerUpsertError,
    dwollaCustomerbuilder,
    verifyKBA,
    handleErrors,
    dwollaCustomerUpsertData,
  };

  const handleBankAccountInfoReceived = (getPlaidAccountResponse: GetPlaidAccountResponse | undefined) => {
    stepManager.sethandleBankAccountInfoReceived(handleBankAccountInfoReceived);
    stepManager.setAccountInfo(getPlaidAccountResponse);

    if (emdAmount < MAX_AMOUNT_WITH_NO_VERIFICATION_REQUIRED) {
      upsertDwollaCustomer(dwollaCustomerbuilder.build()).catch((e) => {
        handleErrors(undefined, e.message);
      });
    } else {
      setNextStep(stepManager.getNextStep(setNextStepInput));
    }
  };

  // kba question verification results
  if (!kbaVerificationLoading && !hasError) {
    if (kbaVerificationError) {
      handleErrors(kbaVerificationError);
    }

    /*
     * EP-4715 Because of the high priority, here is the quick fix for forever-loading after kba bug
     * Calling upsertDwollaCustomer causes component to re-render,
     * and when kbaVerificationResults are set - upsertDwollaCustomer was beeing called unconditionally every time, hence the bug.
     * We are missing types from dwolla, and it seems like we don't actually need to upsertDwollaCustomer again after KBA,
     * but that should be fixed in a refactoring phase, which is a must for this flow.
     * To fix the issue, we are checking if kba status matches dwolla customer status
     * if it does - we are not going to call upsertDwollaCustomer
     */
    if (
      kbaVerificationResults?.verifyKba?.status &&
      (dwollaCustomerCreationResult?.upsertDwollaCustomer?.status as string) !==
        (kbaVerificationResults?.verifyKba?.status as string)
    ) {
      upsertDwollaCustomer(dwollaCustomerbuilder.build()).catch((e) => {
        handleErrors(undefined, e.message);
      });
    }
    // EndFix EP-4715
  }

  // upload dwolla customer results
  if (!dwollaCustomerLoading) {
    if (!hasError) {
      if (dwollaCustomerUpsertError) {
        if (isContainsDwollaValidationError(dwollaCustomerUpsertError)) {
          return stepManager.getNextStep(setNextStepInput);
        }

        handleErrors(dwollaCustomerUpsertError);
      }

      if (dwollaCustomerCreationResult?.upsertDwollaCustomer) {
        stepManager.setDwollaCustomerStatus(dwollaCustomerCreationResult?.upsertDwollaCustomer?.status);

        const { kbaSession, id } = dwollaCustomerCreationResult?.upsertDwollaCustomer ?? {};

        if (id && !dwollaCustomerId) {
          trackAction(EMDTrackingEvents.DWOLLA_CUSTOMER_CREATED);
          setDwollaCustomerId(id);
        }

        if (kbaSession) stepManager.setKbaSession(kbaSession);

        return stepManager.getNextStep(setNextStepInput);
      }
    }
  }

  if (hasError || getLinkTokenError) {
    trackAction(EMDTrackingEvents.ONLINE_ACH_TRANSFER_FAILURE);

    return <EMDError validationError={false} />;
  }

  if (isPlaidLinkLoading || dwollaCustomerLoading || kbaVerificationLoading) {
    return <ErrorLoadingStatesWidget error={false} loading />;
  }

  return (
    nextStep || (
      <>
        <Plaid
          linkToken={linkTokenData?.getPlaidLinkToken?.linkToken!}
          todoAssignmentId={todoId}
          transactionId={transactionId}
          onAccountInfoReceived={handleBankAccountInfoReceived}
          onErrorReceived={handleErrors}
        />
        <Flex alignItems="center" justifyContent="center" m="space50">
          <Loading />
        </Flex>
      </>
    )
  );
};
