import { useMutation } from '@apollo/client';
import {
  CreateUnityContactInput,
  UnityContactWhereUniqueInput,
  UpdateUnityContactInput,
} from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import {
  CREATE_UNITY_CONTACT,
  CreateUnityContactData,
  CreateUnityContactVariables,
  UPDATE_UNITY_CONTACT,
  UpdateUnityContactData,
  UpdateUnityContactVariables,
} from './mutations';

export const useUnityContact = () => {
  const [
    createUnityContactMutation,
    { data: createUnityContactResult, loading: createUnityContactLoading, error: createUnityContactError },
  ] = useMutation<CreateUnityContactData, CreateUnityContactVariables>(CREATE_UNITY_CONTACT, {
    errorPolicy: 'all',
  });
  const [
    updateUnityContactMutation,
    { data: updateUnityContactResult, loading: updateUnityContactLoading, error: updateUnityContactError },
  ] = useMutation<UpdateUnityContactData, UpdateUnityContactVariables>(UPDATE_UNITY_CONTACT, {
    errorPolicy: 'all',
  });

  const sentryTransactionCreateUnityContact = useSentryTransaction('createUnityContact', 'GraphQL client mutation');
  const sentryTransactionUpdateUnityContact = useSentryTransaction('updateUnityContact', 'GraphQL client mutation');

  const createUnityContact = async (input: CreateUnityContactInput) => {
    await createUnityContactMutation({
      variables: { input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionCreateUnityContact.toTraceparent(),
        },
      },
    });

    sentryTransactionCreateUnityContact.finish();
  };

  const updateUnityContact = async (where: UnityContactWhereUniqueInput, input: UpdateUnityContactInput) => {
    await updateUnityContactMutation({
      variables: { where, input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionUpdateUnityContact.toTraceparent(),
        },
      },
    });

    sentryTransactionCreateUnityContact.finish();
  };

  return {
    createUnityContact,
    createUnityContactResult,
    createUnityContactLoading,
    createUnityContactError,
    updateUnityContact,
    updateUnityContactResult,
    updateUnityContactLoading,
    updateUnityContactError,
  };
};
