import React from 'react';
import { ContentType, TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import { Note } from 'components/Note';

import { DocumentPreviewWidget } from './DocumentPreviewWidget';
import { TodoRadioGroup } from './TodoRadioGroup';
import { TodoTextInput } from './TodoTextInput';
import { TodoTextArea } from './TodoTextArea';
import { BuyersWidget } from './BuyersWidget';
import { SellersWidget } from './SellersWidget';
import { Acknowledgement } from './Acknowledgement';
import { AmountWidget } from './AmountWidget';
import { ClosingCheckInstructionsWidget } from './CheckInstructions/ClosingCheckInstructionsWidget';
import { EmdCheckInstructionsWidget } from './CheckInstructions/EmdCheckInstructionsWidget';
import { ClosingWireInstructionsWidget } from './WireInstructions/ClosingWireInstructionsWidget';
import { EmdWireInstructionsWidget } from './WireInstructions/EmdWireInstructionsWidget';
import { MobileCheckDeposit } from './MobileCheckDeposit';
import { MobileCheckDepositWidget } from './MobileCheckDepositWidget';
import { OnlinePaymentWidget } from './OnlinePaymentWidget';
import { OnlinePayment } from './OnlinePayment';
import { TodoDatePicker } from './TodoDatePicker';
import { TodoDateTimePicker } from './TodoDateTimePicker';
import { TodoSSN } from './TodoSSN';
import { TodoPhoneInput } from './TodoPhoneInput';
import { TodoImage } from './TodoImage';
import { TodoCheckbox } from './TodoCheckbox';
import { TodoParagraph } from './TodoParagraph';
import { TodoUnorderedList } from './TodoUnorderedList';
import { TodoOrderedList } from './TodoOrderedList';
import { EsignWidget } from './EsignWidget';
import { TodoConditionalContent } from './TodoConditionalContent';
import { TodoMultipleInput } from './TodoMultipleInput';
import { TodoDropdown } from './TodoDropdown';
import { TodoFileUploader } from './TodoFileUploader';
import { TodoPercentInput } from './TodoPercentInput';
import { TodoMoneyInput } from './TodoMoneyInput';
import { TodoFixedSum } from './TodoFixedSum';
import { TodoHeading } from './TodoHeading';
import { PaymentOptionsRadioGroup } from './PaymentOptionsRadioGroup';
import { TodoNumberInput } from './TodoNumberInput';

interface TodoStepComponentProps {
  content: TodoAssignmentContent;
  onPrevious: () => void;
}

export const TodoStepComponent = ({
  content: { nodeType, value, children, textMarkup, field, image, conditionalGroup },
  onPrevious,
}: TodoStepComponentProps) => {
  switch (nodeType) {
    case ContentType.HEADING:
    case ContentType.HEADING_2:
    case ContentType.HEADING_3:
      return <TodoHeading contentType={nodeType} value={value} />;
    case ContentType.PARAGRAPH:
      return <TodoParagraph paragraph={{ children, textMarkup, value }} />;
    case ContentType.NOTE:
      return <Note mb="space70">{value}</Note>;
    case ContentType.AMOUNT:
      return <AmountWidget value={value} />;
    case ContentType.IMAGE_URL:
      return image ? <TodoImage image={image} /> : <></>;
    case ContentType.BUYERS_WIDGET:
      return <BuyersWidget />;
    case ContentType.DOCUMENT_PREVIEW:
      return <DocumentPreviewWidget documentId={value} />;
    case ContentType.DROPDOWN:
      return field ? <TodoDropdown field={field} /> : <></>;
    case ContentType.ACKNOWLEDGEMENT:
      return field ? <Acknowledgement field={field} /> : <></>;
    case ContentType.SELLERS_WIDGET:
      return <SellersWidget />;
    case ContentType.CLOSING_FUNDS_CHECK_INSTRUCTIONS:
      return <ClosingCheckInstructionsWidget amount={value ?? ''} />;
    case ContentType.EMD_CHECK_INSTRUCTIONS:
      return <EmdCheckInstructionsWidget amount={value} />;
    case ContentType.EMD_WIRE_INSTRUCTIONS_US:
      return <EmdWireInstructionsWidget amount={value ?? ''} />;
    case ContentType.EMD_WIRE_INSTRUCTIONS_INT:
      return <EmdWireInstructionsWidget amount={value ?? ''} isInternational />;
    case ContentType.CLOSING_FUNDS_WIRE_INSTRUCTIONS_US:
      return <ClosingWireInstructionsWidget amount={value ?? ''} />;
    case ContentType.CLOSING_FUNDS_WIRE_INSTRUCTIONS_INT:
      return <ClosingWireInstructionsWidget amount={value ?? ''} isInternational />;
    case ContentType.UNORDERED_LIST:
    case ContentType.UNORDERED_LIST_WITH_ICON:
      return <TodoUnorderedList list={{ children }} />;
    case ContentType.ORDERED_LIST:
      return <TodoOrderedList list={{ children }} />;
    case ContentType.RADIO:
      return field ? <TodoRadioGroup field={field} /> : <></>;
    case ContentType.PAYMENT_OPTIONS_RADIO_GROUP:
      return field ? <PaymentOptionsRadioGroup field={field} /> : <></>;
    case ContentType.TEXT_INPUT:
      return field ? <TodoTextInput field={field} /> : <></>;
    case ContentType.TEXT_AREA:
      return field ? <TodoTextArea field={field} /> : <></>;
    case ContentType.MOBILE_CHECK_DEPOSIT:
      return <MobileCheckDeposit value={value} />;
    case ContentType.MOBILE_CHECK_DEPOSIT_WIDGET:
      return <MobileCheckDepositWidget value={value} />;
    case ContentType.ONLINE_PAYMENT:
      return <OnlinePaymentWidget value={value} />;
    case ContentType.ONLINE_PAYMENT_WIDGET:
      return <OnlinePayment value={value} />;
    case ContentType.DATE_PICKER:
      return field ? <TodoDatePicker field={field} /> : <></>;
    case ContentType.DATE_TIME_PICKER:
      return field ? <TodoDateTimePicker field={field} param={value} /> : <></>;
    case ContentType.SSN:
      return field ? <TodoSSN field={field} /> : <></>;
    case ContentType.PHONE:
      return field ? <TodoPhoneInput field={field} /> : <></>;
    case ContentType.CHECKBOX:
      return field ? <TodoCheckbox field={field} /> : <></>;
    case ContentType.ESIGN_DOCUMENT:
    case ContentType.QUESTION_AND_ANSWER_ESIGN:
      return field ? <EsignWidget field={field} onPrevious={onPrevious} /> : <></>;
    case ContentType.CONDITIONAL_FORM:
      return conditionalGroup ? (
        <TodoConditionalContent conditionalGroup={conditionalGroup} content={children} />
      ) : (
        <></>
      );
    case ContentType.MULTIPLE:
      return field ? <TodoMultipleInput field={field} /> : <></>;
    case ContentType.FILE_UPLOADER:
      return field ? <TodoFileUploader field={field} /> : <></>;
    case ContentType.PERCENT_INPUT:
      return field ? <TodoPercentInput field={field} /> : <></>;
    case ContentType.MONEY_INPUT:
      return field ? <TodoMoneyInput field={field} /> : <></>;
    case ContentType.FIXED_SUM_INPUT:
      return field ? <TodoFixedSum field={field} /> : <></>;
    case ContentType.NUMBER:
      return field ? <TodoNumberInput field={field} /> : <></>;
    default:
      return <></>;
  }
};
