import { trackAction } from 'utils/analytics';
import { TodoEMDAnswerKeys, EMDTrackingEvents } from 'consts/analytics';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

export const trackEmdAnalytics = (analyticPayload: GenericObject) => {
  const answerKey = Object.keys(analyticPayload.answer)[0];
  const requiredKeys = ['depositId', 'EMDAmount'];

  switch (answerKey) {
    case TodoEMDAnswerKeys.INTEREST_BEARING_OPTION:
      if (includes(analyticPayload.answer[answerKey], 'Yes, create the account')) {
        trackAction(EMDTrackingEvents.EMD_INTEREST_BEARING_ACCOUNT, analyticPayload);
      }

      break;
    case TodoEMDAnswerKeys.PAYMENT_METHOD_CHOICE:
      if (includes(toLower(analyticPayload.answer[answerKey]), 'pay online')) {
        trackAction(EMDTrackingEvents.EMD_PAY_ONLINE, analyticPayload);
      }

      if (includes(toLower(analyticPayload.answer[answerKey]), 'wire transfer')) {
        trackAction(EMDTrackingEvents.EMD_PAY_BY_WIRE, analyticPayload);
      }

      if (includes(toLower(analyticPayload.answer[answerKey]), 'check via mobile phone')) {
        trackAction(EMDTrackingEvents.EMD_PAY_BY_MOBILE, analyticPayload);
      }

      if (includes(toLower(analyticPayload.answer[answerKey]), 'check via overnight mail')) {
        trackAction(EMDTrackingEvents.EMD_PAY_BY_CHECK, analyticPayload);
      }

      if (includes(toLower(analyticPayload.answer[answerKey]), 'payment has already been submitted')) {
        trackAction(EMDTrackingEvents.EMD_PAY_ALREADY_SUBMITTED, analyticPayload);
      }

      break;
    case TodoEMDAnswerKeys.NON_ONLINE_PAYMENT_METHOD_CHOICES:
      if (includes(analyticPayload.answer[answerKey], 'Pay using wire transfer')) {
        trackAction(EMDTrackingEvents.EMD_PAY_BY_WIRE, analyticPayload);
      }

      if (includes(analyticPayload.answer[answerKey], 'Pay with a check')) {
        trackAction(EMDTrackingEvents.EMD_PAY_BY_CHECK, analyticPayload);
      }

      break;
    case TodoEMDAnswerKeys.DWOLLA_DEPOSIT:
      if (requiredKeys.every((key) => includes(Object.keys(analyticPayload.answer), key))) {
        trackAction(EMDTrackingEvents.ONLINE_ACH_TRANSFER_SUCCESSFUL, analyticPayload);
        break;
      }

      trackAction(EMDTrackingEvents.ONLINE_ACH_TRANSFER_FAILURE, analyticPayload);
      break;
    default:
      break;
  }
};
