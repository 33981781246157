import React, { FC } from 'react';

import { SignUpLink } from './SignUpLink';

export const UserNotFoundError: FC = () => (
  <>
    {'Sorry, we could not find an account associated with this email. '}
    <SignUpLink color="watermelon500" />
  </>
);
