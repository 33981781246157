import { BFF_CLIENT_NAME } from 'Apollo';
import { useParams } from 'react-router-dom';
import { GET_SIGNATURE_URL } from 'routes/Transactions/queries';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { GetSignUrlInput, SignUrl } from '@endpoint/endpoint-bff-graphql-schema';
import { useLazyQuery } from '@apollo/client';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

export const useSignatureUrl = (todoAssignmentId: string) => {
  const { transactionId } = useParams();
  const sentryTransaction = useSentryTransaction('getSignUrl', 'GraphQL client query');

  const input: GetSignUrlInput = { todoAssignmentId, transactionId };

  const [getSignUrl, { data, error, loading }] = useLazyQuery<{ getSignUrl: SignUrl }, { input: GetSignUrlInput }>(
    GET_SIGNATURE_URL,
    {
      variables: { input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
      },
      fetchPolicy: 'network-only',
    },
  );

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    signatureUrl: data?.getSignUrl.signUrl,
    esignError: error,
    isLoading: loading,
    getSignUrl,
  };
};
