import React, { FC, useEffect } from 'react';
import { Image } from '@endpoint/blockparty';
import * as Sentry from '@sentry/react';
import { useGetTodoAssignmentDocuments } from 'hooks/useGetTodoAssignmentDocuments';
import { useParams } from 'react-router';
import { useAppContext } from 'utils/context';
import { PdfViewer } from 'components/PdfViewer';

import { useTodoStepContext } from '../..';
import { ErrorLoadingStatesWidget } from '../ErrorLoadingStatesWidget';

interface DocumentPreviewWidgetProps {
  documentId?: string;
}

export const DocumentPreviewWidget: FC<DocumentPreviewWidgetProps> = ({ documentId }) => {
  const { todoId = '', transactionId = '' } = useParams();

  const { setDocumentPreviewLoaded } = useTodoStepContext();
  const { user } = useAppContext();

  const { todoAssignmentDocuments, todoAssignmentDocumentsLoading, todoAssignmentDocumentsError } =
    useGetTodoAssignmentDocuments(transactionId, todoId);

  useEffect(() => {
    setDocumentPreviewLoaded(!todoAssignmentDocumentsError && !todoAssignmentDocumentsLoading);
  }, [todoAssignmentDocumentsLoading, todoAssignmentDocumentsError, setDocumentPreviewLoaded]);

  todoAssignmentDocumentsError &&
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `User ${
        user.id
      } | Transaction ${transactionId} | Todo ${todoId}. There's an error trying to retrieve the assignment documents. error: ${JSON.stringify(
        todoAssignmentDocumentsError,
      )}`,
    });

  const filteredDocument = todoAssignmentDocuments?.filter((document) =>
    documentId ? document.id === documentId : true,
  )[0];

  const isPdf = filteredDocument?.mimeType?.includes('pdf');
  const documentUrl = filteredDocument?.url;

  const documentUrlNotPresent = !documentUrl && !todoAssignmentDocumentsLoading && !todoAssignmentDocumentsError;

  documentUrlNotPresent &&
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `User ${user.id} | Transaction ${transactionId} | Todo ${todoId}| Document ${
        documentId ?? filteredDocument?.id
      }'s url is missing.`,
    });

  if (todoAssignmentDocumentsLoading || todoAssignmentDocumentsError || documentUrlNotPresent) {
    return (
      <ErrorLoadingStatesWidget
        error={documentUrlNotPresent || todoAssignmentDocumentsError}
        loading={todoAssignmentDocumentsLoading}
      />
    );
  }

  return isPdf ? (
    <PdfViewer
      className="fs-exclude dd-privacy-hidden"
      height={{ base: '80vh', md: 'calc(100vh - 310px)' }}
      maxHeight="800px"
      source={documentUrl || ''}
    />
  ) : (
    <Image
      alt="Preview file"
      className="fs-exclude dd-privacy-hidden"
      display="block"
      height={{ base: '80vh', md: 'calc(100vh - 310px)' }}
      margin="0 auto"
      maxHeight="800px"
      maxWidth="100%"
      src={documentUrl || ''}
    />
  );
};
