import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { RadioProps } from '@endpoint/blockparty/dist/components/Radio';
import { Radio } from '@endpoint/blockparty';

interface Props {
  field: FieldProps['field'];
  form: FieldProps['form'];
  onRadioChange: Function;
}

export const FormikRadio: FC<RadioProps & Props> = ({ field, form, onRadioChange, ...props }) => {
  const { name } = field;
  const { setFieldValue } = form;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onRadioChange) onRadioChange.apply({ field, form, e });
    setFieldValue(name, e.target.value);
  };

  return <Radio mb={{ base: 'space70', md: 'space50' }} onChange={onChange} {...props} />;
};
