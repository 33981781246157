import { gql } from '@apollo/client';
import { PaymentInstructions } from '@endpoint/endpoint-bff-graphql-schema';

export interface PayableToNamePayload {
  getPaymentInstructions: Pick<PaymentInstructions, 'payableToName'>;
}

export const GET_PAYABLE_TO_NAME = gql`
  query getPaymentInstructions($input: GetPaymentInstructionsInput!) {
    getPaymentInstructions(input: $input) {
      payableToName
    }
  }
`;
