import React, { FC, useContext } from 'react';
import { AlertMessage } from 'components/AlertMessage';
import { Box, Text } from '@endpoint/blockparty';
import { CompanyContext } from 'utils/context';

import { getCompanyPhoneNumber } from '../../../../../utils/getCompanyPhoneNumber';
import { formatPhone } from '../../../../../utils/formatPhone';

const PinnedItemCustomMessage: FC = () => {
  const { companyName } = useContext(CompanyContext);

  return (
    <Box data-test-id="pinned-item-error-text">
      <Text>Please try to refresh the page. If the problem persists, please call {companyName} for assistance at</Text>{' '}
      <Text as="a" href={`tel:+1${getCompanyPhoneNumber(companyName).technicalSupport}`}>
        {formatPhone(getCompanyPhoneNumber(companyName).technicalSupport)}
      </Text>{' '}
      <Text>or use the chat icon to message us directly.</Text>
    </Box>
  );
};

export const PinnedItemErrorMessage: FC = () => (
  <Box m="space60">
    <AlertMessage description=" " title="Oops! Something went wrong">
      <PinnedItemCustomMessage />
    </AlertMessage>
  </Box>
);
