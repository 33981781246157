import React, { FC, useEffect, useContext } from 'react';
import { trackAction } from 'utils/analytics';
import { ChatEvents } from 'consts/analytics';
import { AppContext, AppContextType } from 'utils/context';
import { useGetZendeskToken } from 'hooks/useGetZendeskToken';
import * as Sentry from '@sentry/react';

interface ZendeskChatProps {
  appId: string;
}

export const ZendeskChat: FC<ZendeskChatProps> = ({ appId }) => {
  const { user, setError }: AppContextType = useContext(AppContext);

  const { data: zendeskJWT, error: userError } = useGetZendeskToken(user.email!);

  useEffect(() => {
    initZendeskSettings(appId, zendeskJWT);
    loadChatSnippet(appId);
    const pollZopim = setInterval(() => {
      if (window.$zopim && window.$zopim.livechat) {
        clearInterval(pollZopim);
        chatBoxEvents();
      }
    }, 340);

    () => {
      if (window.$zopim) {
        delete window.$zopim;
      }

      if (window.zESettings) {
        delete window.zESettings;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, zendeskJWT]);

  useEffect(() => {
    if (userError && setError) {
      setError(userError);
    }
  }, [userError, setError]);

  function initZendeskSettings(zendeskAppId: string, zendeskToken: string | undefined) {
    if (!zendeskAppId) {
      console.log('Missing Zopim App ID. Please add it.');

      return;
    }

    if (!zendeskToken) {
      return;
    }

    if (!window.zESettings) {
      window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: (callback: Function) => {
                callback(zendeskToken);
              },
            },
          },
        },
      };
    }
  }

  function loadChatSnippet(zendeskAppId: string) {
    const id = 'ze-snippet';
    const fjs = document.getElementsByTagName('script')[0];
    const exists = document.getElementById(id);

    if (exists) return;

    const js = document.createElement('script');

    js.id = 'ze-snippet';
    js.type = 'text/javascript';
    js.async = !0;
    js.src = `//static.zdassets.com/ekr/snippet.js?key=${zendeskAppId}`;

    js.onerror = () => {
      console.log('failed to load zendesk snippet');
    };

    fjs?.parentNode?.appendChild(js);
  }

  function chatBoxEvents() {
    try {
      if (!!window.$zopim && typeof window.$zopim === 'function') {
        window.$zopim(() => {
          window.$zopim.livechat.button.hide();

          window.$zopim.livechat.window.onHide(() => {
            window.$zopim.livechat.button.hide();
            trackAction(ChatEvents.HIDE_CHAT);
          });

          window.$zopim.livechat.setOnChatStart(() => trackAction(ChatEvents.START_CHAT));

          window.$zopim.livechat.setOnChatEnd(() => {
            trackAction(ChatEvents.END_CHAT);
          });

          window.$zopim.livechat.setOnUnreadMsgs(() => {
            window.$zopim.livechat.button.show();
            window.$zopim.livechat.window.show();
          });
        });
      }
    } catch (e) {
      Sentry.captureEvent({
        level: Sentry.Severity.Warning,
        message: `Zendesk window.$zopim error: ${e}`,
      });
    }
  }

  return <div />;
};
