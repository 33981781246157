import React, { FC } from 'react';
import { useCheckInstructions } from 'hooks/useCheckInstructions';
import { formatCurrency } from 'utils/formatCurrency';

import { TodoCheckInstructions } from '../TodoCheckInstructions';

interface ClosingCheckInstructionsWidgetProps {
  amount: string;
}

export const ClosingCheckInstructionsWidget: FC<ClosingCheckInstructionsWidgetProps> = ({ amount }) => {
  const { payableName, paymentAddress, transactionAddress, error, isLoading } = useCheckInstructions();

  const numericAmount = parseFloat(amount);
  const formattedAmount = formatCurrency(numericAmount);
  const checkInstructions = { formattedAmount, payableName, paymentAddress, transactionAddress };

  return <TodoCheckInstructions checkInstructions={checkInstructions} error={error} isLoading={isLoading} />;
};
