import React from 'react';
import { ContentType, SummaryField, TodoAssignmentSummary } from '@endpoint/endpoint-bff-graphql-schema';
import { Box, Button, Divider, Flex, Heading, Text } from '@endpoint/blockparty';
import { useTodoAnalytics } from 'hooks/useTodoAnalytics';
import { trackAction } from 'utils/analytics';
import { TodoTrackingEvents } from 'consts/analytics';

import { SummaryFixedSum } from './SummaryFixedSum';
import { MultipleInputSummary } from './MultipleInputSummary';
import { SummaryFileUploader } from './SummaryFileUploader';
import { GeneralPurposeSummary } from './GeneralPurposeSummary';
import { NavigationPanel } from '..';

interface TodoSummaryProps {
  summary: TodoAssignmentSummary;
  onEdit: (stepId: string, isFromSummary?: boolean) => void;
  onSubmit: () => void;
}

function renderSummaryField(field: SummaryField) {
  switch (field.type) {
    case ContentType.DROPDOWN:
    case ContentType.PHONE:
    case ContentType.RADIO:
    case ContentType.SSN:
    case ContentType.TEXT_AREA:
    case ContentType.TEXT_INPUT:
    case ContentType.DATE_PICKER:
    case ContentType.ESIGN_DOCUMENT:
    case ContentType.MONEY_INPUT:
    case ContentType.PERCENT_INPUT:
    case ContentType.NUMBER:
      return <GeneralPurposeSummary field={field} />;

    case ContentType.DATE_TIME_PICKER: {
      const answer = field.answer;

      return (
        <>
          <Flex data-test-id="date-time-value" flexDirection="column">
            <Text data-test-id="summary-field-answer">{answer.date}</Text>
            <Text data-test-id="summary-field-answer">{answer.time}</Text>
          </Flex>
        </>
      );
    }

    case ContentType.FILE_UPLOADER:
      return <SummaryFileUploader field={field} />;
    case ContentType.MULTIPLE:
      return <MultipleInputSummary field={field} />;
    case ContentType.FIXED_SUM_INPUT:
      return <SummaryFixedSum field={field} />;
    default:
      return <></>;
  }
}

export const TodoSummary = ({ summary: { fields, summaryPageHeading }, onEdit, onSubmit }: TodoSummaryProps) => {
  const { todoTrackingPayload } = useTodoAnalytics();

  const handleSubmitButtonClick = () => {
    trackAction(TodoTrackingEvents.TODO_SUBMITTED, todoTrackingPayload);
    onSubmit();
  };

  const handleEditButtonClick = (stepId: string) => {
    const isFromSummary = true;

    trackAction(TodoTrackingEvents.TODO_EDIT, todoTrackingPayload);
    onEdit(stepId, isFromSummary);
  };

  return (
    <Box>
      <Heading as="h1" mb="space70" size="fontSize50">
        {summaryPageHeading}
      </Heading>

      {fields
        .filter((field) => field.type !== ContentType.UNKNOWN)
        .map((field) => {
          return (
            <Box key={`${field.id}-${field.stepId}`} mb="space60">
              <Divider />
              <Flex alignItems="baseline" justifyContent="space-between" mb="space30" mt="space20">
                <Text as="h2" color="carbon400" pr="space60">
                  {field.label}
                </Text>
                <Button
                  color="blue500"
                  cursor="pointer"
                  data-test-id="summary-edit-link"
                  fontWeight="semi"
                  height="auto"
                  padding="space0"
                  textDecoration="underline"
                  variant="link"
                  onClick={() => handleEditButtonClick(field.stepId)}
                >
                  Edit
                </Button>
              </Flex>
              {renderSummaryField(field)}
            </Box>
          );
        })}

      <NavigationPanel>
        <Button dataTestId="todo-submit-button" onClick={handleSubmitButtonClick}>
          Submit
        </Button>
      </NavigationPanel>
    </Box>
  );
};
