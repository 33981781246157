import React, { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { ITEMS_PER_PAGE, useListTransactions } from 'hooks/useListTransactions';
import { TransactionSkeleton } from 'components/Skeleton';
import { Box, Pagination, Text } from '@endpoint/blockparty';
import { AlertMessage } from 'components/AlertMessage';
import { TRANSACTION } from 'consts/routes';
import { MilestoneStatus, TransactionStatus } from '@endpoint/endpoint-bff-graphql-schema';
import { Link, useNavigate } from 'react-router-dom';
import { isAgent, isContactTypeAgentOrTC, useAppContext } from 'utils/context';
import { useIsSupportedOrderReportState } from 'hooks/useIsSupportedOrderReportState';
import { useClosedAndPrelistingTransactionIds } from 'hooks/useClosedAndPrelistingTransactionIds';
import useOrganizationId from 'hooks/useOrganizationId';
import { useGetMarkets } from 'hooks/useGetMarkets';
import { ExtendedUser, formatAnalyticsIdentity, trackIdentity } from 'utils/analytics';
import { setMarketForTransactionSummary } from 'utils/zendesk';

import { TransactionSummaryCard } from '../TransactionSummaryCard';
import { TransactionGrid } from '../TransactionGrid';
import { NoTransactions } from '../NoTransactions';
import { getUserRoles } from '../TransactionSummaryCard/helpers';

const InEscrowPaginated: React.FC = () => {
  const organizationId = useOrganizationId();
  const {
    getClosedAndPrelistingTransactionIds,
    closedAndPrelistingTransactionIds,
    closedAndPrelistingTransactionIdsError,
    closedAndPrelistingTransactionIdsLoading,
  } = useClosedAndPrelistingTransactionIds();
  const [page, setPage] = useState(1);
  const { listTransactions, listTransactionsLoading, listTransactionsError, totalItems, totalPages } =
    useListTransactions({
      page,
      status: TransactionStatus.IN_ESCROW,
    });

  const { markets } = useGetMarkets();
  const { user } = useAppContext();
  const navigate = useNavigate();
  const hasNoTransactions = listTransactions.length === 0;
  const isSingleEscrowTransaction =
    listTransactions.length === 1 &&
    listTransactions[0].milestones[0] &&
    listTransactions[0].milestones[0].status !== MilestoneStatus.UPCOMING;
  const isOrderReportSupported = useIsSupportedOrderReportState(user);

  const [hasTrackedUserIdentity, setHasTrackedUserIdentity] = useState(false);

  useEffect(() => {
    if (!hasTrackedUserIdentity && user.id && listTransactions) {
      const userId = user.id!;
      const roles = listTransactions
        .map((transaction) => getUserRoles(transaction.participants, userId).join(''))
        .filter((role) => !!role);
      const offices = listTransactions
        .map((transaction) => transaction.officeName)
        .filter((value, index, self) => self.indexOf(value) === index);

      const analyticsIdentityData: ExtendedUser = {
        ...user,
        roles,
        rolesStr: roles.sort().join(''),
        offices,
      };

      trackIdentity(userId, organizationId, formatAnalyticsIdentity(analyticsIdentityData));

      setHasTrackedUserIdentity(true);
    }
  }, [user, organizationId, hasTrackedUserIdentity, listTransactions]);

  const shouldRedirect = useMemo(
    () =>
      !isContactTypeAgentOrTC(user) &&
      isSingleEscrowTransaction &&
      closedAndPrelistingTransactionIds !== undefined &&
      (!closedAndPrelistingTransactionIds || !closedAndPrelistingTransactionIds.length) &&
      !closedAndPrelistingTransactionIdsError &&
      !closedAndPrelistingTransactionIdsLoading,
    [
      isSingleEscrowTransaction,
      closedAndPrelistingTransactionIds,
      closedAndPrelistingTransactionIdsError,
      closedAndPrelistingTransactionIdsLoading,
      user,
    ],
  );

  useEffect(() => {
    if (listTransactions && markets && !!markets.length) {
      setMarketForTransactionSummary(listTransactions, markets);
    }
  }, [listTransactions, markets]);

  useEffect(() => {
    if (isSingleEscrowTransaction) {
      // Before redirection ensuring if the user doesn't have closed or listing transactions
      getClosedAndPrelistingTransactionIds();
    }
    // Verifying if the user has a single transaction in escrow
  }, [isSingleEscrowTransaction, getClosedAndPrelistingTransactionIds]);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(`/${TRANSACTION}/${listTransactions[0].id}/todos`);
    }
  }, [listTransactions, navigate, shouldRedirect]);

  const isLoading =
    listTransactionsLoading ||
    shouldRedirect ||
    closedAndPrelistingTransactionIdsLoading ||
    (closedAndPrelistingTransactionIds === undefined &&
      isSingleEscrowTransaction &&
      !closedAndPrelistingTransactionIdsError);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return (
      <TransactionGrid>
        <TransactionSkeleton />
      </TransactionGrid>
    );
  }

  if (listTransactionsError) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error in inEscrow page. user id: ${user.id}, error: ${listTransactionsError.message}`,
    });

    return (
      <Box m="space50">
        <AlertMessage>Cannot load In Escrow transactions</AlertMessage>
      </Box>
    );
  }

  if (hasNoTransactions) {
    return (
      <NoTransactions>
        {isAgent(user) && (
          <Text as="p" color="carbon500" data-test-id="no-transactions-agent-text">
            To get started, click{' '}
            <Link color="blue500" to="/open-escrow">
              <Text color="blue500">Open Escrow</Text>
            </Link>{' '}
            {isOrderReportSupported && (
              <>
                or{' '}
                <Link color="blue500" data-test-id="order-report-quick-link" to="/order-reports">
                  <Text color="blue500">Order Reports</Text>
                </Link>{' '}
                to get started.
              </>
            )}
          </Text>
        )}
      </NoTransactions>
    );
  }

  return (
    <>
      <TransactionGrid>
        {listTransactions.map((transaction) => {
          const { milestones } = transaction;
          const nonPendingTransaction = milestones[0] && milestones[0].status !== MilestoneStatus.UPCOMING;
          const hasTransactionDetail = nonPendingTransaction && {
            as: Link,
            to: `/${TRANSACTION}/${transaction.id}/todos`,
          };

          return (
            <Box key={transaction.id} bg="background" textDecoration="none" width="100%" {...hasTransactionDetail}>
              <TransactionSummaryCard {...transaction} />
            </Box>
          );
        })}
      </TransactionGrid>
      {totalItems > ITEMS_PER_PAGE && (
        <Box mb="space90" ml="auto" mr="auto" mt="space80">
          <Pagination count={totalPages} page={page} siblingCount={2} onChange={handlePageChange} />
        </Box>
      )}
    </>
  );
};

export default InEscrowPaginated;
