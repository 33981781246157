import {
  TransactionStatus,
  TransactionSummary,
  TransactionSummaryMilestone,
} from '@endpoint/endpoint-bff-graphql-schema';
import { parseISO } from 'date-fns';
import { formatDate, MONTH_DAY_YEAR } from 'utils/formatDate';

export const getSigningDetails = (transaction: TransactionSummary) => {
  const { status, signingDate, milestones } = transaction;
  const milestoneWithSigning =
    milestones &&
    milestones.find((milestone: TransactionSummaryMilestone) => milestone?.name?.includes('Sign Closing Documents'));

  const hasSignedDocuments = milestoneWithSigning && milestoneWithSigning.status === 'COMPLETE';
  const parsedSigningDate = signingDate ? parseISO(signingDate) : null;

  switch (status) {
    case TransactionStatus.IN_ESCROW:
    case TransactionStatus.CLOSED:
      return {
        label: hasSignedDocuments ? 'Signed:' : 'Signing:',
        value: signingDate ? formatDate(`${parsedSigningDate}`, MONTH_DAY_YEAR) : 'Not available yet',
      };
    default:
      return { label: 'Signing', value: 'N/A' };
  }
};
