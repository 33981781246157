import React, { FC, useRef, useState, useEffect } from 'react';
import { Stack, Flex, Text, Heading, Box } from '@endpoint/blockparty';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { NavigationButton } from 'components/form/NavigationButton';
import { DwollaCustomerInput, DwollaAddressInput } from '@endpoint/endpoint-bff-graphql-schema';
import { AlertMessage } from 'components/AlertMessage';
import * as yup from 'yup';
import { ApolloError } from '@apollo/client';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { formatDate, YEAR_MONTH_DAY, MONTH_DAY_YEAR_WITH_SLASHES } from 'utils/formatDate';
import { filterDwollaValidationError } from 'utils/dwollaErrorStatus';
import { parseISO } from 'date-fns';
import { AnyObject } from 'yup/lib/object';

import { DwollaCustomerBuilder } from '../../helpers/DwollaCustomerBuilder';
import * as ADDRESS_FORM_CONSTS from './const';

interface AddressFormInput {
  upsertDwollaCustomer: Function;
  dwollaCustomerBuilder: DwollaCustomerBuilder;
  dwollaCustomerUpsertError?: ApolloError;
  prevDwollaCustomerUpsertData?: DwollaCustomerInput;
  onSubmit: () => void;
}

type AddressFormFields = {
  lastFourSocial: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  dateOfBirth: string;
};

export const AddressForm: FC<AddressFormInput> = ({
  upsertDwollaCustomer,
  dwollaCustomerBuilder,
  dwollaCustomerUpsertError,
  prevDwollaCustomerUpsertData,
  onSubmit,
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setShowTodoStepNavigationPanel, handlePreviousSubmission } = useTodoStepContext();
  const parsedDateOfBirth = prevDwollaCustomerUpsertData?.dateOfBirth
    ? parseISO(prevDwollaCustomerUpsertData?.dateOfBirth)
    : null;
  const formInitialValues: AddressFormFields = {
    lastFourSocial: prevDwollaCustomerUpsertData?.ssn || '',
    street1: prevDwollaCustomerUpsertData?.address?.street1 || '',
    street2: prevDwollaCustomerUpsertData?.address?.street2 || '',
    city: prevDwollaCustomerUpsertData?.address?.city || '',
    state: prevDwollaCustomerUpsertData?.address?.state || '',
    zip: prevDwollaCustomerUpsertData?.address?.zip || '',
    dateOfBirth: parsedDateOfBirth ? formatDate(`${parsedDateOfBirth}`, MONTH_DAY_YEAR_WITH_SLASHES) : '',
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current?.validateForm().catch(() => {});
    }
  }, []);

  const filteredDwollaCustomerUpsertError = filterDwollaValidationError(dwollaCustomerUpsertError);

  const submitForm = (values: AddressFormFields) => {
    const addressInput: DwollaAddressInput = {
      street1: values.street1,
      street2: values.street2,
      city: values.city,
      state: values.state.toUpperCase(),
      zip: values.zip,
    };

    dwollaCustomerBuilder
      .withAddress(addressInput)
      .withSSN(values.lastFourSocial)
      .withDateOfBirth(formatDate(values.dateOfBirth, YEAR_MONTH_DAY));

    upsertDwollaCustomer(dwollaCustomerBuilder.build());
    onSubmit();
  };

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        {ADDRESS_FORM_CONSTS.HEADING_TEXT}
      </Heading>
      <Text mb="space50">
        For security purposes, our payment processor requires additional verification for deposits over $5,000. This
        information will not be stored in our system.
      </Text>

      {filteredDwollaCustomerUpsertError.map((error) => (
        <Box key={error.message} mb="space60">
          <AlertMessage data-test-id="error-msg" description={error.message} />
        </Box>
      ))}
      {/* @ts-ignore we are submitting when clicking on Next NavigationButton */}
      <Formik
        data-test-id="address-form"
        enableReinitialize
        initialValues={formInitialValues}
        innerRef={formRef}
        validationSchema={addressFormValidationSchema}
      >
        {({ values, isValid }) => (
          <Form>
            <Stack mt="space70" spacing="space60">
              <FormikInputGroup
                data-test-id={ADDRESS_FORM_CONSTS.SOCIAL_SECURITY_NUMBER_INPUT_TEST_ID}
                isRequired
                isSensitiveInformation
                label={ADDRESS_FORM_CONSTS.SOCIAL_SECURITY_NUMBER_INPUT_LABEL}
                mask={{ numericOnly: true, blocks: [4] }}
                name={ADDRESS_FORM_CONSTS.SOCIAL_SECURITY_NUMBER_INPUT_NAME}
                placeholder={ADDRESS_FORM_CONSTS.SOCIAL_SECURITY_NUMBER_INPUT_PLACEHOLDER}
              />
              <FormikInputGroup
                data-test-id={ADDRESS_FORM_CONSTS.DATE_OF_BIRTH_INPUT_TEST_ID}
                isRequired
                isSensitiveInformation
                label={ADDRESS_FORM_CONSTS.DATE_OF_BIRTH_INPUT_LABEL}
                mask={{ numericOnly: true, blocks: [2, 2, 4], delimiters: ['/', '/', '/'] }}
                name={ADDRESS_FORM_CONSTS.DATE_OF_BIRTH_INPUT_NAME}
                placeholder={ADDRESS_FORM_CONSTS.DATE_OF_BIRTH_INPUT_PLACEHOLDER}
              />
              <FormikInputGroup
                data-test-id={ADDRESS_FORM_CONSTS.STREET1_INPUT_TEST_ID}
                isRequired
                label={ADDRESS_FORM_CONSTS.STREET1_INPUT_LABEL}
                name={ADDRESS_FORM_CONSTS.STREET1_INPUT_NAME}
                placeholder={ADDRESS_FORM_CONSTS.STREET1_INPUT_PLACEHOLDER}
              />
              <FormikInputGroup
                data-test-id={ADDRESS_FORM_CONSTS.STREET2_INPUT_TEST_ID}
                label={ADDRESS_FORM_CONSTS.STREET2_INPUT_LABEL}
                name={ADDRESS_FORM_CONSTS.STREET2_INPUT_NAME}
                placeholder={ADDRESS_FORM_CONSTS.STREET2_INPUT_PLACEHOLDER}
              />
              <FormikInputGroup
                data-test-id={ADDRESS_FORM_CONSTS.CITY_INPUT_TEST_ID}
                isRequired
                label={ADDRESS_FORM_CONSTS.CITY_INPUT_LABEL}
                name={ADDRESS_FORM_CONSTS.CITY_INPUT_NAME}
                placeholder={ADDRESS_FORM_CONSTS.CITY_INPUT_PLACEHOLDER}
              />
              <Flex>
                <FormikInputGroup
                  data-test-id={ADDRESS_FORM_CONSTS.STATE_INPUT_TEST_ID}
                  flex={1}
                  isRequired
                  label={ADDRESS_FORM_CONSTS.STATE_INPUT_LABEL}
                  mask={{ blocks: [2] }}
                  mr="space40"
                  name={ADDRESS_FORM_CONSTS.STATE_INPUT_NAME}
                  placeholder={ADDRESS_FORM_CONSTS.STATE_INPUT_PLACEHOLDER}
                />
                <FormikInputGroup
                  data-test-id={ADDRESS_FORM_CONSTS.ZIP_INPUT_TEST_ID}
                  flex={1}
                  isRequired
                  label={ADDRESS_FORM_CONSTS.ZIP_INPUT_LABEL}
                  mask={{ numericOnly: true, blocks: [5] }}
                  name={ADDRESS_FORM_CONSTS.ZIP_INPUT_NAME}
                  placeholder={ADDRESS_FORM_CONSTS.ZIP_INPUT_PLACEHOLDER}
                />
              </Flex>
            </Stack>
            <NavigationButton
              goNext={() => {
                setIsLoading(true);
                submitForm(values as AddressFormFields);
              }}
              goPrevious={() => {
                handlePreviousSubmission();
                setShowTodoStepNavigationPanel(true);
              }}
              iconRight="ArrowChevronRight"
              isDisabled={!isValid}
              isLoading={isLoading}
            >
              Next
            </NavigationButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

const isPOBox = (value: string = ''): boolean => {
  const poBoxRegexp =
    /^\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+/gi;

  return poBoxRegexp.test(value);
};

yup.addMethod<yup.StringSchema>(
  yup.string,
  'preventPOBox',
  function isValidAddress(
    this: yup.StringSchema<string | undefined, AnyObject, string | undefined>,
    errorMessage: string,
  ): yup.StringSchema<string | undefined, AnyObject, string | undefined> {
    return this.test(
      'prevent-po-box',
      errorMessage,
      function validationHandler(
        this: yup.TestContext<AnyObject>,
        value: string | undefined,
      ): true | yup.ValidationError {
        const { path, createError } = this;

        return !isPOBox(value) || createError({ path, message: errorMessage });
      },
    );
  },
);

export const addressFormValidationSchema = yup.object().shape({
  lastFourSocial: yup
    .string()
    .required('Last 4 digits of Social Security Number are required')
    .min(4, 'Value must be 4 digits.'),
  dateOfBirth: yup
    .string()
    .required('Date of Birth is required')
    .min(10, 'Date is invalid')
    .matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, 'Date is invalid'),
  street1: yup
    .string()
    .required('Address is required')
    .max(50, 'Value must be less than or equal to 50')
    .matches(/^[a-zA-Z0-9 @#^&*()_+-;':|,./]*$/, 'Must contain no special characters')
    .preventPOBox('Address provided cannot be a PO Box'),
  street2: yup
    .string()
    .max(50, 'Value must be less than or equal to 50')
    .matches(/^[a-zA-Z0-9 @#^&*()_+-;':|,./]*$/, 'Must contain no special characters')
    .preventPOBox('Address provided cannot be a PO Box'),
  state: yup.string().required('State is required').min(2),
  city: yup.string().required('City is required'),
  zip: yup.string().required('Zip code is required').min(5, 'Zip code must be 5 digits'),
});
