import React, { FC, useCallback } from 'react';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  useDropdownMenuState,
  assignStyle,
  useMediaQuery,
  PopoverStateRenderCallbackProps,
  useTheme,
} from '@endpoint/blockparty';
import { useNavigate } from 'react-router-dom';

import { HeaderLeftRoutes, RouteLabels } from '../routes';

interface HeaderLeftMobileProps {
  headerRoutes: Array<RouteLabels>;
}

export const HeaderLeftMobile: FC<HeaderLeftMobileProps> = ({ headerRoutes }) => {
  const menuItemInitialStyles = 'initial !important';
  const theme = useTheme();
  const navigate = useNavigate();
  const isLarge = useMediaQuery(theme.mediaQueries.md);
  // Since DropdownMenu has a new positioning method, it doesn't comply
  // with BlockParty's responsive styles. We resolve it by passing
  // renderCallback into useDropdownMenuState
  const renderCallback = useCallback(
    (props: PopoverStateRenderCallbackProps) => {
      const { popover, defaultRenderCallback } = props;

      if (isLarge) return defaultRenderCallback();

      return assignStyle(popover, {
        top: '56px',
        width: '100%',
      });
    },
    [isLarge],
  );

  const mobileMenu = useDropdownMenuState({
    renderCallback,
  });

  if (mobileMenu.open && isLarge) {
    mobileMenu.hide();
  }

  return (
    <>
      <DropdownMenuTrigger data-test-id="mobile-menu-trigger" state={mobileMenu}>
        <Flex alignItems="center" bg="transparent" color="background" cursor="pointer" height="100%">
          Menu
        </Flex>
      </DropdownMenuTrigger>
      <DropdownMenu aria-label="mobile nav menu" data-test-id="mobile-menu" state={mobileMenu}>
        {HeaderLeftRoutes.reduce((result: Array<JSX.Element>, { route, label }) => {
          const isLabelSupportedInStateSignedUp = headerRoutes.includes(label);

          if (isLabelSupportedInStateSignedUp === true) {
            result.push(
              <DropdownMenuItem
                key={`nav-bar-mobile-item-${label}`}
                _first={{ borderTopLeftRadius: menuItemInitialStyles, borderTopRightRadius: menuItemInitialStyles }}
                _last={{
                  borderBottomLeftRadius: menuItemInitialStyles,
                  borderBottomRightRadius: menuItemInitialStyles,
                }}
                data-test-id={`nav-bar-mobile-item-${label}`}
                py="space50"
                state={mobileMenu}
                onClick={() => {
                  mobileMenu.hide();
                  navigate(route);
                }}
              >
                {label}
              </DropdownMenuItem>,
            );
          }

          return result;
        }, [])}
      </DropdownMenu>
    </>
  );
};
