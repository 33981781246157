import { TodoSyncType } from '@endpoint/platform-api-connector/dist/graphql-types';

interface DidUserCompleteAssignmentProps {
  firstName: string;
  lastName: string;
  completedByName: string;
}

const didUserCompleteAssignment = ({
  firstName,
  lastName,
  completedByName,
}: DidUserCompleteAssignmentProps): boolean => {
  return (
    completedByName.toLowerCase().includes(firstName.toLowerCase()) &&
    completedByName.toLowerCase().includes(lastName.toLowerCase())
  );
};

export const getCompletedByName = (
  syncType: TodoSyncType,
  completedByName: string,
  firstName: string,
  lastName: string,
) => {
  if (!completedByName || syncType === TodoSyncType.ALL_ASSIGNEES) {
    return 'you';
  }

  const completedByAuthenticatedUser = didUserCompleteAssignment({ firstName, lastName, completedByName });

  if (completedByAuthenticatedUser) {
    return 'you';
  }

  return completedByName;
};
