import React, { FC } from 'react';
import { Box, Drawer, DrawerActionBar, DrawerContent, Flex } from '@endpoint/blockparty';
import { Milestone } from '@endpoint/platform-api-connector/dist/graphql-types';

import { M2PaymentVariant } from './PinnedItemM2';
import { M6SigningVariant } from './PinnedItemM6';

export interface PinnedItemProps {
  id: Milestone['id'];
  isOpen: boolean;
  onClose(): void;
  milestoneStep: number;
}

export const PinnedItem: FC<PinnedItemProps> = ({ id, milestoneStep, isOpen, onClose }) => {
  return (
    <Box aria-label="Pinned Item">
      <Drawer id={id} isOpen={isOpen} onClose={onClose}>
        <DrawerContent>
          <DrawerActionBar />
          <Flex flex={1} flexDirection="column" overflow="auto">
            {getPinnedItemByMilestone({ milestoneStep })}
          </Flex>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

/* ======================================= */

export const getPinnedItemByMilestone = ({ milestoneStep }: { milestoneStep: number }) => {
  switch (milestoneStep) {
    case 2: // EMD
      return <M2PaymentVariant />;
    case 5: // M5 complete links to docs signing
    case 6: // Closing docs signing
      return <M6SigningVariant />;
    default:
      return null;
  }
};
