import React, { FC, Fragment } from 'react';
import { Box, SkeletonBlock, Flex, Divider } from '@endpoint/blockparty';

import { PropertyHeaderSkeleton } from '../PropertyHeaderSkeleton';

export const DocumentsSkeleton: FC = () => (
  <>
    <PropertyHeaderSkeleton />
    <Box bg="white" mt={{ base: 'space0', md: 'space60' }} p={{ base: 'space20', md: 'space40' }}>
      <Box data-test-id="document-skeleton-header" px="space40" py="space60">
        <Box mb="space60">
          <SkeletonBlock height={16} maxWidth={170} mb="space30" />
          <SkeletonBlock height={12} maxWidth={269} />
        </Box>
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          <Box flex={1} mb={{ base: 'space40', md: 'space0' }}>
            <SkeletonBlock height={12} maxWidth={487} mb="space30" />
            <SkeletonBlock height={12} maxWidth={414} />
          </Box>
          <Flex display={{ base: 'none', md: 'flex' }} flex={1} justifyContent="flex-end">
            <SkeletonBlock height={40} maxWidth={148} mr="space30" />
            <SkeletonBlock height={40} maxWidth={113} />
          </Flex>
        </Flex>
      </Box>
      <Divider width="100%" />
      <Box data-test-id="document-skeleton-body">
        <Flex p="space40">
          <Flex alignItems="center" flex={1} width={[1 / 3]}>
            <SkeletonBlock height={18} mr="space70" width={18} />
            <SkeletonBlock height={12} width={32} />
          </Flex>
          <Flex alignItems="center" display={{ base: 'none', md: 'flex' }} flex={1} width={[1 / 3]}>
            <SkeletonBlock height={12} width={32} />
          </Flex>
          <Flex alignItems="center" display={{ base: 'none', md: 'flex' }} flex={1} width={[1 / 3]}>
            <SkeletonBlock height={12} width={32} />
          </Flex>
          <Flex alignItems="center" flex={1} justifyContent="flex-end" width={[1 / 3]}>
            <SkeletonBlock height={12} width={32} />
          </Flex>
        </Flex>
        <Divider width="100%" />
        {[...Array(7)].map((_item, i) => (
          // eslint-disable-next-line
          <Fragment key={i}>
            <Flex px="space40" py="space60">
              <Flex alignItems="center" flex={1} maxWidth={396}>
                <SkeletonBlock height={18} mr="space70" width={18} />
                <SkeletonBlock height={12} maxWidth={170} />
              </Flex>
              <Flex alignItems="center" display={{ base: 'none', md: 'flex' }} flex={1} maxWidth={[250]}>
                <SkeletonBlock height={12} maxWidth={89} />
              </Flex>
              <Flex alignItems="center" display={{ base: 'none', md: 'flex' }} flex={1} maxWidth={273}>
                <SkeletonBlock height={12} maxWidth={165} />
              </Flex>
              <Flex alignItems="center" flex={1} justifyContent="flex-end">
                <SkeletonBlock height={12} maxWidth={18} />
              </Flex>
            </Flex>
            <Divider width="100%" />
          </Fragment>
        ))}
      </Box>
    </Box>
  </>
);
