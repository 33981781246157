import React, { FC } from 'react';
import { Loading, Box, Flex } from '@endpoint/blockparty';
import { AlertMessage } from 'components/AlertMessage';

interface ErrorLoadingStatesWidgetProps {
  loading: boolean;
  error?: boolean | unknown;
}

export const ErrorLoadingStatesWidget: FC<ErrorLoadingStatesWidgetProps> = ({
  loading,
  error,
}: ErrorLoadingStatesWidgetProps) => {
  return (
    <>
      {error && (
        <Box m="space50">
          <AlertMessage />
        </Box>
      )}

      {loading && (
        <Flex alignItems="center" justifyContent="center" m="space50" size="medium">
          <Loading />
        </Flex>
      )}
    </>
  );
};
