import React, { FC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Box,
  Text,
  ProgressBar,
  Icon,
  Flex,
  Stack,
} from '@endpoint/blockparty';
import { Step } from 'components/form/TwoColumnWithProgressContainer';

export interface ProgressPanelProps {
  currentStepPosition: number;
  steps: Step[];
  title: string;
}

interface ProgressPanelItemsProps extends Omit<ProgressPanelProps, 'title'> {}

/**
 * ProgressPanel contains title, progress tracking (<Progress>) and lists of its own steps (props.steps).
 * See Figma below, on the left column to see the UI.
 * Figma ref: https://www.figma.com/file/d9zcbACqotpxTcwPdrYSfA/Prototypes%3A-Order-Prelim?node-id=1815%3A166
 */
export const ProgressPanel: FC<ProgressPanelProps> = (props: ProgressPanelProps) => {
  const { currentStepPosition, steps, title } = props;
  const totalSteps = steps.length;

  const padding = { base: 'space50', lg: 'space60' };

  return (
    <>
      {/* Desktop view */}
      <Box bg="background" display={{ base: 'none', md: 'block' }} {...props}>
        <Box p={padding}>
          <Text as="h6" size="fontSize30">
            {title}
          </Text>
        </Box>
        <Box mb={{ base: 'space30', lg: 'space0' }} px={padding}>
          <ProgressBar aria-label={title} completed={currentStepPosition} steps={totalSteps} />
        </Box>
        <Box mb="space30" p={padding}>
          <Stack spacing="space50">
            <ProgressPanelItems currentStepPosition={currentStepPosition} steps={steps} />
          </Stack>
        </Box>
      </Box>
      {/* Mobile view */}
      <Box bg="background" display={{ base: 'block', md: 'none' }} {...props}>
        <Accordion collapsible>
          <AccordionItem value="accordion-item-1" variant="ghost">
            <AccordionHeader iconRight iconRightName="ArrowChevronDown" pb="space0" px="space50">
              <Text as="h6" size="fontSize30">
                {title}
              </Text>
            </AccordionHeader>
            <Box pb="space50" pt="space40" px="space50">
              <ProgressBar aria-label={title} completed={currentStepPosition} steps={totalSteps} />
            </Box>
            <AccordionPanel hideDivider p="space50">
              <ProgressPanelItems currentStepPosition={currentStepPosition} steps={steps} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </>
  );
};

export const ProgressPanelItems = ({ steps, currentStepPosition }: ProgressPanelItemsProps) => {
  const totalSteps = steps.length;

  return (
    <>
      {steps.map((step, index) => {
        const isCurrentSection = currentStepPosition === index + 1;
        const isCompleted =
          (!isCurrentSection && index + 1 < currentStepPosition) || (isCurrentSection && index + 1 === totalSteps);
        const showNext = isCurrentSection && !isCompleted;
        const showIncompleteStep = !isCurrentSection && index + 1 > currentStepPosition;

        return (
          <Flex
            key={`${step.title}`}
            alignItems="center"
            data-test-id={`progress-panel-item-${step.title}`}
            py={{ base: 'space30', md: 'space0' }}
          >
            <Box mr="space30">
              {isCompleted && <Icon color="turquoise500" dataTestId="check-circle-icon" name="CheckCircle" />}
              {showNext && <Icon dataTestId="arrow-forward-icon" name="ArrowForward" />}
              {showIncompleteStep && (
                <Flex
                  alignItems="center"
                  border="1px solid"
                  borderColor="carbon400"
                  borderRadius="radiusFull"
                  color="carbon400"
                  data-test-id="step-number-badge"
                  height="16px"
                  justifyContent="center"
                  textAlign="center"
                  width="16px"
                >
                  <Text color="carbon400" size="fontSize10">
                    {index + 1}
                  </Text>
                </Flex>
              )}
            </Box>
            <Text
              color={
                (isCompleted && 'carbon900') || (showNext && 'text') || (showIncompleteStep && 'carbon400') || undefined
              }
              fontWeight={isCurrentSection ? 'semi' : null}
            >
              {step.title}
            </Text>
          </Flex>
        );
      })}
    </>
  );
};

Icon.displayName = 'Icon';
