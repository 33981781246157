import { object as yupObject, string as yupString, ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';

const UnityIndividualProfileFieldsSchema = {
  firstName: yupString().trim().required('Please enter your first name'),
  middleName: yupString(),
  lastName: yupString().trim().required('Please enter your last name'),
  suffix: yupString(),
};

const UnityProfileValidationSchema: ObjectSchema<ObjectShape> = yupObject().shape(UnityIndividualProfileFieldsSchema);

export default UnityProfileValidationSchema;
