import {
  ConditionalGroup,
  ContentType,
  FieldOptions,
  FieldValidations,
  TodoAssignmentContent,
} from '@endpoint/endpoint-bff-graphql-schema';
import * as Yup from 'yup';
import { getTotalPercent } from 'utils/getTotalPercent';

import { validateDocumentLoadingComplete } from './validationFunctions';
import { isConditionalGroupSatisfied } from '../TodoStep/TodoStepComponent/TodoConditionalContent/helpers/isConditionalGroupSatisfied';

const mergeValidationSchemas = (...schemas: Yup.AnySchema[]): Yup.AnySchema => {
  const [first, ...rest] = schemas;

  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

  return merged;
};

const isAcknowledgementField = (field: FieldOptions): boolean => {
  return [
    'acknowledge-title-product-field',
    'acknowledge-apr-12-disclosure-field',
    'acknowledge-firpta-field',
    'generic-acknowledgement-field',
  ].includes(field.id);
};

const isDateTimePickerField = (field: FieldOptions): boolean => {
  return field.id === 'notary';
};

const getFieldValues = (fieldIds: string[], values: string[]) => {
  let fieldValues: GenericObject = {};

  fieldIds.forEach((id, index) => {
    fieldValues = { ...fieldValues, [id]: values[index] };
  });

  return fieldValues;
};

const getMultipleInputFieldSetValidation = (field: FieldOptions) => {
  let fieldSetValidation = {};

  field.children?.forEach((element) => {
    if (element.field) {
      fieldSetValidation = {
        ...fieldSetValidation,
        [element.field.id]: getFieldValidationSchema(element.field),
      };
    }
  });

  return fieldSetValidation;
};

const getFieldValidationSchema = (field: FieldOptions, conditionalGroup?: ConditionalGroup): Yup.AnySchema => {
  const fieldValidations: Yup.AnySchema[] = [];

  if (conditionalGroup) {
    const conditionalFieldIds = (conditionalGroup.conditions ?? []).map((condition) => condition.fieldId);

    const conditionalValidation = Yup.string().when(conditionalFieldIds, {
      is: (...conditionalValues: string[]) => {
        const fieldValues = getFieldValues(conditionalFieldIds, conditionalValues);

        return isConditionalGroupSatisfied(conditionalGroup, fieldValues);
      },
      then: () => getFieldValidationSchema(field),
    });

    return conditionalValidation;
  }

  field.validations.forEach((validation) => {
    switch (validation.type) {
      case FieldValidations.FileRequired:
        fieldValidations.push(
          Yup.object()
            .required(validation.message)
            .test('loading-completed', 'Document is not fully loaded', validateDocumentLoadingComplete),
        );
        break;
      case FieldValidations.Required:
        if (isAcknowledgementField(field)) {
          fieldValidations.push(Yup.object().shape({ acknowledge: Yup.string().required(validation.message) }));
          break;
        }

        if (isDateTimePickerField(field)) {
          fieldValidations.push(
            Yup.object().shape({
              date: Yup.string().required(validation.message),
              time: Yup.string().required(validation.message),
            }),
          );
          break;
        }

        fieldValidations.push(Yup.string().required(validation.message));
        break;
      case FieldValidations.Email:
        fieldValidations.push(Yup.string().email(validation.message));
        break;
      case FieldValidations.SSN:
        fieldValidations.push(Yup.string().min(9, validation.message));
        break;
      case FieldValidations.Phone:
        fieldValidations.push(Yup.string().min(14, validation.message).max(14, validation.message));
        break;
      case FieldValidations.Length:
        if (validation.minLength && validation.maxLength) {
          fieldValidations.push(
            Yup.string().min(validation.minLength, validation.message).max(validation.maxLength, validation.message),
          );
        } else if (validation.minLength) {
          fieldValidations.push(Yup.string().min(validation.minLength, validation.message));
        } else if (validation.maxLength) {
          fieldValidations.push(Yup.string().max(validation.maxLength, validation.message));
        }

        break;
      case FieldValidations.PercentTotal:
        fieldValidations.push(
          Yup.object()
            .required(validation.message)
            .test(
              'validate-percent',
              validation.message,
              (value: GenericObject) => value && getTotalPercent(value) === 100,
            ),
        );
        break;
      default:
    }
  });

  return mergeValidationSchemas(...fieldValidations);
};

export const getValidationSchema = (currentStepContent: TodoAssignmentContent[]): Yup.ObjectSchema<GenericObject> => {
  let fieldValidations: { [key: string]: Yup.AnySchema } = {};

  currentStepContent.forEach(({ children, conditionalGroup, field, nodeType }) => {
    if (nodeType === ContentType.CONDITIONAL_FORM) {
      children.forEach(({ field: conditionalField }) => {
        if (conditionalField) {
          const newFieldValidation = {
            [conditionalField.id]: getFieldValidationSchema(conditionalField, conditionalGroup),
          };

          fieldValidations = { ...fieldValidations, ...newFieldValidation };
        }
      });
    }

    if (field) {
      if (nodeType === ContentType.MULTIPLE) {
        const fieldSetValidation = getMultipleInputFieldSetValidation(field);

        const multipleFieldValidation = { [field.id]: Yup.array().of(Yup.object().shape(fieldSetValidation)) };

        fieldValidations = { ...fieldValidations, ...multipleFieldValidation };
      } else {
        const newFieldValidation = { [field.id]: getFieldValidationSchema(field) };

        fieldValidations = { ...fieldValidations, ...newFieldValidation };
      }
    }
  });

  return Yup.object().shape(fieldValidations);
};
