import { BFF_CLIENT_NAME } from 'Apollo';
import { useQuery } from '@apollo/client';

import { GET_CHECK_PAYMENT_INSTRUCTIONS, GET_WIRE_PAYMENT_INSTRUCTIONS, PaymentInstructionsPayload } from './queries';

export enum InstructionType {
  CHECK = 'CHECK',
  WIRE = 'WIRE',
}
export interface UseGetPaymentInstructionsProps {
  transactionId: string;
  instructionType: InstructionType;
  isInternationalWire?: boolean;
  skipQuery?: boolean;
}

const instructionTypeToQuery = {
  [InstructionType.WIRE]: GET_WIRE_PAYMENT_INSTRUCTIONS,
  [InstructionType.CHECK]: GET_CHECK_PAYMENT_INSTRUCTIONS,
};

export const useGetPaymentInstructions = ({
  transactionId,
  instructionType,
  isInternationalWire,
  skipQuery,
}: UseGetPaymentInstructionsProps) => {
  const { data, loading, error } = useQuery<PaymentInstructionsPayload>(instructionTypeToQuery[instructionType], {
    variables: { input: { transactionId, isInternationalWireTransfer: Boolean(isInternationalWire) } },
    context: { clientName: BFF_CLIENT_NAME },
    skip: Boolean(skipQuery),
  });

  return {
    checkPayableToName: data?.getPaymentInstructions?.checkPayableToName ?? '',
    checkMailToAddress: data?.getPaymentInstructions?.checkMailToAddress,
    payableToName: data?.getPaymentInstructions?.payableToName ?? '',
    wireTransferBankAddress: data?.getPaymentInstructions?.wireTransferBankAddress,
    creditBeneficiaryAddress: data?.getPaymentInstructions?.creditBeneficiaryAddress,
    paymentInstructionsLoading: loading,
    paymentInstructionsError: error,
  };
};
