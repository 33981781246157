import { MARKET_ID, mmunikyMarketIds } from 'consts/markets';
import { PAYABLE_TO_CA, PAYABLE_TO_DEFAULT, PAYABLE_TO_TX } from 'consts/moneyTransfer';

export const expansionMarketsIds = [8, 9, 10, 11, 12, 13];

export function isExpansionMarket(marketId?: number | null) {
  return !!marketId && expansionMarketsIds.includes(marketId);
}

export function isMmunikyMarket(marketId: number) {
  return mmunikyMarketIds().includes(marketId);
}

export const officesLocationMap = new Map<number, string>([
  [MARKET_ID.WA, PAYABLE_TO_DEFAULT],
  [MARKET_ID.CA, PAYABLE_TO_CA],
  [MARKET_ID.AZ, PAYABLE_TO_DEFAULT],
  [MARKET_ID.TX, PAYABLE_TO_TX],
  [MARKET_ID.NorCA, PAYABLE_TO_CA],
  [MARKET_ID.AHC, 'Access Home Closing'],
  [MARKET_ID.SoCA, PAYABLE_TO_CA],
  [35, PAYABLE_TO_DEFAULT],

  [MARKET_ID.CO, PAYABLE_TO_DEFAULT],
  [MARKET_ID.FL, PAYABLE_TO_DEFAULT],
  [MARKET_ID.IN, PAYABLE_TO_DEFAULT],
  [MARKET_ID.KS, PAYABLE_TO_DEFAULT],
  [MARKET_ID.KY, PAYABLE_TO_DEFAULT],
  [MARKET_ID.MO, PAYABLE_TO_DEFAULT],
  [MARKET_ID.OH, PAYABLE_TO_DEFAULT],
  [MARKET_ID.TN, PAYABLE_TO_DEFAULT],
]);
