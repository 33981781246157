import { gql } from '@apollo/client';
import { TodoAssignment } from '@endpoint/endpoint-bff-graphql-schema';

export interface TodoAssignmentPayload {
  getTodoAssignment: TodoAssignment;
}

export interface TodoAssignmentQueryVariables {
  todoAssignmentId: string;
  stepId?: string;
  isWebView?: boolean;
}

const FRAGMENT_DEFINITIONS = gql`
  fragment TodoAssignmentContentChild on TodoAssignmentContent {
    nodeType
    value
    icon
    textMarkup
    conditionalGroup {
      conditionOperator
      conditions {
        fieldId
        fieldValueComparator
        valueToCompare
      }
    }
    field {
      id
      validations {
        type
        message
        minLength
        maxLength
      }
      answer
      placeHolder
      label
      options
      enhancedOptions {
        value
        description
        isDisabled
      }
      isSensitiveInformation
      allowMultiple
      children {
        nodeType
        value
        field {
          id
          validations {
            type
            message
            minLength
            maxLength
          }
          answer
          placeHolder
          label
          options
          enhancedOptions {
            value
            description
            isDisabled
          }
          isSensitiveInformation
        }
      }
    }
    image {
      alt
      src
      title
    }
  }

  fragment TodoAssignmentContentChildRecursive on TodoAssignmentContent {
    ...TodoAssignmentContentChild
    children {
      ...TodoAssignmentContentChild
      children {
        ...TodoAssignmentContentChild
        children {
          ...TodoAssignmentContentChild
          children {
            ...TodoAssignmentContentChild
            children {
              ...TodoAssignmentContentChild
              children {
                ...TodoAssignmentContentChild
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TODO_ASSIGNMENT = gql`
  ${FRAGMENT_DEFINITIONS}
  query getTodoAssignment($todoAssignmentId: String!, $stepId: String, $isWebView: Boolean) {
    getTodoAssignment(todoAssignmentId: $todoAssignmentId, stepId: $stepId, isWebView: $isWebView) {
      currentStepId
      progressTracker {
        name
        dueDate
        estimatedTimeInMinutes
        sectionData {
          name
          status
        }
      }
      stepContent {
        ...TodoAssignmentContentChildRecursive
      }
      navigationPanel {
        type
        text
        isDisplayed
        isEnabled
        isExitButton
        completesTodo
      }
      header {
        address
        shouldRenderSaveButton
      }
      hasSuccessPage
      hasSummaryPage
      definitions {
        term
        definition
      }
      summary {
        summaryPageHeading
        fields {
          id
          type
          label
          stepId
          answer
          isSensitiveInformation
        }
      }
    }
  }
`;
