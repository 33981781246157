import { useLazyQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { useNavigate, useParams } from 'react-router';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useEffect } from 'react';
import { getTodoStepUrl } from 'routes/TransactionDetail/Todo/helpers/navigation';
import { isWebView } from 'utils/webview';
import { TodoStepId } from 'consts/enums';
import { TRANSACTION } from 'consts/routes';

import { GET_TODO_ASSIGNMENT, TodoAssignmentPayload, TodoAssignmentQueryVariables } from './queries';

export const useTodoAssignment = () => {
  const navigate = useNavigate();
  const { todoId = '', transactionId = '' } = useParams();
  const sentryTransaction = useSentryTransaction('getTodoAssignment', 'GraphQL client query');

  const [getTodoAssignment, { data, error, loading, variables }] = useLazyQuery<
    TodoAssignmentPayload,
    TodoAssignmentQueryVariables
  >(GET_TODO_ASSIGNMENT, {
    variables: { todoAssignmentId: todoId, isWebView: isWebView() },
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const currentStepId = data?.getTodoAssignment.currentStepId;

    if (variables?.stepId) {
      return;
    }

    if (currentStepId === TodoStepId.THANK_YOU) {
      navigate(`/${TRANSACTION}/${transactionId}/todos`);

      return;
    }

    if (currentStepId) {
      const stepUrl = getTodoStepUrl({ transactionId, todoId, stepId: currentStepId });

      navigate({ pathname: stepUrl, search: window.location.search }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getTodoAssignment.currentStepId]);

  if (!loading) {
    sentryTransaction.finish();
  }

  return {
    todoAssignment: data?.getTodoAssignment,
    todoAssignmentLoading: loading,
    todoAssignmentError: error,
    getTodoAssignment,
  };
};
