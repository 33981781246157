import React, { FC } from 'react';
import { Box } from '@endpoint/blockparty';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';

import { SvgNames, svgList } from './svgList';

interface SvgProps extends BoxProps {
  /** Name of available Svgs listed in EP 2.0 */
  name: SvgNames;
  /** SVG role helps screen readers consider it as a single entity */
  role?: string;
  /** ID for `data-test-id` attribute */
  dataTestId?: string;
  viewBox?: string;
}

export const Svg: FC<SvgProps> = ({ dataTestId, name, role = 'presentation', ...rest }) => {
  const svgName = svgList[name];

  return (
    <Box as="svg" color="currentColor" data-test-id={`${dataTestId}-svg`} fill="none" role={role} {...rest}>
      {svgName}
    </Box>
  );
};
