import React, { FC } from 'react';
import { Stack, Divider, Flex, Text, Link } from '@endpoint/blockparty';

interface FormikSummaryDisplayProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  shouldRenderHTML?: boolean;
  value: string | JSX.Element;
}

export const FormikSummaryDisplay: FC<FormikSummaryDisplayProps> = ({
  label,
  onClick,
  shouldRenderHTML = false,
  value,
}) => (
  <Stack bg="white" mb="space50" mt="space50">
    <Divider />
    <Flex justifyContent="space-between" mb="space30">
      <Text as="h3" color="carbon400" pr="space40">
        {label}
      </Text>
      <Link
        color="blue500"
        cursor="pointer"
        fontWeight="semi"
        ml="auto"
        size="fontSize10"
        textDecoration="underline"
        onClick={onClick}
      >
        Edit
      </Link>
    </Flex>

    <Flex flexDirection="column">
      {shouldRenderHTML ? <Text dangerouslySetInnerHTML={{ __html: value as string }} size="fontSize30" /> : value}
    </Flex>
  </Stack>
);
