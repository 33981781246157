import React, { FC } from 'react';
import { Box, Heading, Text } from '@endpoint/blockparty';
import { getFormattedAddressParts } from 'utils/formatAddress';
import { Address as TransactionAddress } from '@endpoint/endpoint-bff-graphql-schema';

interface AddressProps {
  address: TransactionAddress;
}

export const Address: FC<AddressProps> = ({ address }) => {
  const hasAddress = address?.street1;

  if (hasAddress) {
    const { streetAddress, cityStateZip } = getFormattedAddressParts(address);

    return (
      <Box>
        <Heading data-test-id="transaction-card-street-address" size="fontSize30">
          {streetAddress}
        </Heading>
        <Text data-test-id="transaction-card-city-state-zip" size="fontSize20">
          {cityStateZip}
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Heading data-test-id="transaction-card-street-address" mb="22px" size="fontSize30">
        Order Pending
      </Heading>
    </Box>
  );
};
