import React, { FC } from 'react';
import { Box, ProgressList, ProgressListItem as BPProgressListItem, Text, useDisclosure } from '@endpoint/blockparty';
import {
  MilestoneStatus,
  Milestone,
  TransactionRole,
  Transaction,
  TransactionType,
} from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatDate, MONTH_DAY_TIME } from 'utils/formatDate';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';
import { getUserRoles } from 'utils/transactions/getUserRoles';
import { checkForPinnedItem } from 'utils/transactions/checkForPinnedItem';
import { useAppContext } from 'utils/context';

import { PinnedItem } from '../PinnedItem';

enum ProgressListType {
  completed = 'completed',
  current = 'current',
  upcoming = 'upcoming',
}

interface ProgressListItemProps {
  milestone: Milestone;
  milestones: Milestone[];
  count: number;
  userRoles: TransactionRole[];
  transaction: Transaction;
}

interface ProgressListViewPinnedItemProps {
  onOpen: () => void;
}

/* ======================================= */

function getStatus(
  currentMilestoneStatus: MilestoneStatus,
  previousMilestoneStatus: MilestoneStatus | undefined,
): ProgressListType {
  if (currentMilestoneStatus === MilestoneStatus.COMPLETE) {
    return ProgressListType.completed;
  }

  if (previousMilestoneStatus === MilestoneStatus.COMPLETE) {
    return ProgressListType.current;
  }

  return ProgressListType.upcoming;
}

export const ProgressListView: FC = () => {
  const { user } = useAppContext();
  const { milestones, data } = useTransactionDetailsContext();
  const participants = data?.transaction.participants;
  // @ts-ignore
  const userRoles = getUserRoles(participants, user.id);

  return (
    <Box data-test-id="progress-list-view">
      <ProgressList m="space30">
        {milestones.map((milestone, i) => (
          <ProgressListItem
            key={milestone.name}
            count={i}
            milestone={milestone}
            milestones={milestones}
            transaction={data!.transaction}
            userRoles={userRoles}
          />
        ))}
      </ProgressList>
    </Box>
  );
};

export const ProgressListItem: FC<ProgressListItemProps> = ({
  milestones,
  milestone,
  count,
  userRoles,
  transaction,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { name, completedAt, id } = milestone;
  const milestoneStep = count + 1;
  const last = milestones.length - 1 === count;
  const stepCompleted = milestone.status === MilestoneStatus.COMPLETE;
  const completedDate = stepCompleted ? `${formatDate(completedAt, MONTH_DAY_TIME)}` : null;
  const status = getStatus(milestone.status, milestones[count - 1]?.status);
  const boldMilestoneName = status === ProgressListType.current || (last && stepCompleted);
  const hasPinnedItem = userRoles && checkForPinnedItem({ userRoles, milestone, transaction });

  const itemName = React.useMemo(() => {
    if ((transaction.type?.toUpperCase() as TransactionType) === TransactionType.EQUITY) {
      return name.replaceAll('Seller', 'Borrower');
    }

    if ((transaction.type?.toUpperCase() as TransactionType) === TransactionType.REFINANCE) {
      return name.replaceAll('Buyer', 'Borrower');
    }

    return name;
  }, [name, transaction]);

  return (
    <>
      <BPProgressListItem status={status}>
        <Text as="p" data-test-id="milestone-name" fontWeight={boldMilestoneName ? 'semi' : 'normal'}>
          {count + 1}. {itemName} {hasPinnedItem ? <ProgressListViewPinnedItem onOpen={onOpen} /> : null}
          {last && '🎉'}
        </Text>
        <Text as="p" color="passive" data-test-id="milestone-completed-date" size="fontSize10">
          {completedDate}
        </Text>
      </BPProgressListItem>
      {hasPinnedItem && <PinnedItem id={id} isOpen={isOpen} milestoneStep={milestoneStep} onClose={onClose} />}
    </>
  );
};

export const ProgressListViewPinnedItem: FC<ProgressListViewPinnedItemProps> = ({ onOpen }) => {
  return (
    <>
      {' '}
      <Box
        aria-label="Pinned item button"
        as="button"
        bg="transparent"
        border="none"
        color="blue500"
        cursor="pointer"
        data-test-id="pinned-item-button"
        textDecoration="underline"
        onClick={onOpen}
      >
        View
      </Box>
    </>
  );
};
