import { gql } from '@apollo/client';
import { ZendeskTokenResponse } from '@endpoint/endpoint-bff-graphql-schema';

export const GET_ZENDESK_TOKEN = gql`
  query getZendeskToken($where: ZendeskWhereInput!) {
    getZendeskToken(where: $where) {
      zendeskJWT
    }
  }
`;

export interface ZendeskTokenPayload {
  getZendeskToken: ZendeskTokenResponse;
}
