import { MutationHookOptions, OperationVariables, useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { CreateTodoDocumentInput } from '@endpoint/endpoint-bff-graphql-schema';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { Document } from '@endpoint/endpoint-bff-graphql-schema/dist/types';

import { CREATE_TODO_DOCUMENT } from './mutations';

export const useCreateTodoDocument = (config: MutationHookOptions) => {
  const sentryTransactionCreateDocument = useSentryTransaction('createDocument', 'GraphQL client mutation');

  const [
    createTodoDocumentMutation,
    { data: createTodoDocumentResult, loading: createTodoDocumentLoading, error: createTodoDocumentError },
  ] = useMutation<{ createTodoDocument: Document; input: CreateTodoDocumentInput }, OperationVariables>(
    CREATE_TODO_DOCUMENT,
    config,
  );

  const createTodoDocument = async (input: CreateTodoDocumentInput) => {
    await createTodoDocumentMutation({
      variables: { input },
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: {
          [SENTRY_TRACE_HEADER_NAME]: sentryTransactionCreateDocument.toTraceparent(),
        },
      },
    });

    sentryTransactionCreateDocument.finish();
  };

  return {
    createTodoDocument,
    createTodoDocumentResult,
    createTodoDocumentLoading,
    createTodoDocumentError,
  };
};
