export const HEADING_TEXT =
  'We were unable to verify your identity. Please provide your full Social Security number and date of birth.';

export const SOCIAL_SECURITY_NUMBER_INPUT_TEST_ID = 'ssn-input';
export const SOCIAL_SECURITY_NUMBER_INPUT_LABEL = 'Social Security Number';
export const SOCIAL_SECURITY_NUMBER_INPUT_NAME = 'ssn';
export const SOCIAL_SECURITY_NUMBER_INPUT_PLACEHOLDER = '###-##-####';

export const DATE_OF_BIRTH_INPUT_TEST_ID = 'dob-input';
export const DATE_OF_BIRTH_INPUT_LABEL = 'Date of Birth';
export const DATE_OF_BIRTH_INPUT_NAME = 'dateOfBirth';
export const DATE_OF_BIRTH_INPUT_PLACEHOLDER = 'MM/DD/YYYY';
