import { TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';

export const doDirectChildItemsHaveIcons = (items: TodoAssignmentContent[]): boolean => {
  const directChildrenHaveIcons = items.some((item) => !!item.icon);

  const directCompositeChildrenHaveIcons = items.some((item) => {
    if (!item.children.length) {
      return false;
    }

    return item.children.some(({ icon }) => !!icon);
  });

  return directChildrenHaveIcons || directCompositeChildrenHaveIcons;
};
