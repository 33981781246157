import React, { FC, useContext } from 'react';
import { Box, Flex, Link, Stack, Text } from '@endpoint/blockparty';
import { CompanyContext, useAppConfigsContext } from 'utils/context';

const SignInFooter: FC = () => {
  const { HELP_URL, PRIVACY_URL } = useAppConfigsContext();
  const { companyName } = useContext(CompanyContext);
  const getCurrentYear = new Date().getFullYear();

  return (
    <Box backgroundColor="white" role="banner">
      <Flex margin="0 auto" maxWidth="1440px" pb="space70" pt="space60" px="space50" width="100%">
        <Stack direction="row" spacing="space70">
          <Text size="fontSize10">
            &copy; {getCurrentYear} {companyName}
          </Text>
          <Link href={PRIVACY_URL} isExternal size="fontSize10" textDecoration="underline">
            Privacy & Terms
          </Link>
          <Link href={HELP_URL} isExternal size="fontSize10" textDecoration="underline">
            FAQ
          </Link>
        </Stack>
      </Flex>
    </Box>
  );
};

export default SignInFooter;
