import React, { FC, useContext } from 'react';
import { Box, DrawerSection, Heading, Stack, Text } from '@endpoint/blockparty';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';
import {
  formatWireTransferData,
  WireInstructions,
  WireTransferTransactionInfo,
} from 'components/WireTransferInstructions';
import { Office } from '@endpoint/platform-api-connector/dist/graphql-types';
import { CompanyContext } from 'utils/context';
import { PinnedItemSkeleton } from 'components/Skeleton/PinnedItemSkeleton';
import { useWireInstructions } from 'hooks/useWireInstructions';

import { PinnedItemErrorMessage } from '../../PinnedItemErrorMessage';

interface PinnedItemM2WireTransferProps {
  office: Office;
}

export const PinnedItemM2WireTransfer: FC<PinnedItemM2WireTransferProps> = ({ office }) => {
  const { data: transactionData } = useTransactionDetailsContext();
  const { companyName } = useContext(CompanyContext);
  const { wireTransferBankAccount } = office;

  const accountNumber = wireTransferBankAccount?.accountNumber ?? '';
  const routingNumber = wireTransferBankAccount?.routingNumber ?? '';
  const swiftCode = wireTransferBankAccount?.swiftCode ?? '';
  const { address, fileNum, isUsBank, participants, paymentAmount } = formatWireTransferData(
    // @ts-ignore
    transactionData,
  );
  const { creditBeneficiary, bank, payableName, isLoading, error } = useWireInstructions(!isUsBank);

  if (isLoading) {
    return <PinnedItemSkeleton />;
  }

  if (error) {
    return <PinnedItemErrorMessage />;
  }

  return (
    <DrawerSection>
      <Stack spacing="space70">
        <Box>
          <Heading data-test-id="pinned-header-wire" mb="space50" size="fontSize50">
            How to pay by Wire Transfer ({isUsBank ? 'US Bank' : 'International Bank'})
          </Heading>
          <Text as="p" mb="space60">
            Please follow the instructions below to pay your Earnest Money Deposit by wire transfer. If you’d like to
            change your payment option, please message your {companyName} team.
          </Text>
          <Text as="p">
            Beware of wire fraud! Do not use any wire instructions sent through email or any other source outside of{' '}
            {companyName}. We only send wire instructions through our platform.
          </Text>
        </Box>
        <Box>
          <Text as="p" fontWeight="semi" mb="space50">
            Wire Transfer Instructions
          </Text>
          <WireInstructions
            accountNumber={accountNumber}
            bankAddress={bank}
            cityStateZip={creditBeneficiary.cityStateZip}
            isUsBank={isUsBank}
            payableName={payableName}
            paymentAmount={paymentAmount}
            routingNumber={routingNumber}
            streetAddress={creditBeneficiary.streetAddress}
            suite={creditBeneficiary.suite}
            swiftCode={swiftCode}
          />
        </Box>
        <Box>
          <Text as="p" fontWeight="semi" mb="space50">
            Please Reference the Following
          </Text>
          <WireTransferTransactionInfo
            address={address}
            fileNumber={fileNum}
            isPinnedItem
            participants={participants}
          />
        </Box>
      </Stack>
    </DrawerSection>
  );
};

PinnedItemM2WireTransfer.displayName = 'PinnedItemM2WireTransfer';
