import { gql } from '@apollo/client';

export const CREATE_TRANSACTION_DOCUMENT = gql`
  mutation ($input: CreateTransactionDocumentInput!) {
    createTransactionDocument(input: $input) {
      id
      transactionId
      name
      type
      uploadUrl
    }
  }
`;
