import { useLazyQuery } from '@apollo/client';
import { MeResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { useRedirectOnGetMeError } from 'hooks/useRedirectOnGetMeError';

import { GET_ME } from './queries';

export const useGetUser = () => {
  const sentryTransaction = useSentryTransaction('getMe', 'GraphQL client query');
  const { redirectOnError } = useRedirectOnGetMeError();

  const [getUser, { data, error, loading, refetch }] = useLazyQuery<{ getMe: MeResponse }>(GET_ME, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
  });

  if (error) {
    redirectOnError(error).catch(() => {});
  }

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    getUser,
    user: data?.getMe,
    userLoading: loading,
    userError: error,
    refetch,
  };
};
