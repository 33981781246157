const replacePathname = (url: string, currentPath: string, newPath: string) => url.replace(currentPath, newPath);

const shouldRedirect = (locationPath: string) =>
  locationPath.includes('tasks/') ||
  locationPath.includes('tasks') ||
  locationPath.includes('openPreliminaryTitle') ||
  locationPath.includes('newTitleAndEscrow') ||
  locationPath.includes('prelim');

export const redirectNotificationLinks = () => {
  const locationPath = window.location.pathname;

  if (shouldRedirect(locationPath)) {
    const isLocalHost = window.location.host.includes('localhost');
    const rootUrl = !isLocalHost
      ? `${window.location.protocol}//${window.location.host}`
      : `${window.location.protocol}//localhost:5000`;

    if (locationPath.includes('tasks/')) {
      // To-Do
      const todoPathname = replacePathname(locationPath, 'tasks', 'todo');

      window.location.href = `${rootUrl}${todoPathname}`;
    } else if (locationPath.includes('tasks')) {
      // Transaction Detail
      const todosPathname = replacePathname(locationPath, 'tasks', 'todos');

      window.location.href = `${rootUrl}${todosPathname}`;
    } else if (locationPath.includes('openPreliminaryTitle')) {
      // Open Prelim
      const openPrelimPathname = replacePathname(locationPath, 'openPreliminaryTitle', 'order-reports');

      window.location.href = `${rootUrl}${openPrelimPathname}`;
    } else if (locationPath.includes('newTitleAndEscrow')) {
      // Open Escrow
      const openEscrowPathname = replacePathname(locationPath, 'newTitleAndEscrow', 'open-escrow');

      window.location.href = `${rootUrl}${openEscrowPathname}`;
    } else if (locationPath.includes('prelim')) {
      // Prelim (processing) -> Documents
      const documentsPathname = replacePathname(locationPath, 'prelim', 'documents');

      window.location.href = `${rootUrl}${documentsPathname}`;
    }
  }
};
