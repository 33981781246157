import { useLazyQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { ParticipantRole } from '@endpoint/endpoint-bff-graphql-schema';

import { GET_ME } from './queries';

export interface ParicipantRolesResponse {
  participantRoles?: ParticipantRole[];
}

export const useGetParticipantRoles = () => {
  const sentryTransaction = useSentryTransaction('getMe', 'GraphQL client query');

  const [getParticipantRoles, { data, error, loading }] = useLazyQuery<{ getMe: ParicipantRolesResponse }>(GET_ME, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
    fetchPolicy: 'network-only',
  });

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    getParticipantRoles,
    participantRoles: data?.getMe.participantRoles,
    participantRolesLoading: loading,
    participantRolesError: error,
  };
};
