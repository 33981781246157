import { useQuery } from '@apollo/client';
import { Transaction } from '@endpoint/endpoint-bff-graphql-schema';
import { useCallback, useMemo } from 'react';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { BFF_CLIENT_NAME } from 'Apollo';
import { TransactionDetailsPayload, GET_TRANSACTION_DETAILS } from 'routes/TransactionDetail/Todos/queries';

import { GET_TRANSACTION_BFF } from './queries';
import { buildTransaction } from './transactionBuilder';

export const useTransaction = (transactionId: string) => {
  const { ENABLE_TRANSACTION_DETAILS_FROM_BFF } = useFeatureFlags();

  const {
    data: bffData,
    error: bffError,
    loading: bffLoading,
    refetch: refetchBff,
  } = useQuery<{
    getTransaction: Transaction;
  }>(GET_TRANSACTION_BFF, {
    variables: { transactionId },
    fetchPolicy: 'network-only',
    context: {
      clientName: BFF_CLIENT_NAME,
    },
    skip: !ENABLE_TRANSACTION_DETAILS_FROM_BFF,
  });

  const {
    loading: apiLoading,
    error: apiError,
    data: apiData,
    refetch: refetchApi,
  } = useQuery<TransactionDetailsPayload>(GET_TRANSACTION_DETAILS, {
    fetchPolicy: 'network-only',
    variables: { where: { id: transactionId } },
  });

  const refetch = useCallback(async () => Promise.all([refetchApi(), refetchBff()]), [refetchApi, refetchBff]);

  return useMemo(() => {
    return {
      data: buildTransaction(apiData, bffData?.getTransaction),
      loading: apiLoading || bffLoading,
      error: apiError || bffError,
      refetch,
    };
  }, [apiData, bffData, apiError, apiLoading, bffError, bffLoading, refetch]);
};
