import { useMutation } from '@apollo/client';
import {
  ContactWhereUniqueInput,
  UpdateContactInput,
  UpdateContactResponse,
} from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { UPDATE_CONTACT_MUTATION } from './mutations';

interface OperationVariables {
  where: ContactWhereUniqueInput;
  input: UpdateContactInput;
}

interface UpdateContactMutationResponse {
  updateContact: UpdateContactResponse;
}

export const useUpdateContact = () => {
  const sentryTransaction = useSentryTransaction('updateContact', 'GraphQL client mutation');

  const [updateContact, { data, error, loading }] = useMutation<UpdateContactMutationResponse, OperationVariables>(
    UPDATE_CONTACT_MUTATION,
    {
      context: {
        clientName: BFF_CLIENT_NAME,
        headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
      },
    },
  );

  if ((data || error) && !loading) {
    sentryTransaction.finish();
  }

  return {
    updateContact,
    updatedContact: data?.updateContact,
    updateContactLoading: loading,
    updateContactError: error,
  };
};
