import {
  MilestoneStatus,
  Milestone,
  TransactionRole,
  MilestoneType,
  EmdPaymentType,
  Transaction,
} from '@endpoint/platform-api-connector/dist/graphql-types';

export const checkForPinnedItem = ({
  userRoles,
  milestone,
  transaction,
}: {
  userRoles: TransactionRole[];
  milestone: Milestone;
  transaction: Transaction;
}) => {
  const { type, status } = milestone;
  const isBuyer = userRoles.includes(TransactionRole.BUYER);
  const milestoneNotStarted = status === MilestoneStatus.UPCOMING;
  const milestoneInProgress = status === MilestoneStatus.INTERMEDIATE;
  const milestoneCompleted = status === MilestoneStatus.COMPLETE;

  switch (type) {
    // M2 - Only the Buyer in transaction
    case MilestoneType.EARNEST_MONEY_DEPOSIT: {
      const paymentType = transaction?.emd?.paymentType ?? '';
      // endpoint/platform-api-connector hasn't been updated due to compatibility issues
      // will replace hardcoded string value once it's resolved
      const NO_PINNED_ITEM_PAYMENT_TYPES = [EmdPaymentType.ACH, 'MOBILE_CHECK'];
      const hasPinnedItemPaymentType = !NO_PINNED_ITEM_PAYMENT_TYPES.includes(paymentType);

      return isBuyer && !!paymentType && hasPinnedItemPaymentType && milestoneInProgress;
    }

    // M5 - All roles in transaction when RON and completed
    case MilestoneType.SELLER_SIGNING_SCHEDULED:
      return transaction.sellerSigning?.isRemote && milestoneCompleted;

    // M6 - All roles in transaction
    case MilestoneType.BUYER_CLOSING_DOCUMENTS_SIGNED:
    case MilestoneType.SELLER_CLOSING_DOCUMENTS_SIGNED:
      return !milestoneNotStarted;

    default:
      return false;
  }
};
