// Transactions
export const TRANSACTIONS = 'transactions';
export const TRANSACTION_LISTINGS = `/${TRANSACTIONS}/listings`;
export const TRANSACTION_IN_ESCROW = `/${TRANSACTIONS}/in-escrow`;

// Transaction Details
export const TRANSACTION = 'transaction';
export const TRANSACTION_DETAILS = `${TRANSACTION}/:transactionId`;

// Transaction Details - To-Do
const STEP = 'step/:stepId';

export const TODOS = 'todos';
export const TODO = 'todo/:todoId';
export const TODO_ASSIGNMENT = `${TRANSACTION_DETAILS}/${TODO}`;
export const TODO_ASSIGNMENT_WITH_STEP = `${TODO_ASSIGNMENT}/${STEP}`;

// Settings
export const SETTINGS = 'settings';
export const PROFILE_CANCELLATION = `/${SETTINGS}/cancellation`;
export const SETTINGS_PROFILE = `/${SETTINGS}/profile`;
export const SETTINGS_NOTIFICATION = `/${SETTINGS}/notifications`;
