import React, { FC } from 'react';
import { Address, TransactionParticipant, TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { Box, Text } from '@endpoint/blockparty';
import { getFormattedAddressParts } from 'utils/formatAddress';
import { DataBlock, DataBlockProps, Label } from 'components/DataBlock';

interface WireTransferTransactionInfoProps {
  address: Address;
  fileNumber: string;
  isPinnedItem?: boolean;
  participants: TransactionParticipant[];
  layoutType?: DataBlockProps['layoutType'];
}

/* ======================================= */

export const WireTransferTransactionInfo: FC<WireTransferTransactionInfoProps> = ({
  address,
  fileNumber,
  isPinnedItem,
  participants,
  layoutType = 'stack',
}) => {
  const { streetAddress, cityStateZip } = getFormattedAddressParts(address);
  const buyers = getAllBuyers(participants);

  return (
    <DataBlock layoutType={layoutType}>
      <Box>
        <Label>Property</Label>
        <Text as="p" data-test-id="wire-street-address">
          {streetAddress}
        </Text>
        <Text as="p" data-test-id="wire-city-state-zip">
          {cityStateZip}
        </Text>
      </Box>
      {!isPinnedItem && (
        <>
          <Box>
            <Label>File #</Label>
            <Text as="p" data-test-id="wire-file-number">
              {fileNumber}
            </Text>
          </Box>
          {buyers && (
            <Box>
              <Label>On behalf of</Label>
              {buyers.map((buyer) => (
                <Text key={buyer} as="p" data-test-id="wire-buyer">
                  {buyer}
                </Text>
              ))}
            </Box>
          )}
        </>
      )}
    </DataBlock>
  );
};

const getAllBuyers = (participants: TransactionParticipant[]) => {
  return (
    participants
      ?.filter((p) => p.roles.includes(TransactionRole.BUYER))
      .map(({ user }) => `${user?.firstName} ${user?.lastName}`) || []
  );
};

WireTransferTransactionInfo.displayName = 'WireTransferTransactionInfo';
