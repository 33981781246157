import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Box, Center, EPContainer, EPContent, EPSidebar, Text, Button, Heading } from '@endpoint/blockparty';
import { ANCHORED_BUTTON_OFFSET } from 'consts/responsive';
import { useTodoAssignment } from 'hooks/useTodoAssignment';
import { TodoSkeleton } from 'components/Skeleton';
import { TRANSACTIONS } from 'consts/routes';
import { FormikProps } from 'formik';
import { useTransaction } from 'hooks/useTransaction';
import { useAppContext } from 'utils/context';
import { getUserRoles } from 'utils/transactions/getUserRoles';

import { TodoProgressTracker } from './TodoProgressTracker';
import { TodoStep } from './TodoStep';
import TodoHeader from './TodoHeader';

function Todo() {
  const { getTodoAssignment, todoAssignment, todoAssignmentLoading, todoAssignmentError } = useTodoAssignment();
  const { transactionId = '' } = useParams();
  const { data: transactionData } = useTransaction(transactionId);
  const { user } = useAppContext();
  const userTransactionRoles = getUserRoles(transactionData?.transaction?.participants, user.id);
  const formRef = useRef<FormikProps<GenericObject>>(null);

  useEffect(() => {
    getTodoAssignment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (todoAssignmentError) {
    return (
      <Center flex={1}>
        <Box maxWidth="488px" mx="space60" textAlign="center">
          <Heading fontWeight="normal" marginBottom="space60" size="fontSize60">
            This To-Do cannot be found.
          </Heading>
          <Text as="p" mb="3.5rem" size="fontSize20">
            It appears this To-Do no longer exists, was assigned to another member of this transaction, or has been
            completed already.
          </Text>
          <Button as="a" href={`/${TRANSACTIONS}`} textDecoration="none">
            Back to Transactions
          </Button>
        </Box>
      </Center>
    );
  }

  const { progressTracker, header } = todoAssignment ?? {};

  return (
    <>
      <TodoHeader
        formRef={formRef}
        header={header}
        todoAssignment={todoAssignment}
        userTransactionRoles={userTransactionRoles}
      />
      <EPContainer>
        <EPSidebar>
          <TodoProgressTracker progressTracker={progressTracker} />
        </EPSidebar>
        <EPContent mb={{ base: ANCHORED_BUTTON_OFFSET, md: 'space0' }}>
          {todoAssignmentLoading || !todoAssignment ? (
            <TodoSkeleton />
          ) : (
            <TodoStep
              formRef={formRef}
              getTodoAssignment={getTodoAssignment}
              todoAssignment={todoAssignment}
              userTransactionRoles={userTransactionRoles}
            />
          )}
        </EPContent>
      </EPContainer>
    </>
  );
}

export default Todo;
