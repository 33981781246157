import React, { FC } from 'react';
import { Box, Checkbox, Divider, Flex, Icon, Text } from '@endpoint/blockparty';
import { DocumentTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import { CheckedTransactionDocument } from 'utils/documents';

import { DocumentAdded } from '../DocumentAdded';
import { DocumentName } from '../DocumentName';

interface DocumentRowRow {
  doc: CheckedTransactionDocument;
  onNoteClick: (note: string) => void;
  onCheckboxClick: (id: string) => void;
  reduceOpacity?: boolean;
}

export const DocumentRow: FC<DocumentRowRow> = ({ doc, onNoteClick, onCheckboxClick, reduceOpacity = false }) => {
  const handleNoteClick = (note: string | undefined) => {
    trackAction(DocumentTrackingEvents.VIEW_DOC_NOTE);
    onNoteClick(note || '');
  };

  const handleCheckboxClick = (id: string) => {
    onCheckboxClick(id);
  };

  return (
    <Box key={doc.id}>
      <Flex
        _hover={{ backgroundColor: 'mist0' }}
        backgroundColor={doc.checked ? 'mist0' : 'none'}
        justifyContent="space-between"
        opacity={reduceOpacity ? 0.5 : 1}
      >
        <Checkbox
          alignItems="flex-start"
          data-doc-id={doc.id}
          data-test-id={doc.id}
          isChecked={doc.checked}
          p="space40"
          onClick={() => handleCheckboxClick(doc.id)}
        />
        <Box flex={1}>
          <Flex
            alignContent="space-between"
            alignItems="flex-start"
            flexDirection={{ base: 'column', md: 'row' }}
            p="space40"
          >
            <Box flex={1} maxWidth="281px">
              <DocumentName doc={doc} />
            </Box>
            <Box flex={1} maxWidth="321px">
              <Text color="carbon500" data-test-id="document-type">
                {doc.type || ''}
              </Text>
            </Box>
            <Box flex={1} maxWidth="321px">
              <DocumentAdded doc={doc} />
            </Box>
          </Flex>
        </Box>
        <Text as="p" data-test-id="document-note" p="space40" width="56px">
          {doc.escrowNote && (
            <Icon
              color="mist500"
              cursor="pointer"
              data-test-id="icon-note"
              name="Paper"
              size="large"
              onClick={() => handleNoteClick(doc.escrowNote)}
            />
          )}
        </Text>
      </Flex>
      <Divider />
    </Box>
  );
};
