import * as yup from 'yup';

export const orderMethodSchema = yup.object().shape({
  orderMethod: yup.string().required('orderMethod is required'),
});

export const parcelFormValidationSchema = yup.object().shape({
  parcelNumber: yup
    .string()
    .required('Parcel Number is required')
    .matches(/^[0-9a-zA-Z]{1,35}$/, 'Invalid Parcel Number'),
  county: yup.string().required('Please select a county'),
  state: yup.string().required('Please select a state'),
});

export const addressFormValidationSchema = yup.object().shape({
  propertyAddress: yup.string().required('Please enter an address'),
  zip: yup
    .string()
    .required('Please enter a zipcode')
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid zipcode'),
  city: yup.string().required('Please enter a city'),
  county: yup.string().required('Please select a county'),
  state: yup.string().required('Please select a state'),
});
