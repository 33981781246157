import { gql } from '@apollo/client';
import {
  ContactWhereUniqueInput,
  CreateContactInput,
  CreateContactResponse,
  UpdateContactInput,
  UpdateContactResponse,
} from '@endpoint/endpoint-bff-graphql-schema';

export interface CreateContactData {
  createContact: Pick<CreateContactResponse, 'id' | 'email' | 'phone' | 'contactType'>;
}

export interface CreateContactVariables {
  data: CreateContactInput;
}

export const CREATE_CONTACT = gql`
  mutation CreateContact($data: CreateContactInput!) {
    createContact(input: $data) {
      id
      email
      phone
      contactType
    }
  }
`;

export interface UpdateContactData {
  updateContact: Pick<
    UpdateContactResponse,
    | 'id'
    | 'email'
    | 'phone'
    | 'contactType'
    | 'notificationPreferences'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'suffix'
    | 'licenseNumber'
    | 'stateOfOperation'
    | 'entityName'
    | 'mlsId'
    | 'nmlsId'
    | 'group'
    | 'isOnboarded'
    | 'roles'
    | 'fileNumbers'
  >;
}

export interface UpdateContactVariables {
  input: UpdateContactInput;
  where: ContactWhereUniqueInput;
}

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($where: ContactWhereUniqueInput!, $input: UpdateContactInput!) {
    updateContact(where: $where, input: $input) {
      id
      email
      phone
      contactType
      notificationPreferences {
        email
        sms
      }
      firstName
      middleName
      lastName
      suffix
      licenseNumber
      stateOfOperation
      entityName
      mlsId
      nmlsId
      group
      isOnboarded
      roles
      fileNumbers
    }
  }
`;
