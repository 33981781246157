import React, { FC, createContext, useContext } from 'react';
import { Box, Button, Flex, Heading, Loading, Text, EPContainer, EPSidebar } from '@endpoint/blockparty';
import { Outlet } from 'react-router-dom';
import { SubHeaderContainer } from 'components/SubHeader/SubHeaderContainer';
import { SubHeaderSettings } from 'components/SubHeader/SubHeaderSettings';
import { AlertMessage } from 'components/AlertMessage';
import { Svg } from 'components/Svg';
import { useAppContext } from 'utils/context';
import { openChatWindow } from 'utils/zendesk';
import { trackAction } from 'utils/analytics';
import { ChatEvents } from 'consts/analytics';
import { useGetZendeskTag } from 'hooks/useGetZendeskTag';

interface SettingsContextType {
  user: {
    id?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    realEstateAgentLicenseNumber?: string;
    emailNotifications?: boolean;
    smsNotifications?: boolean;
  };
}

/*= ====================================== */

export const SettingsContext = createContext<SettingsContextType>({ user: {} });
export const useSettingsContext = () => useContext(SettingsContext);

/*= ====================================== */

export const Settings: FC = () => {
  const { user: data, error } = useAppContext();
  const zendeskTag = useGetZendeskTag(data?.lastTransactionViewed?.marketId?.valueOf());
  const isLoading = !data && !error;

  if (error) {
    return (
      <Box m="space60" mb="space0">
        <AlertMessage>Sorry, there seems to be an issue loading your profile settings</AlertMessage>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" m="space60">
        <Loading />
      </Flex>
    );
  }

  return (
    <>
      <SubHeaderContainer>
        <SubHeaderSettings />
      </SubHeaderContainer>
      <EPContainer>
        {data && (
          <>
            <EPSidebar data-test-id="profile-sidebar">
              <Box bg="background" p="space50">
                <Svg height={48} mb="space50" name="Settings" size="initial" width={54} />
                <Heading mb="space50" size="fontSize40">
                  Settings
                </Heading>
                <Box mb="space70">
                  <Text as="p" mb="space60">
                    Request to change your information or notification settings here.
                  </Text>
                  <Text>If you need help, launch a chat by clicking the message button below.</Text>
                </Box>
                <Button
                  data-test-id="start-zendesk-chat"
                  variant="outline"
                  width="100%"
                  onClick={() => {
                    trackAction(ChatEvents.OPEN_CHAT_PROFILE);
                    openChatWindow(data, zendeskTag);
                  }}
                >
                  Message Your Team
                </Button>
              </Box>
            </EPSidebar>
            <Outlet />
          </>
        )}
      </EPContainer>
    </>
  );
};
