import React, { useContext } from 'react';
import { Text } from '@endpoint/blockparty';
import { CompanyContext } from 'utils/context';

export const WebViewDocumentInstructions = () => {
  const { companyName } = useContext(CompanyContext);

  return (
    <>
      <Text as="span" mt="space50">
        Preview or download a document uploaded by you or {companyName}. To upload a file, the size must be less than
        50MB, PDF or JPG only.{' '}
        <Text as="span" fontWeight="semi">
          Please note:{' '}
        </Text>
        only one document can be downloaded at a time.
      </Text>
    </>
  );
};
