import { gql } from '@apollo/client';
import {
  CreateUnityContactInput,
  CreateUnityContactResponse,
  UnityContactWhereUniqueInput,
  UpdateUnityContactInput,
  UpdateUnityContactResponse,
} from '@endpoint/endpoint-bff-graphql-schema';

export interface CreateUnityContactData {
  createUnityContact: CreateUnityContactResponse;
}

export interface UpdateUnityContactData {
  updateUnityContact: UpdateUnityContactResponse;
}

export interface CreateUnityContactVariables {
  input: CreateUnityContactInput;
}

export interface UpdateUnityContactVariables {
  where: UnityContactWhereUniqueInput;
  input: UpdateUnityContactInput;
}

export const CREATE_UNITY_CONTACT = gql`
  mutation createUnityContact($input: CreateUnityContactInput!) {
    createUnityContact(input: $input) {
      id
      firstName
      middleName
      lastName
      email
      phone
      createdAt
      updatedAt
      contactClass
      organizationId
    }
  }
`;

export const UPDATE_UNITY_CONTACT = gql`
  mutation updateUnityContact($where: UnityContactWhereUniqueInput!, $input: UpdateUnityContactInput!) {
    updateUnityContact(where: $where, input: $input) {
      id
      firstName
      middleName
      lastName
      phone
      email
      notificationPreferences {
        sms
        email
      }
      isOnboarded
      contactClass
      licenses {
        id
        licenseNumber
        stateOfOperation
      }
      organizationId
      createdAt
      updatedAt
    }
  }
`;
