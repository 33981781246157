import React, { FC, useContext, useState } from 'react';
import { Box, Heading, Radio, RadioGroup, Stack, Text, Flex, Button } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';
import { trackAction } from 'utils/analytics';
import { SignInTrackingEvents } from 'consts/analytics';
import { AppContext, AppContextType } from 'utils/context';
import { RouteOptions, ForgotType, FORGOT_EMAIL_URL, FORGOT_PASSWORD_URL } from 'Auth/Utilities/Constant';

const ForgotCredentials: FC = () => {
  const navigate = useNavigate();
  const { authFields, setAuthFields }: AppContextType = useContext(AppContext);
  const [forgotCredentialType, setForgotCredentialType] = useState<ForgotType | undefined>(undefined);

  const handleForgotSelection = (value: ForgotType) => {
    setForgotCredentialType(value);
  };

  const goPrevious = () => {
    setAuthFields({ ...authFields, errorCode: null });
    navigate(RouteOptions.SIGN_IN);
  };

  const goNext = () => {
    if (forgotCredentialType === ForgotType.email) {
      trackAction(SignInTrackingEvents.FORGOT_EMAIL_SELECTED);
      navigate(FORGOT_EMAIL_URL);
    } else {
      trackAction(SignInTrackingEvents.FORGOT_PASSWORD_SELECTED);
      navigate(FORGOT_PASSWORD_URL);
    }
  };

  return (
    <>
      <Box mb="space70" textAlign="center">
        <Heading as="h2" mb="space50" size="fontSize60">
          Forgot email or password
        </Heading>

        <Text>No worries, we’ve been there too! What can we help you with?</Text>
      </Box>
      <Stack mb="space80" spacing="space60">
        <RadioGroup
          aria-label="forgot-email-password-selection"
          defaultValue={forgotCredentialType}
          name="forgot-email-password-selection"
        >
          <Radio
            mb={{ base: '20px', md: '6px' }}
            value={ForgotType.email}
            onChange={() => handleForgotSelection(ForgotType.email)}
          >
            I forgot my email address
          </Radio>
          <Radio
            dataTestId="forgot-password-radio"
            value={ForgotType.password}
            onChange={() => handleForgotSelection(ForgotType.password)}
          >
            I forgot my password
          </Radio>
        </RadioGroup>
      </Stack>
      <Flex flex={1} justifyContent="flex-end" width="100%">
        <Button iconLeft="ArrowChevronLeft" mr={{ base: 'space60', md: 'space50' }} variant="link" onClick={goPrevious}>
          Back
        </Button>

        <Button
          dataTestId="submit-forgot-creds-button"
          iconRight="ArrowChevronRight"
          isDisabled={!forgotCredentialType}
          onClick={goNext}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export default ForgotCredentials;
