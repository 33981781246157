import { useMutation } from '@apollo/client';
import {
  OrderPrelimWithAddressInput,
  OrderPrelimWithParcelInput,
  TransactionCreationResult,
} from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { Transaction as SentryTransaction } from '@sentry/types';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { ORDER_PRELIM_WITH_ADDRESS_MUTATION, ORDER_PRELIM_WITH_PARCEL_MUTATION } from './mutations';

const getMutationContext = (sentryTransaction: SentryTransaction) => ({
  clientName: BFF_CLIENT_NAME,
  headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
});

export const useOrderPrelim = () => {
  const sentryTransactionWithAddress = useSentryTransaction('orderPrelimWithAddress', 'GraphQL client mutation');
  const sentryTransactionWithParcel = useSentryTransaction('orderPrelimWithParcel', 'GraphQL client mutation');

  const [
    orderPrelimWithAddress,
    { data: prelimWithAddressData, error: prelimWithAddressError, loading: prelimWithAddressLoading },
  ] = useMutation<{ orderPrelimWithAddress: TransactionCreationResult }, { input: OrderPrelimWithAddressInput }>(
    ORDER_PRELIM_WITH_ADDRESS_MUTATION,
    { context: getMutationContext(sentryTransactionWithAddress) },
  );

  const [
    orderPrelimWithParcel,
    { data: prelimWithParcelData, error: prelimWithParcelError, loading: prelimWithParcelLoading },
  ] = useMutation<{ orderPrelimWithParcel: TransactionCreationResult }, { input: OrderPrelimWithParcelInput }>(
    ORDER_PRELIM_WITH_PARCEL_MUTATION,
    { context: getMutationContext(sentryTransactionWithParcel) },
  );

  if ((prelimWithAddressData || prelimWithAddressError) && !prelimWithAddressLoading) {
    sentryTransactionWithAddress.finish();
  }

  if ((prelimWithParcelData || prelimWithParcelError) && !prelimWithParcelLoading) {
    sentryTransactionWithParcel.finish();
  }

  return {
    orderPrelimWithAddress,
    orderPrelimWithParcel,
    orderPrelimLoading: prelimWithAddressLoading || prelimWithParcelLoading,
    orderPrelimError: prelimWithAddressError ?? prelimWithParcelError,
  };
};
