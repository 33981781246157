import { gql } from '@apollo/client';

export const UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact($where: ContactWhereUniqueInput!, $input: UpdateContactInput!) {
    updateContact(where: $where, input: $input) {
      id
      email
      phone
      contactType
      notificationPreferences {
        email
        sms
      }
      createdAt
      updatedAt
      group
      firstName
      middleName
      lastName
      suffix
      licenseNumber
      mlsId
      stateOfOperation
      entityName
      nmlsId
      roles
      isDeactivated
    }
  }
`;
