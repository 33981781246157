import React, { FC, useContext } from 'react';
import { Box, Heading, Text, Flex, Button } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';
import { RouteOptions } from 'Auth/Utilities/Constant';
import { CompanyContext, CompanyContextType, useAppConfigsContext } from 'utils/context';
import { getCompanyEmail } from 'utils/getCompanyEmail';
import { getCompanyPhoneNumber } from 'utils/getCompanyPhoneNumber';
import { formatPhone } from 'utils/formatPhone';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

const UnverifiedUser: FC = () => {
  const navigate = useNavigate();
  const { companyName }: CompanyContextType = useContext(CompanyContext);
  const { COMPANY_EMAIL } = useAppConfigsContext();
  const { isAppConfigsFromOrgService } = useFeatureFlags();
  const companyEmail = isAppConfigsFromOrgService ? COMPANY_EMAIL : getCompanyEmail(companyName);

  return (
    <Box textAlign="center">
      <Heading as="h1" mb="space50" size="fontSize60">
        Verification needed
      </Heading>
      <Text as="p">
        It looks as though you have not finished creating your account. To ensure your account remains secure,{' '}
        <b>please contact us</b> at{' '}
        <Text as="a" color="blue500" href={`mailto:${companyEmail}`}>
          {companyEmail}
        </Text>{' '}
        or{' '}
        <Text
          as="a"
          color="blue500"
          href={`tel:+1${getCompanyPhoneNumber(companyName).generalInquiries}`}
          textDecoration="none"
        >
          {formatPhone(getCompanyPhoneNumber(companyName).generalInquiries)}
        </Text>{' '}
        and we’ll help get you logged in.
      </Text>
      <Flex flex={1} justifyContent="center" mb="space70" mt="space80" width="100%">
        <Button onClick={() => navigate(RouteOptions.SIGN_IN)}>Back to Sign In</Button>
      </Flex>
    </Box>
  );
};

export default UnverifiedUser;
