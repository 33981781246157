import { gql } from '@apollo/client';
import { Market } from '@endpoint/endpoint-bff-graphql-schema';

export const GET_MARKETS = gql`
  query getMarkets {
    getMarkets {
      id
      name
      state
      active
      acceptsPrelistings
    }
  }
`;

export interface GetMarketsQueryResult {
  getMarkets: Market[];
}
