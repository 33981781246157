import React, { FC, Fragment } from 'react';
import { TodoStatus, Todo, TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { Box, Button, Divider, Flex, Icon, Text } from '@endpoint/blockparty';
import { useNavigate, useParams } from 'react-router-dom';
import { countBy } from 'lodash';
import { Note } from 'components/Note';
import { TRANSACTION } from 'consts/routes';
import { formatDate, isWithin24Hours, MONTH_AND_DAY, MONTH_DAY_TIME } from 'utils/formatDate';
import { TodoTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import { useAppContext } from 'utils/context';

import { getCompletedByName } from './helpers';

interface TodoListProps {
  userTransactionRoles: TransactionRole[];
  todos: Todo[];
}

interface TodoItemProps {
  userTransactionRoles: TransactionRole[];
  todo: Todo;
}

/*= ====================================== */

const inCompleteStatuses = [TodoStatus.DELIVERED, TodoStatus.STARTED];
const completedStatuses = [
  TodoStatus.SUBMITTED,
  TodoStatus.SUBMITTED_BY_OPS,
  TodoStatus.SUBMITTED_BY_PEER,
  TodoStatus.PROCESSED,
];

export const TodoList: FC<TodoListProps> = ({ todos, userTransactionRoles }) => {
  const noTodos = todos.length === 0;
  const todoCount =
    countBy(todos, (todo) => todo.status === TodoStatus.DELIVERED || todo.status === TodoStatus.STARTED).true || 0;
  const allTodosCompleted = todos.every((todo) => completedStatuses.includes(todo.status));

  // get completedAt from assignment and put onto todo
  const todoWithCompletedDate = todos.map((todo) => {
    const [assignment] = todo.assignments || [];

    return { ...todo, completedAt: assignment?.completedAt };
  });
  const sortedTodos = sortTodos(todoWithCompletedDate);

  return (
    <>
      <Box mb="space30">
        <Text as="h5" mb="space30" size="fontSize40">
          Your To-Do&rsquo;s ({todoCount})
        </Text>
        <Divider />
      </Box>
      <Box data-test-id="todo-list">
        {(noTodos || allTodosCompleted) && <TodoEmpty />}

        {sortedTodos.map((todo) => {
          const hasBeenCompleted = completedStatuses.includes(todo.status);

          const TodoComponent = hasBeenCompleted ? TodoCompleted : TodoDefault;

          return (
            <Fragment key={todo.id}>
              <TodoComponent todo={todo} userTransactionRoles={userTransactionRoles} />
              <Divider />
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};

export const TodoDefault: FC<TodoItemProps> = ({ todo, userTransactionRoles }) => {
  const { transactionId } = useParams();
  const { user } = useAppContext();
  const navigate = useNavigate();
  const hasStartedTodo = todo.status === TodoStatus.STARTED;
  const todoAction = hasStartedTodo ? 'Continue' : 'Start';
  const isDueSoon = isWithin24Hours(todo.due) || false;
  const { assignments = [] } = todo;
  const [myAssignment] = assignments || [];
  const trackingData = {
    todoName: todo.name,
    status: todo.status,
    userId: user.id,
    transactionId,
    userTransactionRoles,
  };

  return (
    <Box data-test-id="todo-item">
      <Flex justifyContent="space-between" my="space60">
        <Box mr="space60">
          <Text as="h6" fontWeight="semi" mb="space30" size={{ base: 'fontSize20', md: 'fontSize30' }}>
            {todo.name}
          </Text>
          <Flex>
            {isDueSoon && (
              <Box mr="space30">
                <Icon color="watermelon500" dataTestId="due-soon-icon" name="CircleSmall" />
              </Box>
            )}
            <Box>
              <Text color="carbon500" fontWeight="semi" size="fontSize10">
                Due {formatDate(todo.due, MONTH_AND_DAY, true)}
              </Text>
              {/* Save until 'est. time' is available in back-end */}
              {/* <Text color="carbon500" mx="space30" size="fontSize10">
                |
              </Text>
              <Text color="carbon500" size="fontSize10">
                Est. time 20 min
              </Text> */}
            </Box>
          </Flex>
        </Box>
        <Box>
          <Button
            variant={hasStartedTodo ? 'solid' : 'outline'}
            onClick={() => {
              navigate(`/${TRANSACTION}/${transactionId}/todo/${myAssignment.id}/step/about`);
              trackAction(TodoTrackingEvents.TODO_OPENED, trackingData);
            }}
          >
            {todoAction}
          </Button>
        </Box>
      </Flex>
      {todo.escrowNote && (
        <Box data-test-id="todo-item-note" mb="space60">
          <Note>{todo.escrowNote}</Note>
        </Box>
      )}
    </Box>
  );
};

export const TodoCompleted: FC<TodoItemProps> = ({ todo }) => {
  const { user } = useAppContext();
  const [assignment] = todo?.assignments || [];

  return (
    <Flex data-test-id="todo-item-completed" justifyContent="space-between" my="space60">
      <Box mr="space60">
        <Text as="h6" mb="space30" size={{ base: 'fontSize20', md: 'fontSize30' }}>
          {todo.name}
        </Text>
        <Box>
          <Icon color="aloe500" mr="space30" name="CheckBasic" />
          <Text color="carbon500" fontWeight="semi" size="fontSize10">
            Completed
          </Text>
          {/* // TODO: change date to completedAt when field is available on query */}
          <Text color="carbon500" data-test-id="completed-todo-date" size="fontSize10">
            {' '}
            by{' '}
            {getCompletedByName(
              todo.syncType,
              assignment?.completedByName || '',
              user.firstName || '',
              user.lastName || '',
            )}{' '}
            {formatDate(todo.updatedAt, MONTH_DAY_TIME)}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export const TodoEmpty: FC = () => (
  <Box bg="carbon0" data-test-id="todo-item-empty" mb="space70" mt="space60" px="space70" py="space60">
    <Text as="h6" mb="space30" size="fontSize30" textAlign="center">
      You’re all caught up – way to go!
    </Text>
    <Text as="p" color="carbon500" size="fontSize10" textAlign="center">
      If you have upcoming to-do’s to complete, they will appear here
    </Text>
  </Box>
);

/*
 * Sorts Todos by incomplete -> completed status
 * and then by due date
 */
export function sortTodos(todos: Todo[]) {
  const completedTodos = todos.filter((x) => !inCompleteStatuses.includes(x.status));
  const incompletedTodos = todos.filter((x) => inCompleteStatuses.includes(x.status));

  completedTodos.sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());

  incompletedTodos.sort((a, b) => new Date(a.due).getTime() - new Date(b.due).getTime());

  return [...incompletedTodos, ...completedTodos];
}
