import { useQuery } from '@apollo/client';
import { Market } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { User } from 'utils/context';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { isUnsupportedOrderReportStates } from 'utils/isUnsupportedOrderReportStates';

import { GetMarketsQueryResult, GET_MARKETS } from './queries';

export const useIsSupportedOrderReportState = (user: User): boolean => {
  const { orderReportsUsingOrganizationService } = useFeatureFlags();

  const { data } = useQuery<GetMarketsQueryResult>(GET_MARKETS, {
    context: {
      clientName: BFF_CLIENT_NAME,
    },
    skip: !orderReportsUsingOrganizationService,
  });

  if (!orderReportsUsingOrganizationService) {
    return !isUnsupportedOrderReportStates(user);
  }

  const userMarket = data?.getMarkets?.find((market: Market) => market.state === user.stateSignedUp);

  return userMarket ? userMarket.acceptsPrelistings : false;
};
