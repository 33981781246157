import React, { useContext } from 'react';
import { Box, Grid, Image, Text, ProgressBar, useTheme } from '@endpoint/blockparty';
import { Outlet, useLocation } from 'react-router-dom';
import { TOTAL_ONBOARDING_STEPS } from 'Auth/Utilities/Constant';
import { getCurrentStep } from 'Auth/Utilities/helper';

import SignUpLeft from './SignUpLeft';
import SignUpRight from './SignUpRight';
import { CompanyContext, CompanyContextType } from '../../../utils/context';

const SignUpContainer = () => {
  const { companyName }: CompanyContextType = useContext(CompanyContext);
  const { config } = useTheme();
  const { LOGO_LIGHT, LOGO_LIGHT_FULL } = config;
  const { pathname } = useLocation();
  const currentStep = getCurrentStep(pathname);

  return (
    <Grid
      display={{ base: 'block', md: 'grid' }}
      gridTemplateColumns={{
        md: 'minmax(303px, 303px) minmax(433px, 911px)',
        lg: 'minmax(303px, 529px) minmax(433px, 911px)',
      }}
      height="100%"
      margin="0 auto"
      maxWidth="1440px"
      minHeight="100vh"
    >
      <SignUpLeft role="columnheader">
        <Image alt={companyName} display={{ base: 'none', md: 'block' }} src={LOGO_LIGHT_FULL} width={160} />
        <Image alt={companyName} display={{ base: 'block', md: 'none' }} src={LOGO_LIGHT} width={40} />
        {currentStep !== 0 && (
          <Box
            bottom="0px"
            left="0px"
            mt={{ base: 'space0', md: 'space80' }}
            position={{ base: 'absolute', md: 'initial' }}
            right="0px"
          >
            <ProgressBar
              backgroundColor="transparent"
              barColor="progressBarSignUp"
              border="1px solid"
              borderColor={{ base: 'transparent', md: 'progressBarSignUp' }}
              borderLeftWidth={{ base: '0px', md: '1px' }}
              borderRadius={{ base: 'radiusNone', md: '10px' }}
              borderRightWidth={{ base: '0px', md: '1px' }}
              completed={currentStep}
              steps={TOTAL_ONBOARDING_STEPS}
            />
            <Text color="white" display={{ base: 'none', md: 'block' }} mt="space30" size="fontSize30">
              {`Step ${currentStep} of ${TOTAL_ONBOARDING_STEPS}`}
            </Text>
          </Box>
        )}
      </SignUpLeft>

      <SignUpRight mx={{ base: 'space60', md: 'space70', lg: 'space80' }}>
        <Box mb={{ base: 'space70', lg: 'space80' }}>
          <Outlet />
        </Box>
      </SignUpRight>
    </Grid>
  );
};

export default SignUpContainer;
