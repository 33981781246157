import * as React from 'react';
import { render } from 'react-dom';
import { BlockPartyProvider } from '@endpoint/blockparty';
import { getNonTenantConfig, CONFIG_ID, getConfig } from 'utils/config';
import { initSentry } from 'utils/sentry';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { AppConfigsContext, AppConfigsContextType, CompanyContext, CompanyContextType } from 'utils/context';
import { removeExtraConfig, toCompanyName } from 'utils/whiteLabel';
import { BrowserRouter } from 'react-router-dom';
import { FC } from 'react';
import { NativeAppFallback } from 'components/NativeAppFallback';
import { getTheme } from 'themes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { getOrganizationDetails } from 'utils/getOrganizationData';
import { buildAppConfigsContextValue } from 'utils/buildAppConfigContext';

import App from './App';

const config: AppConfig = getConfig();

(async (): Promise<void> => {
  const { epAppConfigs, shortName, id } = await getOrganizationDetails();

  const appConfigsContextValue: AppConfigsContextType = buildAppConfigsContextValue(id, epAppConfigs);

  const nonTenantConfig = await getNonTenantConfig();

  if (!config) {
    console.error('!!! MISSING ENV CONFIG !!!');
    render(<h1>Missing Configuration. Please contact support.</h1>, document.getElementById('root'));
  } else {
    removeExtraConfig(CONFIG_ID);

    initSentry(nonTenantConfig);

    const LDProvider = await asyncWithLDProvider({
      clientSideID: nonTenantConfig.LAUNCH_DARKLY_CLIENT_ID ?? '',
    });

    const AppWrapper: FC = () => {
      const { isAppConfigsFromOrgService } = useFeatureFlags();
      const companyName = isAppConfigsFromOrgService && shortName ? shortName : toCompanyName(shortName ?? '');
      const CompanyContextValue: CompanyContextType = { companyName };
      const theme = getTheme(companyName);

      return (
        <React.StrictMode>
          <AppConfigsContext.Provider value={appConfigsContextValue}>
            <LDProvider>
              <CompanyContext.Provider value={CompanyContextValue}>
                <BlockPartyProvider theme={theme}>
                  <BrowserRouter>
                    <NativeAppFallback>
                      <App />
                    </NativeAppFallback>
                  </BrowserRouter>
                </BlockPartyProvider>
              </CompanyContext.Provider>
            </LDProvider>
          </AppConfigsContext.Provider>
        </React.StrictMode>
      );
    };

    render(<AppWrapper />, document.getElementById('root'));
  }
})();
