import {
  DocumentUploadValues,
  DocumentUploadValuesUnclean,
  DocumentUploadFileUnclean,
  DocumentUploadFile,
  DocumentUploadValuesBff,
  DocumentUploadFileBff,
} from 'consts/uploadDocument';
import { trackAction } from 'utils/analytics';
import { DocumentTrackingEvents } from 'consts/analytics';
import { CreateTransactionDocumentInput, Document } from '@endpoint/endpoint-bff-graphql-schema';
import axios from 'axios';

import { getFileNameWithoutExtension } from './helper';

export const checkFileFieldIsEmpty = (values: DocumentUploadValuesUnclean): boolean => {
  if (!values.files) return false;

  return values.files.every((file) => file.file && Object.keys(file.file).length === 0);
};

export const hasAllFilesFinishedUploading = (values: DocumentUploadValuesUnclean): boolean => {
  if (!values.files) return true;

  return values.files.every((file) => file.file && Object.values(file.file).every((x) => x.percentage === 100));
};

export const getCleansedFiles = (files: DocumentUploadValuesUnclean): DocumentUploadValues => {
  const filesArray = files.files;
  const cleansedValues: DocumentUploadFile[] = [];

  filesArray.forEach((file: DocumentUploadFileUnclean) => {
    const fileEntries = Object.entries(file.file);
    const fileKey = 'file';
    const fileValue = fileEntries[0][1].response;
    const fixedFile = { [fileKey]: fileValue, documentType: file.documentType };

    cleansedValues.push(fixedFile);
  });

  return { files: cleansedValues };
};

const getCleansedFilesBff = (files: DocumentUploadValuesUnclean): DocumentUploadValuesBff => {
  const filesArray = files.files;
  const cleansedValues: DocumentUploadFileBff[] = [];

  filesArray.forEach((file: DocumentUploadFileUnclean) => {
    const fileEntries = Object.entries(file.file);
    const fileKey = 'file';
    const fileValue = fileEntries[0][1].file;
    const fixedFile = { [fileKey]: fileValue, documentType: file.documentType };

    cleansedValues.push(fixedFile);
  });

  return { files: cleansedValues };
};

export async function uploadDocumentsBff(
  transactionId: string,
  files: DocumentUploadValuesUnclean,
  createTransactionDocument: Function,
): Promise<any> {
  trackAction(DocumentTrackingEvents.UPLOAD_DOC);
  const cleanFiles = getCleansedFilesBff(files);

  const bffCalls: Promise<Document | undefined>[] = [];

  cleanFiles.files.forEach((file) => {
    const input: CreateTransactionDocumentInput = {
      transactionId,
      filename: getFileNameWithoutExtension(file.file?.name),
      documentType: file.documentType,
    };

    bffCalls.push(createTransactionDocument(input));
  });

  const results = await Promise.all(bffCalls);

  await Promise.all(
    results.map(async (documentForUpload, index) => {
      const { name, uploadUrl } = documentForUpload ?? {};

      const type = cleanFiles.files[index].file?.type;
      const file = cleanFiles.files[index].file;

      if (name && uploadUrl && type) {
        const options = {
          headers: {
            'Content-Type': type,
          },
        };

        try {
          await axios.put(uploadUrl, file, options);
        } catch (e) {
          console.log('error', e);
        }
      }
    }),
  );

  return results;
}
