import { gql, useQuery } from '@apollo/client';
import { Office } from '@endpoint/platform-api-connector/dist/graphql-types';

export const GET_TRANSACTION_OFFICE = gql`
  query getTransactionOffice($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      office {
        name
        wireTransferBankAccount {
          accountNumber
          routingNumber
          swiftCode
        }
      }
    }
  }
`;

export const useTransactionOffice = (transactionId: string) => {
  const { loading, error, data } = useQuery<OfficePayload>(GET_TRANSACTION_OFFICE, {
    variables: { where: { id: transactionId } },
  });

  return {
    data,
    loading,
    error,
  };
};

export interface OfficePayload {
  transaction: {
    office: Office;
  };
}
