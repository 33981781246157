import React, { FC } from 'react';
import { Flex, Text, NavBar as SubNavBar, EPSubHeader } from '@endpoint/blockparty';
import { Svg } from 'components/Svg';
import { CENTERED_CONTENT_WIDTH } from 'consts/responsive';
import { USState } from 'utils/getSupportedUSState';

import { TransactionsNonAgentRoutes, TransactionsAgentRoutes } from '../routes';
import { NavItemTransactions } from './NavItemTransactions';

interface SubHeaderTransactionsProps {
  isNonAgent: boolean;
  isTexasUser?: boolean;
  userFirstName?: string;
}

export const SubHeaderTransactions: FC<SubHeaderTransactionsProps> = ({ isNonAgent, userFirstName, isTexasUser }) => {
  const transactionsAgentRoutes = isTexasUser
    ? TransactionsAgentRoutes.filter((item) => !item.statesNotAvailable?.includes(USState.TX))
    : TransactionsAgentRoutes;

  return (
    <EPSubHeader>
      <SubNavBar
        margin="0 auto"
        maxWidth={CENTERED_CONTENT_WIDTH}
        underlineColor="brandPrimary500"
        underlineHeight={4}
        width="100%"
      >
        {(isNonAgent ? TransactionsNonAgentRoutes : transactionsAgentRoutes).map(({ route, label }) => {
          return (
            <NavItemTransactions
              key={`sub-nav-bar-item-${label}`}
              data-test-id={`sub-nav-bar-item-${label}`}
              route={route}
            >
              {label}
            </NavItemTransactions>
          );
        })}
        <Flex display={{ base: 'none', md: 'flex' }} flex={1} justifyContent="flex-end">
          <Flex alignItems="center">
            <Text {...(userFirstName && { 'data-test-id': 'greeting' })} mr="space50" size="fontSize30">
              {userFirstName ? `Hi, ${userFirstName}!` : 'Hi!'}
            </Text>{' '}
            <Svg aria-label="hand wave" height="24px" name="HandWave" width="24px" />
          </Flex>
        </Flex>
      </SubNavBar>
    </EPSubHeader>
  );
};

SubHeaderTransactions.displayName = 'SubHeaderTransactions';
