import { EscrowAssistant, EscrowClient } from '@endpoint/endpoint-bff-graphql-schema';

import { STEP1_TITLE, STEP3_TITLE } from '../Steps/constants';
import { Step } from '../../../components/form/TwoColumnWithProgressContainer';

export const removeStep3 = (steps: Step[]) => steps.filter((step) => step.title !== STEP3_TITLE);

export const removeStep1 = (steps: Step[]) => steps.filter((step) => step.title !== STEP1_TITLE);

export const getFileNameMimeType = (fileName: string) => {
  const mimeType = fileName.substr(fileName.lastIndexOf('.'));

  return { mime_type: mimeType, filename: fileName.replace(mimeType, '') };
};

export function removeEmptyEscrowParticipants(
  participants: Array<EscrowAssistant | EscrowClient>,
): Array<EscrowAssistant | EscrowClient> {
  return participants.filter((participant) => participant.firstName && participant.lastName && participant.email);
}
