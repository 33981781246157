import { gql } from '@apollo/client';
import { SubmitTodoAssignmentStepInput, SubmitTodoAssignmentStepPayload } from '@endpoint/endpoint-bff-graphql-schema';

export interface OperationVariables {
  input: SubmitTodoAssignmentStepInput;
}

export interface SubmitTodoAssignmentStepResponse {
  submitTodoAssignmentStep: SubmitTodoAssignmentStepPayload;
}

export const SUBMIT_TODO_ASSIGNMENT_STEP_MUTATION = gql`
  mutation submitTodoAssignmentStep($input: SubmitTodoAssignmentStepInput!) {
    submitTodoAssignmentStep(input: $input) {
      todoAssignmentStepId
    }
  }
`;
