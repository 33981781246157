import React, { FC } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {
  FAVICON_16_ID,
  FAVICON_32_ID,
  FAVICON_48_ID,
  FAVICON_180_ID,
  FAVICON_192_ID,
  ENDPOINT_DESCRIPTION,
  AHC_DESCRIPTION,
  ENDPOINT_MANIFEST,
  AHC_MANIFEST,
  QE_DESCRIPTION,
  QE_MANIFEST,
  QE_MANIFEST2,
  QE_DESCRIPTION2,
  OPS_TEST_DESCRIPTION,
  OPS_TEST_MANIFEST,
} from 'consts/assets';
import { CompanyName } from 'utils/whiteLabel/types';

import { useCompanyContext, CompanyContextType } from '../../utils/context';

interface HeadTagsProps {
  config: {
    FAVICON_16: string;
    FAVICON_32: string;
    FAVICON_48: string;
    FAVICON_180: string;
    FAVICON_192: string;
  };
}

const getDescription = (companyName: CompanyName) => {
  return {
    Endpoint: ENDPOINT_DESCRIPTION,
    AHC: AHC_DESCRIPTION,
    QETestOrg: QE_DESCRIPTION,
    QETestOrg2: QE_DESCRIPTION2,
    Ops: OPS_TEST_DESCRIPTION,
  }[companyName];
};

const getManifest = (companyName: CompanyName) => {
  return {
    Endpoint: ENDPOINT_MANIFEST,
    AHC: AHC_MANIFEST,
    QETestOrg: QE_MANIFEST,
    QETestOrg2: QE_MANIFEST2,
    Ops: OPS_TEST_MANIFEST,
  }[companyName];
};

export const HeadTags: FC<HeadTagsProps> = ({ config }) => {
  const { FAVICON_16, FAVICON_32, FAVICON_48, FAVICON_180, FAVICON_192 } = config;
  const { companyName }: CompanyContextType = useCompanyContext();
  const description = getDescription(companyName);
  const manifest = getManifest(companyName);
  const publicRoute = `/assets/favicons/${companyName.toLocaleLowerCase()}`;

  return (
    <HelmetProvider>
      <Helmet>
        <link href={manifest} rel="manifest" />
        <title>{companyName}</title>
        <meta content={description} name="description" />
        <link href={FAVICON_16} id={FAVICON_16_ID} rel="icon" sizes="16x16" type="image/png" />
        <link href={FAVICON_32} id={FAVICON_32_ID} rel="icon" sizes="32x32" type="image/png" />
        <link href={FAVICON_48} id={FAVICON_48_ID} rel="icon" sizes="48x48" type="image/png" />
        <link href={`${publicRoute}/favicon-57x57.png`} rel="apple-touch-icon" sizes="57x57" type="image/png" />
        <link href={`${publicRoute}/favicon-60x60.png`} rel="apple-touch-icon" sizes="60x60" type="image/png" />
        <link href={`${publicRoute}/favicon-72x72.png`} rel="apple-touch-icon" sizes="72x72" type="image/png" />
        <link href={`${publicRoute}/favicon-76x76.png`} rel="apple-touch-icon" sizes="76x76" type="image/png" />
        <link href={`${publicRoute}/favicon-114x114.png`} rel="apple-touch-icon" sizes="114x114" type="image/png" />
        <link href={`${publicRoute}/favicon-120x120.png`} rel="apple-touch-icon" sizes="120x120" type="image/png" />
        <link href={`${publicRoute}/favicon-144x144.png`} rel="apple-touch-icon" sizes="144x144" type="image/png" />
        <link href={`${publicRoute}/favicon-152x152.png`} rel="apple-touch-icon" sizes="152x152" type="image/png" />
        <link href={FAVICON_180} id={FAVICON_180_ID} rel="apple-touch-icon" sizes="180x180" type="image/png" />
        <link href={FAVICON_192} id={FAVICON_192_ID} rel="icon" sizes="192x192" type="image/png" />
      </Helmet>
    </HelmetProvider>
  );
};
