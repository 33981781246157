import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { gql, useMutation } from '@apollo/client';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { AuthResponse, SignInInput } from '@endpoint/endpoint-bff-graphql-schema';

export const SIGN_IN_MUTATION = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      session
      challengeName
      authentication {
        accessToken
        idToken
      }
    }
  }
`;

export const useSignInMutation = () => {
  const sentryTransaction = useSentryTransaction('signIn', 'GraphQL client mutation');

  const [runMutation, { data: signInResult, loading: signInLoading, error: signInError }] = useMutation<
    { signIn: AuthResponse },
    { input: SignInInput }
  >(SIGN_IN_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  const signInMutation = (input: SignInInput) => {
    return runMutation({
      variables: { input },
    });
  };

  if ((signInResult || signInError) && !signInLoading) {
    sentryTransaction.finish();
  }

  return {
    signInMutation,
    signInResult,
    signInLoading,
    signInError,
  };
};
