import { clearTokens } from 'utils/auth/storage';

export enum RouteLabels {
  FAQ = 'FAQ',
  OpenEscrow = 'Open Escrow',
  OrderReports = 'Order Reports',
  PrivacyAndTerms = 'Privacy & Terms',
  ProfileSettings = 'Profile Settings',
  SignOut = 'Sign Out',
  Transactions = 'Transactions',
}

export interface HeaderRouteProps {
  /** Text in NavBarItem */
  label: RouteLabels;
  /** URL path */
  route: string;
  /** Keyword that matches route and highlights selected route */
  match: string;
  /** Additional event handler when route has been triggered */
  callback?: () => void;
}

export const NonAgentRoutes = [RouteLabels.Transactions];
export const NonOrderReportsRoutes = [RouteLabels.Transactions, RouteLabels.OpenEscrow];
export const OtherStateRoutes = [RouteLabels.Transactions, RouteLabels.OpenEscrow, RouteLabels.OrderReports];

interface HeaderRightRouteProps extends Omit<HeaderRouteProps, 'match' | 'route'> {
  route?: string;
}

export const HeaderLeftRoutes: HeaderRouteProps[] = [
  {
    label: RouteLabels.Transactions,
    route: '/transactions/in-escrow',
    match: 'transaction',
  },
  {
    label: RouteLabels.OpenEscrow,
    route: '/open-escrow',
    match: 'open-escrow',
  },
  {
    label: RouteLabels.OrderReports,
    route: '/order-reports',
    match: 'report',
  },
];

export const getHeaderRightRoutes = (PRIVACY_URL: string, HELP_URL: string): HeaderRightRouteProps[] => {
  return [
    {
      label: RouteLabels.ProfileSettings,
      route: '/settings/profile',
    },
    {
      label: RouteLabels.PrivacyAndTerms,
      callback: () => {
        window.open(PRIVACY_URL, '_newtab');
      },
    },
    {
      label: RouteLabels.FAQ,
      callback: () => {
        window.open(HELP_URL, '_newtab');
      },
    },
    {
      label: RouteLabels.SignOut,
      callback: async () => {
        await clearTokens();

        window.location.assign('/signin');
      },
    },
  ];
};
