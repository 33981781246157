import React, { FC } from 'react';
import { NavBarItem as EPNavBarItem } from '@endpoint/blockparty';
import { useMatch } from 'react-router-dom';
import { TRANSACTIONS } from 'consts/routes';

interface NavBarItemProps {
  route: string;
}

export const NavItemTransactions: FC<NavBarItemProps> = ({ route, children, ...props }) => {
  const subHeaderRoute = `/${TRANSACTIONS}/${route}`;
  const match = useMatch(subHeaderRoute);

  return (
    <EPNavBarItem color="text" fontWeight={match ? 'semi' : null} href={subHeaderRoute} selected={!!match} {...props}>
      {children}
    </EPNavBarItem>
  );
};
