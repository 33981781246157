import React, { FC, useCallback } from 'react';
import { useFormikContext, useField } from 'formik';
import { Upload } from 'components/Upload';

import { UploadTransactionDocument } from '../../UploadTransactionDocument';

interface UploadFieldProps {
  name: string;
  allowMultiple?: boolean;
  transactionId?: string;
  useGrayUploadSuccess?: boolean;
  documentType?: string;
}

export const UploadField: FC<UploadFieldProps> = ({
  allowMultiple = true,
  name,
  useGrayUploadSuccess = false,
  transactionId,
  documentType,
}) => {
  const { values } = useFormikContext<any>();
  const [field, meta, helpers] = useField(name);

  const updateFiles = (files: any) => {
    helpers.setValue(files);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFileUploaded = useCallback(updateFiles, [name]);
  const files = values[name];

  if (transactionId) {
    return (
      <UploadTransactionDocument
        callback={onFileUploaded}
        files={files}
        multiple={allowMultiple}
        useGrayUploadSuccess={useGrayUploadSuccess}
      />
    );
  }

  return (
    <Upload
      callback={onFileUploaded}
      documentType={documentType}
      files={files}
      multiple={allowMultiple}
      useGrayUploadSuccess={useGrayUploadSuccess}
    />
  );
};
