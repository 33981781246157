import React from 'react';
import { Text, Box, Loading } from '@endpoint/blockparty';
import { useParams } from 'react-router';
import { TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { useTransactionParticipants } from 'hooks/useTransactionParticipants';

export const BuyersWidget = () => {
  const { transactionId = '' } = useParams();
  // TODO: get participant data from BFF rather than platform API
  const { loading, data: buyers } = useTransactionParticipants(transactionId, [TransactionRole.BUYER]);

  return (
    <Box bg="carbon0" borderRadius="radiusDefault" mb="space70" p="space50">
      <Text as="p" color="carbon400" size="fontSize10">
        Buyer
      </Text>
      <Text size="fontSize30">
        {loading ? (
          <Loading data-test-id="loading-indicator" mr="space50" size="medium" />
        ) : (
          buyers.map((buyer) => (
            <Text key={buyer} as="p" data-test-id="buyer-name" size="fontSize30">
              {buyer}
            </Text>
          ))
        )}
      </Text>
    </Box>
  );
};
