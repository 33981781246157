import { gql } from '@apollo/client';

export const getMarketsWithCounties = gql(`
query getMarketsWithCounties($input: GetMarketsInput) {
  getMarkets(input: $input) {
    state
    counties {
      name
    }
  }
}
`);
