import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Auth } from 'aws-amplify';
import { TRACK_GENERIC_ERROR_VIEWED_PAYLOAD, GenericTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { REQUEST_MFA_URL, ErrorCodes, UNVERIFIED_USER_URL } from '../../Auth/Utilities/Constant';
import { AppContext, AppContextType } from '../../utils/context';

export const useForgotPassword = () => {
  const { authFields, setAuthFields, setUser }: AppContextType = useContext(AppContext);
  const navigate = useNavigate();

  const forgotPassword = async (email: string) => {
    try {
      setUser({ loading: true });
      const user = await Auth.forgotPassword(email);

      setAuthFields({
        ...authFields,
        user,
        errorCode: null,
        resetPassword: true,
        email,
        resendCodeAction: async () => Auth.forgotPassword(email),
      });

      navigate(REQUEST_MFA_URL);
    } catch (error: any) {
      if (error.code === ErrorCodes.InvalidParameterException) {
        navigate(UNVERIFIED_USER_URL);
      } else {
        trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
        setAuthFields({ ...authFields, errorCode: error.code });
      }
    } finally {
      setUser({ loading: false });
    }
  };

  return forgotPassword;
};
