import { useQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';

import { LIST_TRANSACTIONS, ListTransactionsPayload, UseListTransactionsProps } from './queries';

export const ITEMS_PER_PAGE = 48;

export const useListTransactions = ({ page, status, skipQuery }: UseListTransactionsProps) => {
  const { data, loading, error } = useQuery<ListTransactionsPayload>(LIST_TRANSACTIONS, {
    variables: { where: { itemsPerPage: ITEMS_PER_PAGE, pageIndex: page - 1, status } },
    context: { clientName: BFF_CLIENT_NAME },
    skip: skipQuery ?? false,
    fetchPolicy: 'cache-and-network',
  });

  return {
    listTransactions: data?.listTransactions.results || [],
    listTransactionsLoading: loading,
    listTransactionsError: error,
    totalItems: data?.listTransactions.totalItems ?? 0,
    totalPages: data?.listTransactions.totalPages ?? 0,
  };
};
