import { useQuery } from '@apollo/client';
import { TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { GET_TRANSACTIONS, TransactionsPayload } from 'routes/Transactions/queries';

import { transactionInEscrowSorter } from './transactionInEscrowSorter';

interface UseTransactionPayloadProps {
  skipQuery?: boolean;
}

export const useTransactionPayload = ({ skipQuery }: UseTransactionPayloadProps = {}) => {
  const { data, loading, error } = useQuery<TransactionsPayload>(GET_TRANSACTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { where: { transactionStatus: TransactionStatus.IN_ESCROW } },
    skip: skipQuery ?? false,
  });

  return {
    transactionPayloadData: data && transactionInEscrowSorter(data),
    transactionPayloadLoading: loading,
    transactionPayloadError: error,
  };
};
