import React, { FC } from 'react';
import { Flex, Text, NavBar as SubNavBar, EPSubHeader } from '@endpoint/blockparty';
import { useAppContext } from 'utils/context';
import { Svg } from 'components/Svg';
import { CENTERED_CONTENT_WIDTH } from 'consts/responsive';

import { TransactionDetailsRoutes, TransactionDetailsRoutesPrelim } from '../routes';
import { NavItemTransaction } from './NavItemTransaction';

interface SubHeaderTransactionDetailProps {
  isPendingTransaction: boolean;
}

export const SubHeaderTransactionDetail: FC<SubHeaderTransactionDetailProps> = ({ isPendingTransaction }) => {
  const { user } = useAppContext();

  return (
    <EPSubHeader overflow="auto">
      <SubNavBar
        margin="0 auto"
        maxWidth={CENTERED_CONTENT_WIDTH}
        underlineColor="brandPrimary500"
        underlineHeight={4}
        width="100%"
      >
        {(isPendingTransaction ? TransactionDetailsRoutesPrelim : TransactionDetailsRoutes).map(({ route, label }) => {
          return (
            <NavItemTransaction
              key={`sub-nav-bar-item-${label}`}
              data-test-id={`sub-nav-bar-item-${label}`}
              route={route}
            >
              {label}
            </NavItemTransaction>
          );
        })}
        <Flex display={{ base: 'none', md: 'flex' }} flex={1} justifyContent="flex-end">
          <Flex alignItems="center">
            <Text mr="space50" size="fontSize30">
              {user?.firstName ? `Hi, ${user?.firstName}!` : 'Hi!'}
            </Text>{' '}
            <Svg aria-label="hand wave" height="24px" name="HandWave" width="24px" />
          </Flex>
        </Flex>
      </SubNavBar>
    </EPSubHeader>
  );
};

SubHeaderTransactionDetail.displayName = 'SubHeaderTransactionDetail';
