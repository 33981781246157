import { useMemo } from 'react';
import { TodoStepId } from 'consts/enums';

import { isWebView, SHOW_OAUTH_ALERT } from '../../utils/webview';

export const useShowNativeOauthAlert = (stepId: string) => {
  return useMemo(() => {
    if (stepId === TodoStepId.PAYMENT_METHOD_OPTIONS && isWebView()) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ event: SHOW_OAUTH_ALERT, payload: {} }));
    }
  }, [stepId]);
};
