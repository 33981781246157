import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export const dwollaValidationErrorStatus = 403;

export const filterDwollaValidationError = (dwollaErrors: ApolloError | undefined): GraphQLError[] => {
  if (dwollaErrors?.graphQLErrors?.length) {
    return dwollaErrors.graphQLErrors.filter(
      (error: GraphQLError) => error.extensions?.exception?.status === dwollaValidationErrorStatus,
    );
  }

  return [];
};

export const isContainsDwollaValidationError = (dwollaErrors: ApolloError | undefined): boolean => {
  return Boolean(filterDwollaValidationError(dwollaErrors)?.length);
};

export const isDwollaSSNError = (dwollaErrors: ApolloError | undefined): boolean => {
  if (dwollaErrors?.graphQLErrors?.length) {
    return Boolean(
      dwollaErrors.graphQLErrors.filter((error: GraphQLError) => error.message?.toLowerCase().includes('ssn')).length,
    );
  }

  return false;
};

export const isDwollaAddressPoBoxError = (dwollaErrors: ApolloError | undefined): boolean => {
  if (dwollaErrors?.graphQLErrors?.length) {
    return Boolean(
      dwollaErrors.graphQLErrors.filter((error: GraphQLError) =>
        error.message?.toLowerCase().includes('cannot be a po box'),
      ).length,
    );
  }

  return false;
};
