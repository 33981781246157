import { createContext } from 'react';

export interface OpenEscrowContextValue {
  currentStep: number;
  setCurrentStep: Function;
  reswareFileNumber: string;
  setReswareFileNumber: Function;
  isFromPrelim: boolean;
  setIsFromPrelim: Function;
}

export const OpenEscrowContext = createContext<OpenEscrowContextValue>({
  currentStep: 1,
  setCurrentStep: () => null,
  reswareFileNumber: '',
  setReswareFileNumber: () => null,
  isFromPrelim: false,
  setIsFromPrelim: () => null,
});
