import { TransactionRole, TransactionParticipant } from '@endpoint/platform-api-connector/dist/graphql-types';

import { TransactionCardDetails } from '..';

/**
 * `getTransactionRole` is specifically used to get a user's role that's
 * formatted in Transaction Card or Transaction Detail property card
 */
export const getTransactionRole = ({ transaction, userId }: TransactionCardDetails) => {
  const { participants } = transaction;

  const matchedUser = participants?.find((participant) => participant.user.id === userId);

  const matchedUserRole = matchedUser?.roles[0];

  let transactionUserRole = 'Buyer:';
  let transactionUserName: string | string[] = 'Not available yet';

  if (matchedUserRole) {
    transactionUserRole = getExpectedUserRole(matchedUserRole);
    transactionUserName = getExpectedUserName(participants, matchedUserRole);
  }

  return {
    label: transactionUserRole,
    value: transactionUserName,
  };
};

export const getExpectedUserRole = (role: TransactionRole) => {
  switch (role) {
    case TransactionRole.BUYER:
    case TransactionRole.SELLER:
    case TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR:
    case TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR:
      return 'Agent:';
    case TransactionRole.SELLING_AGENT:
      return 'Buyer:';
    case TransactionRole.LISTING_AGENT:
      return 'Seller:';
    case TransactionRole.BORROWER:
      return 'M. Broker:';
    case TransactionRole.LOAN_OFFICER:
    case TransactionRole.MORTGAGE_BROKER:
      return 'Borrower:';
    default:
      return 'Buyer:';
  }
};

export const getExpectedUserName = (participants: TransactionParticipant[], role: TransactionRole) => {
  switch (role) {
    case TransactionRole.BUYER:
    case TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR:
      return findRespectiveParticipants(participants, TransactionRole.SELLING_AGENT);
    case TransactionRole.SELLER:
    case TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR:
      return findRespectiveParticipants(participants, TransactionRole.LISTING_AGENT);
    case TransactionRole.SELLING_AGENT:
      return findRespectiveParticipants(participants, TransactionRole.BUYER);
    case TransactionRole.LISTING_AGENT:
      return findRespectiveParticipants(participants, TransactionRole.SELLER);
    case TransactionRole.BORROWER:
      return findRespectiveParticipants(participants, TransactionRole.MORTGAGE_BROKER);
    case TransactionRole.LOAN_OFFICER:
    case TransactionRole.MORTGAGE_BROKER:
      return findRespectiveParticipants(participants, TransactionRole.BORROWER);
    default:
      return 'Not available yet';
  }
};

export const findRespectiveParticipants = (participants: TransactionParticipant[], expectedRole: TransactionRole) => {
  const foundParticipants = participants.filter((participant) => {
    return participant?.roles?.includes(expectedRole);
  });

  const participantNames =
    foundParticipants.length > 0
      ? foundParticipants.map((p) => `${p?.user?.firstName} ${p?.user?.lastName}`).join(', ')
      : 'Not available yet';

  return participantNames;
};
