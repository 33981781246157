import { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from 'utils/analytics';
import { TrackingPages } from 'consts/analytics';
import { CompanyContext } from 'utils/context';
import { RouteOptions } from 'Auth/Utilities/Constant';

const mapRoutesToName: Record<string, TrackingPages> = {
  profile: TrackingPages.PROFILE,
  notifications: TrackingPages.NOTIFICATION_SETTINGS,
  'in-escrow': TrackingPages.TRANSACTIONS_IN_ESCROW,
  closed: TrackingPages.TRANSACTIONS_CLOSED,
  listings: TrackingPages.TRANSACTIONS_LISTING,
  todos: TrackingPages.TRANSACTION_TODOS,
  documents: TrackingPages.TRANSACTION_DOCUMENTS,
  contacts: TrackingPages.TRANSACTION_CONTACTS,
  'open-escrow': TrackingPages.OPEN_ESCROW,
  'order-reports': TrackingPages.ORDER_REPORTS,
  'signin/requestmfa': TrackingPages.SIGN_IN_MFA,
  'signup/requestmfa': TrackingPages.SIGN_UP_MFA,
  signup: TrackingPages.CREATE_NEW_ACCOUNT,
  [RouteOptions.UNVERIFIED_USER]: TrackingPages.VERIFICATION_NEEDED,
  [RouteOptions.SET_UP_PROFILE]: TrackingPages.SET_PROFILE,
  [RouteOptions.SELECT_ROLE]: TrackingPages.SELECT_ROLE,
  [RouteOptions.NOTIFICATION_PREFERENCE]: TrackingPages.NOTIFICATION_PREFERENCES,
};

export const PageTracker: FC = () => {
  const { companyName } = useContext(CompanyContext);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.includes('requestmfa')
      ? location.pathname.split('/').slice(-2).join('/')
      : location.pathname.split('/').pop();

    if (path) {
      const pageName = mapRoutesToName[path];

      if (pageName) {
        trackPage(pageName);
      }
    }
  }, [location, companyName]);

  return null;
};

export default PageTracker;
