import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { EnvironmentConfig } from 'utils/config';

const SENTRY_DSN: string = 'https://f30f26f59cd344f796643d64b988368f@o174251.ingest.sentry.io/5262158';

const sentryOptions = {
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Ignore failed localStorage capability checks which will throw errors if user has strict browser
    // privacy settings or is in private browsing mode
    "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
    // This is also almost always due to storage capability checks being denied due to strict browser
    // privacy settings or private browsing mode
    'SecurityError: The operation is insecure.',
    // Generic / inspecific errors usually caught by window handler w/o any valuable context
    /object Object/i,
    'Cannot redefine property: BetterJsPop',
    "Cannot read property '_avast_submit' of undefined",
    "Cannot read properties of undefined (reading '_avast_submit')",
    // Chrome Bug: https://bugs.chromium.org/p/chromium/issues/detail?id=590375
    "undefined is not an object (evaluating '__gCrWeb.autofill.extractForms')",
    /from accessing a frame with origin "https:\/\/staticxx\.facebook\.com"/i,
    /from accessing a frame with origin "https:\/\/accounts\.google\.com"/i,
    "Can't execute code from a freed script",
    "Can't find variable: auto",
    "Can't find variable: chrome",
    /Can't find variable: SymBrowser_/i,
    "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
    /*
  Windows IE 11 desktop heap memory limits:
  https://support.microsoft.com/en-us/help/947246/you-may-
  receive-an-out-of-memory-error-message-because-of-the-desktop
  */
    'Out of memory',
    // Yandex referrer error
    'Access is denied.',
    'GetCurrentPosition component failed - err:',
    'ResizeObserver loop limit exceeded',
    // mobile users' connection cuts out
    'The network connection was lost.',
    'The Internet connection appears to be offline.',
    'window.FS.event is not a function.',
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  whitelistUrls: [],
};

export function initSentry(config: EnvironmentConfig): void {
  const { ENVIRONMENT, BFF_URL, GRAPHQL_URL } = config;

  const browserTracing = new TracingIntegrations.BrowserTracing({
    tracingOrigins: [BFF_URL, GRAPHQL_URL],
  });

  const isProductionEnv = process.env.NODE_ENV === 'production';

  if (isProductionEnv || ENVIRONMENT !== 'local') {
    Sentry.init({
      ...sentryOptions,
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: window.buildVersion,
      integrations: [
        browserTracing,
        new Sentry.Integrations.TryCatch({
          eventTarget: false,
        }),
      ],
      sampleRate: isProductionEnv ? 1 : 0.25,
      tracesSampleRate: isProductionEnv ? 0.2 : 0,
    });
  }
}

export const SENTRY_TRACE_HEADER_NAME = 'sentry-trace';
