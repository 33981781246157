import { gql } from '@apollo/client';
import { PaymentInstructions } from '@endpoint/endpoint-bff-graphql-schema';

export interface PaymentInstructionsPayload {
  getPaymentInstructions: PaymentInstructions;
}

export const GET_CHECK_PAYMENT_INSTRUCTIONS = gql`
  query getPaymentInstructions($input: GetPaymentInstructionsInput!) {
    getPaymentInstructions(input: $input) {
      checkPayableToName
      checkMailToAddress {
        street1
        street2
        city
        state
        zip
      }
    }
  }
`;

export const GET_WIRE_PAYMENT_INSTRUCTIONS = gql`
  query getPaymentInstructions($input: GetPaymentInstructionsInput!) {
    getPaymentInstructions(input: $input) {
      payableToName
      wireTransferBankAddress {
        name
        street1
        street2
        city
        state
        zip
      }
      creditBeneficiaryAddress {
        street1
        street2
        city
        state
        zip
      }
    }
  }
`;
