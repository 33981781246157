import { FC } from 'react';
import { usePlaid } from 'hooks/usePlaid';

interface PlaidProps {
  linkToken: string;
  transactionId: string;
  todoAssignmentId: string;
  onAccountInfoReceived: Function;
  onErrorReceived: Function;
}

export const Plaid: FC<PlaidProps> = (props) => {
  const { linkToken, onAccountInfoReceived, transactionId, onErrorReceived, todoAssignmentId } = props;
  const input = {
    linkToken,
    transactionId,
    todoAssignmentId,
    onAccountInfoReceived,
    onErrorReceived,
    openOnLaunch: true,
  };

  usePlaid(input);

  return null;
};
