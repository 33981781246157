import React, { FC, useMemo, useEffect, useCallback } from 'react';
import { Box, Icon, Text, Modal, ModalContent, useDisclosure, Image, ModalHeader } from '@endpoint/blockparty';
import { useParams, useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import { isWebView } from 'utils/webview';
import { trackAction } from 'utils/analytics';
import { truncateFileName } from 'utils/fileUpload';
import { useAppContext } from 'utils/context';
import { DocumentTrackingEvents } from 'consts/analytics';
import { PdfViewer } from 'components/PdfViewer';
import { CheckedTransactionDocument } from 'utils/documents';

import { getFileExtension, renderMimetype } from '../../helper';

interface DocumentNameProps {
  doc: CheckedTransactionDocument;
}

interface DocumentPreviewProps {
  isOpen: boolean;
  onClose: () => void;
  docUrl: string;
  doc: CheckedTransactionDocument;
}

/* ======================================= */

export const DocumentName: FC<DocumentNameProps> = ({ doc }) => {
  const { transactionId } = useParams();
  const location = useLocation();
  const {
    user: { id: userId },
  } = useAppContext();

  const { id: documentId, mimeType: documentMimeType, name: documentName } = doc;

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (doc.url) {
      return;
    }

    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `User ${userId} | Transaction ${transactionId} | Document ${documentId}'s url is missing.`,
    });
  }, [doc.url, documentId, transactionId, userId]);

  const openDocumentPreview = useCallback(() => {
    trackAction(DocumentTrackingEvents.VIEW_DOC);
    onOpen();
  }, [onOpen]);

  /**
   * For doc drop deep linking
   */
  const docIdFromDeepLink = location.pathname.split('/').pop();

  useEffect(() => {
    if (documentId === docIdFromDeepLink) {
      openDocumentPreview();
    }
  }, [documentId, docIdFromDeepLink, openDocumentPreview]);

  return (
    <>
      <Box
        as="button"
        bg="transparent"
        border="none"
        cursor="pointer"
        data-test-id="document-name"
        textAlign="left"
        onClick={openDocumentPreview}
      >
        <Icon color="blue500" name={renderMimetype(documentMimeType)} size="large" />{' '}
        <Text _hover={{ textDecoration: 'underline' }}>
          {`${truncateFileName(documentName, getFileExtension(documentMimeType))}`}
        </Text>
      </Box>
      {doc.url && <DocumentPreview doc={doc} docUrl={doc.url} isOpen={isOpen} onClose={onClose} />}
    </>
  );
};

export const DocumentPreview: FC<DocumentPreviewProps> = ({ docUrl, doc, isOpen, onClose }) => {
  const { mimeType: documentMimeType, name: documentName } = doc;
  const isPdf = documentMimeType?.includes('pdf');

  return useMemo(() => {
    return (
      <Modal id="open-escrow-modal" isOpen={isOpen} onClose={onClose}>
        <ModalContent height={isPdf || isWebView() ? '80vh' : null} maxWidth={700}>
          <ModalHeader p="space40">
            <Text>{documentName}</Text>
          </ModalHeader>
          {isPdf ? (
            <PdfViewer className="fs-exclude dd-privacy-hidden" height="100%" source={docUrl} />
          ) : (
            <Image
              alt={documentName}
              className="fs-exclude dd-privacy-hidden"
              display="block"
              height="100%"
              margin="0 auto"
              maxWidth="100%"
              src={docUrl}
              type={documentMimeType}
            />
          )}
        </ModalContent>
      </Modal>
    );
  }, [isOpen, onClose, isPdf, documentName, docUrl, documentMimeType]);
};
