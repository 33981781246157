import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLegacyErrorCode, isIncorrectAttemptV2 } from 'Auth/Utilities/helper';
import { useConfirmSignInMutation } from 'hooks/auth/useConfirmSignInMutation';
import { useSignInMutation } from 'hooks/auth/useSignInMutation';
import {
  MIN_RESEND_CODE_INTERVAL_MS,
  RESET_PASSWORD_URL,
  RESET_SUCCESS_MFA_URL,
  ACCOUNT_LOCKED_URL,
  ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_IN,
  ErrorCodes,
  ACCOUNT_LOCKED_URL_SIGN_UP,
  ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_UP,
} from 'Auth/Utilities/Constant';
import {
  MFATrackingEvents,
  TRACK_MFA_SUBMIT_PAYLOAD,
  TRACK_MFA_RESEND_CODE_PAYLOAD,
  GenericTrackingEvents,
  TRACK_GENERIC_ERROR_VIEWED_PAYLOAD,
} from 'consts/analytics';
import { trackAction } from 'utils/analytics';
import { AppContextType, AppContext } from 'utils/context';
import { useHandleAuthResponse } from 'hooks/auth/useHandleAuthResponse';
import { useForgotPassword } from 'hooks/auth/useForgotPassword';
import useOrganizationId from 'hooks/useOrganizationId';

import { useAuth } from '../useAuth';

const isResendAvailable = (codeSentAt: Date) =>
  !codeSentAt || new Date().getTime() - codeSentAt.getTime() > MIN_RESEND_CODE_INTERVAL_MS;

export const useCodeVerify = () => {
  const { data } = useAuth();
  const forgotPassword = useForgotPassword();
  const { authFields, setAuthFields, setUser }: AppContextType = useContext(AppContext);
  const navigate = useNavigate();
  const [codeSentAt, setCodeSentAt] = useState<Date>(undefined!);
  const handleAuthResponse = useHandleAuthResponse();
  const { signInMutation } = useSignInMutation();
  const organizationId = useOrganizationId();

  const { confirmSignIn } = useConfirmSignInMutation();

  const codeVerify = async (code: string) => {
    try {
      if (authFields.resetPassword) {
        // forgot password flow for verified users
        setAuthFields({ ...authFields, mfa: code });
        navigate(RESET_PASSWORD_URL);
      } else if (authFields.passwordExpired) {
        const response = await confirmSignIn({
          organizationId,
          username: data.username,
          session: data.session,
          mfaCode: code,
        });

        if (response?.data?.confirmSignIn) {
          setAuthFields({
            ...authFields,
            passwordExpired: false,
            accessToken: response.data.confirmSignIn.authentication?.accessToken,
            idToken: response.data.confirmSignIn.authentication?.idToken,
          });

          navigate(RESET_SUCCESS_MFA_URL);
        }
      } else {
        setUser((user: any) => ({ ...user, loading: true }));
        trackAction(MFATrackingEvents.SUBMITTED_MFA_CODE, TRACK_MFA_SUBMIT_PAYLOAD);

        const response = await confirmSignIn({
          organizationId,
          username: data.username,
          session: data.session,
          mfaCode: code,
        });

        if (response?.data?.confirmSignIn) {
          setUser((user: any) => ({ ...user, refetch: true }));
          handleAuthResponse(response.data.confirmSignIn, undefined, authFields.navigateToAfterLogin);
        }
      }
    } catch (error: any) {
      if (isIncorrectAttemptV2(error)) {
        trackAction(MFATrackingEvents.MAX_MFA_ATTEMPTS_REACHED, TRACK_MFA_SUBMIT_PAYLOAD);
        navigate(ACCOUNT_LOCKED_URL, {
          state: {
            accountLockedInstructions: ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_IN,
          },
        });
      } else {
        const errorCode = getLegacyErrorCode(error?.message ?? error);

        trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
        setAuthFields({ ...authFields, errorCode });
      }
    } finally {
      setUser((user: any) => ({ ...user, loading: false }));
    }
  };

  const codeResend = async () => {
    try {
      if (isResendAvailable(codeSentAt)) {
        if (authFields.resetPassword) {
          void forgotPassword(data.username);
        } else {
          trackAction(MFATrackingEvents.RESEND_MFA_CODE, TRACK_MFA_RESEND_CODE_PAYLOAD);
          setUser((user: any) => ({ ...user, loading: true }));

          const response = await signInMutation({
            organizationId,
            username: data.username,
            password: data.password,
          });

          if (response?.data?.signIn) {
            handleAuthResponse(response.data.signIn);
            setAuthFields({
              ...authFields,
              errorCode: ErrorCodes.CustomCodeResend,
              loading: false,
            });
          }
        }

        setCodeSentAt(new Date());
      }
    } catch (error: any) {
      if (isIncorrectAttemptV2(error)) {
        trackAction(MFATrackingEvents.MAX_MFA_ATTEMPTS_REACHED, TRACK_MFA_RESEND_CODE_PAYLOAD);

        navigate(ACCOUNT_LOCKED_URL_SIGN_UP, {
          state: {
            accountLockedInstructions: ACCOUNT_LOCKED_INSTRUCTIONS_SIGN_UP,
          },
        });

        trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
        setAuthFields({ ...authFields, errorMessage: error });
      } else {
        const errorCode = getLegacyErrorCode(error?.message ?? error);

        trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
        setAuthFields({ ...authFields, errorCode });
      }
    } finally {
      setUser((user: any) => ({ ...user, loading: false }));
    }
  };

  return {
    codeVerify,
    codeResend,
  };
};
