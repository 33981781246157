import { gql } from '@apollo/client';

export const UPSERT_DWOLLA_CUSTOMER = gql`
  mutation UpsertDwollaCustomer($data: DwollaCustomerInput!) {
    upsertDwollaCustomer(data: $data) {
      id
      status
      kbaSession {
        id
        questions {
          id
          text
          answers {
            id
            text
          }
        }
      }
    }
  }
`;

export const VERIFY_KBA = gql`
  mutation VerifyKba($data: DwollaKbaVerification!) {
    verifyKba(data: $data) {
      status
    }
  }
`;

export const SEND_TRANSFER = gql`
  mutation SendTransfer($data: DwollaTransferInput!) {
    sendTransfer(data: $data) {
      depositId
    }
  }
`;
