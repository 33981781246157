import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { ErrorCodes, REQUEST_MFA_URL } from 'Auth/Utilities/Constant';
import { AppContext, AppContextType } from 'utils/context';
import { gql, useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { getLegacyErrorCode } from 'Auth/Utilities/helper';
import useOrganizationId from 'hooks/useOrganizationId';
import { ConfirmForgotPasswordInput, ConfirmForgotPasswordResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { GenericTrackingEvents, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { useSentryTransaction } from '../../useSentryTransaction';

export const CONFIRM_FORGOT_PASSWORD_MUTATION = gql`
  mutation ConfirmForgotPassword($input: ConfirmForgotPasswordInput!) {
    confirmForgotPassword(input: $input) {
      success
      errorMessage
    }
  }
`;

export const useResetPassword = () => {
  const sentryTransaction = useSentryTransaction('confirmForgotPassword', 'GraphQL client mutation');
  const { authFields, setAuthFields, setUser }: AppContextType = useContext(AppContext);
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const [confirmForgotPasswordMutation] = useMutation<
    { confirmForgotPassword: ConfirmForgotPasswordResponse },
    { input: ConfirmForgotPasswordInput }
  >(CONFIRM_FORGOT_PASSWORD_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  return async (password: string) => {
    try {
      setUser((user: any) => ({ ...user, loading: true }));

      const { data } = await confirmForgotPasswordMutation({
        variables: {
          input: {
            username: authFields.email,
            organizationId,
            code: authFields.mfa,
            password,
          },
        },
      });

      if (!data?.confirmForgotPassword?.success) {
        throw new Error(data?.confirmForgotPassword?.errorMessage ?? 'An unknown error occurred');
      }

      setAuthFields({ ...authFields, errorCode: null, resetPassword: false, mfa: '' });
    } catch (error: any) {
      const errorCode = getLegacyErrorCode(error?.message ?? error);

      if (errorCode === ErrorCodes.CodeMismatchException) {
        navigate(REQUEST_MFA_URL);
      } else {
        // Forgot password AND reset pw return back the same error but only forgot pw can map the error to an input,
        //  therefore for all other errors we pass 'unknown' so the <Alert /> component will display a general error.
        setAuthFields({ ...authFields, errorCode: 'unknown' });
      }

      trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
    } finally {
      setUser((user: any) => ({ ...user, loading: false }));
    }
  };
};
