import React, { FC } from 'react';
import { Heading, Box, Text } from '@endpoint/blockparty';
import { FormikGroup, FormikTextArea } from 'components/form/FormikBlockparty';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';

import { FormikValue } from '../..';

export const Step6: FC<CurrentStepComponentProps<FormikValue>> = ({
  currentStepPosition = 6,
  setCurrentStepPosition,
}) => {
  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Do you have any additional information about this transaction that you would like us to know?
      </Heading>
      <Box mb="space60">
        <FormikGroup
          component={FormikTextArea}
          label="(optional)"
          name="optionalNotes"
          placeholder="e.g. I have another addendum on its way. I'll submit in a few days."
        />
      </Box>
      <NavigationButton
        goNext={() => setCurrentStepPosition(currentStepPosition + 1)}
        goPrevious={() => setCurrentStepPosition(currentStepPosition - 1)}
        iconRight="ArrowChevronRight"
      >
        Next
      </NavigationButton>
    </>
  );
};

export const Summary = (formikValues: FormikValue) => {
  return (
    <Box>
      <Text data-test-id="optional-notes">{formikValues.optionalNotes || 'N/A'}</Text>
    </Box>
  );
};
