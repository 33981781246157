import { Transaction, TransactionStatus, TransactionRole } from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatCurrency } from 'utils/formatCurrency';

import { DetailList, displayDateStatus, TransactionCardDetails } from '..';
import { getListingDetails } from '../getListingDetails';
import { getTransactionRole } from '../getTransactionRole';
import { getSigningDetails } from '../getSigningDetails';
import { getUserRoles } from '../getUserRoles';

export const baseSaleDetails = (t: Transaction): DetailList[] => [
  {
    label: 'File #',
    value: t.fileNum ? t.fileNum : 'Not available yet',
  },
  {
    label: 'Price:',
    value: t.price ? formatCurrency(t.price) : 'Not available yet',
  },
  { ...getSigningDetails(t) },
  { ...displayDateStatus(t) },
];

export const getSaleDetails = ({ transaction, userId }: TransactionCardDetails): DetailList[] => {
  const { status, participants } = transaction;

  switch (status) {
    case TransactionStatus.PRELISTING: {
      if (userId) {
        const userRole = getUserRoles(participants, userId);
        const isTransactionCoordinator =
          userRole.includes(TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR) ||
          userRole.includes(TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR);

        if (isTransactionCoordinator) {
          // displays agents when user is TC
          return [getTransactionRole({ transaction, userId }), ...getListingDetails(transaction)];
        }
      }

      return [...getListingDetails(transaction)];
    }

    case TransactionStatus.IN_ESCROW:
      return [getTransactionRole({ transaction, userId }), ...baseSaleDetails(transaction)];

    default:
      return [getTransactionRole({ transaction, userId }), ...baseSaleDetails(transaction)];
  }
};
