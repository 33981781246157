import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useToast, Heading, Text, Box, Checkbox, Flex, Button } from '@endpoint/blockparty';
import { DwollaTransferInput, TodoButtonNavigation } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { Log } from 'utils/logger';
import * as Sentry from '@sentry/react';
import { CompanyContext } from 'utils/context';
import { useDwolla } from 'hooks/useDwolla';
import { useSubmitTodoAssignmentStep } from 'hooks/useSubmitTodoAssignmentStep';
import { getTodoStepUrl } from 'routes/TransactionDetail/Todo/helpers/navigation';
import { AlertMessage } from 'components/AlertMessage';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { trackEmdAnalytics } from 'routes/TransactionDetail/Todo/TodoStep/analytics/index';

import { AccountDetails } from './accountDetails';
import { EMDError } from '../Error';
import { ErrorLoadingStatesWidget } from '../../../ErrorLoadingStatesWidget';

interface ConfirmTransferProps {
  transactionId: string;
  todoAssignmentId: string;
  accountNumber?: string | undefined;
  accountName?: string | undefined;
  accountInfoLoading: boolean;
  amount: number;
}

export const ConfirmTransfer: FC<ConfirmTransferProps> = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { todoId = '', stepId = '' } = useParams();

  const { todoAssignment, setShowTodoStepNavigationPanel, getTodoAssignment } = useTodoStepContext();

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const { accountNumber, accountName, accountInfoLoading, amount, transactionId } = props;
  const [stepAnswerSubmitted, setStepAnswerSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { companyName } = useContext(CompanyContext);

  const { submitTodoAssignmentStep } = useSubmitTodoAssignmentStep();
  const { sendTransfer, transferResults, transferLoading, transferError } = useDwolla();

  const handleCheckboxSelected = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleSubmit = async () => {
    if (termsAccepted) {
      setIsLoading(true);
      const input: DwollaTransferInput = {
        transactionId: props.transactionId,
        todoAssignmentId: props.todoAssignmentId,
      };

      await sendTransfer({ variables: { data: input }, context: { clientName: BFF_CLIENT_NAME } });
    }
  };

  const submitTodo = async () => {
    setStepAnswerSubmitted(true);

    try {
      const response = await submitTodoAssignmentStep({
        variables: {
          input: {
            todoAssignmentId: todoId,
            currentStepId: stepId,
            direction: TodoButtonNavigation.NEXT,
            answer: { depositId: transferResults?.sendTransfer.depositId, EMDAmount: amount },
            hasSuccessPage: todoAssignment.hasSuccessPage,
            hasSummaryPage: todoAssignment.hasSummaryPage,
          },
        },
      });

      if (transferResults?.sendTransfer.depositId && amount) {
        trackEmdAnalytics({
          todoName: todoAssignment.progressTracker.name || 'Not Available',
          answer: { depositId: transferResults.sendTransfer.depositId, EMDAmount: amount },
        });
      }

      const nextStepId = response.data?.submitTodoAssignmentStep?.todoAssignmentStepId;

      if (nextStepId) {
        const stepUrl = getTodoStepUrl({ transactionId, todoId, stepId: nextStepId });

        navigate(stepUrl);
        getTodoAssignment({ variables: { todoAssignmentId: todoId, stepId: nextStepId } });
      }

      setShowTodoStepNavigationPanel(true);
    } catch (e) {
      toast({
        duration: 5000,
        position: 'top-right',
        render: ({ onClose }) => (
          <Box mr="space50" mt="space50">
            <AlertMessage isCloseable onClose={onClose}>
              <p>There was an error updating your to-do.</p>
            </AlertMessage>
          </Box>
        ),
      });
    }
  };

  useEffect(() => {
    if (!stepAnswerSubmitted) {
      if (!transferLoading && transferResults) {
        submitTodo().catch((e) => {
          throw e;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepAnswerSubmitted, transferLoading, transferResults]);

  if (!transferLoading && transferError) {
    Sentry.captureEvent({
      level: Sentry.Severity.Info,
      message: `Failed to transfer funds for transactionId: ${transactionId}`,
    });

    Log(`Error occured while transfering funds: ${JSON.stringify(transferError)}`);

    return <EMDError validationError={false} />;
  }

  if (isLoading || accountInfoLoading) {
    return <ErrorLoadingStatesWidget error={false} loading />;
  }

  return (
    <Flex direction="column">
      <Heading as="h1" mb="space50" size="fontSize50">
        Confirm your Earnest Money Deposit transfer.
      </Heading>
      <Text>
        {companyName} uses{' '}
        <a href="https://www.dwolla.com" rel="noopener noreferrer" target="_blank">
          Dwolla
        </a>{' '}
        to quickly and securely send money through same-day electronic, bank-to-bank transfer (ACH transfer).
      </Text>
      <Text mt="space50">
        By initiating this transfer, you&apos;re agreeing to Dwolla&apos;s{' '}
        <a href="https://www.dwolla.com/legal/tos/" rel="noopener noreferrer" target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://www.dwolla.com/legal/privacy/" rel="noopener noreferrer" target="_blank">
          Privacy Policy
        </a>
        . Once you click &quot;Confirm Transfer&quot; the payment will be initiated and your payment method cannot be
        changed.
      </Text>
      <AccountDetails accountName={accountName} accountNumber={accountNumber} amount={amount} />
      <Checkbox
        data-test-id="dwolla-Terms-Checkbox"
        isChecked={termsAccepted}
        mb="space80"
        name="default"
        value="dwollaTermsAccepted"
        onClick={handleCheckboxSelected}
      >
        I accept Dwolla&apos;s Terms of Service and Privacy Policy.
      </Checkbox>
      <Box
        bg="background"
        bottom="0"
        boxShadow={{ base: 'medium', md: 'none' }}
        display="flex"
        flex={1}
        justifyContent="flex-end"
        left="0"
        position={{ base: 'fixed', md: 'initial' }}
        px={{ base: 'space50', md: 'space0' }}
        py={{ base: 'space30', md: 'space0' }}
        right="0"
        width="100%"
      >
        <Flex justifyContent="flex-end">
          <Button
            data-test-id="submit-transfer-button"
            isDisabled={!termsAccepted}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Confirm Transfer
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
