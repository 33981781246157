import React, { FC, FocusEvent } from 'react';
import { useFormikContext } from 'formik';
import Cleave from 'cleave.js/react';
import { Icon, Input, InputElemGroup, InputLeftElement, Text, Stack } from '@endpoint/blockparty';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { CleaveOptions } from 'cleave.js/options';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

export interface TodoTodoMoneyInputProps {
  field: FieldOptions;
}

export const TodoMoneyInput: FC<TodoTodoMoneyInputProps> = ({ field: { id: fieldId, label, answer, placeHolder } }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<GenericObject>();

  useTodoFieldInitializer({ id: fieldId, answer });

  const value = values[fieldId];

  const options: CleaveOptions = {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
  };

  return (
    <Stack mt="space60" spacing="space0">
      {label && (
        <Text color="carbon900" mb="space20" size="fontSize20">
          {label}
        </Text>
      )}
      <InputElemGroup>
        <InputLeftElement>
          <Icon name="DollarSign" />
        </InputLeftElement>
        <Input
          as={Cleave}
          options={options}
          placeholder={placeHolder}
          value={value}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setFieldValue(fieldId, e.target.value);
            setFieldTouched(fieldId);
          }}
        />
      </InputElemGroup>
    </Stack>
  );
};
