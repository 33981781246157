import React, { FC, useContext, useEffect, useState } from 'react';
import { Heading, Text, Box, Link, Stack, Button, Flex, useToast } from '@endpoint/blockparty';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FormikPasswordInputGroup, FormikInputGroup } from 'components/form/FormikBlockparty';
import { AppContext, AppContextType, CompanyContext, useAppConfigsContext } from 'utils/context';
import { Svg } from 'components/Svg';
import { checkEmail, checkPhone } from 'utils/validation';
import { signUpEmailErrors } from 'Auth/Utilities/helper';
import {
  PASSWORD_COMPLEXITY_ERROR,
  PASSWORD_REGEX,
  NO_LEADING_AND_TRAILING_SPACES_REGEX,
} from 'Auth/Utilities/Constant';
import { formatPhoneNumberWithOptionalIntlCode } from 'utils/formatPhone';
import { useLocation } from 'react-router-dom';
import { useSignUp as useSignUpV2 } from 'hooks/auth/useSignUp';
import {
  ButtonClickedTrackingPayload,
  LinkClickedTrackingPayload,
  SignUpTrackingEvents,
  TrackingEventType,
} from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { SignInLink } from './SignInLink';
import { PasswordCriteria } from '../PasswordCriteria';
import { useInputError } from '../../../hooks/useInputError';
import Alerts from '../Alerts';
import GenericToast from '../GenericToast';

interface ISignUpFormValues {
  email: string;
  phone: string;
  phoneConfirm: string;
  password: string;
}

interface DisplayCancelToaster {
  isRecentlyDeactivated: boolean;
}

const DEACTIVATE_TOASTER_HEADER = 'Your account has been deactivated';
const DEACTIVATE_TOASTER_MESSAGE = "We're sorry to see you go!";

const PHONE_NUMBER_WITH_MASK_LENGTH = 14;
const PHONE_NUMBER_FORMAT_ERROR = 'Please enter a valid 10-digit U.S. mobile phone number';
const PHONE_NUMBER_RE_ENTER = 'Please re-enter your U.S. mobile phone number';

const PASSWORD_SPACE_ERROR = 'Password should not start or end with a space.';

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter an email address in valid format, e.g. name@website.com')
    .required('Please enter a valid email address'),
  phone: Yup.string()
    .min(PHONE_NUMBER_WITH_MASK_LENGTH, PHONE_NUMBER_FORMAT_ERROR)
    .max(PHONE_NUMBER_WITH_MASK_LENGTH, PHONE_NUMBER_FORMAT_ERROR)
    .required(PHONE_NUMBER_FORMAT_ERROR),
  phoneConfirm: Yup.string()
    .min(PHONE_NUMBER_WITH_MASK_LENGTH, PHONE_NUMBER_FORMAT_ERROR)
    .max(PHONE_NUMBER_WITH_MASK_LENGTH, PHONE_NUMBER_FORMAT_ERROR)
    .required(PHONE_NUMBER_FORMAT_ERROR)
    .oneOf([Yup.ref('phone'), null], `Phone numbers do not match. ${PHONE_NUMBER_RE_ENTER}.`),
  password: Yup.string()
    .required(PASSWORD_COMPLEXITY_ERROR)
    .matches(NO_LEADING_AND_TRAILING_SPACES_REGEX, PASSWORD_SPACE_ERROR)
    .matches(PASSWORD_REGEX, PASSWORD_COMPLEXITY_ERROR),
});

const SignUp: FC = () => {
  const { authFields, setAuthFields }: AppContextType = useContext(AppContext);
  const { signUp: signUpV2 } = useSignUpV2();
  const location = useLocation();
  const { PRIVACY_URL } = useAppConfigsContext();
  const toast = useToast();
  const emailError = useInputError(signUpEmailErrors);
  const { companyName } = useContext(CompanyContext);
  const { isOnline }: AppContextType = useContext(AppContext);
  const PRIVACY_AND_TERMS_TEXT = 'Privacy & Terms';
  const SUBMIT_BUTTON_TEXT = 'Continue';

  const useQuery = () => new URLSearchParams(useLocation()?.search);
  // eslint-disable-next-line require-explicit-generics/require-explicit-generics
  const queryEmail = useQuery().get('email');
  // eslint-disable-next-line require-explicit-generics/require-explicit-generics
  const queryPhone = formatPhoneNumberWithOptionalIntlCode(useQuery().get('phone') ?? '');
  const [initialValue, setInitialValue] = useState({
    email: '',
    phone: '',
    phoneConfirm: '',
    password: '',
  });
  let isRecentlyDeactivated = (location.state as DisplayCancelToaster)?.isRecentlyDeactivated;

  useEffect(() => {
    if (!isRecentlyDeactivated) return;

    const position = window.innerWidth < 414 ? 'top-center' : 'top-right';

    toast({
      duration: 3000,
      position,
      render: ({ id }) => (
        <GenericToast header={DEACTIVATE_TOASTER_HEADER} id={id} message={DEACTIVATE_TOASTER_MESSAGE} />
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    isRecentlyDeactivated = false;
  }, [isRecentlyDeactivated]);

  useEffect(() => {
    const prePopulateForm = () => {
      if (!queryEmail && !queryPhone) {
        return;
      }

      let email = '';
      let phone = '';

      if (queryEmail && checkEmail(queryEmail)) {
        email = queryEmail;
      }

      if (queryPhone && checkPhone(queryPhone)) phone = queryPhone;

      setInitialValue({
        ...initialValue,
        email,
        phone,
      });
    };

    prePopulateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEmail, queryPhone]);

  const handlePrivacyAndTermsClick = () => {
    const trackPrivacyAndTermsProperties: LinkClickedTrackingPayload = {
      name: TrackingEventType.LINK_CLICKED,
      linkId: PRIVACY_AND_TERMS_TEXT,
    };

    trackAction(SignUpTrackingEvents.PRIVACY_AND_TERMS_LINK, trackPrivacyAndTermsProperties);
  };

  const onSubmit = (values: ISignUpFormValues) => {
    const trackSignUpSubmitProperties: ButtonClickedTrackingPayload = {
      name: TrackingEventType.BUTTON_CLICKED,
      buttonId: SUBMIT_BUTTON_TEXT,
    };

    trackAction(SignUpTrackingEvents.CONTINUE_BUTTON_SUBMITTED, trackSignUpSubmitProperties);
    setAuthFields({ ...authFields, errorCode: null });
    signUpV2(values.email.trim(), values.phone, values.password);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValue}
      validateOnBlur
      validationSchema={SignUpSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, values }) => (
        <Form>
          <Box mb="space70" textAlign="left">
            <Heading as="h2" mb="space50" size="fontSize70">
              Create a new account
            </Heading>
            <Text>
              {'Already have an account? '}
              <SignInLink color="blue500" />
            </Text>
          </Box>
          <Alerts />
          <Stack mb="space60" spacing="space60">
            <FormikInputGroup
              autoFocus
              error={emailError}
              isDisabled={Boolean(queryEmail && checkEmail(queryEmail))}
              label="Email Address"
              name="email"
              placeholder="name@website.com"
              type="email"
            />
            <FormikInputGroup
              data-test-id="phone"
              description={
                <Text color="carbon500" mt="space20" size="fontSize10">
                  We’ll send you a one-time code via SMS. Landline and VoIP phones cannot be used. Message and data
                  rates may apply.
                </Text>
              }
              label="U.S. Mobile Phone Number"
              leftIcon={<Svg aria-label="USFlag" height="24px" ml="16px" mr="8px" name="USFlag" width="24px" />}
              mask={{ numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ['(', ')', ' ', '-'] }}
              name="phone"
              placeholder="(555) 555-5555"
              type="phone"
            />
            <FormikInputGroup
              data-test-id="phoneConfirm"
              helperText={
                <Text color="carbon500" mt="space20" size="fontSize10">
                  {PHONE_NUMBER_RE_ENTER}
                </Text>
              }
              label="Confirm U.S. Mobile Phone Number"
              leftIcon={<Svg aria-label="USFlag" height="24px" ml="16px" mr="8px" name="USFlag" width="24px" />}
              mask={{ numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ['(', ')', ' ', '-'] }}
              name="phoneConfirm"
              placeholder="(555) 555-5555"
              type="phone"
            />
          </Stack>
          <FormikPasswordInputGroup label="Password" name="password" placeholder="Create your password" />
          <Stack mb="space70" mt="space50" spacing="space20">
            <PasswordCriteria password={values.password} />
          </Stack>
          <Box mb="space80">
            <Text>
              By clicking “Continue”, you agree to {companyName}’s{' '}
              <Link
                color="blue500"
                cursor="pointer"
                href={PRIVACY_URL}
                target="_blank"
                textDecoration="underline"
                onClick={handlePrivacyAndTermsClick}
              >
                {PRIVACY_AND_TERMS_TEXT}
              </Link>
            </Text>
          </Box>
          {isOnline && (
            <Flex
              backgroundColor="white"
              bottom={{ base: 0, md: 'initial' }}
              boxShadow={{ base: 'medium', md: 'none' }}
              justifyContent="flex-end"
              left={{ base: 0, md: 'initial' }}
              position={{ base: 'fixed', md: 'initial' }}
              px={{ base: 'space50', md: 'space0' }}
              py={{ base: 'space30', md: 'space0' }}
              right={{ base: 0, md: 'initial' }}
              zIndex="overlay"
            >
              <Button isDisabled={!(isValid && dirty)} type="submit">
                {SUBMIT_BUTTON_TEXT}
              </Button>
            </Flex>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SignUp;
