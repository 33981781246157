import React, { FC, useState } from 'react';
import { Heading, Text, Box, RadioGroup, Radio, Flex, Button } from '@endpoint/blockparty';
import { usePlaid } from 'hooks/usePlaid';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';

import { AmountWidget } from '../../../AmountWidget';
import { OnlinePaymentWidgetProps } from '../..';

export interface InsufficientFundsProps {
  props: OnlinePaymentWidgetProps;
  onBankAccountInfoReceived: Function | undefined;
  linkToken: string | undefined;
  transactionId: string;
  todoAssignmentId?: string;
  onErrors: Function;
}

export const InsufficientFunds: FC<InsufficientFundsProps> = ({
  props,
  onBankAccountInfoReceived,
  linkToken,
  transactionId,
  todoAssignmentId,
  onErrors,
}) => {
  enum PaymentOptions {
    differentAccount = 'different-account',
    differentPaymentMethod = 'different-payment',
  }
  const plaidInput = {
    linkToken: linkToken || '',
    transactionId,
    todoAssignmentId,
    onAccountInfoReceived: onBankAccountInfoReceived,
    onErrorReceived: onErrors,
  };
  const { setShowTodoStepNavigationPanel, handlePreviousSubmission } = useTodoStepContext();
  const { openPlaid } = usePlaid(plaidInput);
  const [paymentMethod, setPaymentMethod] = useState<PaymentOptions>();

  const handleRadioSelection = (value: PaymentOptions) => {
    setPaymentMethod(value);
  };

  const { value } = props;

  const handleNextClick = () => {
    if (paymentMethod === PaymentOptions.differentAccount) {
      openPlaid();
    } else {
      handlePreviousSubmission();
      setShowTodoStepNavigationPanel(true);
    }
  };

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Oops!
      </Heading>
      <Text data-test-id="error-header">
        The available funds for the account you selected are less than the amount due.
      </Text>
      <Text>Your earnest money deposit amount due is:</Text>
      <AmountWidget value={value} />
      <Box mb="space70">
        <RadioGroup
          aria-label="select-new-payment-method"
          defaultValue={paymentMethod}
          name="select-new-payment-method"
          spacing="space50"
        >
          <Radio value="different-account" onChange={() => handleRadioSelection(PaymentOptions.differentAccount)}>
            Select a different account
          </Radio>
          <Radio value="different-payment" onChange={() => handleRadioSelection(PaymentOptions.differentPaymentMethod)}>
            Select a different payment method
          </Radio>
        </RadioGroup>
      </Box>
      <Box
        bg="background"
        bottom="0"
        boxShadow={{ base: 'medium', md: 'none' }}
        display="flex"
        flex={1}
        justifyContent="flex-end"
        left="0"
        mt={{ base: 'space0', md: 'space80' }}
        position={{ base: 'fixed', md: 'initial' }}
        px={{ base: 'space50', md: 'space0' }}
        py={{ base: 'space30', md: 'space0' }}
        right="0"
        width="100%"
      >
        <Flex justifyContent="flex-end">
          <Button onClick={handleNextClick}>Next</Button>
        </Flex>
      </Box>
    </>
  );
};
