import { gql } from '@apollo/client';

export const GET_TRANSACTION_BFF = gql`
  query getTransaction($transactionId: String!) {
    getTransaction(transactionId: $transactionId) {
      id
      fileNumber
      loanAmount
      cancellationDate
      estimatedClosing
      type
      status
      createdAt
      marketId
      price
      prelimPending
      participants {
        id
        contactId
        firstName
        middleName
        lastName
        contactType
        roles
      }
      property {
        address {
          street1
          street2
          city
          state
          zip
          standardFormat
        }
        propertyImage
      }
      milestones {
        buyer {
          id
          completed
          name
          description
          data
          status
          position
          milestoneType
        }
        seller {
          id
          completed
          name
          description
          data
          status
          position
          milestoneType
        }
      }
    }
  }
`;
