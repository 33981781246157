import React, { FC } from 'react';
import { Box, Flex, SkeletonBlock, Divider } from '@endpoint/blockparty';

export const ContactListSkeleton: FC = () => (
  <Box bg="white">
    <SkeletonBlock height={16} maxWidth={234} mb="space30" />
    <Divider mb="space60" />
    <Flex flexDirection={{ base: 'column', md: 'row' }} flexWrap={{ base: 'nowrap', md: 'wrap' }}>
      <Box mb="space60" width={{ base: '100%', md: '50%' }}>
        <SkeletonBlock height={16} maxWidth={234} mb="space30" />
        <SkeletonBlock height={12} maxWidth={249} mb="space30" />
        <SkeletonBlock height={12} maxWidth={203} mb="space30" />
      </Box>
      <Box mb="space60" width={{ base: '100%', md: '50%' }}>
        <SkeletonBlock height={16} maxWidth={234} mb="space30" />
        <SkeletonBlock height={12} maxWidth={249} mb="space30" />
        <SkeletonBlock height={12} maxWidth={203} mb="space30" />
      </Box>
      <Box mb="space60" width={{ base: '100%', md: '50%' }}>
        <SkeletonBlock height={16} maxWidth={234} mb="space30" />
        <SkeletonBlock height={12} maxWidth={249} mb="space30" />
        <SkeletonBlock height={12} maxWidth={203} mb="space30" />
      </Box>
    </Flex>
    <SkeletonBlock height={296} maxWidth="100%" mb="space70" />
    <SkeletonBlock height={16} maxWidth={234} mb="space30" />
    <Divider mb="space60" />
    <Box>
      <Flex py="space50">
        <SkeletonBlock height={16} maxWidth={110} mb="space30" mr="space80" />
        <SkeletonBlock height={12} maxWidth={218} mb="space30" />
      </Flex>
      <Flex py="space50">
        <SkeletonBlock height={16} maxWidth={110} mb="space30" mr="space80" />
        <SkeletonBlock height={12} maxWidth={218} mb="space30" />
      </Flex>
      <Flex py="space50">
        <SkeletonBlock height={16} maxWidth={110} mb="space30" mr="space80" />
        <SkeletonBlock height={12} maxWidth={218} mb="space30" />
      </Flex>
    </Box>
  </Box>
);
