import React, { FC, useState } from 'react';
import { DwollaKbaQuestion } from '@endpoint/endpoint-bff-graphql-schema';
import { Heading, Radio, RadioGroup, Stack } from '@endpoint/blockparty';

interface KBAQuestionInput {
  question: DwollaKbaQuestion;
  handleAnswerSelection: Function;
}

export const KBAQuestion: FC<KBAQuestionInput> = ({ question, handleAnswerSelection }) => {
  const [chosenAnswer, setChosenAnswer] = useState<string>('');

  return (
    <Stack className="fs-exclude dd-privacy-hidden" mt="space70">
      <Heading as="h5" data-test-id="question-text" fontWeight="semi" size="fontSize30">
        {question.text}
      </Heading>
      <RadioGroup
        aria-label="kba-question"
        defaultValue={chosenAnswer}
        name={question.id}
        pt="space30"
        spacing="space50"
      >
        {question.answers.map((answer) => (
          <Radio
            key={answer.id}
            data-test-id="question-answer"
            value={answer.text}
            onChange={() => {
              setChosenAnswer(answer.text);
              handleAnswerSelection(question.id, answer.id);
            }}
          >
            {answer.text}
          </Radio>
        ))}
      </RadioGroup>
    </Stack>
  );
};
