import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Heading } from '@endpoint/blockparty';
import { HeadingProps } from '@endpoint/blockparty/dist/components/Heading';
import { TodoStepId } from 'consts/enums';
import { ContentType } from '@endpoint/endpoint-bff-graphql-schema';

interface TodoHeadingProps {
  contentType: ContentType;
  value?: string;
}

function getH1StylesByStep(stepId: string): Partial<HeadingProps> {
  const defaultStyles: Partial<HeadingProps> = { mb: 'space50', size: 'fontSize50' };

  if (stepId.startsWith(`${TodoStepId.THANK_YOU}-`)) {
    return { ...defaultStyles, size: 'fontSize60' };
  }

  return defaultStyles;
}

function getH2StylesByStep(stepId: string): Partial<HeadingProps> {
  const defaultStyles: Partial<HeadingProps> = { mb: 'space50', mt: 'space0', size: 'fontSize30' };

  switch (stepId) {
    case TodoStepId.ABOUT:
      return { ...defaultStyles, mb: 'space60' };
    case TodoStepId.THANK_YOU:
      return { ...defaultStyles, mt: 'space70' };
    default:
      return defaultStyles;
  }
}

function getH3StylesByStep(stepId: string): Partial<HeadingProps> {
  const defaultStyles: Partial<HeadingProps> = { mb: 'space0', size: 'fontSize20' };

  if (stepId.startsWith(`${TodoStepId.THANK_YOU}-`)) {
    return { ...defaultStyles, mt: 'space70', mb: 'space50', size: 'fontSize30' };
  }

  return defaultStyles;
}

export const TodoHeading: FC<TodoHeadingProps> = ({ contentType, value }: TodoHeadingProps) => {
  const { stepId = '' } = useParams();

  switch (contentType) {
    case ContentType.HEADING:
      return (
        <Heading as="h1" {...getH1StylesByStep(stepId)}>
          {value}
        </Heading>
      );
    case ContentType.HEADING_2:
      return (
        <Heading as="h2" {...getH2StylesByStep(stepId)}>
          {value}
        </Heading>
      );
    case ContentType.HEADING_3:
      return (
        <Heading as="h3" {...getH3StylesByStep(stepId)}>
          {value}
        </Heading>
      );
    default:
      return <></>;
  }
};
