import React, { FC } from 'react';
import { Link, Text } from '@endpoint/blockparty';
import { TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';
import { LinkProps } from '@endpoint/blockparty/dist/components/Link';

interface TodoHyperlinkProps extends Omit<LinkProps, 'content'> {
  content: TodoAssignmentContent;
}

export const TodoHyperlink: FC<TodoHyperlinkProps> = ({ content, ...linkProps }: TodoHyperlinkProps) => {
  return (
    <Link color="blue500" href={content.value} isExternal textDecoration="underline" {...linkProps}>
      {content.children.map((element) => (
        <Text key={element.value} as="span" color="blue500">
          {element.value}
        </Text>
      ))}
    </Link>
  );
};
