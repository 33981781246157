import { isSameDay, isToday, setHours, startOfToday, setMinutes, startOfDay, endOfDay } from 'date-fns';

import { getEarlier, getLater, getNow } from './util';

function minTimeStartDate(selectedDate?: Date, startDate?: Date) {
  if (selectedDate && startDate && isSameDay(selectedDate, startDate)) {
    return startDate;
  }

  return undefined;
}

function minTimeDefault(selectedDate?: Date) {
  return startOfDay(selectedDate || startOfToday());
}

function minTimeToday(selectedDate?: Date) {
  if (selectedDate && isToday(selectedDate)) {
    return getNow();
  }

  return undefined;
}

function minTimePastEndDate(selectedDate?: Date, endDate?: Date) {
  if (selectedDate && endDate && startOfDay(getLater(selectedDate, getNow())) > startOfDay(endDate)) {
    return setMinutes(setHours(selectedDate, 23), 59);
  }

  return undefined;
}

function maxTimeDefault(selectedDate?: Date) {
  return endOfDay(selectedDate || startOfToday());
}

function maxTimeEndDate(selectedDate?: Date, endDate?: Date) {
  if (selectedDate && endDate && isSameDay(selectedDate, endDate)) {
    return endDate;
  }

  return undefined;
}

export function getMinTimeBoundary(selectedDate?: Date, startDate?: Date, endDate?: Date): Date {
  return getLater(
    minTimeDefault(selectedDate),
    minTimeToday(selectedDate),
    minTimeStartDate(selectedDate, startDate),
    minTimePastEndDate(selectedDate, endDate),
  );
}

export function getMaxTimeBoundary(selectedDate?: Date, endDate?: Date, minTimeBoundary?: Date): Date {
  return getLater(minTimeBoundary, getEarlier(maxTimeDefault(selectedDate), maxTimeEndDate(selectedDate, endDate)));
}
