import React, { FC } from 'react';
import { Box, Flex, Text } from '@endpoint/blockparty';

interface NoTransactionsProps {
  title?: string;
  description?: string;
}

export const NoTransactions: FC<NoTransactionsProps> = ({
  title = 'There are no transactions currently in escrow',
  description = 'Once a transaction is in escrow, it will appear here.',
  children,
}) => {
  return (
    <Flex alignItems="center" flex={1} justifyContent="center">
      <Box maxWidth="520px" mx="space60" textAlign="center">
        <Text as="h3" color="carbon500" mb="space60" size="fontSize60">
          {title}
        </Text>
        <Text as="p" color="carbon500" size="fontSize20">
          {description}
        </Text>
        {children}
      </Box>
    </Flex>
  );
};
