import React, { createContext, useContext, ReactNode, useState, useMemo } from 'react';
import jwt_decode from 'jwt-decode';
import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY, CustomStorage } from 'utils/auth/storage';
import { AuthenticationResult } from '@endpoint/endpoint-bff-graphql-schema';

type DataT = any;
type DataRecordsT = Record<string, DataT>;

interface IdentityT {
  sub: string;
  iss: string;
  phone_number_verified: boolean;
  'cognito:username': string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  phone_number: string;
  exp: number;
  iat: string;
  email: string;
}

export interface AuthContextType {
  loading: boolean;
  isLoggedIn: boolean;
  error: string;
  identity?: IdentityT;
  setLoading: (status: boolean) => void;
  setError: (msg: string) => void;
  clearError: () => void;
  data: DataRecordsT;
  setData: (key: string, value: DataT) => void;
  clearData: () => void;
  setTokens: (tokens: AuthenticationResult) => void;
  clearTokens: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const endpointKey = {
  accessToken: ACCESS_TOKEN_KEY,
  idToken: ID_TOKEN_KEY,
};

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!CustomStorage.getItem(endpointKey.accessToken));
  const [data, setDataRaw] = useState<DataRecordsT>({});
  const [error, setError] = useState<string>('');

  const identity = (() => {
    try {
      const idToken = CustomStorage.getItem(endpointKey.idToken);

      if (idToken) {
        return jwt_decode(idToken) as unknown as IdentityT;
      }
    } catch (err) {
      // no op
    }

    return undefined;
  })();

  const setData = (key: string, value: DataT): void => {
    setDataRaw((rawData) => ({
      ...rawData,
      [key]: value,
    }));
  };

  const clearData = (): void => {
    setDataRaw({});
  };

  const clearError = (): void => {
    setError('');
  };

  const setTokens = (tokens: AuthenticationResult): void => {
    Object.keys(tokens).forEach((key) => {
      const typedKey = key as keyof typeof endpointKey;

      if (endpointKey[typedKey]) {
        CustomStorage.setItem(endpointKey[typedKey], tokens[typedKey]);
      }
    });

    setIsLoggedIn(!!CustomStorage.getItem(endpointKey.accessToken));
  };

  const clearTokens = (): void => {
    Object.values(endpointKey).forEach((value) => {
      CustomStorage.removeItem(value);
    });

    setIsLoggedIn(false);
  };

  const values = useMemo(
    () => ({
      loading,
      isLoggedIn,
      identity,
      setLoading,
      setData,
      clearData,
      data,
      setTokens,
      clearTokens,
      setError,
      clearError,
      error,
    }),
    [loading, isLoggedIn, identity, data, error],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }

  return context;
};
