import { useParams } from 'react-router';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { useAppContext } from 'utils/context';

export interface TodoTrackingPayload {
  transactionId: string;
  todoName: string;
  userId?: string;
  userTransactionRoles?: Array<string>;
}

export const useTodoAnalytics = () => {
  const { user } = useAppContext();
  const { transactionId = '' } = useParams();
  const { todoAssignment, userTransactionRoles } = useTodoStepContext();

  const todoTrackingPayload: TodoTrackingPayload = {
    transactionId,
    todoName: todoAssignment.progressTracker.name || 'Not Available',
    userId: user.id,
    userTransactionRoles,
  };

  return { todoTrackingPayload };
};
