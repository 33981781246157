import { TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';

export const getAnswerWithoutConditionalFields = (
  conditionalContent: TodoAssignmentContent[],
  answer: GenericObject,
): GenericObject => {
  const newAnswer = answer;

  conditionalContent.forEach(({ field }) => {
    if (field) {
      delete newAnswer[field.id];
    }
  });

  return newAnswer;
};
