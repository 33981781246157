import { USState } from 'utils/getSupportedUSState';

export interface SubHeaderRouteProps {
  /** Text in NavBarItem */
  label: string;
  /** URL path */
  route: string;
  statesNotAvailable?: Array<USState>;
}

export const TransactionDetailsRoutes: SubHeaderRouteProps[] = [
  {
    label: "To-Do's",
    route: 'todos',
  },
  {
    label: 'Documents',
    route: 'documents',
  },
  {
    label: 'Contacts',
    route: 'contacts',
  },
];

export const TransactionDetailsRoutesPrelim: SubHeaderRouteProps[] = [
  {
    label: 'Documents',
    route: 'documents',
  },
];

export const SettingsRoutes: SubHeaderRouteProps[] = [
  {
    label: 'Profile',
    route: 'profile',
  },
  // {
  //   label: 'Security',
  //   route: 'security',
  // },
  {
    label: 'Notifications',
    route: 'notifications',
  },
];

export const TransactionsAgentRoutes: SubHeaderRouteProps[] = [
  {
    label: 'In Escrow',
    route: 'in-escrow',
  },
  {
    label: 'Closed',
    route: 'closed',
  },
  {
    label: 'Listing',
    route: 'listings',
    statesNotAvailable: [USState.TX],
  },
];

export const TransactionsNonAgentRoutes: SubHeaderRouteProps[] = [
  {
    label: 'In Escrow',
    route: 'in-escrow',
  },
  {
    label: 'Closed',
    route: 'closed',
  },
];
