import React, { FC } from 'react';
import { EPSubHeader, NavBar as SubNavBar, SkeletonBlock, Stack } from '@endpoint/blockparty';
import { CENTERED_CONTENT_WIDTH } from 'consts/responsive';

export const SubHeaderLoading: FC = () => (
  <EPSubHeader>
    <SubNavBar alignItems="center" height={56} margin="0 auto" maxWidth={CENTERED_CONTENT_WIDTH} width="100%">
      <Stack direction="row" spacing="space50">
        <SkeletonBlock width={75} />
        <SkeletonBlock width={75} />
        <SkeletonBlock width={75} />
      </Stack>
    </SubNavBar>
  </EPSubHeader>
);
