import { partition } from 'lodash';
import { TransactionsPayload } from 'routes/Transactions/queries';
import {
  isTitleProductAvailable,
  isTransactionContainAtLeastDateField,
  isTransactionPrelimPending,
  sortByPrelistingOpenedDate,
  sortByTitleProductDelivereDate,
} from 'utils/transactions/sortingTransactionHelpers';

export function prelistingSorter(transactionPayload: TransactionsPayload): TransactionsPayload {
  const [prelimPendingtransactions, nonPrelimPendingTransactions] = partition(
    transactionPayload.myTransactions,
    isTransactionPrelimPending,
  ).map((transactionArr) => partition(transactionArr, isTransactionContainAtLeastDateField));

  const [prelimPendingTransactionWithDates, prelimPendingTransactionWithNoDates] = prelimPendingtransactions;

  const [nonPrelimPendingTransactionWithDates, nonPrelimPendingTransactionWithNoDates] = nonPrelimPendingTransactions;

  const [deliveredPrelimTransactions, orderedPrelimTransactions] = partition(
    prelimPendingTransactionWithDates,
    isTitleProductAvailable,
  );

  const [deliveredNonPrelimTransactions, orderedNonPrelimTransactions] = partition(
    nonPrelimPendingTransactionWithDates,
    isTitleProductAvailable,
  );

  return {
    ...transactionPayload,
    myTransactions: [
      ...nonPrelimPendingTransactionWithNoDates,
      ...sortByTitleProductDelivereDate(deliveredNonPrelimTransactions),
      ...sortByPrelistingOpenedDate(orderedNonPrelimTransactions),
      ...prelimPendingTransactionWithNoDates,
      ...sortByTitleProductDelivereDate(deliveredPrelimTransactions),
      ...sortByPrelistingOpenedDate(orderedPrelimTransactions),
    ],
  };
}
