import React, { FC } from 'react';
import { HEADER_OFFSET } from 'consts/responsive';
import { Box } from '@endpoint/blockparty';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';

export const SubHeaderContainer: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box boxShadow="large" position="sticky" top={HEADER_OFFSET} zIndex={20} {...props}>
      {children}
    </Box>
  );
};
