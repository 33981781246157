import { MARKET_ID } from 'consts/markets';

import { expansionMarketsIds } from '../../../../utils/getMarket';

const officeHours = ['Mon - Thur, 7am - 7pm PST', 'Fri, 7am - 5pm PST'];

const ElSegundoBlvdStreet = '2101 E. El Segundo Blvd, Suite 104';
const ElSegundoCityStateZip = 'El Segundo, CA 90245';
const expansionPhone = '+1 (866) 686-1099';

export interface EndpointTeamContact {
  cityStateZip: string;
  email: string;
  officeHours: string[];
  phone: string;
  street: string;
  marketId: number[];
}

export const ENDPOINT_AZ: EndpointTeamContact = {
  cityStateZip: 'Tempe, AZ 85281',
  email: 'closing@endpoint.com',
  officeHours: ['Monday - Friday, 7am - 6pm MT'],
  phone: '+1 (602) 610-1242',
  street: '51 West 3rd Street, Suite 335',
  marketId: [MARKET_ID.AZ],
};

export const ENDPOINT_CA: EndpointTeamContact = {
  cityStateZip: 'Irvine, CA 92618',
  email: 'closing@endpoint.com',
  officeHours: ['Monday - Friday, 7am - 6pm PT'],
  phone: '+1 (657) 334-6700',
  street: '17400 Laguna Canyon Rd, Suite 150',
  marketId: [MARKET_ID.CA, MARKET_ID.NorCA, MARKET_ID.SoCA],
};

export const ENDPOINT_CO: EndpointTeamContact = {
  cityStateZip: 'Tempe, AZ 85281',
  email: 'closing@endpoint.com',
  officeHours: ['Monday - Friday, 7am - 6pm MT'],
  phone: expansionPhone,
  street: '51 West 3rd Street, Suite 335',
  marketId: [MARKET_ID.CO],
};

export const ENDPOINT_FL_IN_OH: EndpointTeamContact = {
  cityStateZip: 'Tempe, AZ 85281',
  email: 'closing@endpoint.com',
  officeHours: ['Monday - Friday, 10am - 7pm ET'],
  phone: expansionPhone,
  street: '51 West 3rd Street, Suite 335',
  marketId: [MARKET_ID.FL, MARKET_ID.OH, MARKET_ID.IN],
};

export const ENDPOINT_KS_KY_MO_TN: EndpointTeamContact = {
  cityStateZip: 'Tempe, AZ 85281',
  email: 'closing@endpoint.com',
  officeHours: ['Monday - Friday, 9am - 6pm CT'],
  phone: expansionPhone,
  street: '51 West 3rd Street, Suite 335',
  marketId: [MARKET_ID.TN, MARKET_ID.MO, MARKET_ID.KS, MARKET_ID.KY],
};

export const ENDPOINT_TX: EndpointTeamContact = {
  cityStateZip: 'Austin, TX 78746',
  email: 'closing@endpoint.com',
  officeHours: ['Monday - Friday, 9am - 6pm CT'],
  phone: '+1 (469) 529-5084',
  street: '1221 S Mopac Expressway, Suite 150',
  marketId: [MARKET_ID.TX],
};

export const ENDPOINT_WA: EndpointTeamContact = {
  cityStateZip: 'Seattle, WA 98104',
  email: 'closing@endpoint.com',
  officeHours: ['Monday - Friday, 7am - 6pm PT'],
  phone: '+1 (425) 409-9710',
  street: '920 Fifth Ave, Suite 1300',
  marketId: [MARKET_ID.WA],
};

export const ENDPOINT_AZ_BANKING: EndpointTeamContact = {
  ...ENDPOINT_AZ,
  cityStateZip: 'Tempe, AZ 85281',
  street: '51 West 3rd Street, Suite 335',
};

export const ENDPOINT_CA_BANKING: EndpointTeamContact = {
  ...ENDPOINT_CA,
  cityStateZip: ElSegundoCityStateZip,
  street: ElSegundoBlvdStreet,
};

export const ENDPOINT_CO_FL_IN_MO_OH_TN_BANKING: EndpointTeamContact = {
  email: 'closing.eptech@endpoint.com',
  officeHours: ['Mon - Fri, 8am - 5pm CST/EST'],
  phone: expansionPhone,
  marketId: expansionMarketsIds,
  cityStateZip: ElSegundoCityStateZip,
  street: '2101 East El Segundo Blvd, Suite 104',
};

export const ENDPOINT_KS_KY_BANKING: EndpointTeamContact = {
  email: 'closing.eptech@endpoint.com',
  officeHours: ['Monday - Thursday, 7am - 7pm Friday, 7am - 5pm'],
  phone: expansionPhone,
  cityStateZip: ElSegundoCityStateZip,
  street: ElSegundoBlvdStreet,
  marketId: [MARKET_ID.KS, MARKET_ID.KY],
};

export const ENDPOINT_TX_BANKING: EndpointTeamContact = {
  ...ENDPOINT_TX,
  cityStateZip: 'Plano, TX 75024',
  street: '7700 Windrose Ave, Suite G300',
};

export const ENDPOINT_WA_BANKING: EndpointTeamContact = {
  ...ENDPOINT_WA,
  cityStateZip: 'Tacoma, WA 98402',
  street: '1201 Pacific Ave, Suite 1950',
};

export const ENDPOINT_WA_UNITY_BANKING: EndpointTeamContact = {
  ...ENDPOINT_WA,
  street: '1525 11th Ave, Office #03W113',
  cityStateZip: 'Seattle, WA 98122',
};

export const AHC_WA: EndpointTeamContact = {
  cityStateZip: 'Bellevue, WA 98005',
  email: 'closing@accesshomeclosing.com',
  officeHours,
  phone: '+1 (866) 585-2046',
  street: '11747 NE First St, Suite 210',
  marketId: [MARKET_ID.AHC],
};

export const endpointTeamContacts: EndpointTeamContact[] = [
  AHC_WA,
  ENDPOINT_AZ,
  ENDPOINT_CA,
  ENDPOINT_CO,
  ENDPOINT_FL_IN_OH,
  ENDPOINT_KS_KY_MO_TN,
  ENDPOINT_TX,
  ENDPOINT_WA,
];

export const endpointTeamBanking: EndpointTeamContact[] = [
  AHC_WA,
  ENDPOINT_AZ_BANKING,
  ENDPOINT_CA_BANKING,
  ENDPOINT_CO_FL_IN_MO_OH_TN_BANKING,
  ENDPOINT_KS_KY_BANKING,
  ENDPOINT_TX_BANKING,
  ENDPOINT_WA_BANKING,
];

export const endpointTeamBankingUnity: EndpointTeamContact[] = [
  AHC_WA,
  ENDPOINT_AZ,
  ENDPOINT_CA,
  ENDPOINT_CO,
  ENDPOINT_FL_IN_OH,
  ENDPOINT_KS_KY_MO_TN,
  ENDPOINT_TX,
  ENDPOINT_WA_UNITY_BANKING,
];
