import React, { FC } from 'react';
import { Box, DrawerSection, Heading, Stack, Text } from '@endpoint/blockparty';
import { useTransactionDetailsContext } from 'routes/TransactionDetail';
import { formatCheckData } from 'components/CheckInstructions/helpers';
import {
  CheckInstructionsMailTo,
  CheckInstructionsMemo,
  CheckInstructionsPaymentDetails,
} from 'components/CheckInstructions';
import { formatPaymentAddress } from 'utils/formatPaymentAddress';
import { EndpointTeamContact } from 'routes/TransactionDetail/Contacts/data/EndpointContactData';

interface PinnedItemM2CheckProps {
  payableName?: string;
  paymentAddress?: Pick<EndpointTeamContact, 'street' | 'cityStateZip'>;
}

export const PinnedItemM2Check: FC<PinnedItemM2CheckProps> = ({ payableName = '', paymentAddress }) => {
  const { data: transactionData } = useTransactionDetailsContext();
  const { paymentAmount, transactionStreetAddress, transactionCityStateZip } = formatCheckData(transactionData!);
  const { paymentStreet, paymentSuite, paymentCityStateZip } = formatPaymentAddress(paymentAddress);

  return (
    <DrawerSection>
      <Stack spacing="space70">
        <Box>
          <Heading mb="space50" size="fontSize50">
            How to pay by Check
          </Heading>
          <Text as="p">
            We will need to receive the funds for closing before your signing appointment. Due to the Good Funds Law, we
            can only accept these funds by a Wire Transfer or Cashier’s check.
          </Text>
        </Box>
        <Box>
          <Text as="p" fontWeight="semi" mb="space50">
            Payment Details (Cashier’s Check Only)
          </Text>
          <CheckInstructionsMailTo payableName={payableName} paymentAmount={paymentAmount} />
        </Box>
        <Box>
          <Text as="p" fontWeight="semi" mb="space50">
            Note in Memo of Check
          </Text>
          <CheckInstructionsMemo cityStateZip={transactionCityStateZip} streetAddress={transactionStreetAddress} />
        </Box>
        <Box>
          <Text as="p" fontWeight="semi" mb="space50">
            Mail To
          </Text>
          <CheckInstructionsPaymentDetails
            cityStateZip={paymentCityStateZip}
            payableName={payableName}
            streetAddress={paymentStreet}
            suite={paymentSuite}
          />
        </Box>
      </Stack>
    </DrawerSection>
  );
};

PinnedItemM2Check.displayName = 'PinnedItemM2Check';
