import React, { FC } from 'react';
import { Box, Center, Text, Button, Heading } from '@endpoint/blockparty';
import { TRANSACTIONS } from 'consts/routes';

export const TransactionNotFound: FC = () => {
  return (
    <Center flex={1}>
      <Box maxWidth="488px" mx="space60" textAlign="center">
        <Heading fontWeight="normal" marginBottom="space60" size="fontSize60">
          This Transaction cannot be found.
        </Heading>
        <Text as="p" mb="space80" size="fontSize20">
          It appears this transaction no longer exists.
        </Text>
        <Button as="a" href={`/${TRANSACTIONS}`} textDecoration="none">
          Go to Transactions
        </Button>
      </Box>
    </Center>
  );
};
