import React from 'react';
import { FieldOptions, FieldValidations } from '@endpoint/endpoint-bff-graphql-schema';
import { useFormikContext } from 'formik';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

interface TodoSSNProps {
  field: FieldOptions;
}

export const TodoSSN = ({ field }: TodoSSNProps) => {
  const formikContext = useFormikContext<GenericObject>();
  const isRequired = field.validations.some((validation) => validation.type === FieldValidations.Required);

  const maskOptions = {
    numericOnly: true,
    delimiter: '-',
    blocks: [3, 2, 4],
  };

  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const unmaskValue = value?.replace(/\D/g, '');

    formikContext.setFieldValue(field.id, unmaskValue);
  };

  useTodoFieldInitializer(field);

  return (
    <FormikInputGroup
      isRequired={isRequired}
      isSensitiveInformation={field.isSensitiveInformation}
      label={field.label}
      mask={maskOptions}
      name={field.id}
      placeholder={field.placeHolder ?? ''}
      onChange={handleOnChange}
    />
  );
};
