import React, { FC, useMemo } from 'react';
import { Box, Divider, Stack, Text } from '@endpoint/blockparty';
import { TransactionContacts } from '@endpoint/endpoint-bff-graphql-schema';
import { useAppContext } from 'utils/context';
import { Transaction, TransactionType } from '@endpoint/platform-api-connector/dist/graphql-types';

import { Contact } from './Contact';
import { getDisplayGroupOrder, getTransactionGroupsForDisplay, isUserInBuyerGroup } from '../helpers';

interface TransactionGroupsProps {
  transaction: Transaction;
  contacts: TransactionContacts;
}

export interface TransactionDisplayProps {
  participantId: string;
  participantName: string;
  participantTitle: string;
}

export const TransactionGroups: FC<TransactionGroupsProps> = ({ transaction, contacts }) => {
  const { user } = useAppContext();
  const isRefiOrEquity = transaction.type === TransactionType.REFINANCE || transaction.type === TransactionType.EQUITY;

  const buyerSide = useMemo(() => isUserInBuyerGroup(contacts, user.id), [contacts, user.id]);

  const { groupWithUser, groupWithoutUser } = useMemo(
    () => getTransactionGroupsForDisplay(contacts, user.id),
    [contacts, user.id],
  );

  return (
    <Box>
      <Text as="h5" data-test-id="your-transaction-group" mb="space30" size="fontSize40">
        Your Transaction Group
      </Text>
      <Divider mb="space50" />
      <Stack spacing="space60">
        {getDisplayGroupOrder(groupWithUser).map(({ participantId, participantName, participantTitle }) => (
          <Contact key={participantId} name={participantName} title={participantTitle} />
        ))}
      </Stack>

      {!isRefiOrEquity && (
        <>
          <Text as="h5" data-test-id="other-transaction-group" mb="space30" mt="space80" size="fontSize40">
            {buyerSide ? "Seller's Group" : "Buyer's Group"}
          </Text>
          <Divider mb="space50" />
          <Stack spacing="space60">
            {getDisplayGroupOrder(groupWithoutUser).map(({ participantId, participantName, participantTitle }) => (
              <Contact key={participantId} name={participantName} title={participantTitle} />
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
};
