import React, { FC } from 'react';
import * as yup from 'yup';
import { Heading, Box, Text, Icon } from '@endpoint/blockparty';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { UploadField } from 'components/form/UploadField';
import { mapValues } from 'lodash';
import { setIconByMimeType } from 'utils/fileUpload';
import { trackAction } from 'utils/analytics';
import { TransactionTrackingEvents } from 'consts/analytics';

import { getFileNameMimeType } from '../../Helpers';
import { FormikValue } from '../..';

export const Step2: FC<CurrentStepComponentProps<FormikValue>> = ({
  currentStepPosition = 2,
  formikValue,
  setCurrentStepPosition,
}) => {
  const hasFile = Object.keys(formikValue.file || {}).length > 0;
  const hasAllFilesFinishedUploading = hasFile && Object.values(formikValue.file).every((x) => x.percentage === 100);
  const shouldShowBackButton = currentStepPosition === 2;

  const handleFileUploadClicked = () => {
    trackAction(TransactionTrackingEvents.UPLOAD_PURCHASE_AGREEMENT);
  };

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Upload Purchase Agreement
      </Heading>
      <Box mb="space60">
        <Text size="fontSize20">Select 1 or more files to upload. Maximum upload file size 50MB. PDF or JPG only.</Text>
      </Box>
      <Box data-test-id="fileUpload" mb="space60" onClick={handleFileUploadClicked}>
        <UploadField documentType="Purchase Agreement" name="file" />
      </Box>
      {shouldShowBackButton ? (
        <NavigationButton
          goNext={() => setCurrentStepPosition(currentStepPosition + 1)}
          goPrevious={() => setCurrentStepPosition(1)}
          iconRight="ArrowChevronRight"
          isBackDisabled={hasFile && !hasAllFilesFinishedUploading}
          isDisabled={!(hasFile && hasAllFilesFinishedUploading)}
        >
          Next
        </NavigationButton>
      ) : (
        <NavigationButton
          goNext={() => setCurrentStepPosition(currentStepPosition + 1)}
          iconRight="ArrowChevronRight"
          isBackDisabled={hasFile && !hasAllFilesFinishedUploading}
          isDisabled={!(hasFile && hasAllFilesFinishedUploading)}
        >
          Next
        </NavigationButton>
      )}
    </>
  );
};

export const Summary = (formikValues: FormikValue) => {
  const { file = {} } = formikValues;

  const files = Object.keys(file).map((x) => getFileNameMimeType(file[x].name));

  return (
    <Box>
      {files.map((f) => (
        <Box key={f.filename}>
          <Icon mr="space30" name={setIconByMimeType(f.mime_type)} size="large" />
          <Text data-test-id="file-name">{f.filename}</Text>
        </Box>
      ))}
    </Box>
  );
};

const fileSchema: { [key: string]: any } = {
  filename: yup.string().required(),
  mime_type: yup.string().required(),
};

export const step2ValidationSchema = yup.object().shape({
  file: yup.lazy((obj: any) =>
    yup.object(
      mapValues(obj, (_v, k: string) => {
        return fileSchema[k];
      }),
    ),
  ),
});
