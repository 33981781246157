import { isBefore, isWithinInterval, startOfToday } from 'date-fns';

import { setTime } from './util';
import { getMaxTimeBoundary, getMinTimeBoundary } from './timeBoundary';

export function validateSelectedTime(
  selectedDate?: Date,
  selectedTime?: Date,
  startDate?: Date,
  endDate?: Date,
): Date | undefined {
  if (
    selectedDate &&
    selectedTime &&
    !isBefore(selectedDate, startOfToday()) &&
    isWithinInterval(setTime(selectedDate, selectedTime), {
      start: setTime(selectedDate, getMinTimeBoundary(selectedDate, startDate, endDate)),
      end: setTime(selectedDate, getMaxTimeBoundary(selectedDate, endDate)),
    })
  ) {
    return selectedTime;
  }

  return undefined;
}
