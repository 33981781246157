import React, { FC } from 'react';
import { Box, Flex, SkeletonBlock } from '@endpoint/blockparty';

export const ProgressTrackerSkeleton: FC = () => {
  return (
    <>
      <Box bg="white" p="space50">
        <Flex alignItems="center" justifyContent="space-between" mb="space60">
          <SkeletonBlock height={16} maxWidth={128} />
          <SkeletonBlock height={40} maxWidth={76} />
        </Flex>

        <Box>
          <SkeletonBlock height={12} maxWidth={273} mb="space40" />
          <SkeletonBlock height={154} maxWidth="none" mb="space30" />
          <Flex justifyContent="center">
            <SkeletonBlock height="24px" mr="40px" width="24px" />
            <SkeletonBlock height="24px" width="24px" />
          </Flex>
        </Box>
      </Box>
    </>
  );
};
