import { AssistantRole } from '@endpoint/endpoint-bff-graphql-schema';

/**
 * @param role participant role
 * @returns corresponding role string in title case
 */
export const formatTeammateRole = (role: AssistantRole): string => {
  return role
    .valueOf()
    .split('_')
    .map((word) => word.charAt(0) + word.substr(1).toLowerCase())
    .join(' ');
};
