import React, { FC } from 'react';
import { Box, Flex, SkeletonBlock } from '@endpoint/blockparty';

export const TodoSkeleton: FC = () => {
  return (
    <Box bg="white" data-test-id="todo-skeleton">
      <SkeletonBlock height={16} maxWidth={165} mb="space60" />
      <SkeletonBlock height={12} maxWidth="none" mb="space30" />
      <SkeletonBlock height={12} maxWidth="none" mb="space30" />
      <SkeletonBlock height={12} maxWidth={419} mb="space70" />
      <SkeletonBlock height={16} mb="space70" />
      <SkeletonBlock height={12} maxWidth={440} mb="space60" />
      <SkeletonBlock height={12} maxWidth={440} mb="space60" />
      <SkeletonBlock height={12} maxWidth={440} mb="space80" />
      <Flex flexDirection="row" justifyContent="flex-end">
        <SkeletonBlock height={40} maxWidth={128} />
      </Flex>
    </Box>
  );
};
