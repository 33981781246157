import React, { FC, ReactNode } from 'react';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { isWebView } from 'utils/webview';
import { Box, Center, Heading, Text } from '@endpoint/blockparty';

interface NativeAppFallbackProps {
  children: ReactNode;
}

export const NativeAppFallback: FC<NativeAppFallbackProps> = ({ children }) => {
  const { enableNativeAppDeprecationMessage } = useFeatureFlags();
  const isNativeApp = Boolean(enableNativeAppDeprecationMessage) && isWebView();

  if (isNativeApp) {
    return (
      <Center backgroundColor="slate500" height="100vh" paddingX="space60" width="100vw">
        <Box backgroundColor="white" borderRadius="4px" max-height="462px" p="space60">
          <Heading as="h1" color="carbon900" mb="space50" size="fontSize30">
            Thank you for using the Endpoint mobile app
          </Heading>
          <Text as="p" color="carbon900" mb="space50" size="fontSize20">
            Starting Thursday, June 27, 2024, your transactions and documents will only be available via our website
            application at{' '}
            <Text as="span" fontWeight="semi">
              app.endpointclosing.com
            </Text>
            , as the Endpoint mobile app will no longer be available for use.
          </Text>
          <Text as="p" color="carbon900" mb="space50" size="fontSize20">
            Rest assured, our app can be used on any website browser on your phone and desktop, and offers features such
            as the progress tracker, in-app chat, and more.
          </Text>
          <Text as="p" color="carbon900" size="fontSize20">
            Thank you again for using the Endpoint app. We look forward to seeing you online!
          </Text>
        </Box>
      </Center>
    );
  }

  return <>{children}</>;
};
