import { gql } from '@apollo/client';

export const GET_ACCOUNT_INFO = gql`
  query getPlaidAccount($input: GetPlaidAccountInput!) {
    getPlaidAccount(input: $input) {
      selectedAccount {
        accountNumberMask
        name
        type
        subtype
        balance
        isoCurrencyCode
      }
      hasSufficientFunds
      plaidItemId
      errorMessage
    }
  }
`;
