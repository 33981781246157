import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';

export const useTodoFieldInitializer = (field: Pick<FieldOptions, 'id' | 'answer'>) => {
  const formikContext = useFormikContext<GenericObject>();

  useEffect(() => {
    formikContext.setFieldValue(field.id, field.answer ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
