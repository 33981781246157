import React from 'react';
import { Box, Flex, useTheme } from '@endpoint/blockparty';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';

const SignUpLeft = ({ children, ...props }: BoxProps) => {
  const { colors, config } = useTheme();
  const { organization } = config;
  const isWhiteLabelPartner = organization !== 'Endpoint';
  const themedBackgroundColor = isWhiteLabelPartner ? colors.brandPrimary500 : colors.slate500;

  return (
    <Flex
      _before={{
        backgroundColor: 'inherit',
        // @ts-ignore
        content: '""',
        height: '100%',
        left: '-100vw',
        position: 'absolute',
        right: '100%',
        top: 0,
      }}
      backgroundColor={themedBackgroundColor}
      position="relative"
      width="100%"
    >
      <Box
        mb={{ base: 'space30', md: 'initial' }}
        mt={{ base: 'space30', md: 'space90' }}
        mx={{ base: 'space60', md: 'space70', lg: '88px' }}
        width="100%"
        {...props}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default SignUpLeft;
