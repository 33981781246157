import React, { FC } from 'react';
import { Link, useDisclosure } from '@endpoint/blockparty';
import { TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';

import { PaymentOptionsDrawer } from '../PaymentOptionsDrawer';

interface TodoDrawOpenerProps {
  content: TodoAssignmentContent;
}

export const TodoDrawerOpener: FC<TodoDrawOpenerProps> = ({ content }: TodoDrawOpenerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentOptionsDrawer] = content.children;

  return (
    <>
      <PaymentOptionsDrawer content={paymentOptionsDrawer} isOpen={isOpen} onClose={onClose} />
      <Link as="button" border="none" color="brandPrimary500" textDecoration="underline" type="button" onClick={onOpen}>
        {content.value}
      </Link>
    </>
  );
};
