import { gql } from '@apollo/client';

export const ORDER_PRELIM_WITH_ADDRESS_MUTATION = gql`
  mutation orderPrelimWithAddress($input: OrderPrelimWithAddressInput!) {
    orderPrelimWithAddress(input: $input) {
      transactionId
    }
  }
`;

export const ORDER_PRELIM_WITH_PARCEL_MUTATION = gql`
  mutation orderPrelimWithParcel($input: OrderPrelimWithParcelInput!) {
    orderPrelimWithParcel(input: $input) {
      transactionId
    }
  }
`;
