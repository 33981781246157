/**
 * Takes a number and returns US currency as whole dollar string
 * Example usage: formatCurrency(2500.00); --> '$2,500'
 */
export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};
