import React, { useState, useEffect, useRef, FC } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Box, Button, EPContent, Flex, Heading, Stack, Text, Link } from '@endpoint/blockparty';
import * as Sentry from '@sentry/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import { AlertMessage } from 'components/AlertMessage';
import { AnchoredButton } from 'components/AnchoredButton';
import { useAppContext } from 'utils/context';
import { ANCHORED_BUTTON_OFFSET } from 'consts/responsive';
import { trackAction } from 'utils/analytics';
import { getNationalNumber } from 'utils/formatPhone';
import { ProfileTrackingEvents } from 'consts/analytics';
import { useRequestProfileUpdate } from 'hooks/useRequestProfileUpdate';
import { useGetActiveFileNumbers } from 'hooks/useGetActiveFileNumbers';
import { ProfileUpdateInput } from '@endpoint/endpoint-bff-graphql-schema';
import { PROFILE_CANCELLATION } from 'consts/routes';
import { useNavigate } from 'react-router';
import { useCompanySupportPhoneNumber } from 'hooks/useCompanySupportPhoneNumber';

import { profileEditValidationSchema } from './helpers/profileEditValidationSchema';
import { MappedInputFields } from './helpers/MappedInputFields';

const profileFields = [
  {
    label: 'First Name',
    name: 'firstName',
  },
  {
    label: 'Middle Name (optional)',
    name: 'middleName',
  },
  {
    label: 'Last Name',
    name: 'lastName',
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'U.S. Mobile Phone Number',
    name: 'phone',
    autoFormat: true,
    options: {
      numericOnly: true,
      blocks: [0, 3, 0, 3, 4],
      delimiters: ['(', ')', ' ', '-'],
    },
  },
];

export const UnityProfile: FC = () => {
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { user } = useAppContext();
  const formikRef = useRef<FormikProps<ProfileUpdateInput>>(null);
  const supportPhoneNumber = useCompanySupportPhoneNumber();

  const { requestProfileUpdate, requestProfileUpdateResult, requestProfileUpdateError } = useRequestProfileUpdate();
  const { getActiveFileNumbers, activeFileNumbers, activeFileNumbersLoading, activeFileNumberError } =
    useGetActiveFileNumbers();

  const formInitialValues: ProfileUpdateInput = {
    firstName: user?.firstName || '',
    middleName: user?.middleName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
    fileNumber: '',
  };

  const deleteNullFields = (input: ProfileUpdateInput) => {
    const tmpObject = input;

    Object.keys(input).forEach((key) => {
      if (!tmpObject[key as keyof ProfileUpdateInput]) delete tmpObject[key as keyof ProfileUpdateInput];
    });

    return tmpObject;
  };

  useEffect(() => {
    if (requestProfileUpdateResult) {
      setHasSubmitted(true);
    }

    if (requestProfileUpdateError) {
      formikRef?.current?.setStatus({ error: 'Failed to request profile edit' });
    }
  }, [requestProfileUpdateResult, requestProfileUpdateError]);

  useEffect(() => {
    const values = formikRef.current?.values;

    if (activeFileNumberError) {
      Sentry.captureEvent({
        level: Sentry.Severity.Warning,
        message: `Error trying to retrieve active transactions fileNumbers by status. Error: ${JSON.stringify(
          activeFileNumberError,
        )}}`,
      });
    }

    const fileNumbers = activeFileNumbers?.getActiveFileNumbers;

    if ((fileNumbers || activeFileNumberError) && values) {
      let input = deleteNullFields({
        fileNumber: fileNumbers ? fileNumbers[0] : undefined,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: getNationalNumber(values.phone),
        middleName: user?.middleName !== values.middleName ? values.middleName : undefined,
      });

      if (user.isAgent) {
        input = {
          ...input,
          isAgent: user.isAgent,
        };
      }

      if (user.isTransactionCoordinator) {
        input = {
          ...input,
          isTransactionCoordinator: user.isTransactionCoordinator,
        };
      }

      requestProfileUpdate(input).catch((err) => {
        Sentry.captureEvent({
          level: Sentry.Severity.Warning,
          message: `Error trying to requestProfileUpdate. Error: ${JSON.stringify(err)}}`,
        });
      });
    }
  }, [user, requestProfileUpdate, activeFileNumbers, activeFileNumbersLoading, activeFileNumberError]);

  const handleEditProfileRequest = () => {
    trackAction(ProfileTrackingEvents.PROFILE_EDIT);
    getActiveFileNumbers();
  };

  return (
    <>
      <EPContent mb={{ base: ANCHORED_BUTTON_OFFSET, md: 'space0' }}>
        <Box>
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            innerRef={formikRef}
            validationSchema={profileEditValidationSchema}
            onSubmit={handleEditProfileRequest}
          >
            {({ dirty, isSubmitting, isValid, resetForm, status: formStatus }) => {
              return (
                <Form>
                  <Heading mb="space50" size="fontSize40">
                    Request Profile Edit
                  </Heading>
                  {hasSubmitted && (
                    <Box mb="space70">
                      <AlertMessage
                        description={`We will update your profile as soon as we review your changes. If your issue is urgent, call us at ${supportPhoneNumber}.`}
                        status="info"
                        title="Your request to update information has been received."
                      />
                    </Box>
                  )}
                  {formStatus?.error && (
                    <Box mb="space70">
                      <AlertMessage description={formStatus?.error} status="error" />
                    </Box>
                  )}
                  <Text as="p" mb="space70">
                    Fill out the fields you would like to request edit. You are not required to fill out the entire
                    form.
                  </Text>
                  <Stack spacing="space60">
                    <MappedInputFields fields={profileFields} />
                  </Stack>

                  <Box mb="space90" mt="space80">
                    <Text>
                      If you would like to deactivate your account,{' '}
                      <Link
                        cursor="pointer"
                        data-test-id="profile-cancel-link"
                        textDecoration="underline"
                        onClick={() => navigate(PROFILE_CANCELLATION)}
                      >
                        <Text as="p" color="blue500" display="inline">
                          click here
                        </Text>
                      </Link>
                      .
                    </Text>
                  </Box>

                  <AnchoredButton>
                    <Flex justifyContent="flex-end">
                      <Button
                        isDisabled={!dirty || !isValid}
                        isLoading={isSubmitting}
                        mr="space50"
                        type="reset"
                        variant="outline"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        isDisabled={!dirty || !isValid}
                        isLoading={isSubmitting}
                        loadingText="Sending request..."
                        type="submit"
                      >
                        Request Edit
                      </Button>
                    </Flex>
                  </AnchoredButton>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </EPContent>
    </>
  );
};
