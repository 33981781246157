import React, { FC } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { useWireInstructions } from 'hooks/useWireInstructions';

import { TodoWireInstructions } from '../TodoWireInstructions';

interface ClosingWireInstructionsWidgetProps {
  amount: string;
  isInternational?: boolean;
}

export const ClosingWireInstructionsWidget: FC<ClosingWireInstructionsWidgetProps> = ({ amount, isInternational }) => {
  const { bank, creditBeneficiary, accountNumber, routingNumber, swiftCode, transactionDetails, isLoading, error } =
    useWireInstructions(isInternational);

  const numericAmount = parseFloat(amount);
  const formattedAmount = formatCurrency(numericAmount);
  const payment = { paymentAmount: formattedAmount, accountNumber, routingNumber, swiftCode };

  return (
    <TodoWireInstructions
      error={error}
      isInternational={isInternational}
      isLoading={isLoading}
      wireInstructions={{ bank, creditBeneficiary, payment, transactionDetails }}
    />
  );
};
