import React, { FC } from 'react';
import { Box, Grid } from '@endpoint/blockparty';
import { CENTERED_CONTENT_WIDTH, TRANSACTION_GRID } from 'consts/responsive';

export const TransactionGrid: FC = ({ children }) => (
  <Box p={{ base: 'space50', md: 'space60' }}>
    <Box margin="0 auto" maxWidth={CENTERED_CONTENT_WIDTH} width="100%">
      <Grid gap="space60" templateColumns={TRANSACTION_GRID}>
        {children}
      </Grid>
    </Box>
  </Box>
);
