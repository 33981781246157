import React, { FC, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Text, Stack } from '@endpoint/blockparty';
import { useParams } from 'react-router';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { Upload } from 'components/Upload';

export interface TodoFileUploaderProps {
  field: FieldOptions;
}

export const TodoFileUploader: FC<TodoFileUploaderProps> = ({
  field: { id: fieldId, label, answer, allowMultiple },
}) => {
  const { todoId } = useParams();
  const { values, setFieldValue } = useFormikContext<GenericObject>();

  useEffect(() => {
    if (answer) {
      setFieldValue(fieldId, answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const files = values[fieldId];

  const updateFiles = (filesToUpdate: any) => {
    setFieldValue(fieldId, filesToUpdate);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFileUploaded = useCallback(updateFiles, [fieldId]);

  return (
    <Stack mt="space40" spacing="space0">
      {label && (
        <Text color="carbon900" mb="space60" size="fontSize20">
          {label}
        </Text>
      )}
      <Upload callback={onFileUploaded} files={{ ...files }} multiple={allowMultiple} todoAssignmentId={todoId} />
    </Stack>
  );
};
