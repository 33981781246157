import React, { ReactElement, useContext } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { AppContext, AppContextType } from 'utils/context';
import { ErrorBoundary } from 'components/ErrorBoundary';

type ParticipantCheckProps = {
  element: ReactElement;
};

export const ParticipantCheck = ({ element }: ParticipantCheckProps) => {
  const { user }: AppContextType = useContext(AppContext);
  const { transactionId } = useParams();
  const isParticipant = transactionId && user.transactions?.includes(transactionId);

  return isParticipant === true ? <ErrorBoundary element={element} /> : <Navigate replace to="/error" />;
};
