import { useQuery } from '@apollo/client';
import { Document } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';

import { GET_TODO_ASSIGNMENT_DOCUMENTS } from './queries';

export const useGetTodoAssignmentDocuments = (transactionId: string, todoAssignmentId: string) => {
  const sentryTransaction = useSentryTransaction('getTodoAssignmentDocuments', 'GraphQL client query');

  const { data, error, loading } = useQuery<{ getTodoAssignmentDocuments: Document[] }>(GET_TODO_ASSIGNMENT_DOCUMENTS, {
    variables: { transactionId, todoAssignmentId },
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  if (!loading) {
    sentryTransaction.finish();
  }

  return {
    todoAssignmentDocuments: data?.getTodoAssignmentDocuments,
    todoAssignmentDocumentsLoading: loading,
    todoAssignmentDocumentsError: error,
  };
};
