import { gql } from '@apollo/client';

export const CREATE_UNASSIGNED_DOCUMENT = gql`
  mutation CreateUnassignedDocument($input: CreateUnassignedDocumentInput!) {
    createUnassignedDocument(input: $input) {
      id
      transactionId
      name
      type
      uploadUrl
    }
  }
`;
