import React, { FC } from 'react';
import { Text } from '@endpoint/blockparty';
import { SummaryField } from '@endpoint/endpoint-bff-graphql-schema';

interface SummaryFixedSumProps {
  field: SummaryField;
}

export const SummaryFixedSum: FC<SummaryFixedSumProps> = ({ field }: SummaryFixedSumProps) => {
  return (
    <>
      {Object.entries(field.answer).map(([key, value], index) => {
        return (
          <Text key={`${key}-${value}`} as="p" dataTestId={`fixed-sum-value-${index}`}>
            {value}% - {key}
          </Text>
        );
      })}
    </>
  );
};
