import { useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { SignUpInput } from '@endpoint/endpoint-bff-graphql-schema';

import { SignUpData, SignUpVariables, SIGN_UP_MUTATION } from './mutations';

export const useSignUpMutation = () => {
  const sentryTransactionSignUp = useSentryTransaction('signUp', 'GraphQL client mutation');
  const [runMutation, { data: signUpResult, loading: signUpLoading, error: signUpError }] = useMutation<
    SignUpData,
    SignUpVariables
  >(SIGN_UP_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: {
        [SENTRY_TRACE_HEADER_NAME]: sentryTransactionSignUp.toTraceparent(),
      },
    },
  });

  const signUpMutation = (input: SignUpInput) => {
    return runMutation({
      variables: { input },
    });
  };

  if ((signUpResult || signUpError) && !signUpLoading) {
    sentryTransactionSignUp.finish();
  }

  return {
    signUpMutation,
    signUpResult,
    signUpLoading,
    signUpError,
  };
};
