import React, { FC } from 'react';
import { Box, Button, Flex, IconNames } from '@endpoint/blockparty';

interface NavigationButtonProps {
  iconRight?: IconNames;
  isDisabled?: boolean;
  isBackDisabled?: boolean;
  isLoading?: boolean;
  goNext: React.MouseEventHandler<HTMLElement>;
  goPrevious?: React.MouseEventHandler<HTMLElement>;
}

export const NavigationButton: FC<NavigationButtonProps> = ({
  children,
  isDisabled = false,
  isBackDisabled = false,
  isLoading = false,
  iconRight,
  goNext,
  goPrevious,
}) => {
  return (
    <Box
      bg="background"
      bottom="0"
      boxShadow={{ base: 'medium', md: 'none' }}
      display={{ base: 'block', md: 'flex' }}
      flex={1}
      justifyContent="flex-end"
      left="0"
      mt={{ base: 'space0', md: 'space80' }}
      position={{ base: 'fixed', md: 'initial' }}
      px={{ base: 'space50', md: 'space0' }}
      py={{ base: 'space30', md: 'space0' }}
      right="0"
      width="100%"
    >
      <Flex flex={1} justifyContent="flex-end" width="100%">
        {goPrevious && (
          <Button
            dataTestId="btn-navigation-prev"
            iconLeft="ArrowChevronLeft"
            isDisabled={isBackDisabled}
            variant="link"
            onClick={goPrevious}
          >
            Back
          </Button>
        )}
        <Button
          dataTestId="btn-navigation"
          iconRight={iconRight}
          isDisabled={isDisabled}
          isLoading={isLoading}
          loadingText="Submitting..."
          onClick={goNext}
        >
          {children}
        </Button>
      </Flex>
    </Box>
  );
};
