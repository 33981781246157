import { useMutation } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

import { OperationVariables, SaveTodoAssignmentStepResponse, SAVE_TODO_ASSIGNMENT_STEP_MUTATION } from './mutations';

export const useSaveTodoAssignmentStep = () => {
  const sentryTransaction = useSentryTransaction('saveTodoAssignmentStep', 'GraphQL client mutation');

  const [saveTodoAssignmentStep, { data, error, loading }] = useMutation<
    SaveTodoAssignmentStepResponse,
    OperationVariables
  >(SAVE_TODO_ASSIGNMENT_STEP_MUTATION, {
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  if ((error || data) && !loading) {
    sentryTransaction.finish();
  }

  return {
    saveTodoAssignmentStep,
    saveTodoAssignmentStepLoading: loading,
    saveTodoAssignmentStepError: error,
  };
};
