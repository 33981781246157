import React from 'react';
import { FieldOptions, FieldValidations } from '@endpoint/endpoint-bff-graphql-schema';
import { useField } from 'formik';
import { Input, InputErrorMessage, InputGroup, InputLabel } from '@endpoint/blockparty';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

interface TodoNumberProps {
  field: FieldOptions;
}

export const TodoNumberInput = ({ field }: TodoNumberProps) => {
  const [formikField, meta] = useField(field.id);
  const isRequired = field.validations.some((validation) => validation.type === FieldValidations.Required);

  useTodoFieldInitializer(field);

  const isInvalid = Boolean(meta.touched && meta.error);

  return (
    <InputGroup groupId={field.id} isInvalid={isInvalid} isRequired={isRequired} mb="space60">
      {field.label && <InputLabel>{field.label}</InputLabel>}

      <Input
        {...formikField}
        className={field.isSensitiveInformation ? 'fs-exclude dd-privacy-hidden' : undefined}
        pl="16px"
        placeholder={field.placeHolder ?? ''}
        type="number"
      />

      {isInvalid && <InputErrorMessage>{meta.error}</InputErrorMessage>}
    </InputGroup>
  );
};
