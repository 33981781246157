import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Heading, Text, Box, Flex, Button, useToast } from '@endpoint/blockparty';
import { TodoButtonNavigation } from '@endpoint/endpoint-bff-graphql-schema';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { getTodoStepUrl } from 'routes/TransactionDetail/Todo/helpers/navigation';
import { useSubmitTodoAssignmentStep } from 'hooks/useSubmitTodoAssignmentStep';
import { AlertMessage } from 'components/AlertMessage';
import { QueryParam } from 'consts/enums';

export interface EMDErrorProps {
  validationError: boolean;
}

export const EMDError: FC<EMDErrorProps> = ({ validationError }) => {
  const { todoId = '', transactionId = '', stepId = '' } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { submitTodoAssignmentStep } = useSubmitTodoAssignmentStep();
  const { getTodoAssignment, setShowTodoStepNavigationPanel, todoAssignment } = useTodoStepContext();

  const handleClick = async () => {
    try {
      const response = await submitTodoAssignmentStep({
        variables: {
          input: {
            todoAssignmentId: todoId,
            currentStepId: stepId,
            direction: TodoButtonNavigation.PREVIOUS,
            hasSuccessPage: todoAssignment.hasSuccessPage,
            hasSummaryPage: todoAssignment.hasSummaryPage,
          },
        },
      });

      const nextStepId = response.data?.submitTodoAssignmentStep?.todoAssignmentStepId;

      if (nextStepId) {
        const stepUrl = `${getTodoStepUrl({ transactionId, todoId, stepId: nextStepId })}?${
          QueryParam.DISABLED_PAY_ONLINE
        }=true`;

        navigate(stepUrl);
        getTodoAssignment({ variables: { todoAssignmentId: todoId, stepId: nextStepId } });
      }

      setShowTodoStepNavigationPanel(true);
    } catch (e) {
      toast({
        duration: 5000,
        position: 'top-right',
        render: ({ onClose }) => (
          <Box mr="space50" mt="space50">
            <AlertMessage isCloseable onClose={onClose}>
              <p>There was an error updating your to-do.</p>
            </AlertMessage>
          </Box>
        ),
      });
    }
  };

  const errorMessage = validationError
    ? 'Unfortunately, our payment processor was unable to successfully verify your identity.'
    : 'Unfortunately, we were unable to successfully initiate this transfer.';

  return (
    <>
      <Heading as="h1" data-test-id="error-header" fontWeight="semi" mb="space50" size="fontSize50">
        {errorMessage}
      </Heading>
      <Text>Please select a different payment method by clicking the “Back to payment methods” button below.</Text>
      <Box
        bg="background"
        bottom="0"
        boxShadow={{ base: 'medium', md: 'none' }}
        display="flex"
        flex={1}
        justifyContent="flex-end"
        left="0"
        mt={{ base: 'space0', md: 'space80' }}
        position={{ base: 'fixed', md: 'initial' }}
        px={{ base: 'space50', md: 'space0' }}
        py={{ base: 'space30', md: 'space0' }}
        right="0"
        width="100%"
      >
        <Flex justifyContent="flex-end">
          <Button onClick={handleClick}>Back to payment methods</Button>
        </Flex>
      </Box>
    </>
  );
};
