import { useState, useEffect, useContext } from 'react';
import { AppContext, AppContextType } from 'utils/context';
import { getErrorDetails } from 'Auth/Utilities/helper';
import { ErrorCodes } from 'Auth/Utilities/Constant';

export const useInputError = (customErrors: Map<ErrorCodes, React.ReactNode>) => {
  const { authFields }: AppContextType = useContext(AppContext);
  const [error, setError] = useState<React.ReactNode>(null);

  useEffect(() => {
    const getCustomError = (code: ErrorCodes): React.ReactNode => customErrors.get(code);

    if (authFields.errorCode) {
      const { inputError } = getErrorDetails(authFields.errorCode);

      setError(inputError ? getCustomError(authFields.errorCode) || inputError : '');
    }
  }, [authFields.errorCode, customErrors]);

  return error;
};
