import { object as yupObject, string as yupString, ObjectSchema } from 'yup';
import { ContactType } from '@endpoint/endpoint-bff-graphql-schema';
import { ObjectShape } from 'yup/lib/object';

export const REQUIRED_COMPANY_NAME_ERROR = 'Please enter your company name';
export const MIN_WIDTH_COMPANY_NAME_ERROR = 'Company name should have at least two characters';
export const REQUIRED_OPERATING_STATE_ERROR = 'Please select your operating state';
const PHONE_NUMBER_WITH_MASK_LENGTH = 14;
const PHONE_NUMBER_FORMAT_ERROR = 'Please enter a valid 10-digit U.S. mobile phone number';

const BuyerSellerProfileFieldsSchema = {
  firstName: yupString().trim().required('Please enter your first name'),
  middleName: yupString(),
  lastName: yupString().trim().required('Please enter your last name'),
  suffix: yupString(),
  phone: yupString()
    .min(PHONE_NUMBER_WITH_MASK_LENGTH, PHONE_NUMBER_FORMAT_ERROR)
    .max(PHONE_NUMBER_WITH_MASK_LENGTH, PHONE_NUMBER_FORMAT_ERROR),
};

const AgentProfileFieldsSchema = {
  ...BuyerSellerProfileFieldsSchema,
  entityName: yupString().min(2, MIN_WIDTH_COMPANY_NAME_ERROR).required(REQUIRED_COMPANY_NAME_ERROR),
  stateOfOperation: yupString().required(REQUIRED_OPERATING_STATE_ERROR),
  licenseNumber: yupString(),
};

const MortgageBrokerProfileFieldsSchema = {
  ...BuyerSellerProfileFieldsSchema,
  entityName: yupString().min(2, MIN_WIDTH_COMPANY_NAME_ERROR).required(REQUIRED_COMPANY_NAME_ERROR),
  stateOfOperation: yupString().required(REQUIRED_OPERATING_STATE_ERROR),
};

const ProfileValidationSchema: Partial<Record<ContactType, ObjectSchema<ObjectShape>>> = {
  [ContactType.TRANSACTEE_INDIVIDUAL]: yupObject().shape(BuyerSellerProfileFieldsSchema),
  [ContactType.TRANSACTEE_CORPORATION]: yupObject().shape(BuyerSellerProfileFieldsSchema),
  [ContactType.TRANSACTEE_PARTNERSHIP]: yupObject().shape(BuyerSellerProfileFieldsSchema),
  [ContactType.TRANSACTEE_TRUST]: yupObject().shape(BuyerSellerProfileFieldsSchema),
  [ContactType.AGENT]: yupObject().shape(AgentProfileFieldsSchema),
  [ContactType.TRANSACTION_COORDINATOR]: yupObject().shape(MortgageBrokerProfileFieldsSchema),
  [ContactType.MORTGAGE_BROKER]: yupObject().shape(MortgageBrokerProfileFieldsSchema),
  [ContactType.LOAN_OFFICER]: yupObject().shape(MortgageBrokerProfileFieldsSchema),
  [ContactType.LOAN_PROCESSOR]: yupObject().shape(MortgageBrokerProfileFieldsSchema),
};

export default ProfileValidationSchema;
