import { createContext } from 'react';

export interface OrderReportContextValue {
  currentStep: number;
  setCurrentStep: Function;
  reswareFileNumber: string;
  setReswareFileNumber: Function;
  setOrderMethod: Function;
}

export const OrderReportContext = createContext<OrderReportContextValue>({
  currentStep: 1,
  setCurrentStep: () => null,
  reswareFileNumber: '',
  setReswareFileNumber: () => null,
  setOrderMethod: () => null,
});
