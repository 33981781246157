export const PAYABLE_TO_CA = 'Endpoint Title, Inc.';
export const PAYABLE_TO_CA_SOUTH_LEGACY = 'Endpoint Escrow, Inc.';
export const PAYABLE_TO_TX = 'Endpoint Title of Texas, LLC';
export const PAYABLE_TO_ENDPOINT_INC = 'Endpoint Closing, Inc.';
export const PAYABLE_TO_AHC_WA = 'Access Home Closing, LLC';
export const PAYABLE_TO_EMD_CHECK = 'Endpoint';
export const PAYABLE_TO_DEFAULT = 'Endpoint Digital Closings, LLC';

export const BANK_DETAILS_TX = {
  name: 'First American Trust, FSB',
  addressLine1: '1500 South Dairy Ashford, Ste 300',
  addressLine2: 'Houston, TX 77077',
};

export const BANK_DETAILS = {
  name: 'First American Trust, FSB',
  addressLine1: '5 First American Way',
  addressLine2: 'Santa Ana, CA 92707',
};

export const EXPANSION_BANK_DETAILS = {
  name: 'First American Trust, FSB',
  addressLine1: '2082 Summit Lake Drive',
  addressLine2: 'Tallahassee, FL 32317',
};

export const BANK_DETAILS_AHC_WA = BANK_DETAILS;
