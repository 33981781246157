import { useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import { ApolloError } from '@apollo/client';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { TRANSACTIONS } from 'consts/routes';
import { useAuth } from 'hooks/auth/useAuth';
import { isNewLoginSystemEnabled } from 'Auth/Utilities/helper';
import { GenericTrackingEvents, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { AppContext, AppContextType } from '../../utils/context';
import { clearTokens } from '../../utils/auth/storage';
import { RouteOptions, SELECT_ROLE_URL, ErrorCodes } from '../../Auth/Utilities/Constant';

export const useRedirectOnGetMeError = () => {
  const { authFields, setAuthFields, user, setUser }: AppContextType = useContext(AppContext);
  const navigate = useNavigate();
  const { clearTokens: clearTokensV2 } = useAuth();
  const { pathname } = useLocation();
  const { unityTransactionCreationEnabled } = useFeatureFlags();
  const newLoginSystemEnabled = isNewLoginSystemEnabled();

  const redirectOnGeneralError = useCallback(async () => {
    if (newLoginSystemEnabled) {
      await clearTokensV2();
    } else {
      await clearTokens();
    }

    navigate(RouteOptions.SIGN_IN, { replace: true });
    trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
    setAuthFields({ ...authFields, password: '', errorCode: ErrorCodes.SomethingWentWrong });
  }, [authFields, clearTokensV2, setAuthFields, navigate, newLoginSystemEnabled]);

  const redirectOnError = useCallback(
    async (error: ApolloError) => {
      if (error?.graphQLErrors[0]?.extensions?.exception?.status !== 404) {
        Sentry.captureEvent({
          level: Sentry.Severity.Error,
          message: `getMe query error -- Error: ${JSON.stringify(error?.graphQLErrors[0]?.message)}}`,
        });

        await redirectOnGeneralError();
      } else {
        const shouldResetUnityUser = [RouteOptions.ROOT, `/${TRANSACTIONS}/in-escrow`, RouteOptions.SIGN_IN].includes(
          pathname,
        );
        const shouldResetLegacyUser = pathname !== SELECT_ROLE_URL;
        const shouldResetUser = unityTransactionCreationEnabled ? shouldResetUnityUser : shouldResetLegacyUser;

        if (!user.id && shouldResetUser) {
          setUser({ isOnboarded: false });
        }
      }
    },
    [redirectOnGeneralError, pathname, unityTransactionCreationEnabled, user.id, setUser],
  );

  return { redirectOnError };
};
