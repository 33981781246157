import { BasicAddress } from '@endpoint/endpoint-bff-graphql-schema';
import { PAYABLE_TO_AHC_WA } from 'consts/moneyTransfer';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { InstructionType, useGetPaymentInstructions } from 'hooks/useGetPaymentInstructions';
import { AHC_WA, ENDPOINT_AZ_BANKING } from 'routes/TransactionDetail/Contacts/data/EndpointContactData';
import { getPayableToNameByMarketIdCheckInstructions } from 'utils/getPayableToName';

type CheckPayableDetailsProps = {
  isAHC: boolean;
  isUnityTransaction: boolean;
  marketId: number;
  transactionId: string;
};

const formatAddress = (checkMailToAddress?: BasicAddress) => {
  if (!checkMailToAddress) {
    return { cityStateZip: '', street: '' };
  }

  const { street1, street2, city, state, zip } = checkMailToAddress;
  const cityStateZip = `${city}, ${state} ${zip}`;
  const street = `${street1}${street2 ? `, ${street2}` : ''}`;

  return { cityStateZip, street };
};

export const useCheckPayableDetails = ({
  isAHC,
  isUnityTransaction,
  marketId,
  transactionId,
}: CheckPayableDetailsProps) => {
  const { isMarketBankDataFromOrgService } = useFeatureFlags();
  const { checkPayableToName, checkMailToAddress, paymentInstructionsLoading, paymentInstructionsError } =
    useGetPaymentInstructions({
      transactionId,
      instructionType: InstructionType.CHECK,
      skipQuery: !isMarketBankDataFromOrgService,
    });
  const defaultPaymentInstructionsResponse = { paymentInstructionsLoading, paymentInstructionsError };

  if (isMarketBankDataFromOrgService) {
    return {
      ...defaultPaymentInstructionsResponse,
      checkInstructionsAddress: formatAddress(checkMailToAddress),
      payableName: checkPayableToName,
    };
  }

  return {
    ...defaultPaymentInstructionsResponse,
    checkInstructionsAddress: isAHC ? AHC_WA : ENDPOINT_AZ_BANKING,
    payableName: isAHC ? PAYABLE_TO_AHC_WA : getPayableToNameByMarketIdCheckInstructions(marketId, isUnityTransaction),
  };
};
