import { useCompanyContext } from 'utils/context';
import { formatPhone } from 'utils/formatPhone';
import { getCompanyPhoneNumber } from 'utils/getCompanyPhoneNumber';

export const useCompanySupportPhoneNumber = (): string => {
  const { companyName } = useCompanyContext();
  const { generalInquiries } = getCompanyPhoneNumber(companyName);

  return formatPhone(generalInquiries);
};
