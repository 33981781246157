import { Transaction, TransactionStatus, TransactionType } from '@endpoint/platform-api-connector/dist/graphql-types';
import { displayLoanOrPriceAmount } from 'routes/TransactionDetail/Todos/PropertyDetails/helpers';

import { DetailList, displayDateStatus, TransactionCardDetails } from '..';
import { getTransactionRole } from '../getTransactionRole';
import { getSigningDetails } from '../getSigningDetails';

export const baseRefiAndEquityDetails = (t: Transaction): DetailList[] => {
  const noLoanOrPrice = {
    label: 'Loan:',
    value: 'Not available yet',
  };
  const loanAmountDetails =
    !t.loanAmount && !t.price ? noLoanOrPrice : displayLoanOrPriceAmount(t.type, t.price, t.loanAmount);

  const fileNumDetails = {
    label: 'File #',
    value: t.fileNum ? t.fileNum : 'Not available yet',
  };

  return [
    {
      ...fileNumDetails,
    },
    {
      ...loanAmountDetails,
    },
    { ...getSigningDetails(t) },
    { ...displayDateStatus(t) },
  ];
};

export const getRefiAndEquityDetails = ({ transaction, userId }: TransactionCardDetails): DetailList[] => {
  const { status } = transaction;

  switch (status) {
    case TransactionStatus.IN_ESCROW:
    case TransactionStatus.CLOSED:
      if (transaction.type === TransactionType.EQUITY) {
        return [...baseRefiAndEquityDetails(transaction)];
      }

      return [getTransactionRole({ transaction, userId }), ...baseRefiAndEquityDetails(transaction)];
    default:
      return [];
  }
};
