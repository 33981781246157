import React, { FC } from 'react';
import { NavBarItem as EPNavBarItem } from '@endpoint/blockparty';
import { useMatch, useParams } from 'react-router-dom';
import { TRANSACTION } from 'consts/routes';

interface NavBarItemProps {
  route: string;
}

export const NavItemTransaction: FC<NavBarItemProps> = ({ route, children, ...props }) => {
  const { transactionId } = useParams();
  const subHeaderRoute = `/${TRANSACTION}/${transactionId}/${route}`;
  const match = useMatch(subHeaderRoute) ?? false;

  return (
    <EPNavBarItem color="text" fontWeight={match ? 'semi' : null} href={subHeaderRoute} selected={!!match} {...props}>
      {children}
    </EPNavBarItem>
  );
};
