import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/platform-api-connector/dist/graphql-types';

export const GET_TODO_ASSIGNMENT_STATUS = gql`
  query getTodoAssignment($todoAssignmentId: ID!) {
    todoAssignment(where: { id: $todoAssignmentId }) {
      todo {
        schemaKey
      }
      status
      metadata
    }
  }
`;

export const GET_TRANSACTION_DETAILS = gql`
  query getTransactionDetails($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      fileNum
      marketId
      createdAt
      status
      type
      price
      loanAmount
      signing {
        date
        location
        notaryName
        notaryPhone
        notaryProvider
        documentExpirationDate
        isRemote
      }
      buyerSigning {
        date
        location
        notaryName
        notaryPhone
        notaryProvider
        documentExpirationDate
        isRemote
      }
      sellerSigning {
        date
        location
        notaryName
        notaryPhone
        notaryProvider
        documentExpirationDate
        isRemote
      }
      emd {
        datePaid
        amount
        paymentType
        wireTransferType
      }
      prelimPending
      dates {
        estimatedClosing
        cancellationDate
      }
      property {
        propertyImage
        address {
          street1
          street2
          city
          state
          zip
          standardFormat
        }
      }
      participants {
        id
        roles
        user {
          id
          firstName
          lastName
        }
      }
      todos {
        id
        metadata
        name
        status
        escrowNote
        due
        completedAt
        syncType
        updatedAt
        assignments {
          id
          status
          completedAt
          completedByName
        }
      }
      milestones {
        id
        type
        name
        description
        status
        position
        completedAt
        data
        pinnedItems {
          data
        }
      }
      office {
        name
      }
      processVariation
    }
  }
`;

export interface TransactionDetailsPayload {
  transaction: Transaction;
}
