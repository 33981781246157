import { BFF_CLIENT_NAME } from 'Apollo';
import { useQuery } from '@apollo/client';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { getPayableToNameByMarketId } from 'utils/getPayableToName';

import { GET_PAYABLE_TO_NAME, PayableToNamePayload } from './queries';

export interface UseGetPayableToNameProps {
  transactionId: string;
  marketId: number | undefined;
  isUnityTransaction: boolean;
}

export const useGetPayableToName = ({ transactionId, marketId, isUnityTransaction }: UseGetPayableToNameProps) => {
  const { isMarketBankDataFromOrgService } = useFeatureFlags();

  const { data, loading, error } = useQuery<PayableToNamePayload>(GET_PAYABLE_TO_NAME, {
    variables: { input: { transactionId } },
    context: { clientName: BFF_CLIENT_NAME },
    skip: isMarketBankDataFromOrgService !== undefined ? !isMarketBankDataFromOrgService : false,
  });

  const defaultPaymentInstructionsResponse = {
    payableToNameLoading: loading,
    payableToNameError: error,
    payableTo: isMarketBankDataFromOrgService
      ? data?.getPaymentInstructions?.payableToName
      : getPayableToNameByMarketId(marketId, isUnityTransaction),
  };

  return defaultPaymentInstructionsResponse;
};
