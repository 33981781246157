import { gql, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppContext, AppContextType } from 'utils/context';
import { BFF_CLIENT_NAME } from 'Apollo';
import useOrganizationId from 'hooks/useOrganizationId';
import { AuthResponse, ResetPasswordInput } from '@endpoint/endpoint-bff-graphql-schema';
import { useAuth } from 'hooks/auth/useAuth';
import { REQUEST_MFA_URL } from 'Auth/Utilities/Constant';
import { GenericTrackingEvents, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      session
      challengeName
      authentication {
        accessToken
        idToken
      }
    }
  }
`;

export const useResetPasswordExpired = () => {
  const { authFields, setAuthFields, setUser, user }: AppContextType = useContext(AppContext);
  const { data: authData, setData } = useAuth();
  const navigate = useNavigate();

  const organizationId = useOrganizationId();
  const [resetPasswordMutation] = useMutation<{ resetPassword: AuthResponse }, { input: ResetPasswordInput }>(
    RESET_PASSWORD_MUTATION,
    {
      context: {
        clientName: BFF_CLIENT_NAME,
      },
    },
  );

  return async (password: string) => {
    try {
      setUser({ ...user, loading: true });

      const { data } = await resetPasswordMutation({
        variables: {
          input: {
            organizationId,
            username: authData.username,
            session: authData.session,
            newPassword: password,
          },
        },
      });

      if (!data?.resetPassword?.session && !data?.resetPassword?.authentication) {
        throw new Error('An unknown error occurred');
      }

      if (data?.resetPassword?.session) {
        setData('session', data?.resetPassword?.session);
        setAuthFields({
          ...authFields,
          resetPassword: false,
          passwordExpired: true,
        });

        navigate(REQUEST_MFA_URL);
      } else {
        setAuthFields({ ...authFields, errorCode: null, resetPassword: false, passwordExpired: false, mfa: '' });
      }
    } catch (error: any) {
      trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
      setAuthFields({ ...authFields, errorCode: error.message });
    } finally {
      setUser({ ...user, loading: false });
    }
  };
};
