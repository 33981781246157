import React, { FC } from 'react';
import { Drawer, DrawerActionBar, DrawerHeader, DrawerContent, DrawerSection, Flex, Text } from '@endpoint/blockparty';
import { TodoAssignmentContent } from '@endpoint/endpoint-bff-graphql-schema';

interface PaymentOptionsDrawerProps {
  content: TodoAssignmentContent;
  isOpen: boolean;
  onClose: () => void;
}

export const PaymentOptionsDrawer: FC<PaymentOptionsDrawerProps> = ({ content, isOpen, onClose }) => {
  if (!content?.value) {
    return <></>;
  }

  const { drawerHeading, paymentOptions } = JSON.parse(content.value);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerContent>
        <DrawerActionBar />
        <DrawerHeader title={drawerHeading} />
        <DrawerSection overflow="auto">
          {paymentOptions?.length ? (
            paymentOptions.map(({ label, value }: { label: string; value: string }) => (
              <Flex key={label} flex={1} flexDirection="column" pb="space50">
                <Text as="p" fontWeight="semi" mb="space30" textTransform="capitalize">
                  {label}
                </Text>
                <Text as="p">{value}</Text>
              </Flex>
            ))
          ) : (
            <Text as="p">No payment options available</Text>
          )}
        </DrawerSection>
      </DrawerContent>
    </Drawer>
  );
};
