import React, { FC } from 'react';
import { Box, CloseButton, Flex, Icon, ProgressBar, Text } from '@endpoint/blockparty';
import { getFileNameFromUploadedFile, setIconByMimeType } from 'utils/fileUpload';

import { UploadedFileProps } from '..';

interface UploadSuccessProps {
  useGrayUploadSuccess?: boolean;
  uploadedFile: UploadedFileProps;
}

export const UploadSuccess: FC<UploadSuccessProps> = ({ useGrayUploadSuccess, uploadedFile }) => {
  const fileName = getFileNameFromUploadedFile(uploadedFile);
  const mimeType = fileName.split('.')[1];

  return (
    <Box>
      {useGrayUploadSuccess ? (
        <>
          <Flex
            alignItems="center"
            backgroundColor="slate0"
            borderRadius="radiusDefault"
            flexDirection="row"
            justifyContent="space-between"
            mb="space40"
            px="space50"
            py="20px"
          >
            <Flex alignItems="center">
              <Icon
                color={mimeType?.includes('pdf') ? 'currentColor' : 'blue500'}
                data-test-id="document-icon"
                mr="space50"
                name={setIconByMimeType(mimeType)}
                size="large"
              />
              <Box flex={1}>
                <Text style={{ wordBreak: 'break-word' }}>{fileName}</Text>
              </Box>
            </Flex>
            <CloseButton
              onClick={() => {
                uploadedFile.close && uploadedFile.close();
              }}
            />
          </Flex>
        </>
      ) : (
        <>
          <Flex
            alignItems="center"
            backgroundColor="white"
            border="1px solid"
            borderBottom="none"
            borderColor="carbon300"
            borderTopLeftRadius="4px"
            borderTopRightRadius="4px"
            flexDirection="row"
            justifyContent="space-between"
            px="space50"
            py="20px"
          >
            <Flex alignItems="center">
              <Icon color="aloe500" dataTestId="check-circle-icon" mr="space50" name="CheckCircle" size="large" />
              <Box flex={1}>
                <Text style={{ wordBreak: 'break-word' }}>{fileName}</Text>
              </Box>
            </Flex>
            <CloseButton
              onClick={() => {
                uploadedFile.close && uploadedFile.close();
              }}
            />
          </Flex>
          <ProgressBar
            aria-label="document upload"
            barColor="aloe500"
            borderBottomLeftRadius="4px"
            borderBottomRightRadius="4px"
            borderTopLeftRadius="radiusNone"
            borderTopRightRadius="radiusNone"
            completed={100}
            steps={100}
          />
        </>
      )}
    </Box>
  );
};
