import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  EPContainer,
  EPContent,
  EPSidebar,
  Flex,
  Heading,
  Link,
  Text,
} from '@endpoint/blockparty';
import { EscrowTeamMember } from '@endpoint/endpoint-bff-graphql-schema';
import { AlertMessage } from 'components/AlertMessage';
import { AnchoredButton } from 'components/AnchoredButton';
import { ChatEvents } from 'consts/analytics';
import { openChatWindow } from 'utils/zendesk';
import { trackAction } from 'utils/analytics';
import { CompanyContext, useAppContext } from 'utils/context';
import { ContactsSkeleton } from 'components/Skeleton';
import { ANCHORED_BUTTON_OFFSET } from 'consts/responsive';
import { useTransactionContacts } from 'hooks/useTransactionContacts';
import { isExpansionMarket } from 'utils/getMarket';
import { PropertyHeader } from 'components/PropertyHeader';
import { useGetZendeskTag } from 'hooks/useGetZendeskTag';
import { useGetTeamContactInfo } from 'hooks/useGetTeamContactInfo';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { useTransactionDetailsContext } from '../index';
import { ContactList } from './ContactList';
import { TeamContactsHeader } from './TeamContactsHeader';
import {
  formatOfficeLocationWithMarketId,
  getContactInfoByMarketId,
  getSuiteFromAddress,
  formatTeamContactInfo,
} from './helpers';
import { TransactionGroups } from './TransactionGroups';

export const Contacts = () => {
  const { isTransactionContactFromOrgService } = useFeatureFlags();

  const { data: transactionContext, error } = useTransactionDetailsContext();
  const { transactionId = '' } = useParams();
  const { user } = useAppContext();
  const zendeskTag = useGetZendeskTag(user.lastTransactionViewed?.marketId?.valueOf());
  const { companyName } = useContext(CompanyContext);

  const [marketId, setMarketId] = useState<number | null>(null);

  const transaction = transactionContext?.transaction;

  useEffect(() => {
    if (transaction?.marketId) {
      setMarketId(transaction.marketId);
    }
  }, [transaction]);

  const { teamContactInfo, teamContactInfoLoading, teamContactInfoError } = useGetTeamContactInfo(marketId);

  const { contacts, contactsError } = useTransactionContacts(transactionId);

  if (!transaction || !contacts || !user || teamContactInfoLoading) {
    return <ContactsSkeleton />;
  }

  if (error || contactsError || teamContactInfoError) {
    return (
      <Box m="space50">
        <AlertMessage
          errorMessagePrefix="Please try to refresh the page. If the problem persists, please call "
          phoneLinkColor="blue500"
          title="Oops! Something went wrong."
        />
      </Box>
    );
  }

  const team: EscrowTeamMember[] | undefined = contacts?.closingTeam;
  const { property } = transaction || {};

  const contactInfo = isTransactionContactFromOrgService
    ? formatTeamContactInfo(teamContactInfo)
    : getContactInfoByMarketId(marketId);

  const isExpansionContact = isExpansionMarket(marketId);

  if (contactInfo && isExpansionContact) {
    contactInfo.cityStateZip = '';
    contactInfo.street = '';
  }

  const { cityStateZip, email, officeHours, phone, street } = contactInfo || {};

  const { address, suite } = getSuiteFromAddress(street);
  const marketName = isTransactionContactFromOrgService
    ? teamContactInfo?.marketContactOfficeName
    : formatOfficeLocationWithMarketId(marketId);
  const isEmptyContactLocation = !address && !suite && !street && !cityStateZip && !marketName;

  return (
    <>
      <EPContainer gridTemplateRows="max-content">
        <PropertyHeader fileNum={transaction.fileNum} property={property} />
        <EPSidebar data-test-id="contact-sidebar">
          <Box bg="background" px="space50" py="space60">
            <Heading size="fontSize40">Contact Us</Heading>
            <Text data-test-id="help-text-sidebar" display="block" my="space50">
              Questions? We&rsquo;re here for you! A customer service rep will help connect you with a member of your
              closing team.
            </Text>
            <Divider />
            {!isEmptyContactLocation && (
              <Box data-test-id="contact-location" my="space60">
                <Text as="h4" fontWeight="semi">
                  {marketName}
                </Text>
                <Box>
                  <Flex flexDirection="column">
                    {suite ? (
                      <>
                        <Text data-test-id="contact-address">{address}</Text>
                        <Text data-test-id="contact-suite">{suite}</Text>
                      </>
                    ) : (
                      <Text data-test-id="contact-address">{street}</Text>
                    )}
                    <Text data-test-id="contact-citystatezip">{cityStateZip}</Text>
                  </Flex>
                </Box>
              </Box>
            )}
            {officeHours?.length && (
              <Box>
                <Box data-test-id="contact-hours" mb="space60">
                  <Text as="h4" fontWeight="semi">
                    Office Hours
                  </Text>
                  <Flex flexDirection="column">
                    {officeHours?.map((h: string) => {
                      return <Text key={h.charAt(0)}>{h}</Text>;
                    })}
                  </Flex>
                </Box>
                <Divider />
              </Box>
            )}
            {(phone || email) && (
              <Box data-test-id="contact-info" mt="space60">
                <Text as="h4" fontWeight="semi">
                  Contact Us
                </Text>
                <Flex flexDirection="column">
                  {phone && (
                    <Link
                      color={{ base: 'blue500', md: 'carbon900' }}
                      data-test-id="phone"
                      href={`tel:${phone}`}
                      my={{ base: 'space30', md: 'space0' }}
                      textDecoration={{ base: 'underline', md: 'none' }}
                    >
                      {phone}
                    </Link>
                  )}
                  {email && (
                    <Link
                      color="blue500"
                      data-test-id="email"
                      href={`mailto:${email}`}
                      target="_blank"
                      textDecoration="underline"
                    >
                      {email}
                    </Link>
                  )}
                </Flex>
              </Box>
            )}
            <AnchoredButton mt={{ md: 'space60' }} zIndex={1}>
              <Flex justifyContent={{ base: 'flex-end', md: 'center' }} width={{ md: '100%' }}>
                <Button
                  data-test-id="start-zendesk-chat"
                  variant="outline"
                  width="100%"
                  onClick={() => {
                    trackAction(ChatEvents.OPEN_CHAT_CONTACT);
                    openChatWindow(user, zendeskTag);
                  }}
                >
                  Message Your Team
                </Button>
              </Flex>
            </AnchoredButton>
          </Box>
        </EPSidebar>
        <EPContent mb={{ base: ANCHORED_BUTTON_OFFSET, md: 'space0' }}>
          {companyName === 'Endpoint' && !!team?.length && (
            <>
              <TeamContactsHeader title={`Your Closing Specialist${(team?.length ?? 0) > 1 ? 's' : ''}`} />
              <ContactList team={team} />
            </>
          )}
          <TransactionGroups contacts={contacts} transaction={transaction} />
        </EPContent>
      </EPContainer>
    </>
  );
};
