import React, { FC } from 'react';
import { Box, Flex, Image, useTheme } from '@endpoint/blockparty';
import { Link } from 'react-router-dom';
import { TRANSACTIONS } from 'consts/routes';

export const Logo: FC = () => {
  const { config } = useTheme();
  const { LOGO_LIGHT, LOGO_LIGHT_FULL, organization } = config;

  return (
    <Flex alignItems="center" mr={{ base: 'space60', md: '40px' }} width={{ base: 'initial', md: 136 }}>
      <Link to={`/${TRANSACTIONS}/in-escrow`}>
        <Box display={{ base: 'flex', md: 'none' }}>
          <Image alt={`${organization} logo`} src={LOGO_LIGHT} width="37px" />
        </Box>
        <Box display={{ base: 'none', md: 'flex' }}>
          <Image alt={`${organization} logo`} src={LOGO_LIGHT_FULL} />
        </Box>
      </Link>
    </Flex>
  );
};
