import React, { FC, useContext } from 'react';
import { Text, Heading, Box, Flex } from '@endpoint/blockparty';
import { Svg } from 'components/Svg';
import { CompanyContext } from 'utils/context';

export const Note: FC<React.ComponentProps<typeof Flex>> = ({ children, ...props }) => {
  const { companyName } = useContext(CompanyContext);

  return (
    <Flex bg="mist0" borderRadius="radiusDefault" p="space50" {...props}>
      <Svg display={{ base: 'none', md: 'block' }} height="40px" mr="space50" name="Note" width="40px" />
      <Box>
        <Heading as="h4" mb="space30" size="fontSize20">
          Note from {companyName}
        </Heading>
        <Text>{children}</Text>
      </Box>
    </Flex>
  );
};
