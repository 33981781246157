import React from 'react';
import { Text, Box, Loading } from '@endpoint/blockparty';
import { useParams } from 'react-router';
import { TransactionRole, TransactionType } from '@endpoint/platform-api-connector/dist/graphql-types';
import { getAllParticipantsNameByRoles } from 'utils/transactions/getAllParticipantsNameByRoles';
import { useTransaction } from 'hooks/useTransaction';

export const SellersWidget = () => {
  const { transactionId = '' } = useParams();
  const { loading, data } = useTransaction(transactionId);

  const participants = data?.transaction?.participants;
  const type = data?.transaction?.type || TransactionType.SALE_CASH;
  const isRefi = type === TransactionType.REFINANCE;
  const sellers = getAllParticipantsNameByRoles(participants, [TransactionRole.SELLER, TransactionRole.BORROWER]);

  return (
    <Box bg="carbon0" borderRadius="radiusDefault" mb="space70" p="space50">
      <Text as="p" color="carbon500" data-test-id="title-name" size="fontSize10">
        {isRefi ? 'Owner / Borrower' : 'Seller'}
      </Text>
      {loading ? (
        <Loading data-test-id="loading-indicator" mr="space50" size="medium" />
      ) : (
        sellers.map((seller) => (
          <Text key={seller} as="p" data-test-id="seller-name" size="fontSize30">
            {seller}
          </Text>
        ))
      )}
    </Box>
  );
};
