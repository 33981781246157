import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { fetchDocumentsById, TransactionDocument } from 'utils/documents';
import { downloadDocuments } from 'utils/downloadDocuments';

import { getFileExtension } from './helper';

export async function downloadDocumentsById(
  transactionId: string,
  downloadDocumentIds: string[],
): Promise<Blob[] | Blob> {
  const documents = await fetchDocumentsById(downloadDocumentIds, transactionId);

  return downloadDocuments(documents);
}

const getDocumentNameWithExtension = ({ name, mimeType }: TransactionDocument) => {
  const extension = getFileExtension(mimeType);

  return `${name}.${extension}`;
};

export async function downloadSingleFile(downloadDocumentData: TransactionDocument): Promise<Blob> {
  const response = await fetch(downloadDocumentData.url || '');
  const dataBlob = await response.blob();

  const url = window.URL.createObjectURL(dataBlob);
  const link = document.createElement('a');

  const nameWithExtension = getDocumentNameWithExtension(downloadDocumentData);

  link.href = url;
  link.setAttribute('download', nameWithExtension);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return dataBlob;
}

export async function downloadMultipleFiles(downloadDocumentData: TransactionDocument[]): Promise<Blob[]> {
  const zipFilename = 'documents.zip';

  const jsZip = new JSZip();

  const downloadDocumentBlob = await Promise.all(
    downloadDocumentData.map(async (doc) => {
      const resp = await fetch(doc.url || '');

      return resp.blob();
    }),
  );

  const zippedFolder = downloadDocumentBlob.reduce((jsZipInstance, blob, index) => {
    const nameWithExtension = getDocumentNameWithExtension(downloadDocumentData[index]);

    jsZipInstance.file(nameWithExtension, blob);

    return jsZipInstance;
  }, jsZip);

  const zipFile = await zippedFolder.generateAsync({ type: 'blob' });

  saveAs(zipFile, zipFilename);

  return downloadDocumentBlob;
}
