import { useCallback, useEffect } from 'react';
import { usePlaidLink, PlaidLinkOptions } from 'react-plaid-link';
import { useLazyQuery } from '@apollo/client';
import { GetPlaidAccountResponse, GetPlaidAccountInput } from '@endpoint/endpoint-bff-graphql-schema';
import { BFF_CLIENT_NAME } from 'Apollo';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';

import { GET_ACCOUNT_INFO } from './queries';

interface UsePlaidResponse {
  openPlaid: () => void;
  accountInfo?: GetPlaidAccountResponse;
  hasError: boolean;
  loading: boolean;
}

interface UsePlaidInput {
  linkToken: string;
  transactionId: string;
  todoAssignmentId: string | undefined;
  onAccountInfoReceived: Function | undefined;
  onErrorReceived: Function;
  openOnLaunch?: boolean;
}

export const usePlaid = (input: UsePlaidInput): UsePlaidResponse => {
  const { linkToken, onAccountInfoReceived, transactionId, onErrorReceived, openOnLaunch, todoAssignmentId } = input;
  const { setShowTodoStepNavigationPanel, handlePreviousSubmission } = useTodoStepContext();

  const [getAccountInfo, { data: accountInfo, loading: isGetAccountInfoLoading, error: getAccountInfoError }] =
    useLazyQuery(GET_ACCOUNT_INFO);
  const sentryTransaction = useSentryTransaction('getPlaidAccount', 'GraphQL client query');

  const onSuccess = useCallback(
    (token, metadata) => {
      const getPlaidAccountInput: GetPlaidAccountInput = {
        selectedAccountId: metadata.account_id,
        todoAssignmentId,
        transactionId,
        publicToken: token,
      };

      getAccountInfo({
        variables: { input: getPlaidAccountInput },
        context: {
          clientName: BFF_CLIENT_NAME,
          headers: {
            [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent(),
          },
        },
      });

      sentryTransaction.finish();
    },
    [getAccountInfo, sentryTransaction, todoAssignmentId, transactionId],
  );

  useEffect(() => {
    if (!isGetAccountInfoLoading && onAccountInfoReceived) {
      if (getAccountInfoError || accountInfo?.getPlaidAccount?.errorMessage) {
        onErrorReceived(getAccountInfoError, accountInfo?.getPlaidAccount?.errorMessage);
      } else {
        accountInfo && onAccountInfoReceived(accountInfo.getPlaidAccount);
      }
    }
  }, [accountInfo, getAccountInfoError, isGetAccountInfoLoading, onAccountInfoReceived, onErrorReceived]);

  const onExit = useCallback(
    (err) => {
      if (err) {
        onErrorReceived(err);
      }
      // if no error message, user exited out of plaid voluntarily
      else {
        handlePreviousSubmission();
        setShowTodoStepNavigationPanel(true);
      }
    },
    [handlePreviousSubmission, setShowTodoStepNavigationPanel, onErrorReceived],
  );

  const plaidConfig: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  const { ready, error: plaidError, open } = usePlaidLink(plaidConfig);

  const openPlaid = useCallback(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  useEffect(() => {
    if (openOnLaunch) {
      openPlaid();
    }
  }, [openOnLaunch, openPlaid]);

  useEffect(() => {
    if (plaidError) {
      onErrorReceived(plaidError);
    }
  }, [onErrorReceived, plaidError]);

  return {
    openPlaid,
    accountInfo,
    hasError:
      !accountInfo || getAccountInfoError !== undefined || plaidError !== undefined || !accountInfo?.hasSufficientFunds,
    loading: isGetAccountInfoLoading,
  };
};
