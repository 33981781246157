import React, { FC, createContext, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  Milestone,
  TransactionStatus,
  TransactionSigning,
  TransactionEarnestMoneyDeposit,
} from '@endpoint/platform-api-connector/dist/graphql-types';
import { ApolloError } from '@apollo/client';
import { SubHeaderContainer } from 'components/SubHeader/SubHeaderContainer';
import { SubHeaderTransactionDetail } from 'components/SubHeader/SubHeaderTransactionDetail';
import { SubHeaderLoading } from 'components/Skeleton/SubheaderLoading';
import { useAppContext } from 'utils/context';
import { getTransactionRole } from 'utils/transactions/getTransactionRole';
import { useTransaction } from 'hooks/useTransaction';

import { TransactionNotFound } from './TransactionNotFound';
import { TransactionDetailsPayload } from './Todos/queries';

export interface TransactionDetailsContextTypes {
  data: TransactionDetailsPayload | undefined;
  error: ApolloError | undefined;
  loading?: boolean;
  milestones: Milestone[];
  signing?: TransactionSigning;
  emd?: TransactionEarnestMoneyDeposit;
}

/* ======================================= */

export const TransactionDetailsContext = createContext<TransactionDetailsContextTypes>(undefined!);
export const useTransactionDetailsContext = () => useContext(TransactionDetailsContext);

/* ======================================= */

export const TransactionDetail: FC = () => {
  const { transactionId = '' } = useParams();
  const { user } = useAppContext();
  const { updateLastTransactionViewed } = user;

  const { loading, error, data } = useTransaction(transactionId);

  const contextValue = {
    data,
    error,
    loading,
    milestones: data?.transaction?.milestones || [],
    emd: data?.transaction?.emd || {},
    signing: data?.transaction?.signing || {},
    userRole: data?.transaction
      ? getTransactionRole({ transaction: data.transaction, userId: user.id }).label
      : 'Not Available',
  };

  useEffect(() => {
    const transactionViewed = data?.transaction;

    updateLastTransactionViewed &&
      updateLastTransactionViewed({
        fileNum: transactionViewed?.fileNum,
        marketId: transactionViewed?.marketId,
        officeName: transactionViewed?.office?.name,
      });
  }, [data, updateLastTransactionViewed]);

  const pendingTransaction =
    data?.transaction?.status === TransactionStatus.PRELISTING && !data?.transaction?.prelimPending;

  return (
    <TransactionDetailsContext.Provider value={contextValue}>
      {error || (!data?.transaction && !loading) ? (
        <TransactionNotFound />
      ) : (
        <>
          <SubHeaderContainer data-test-id="nav-subheader-container">
            {loading && <SubHeaderLoading />}
            {data && data.transaction && <SubHeaderTransactionDetail isPendingTransaction={pendingTransaction} />}
          </SubHeaderContainer>
          <Outlet />
        </>
      )}
    </TransactionDetailsContext.Provider>
  );
};
