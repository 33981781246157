import { useLazyQuery } from '@apollo/client';
import { TransactionStatus } from '@endpoint/platform-api-connector/dist/graphql-types';

import { TransactionsPayload, GET_TRANSACTIONS_IDS } from '../../routes/Transactions/queries';

export const useClosedAndPrelistingTransactionIds = () => {
  const [getClosedAndPrelistingTransactionIds, { data, error, loading }] = useLazyQuery<TransactionsPayload>(
    GET_TRANSACTIONS_IDS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        where: {
          transactionStatus: [TransactionStatus.CLOSED, TransactionStatus.PRELISTING],
        },
      },
    },
  );

  return {
    getClosedAndPrelistingTransactionIds,
    closedAndPrelistingTransactionIds: data?.myTransactions,
    closedAndPrelistingTransactionIdsLoading: loading,
    closedAndPrelistingTransactionIdsError: error,
  };
};
