import { Dispatch, SetStateAction } from 'react';

import { HelloSignFieldValue, HelloSignStatus, HelloSignStatuses, HelloSignValue } from './const';

interface UpdateSigningErrorStatus {
  setSigningStatus: Dispatch<HelloSignStatus>;
  setIsEsignDocumentSigned: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  setFieldValue: (fieldId: string, value: any) => void;
  fieldId: string;
  statuses: HelloSignStatuses;
}

// Errors specific to signature URL
export const updateSigningErrorStatus = ({
  setSigningStatus,
  setIsEsignDocumentSigned,
  errorMessage,
  setFieldValue,
  fieldId,
  statuses,
}: UpdateSigningErrorStatus) => {
  switch (true) {
    case /This request has already been signed/.test(errorMessage):
      setSigningStatus(statuses.ALREADY_SIGNED);
      setFieldValue(fieldId, HelloSignValue.SIGN);
      setFieldValue(HelloSignFieldValue.SIGNED, true);
      setIsEsignDocumentSigned(true);
      break;
    case /This request cannot be signed yet/.test(errorMessage):
      setSigningStatus(statuses.CANNOT_BE_SIGNED_YET);
      break;
    case /Signature not found/.test(errorMessage):
      setSigningStatus(statuses.NOT_LOADED);
      break;
    default:
      setSigningStatus(statuses.FAILED);
      break;
  }
};
