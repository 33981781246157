import { Reducer, useReducer } from 'react';

interface BasePollingState {
  isPolling: boolean;
}

interface StartPollingAction<T> {
  type: 'start-polling';
  startPolling: () => void;
  customState: Partial<T>;
}

interface StopPollingAction<T> {
  type: 'stop-polling';
  stopPolling: () => void;
  customState: Partial<T>;
}

type PollingState<T> = BasePollingState & T;
type PollingAction<T> = StartPollingAction<T> | StopPollingAction<T>;

const reducer = <CustomState>(state: PollingState<CustomState>, action: PollingAction<CustomState>) => {
  switch (action.type) {
    case 'start-polling':
      action.startPolling();

      return { ...state, ...action.customState, isPolling: true };
    case 'stop-polling':
      action.stopPolling();

      return { ...state, ...action.customState, isPolling: false };
    default:
      throw new Error('Unexpected polling action dispatched');
  }
};

/**
 * @param initialState polling-specific state that helps determine when to start and/or stop polling
 * @returns current polling state and a function to dispatch actions
 */
export const usePollingReducer = <CustomState>(initialState: CustomState) => {
  const [pollingState, dispatchPollingAction] = useReducer<
    Reducer<PollingState<CustomState>, PollingAction<CustomState>>
  >(reducer, { isPolling: false, ...initialState });

  return {
    pollingState,
    dispatchPollingAction,
  };
};
