import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { QueryParam } from 'consts/enums';

import { markDisabledOptions } from '../../helpers/markDisabledOptions';
import { TodoRadioGroup } from '../TodoRadioGroup';

interface PaymentOptionsRadioGroupProps {
  field: FieldOptions;
}

export const PaymentOptionsRadioGroup = ({ field }: PaymentOptionsRadioGroupProps) => {
  const { stepId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const isDisabledPayOnline = searchParams.get(QueryParam.DISABLED_PAY_ONLINE);

  const radioField: FieldOptions = {
    ...field,
    answer: isDisabledPayOnline ? '' : field.answer,
    enhancedOptions: isDisabledPayOnline ? markDisabledOptions(stepId, field.enhancedOptions) : field.enhancedOptions,
  };

  return <TodoRadioGroup field={radioField} />;
};
