import React from 'react';
import {
  DwollaCustomerStatus,
  DwollaKbaSession,
  GetPlaidAccountResponse,
  DwollaKbaVerificationStatus,
  DwollaCustomerInput,
} from '@endpoint/endpoint-bff-graphql-schema';
import * as Sentry from '@sentry/react';
import { trackAction } from 'utils/analytics';
import { isContainsDwollaValidationError } from 'utils/dwollaErrorStatus';
import { EMDTrackingEvents } from 'consts/analytics';
import { ApolloError } from '@apollo/client';

import { ConfirmTransfer } from '../../Steps/ConfirmTransfer';
import { InsufficientFunds } from '../../Steps/InsufficientFundsError';
import { EMDError } from '../../Steps/Error';
import { AddressForm } from '../../Steps/AddressForm';
import { DwollaCustomerBuilder } from '../DwollaCustomerBuilder';
import { KBAForm } from '../../Steps/kba';
import { RetryForm } from '../../Steps/RetryForm';
import { OnlinePaymentProps } from '../../index';

export interface GetNextStepInput {
  emdAmount: number;
  upsertDwollaCustomer: Function;
  dwollaCustomerUpsertError?: ApolloError;
  dwollaCustomerbuilder: DwollaCustomerBuilder;
  dwollaCustomerUpsertData?: DwollaCustomerInput;
  verifyKBA: Function;
  handleErrors: Function;
  addressFormAttempted: boolean;
  setAddressFormAttempted: (addressFormAttempted: boolean) => void;
}
export class StepManager {
  transactionId: string;
  todoAssignmentId: string;
  linkToken: string | undefined;
  handleBankAccountInfoReceived: Function | undefined;
  accountInfo: GetPlaidAccountResponse | undefined;
  dwollaCustomerStatus: DwollaCustomerStatus | undefined;
  kbaStatus: DwollaKbaVerificationStatus | undefined;
  kbaSession: DwollaKbaSession | undefined;
  props: OnlinePaymentProps;

  private accountInfoLoading = true;

  constructor(transactionid: string, todoAssignmentId: string, props: OnlinePaymentProps) {
    this.transactionId = transactionid;
    this.todoAssignmentId = todoAssignmentId;
    this.props = props;
  }

  setLinkToken(linkToken: string | undefined) {
    this.linkToken = linkToken;
  }

  setAccountInfo(accountInfo: GetPlaidAccountResponse | undefined) {
    this.accountInfo = accountInfo;
    this.accountInfoLoading = false;
  }

  setKbaStatus(status: DwollaKbaVerificationStatus | undefined) {
    this.kbaStatus = status;
  }

  setDwollaCustomerStatus(status: DwollaCustomerStatus | undefined) {
    this.dwollaCustomerStatus = status;
  }

  setKbaSession(kbaSession: DwollaKbaSession) {
    this.kbaSession = kbaSession;
  }

  sethandleBankAccountInfoReceived(handleBankInfoReceived: Function) {
    this.handleBankAccountInfoReceived = handleBankInfoReceived;
  }

  getNextStep(getNextStepInput: GetNextStepInput): JSX.Element {
    const {
      emdAmount,
      dwollaCustomerbuilder,
      upsertDwollaCustomer,
      dwollaCustomerUpsertData,
      dwollaCustomerUpsertError,
      verifyKBA,
      handleErrors,
      addressFormAttempted,
      setAddressFormAttempted,
    } = getNextStepInput;
    const hasSufficientFunds = this.accountInfo && this.accountInfo.hasSufficientFunds;
    const addressFormDwollaValidationError = isContainsDwollaValidationError(dwollaCustomerUpsertError);

    const MAX_AMOUNT_WITH_NO_VERIFICATION_REQUIRED = 5000;
    const MAX_AMOUNT_ALLOWED_FOR_ONLINE_PAYMENT = 100000;

    if (emdAmount > MAX_AMOUNT_ALLOWED_FOR_ONLINE_PAYMENT) {
      trackAction(EMDTrackingEvents.ONLINE_ACH_TRANSFER_FAILURE);

      return <EMDError validationError={false} />;
    }

    if (!hasSufficientFunds && this.accountInfo) {
      return (
        <InsufficientFunds
          linkToken={this.linkToken}
          props={this.props}
          transactionId={this.transactionId}
          onBankAccountInfoReceived={this.handleBankAccountInfoReceived}
          onErrors={handleErrors}
        />
      );
    }

    if (
      emdAmount >= MAX_AMOUNT_WITH_NO_VERIFICATION_REQUIRED &&
      this.dwollaCustomerStatus !== DwollaCustomerStatus.verified &&
      !addressFormAttempted
    ) {
      trackAction(EMDTrackingEvents.EMD_INITIAL_VERIFICATION_STARTED);

      return (
        <AddressForm
          dwollaCustomerBuilder={dwollaCustomerbuilder}
          upsertDwollaCustomer={upsertDwollaCustomer}
          onSubmit={() => setAddressFormAttempted(true)}
        />
      );
    }

    if (
      addressFormAttempted &&
      addressFormDwollaValidationError &&
      this.dwollaCustomerStatus !== DwollaCustomerStatus.retry
    ) {
      return (
        <AddressForm
          dwollaCustomerBuilder={dwollaCustomerbuilder}
          dwollaCustomerUpsertError={dwollaCustomerUpsertError}
          prevDwollaCustomerUpsertData={dwollaCustomerUpsertData}
          upsertDwollaCustomer={upsertDwollaCustomer}
          onSubmit={() => setAddressFormAttempted(true)}
        />
      );
    }

    if (addressFormAttempted && this.dwollaCustomerStatus === DwollaCustomerStatus.unverified) {
      Sentry.captureEvent({
        level: Sentry.Severity.Info,
        message: `Dwolla user verification failed for transactionId: ${this.transactionId}`,
      });

      trackAction(EMDTrackingEvents.EMD_INITIAL_VERIFICATION_FAILED);

      return <EMDError validationError />;
    }

    if (this.dwollaCustomerStatus === DwollaCustomerStatus.retry) {
      trackAction(EMDTrackingEvents.EMD_RETRY_STARTED);

      return <RetryForm dwollaCustomerBuilder={dwollaCustomerbuilder} upsertDwollaCustomer={upsertDwollaCustomer} />;
    }

    if (this.dwollaCustomerStatus === DwollaCustomerStatus.kba && this.kbaSession && !this.kbaStatus) {
      trackAction(EMDTrackingEvents.EMD_INITIAL_VERIFICATION_COMPLETED);
      trackAction(EMDTrackingEvents.EMD_KBA_STARTED);

      return <KBAForm kbaSession={this.kbaSession} verifyKBA={verifyKBA} />;
    }

    if (
      (this.dwollaCustomerStatus && this.dwollaCustomerStatus === DwollaCustomerStatus.deactivated) ||
      this.dwollaCustomerStatus === DwollaCustomerStatus.document ||
      this.dwollaCustomerStatus === DwollaCustomerStatus.suspended ||
      this.kbaStatus === DwollaKbaVerificationStatus.document
    ) {
      Sentry.captureEvent({
        level: Sentry.Severity.Info,
        message: `Dwolla user verification failed for transactionId: ${this.transactionId}`,
      });

      trackAction(EMDTrackingEvents.EMD_KBA_FAILED);

      return <EMDError validationError />;
    }

    if (
      this.kbaStatus === DwollaKbaVerificationStatus.verified ||
      this.dwollaCustomerStatus === DwollaCustomerStatus.verified ||
      emdAmount < MAX_AMOUNT_WITH_NO_VERIFICATION_REQUIRED
    ) {
      trackAction(EMDTrackingEvents.EMD_KBA_COMPLETED);

      return (
        <ConfirmTransfer
          accountInfoLoading={this.accountInfoLoading}
          accountName={this.accountInfo?.selectedAccount?.name}
          accountNumber={this.accountInfo?.selectedAccount?.accountNumberMask}
          amount={emdAmount}
          todoAssignmentId={this.todoAssignmentId}
          transactionId={this.transactionId}
        />
      );
    }

    Sentry.captureEvent({
      level: Sentry.Severity.Info,
      message: `Online payment unsuccessful for transactionId: ${this.transactionId}`,
    });

    trackAction(EMDTrackingEvents.ONLINE_ACH_TRANSFER_FAILURE);

    return <EMDError validationError={false} />;
  }
}
