import { useQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { GET_DOCUMENTS } from 'routes/Transactions/queries';
import { DocumentsQueryResponse } from 'utils/documents';
import { SENTRY_TRACE_HEADER_NAME } from 'utils/sentry';
import { useSentryTransaction } from 'hooks/useSentryTransaction';

export const useDocuments = (transactionId: string) => {
  const sentryTransaction = useSentryTransaction('queryDocuments', 'GraphQL client query');

  const { data, error, loading, startPolling, stopPolling } = useQuery<DocumentsQueryResponse>(GET_DOCUMENTS, {
    variables: { input: { transactionId } },
    context: {
      clientName: BFF_CLIENT_NAME,
      headers: { [SENTRY_TRACE_HEADER_NAME]: sentryTransaction.toTraceparent() },
    },
  });

  if (!loading) {
    sentryTransaction.finish();
  }

  return {
    documents: data?.getDocuments,
    documentsLoading: loading,
    documentsError: error,
    startPollingDocuments: startPolling,
    stopPollingDocuments: stopPolling,
  };
};
