import { TransactionType } from '@endpoint/common-model';
import { formatCurrency } from 'utils/formatCurrency';

export const displayLoanOrPriceAmount = (type?: string, price?: number, loanAmount?: number) => {
  const isRefinanceOrEquity = type === TransactionType.REFINANCE || type === TransactionType.EQUITY;

  const label = isRefinanceOrEquity ? 'Loan:' : 'Price:';
  const fallbackToPrice = isRefinanceOrEquity && !loanAmount;
  const loanOrPriceValue = !isRefinanceOrEquity || fallbackToPrice ? price : loanAmount;
  const value = loanOrPriceValue ? formatCurrency(loanOrPriceValue) : 'N/A';

  return {
    label,
    value,
  };
};
