import React, { FC } from 'react';
import { Stack, Heading } from '@endpoint/blockparty';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { Form, Formik } from 'formik';
import { NavigationButton } from 'components/form/NavigationButton';
import * as yup from 'yup';
import { formatDate, MONTH_DAY_YEAR_WITH_SLASHES, YEAR_MONTH_DAY } from 'utils/formatDate';

import { DwollaCustomerBuilder } from '../../helpers/DwollaCustomerBuilder';
import {
  HEADING_TEXT,
  DATE_OF_BIRTH_INPUT_TEST_ID,
  DATE_OF_BIRTH_INPUT_LABEL,
  DATE_OF_BIRTH_INPUT_PLACEHOLDER,
  DATE_OF_BIRTH_INPUT_NAME,
  SOCIAL_SECURITY_NUMBER_INPUT_TEST_ID,
  SOCIAL_SECURITY_NUMBER_INPUT_LABEL,
  SOCIAL_SECURITY_NUMBER_INPUT_NAME,
  SOCIAL_SECURITY_NUMBER_INPUT_PLACEHOLDER,
} from './const';

interface RetryFormInput {
  upsertDwollaCustomer: Function;
  dwollaCustomerBuilder: DwollaCustomerBuilder;
}

export const RetryForm: FC<RetryFormInput> = ({ upsertDwollaCustomer, dwollaCustomerBuilder }) => {
  const submitForm = async (values: { dateOfBirth: string; ssn: string }) => {
    dwollaCustomerBuilder.withSSN(values.ssn).withDateOfBirth(formatDate(values.dateOfBirth, YEAR_MONTH_DAY));
    /** Similar to EMD1.0 retry flow, there is a race condition on update and subsequent retrieval call. To
     * alleviate, second upsert call retrieves the expected data. Await is only necessary for one of the below
     * calls; since response time to end-user is affected by a second await, opted for the single await
     */
    await upsertDwollaCustomer(dwollaCustomerBuilder.build());
    upsertDwollaCustomer(dwollaCustomerBuilder.build());
  };

  const dateOfBirthUnformatted = dwollaCustomerBuilder.build().dateOfBirth || '';
  const dateOfBirth = dateOfBirthUnformatted && formatDate(dateOfBirthUnformatted, MONTH_DAY_YEAR_WITH_SLASHES);

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        {HEADING_TEXT}
      </Heading>
      {/* @ts-ignore we are submitting when clicking on Next NavigationButton */}
      <Formik
        initialValues={{ dateOfBirth, ssn: '' }}
        validationSchema={yup.object().shape({
          ssn: yup
            .string()
            .required('Social Security Number is required')
            .min(11, 'Social Security Number must be 9 digits'),
          dateOfBirth: yup
            .string()
            .min(10, 'Date is invalid')
            .matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, 'Date is invalid'),
        })}
      >
        {({ values, isValid }) => (
          <Form>
            <Stack spacing="space60">
              <FormikInputGroup
                data-test-id={SOCIAL_SECURITY_NUMBER_INPUT_TEST_ID}
                isRequired
                isSensitiveInformation
                label={SOCIAL_SECURITY_NUMBER_INPUT_LABEL}
                mask={{ numericOnly: true, blocks: [3, 2, 4], delimiters: ['-'], delimiterLazyShow: true }}
                name={SOCIAL_SECURITY_NUMBER_INPUT_NAME}
                placeholder={SOCIAL_SECURITY_NUMBER_INPUT_PLACEHOLDER}
              />
              <FormikInputGroup
                data-test-id={DATE_OF_BIRTH_INPUT_TEST_ID}
                isRequired
                isSensitiveInformation
                label={DATE_OF_BIRTH_INPUT_LABEL}
                mask={{ numericOnly: true, blocks: [2, 2, 4], delimiters: ['/', '/', '/'] }}
                name={DATE_OF_BIRTH_INPUT_NAME}
                placeholder={DATE_OF_BIRTH_INPUT_PLACEHOLDER}
              />
              <NavigationButton goNext={() => submitForm(values)} iconRight="ArrowChevronRight" isDisabled={!isValid}>
                Next
              </NavigationButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
