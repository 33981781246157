import React from 'react';
import { FieldOptions, FieldValidations } from '@endpoint/endpoint-bff-graphql-schema';
import { Field, FieldProps } from 'formik';
import { InputErrorMessage, InputGroup, InputLabel, Textarea } from '@endpoint/blockparty';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

interface TodoTextAreaProps {
  field: FieldOptions;
}

export const TodoTextArea = ({ field }: TodoTextAreaProps) => {
  const isRequired = field.validations.some((validation) => validation.type === FieldValidations.Required);

  useTodoFieldInitializer(field);

  return (
    <Field name={field.id}>
      {({ field: formikField, meta }: FieldProps) => (
        <InputGroup groupId={field.id} isInvalid={Boolean(meta.touched && meta.error)} isRequired={isRequired}>
          {field.label && <InputLabel>{field.label}</InputLabel>}
          <Textarea {...formikField} placeholder={field.placeHolder} />
          {meta.touched && meta.error && <InputErrorMessage>{meta.error}</InputErrorMessage>}
        </InputGroup>
      )}
    </Field>
  );
};
