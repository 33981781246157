import { formatDate, MONTH_DAY_YEAR } from 'utils/formatDate';
import { TransactionSummary, TransactionSummaryDates } from '@endpoint/endpoint-bff-graphql-schema';

import { DetailList } from '..';

export const getListingDetails = (transaction: TransactionSummary): DetailList[] => {
  const { dates, fileNum } = transaction;
  const { prelistingOpened, titleProductDelivered } = dates || {};

  return [
    {
      ...displayListingDate({ prelistingOpened, titleProductDelivered }),
    },
    {
      label: 'File #',
      value: fileNum || 'Not available yet',
    },
    {
      label: 'Status:',
      value: titleProductDelivered ? 'Escrow is ready to be opened' : 'Order Processing',
    },
  ];
};

// Generally, prelisting date will always appear before delivered date
const displayListingDate = ({ prelistingOpened, titleProductDelivered }: TransactionSummaryDates) => {
  return titleProductDelivered
    ? {
        label: 'Delivered:',
        value: formatDate(titleProductDelivered, MONTH_DAY_YEAR),
      }
    : {
        label: 'Ordered:',
        value: formatDate(prelistingOpened, MONTH_DAY_YEAR),
      };
};
