import React, { useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { Box, Heading, Text, Stack, Button, Flex, Link } from '@endpoint/blockparty';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { RouteOptions, SELECT_ROLE_URL } from 'Auth/Utilities/Constant';
import { useInputError } from 'hooks/useInputError';
import { usePageLeavePrompt } from 'hooks/usePageLeavePrompt';
import { useCodeVerify as useCodeVerifyV2 } from 'hooks/auth/useCodeVerify';
import { AppContextType, AppContext } from 'utils/context';
import { useAuth } from 'hooks/auth/useAuth';

import Alerts from '../Alerts';
import { getMfaDisplayPhoneNumber, signUpMfaErrors } from '../../Utilities/helper';
import { MFA_RESEND_CODE_LINK_TEXT, MFA_SUBMIT_BUTTON_TEXT } from '../../../consts/descriptions';

const CONFIRMATION_CODE_WARNING = 'Please enter a 6-digit code';

const MfaSchema = Yup.object().shape({
  code: Yup.string()
    .required(CONFIRMATION_CODE_WARNING)
    .min(6, CONFIRMATION_CODE_WARNING)
    .max(6, CONFIRMATION_CODE_WARNING),
});

interface IMfaFormValues {
  code: string;
}

function MfaSignUp() {
  const { data } = useAuth();
  const navigate = useNavigate();
  const { authFields, setAuthFields }: AppContextType = useContext(AppContext);
  const { user, phone } = authFields;
  const codeError = useInputError(signUpMfaErrors);

  const { codeVerify: codeVerifyV2, codeResend: codeResendV2 } = useCodeVerifyV2();

  useEffect(() => {
    // TODO: delete dependency on 'user' after Amplify login is removed
    if (!user && !data.username) {
      navigate(RouteOptions.SIGN_UP);
    }
  }, [navigate, user, data]);

  const handleCodeSubmit = async (values: IMfaFormValues) => {
    setAuthFields({
      ...authFields,
      navigateToAfterLogin: SELECT_ROLE_URL,
    });

    codeVerifyV2(values.code);
  };

  const confirmationMessage =
    'Please note that leaving this page without entering the confirmation code may cause issues with your account.';

  const phoneNumber = data.phone ? getMfaDisplayPhoneNumber(data.phone) : phone;

  usePageLeavePrompt(confirmationMessage);

  return (
    <Formik initialValues={{ code: '' }} validateOnBlur validationSchema={MfaSchema} onSubmit={handleCodeSubmit}>
      {({ isValid, dirty }) => (
        <Form>
          <Box mb="space70" textAlign="left">
            <Heading as="h2" mb="space50" size="fontSize60">
              Enter your 6-digit code
            </Heading>
            <Stack spacing="space50">
              <Text>
                A code has been sent via text message to <b>{phoneNumber}</b>. If you have not received the code within
                5 minutes,{' '}
                <Text>
                  <Link
                    color="blue500"
                    cursor="pointer"
                    data-test-id="resend-code"
                    textDecoration="underline"
                    onClick={codeResendV2}
                  >
                    {MFA_RESEND_CODE_LINK_TEXT.toLowerCase()}
                  </Link>
                </Text>
                .
              </Text>
            </Stack>
          </Box>
          <Alerts />
          <Box mb="space80">
            <FormikInputGroup
              autoFocus
              error={codeError}
              label="6-Digit Code"
              name="code"
              placeholder="Enter your code"
              type="number"
            />
          </Box>
          <Flex
            backgroundColor="white"
            bottom={{ base: 0, md: 'initial' }}
            boxShadow={{ base: 'medium', md: 'none' }}
            justifyContent="flex-end"
            left={{ base: 0, md: 'initial' }}
            position={{ base: 'fixed', md: 'initial' }}
            px={{ base: 'space50', md: 'space0' }}
            py={{ base: 'space30', md: 'space0' }}
            right={{ base: 0, md: 'initial' }}
            zIndex="overlay"
          >
            <Button isDisabled={!(isValid && dirty)} type="submit">
              {MFA_SUBMIT_BUTTON_TEXT}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default MfaSignUp;
