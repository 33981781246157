import {
  Milestone,
  TransactionSigning,
  TransactionEarnestMoneyDeposit,
  EmdPaymentType,
  MilestoneStatus,
  TransactionRole,
} from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatDate, MONTH_AND_DAY, MONTH_DAY_TIME, MONTH_DAY_YEAR_AT_TIME } from 'utils/formatDate';
import { parseISO, isValid } from 'date-fns';

function isImmediatePaymentType(paymentType: EmdPaymentType | undefined | null) {
  const NON_IMMEDIATE_PAYMENT_TYPES = [EmdPaymentType.CHECK, EmdPaymentType.WIRE_TRANSFER];

  return paymentType && !NON_IMMEDIATE_PAYMENT_TYPES.includes(paymentType);
}

export const getMilestoneDescription = (
  milestone: Milestone,
  signing: TransactionSigning = {},
  emd: TransactionEarnestMoneyDeposit = {},
  role: string = '',
): string => {
  const description = milestone.description;
  const isInProgress = milestone.status === MilestoneStatus.INTERMEDIATE;
  const isComplete = milestone.status === MilestoneStatus.COMPLETE;
  const isUpcoming = milestone.status === MilestoneStatus.UPCOMING;
  const parsedEmdDate = emd && emd.datePaid ? parseISO(emd.datePaid) : null;
  const parsedSigningDate = signing && signing.date ? parseISO(signing.date) : null;
  const isSeller: boolean = role === TransactionRole.SELLER;
  const isListingAgentOrTC: boolean =
    role === TransactionRole.LISTING_AGENT || role === TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR;
  let m5SigningText: string;
  let m6SigningText: string;
  let emdText: string;

  const emdDate = parsedEmdDate && isValid(parsedEmdDate) ? formatDate(parsedEmdDate, MONTH_DAY_TIME) : '';

  const signingDateAndTime =
    parsedSigningDate && isValid(parsedSigningDate) ? ` ${formatDate(parsedSigningDate, MONTH_DAY_YEAR_AT_TIME)}` : '';
  const signingLocation = signing && signing.location ? `, ${signing.location}` : '';

  const documentExpirationDate =
    signing?.documentExpirationDate && isValid(new Date(signing.documentExpirationDate))
      ? formatDate(signing.documentExpirationDate, MONTH_AND_DAY)
      : '';

  if (milestone.position === 5 && signing.isRemote && isComplete && isSeller)
    m5SigningText = `It's time to complete your remote signing appointment with ${signing.notaryProvider}.`;
  else if (milestone.position === 5 && signing.isRemote && isComplete && isListingAgentOrTC)
    m5SigningText = `It's time for the seller to complete their remote signing appointment with ${signing.notaryProvider}.`;
  else if (milestone.position === 5 && (isInProgress || isUpcoming) && isSeller)
    m5SigningText = "We'll let you know when it's time to schedule your signing.";
  else if (milestone.position === 5 && (isInProgress || isUpcoming) && isListingAgentOrTC)
    m5SigningText = "We'll let you know when it's time for the seller to schedule their signing.";
  else if (
    milestone.position === 5 &&
    signing.isRemote &&
    (isInProgress || isUpcoming) &&
    !isSeller &&
    !isListingAgentOrTC
  )
    m5SigningText = "We'll let you know when it's time to schedule signing.";
  else m5SigningText = description || '';

  if (milestone.position === 6 && !signing?.isRemote && isComplete)
    m6SigningText = `Signed on${signingDateAndTime}${signingLocation}`;
  else if (milestone.position === 6 && !signing?.isRemote && !isComplete && signingDateAndTime)
    m6SigningText = `Signing scheduled for${signingDateAndTime}${signingLocation}`;
  else if (milestone.position === 6 && signing.isRemote && isComplete && isSeller)
    m6SigningText = `You have completed signing with ${signing.notaryProvider}.`;
  else if (milestone.position === 6 && signing.isRemote && isComplete && isListingAgentOrTC)
    m6SigningText = `The seller has signed with ${signing.notaryProvider}.`;
  else if (milestone.position === 6 && signing?.isRemote && isComplete && !isSeller && !isListingAgentOrTC)
    m6SigningText = `Signed with ${signing.notaryProvider}.`;
  else if (milestone.position === 6 && signing.isRemote && (isInProgress || isUpcoming) && isSeller)
    m6SigningText = `Make sure to sign your closing documents with ${signing.notaryProvider}${
      documentExpirationDate ? ` by ${documentExpirationDate}` : ''
    }.`;
  else if (milestone.position === 6 && signing.isRemote && (isInProgress || isUpcoming) && isListingAgentOrTC)
    m6SigningText = `Make sure the seller signs their closing documents with ${signing.notaryProvider}${
      documentExpirationDate ? ` by ${documentExpirationDate}` : ''
    }.`;
  else if (milestone.position === 6 && signing.isRemote && isInProgress && !isSeller && !isListingAgentOrTC)
    m6SigningText = `Make sure the seller signs the closing documents with ${signing.notaryProvider}${
      documentExpirationDate ? ` by ${documentExpirationDate}` : ''
    }.`;
  else if (milestone.position === 6 && signing.isRemote && isUpcoming && !isListingAgentOrTC && !isSeller)
    m6SigningText = 'Signing not scheduled yet';
  else m6SigningText = description || '';

  if (
    (milestone.position === 2 && isComplete) ||
    (milestone.position === 2 && !isComplete && emdDate && isImmediatePaymentType(emd?.paymentType))
  ) {
    emdText = `Payment received ${emdDate}`;
  } else emdText = description || '';

  switch (milestone.position) {
    case 2:
      return `${emdText}`;
    case 5:
      return `${m5SigningText}`;
    case 6:
      return `${m6SigningText}`;
    default:
      return `${milestone.description}`;
  }
};
