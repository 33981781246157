import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Auth } from 'aws-amplify';
import { TRACK_GENERIC_ERROR_VIEWED_PAYLOAD, GenericTrackingEvents } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

import { REQUEST_MFA_URL, ErrorCodes } from '../../Auth/Utilities/Constant';
import { AppContext, AppContextType } from '../../utils/context';

export const useResetPassword = () => {
  const { authFields, setAuthFields, setUser }: AppContextType = useContext(AppContext);
  const navigate = useNavigate();

  const resetPassword = async (password: string) => {
    try {
      setUser({ loading: true });
      await Auth.forgotPasswordSubmit(authFields.email, authFields.mfa, password);

      setAuthFields({ ...authFields, errorCode: null, resetPassword: false, mfa: '' });
    } catch (error: any) {
      trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
      setAuthFields({ ...authFields, errorCode: error.code });

      if (error.code === ErrorCodes.CodeMismatchException) {
        navigate(REQUEST_MFA_URL);
      }
    } finally {
      setUser({ loading: false });
    }
  };

  return resetPassword;
};
