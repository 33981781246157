import React, { FC, useEffect } from 'react';
import { Box, Heading, Text, Image, Flex, Link } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';

import { RouteOptions } from '../../Utilities/Constant';

const ResetSuccess: FC = () => {
  const navigate = useNavigate();

  const TIME_TO_REDIRECT = 3000;

  useEffect(() => {
    const timerId = setTimeout(() => navigate(RouteOptions.SIGN_IN), TIME_TO_REDIRECT);

    return () => clearTimeout(timerId);
  }, [navigate]);

  return (
    <>
      <Flex justifyContent="center" mb="space70">
        <Image
          alt="Thank you"
          height="128px"
          src="https://images.ctfassets.net/py22ts3k5d4r/6ja6hUnRpWcOvKevwTrCg9/d02c9cb5c58e4354207560d7f9560357/Illustration_HighFive.svg?h=250"
          width="128px"
        />
      </Flex>
      <Box mb="space70" textAlign="center">
        <Heading as="h2" mb="space40" size="fontSize60">
          Awesome!
          <br />
          Your new password is good to go.
        </Heading>

        <Text>
          In just a moment we’ll ask you to sign in with your new password. If you are not redirected, please{' '}
          <Link
            color="blue500"
            cursor="pointer"
            textDecoration="underline"
            onClick={() => navigate(RouteOptions.SIGN_IN)}
          >
            click here
          </Link>{' '}
          to sign in.
        </Text>
      </Box>
    </>
  );
};

export default ResetSuccess;
