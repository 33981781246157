import { initialize, LDFlagValue, LDContext } from 'launchdarkly-react-client-sdk';
import { getConfig } from 'utils/config';

export const getFlagValue = async (flagKey: string, defaultValue: LDFlagValue, user: LDContext = {}) => {
  const CONFIG = getConfig();

  if (!CONFIG?.LAUNCH_DARKLY_CLIENT_ID) {
    return defaultValue;
  }

  const ldClient = initialize(CONFIG.LAUNCH_DARKLY_CLIENT_ID, user);

  return new Promise((resolve, reject) => {
    ldClient.on('ready', () => {
      try {
        const flagValue = ldClient.variation(flagKey, defaultValue);

        resolve(flagValue);
      } catch (error) {
        reject(error);
      }
    });
  });
};
