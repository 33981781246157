import { TransactionRole, TransactionParticipant, User } from '@endpoint/platform-api-connector/dist/graphql-types';

/**
 * In a transaction, this will return a list of roles the user
 * is represented as based on the user's ID
 *
 * @returns `['LISTING_AGENT', 'SELLING_AGENT', 'BUYER']`
 */
export const getUserRoles = (participants: TransactionParticipant[] = [], userId: User['id'] = '') => {
  if (!userId) return [];

  return participants.reduce((acc, participant) => {
    if (participant.user.id === userId) {
      acc.push(participant.roles[0]);
    }

    return acc;
  }, [] as TransactionRole[]);
};
