import React, { FC } from 'react';
import { Text, Box, Flex } from '@endpoint/blockparty';
import { formatCurrency } from 'utils/formatCurrency';

interface AmountDetailsProp {
  amount: number;
  accountName?: string;
  accountNumber?: string;
}

const prependAccountNum = (accountNum?: string) => {
  return `******* ${accountNum}`;
};

export const AccountDetails: FC<AmountDetailsProp> = (props) => {
  const { amount, accountName, accountNumber } = props;

  return (
    <Flex bg="carbon0" borderRadius="radiusDefault" my="space70" p="space50">
      {' '}
      <Flex flex={2}>
        <Box>
          <Text as="p" size="fontSize10">
            Amount
          </Text>
          <Text as="p" data-test-id="emd-amount" size="fontSize30">
            {formatCurrency(amount)}
          </Text>
        </Box>
      </Flex>
      <Flex flex={2}>
        <Box>
          <Text as="p" size="fontSize10">
            Bank Account Details
          </Text>
          <Text as="p" data-test-id="account-name" size="fontSize30">
            {accountName}
          </Text>
          <Text as="p" data-test-id="account-number" size="fontSize30">
            {prependAccountNum(accountNumber)}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
