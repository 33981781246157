import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { UploadedFileProps } from 'components/Upload';

const MAX_DOC_CHARACTER_COUNT = 24;

export const mimeTypeIconMap: { [index: string]: IconNames } = {
  jpeg: 'Image',
  jpg: 'Image',
  png: 'Image',
  pdf: 'DocumentPDF',
};

export const setIconByMimeType = (mimeType?: string): IconNames => {
  return mimeType && mimeType in mimeTypeIconMap ? mimeTypeIconMap[mimeType] : 'Paper';
};

const getFileNameWithoutExtension = (fileName: string, fileExtension: string): string => {
  const extension = `.${fileExtension}`;

  if (!fileName.includes(extension)) {
    return fileName;
  }

  return fileName.slice(0, fileName.lastIndexOf(extension));
};

export const truncateFileName = (fileName: string = '', fileExtension: string = ''): string => {
  const rawFileExtension = removeDotFromFileExtenstion(fileExtension);

  const fileNameWithoutExtension = getFileNameWithoutExtension(fileName, fileExtension);

  if (fileNameWithoutExtension.length + rawFileExtension.length <= MAX_DOC_CHARACTER_COUNT) {
    let result = fileNameWithoutExtension;

    if (fileExtension) result += `.${rawFileExtension}`;

    return result;
  }

  const sizeOfExtensionWithElipses = rawFileExtension.length + 2;
  const maxSizeOfFileName = MAX_DOC_CHARACTER_COUNT - sizeOfExtensionWithElipses;

  return `${fileNameWithoutExtension.substr(0, maxSizeOfFileName)}...${rawFileExtension}`;
};

const removeDotFromFileExtenstion = (fileExtension: string) => {
  let result = fileExtension;

  if (fileExtension && fileExtension.length > 0 && fileExtension[0] === '.') {
    result = fileExtension.substring(1);
  }

  return result;
};

export const getFileNameFromUploadedFile = (fileInfo: UploadedFileProps) => {
  let fileName = '';

  if (fileInfo.name) fileName = fileInfo.name;
  else if (fileInfo.response && fileInfo.response.length > 0) fileName = fileInfo.response[0].filename;

  return fileName;
};
