import React from 'react';
import { FieldOptions, FieldValidations } from '@endpoint/endpoint-bff-graphql-schema';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { useTodoFieldInitializer } from 'hooks/useTodoFieldInitializer';

interface TodoPhoneInputProps {
  field: FieldOptions;
}

export const TodoPhoneInput = ({ field }: TodoPhoneInputProps) => {
  const isRequired = field.validations.some((validation) => validation.type === FieldValidations.Required);

  const maskOptions = {
    numericOnly: true,
    blocks: [0, 3, 0, 3, 4],
    delimiters: ['(', ')', ' ', '-'],
  };

  useTodoFieldInitializer(field);

  return (
    <FormikInputGroup
      isRequired={isRequired}
      label={field.label}
      mask={maskOptions}
      mb="space60"
      name={field.id}
      placeholder={field.placeHolder ?? ''}
      type="tel"
    />
  );
};
