import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AssistantRole, StateOfOperation } from '@endpoint/endpoint-bff-graphql-schema';
import { Transaction } from '@endpoint/platform-api-connector/dist/graphql-types';
import { SubHeaderContainer } from 'components/SubHeader/SubHeaderContainer';
import { SubHeaderTodo } from 'components/SubHeader/SubHeaderTodo';
import { TwoColumnWithProgressContainer, Step } from 'components/form/TwoColumnWithProgressContainer';
import { Options } from 'components/form/FormikBlockparty/FormikSelectGroup';
import { OpenEscrowSkeleton } from 'components/Skeleton';
import { TRANSACTIONS } from 'consts/routes';
import { useAppContext } from 'utils/context';
import { useAgentOnlyRoute } from 'hooks/useAgentOnlyRoute/useAgentOnlyRoute';

import { OpenEscrowContextValue, OpenEscrowContext } from './Context';
import { Steps } from './Steps';
import { useCancelModal } from '../../hooks/useCancelModal';
import { formatTransactionOptions, openOptions } from './Steps/Step1';
import { removeStep1, removeStep3 } from './Helpers';

const TOTAL_STEPS = Steps.length;

export interface Client {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface TeamMate extends Client {
  role: AssistantRole;
}

export interface UploadResponse {
  filename: string;
  mime_type: string;
  s3: {
    Bucket: string;
    ETag: string;
    Key: string;
    Location: string;
    SSEKMSKeyId: string;
    ServerSideEncryption: string;
    VersionId: string;
    key: string;
  };
}

export interface UploadResponseBff {
  type: string;
  name: string;
  filename: string;
  mime_type: string;
}

export interface FileWrapper {
  id: string;
  name: string;
  percentage: number;
  status: string;
  response: UploadResponse;
}

export interface FormikValue {
  file: {
    [key: string]: FileWrapper;
  };
  clients: Array<Client>;
  openOptions: string;
  optionalNotes?: string;
  representing: string;
  teammates: Array<TeamMate>;
  prelim?: Partial<Transaction>;
  prelimTransactionId?: string;
  // Should not be passed to back-end
  prelimDefaultValues: Options;
}

interface OpenEscrowState {
  currentPrelimId: string;
  currentPrelimTransaction: Transaction;
}

export const OpenEscrow: FC = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  const location = useLocation();
  const passedPrelimId = (location.state as OpenEscrowState)?.currentPrelimId;
  const passedPrelimTransaction = (location.state as OpenEscrowState)?.currentPrelimTransaction;
  const noPrelim =
    user.stateSignedUp &&
    [
      StateOfOperation.FL,
      StateOfOperation.TN,
      StateOfOperation.CO,
      StateOfOperation.TX,
      StateOfOperation.MO,
      StateOfOperation.OH,
      StateOfOperation.IN,
    ].includes(StateOfOperation[user.stateSignedUp]);

  const [isFromPrelim, setIsFromPrelim] = useState(!!passedPrelimTransaction?.fileNum);

  let steps: Step[] = Steps;

  if (isFromPrelim) {
    steps = removeStep3(Steps);
  } else if (noPrelim) {
    // removes step1 if agent is from a state where we don't do prelims
    steps = removeStep1(Steps);
  }

  const [currentStep, setCurrentStep] = useState(1);
  const [reswareFileNumber, setReswareFileNumber] = useState(
    passedPrelimTransaction?.fileNum ? passedPrelimTransaction?.fileNum : '',
  );

  let prelimDefaultValues;

  if (passedPrelimTransaction) {
    const [step1, ...rest] = steps;

    steps = rest;
    prelimDefaultValues = formatTransactionOptions(passedPrelimTransaction);
  }

  const openEscrowContextValue: OpenEscrowContextValue = {
    currentStep,
    setCurrentStep,
    reswareFileNumber,
    setReswareFileNumber,
    isFromPrelim,
    setIsFromPrelim,
  };

  const initialValues = {
    clients: [{ firstName: '', lastName: '', email: '', phone: '' }],
    teammates: [{ firstName: '', lastName: '', email: '', phone: '' }],
    prelimTransactionId: passedPrelimId || '',
    prelim: passedPrelimTransaction || '',
    prelimDefaultValues: prelimDefaultValues || null,
    openOptions: passedPrelimId ? openOptions.existingOrder : '',
  };

  const OpenEscrowContent = useAgentOnlyRoute(
    <TwoColumnWithProgressContainer initialValues={initialValues} steps={steps} title="Open Escrow" />,
    <OpenEscrowSkeleton />,
    user.loading,
  );

  const shouldSubHeaderButtonDisabled = currentStep === TOTAL_STEPS;

  const { onOpen, Modal } = useCancelModal({
    onConfirm: () => navigate(`/${TRANSACTIONS}`),
    cancelButtonText: 'Continue',
    confirmButtonText: 'Yes, Cancel',
  });

  return (
    <OpenEscrowContext.Provider value={openEscrowContextValue}>
      {Modal}
      <SubHeaderContainer>
        <SubHeaderTodo buttonClick={onOpen} buttonLabel="Cancel" isButtonDisabled={shouldSubHeaderButtonDisabled} />
      </SubHeaderContainer>
      {OpenEscrowContent}
    </OpenEscrowContext.Provider>
  );
};
