import React, { FC } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { useWireInstructions } from 'hooks/useWireInstructions';

import { TodoWireInstructions } from '../TodoWireInstructions';

interface EmdWireInstructionsWidgetProps {
  amount?: string;
  isInternational?: boolean;
}

export const EmdWireInstructionsWidget: FC<EmdWireInstructionsWidgetProps> = ({ amount, isInternational }) => {
  const { bank, creditBeneficiary, accountNumber, routingNumber, swiftCode, transactionDetails, isLoading, error } =
    useWireInstructions(isInternational);

  let formattedAmount: string = '';

  if (amount) {
    const numericAmount = parseFloat(amount);

    formattedAmount = formatCurrency(numericAmount);
  } else if (transactionDetails.emdAmount) {
    formattedAmount = formatCurrency(transactionDetails.emdAmount);
  }

  const payment = { paymentAmount: formattedAmount, accountNumber, routingNumber, swiftCode };

  return (
    <TodoWireInstructions
      error={error}
      isInternational={isInternational}
      isLoading={isLoading}
      wireInstructions={{ bank, creditBeneficiary, payment, transactionDetails }}
    />
  );
};
