import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CompanyContext } from 'utils/context';
import { useFormikContext } from 'formik';
import { ApolloError } from '@apollo/client';

import { SupportCounty } from '../types.d';
import { FormikValue } from '../../index';
import { CountiesByState, useCountiesByState } from '../../../../hooks/useCounties/useCountiesByState';
import { Options } from '../../../../components/form/FormikBlockparty/FormikSelectGroup';

function getSelectOptionsForState(countiesByState: CountiesByState, state: string): Options[] {
  return countiesByState[state]?.map((county) => ({ label: county.name, value: county.name })) || [];
}

interface UseCountiesOptionsResponse {
  counties: Options[];
  onSelectState: () => void;
  error: ApolloError | undefined;
  loading: boolean;
}

export function useCountiesOptions(): UseCountiesOptionsResponse {
  const { companyName } = useContext(CompanyContext);
  const { countiesByState, error, loading } = useCountiesByState();
  const [counties, setCounties] = useState<Array<Options>>([]);
  const { setValues, setFieldValue, values } = useFormikContext<FormikValue>();

  const onSelectState = useCallback(
    function onSelectState() {
      const { e: selectEvent } = this;
      const state: keyof SupportCounty = selectEvent.value;

      setFieldValue('county', '');
      setCounties(getSelectOptionsForState(countiesByState, state));
    },
    [setCounties, setFieldValue, countiesByState],
  );

  useEffect(() => {
    if (!loading && companyName === 'AHC' && values.state !== 'WA') {
      setValues({ ...values, state: 'WA' });
      setCounties(getSelectOptionsForState(countiesByState, 'WA'));
    }
  }, [companyName, countiesByState, values, setValues, loading]);

  return useMemo(() => ({ counties, onSelectState, error, loading }), [counties, error, loading, onSelectState]);
}
