import React, { FC, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Text } from '@endpoint/blockparty';
import { MilestoneStatus } from '@endpoint/platform-api-connector/dist/graphql-types';
import { AlertMessage } from 'components/AlertMessage';
import { TRANSACTION } from 'consts/routes';
import { TransactionSkeleton } from 'components/Skeleton';
import { setMarket } from 'utils/zendesk';
import { isAgent, isContactTypeAgentOrTC, useAppContext } from 'utils/context';
import { trackIdentity, formatAnalyticsIdentity, ExtendedUser } from 'utils/analytics';
import { getUserRoles } from 'utils/transactions/getUserRoles';
import { useIsSupportedOrderReportState } from 'hooks/useIsSupportedOrderReportState';
import { useClosedAndPrelistingTransactionIds } from 'hooks/useClosedAndPrelistingTransactionIds';
import { useTransactionPayload } from 'hooks/useTransactionPayload';
import { useGetMarkets } from 'hooks/useGetMarkets';
import useOrganizationId from 'hooks/useOrganizationId';

import { NoTransactions } from '../NoTransactions';
import { TransactionCard } from '../TransactionCard';
import { TransactionGrid } from '../TransactionGrid';

export const InEscrow: FC = () => {
  const organizationId = useOrganizationId();
  const {
    getClosedAndPrelistingTransactionIds,
    closedAndPrelistingTransactionIds,
    closedAndPrelistingTransactionIdsError,
    closedAndPrelistingTransactionIdsLoading,
  } = useClosedAndPrelistingTransactionIds();
  const { transactionPayloadData, transactionPayloadLoading, transactionPayloadError } = useTransactionPayload();

  const { markets } = useGetMarkets();

  const { user } = useAppContext();
  const navigate = useNavigate();
  const hasNoTransactions =
    transactionPayloadData?.myTransactions.length === 0 || transactionPayloadData?.myTransactions === null;
  const isSingleEscrowTransaction =
    transactionPayloadData?.myTransactions.length === 1 &&
    transactionPayloadData?.myTransactions[0].milestones[0] &&
    transactionPayloadData?.myTransactions[0].milestones[0].status !== MilestoneStatus.UPCOMING;
  const isOrderReportSupported = useIsSupportedOrderReportState(user);

  const [hasTrackedUserIdentity, setHasTrackedUserIdentity] = useState(false);

  useEffect(() => {
    if (!hasTrackedUserIdentity && user.id && transactionPayloadData && transactionPayloadData.myTransactions) {
      const transactions = transactionPayloadData;
      const userId = user.id!;
      const roles = transactions?.myTransactions
        .map((transaction) => getUserRoles(transaction.participants, userId).join(''))
        .filter((role) => !!role);
      const offices = transactions?.myTransactions
        .map((transaction) => transaction.office?.name)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      const analyticsIdentityData: ExtendedUser = {
        ...user,
        roles,
        rolesStr: roles.sort().join(''),
        offices,
      };

      trackIdentity(userId, organizationId, formatAnalyticsIdentity(analyticsIdentityData));

      setHasTrackedUserIdentity(true);
    }
  }, [transactionPayloadData, user, organizationId, hasTrackedUserIdentity]);

  const shouldRedirect = useMemo(
    () =>
      !isContactTypeAgentOrTC(user) &&
      isSingleEscrowTransaction &&
      closedAndPrelistingTransactionIds !== undefined &&
      (!closedAndPrelistingTransactionIds || !closedAndPrelistingTransactionIds.length) &&
      !closedAndPrelistingTransactionIdsError &&
      !closedAndPrelistingTransactionIdsLoading,
    [
      isSingleEscrowTransaction,
      closedAndPrelistingTransactionIds,
      closedAndPrelistingTransactionIdsError,
      closedAndPrelistingTransactionIdsLoading,
      user,
    ],
  );

  useEffect(() => {
    if (transactionPayloadData && markets && !!markets.length) {
      setMarket(transactionPayloadData.myTransactions, markets);
    }
  }, [transactionPayloadData, markets]);

  useEffect(() => {
    if (isSingleEscrowTransaction) {
      // Before redirection ensuring if the user doesn't have closed or listing transactions
      getClosedAndPrelistingTransactionIds();
    }
    // Verifying if the user has a single transaction in escrow
  }, [isSingleEscrowTransaction, getClosedAndPrelistingTransactionIds]);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(`/${TRANSACTION}/${transactionPayloadData?.myTransactions[0].id}/todos`);
    }
  }, [transactionPayloadData, navigate, shouldRedirect]);

  const isLoading =
    transactionPayloadLoading ||
    shouldRedirect ||
    closedAndPrelistingTransactionIdsLoading ||
    (closedAndPrelistingTransactionIds === undefined &&
      isSingleEscrowTransaction &&
      !closedAndPrelistingTransactionIdsError);

  if (isLoading) {
    return (
      <TransactionGrid>
        <TransactionSkeleton />
      </TransactionGrid>
    );
  }

  if (transactionPayloadError) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error in inEscrow page. user id: ${user.id}, error: ${transactionPayloadError.message}`,
    });

    return (
      <Box m="space50">
        <AlertMessage>Cannot load In Escrow transactions</AlertMessage>
      </Box>
    );
  }

  if (hasNoTransactions) {
    return (
      <NoTransactions>
        {isAgent(user) && (
          <Text as="p" color="carbon500" data-test-id="no-transactions-agent-text">
            To get started, click{' '}
            <Link color="blue500" to="/open-escrow">
              <Text color="blue500">Open Escrow</Text>
            </Link>{' '}
            {isOrderReportSupported && (
              <>
                or{' '}
                <Link color="blue500" data-test-id="order-report-quick-link" to="/order-reports">
                  <Text color="blue500">Order Reports</Text>
                </Link>{' '}
                to get started.
              </>
            )}
          </Text>
        )}
      </NoTransactions>
    );
  }

  return (
    <TransactionGrid>
      {transactionPayloadData?.myTransactions.map((transaction) => {
        const { milestones } = transaction;
        const nonPendingTransaction = milestones[0] && milestones[0].status !== MilestoneStatus.UPCOMING;
        const hasTransactionDetail = nonPendingTransaction && {
          as: Link,
          to: `/${TRANSACTION}/${transaction.id}/todos`,
        };

        return (
          <Box key={transaction.id} bg="background" textDecoration="none" width="100%" {...hasTransactionDetail}>
            <TransactionCard {...transaction} />
          </Box>
        );
      })}
    </TransactionGrid>
  );
};
