import React, { FC, useContext, useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { Box, Heading, Text, Flex, Button, Stack, useToast } from '@endpoint/blockparty';
import { Formik, Form, FormikProps } from 'formik';
import { identity, pickBy } from 'lodash';
import { FormikInputGroup, FormikSelectGroup } from 'components/form/FormikBlockparty';
import { Label } from 'components/DataBlock';
import {
  ContactClass,
  CreateUnityContactInput,
  StateOfOperation,
  UnityContactWhereUniqueInput,
  UpdateUnityContactInput,
} from '@endpoint/endpoint-bff-graphql-schema';
import { AppContext, AppContextType, CompanyContextType, useCompanyContext } from 'utils/context';
import Toast from 'Auth/Components/Toast';
import { useGetMarkets } from 'hooks/useGetMarkets';
import { Auth } from 'aws-amplify';
import { isSsoUser } from 'utils/singleSignOn';
import { isSupportedSignUpState } from 'utils/isSupportedSignUpState';
import { useUnityContact } from 'hooks/useUnityContact';
import { SSO_USER_INFO_KEY, updateCognitoPoolUserAttribute } from 'utils/auth/storage';
import { useGetUnityContact } from 'hooks/useGetUnityContact';
import { isNewLoginSystemEnabled } from 'Auth/Utilities/helper';
import { localStorageGetItem } from 'utils/localStorageAccessor';
import { trackAction, formatAnalyticsUnityProfileIdentity, trackIdentityPreCreation } from 'utils/analytics';
import { SignUpTrackingEvents, TRACK_SET_PROFILE_PAYLOAD } from 'consts/analytics';

import { US_STATES, NOTIFICATION_PREFERENCE_URL, OUT_OF_COVERAGE_URL, UnityRole } from '../../Utilities/Constant';
import { IUnityIndividualProfileFormValues, IUnityAgentTcProfileFormValues } from '../../types.d';
import UnityProfileValidationSchema from './SetUpUnityProfileValidationSchema';
import { SET_PROFILE_CONTINUE_BUTTON_TEXT } from '../../../consts/descriptions';

interface SsoUserName {
  firstName?: string;
  lastName?: string;
}

const SetUpUnityProfile: FC = () => {
  const { companyName }: CompanyContextType = useCompanyContext();
  const formikRef = useRef<FormikProps<IUnityIndividualProfileFormValues | IUnityAgentTcProfileFormValues>>(null);
  const isAHC = companyName === 'AHC';
  const toast = useToast();
  const { user, setUser }: AppContextType = useContext(AppContext);
  const {
    createUnityContact,
    createUnityContactLoading,
    createUnityContactResult,
    createUnityContactError,
    updateUnityContact,
    updateUnityContactLoading,
    updateUnityContactResult,
    updateUnityContactError,
  } = useUnityContact();
  const { getUnityContact } = useGetUnityContact();
  const [ssoUserInfo, setSsoUserInfo] = useState<SsoUserName | null>(null);
  const [signUpState, setSignUpState] = useState<StateOfOperation | undefined>(undefined);
  const isUnityContactLoading = Boolean(createUnityContactLoading || updateUnityContactLoading);
  const unityContactError = createUnityContactError ?? updateUnityContactError;
  const unityContactResult = createUnityContactResult ?? updateUnityContactResult;

  const isAgent = user.unityRole === UnityRole.AGENT;

  const { markets, loading: marketsLoading, error: marketsError } = useGetMarkets({ isActive: true });

  const isUserInfoAvailable = ssoUserInfo || user?.id;

  useEffect(() => {
    if (!marketsLoading && !!marketsError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={marketsError.message} onClose={onClose} />,
      });
    }
  }, [marketsLoading, marketsError, toast]);

  useEffect(() => {
    async function getCognitoUser() {
      const currentUser = await Auth.currentAuthenticatedUser();

      if (isSsoUser(currentUser))
        setSsoUserInfo({ firstName: currentUser.attributes.given_name, lastName: currentUser.attributes.family_name });
    }

    if (!isNewLoginSystemEnabled()) {
      void getCognitoUser();
    } else if (localStorageGetItem(SSO_USER_INFO_KEY)) {
      const localStorageSSOUserInfo = JSON.parse(localStorageGetItem(SSO_USER_INFO_KEY) ?? '{}');

      setSsoUserInfo({ firstName: localStorageSSOUserInfo.firstName, lastName: localStorageSSOUserInfo.lastName });
    }
  }, []);

  const handleSubmitUnityProfile = async (
    values: IUnityIndividualProfileFormValues | IUnityAgentTcProfileFormValues,
  ) => {
    trackAction(SignUpTrackingEvents.SET_PROFILE_BUTTON_SUBMITTED, TRACK_SET_PROFILE_PAYLOAD);
    const { firstName, lastName, middleName, suffix } = values;

    let unityContactInput: CreateUnityContactInput | UpdateUnityContactInput = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      middleName: middleName?.trim(),
      suffix: suffix?.trim(),
    };

    if (isAgent) {
      const licenseNumber = (values as IUnityAgentTcProfileFormValues).licenseNumber.trim();
      const stateOfOperation = (values as IUnityAgentTcProfileFormValues).stateOfOperation;

      setSignUpState(stateOfOperation);

      unityContactInput = {
        ...unityContactInput,
        licenses: [
          {
            licenseNumber,
            stateOfOperation,
          },
        ],
      };
    }

    const cleanUpdateUnityContactInput = pickBy(unityContactInput, identity) as UpdateUnityContactInput;

    const cleanCreateUnityContactInput = {
      ...cleanUpdateUnityContactInput,
      contactClass: ContactClass.INDIVIDUAL,
    } as CreateUnityContactInput;

    if (user.id) {
      const { error: getUnityContactError, data: getUnityContactData } = await getUnityContact();

      if (!getUnityContactData && getUnityContactError) {
        await createUnityContact(cleanCreateUnityContactInput).then(() => {
          trackIdentityPreCreation(formatAnalyticsUnityProfileIdentity(cleanCreateUnityContactInput));
        });
      }

      if (getUnityContactData && !getUnityContactError) {
        const unityContactWhereUniqueInput: UnityContactWhereUniqueInput = {
          id: user.id,
        };

        if (!isNewLoginSystemEnabled()) {
          await updateCognitoPoolUserAttribute(user.id);
        }

        await updateUnityContact(unityContactWhereUniqueInput, cleanUpdateUnityContactInput).then(() => {
          trackIdentityPreCreation(formatAnalyticsUnityProfileIdentity(cleanUpdateUnityContactInput));
        });
      }
    } else {
      await createUnityContact(cleanCreateUnityContactInput).then(() => {
        trackIdentityPreCreation(formatAnalyticsUnityProfileIdentity(cleanCreateUnityContactInput));
      });
    }
  };

  const getSupportedSignupState = (chosenStateOfOperation?: StateOfOperation): StateOfOperation | undefined => {
    if (!!markets?.length && isSupportedSignUpState(markets, chosenStateOfOperation)) return chosenStateOfOperation;

    return undefined;
  };

  useEffect(() => {
    if (!isUnityContactLoading && unityContactResult) {
      const onboardingUrl =
        !isAgent || getSupportedSignupState(signUpState) ? NOTIFICATION_PREFERENCE_URL : OUT_OF_COVERAGE_URL;
      const userData =
        'createUnityContact' in unityContactResult
          ? unityContactResult.createUnityContact
          : unityContactResult.updateUnityContact;

      setUser({ ...user, ...userData, onboardingUrl, roles: [user.unityRole] });
    }

    if (!isUnityContactLoading && unityContactError) {
      toast({
        duration: 4000,
        position: 'top-right',
        render: ({ onClose, id }) => <Toast id={id} message={unityContactError.message} onClose={onClose} />,
      });

      Sentry.captureEvent({
        level: Sentry.Severity.Error,
        message: `createUnityContact mutation error -- Set up profile screen / Onboarding. Contact id: ${
          user.id
        }. Error: ${JSON.stringify(unityContactError)}}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnityContactLoading, unityContactResult, unityContactError]);

  useEffect(() => {
    if (ssoUserInfo || user?.id) {
      formikRef?.current?.setValues({
        firstName: ssoUserInfo?.firstName || user?.firstName || '',
        lastName: ssoUserInfo?.lastName || user?.lastName || '',
        middleName: user?.middleName || '',
      });
    }
  }, [formikRef?.current?.setValues, ssoUserInfo, user]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: ssoUserInfo?.firstName || '',
        lastName: ssoUserInfo?.lastName || '',
        stateOfOperation: isAHC ? 'WA' : undefined,
      }}
      innerRef={formikRef}
      validateOnBlur
      validationSchema={UnityProfileValidationSchema[user.unityRole as UnityRole]}
      onSubmit={handleSubmitUnityProfile}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Box mb="space70" textAlign="left">
            <Heading as="h2" mb="space50" size={{ base: 'fontSize70', md: 'fontSize60' }}>
              Set up your profile
            </Heading>
            <Text>The following information will be used for your official documents.</Text>
          </Box>
          <Stack mb="space80" spacing="space60">
            <FormikInputGroup
              autoFocus
              data-test-id="firstName"
              display={isUserInfoAvailable ? 'none' : 'block'}
              isOptional={!!isUserInfoAvailable}
              label="First Name"
              name="firstName"
              placeholder="e.g. John"
            />
            <FormikInputGroup
              data-test-id="middleName"
              display={isUserInfoAvailable ? 'none' : 'block'}
              isOptional
              label="Middle Name"
              name="middleName"
              placeholder="e.g. William"
            />
            <FormikInputGroup
              data-test-id="lastName"
              display={isUserInfoAvailable ? 'none' : 'block'}
              isOptional={!!isUserInfoAvailable}
              label="Last Name"
              name="lastName"
              placeholder="e.g. Doe"
            />
            {isUserInfoAvailable && (
              <>
                <Box bg="carbon0" borderRadius="radiusDefault" data-test-id="fullName" mt="space0" p="space50">
                  <Label>Full Name</Label>
                  <Text>
                    {ssoUserInfo?.firstName || user?.firstName} {user?.middleName}{' '}
                    {ssoUserInfo?.lastName || user?.lastName}
                  </Text>
                </Box>
              </>
            )}
            <FormikInputGroup
              data-test-id="suffix"
              display={ssoUserInfo ? 'none' : 'block'}
              isOptional
              label="Suffix"
              name="suffix"
              placeholder="e.g. Jr."
            />
            {isAgent && (
              <>
                <FormikInputGroup
                  data-test-id="licenseNumber"
                  label="Real Estate License Number"
                  name="licenseNumber"
                  placeholder="e.g. 87654321"
                />
                <FormikSelectGroup
                  data-test-id="stateOfOperation"
                  defaultValue={isAHC ? { value: 'WA', label: 'WA' } : undefined}
                  disabled={isAHC}
                  isClearable={false}
                  isSearchable
                  label="Operating State"
                  name="stateOfOperation"
                  options={US_STATES}
                  placeholder="Select"
                />
              </>
            )}
          </Stack>
          <Flex
            backgroundColor="white"
            bottom={{ base: 0, md: 'initial' }}
            boxShadow={{ base: 'medium', md: 'none' }}
            justifyContent="flex-end"
            left={{ base: 0, md: 'initial' }}
            position={{ base: 'fixed', md: 'initial' }}
            px={{ base: 'space50', md: 'space0' }}
            py={{ base: 'space30', md: 'space0' }}
            right={{ base: 0, md: 'initial' }}
            zIndex="overlay"
          >
            <Button isDisabled={!(isValid && dirty)} isLoading={isUnityContactLoading} type="submit">
              {SET_PROFILE_CONTINUE_BUTTON_TEXT}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SetUpUnityProfile;
