import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';

export const getFieldSetWithNestedIds = (field: FieldOptions, index: number) => {
  if (!field.children) {
    return [];
  }

  return field.children.map((child) => {
    if (!child.field) {
      return child;
    }

    const nestedId = `${field.id}[${index}]${child.field.id}`;

    return { ...child, field: { ...child.field, id: nestedId } };
  });
};
