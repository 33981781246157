import React, { FC } from 'react';
import { Box, Text, Icon } from '@endpoint/blockparty';
import { isMeetingStrongPasswordCriteria } from 'Auth/Utilities/helper';
import { StrongPasswordType } from 'Auth/Utilities/Constant';

export interface IPasswordCriteria {
  type: StrongPasswordType;
  message: string;
}

interface IPasswordCriteriaProps {
  password: string;
}

const passwordCriteria = [
  {
    type: StrongPasswordType.Miminum,
    message: '12 characters minimum',
  },
  {
    type: StrongPasswordType.UpperCase,
    message: 'One uppercase letter',
  },
  {
    type: StrongPasswordType.LowerCase,
    message: 'One lowercase letter',
  },
  {
    type: StrongPasswordType.Number,
    message: 'One number',
  },
  {
    type: StrongPasswordType.SpecialCharacter,
    message: 'One special character',
  },
];

export const PasswordCriteria: FC<IPasswordCriteriaProps> = ({ password }: IPasswordCriteriaProps) => {
  return (
    <>
      {isMeetingStrongPasswordCriteria(StrongPasswordType.All, password) ? (
        <Box>
          <Icon color="turquoise500" mr="space30" name="CheckCircle" />
          <Text color="carbon900">Nice! Your password is secure</Text>
        </Box>
      ) : (
        passwordCriteria.map((criteria) => (
          <Box key={criteria.type}>
            <Icon
              color={isMeetingStrongPasswordCriteria(criteria.type, password) ? 'turquoise500' : 'carbon300'}
              mr="space30"
              name="CheckCircle"
            />
            <Text color={isMeetingStrongPasswordCriteria(criteria.type, password) ? 'carbon900' : 'carbon500'}>
              {criteria.message}
            </Text>
          </Box>
        ))
      )}
    </>
  );
};
