import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@endpoint/blockparty';
import { TodoAssignmentButton } from '@endpoint/endpoint-bff-graphql-schema';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TodoTrackingEvents } from 'consts/analytics';
import { useTodoAnalytics } from 'hooks/useTodoAnalytics';
import { useModal } from 'hooks/useModal';
import { trackAction } from 'utils/analytics';
import { QueryParam, TodoStepId } from 'consts/enums';

import { NavigationPanel } from '..';

interface TodoStepNavigationPanelProps {
  navigationPanel: TodoAssignmentButton[];
  validationSchema: Yup.ObjectSchema<GenericObject>;
  currentStepId?: string;
  onCancelEdit: () => void;
  onPrevious: (values: GenericObject) => void;
}

export const TodoStepNavigationPanel = ({
  navigationPanel,
  validationSchema,
  currentStepId,
  onCancelEdit,
  onPrevious,
}: TodoStepNavigationPanelProps) => {
  const formikContext = useFormikContext<GenericObject>();
  const [isNextDisabled, setIsNextDisabled] = useState(!!validationSchema);
  const { todoTrackingPayload } = useTodoAnalytics();
  const [searchParams] = useSearchParams();
  const isEditStep = searchParams.get(QueryParam.EDIT_STEP);
  const { Modal, openModal } = useModal({
    textPrompt: 'Cancel unsaved changes?',
    secondaryTextPrompt: 'Your edited answers will not be applied.',
    onConfirm: onCancelEdit,
    cancelButtonText: 'Continue Editing',
    confirmButtonText: 'Yes, cancel',
  });

  useEffect(() => {
    if (validationSchema) {
      validationSchema
        .validate(formikContext.values)
        .then(() => {
          setIsNextDisabled(false);
        })
        .catch(() => {
          setIsNextDisabled(true);
        });
    }
  }, [formikContext, validationSchema]);

  if (navigationPanel.length !== 2) {
    return <></>;
  }

  const [previousButton, nextButton] = navigationPanel;
  const showIcon = previousButton.text === 'Back' && !isEditStep;

  const handleNextButtonClick = () => {
    if (currentStepId === TodoStepId.ABOUT) {
      trackAction(TodoTrackingEvents.TODO_STARTED, todoTrackingPayload);

      return;
    }

    if (nextButton.completesTodo) {
      trackAction(TodoTrackingEvents.TODO_SUBMITTED, todoTrackingPayload);

      return;
    }

    trackAction(TodoTrackingEvents.TODO_NEXT_STEP, todoTrackingPayload);
  };

  const handlePreviousButtonClick = () => {
    trackAction(TodoTrackingEvents.TODO_PREVIOUS_STEP, todoTrackingPayload);
    onPrevious(formikContext.values);
  };

  const handleCancelEditButtonClick = () => {
    void openModal();
  };

  return (
    <>
      {Modal}
      <NavigationPanel>
        {previousButton.isDisplayed && (
          <Button
            dataTestId="previous-button"
            iconLeft={showIcon ? 'ArrowChevronLeft' : undefined}
            isDisabled={!previousButton.isEnabled}
            variant="link"
            onClick={isEditStep ? handleCancelEditButtonClick : handlePreviousButtonClick}
          >
            {isEditStep ? 'Cancel' : previousButton.text}
          </Button>
        )}
        {nextButton.isDisplayed && (
          <Button
            dataTestId={`todo-${nextButton.text.toLowerCase().replace(/\s/g, '-')}-button`}
            iconRight={nextButton.text === 'Next' ? 'ArrowChevronRight' : undefined}
            isDisabled={isNextDisabled}
            ml="space50"
            type="submit"
            onClick={handleNextButtonClick}
          >
            {nextButton.text}
          </Button>
        )}
      </NavigationPanel>
    </>
  );
};
