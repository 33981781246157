import React, { FC, useEffect } from 'react';
import { Heading, Box, RadioGroup } from '@endpoint/blockparty';
import { CurrentStepComponentProps } from 'components/form/TwoColumnWithProgressContainer';
import { NavigationButton } from 'components/form/NavigationButton';
import { Field, useFormikContext } from 'formik';
import * as yup from 'yup';
import { FormikRadio } from 'components/form/FormikBlockparty';

import { FormikValue } from '../..';

export const Step3: FC<CurrentStepComponentProps<FormikValue>> = ({
  currentStepPosition = 3,
  setCurrentStepPosition,
  formikValue,
}) => {
  const { values, isValid, validateForm } = useFormikContext<FormikValue>();

  useEffect(() => {
    const resolveValidateForm: () => void = async () => {
      await validateForm();
    };

    resolveValidateForm();
  }, [validateForm]);

  return (
    <>
      <Heading as="h1" mb="space50" size="fontSize50">
        Are you representing buyer or seller in this transaction?
      </Heading>
      <Box mb="space60">
        <RadioGroup
          aria-label="radio-group-order-method"
          defaultValue={formikValue.representing}
          name="representing"
          role="group"
          spacing="space50"
        >
          <Field component={FormikRadio} id="Seller" name="representing" value="seller">
            Seller
          </Field>
          <Field component={FormikRadio} id="Buyer" name="representing" value="buyer">
            Buyer
          </Field>
        </RadioGroup>
      </Box>
      <NavigationButton
        goNext={() => setCurrentStepPosition(currentStepPosition + 1)}
        goPrevious={() => setCurrentStepPosition(currentStepPosition - 1)}
        iconRight="ArrowChevronRight"
        isDisabled={!values.representing || !isValid}
      >
        Next
      </NavigationButton>
    </>
  );
};

export const step3ValidationSchema = yup
  .object()
  .shape({ representing: yup.string().required('Please choose who are you representing') });
