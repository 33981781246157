import { addDays, isToday, startOfToday } from 'date-fns';

import { getLater, getEarlier, isPastWorkingHours } from './util';

export function adjustMinDateByCurrentTime(date: Date, endDateBoundary?: Date): Date {
  const adjustedDate = isToday(date) && isPastWorkingHours() ? addDays(date, 1) : date;

  return getEarlier(adjustedDate, endDateBoundary);
}

export function getMinStartDate(startDate?: Date, endDateBoundary?: Date): Date {
  const minDate = getEarlier(getLater(startDate, startOfToday()), endDateBoundary);

  return adjustMinDateByCurrentTime(minDate, endDateBoundary);
}
