export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const STEP1_TITLE = 'Open escrow options';
export const STEP2_TITLE = 'Upload Purchase Agreements';
export const STEP3_TITLE = 'Who are you representing';
export const STEP4_TITLE = 'Add transaction party information';
export const STEP5_TITLE = 'Add teammates';
export const STEP6_TITLE = 'Optional notes';
export const REVIEW_STEP_TITLE = 'Review';
export const THANK_YOU_STEP_TITLE = 'Order submitted';
