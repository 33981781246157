import { gql } from '@apollo/client';
import { SaveTodoAssignmentStepInput, SaveTodoAssignmentStepPayload } from '@endpoint/endpoint-bff-graphql-schema';

export interface OperationVariables {
  input: SaveTodoAssignmentStepInput;
}

export interface SaveTodoAssignmentStepResponse {
  saveTodoAssignmentStep: SaveTodoAssignmentStepPayload;
}

export const SAVE_TODO_ASSIGNMENT_STEP_MUTATION = gql`
  mutation saveTodoAssignmentStep($input: SaveTodoAssignmentStepInput!) {
    saveTodoAssignmentStep(input: $input) {
      updatedStepId
    }
  }
`;
