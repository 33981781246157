import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { useTransaction } from 'hooks/useTransaction';
import { useWirePayableDetails } from 'hooks/useWirePayableDetails';
import { GET_TRANSACTION_OFFICE, OfficePayload } from 'hooks/useTransactionOffice';
import { ProcessVariation } from 'consts/enums';

export const useWireInstructions = (isInternationalWire?: boolean) => {
  const { transactionId = '' } = useParams();
  const transaction = useTransaction(transactionId);
  const transactionOffice = useQuery<OfficePayload>(GET_TRANSACTION_OFFICE, {
    variables: { where: { id: transactionId } },
  });
  const {
    fileNum = '',
    participants = [],
    property,
    marketId,
    emd,
    processVariation,
  } = transaction.data?.transaction ?? {};
  const isUnityTransaction = processVariation === ProcessVariation.UNITY;
  const emdAmount = emd?.amount;

  const office = transactionOffice.data?.transaction?.office;
  const { accountNumber, routingNumber, swiftCode } = office?.wireTransferBankAccount ?? {};
  const { bankAddress, payableName, creditBeneficiary, paymentInstructionsLoading, paymentInstructionsError } =
    useWirePayableDetails({
      isInternationalWire: Boolean(isInternationalWire),
      isUnityTransaction,
      marketId: marketId as number,
      transactionId,
    });

  return {
    bank: bankAddress,
    creditBeneficiary,
    accountNumber: accountNumber?.valueOf() ?? '',
    routingNumber: routingNumber?.valueOf() ?? '',
    swiftCode: swiftCode?.valueOf() ?? '',
    transactionDetails: { fileNum, participants, property, emdAmount },
    isLoading: transaction.loading || transactionOffice.loading || paymentInstructionsLoading,
    error: transaction.error || transactionOffice.error || paymentInstructionsError,
    payableName,
  };
};
