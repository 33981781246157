import { gql } from '@apollo/client';
import { ListTransactionsResponse, TransactionStatus } from '@endpoint/endpoint-bff-graphql-schema';

export interface UseListTransactionsProps {
  page: number;
  status: TransactionStatus;
  skipQuery?: boolean;
}

export interface ListTransactionsPayload {
  listTransactions: ListTransactionsResponse;
}

export const LIST_TRANSACTIONS = gql`
  query listTransactions($where: ListTransactionsWhereUniqueInput!) {
    listTransactions(where: $where) {
      results {
        fileNumber
        id
        loanAmount
        marketId
        prelimPending
        price
        property {
          address {
            street1
            street2
            city
            state
            zip
          }
          propertyImage
        }
        signingDate
        status
        type
        fileNum
        officeName
        todoAssignmentCount
        milestones {
          id
          name
          status
        }
        participants {
          id
          contactId
          firstName
          lastName
          roles
        }
        dates {
          cancellationDate
          estimatedClosing
          prelistingOpened
          titleProductDelivered
        }
      }
      itemsPerPage
      pageIndex
      totalItems
      totalPages
    }
  }
`;
