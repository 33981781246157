// * REVISIT
type Breakpoints<T> = Array<T> & {
  sm: '414px';
  md: '768px';
  lg: '1024px';
  xl: '1440px';
};
/*= =========================================================================== */
/* BlockParty Design System */

/**
 *
 * Colors
 * Typography
 * Layout and Spacing
 * Extra
 */

/*= =========================================================================== */
/* Color System */
// All 5th-index values are primary colors

const baseColors = {
  carbon0: '#f7f7f7',
  carbon100: '#E0E0E0',
  carbon200: '#C9C9C9',
  carbon300: '#adadad',
  carbon400: '#8F8F8F',
  carbon500: '#757575',
  carbon600: '#5e5e5e',
  carbon700: '#474747',
  carbon800: '#333333',
  carbon900: '#222222',
  slate0: '#F5F5F7',
  slate100: '#e4e6f0',
  slate200: '#CED2E0',
  slate300: '#9DA2B3',
  slate400: '#696f82',
  slate500: '#383E54',
  slate600: '#262c42',
  slate700: '#191f36',
  slate800: '#121930',
  slate900: '#0d142b',
  mist0: '#edf3f7',
  mist100: '#D8E2EB',
  mist200: '#C1CFDB',
  mist300: '#A7BAC9',
  mist400: '#97AABA',
  mist500: '#8194A3',
  mist600: '#6C7E8C',
  mist700: '#556470',
  mist800: '#404b54',
  mist900: '#2D353B',
  blue0: '#e3f6fc',
  blue100: '#B3E3F5',
  blue200: '#83D5F2',
  blue300: '#47B6DE',
  blue400: '#1794c2',
  blue500: '#087ea8',
  blue600: '#07698c',
  blue700: '#095975',
  blue800: '#0C4E66',
  blue900: '#0f4559',
  turquoise0: '#e5fdff',
  turquoise100: '#aff5fa',
  turquoise200: '#74eaf2',
  turquoise300: '#3dd5e0',
  turquoise400: '#10bdc9',
  turquoise500: '#009fab',
  turquoise600: '#037a82',
  turquoise700: '#09656b',
  turquoise800: '#0e575c',
  turquoise900: '#114d52',
  aloe0: '#ebfae1',
  aloe100: '#D3F7B7',
  aloe200: '#AFF07D',
  aloe300: '#85E03F',
  aloe400: '#60C712',
  aloe500: '#4AA603',
  aloe600: '#377a04',
  aloe700: '#2e6108',
  aloe800: '#2a520b',
  aloe900: '#294711',
  marigold0: '#fff6e5',
  marigold100: '#ffecc7',
  marigold200: '#fcdea2',
  marigold300: '#fad282',
  marigold400: '#f7c45e',
  marigold500: '#f2b63d',
  marigold600: '#d99611',
  marigold700: '#a67003',
  marigold800: '#855b07',
  marigold900: '#704f0b',
  clay0: '#fff6e9',
  clay100: '#fcecd4',
  clay200: '#fce3c5',
  clay300: '#fad1a2',
  clay400: '#f0ba8b',
  clay500: '#d6966b',
  clay600: '#ba7c52',
  clay700: '#915e3c',
  clay800: '#6b402e',
  clay900: '#59342c',
  watermelon0: '#fae1e3',
  watermelon100: '#fac5cb',
  watermelon200: '#faa0a9',
  watermelon300: '#f57884',
  watermelon400: '#f05666',
  watermelon500: '#dd3446',
  watermelon600: '#b81626',
  watermelon700: '#870e1a',
  watermelon800: '#631018',
  watermelon900: '#52171d',
  brandPrimary0: '#e3f6fc',
  brandPrimary100: '#B3E3F5',
  brandPrimary200: '#83D5F2',
  brandPrimary300: '#47B6DE',
  brandPrimary400: '#1794c2',
  brandPrimary500: '#087ea8',
  brandPrimary600: '#07698c',
  brandPrimary700: '#095975',
  brandPrimary800: '#0C4E66',
  brandPrimary900: '#0f4559',
};

/*= =========================================================================== */
/* Typography System */

const typography = {
  fonts: {
    normal: 'Inter, sans-serif',
    heading: 'Inter-SemiBold, sans-serif',
    semi: 'Inter-SemiBold, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: {
    fontSize0: '10px',
    fontSize10: '12px',
    fontSize20: '14px',
    fontSize30: '16px',
    fontSize40: '18px',
    fontSize50: '20px',
    fontSize60: '24px',
    fontSize70: '30px',
    fontSize80: '38px',
    fontSize90: '46px',
  },
  lineHeights: {
    lineHeight0: '18px',
    lineHeight10: '20px',
    lineHeight20: '22px',
    lineHeight30: '24px',
    lineHeight40: '26px',
    lineHeight50: '28px',
    lineHeight60: '32px',
    lineHeight70: '38px',
    lineHeight80: '46px',
    lineHeight90: '54px',
  },
  fontWeights: {
    normal: 400,
    heading: 600,
    semi: 600,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
};

/*= =========================================================================== */
/* Layout and Spacing */

const layout = {
  space: {
    space0: '0px',
    space10: '2px',
    space20: '4px',
    space30: '8px',
    space40: '12px',
    space50: '16px',
    space60: '24px',
    space70: '32px',
    space80: '64px',
    space90: '128px',
  },
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256], // double check if in use
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536], // double check if in use
};

/*= =========================================================================== */
/* Responsive Breakpoints */
const breakpoints = ['414px', '768px', '1024px', '1440px'] as Breakpoints<any>;

// Alias
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const breakpointLiteral = {
  sm: 414,
  md: 768,
  lg: 1024,
  xl: 1440,
};

// Used in useMediaQuery hook
const mediaQueries = {
  sm: '(min-width: 414px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1440px)',
  phoneMaxWidth: '(max-width: 414px)',
  tabletMaxWidth: '(max-width: 768px)',
  smallDesktopMaxWidth: '(max-width: 1024px)',
  largeDesktopMaxWidth: '(max-width: 1440px)',
};

/*= =========================================================================== */
/* Border */
const border = {
  borderStyles: {
    dotted: 'dotted',
    solid: 'solid',
  },
  borderWidths: {
    small: 2,
    medium: 4,
    large: 6,
  },
  radii: {
    radiusNone: '0px',
    radiusDefault: '4px',
    radiusFull: '50%',
  },
};

/*= =========================================================================== */
/* Main Theme */
const white = '#fff';

export const theme = {
  colors: {
    ...baseColors,
    initialColorMode: 'light',
    white, // fallback name
    background: white,
    black: baseColors.carbon900,
    text: baseColors.carbon900,
    divider: baseColors.carbon100,
    disabled: baseColors.carbon300,
    passive: baseColors.carbon500,
    contentBackground: baseColors.slate0,
    itemHovered: baseColors.slate0,
    itemSelected: baseColors.blue0,
    focusRing: baseColors.blue0,
    // carbon400 is used in Table Headers
    overlay: 'rgba(56, 62, 84, .5)',
    /* Component-specific colors */
    dividers: {
      lightPrimary: baseColors.carbon100,
      lightSecondary: baseColors.carbon300,
      darkPrimary: baseColors.carbon900,
      darkSecondary: baseColors.carbon600,
    },
    inputActive: baseColors.blue500,
    inputActiveFocus: baseColors.blue200,
    inputAlert: baseColors.watermelon500,
    inputAlertDisabled: baseColors.watermelon100,
    inputDefault: baseColors.carbon300,
    inputDisabled: baseColors.carbon200,
    inputEntered: baseColors.carbon300, // looks unused
    inputFocus: baseColors.carbon400, // looks unused
    inputHover: baseColors.carbon400,
    inputPlaceholder: baseColors.carbon500,
    selectors: {
      default: {
        offDefault: baseColors.carbon300,
        offHover: baseColors.carbon400,
        offFocus: baseColors.carbon400,
        offError: baseColors.watermelon400,
        offDisabled: baseColors.carbon200,
        onDefault: baseColors.blue500,
        onHover: baseColors.blue400,
        onFocus: baseColors.blue400,
        onError: baseColors.watermelon500,
        onDisabled: baseColors.blue100,
      },
      yellow: {
        offDefault: baseColors.marigold300,
        offHover: baseColors.marigold400,
        offFocus: baseColors.marigold400,
        offError: baseColors.watermelon400,
        offDisabled: baseColors.marigold200,
        onDefault: baseColors.marigold500,
        onHover: baseColors.marigold400,
        onFocus: baseColors.marigold400,
        onError: baseColors.watermelon500,
        onDisabled: baseColors.marigold100,
      },
      blue: {
        offDefault: baseColors.blue300,
        offHover: baseColors.blue400,
        offFocus: baseColors.blue400,
        offError: baseColors.watermelon400,
        offDisabled: baseColors.blue200,
        onDefault: baseColors.blue500,
        onHover: baseColors.blue400,
        onFocus: baseColors.blue400,
        onError: baseColors.watermelon500,
        onDisabled: baseColors.blue100,
      },
      carbon: {
        offDefault: baseColors.carbon300,
        offHover: baseColors.carbon400,
        offFocus: baseColors.carbon400,
        offError: baseColors.watermelon400,
        offDisabled: baseColors.carbon200,
        onDefault: baseColors.carbon500,
        onHover: baseColors.carbon400,
        onFocus: baseColors.carbon400,
        onError: baseColors.watermelon500,
        onDisabled: baseColors.carbon100,
      },
    },
    navbarLink: white,
    navbarUnderline: baseColors.blue500,
    progressBarSignUp: white,
    shellBg: baseColors.slate0,
    shellBgPanel: white,
    shellBgToolbar: white,
    shellBgHeader: baseColors.slate500,
    shellBorder: baseColors.carbon100,
    // Tags - Transaction Status (Opsware)
    tagPrelisting: baseColors.slate500,
    tagOpen: baseColors.blue500,
    tagPostClosing: baseColors.turquoise500,
    tagClosed: baseColors.mist500,
    tagCancelled: baseColors.carbon500,
    tagOpenInError: baseColors.carbon100,
    tagDismissable: baseColors.carbon800,
    // Tags - To-Do Status (Opsware)
    tagDelivered: baseColors.slate500,
    tagStarted: baseColors.mist700,
    tagSubmitted: baseColors.aloe500,
    tagHold: baseColors.watermelon500,
    tagRecalled: baseColors.mist500,
    tagNewOrder: baseColors.carbon500,
    // Tags - To-Do Milestone Status (Endpoint)
    tagComplete: baseColors.turquoise500,
    tagOrderProcessing: baseColors.slate500,
    tagInProgress: baseColors.turquoise500,
    tagUpcoming: baseColors.carbon500,
    textPrimary: baseColors.carbon900,
    textSecondary: baseColors.carbon500,
    textAlert: baseColors.watermelon500,
    textLink: baseColors.blue500,
  },
  zIndices: {
    hidden: 10,
    underlay: 5,
    trigger: 20,
    dropdown: 100,
    sticky: 110,
    banner: 120,
    overlay: 130,
    modal: 140,
    popover: 150,
    tooltip: 160,
    warning: 200,
  },
  shadows: {
    small: '0px 3px 6px rgba(0, 0, 0, 0.15)',
    medium: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    large: '0px 3px 14px rgba(0, 0, 0, 0.15)',
    focus: `0 0 0 3px ${baseColors.blue200}`,
    focusInset: `0 0 0 3px ${baseColors.blue200} inset`,
    error: `0 0 0 3px ${baseColors.watermelon200}`,
  },
  transitions: {
    fast: 'all ease-in-out .15s',
    normal: 'all ease-in-out .2s',
  },
  breakpoints,
  breakpointLiteral,
  mediaQueries,
  ...border,
  ...typography,
  ...layout,
  config: {
    FAVICON_ICO: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/favicon.ico',
    FAVICON_16: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/favicon-16x16.png',
    FAVICON_32: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/favicon-32x32.png',
    FAVICON_48: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/favicon-48x48.png',
    FAVICON_180: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/favicon-180x180.png',
    FAVICON_192: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/favicon-192x192.png',
    LOGO_DARK: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/EP-logo-dark.png',
    LOGO_DARK_FULL: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/EP-logo-dark-full.png',
    LOGO_LIGHT: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/EP-logo-light.png',
    LOGO_LIGHT_FULL:
      'https://apex-static-assets.s3.us-west-2.amazonaws.com/Themes/EndpointTheme/EP-logo-light-full.png',
    HEADSET_IMAGE: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Headset.png',
    /* ======================================= */
    // TODO - The following assets provide types for Jot. We'll need to extract types based on provided theme
    LOGO_DARK_BG_FULL: '',
    LOGO_LIGHT_BG_FULL: '',
    LOGO_MONO_BLUE_FULL: '',
    /* ======================================= */
    OUT_OF_AREA_IMAGE: 'https://apex-static-assets.s3.us-west-2.amazonaws.com/Illustration_Space_EP.png',
    organization: 'Endpoint',
  },
};
