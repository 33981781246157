import { gql } from '@apollo/client';
import { TeamContactInfo } from '@endpoint/endpoint-bff-graphql-schema';

export interface TeamContactInfoPayload {
  getTeamContactInfo: TeamContactInfo;
}

export const GET_TEAM_CONTACT_INFO = gql`
  query getTeamContactInfo($input: GetTeamContactInfoInput!) {
    getTeamContactInfo(input: $input) {
      street
      city
      state
      zip
      phone
      email
      officeHours
      marketContactOfficeName
    }
  }
`;
