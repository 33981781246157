import { useState, useEffect } from 'react';
import { DEFAULT_ORGANIZATION_ID, getConfig } from 'utils/config';
import { getOrganizationId } from 'utils/getOrganizationData';
import { getFlagValue } from 'utils/getFlagValue';

const { ORGANIZATION_ID } = getConfig();

let isAppConfigsFromOrgService = false;

(async (): Promise<void> => {
  try {
    isAppConfigsFromOrgService = await getFlagValue('isAppConfigsFromOrgService', false, {
      kind: 'boolean',
      key: 'isAppConfigsFromOrgService',
    });
  } catch (error) {
    console.error('Error getting flag value for isAppConfigsFromOrgService:', error);
  }
})();

// Returns organizationId from the organization-service's database of domains, otherwise
// returns the default organizationId from the config.

const useOrganizationId = () => {
  const [organizationId, setOrganizationId] = useState<number>(
    isAppConfigsFromOrgService ? DEFAULT_ORGANIZATION_ID : ORGANIZATION_ID,
  );

  useEffect(() => {
    const fetchOrganizationId = async () => {
      const orgId = await getOrganizationId();

      setOrganizationId(orgId);
    };

    void fetchOrganizationId();
  }, []);

  return organizationId;
};

export default useOrganizationId;
