import { Address } from '@endpoint/platform-api-connector/dist/graphql-types';

export const formatDisplayAddress = (address: Address) => {
  if (!address) return '';
  const { street1, street2, city, state, zip } = address;

  return `${street1}${street2 ? ` ${street2}` : ''}, ${city}, ${state}, ${zip}`;
};

// to support multiline address display
export const formatSplitDisplayAddress = (address: string): string[] => {
  const firstCommaIndex = address.indexOf(',');

  return [address.slice(0, firstCommaIndex + 1), address.slice(firstCommaIndex + 1, address.length)];
};
