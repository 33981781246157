import { isAfter, setHours, setMinutes } from 'date-fns';
import { WORKDAY_END_HOUR } from 'utils/formatDate';

export function getNow(): Date {
  return new Date(Date.now());
}

export function isPastWorkingHours() {
  const now = getNow();
  const todayWorkingHoursEnd = setHours(now, WORKDAY_END_HOUR);

  return !!isAfter(now, todayWorkingHoursEnd);
}

export function getLater(...unfilteredDates: Array<Date | undefined>): Date {
  const dates = filterDates(...unfilteredDates);

  if (dates.length === 0) {
    throw new Error('Pass at least one date');
  }

  return dates.reduce((acc, date) => (date.getTime() > acc.getTime() ? date : acc), dates[0]);
}

export function getEarlier(...unfilteredDates: Array<Date | undefined>): Date {
  const dates = filterDates(...unfilteredDates);

  if (dates.length === 0) {
    throw new Error('Pass at least one date');
  }

  return dates.reduce((acc, date) => (date.getTime() < acc.getTime() ? date : acc), dates[0]);
}

export function setTime(date: Date, timeToSet: Date) {
  return setHours(setMinutes(date, timeToSet.getMinutes()), timeToSet.getHours());
}

export function filterDates(...dates: Array<Date | undefined>): Array<Date> {
  return dates.filter((date) => date) as Array<Date>;
}
