import { Transaction, TransactionStatus, Milestone } from '@endpoint/platform-api-connector/dist/graphql-types';
import { formatDate, MONTH_DAY_YEAR } from 'utils/formatDate';
import { parseISO } from 'date-fns';

export const getSigningDetails = (transaction: Transaction) => {
  const { status, signingDate, milestones } = transaction;
  const milestoneWithSigning =
    milestones && milestones.find((milestone: Milestone) => milestone?.name?.includes('Sign Closing Documents'));

  const hasSignedDocuments = milestoneWithSigning && milestoneWithSigning.status === 'COMPLETE';
  const parsedSigningDate = signingDate ? parseISO(signingDate) : null;

  switch (status) {
    case TransactionStatus.IN_ESCROW:
    case TransactionStatus.CLOSED:
      return {
        label: hasSignedDocuments ? 'Signed:' : 'Signing:',
        value: signingDate ? formatDate(`${parsedSigningDate}`, MONTH_DAY_YEAR) : 'Not available yet',
      };
    default:
      return { label: 'Signing', value: 'N/A' };
  }
};
