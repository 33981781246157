import React, { FC } from 'react';
import { Divider, DrawerSection, Heading, Text, Box } from '@endpoint/blockparty';
import { Todo, TodoStatus } from '@endpoint/platform-api-connector/dist/graphql-types';

import { PinnedItemM2Check } from '../../PinnedItemM2/PinnedItemM2Check';
import { PinnedItemM6 } from '..';

interface BuyerViewProps {
  todos: Todo[];
}

export const BuyerView: FC<BuyerViewProps> = ({ todos }) => {
  const FUNDS_FOR_CLOSING = 'Submit Funds for Closing';
  const SCHEDULE_SIGNING_APPOINTMENT = 'Schedule Signing Appointment';

  const hasIncompleteFundsForClosingTodo = getIncompleteTodo(todos, FUNDS_FOR_CLOSING);

  if (hasIncompleteFundsForClosingTodo) {
    return <IncompleteFundsForClosingTodo />;
  }

  const hasIncompleteSigningTodo = getIncompleteTodo(todos, SCHEDULE_SIGNING_APPOINTMENT);

  if (hasIncompleteSigningTodo) {
    return <IncompleteSigningAppointmentTodo />;
  }

  return <PinnedItemM6 />;
};

export const getIncompleteTodo = (todos: Todo[], todoName: string) => {
  const foundTodo = todos.find((todo) => todo.name === todoName);

  if (foundTodo) {
    return foundTodo.status !== TodoStatus.SUBMITTED;
  }

  return false;
};

export const IncompleteSigningAppointmentTodo: FC = () => (
  <Box>
    <DrawerSection data-test-id="incomplete-signing-appointment">
      <Heading mb="space50" size="fontSize50">
        Your signing appointment
      </Heading>
      <Text as="p">
        Your signing instructions will be listed here once you’ve completed your “Schedule Signing Appointment” to-do.
      </Text>
    </DrawerSection>
    <Divider />
    <PinnedItemM2Check />
  </Box>
);

export const IncompleteFundsForClosingTodo: FC = () => {
  return (
    <Box>
      <PinnedItemM6 />
      <Divider />
      <DrawerSection data-test-id="incomplete-funds-for-closing">
        <Heading mb="space50" size="fontSize50">
          How to pay by Check
        </Heading>
        <Text as="p" mb="space50">
          We will need to receive the funds for closing before your signing appointment.
        </Text>
        <Text as="p">
          Your payment instructions will appear here once you’ve completed your “Submit Funds for Closing” to-do.
        </Text>
      </DrawerSection>
    </Box>
  );
};
